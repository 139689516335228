import { MeterHistoryWidgetEwii } from '@/components/widgets/meter-history-widget/custom/ewii';
import { MeterHistoryWidget } from '@/components/widgets/meter-history-widget/meter-history-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ewii') {
    widget = MeterHistoryWidgetEwii;
} else {
    widget = MeterHistoryWidget;
}
export { widget as MeterHistoryWidget };
