<div class="h-100 row align-items-center mt-5">
    <div class="col justify-center">
        <div class="container w-100">
            <div
                class="row d-block"
                v-if="!shouldVerifyAds || shouldAutomaticallyConfirmOptIn"
            >
                <div><span>{{ $t('verify.confirm.thanks') }}</span></div>

                <a @click="$router.push({name: 'login'})" class="m-link">
                    <span class="register-under-button"
                        >{{ $t('verify.back.to.login') }}</span
                    >
                </a>
            </div>
            <div class="col row d-block" v-else>
                <div class="row mb-4">
                    <div class="col-6">{{ $t('widget.verify.email') }}</div>
                    <div class="col-6 text-end">
                        {{ requestData.email_private }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">{{ $t('widget.verify.ads.email') }}</div>
                    <div class="col-6 text-end">
                        {{ boolToText(requestData.ads_mail) }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">{{ $t('widget.verify.phone') }}</div>
                    <div class="col-6 text-end">
                        {{ boolToText(requestData.ads_phone) }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">{{ $t('widget.verify.post') }}</div>
                    <div class="col-6 text-end">
                        {{ boolToText(requestData.ads_post) }}
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-9">
                        <div class="form-group form-check registration-terms">
                            <label class="form-check-label">
                                <input
                                    type="checkbox"
                                    id="registration_terms_and_conditions"
                                    v-model="confirmed"
                                />
                                {{ $t('registration.accept.terms.before') }} {{
                                $t('verify.accept.all') }}
                            </label>
                        </div>
                    </div>
                    <div class="col-3 buttons-ferify">
                        <vue-button-spinner
                            :is-loading="isLoading"
                            :class="{'disabled': isLoading, 'custom-vue-button-spinner-loading': isLoading}"
                            :disabled="isLoading"
                            @click="submitRequest()"
                            class="
                                btn btn-primary
                                m-btn m-btn--pill m-btn--custom m-btn--air
                                m-login__btn m-login__btn--primary
                            "
                        >
                            <span @click="submitRequest"
                                >{{ $t('login.submit') }}</span
                            >
                        </vue-button-spinner>
                    </div>
                </div>
            </div>
        </div>
        <message-block
            :success="successInformation"
            :errors="errorInformation"
        />
    </div>
</div>
