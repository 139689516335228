<input
    :value="value"
    :id="getId"
    :disabled="disabled()"
    :type="type"
    @input="setValue"
    @click="setValue"
    @change="setValue"
    @blur="setValue"
/>
