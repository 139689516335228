import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './service-widget.html';

@WithRender
@Component({
    props: {
        serviceWidgetPosition: String,
    },
})
export class ServiceWidget extends Base {
    protected elements = {
        bsw: ['mailto-email', 'no-title'],
        wdenergy: ['contact-form-action', 'no-title'],
        'wdenergy-us': ['no-title'],
        hsg: ['no-title'],
        wechselstrom: ['no-title'],
    };
}
