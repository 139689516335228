import { MeterWidget } from '../meter-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './ets.html';

@WithRender
@Component({
    props: {
        isActivecontractInActiveStateWidget: {
            type: Boolean,
            default: false,
        },
    },
})
export class MeterWidgetEts extends MeterWidget {
    get counterNumber() {
        return this.$store.getters['reading/getState'](this.$store.state.contracts.contractId).counter.counterNumber;
    }
}
