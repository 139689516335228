import { EcarUploadWidget } from '../ecar-upload-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli.html';

@WithRender
@Component({})
export class EcarUploadWidgetElli extends EcarUploadWidget {
    public scriptIsInjected = false;

    public mounted(): void {
        const script = document.querySelector(
            'script[src="https://widget.greentrax.de/greentrax-widget.min.js"]'
        );
        // @ts-ignore
        if (!window.Cypress && script == null) {
            const greentraxWidget = document.createElement('script');
            greentraxWidget.src =
                'https://widget.greentrax.de/greentrax-widget.min.js';
            greentraxWidget.onload = () => {
                this.scriptIsInjected = true;
            };
            document.head.appendChild(greentraxWidget);
        } else {
            this.scriptIsInjected = true;
        }
    }

    get productCodeConnectTariff(): boolean | void {
        if (this.productCode === 'PRODVolkswagenNaturstromConnect') {
            return true;
        }
    }
}
