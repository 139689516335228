import Component from 'vue-class-component';
import { Username } from '@/components/pages/auth/username/username';
import { ForgotUsernameWidget } from '@/components/widgets/forgot-username-widget';
import WithRender from './username-forgot.html'

@WithRender
@Component({
    components: {
        'forgot-username-widget': ForgotUsernameWidget,
    }
})
export class UsernameForgot extends Username {}
