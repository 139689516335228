import { MeterSubmitWidget } from '../meter-submit-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './ets.html';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Format from '@/interfaces/Format';
import { ReadingState } from '@/interfaces/Reading';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        fullNumber: formatProvider.euroFull(),
    },
})
export class MeterSubmitWidgetEts extends MeterSubmitWidget {
    get inWaitingState(): boolean {
        return this.nextReadingStateString === ReadingState.WAITING;
    }
}
