<div class="non-commodity-previous-bonus-entry">
    <div class="row">
        <div class="col-lg-2">
            <img class="non-commodity-product-image" :src="productImage" />
        </div>

        <div
            class="col-lg-2 non-commodity-previous-bonus-entry-col-border-right"
        >
            <b>{{ $t('widget.nc.bonus.overview.premium') }}</b>
            <br />

            <div v-if="isTitleLoading" class="form-group m-form__group">
                <div
                    class="spinner-border spinner-inside mr-3"
                    id="non-commodity-loading-products-spinner"
                    role="status"
                ></div>
            </div>
            <template v-else> {{ productName }} </template>
        </div>

        <div
            class="col-lg-2 non-commodity-previous-bonus-entry-col-border-right"
        >
            <b>{{ $t('widget.nc.bonus.overview.amount') }}</b>
            <br />
            {{ productPrice | euro }} {{ $t('euro.symbol') }} {{
            thgService.isCustomerTypeBusiness ?
            $t('widget.nc.bonus.selection.amount.gross') : '' }}
        </div>

        <div
            class="col-lg-2 non-commodity-previous-bonus-entry-col-border-right"
        >
            <b>{{ $t('widget.nc.bonus.overview.code') }}</b>
            <br />
            {{ product.voucherCode ? product.voucherCode :
            $t('widget.nc.bonus.overview.code.unavailable') }}
        </div>

        <div
            class="col-lg-2 non-commodity-previous-bonus-entry-col-border-right"
        >
            <b>{{ $t('widget.nc.bonus.overview.status') }}</b>
            <br />
            {{ $t(productStatusFormatted) }}
        </div>

        <div class="col-lg-2">
            <b>{{ $t('widget.nc.bonus.overview.year') }}</b>
            <br />
            {{ product.year }}
        </div>
    </div>
</div>
