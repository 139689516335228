<div class="m-aside-modal m-aside-modal__wrap">
    <div class="m-aside-modal__body">
        <!--begin::Form-->

        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('widget.modal.tfa.title') }}
            </span>
            <div class="m-aside-modal__close" @click="closeModal()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>

        <content-loader
            v-if="isContentLoader"
            :width="605"
            :height="150"
            data-test="CONTENT-LOADER"
            style="width: 605px; height: 150px"
        ></content-loader>

        <div v-else class="tfa-content" id="section-to-print">
            <template v-if="image">
                <div class="row">
                    <div class="col form-col col-md-12 totp-image-container">
                        <img :src="image" width="200" alt="qrcode" />
                        <br />
                        <span>{{ secret }}</span>
                    </div>
                </div>
                <div class="row totp-code-footer">
                    <div class="col form-col col-md-8">
                        <div class="form-group">
                            <input
                                id="code"
                                type="text"
                                :class="['form-control']"
                                :placeholder="$t('widget.modal.tfa.code')"
                                v-model="code"
                            />
                        </div>
                    </div>
                    <div
                        class="
                            col
                            form-col
                            col-md-4
                            totp-code-footer-button-box
                        "
                    >
                        <div class="form-group">
                            <div class="">
                                <button
                                    class="btn btn-primary"
                                    @click="sendCode"
                                    :disabled="enableLoadingState"
                                >
                                    {{ $t('widget.modal.tfa.submit') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div v-else-if="isActivated" class="row">
                <div class="col form-col">
                    {{ $t('widget.modal.tfa.enabled.already') }}

                    <div class="form-group">
                        <input
                            type="text"
                            :class="['form-control']"
                            :placeholder="$t('widget.modal.tfa.code')"
                            v-model="code"
                        />
                    </div>
                </div>
            </div>
            <div
                v-else-if="codes.length > 0"
                v-html="codesHtml"
                class="block-with-info"
            ></div>
            <div v-else class="block-with-info-content">{{ tfaContent }}</div>
        </div>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>

        <snackbar
            v-if="showSnackbar"
            :message="$t('widget.modal.tfa.text.clipboard')"
        ></snackbar>

        <!--begin::Action-->
        <template v-if="!isContentLoader">
            <div v-if="isActivated">
                <button
                    @click="disable()"
                    class="
                        btn btn-primary
                        m-btn m-btn--pill m-btn--custom
                        m-login__btn m-login__btn--primary
                    "
                    :disabled="enableLoadingState"
                >
                    {{ $t('widget.modal.tfa.button.disable') }}
                </button>
                <button
                    @click="cancelTwoFactorAuthentication()"
                    class="
                        btn btn-primary
                        m-btn m-btn--pill m-btn--custom
                        m-login__btn m-login__btn--primary
                    "
                    :disabled="enableLoadingState"
                >
                    {{ $t('widget.modal.tfa.button.cancel') }}
                </button>
            </div>
            <div v-else-if="codes.length > 0" id="section-no-print">
                <button
                    @click="copyCodes()"
                    class="
                        btn btn-primary
                        m-btn m-btn--pill m-btn--custom
                        m-login__btn m-login__btn--primary
                    "
                >
                    {{ $t('widget.modal.tfa.button.copy') }}
                </button>
                <button
                    @click="printCodes()"
                    class="
                        btn btn-primary
                        m-btn m-btn--pill m-btn--custom
                        m-login__btn m-login__btn--primary
                    "
                >
                    {{ $t('widget.modal.tfa.button.print') }}
                </button>
            </div>
            <div
                v-else-if="!image && codes.length === 0 && !isActivated"
                class="m-login__action modal-tfa-actions"
            >
                <button
                    @click="enable()"
                    class="
                        btn btn-primary
                        m-btn m-btn--pill m-btn--custom
                        m-login__btn m-login__btn--primary
                    "
                    :disabled="enableLoadingState"
                >
                    {{ $t('widget.modal.tfa.button.enable') }}
                </button>
            </div>
        </template>
        <!--end::Action-->
    </div>
</div>
