<div
    class="m-header--wrapper m-header--fixed m-header--fixed-mobile"
    :style="background"
>
    <!-- BEGIN: Header -->
    <header id="m_header" class="m-grid__item m-header">
        <div class="m-container m-container--fluid m-container--full-height">
            <div
                class="m-stack m-stack--ver m-stack--desktop header-v2-default"
            >
                <!-- BEGIN: Brand -->
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general">
                        <div
                            class="
                                m-stack__item m-stack__item--middle
                                m-brand__logo
                            "
                        >
                            <a href="/" class="m-brand__logo-wrapper">
                                <div :style="logo"></div>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- END: Brand -->

                <div
                    v-if="displayElement('header_nav')"
                    class="m-stack__item m-stack__item--fluid m-toolbar-top"
                    id="m_header_nav"
                >
                    <!-- BEGIN: Topbar -->
                    <div id="m_header_topbar" class="m-topbar">
                        <ul class="m-topbar__nav m-nav m-nav--inline">
                            <li class="m-nav__item">
                                <a href="#" class="m-nav__link"
                                    >Kundenservice</a
                                >
                            </li>
                            <li class="m-nav__item">
                                <a href="#" class="m-nav__link">Über Uns</a>
                            </li>
                            <li class="m-nav__item" v-if="isAuthenticated">
                                <a
                                    href="#"
                                    @click="logout()"
                                    class="m-nav__link"
                                    >{{ $t('asside.signout') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                    <!-- END: Topbar -->
                </div>
            </div>
        </div>
    </header>
    <!-- END: Header -->

    <!-- Not needed Navigation
  <a href="#" class="m-brand__toggler">
    <span></span>
  </a>
  -->
</div>
