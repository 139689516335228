<div
    class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
    :class="bodyClass"
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <!-- BEGIN: Header -->
        <app-header @toggleSidebar="toggleSidebar"></app-header>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div
            class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
        >
            <!-- BEGIN: Left Aside -->
            <left-aside
                @toggleSidebar="toggleSidebar"
                :toggleSidebar="toggleSidebarState"
                @toggle-modal="toggleOpenChangePasswordModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></left-aside>
            <!-- END: Left Aside -->

            <!-- begin: Body -->
            <modal-password-update-widget
                v-if="showChangePasswordModal"
                @toggle-modal="toggleOpenChangePasswordModal"
            />

            <modal-message-widget
                v-if="showMessageModal"
                :message="$t('widget.modal.message.security.message')"
                @toggle-message-modal="toggleOpenMessageModal"
            ></modal-message-widget>

            <modal-two-factor-authentication-widget
                v-if="showTwoFactorAuthenticationModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
                @toggle-cancel-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></modal-two-factor-authentication-widget>

            <div
                class="m-grid__item m-grid__item--fluid m-wrapper wallpaper-wrapper"
                id="dashboardMainArea"
                :style="widgetsBackground"
            >
                <div v-if="isDemoMode" class="demo-banner">
                    <span>powered by</span>
                    <img alt="WorkDigital" src="/img/wd_logo.svg" />
                </div>
                <agent-view-widget />
                <div class="container main-wrap">
                    <div class="m-content">
                        <div>
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <div class="m-portlet m-portlet--mobile">
                                        <div class="m-portlet__body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <i
                                                        class="fas fa-poll-h advizzo-icons"
                                                    ></i>
                                                    <span
                                                        class="static-title static-title--custom advizzo-title"
                                                        >{{
                                                        $t('widget.survey.title')
                                                        }}</span
                                                    >
                                                    <div class="adv_container">
                                                        <div
                                                            id="survey_widget"
                                                            class=""
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ROW -->
                        </div>
                        <ads-popup-widget
                            v-if="showAdsPopup"
                            @send-ads-data="sendAdsData"
                        />

                        <app-footer
                            v-if="displayElement('app-footer-inside-container')"
                        ></app-footer>
                    </div>
                </div>
            </div>

            <!-- END: Body -->
        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <template
            v-if="displayElement('hide-app-footer-outside-container')"
        ></template>
        <app-footer v-else></app-footer>
        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->
    <!-- begin::Scroll Top -->
    <scroll-top></scroll-top>
    <!-- end::Scroll Top -->
    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
</div>
