<div class="m-portlet m-portlet--mobile m-portlet--invoice dealer-quotas">
    <div class="m-portlet__body">
        <div class="title">
            <span class="dealer-quotas-title">{{ $t('widget.dq.title') }}</span>
            <span>{{ $t('widget.dq.subtitle') }}</span>
        </div>
        <div class="datatable">
            <table id="datatable">
                <thead>
                    <tr>
                        <th>
                            <div @click="toggleSort('registration')">
                                <span
                                    >{{
                                    $t('widget.dq.table.header.registration')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'registration'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <!--                        <th>-->
                        <!--                            <div @click="toggleSort('vin')">-->
                        <!--                                <span-->
                        <!--                                    >{{ $t('widget.dq.table.header.vin')-->
                        <!--                                    }}</span-->
                        <!--                                >-->
                        <!--                                <span v-if="requestParams.sortedKey === 'vin'">-->
                        <!--                                    <svg-->
                        <!--                                        width="12"-->
                        <!--                                        height="6"-->
                        <!--                                        viewBox="0 0 12 6"-->
                        <!--                                        fill="none"-->
                        <!--                                        xmlns="http://www.w3.org/2000/svg"-->
                        <!--                                    >-->
                        <!--                                        <path-->
                        <!--                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"-->
                        <!--                                            fill="#575962"-->
                        <!--                                        />-->
                        <!--                                    </svg>-->
                        <!--                                </span>-->
                        <!--                                <span v-else>-->
                        <!--                                    <svg-->
                        <!--                                        width="12"-->
                        <!--                                        height="6"-->
                        <!--                                        viewBox="0 0 12 6"-->
                        <!--                                        fill="none"-->
                        <!--                                        xmlns="http://www.w3.org/2000/svg"-->
                        <!--                                    >-->
                        <!--                                        <path-->
                        <!--                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"-->
                        <!--                                            fill="#575962"-->
                        <!--                                        />-->
                        <!--                                    </svg>-->
                        <!--                                </span>-->
                        <!--                            </div>-->
                        <!--                        </th>-->
                        <th>
                            <div @click="toggleSort('class')">
                                <span
                                    >{{
                                    $t('widget.dq.table.header.vehicle.class')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'class'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('status')">
                                <span
                                    >{{ $t('widget.dq.table.header.status')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'status'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('year')">
                                <span
                                    >{{ $t('widget.dq.table.header.quota.year')
                                    }}</span
                                >
                                <span v-if="requestParams.sortedKey === 'year'">
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('commission')">
                                <span
                                    >{{ $t('widget.dq.table.header.commission')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'commission'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="dataSets.length > 0">
                        <tr
                            v-for="(data,dataIndex) in sortedData"
                            :key="dataIndex"
                        >
                            <td>
                                <span>{{ formatData(data.registration) }}</span>
                            </td>
                            <!--                            <td><span>{{ data.vin }}</span></td>-->
                            <td><span>{{ data.class }}</span></td>
                            <td>
                                <span
                                    :class="['status', getStatusClass(data.status)]"
                                ></span
                                ><span
                                    >{{ $t('widget.dq.status.'+data.status)
                                    }}</span
                                >
                            </td>
                            <td><span>{{ data.year }}</span></td>
                            <td>
                                <span
                                    >{{ ['open-status',
                                    'successful-status'].includes(getStatusClass(data.status))
                                    ? 50 : 0 | euro}}
                                    {{$t('euro.symbol')}}</span
                                >
                            </td>
                            <!--                        <td v-for="(column,columnIndex) in options.columns" v-bind:key="columnIndex"-->
                            <!--                            v-bind:style="{'text-align':column.textAlign}">-->
                            <!--                                        <span v-if="column.type === 'component'">-->
                            <!--                                            <component v-bind:is="column.name" v-bind:row="data"></component>-->
                            <!--                                        </span>-->
                            <!--                            <span v-else-if="column.type === 'clickable'">-->
                            <!--                                            <a style="text-decoration: none"-->
                            <!--                                               v-bind:href="column.source+'/'+data[column.uniqueField]">{{data[column.key]}}</a>-->
                            <!--                                        </span>-->
                            <!--                            <span v-else-if="column.currencyFormat">-->
                            <!--                                              {{currencyFormat(data[column.key],column.currencyConfig)}}-->
                            <!--                                        </span>-->
                            <!--                            <span v-else-if="column.dateFormat">-->
                            <!--                                              {{dateFormat(data[column.key],column.currentFormat,column.expectFormat)}}-->
                            <!--                                        </span>-->
                            <!--                            <span v-else>-->
                            <!--                                              {{data[column.key]}}-->
                            <!--                                        </span>-->

                            <!--                        </td>-->
                        </tr>
                    </template>
                    <template v-else>
                        <tr class="no-data">
                            <td colspan="5">
                                <div>{{ $t('widget.dq.no.data') }}</div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <!--            <div class="pagination" v-if="totalCount > requestParams.take">-->
            <div class="pagination">
                <div class="back" @click="firstPage">
                    <svg
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 4.5L8.5 0.602886V8.39711L1 4.5Z"
                            :fill="backColor"
                        />
                        <line x1="0.5" x2="0.5" y2="9" :stroke="backColor" />
                    </svg>
                </div>
                <div class="begin" @click="prevPage">
                    <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 4.5L13.5 0.602886V8.39711L6 4.5Z"
                            :fill="backColor"
                        />
                        <path
                            d="M0 4.5L7.5 0.602886L7.5 8.39711L0 4.5Z"
                            :fill="backColor"
                        />
                    </svg>
                </div>
                <div class="content">
                    <span>{{ $t('widget.dq.pagination.page') }}</span>
                    <span>{{ currentPage }}</span>
                    <span>{{ $t('widget.dq.pagination.of') }}</span>
                    <span>{{ totalPage }}</span>
                </div>
                <div class="forward" @click="nextPage">
                    <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 4.5L2.5 0.602886L2.5 8.39711L10 4.5Z"
                            :fill="forwardColor"
                        />
                        <path
                            d="M16 4.5L8.5 0.602886V8.39711L16 4.5Z"
                            :fill="forwardColor"
                        />
                    </svg>
                </div>
                <div class="end" @click="lastPage">
                    <svg
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 4.5L2.5 0.602886L2.5 8.39711L10 4.5Z"
                            :fill="forwardColor"
                        />
                        <line
                            y1="-0.5"
                            x2="9"
                            y2="-0.5"
                            transform="matrix(0 1 1 0 11 0)"
                            :stroke="forwardColor"
                        />
                    </svg>
                </div>
                <!--                <a href="javascript:" v-bind:class="{'isDisabled':currentPage==1}" v-on:click="newPage(currentPage-1)">&laquo;</a>-->
                <!--                <a href="javascript:" v-on:click="newPage(page)" v-bind:class="{'active':currentPage==page}"-->
                <!--                   v-for="(page,pageIndex) in pages"-->
                <!--                   v-bind:key="pageIndex" v-bind:disables="currentPage ===page || page === '...'">{{page}}</a>-->
                <!--                <a v-on:click="newPage(currentPage+1)" :class="{'isDisabled':currentPage==totalPage}"-->
                <!--                   href="javascript:">&raquo;</a>-->
            </div>
        </div>

        <div class="totals">
            <div class="totals-header">
                <span>{{ $t('widget.dq.totals.title') }}:</span>
            </div>
            <div class="totals-content">
                <div class="totals-content-item">
                    <span
                        ><span class="status open-status"></span>{{
                        $t('widget.dq.totals.open') }}:</span
                    >
                    <span>{{ totalOpen | euro }} {{$t('euro.symbol')}}</span>
                </div>
                <div class="separator"></div>
                <div class="totals-content-item">
                    <span
                        ><span class="status successful-status"></span>{{
                        $t('widget.dq.totals.successful') }}:</span
                    >
                    <span
                        >{{ totalSuccessful | euro }}
                        {{$t('euro.symbol')}}</span
                    >
                </div>
            </div>
        </div>

        <div class="notice mt-2">
            <span
                >{{ $t('widget.dq.date.notice', { date: dataSetDate }) }}</span
            >
        </div>
    </div>
</div>
