import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
import vue from 'vue';
import Base from '@/mixins/base';

import Component, { mixins } from 'vue-class-component';

import WithRender from './survey.html';

@WithRender
@Component({
    metaInfo() {
        return {
            meta: [
                {
                    name: 'csrf-token',
                    content: '',
                },
            ],
        };
    },
})
export class Survey extends mixins(vue, Base, DashboardParent) {
    public mounted(): void {
        const questions = [
            {
                questionId: 0,
                type: 'unique',
                textualId: 'homeType',
                label: 'What type of home do you live in?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'house',
                        label: 'House',
                    },
                    {
                        value: 'flat',
                        label: 'Flat',
                    },
                    {
                        value: 'secHome',
                        label: 'Secondary home',
                    },
                ],
                category: {
                    label: 'Home Information',
                    value: 'homeInfo',
                },
                customerAnswers: ['2'],
            },
            {
                questionId: 1,
                type: 'unique',
                textualId: 'homeOwner',
                label: 'Do you own or rent your home?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'owm',
                        label: 'Own',
                    },
                    {
                        value: 'rent',
                        label: 'Rent',
                    },
                ],
                category: {
                    label: 'Home Information',
                    value: 'homeInfo',
                },
            },
            {
                questionId: 2,
                type: 'unique',
                textualId: 'homeSurface',
                label: 'What is the surface of your home?',
                description: null,
                display: 'slider',
                rules: null,
                allowedAnswers: [
                    {
                        value: '20',
                        label: '20 m2',
                    },
                    {
                        value: '30',
                        label: '30 m2',
                    },
                    {
                        value: '40',
                        label: '40 m2',
                    },
                    {
                        value: '50',
                        label: '50 m2',
                    },
                    {
                        value: '60',
                        label: '60 m2',
                    },
                    {
                        value: '70',
                        label: '70 m2',
                    },
                    {
                        value: '80',
                        label: '80 m2',
                    },
                    {
                        value: '90',
                        label: '90 m2',
                    },
                    {
                        value: '100',
                        label: '100 m2',
                    },
                    {
                        value: '110',
                        label: '110 m2',
                    },
                    {
                        value: '120',
                        label: '120 m2',
                    },
                    {
                        value: '130',
                        label: '130 m2',
                    },
                    {
                        value: '140',
                        label: '140 m2',
                    },
                    {
                        value: '150',
                        label: '150 m2',
                    },
                    {
                        value: '160',
                        label: '160 m2',
                    },
                    {
                        value: '170',
                        label: '170 m2',
                    },
                    {
                        value: '180',
                        label: '180 m2',
                    },
                    {
                        value: '190',
                        label: '190 m2',
                    },
                    {
                        value: '200',
                        label: '200 m2',
                    },
                    {
                        value: '210',
                        label: '210 m2',
                    },
                    {
                        value: '220',
                        label: '220 m2',
                    },
                    {
                        value: '230',
                        label: '230 m2',
                    },
                    {
                        value: '240',
                        label: '240 m2',
                    },
                    {
                        value: '250',
                        label: '250 m2',
                    },
                ],
                category: {
                    label: 'Home Information',
                    value: 'homeInfo',
                },
            },
            {
                questionId: 3,
                type: 'unique',
                textualId: 'occupancyNb',
                label: 'How many people, including children, live in your home?',
                description: null,
                display: 'slider',
                rules: null,
                allowedAnswers: [
                    {
                        value: '1',
                        label: '1',
                    },
                    {
                        value: '2',
                        label: '2',
                    },
                    {
                        value: '3',
                        label: '3',
                    },
                    {
                        value: '4',
                        label: '4',
                    },
                    {
                        value: '5',
                        label: '5',
                    },
                    {
                        value: '6',
                        label: '6',
                    },
                    {
                        value: '7',
                        label: '7',
                    },
                    {
                        value: '8',
                        label: '8',
                    },
                    {
                        value: '9',
                        label: '9',
                    },
                    {
                        value: '10',
                        label: '10',
                    },
                ],
                category: {
                    label: 'Home Information',
                    value: 'homeInfo',
                },
            },
            {
                questionId: 4,
                type: 'unique',
                textualId: 'homeInsulation',
                label: 'Have you done any retrofit work in your home?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Home Information',
                    value: 'homeInfo',
                },
            },
            {
                questionId: 5,
                type: 'unique',
                textualId: 'waterHeater',
                label: 'How is your water heated?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'gas',
                        label: 'Gas',
                    },
                    {
                        value: 'elect',
                        label: 'Electric',
                    },
                    {
                        value: 'other',
                        label: 'Other',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 6,
                type: 'unique',
                textualId: 'waterHeaterUse',
                label: 'How do you use your hot water?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'shower',
                        label: 'Shower',
                    },
                    {
                        value: 'showerBath',
                        label: 'Shower and Bath',
                    },
                    {
                        value: 'bath',
                        label: 'Bath',
                    },
                    {
                        value: 'hotBath',
                        label: 'Hot Bath',
                    },
                    {
                        value: 'dnk',
                        label: "Don't know",
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 7,
                type: 'unique',
                textualId: 'heatingType',
                label: 'What type of home heating do you have?',
                description: null,
                display: 'dropdown',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'gas',
                        label: 'City Gas',
                    },
                    {
                        value: 'elect',
                        label: 'Electric',
                    },
                    {
                        value: 'other',
                        label: 'Other',
                    },
                    {
                        value: 'dnk',
                        label: "Don't know",
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 8,
                type: 'unique',
                textualId: 'cookingType',
                label: 'What type of cooking do you have?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'gas',
                        label: 'City Gas',
                    },
                    {
                        value: 'elect',
                        label: 'Electric',
                    },
                    {
                        value: 'ind',
                        label: 'Induction',
                    },
                    {
                        value: 'gasElect',
                        label: 'Gas and Electricity',
                    },
                    {
                        value: 'other',
                        label: 'Other',
                    },
                    {
                        value: 'dnk',
                        label: "Don't know",
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 9,
                type: 'unique',
                textualId: 'lightingType',
                label: 'What type of lighting do you have?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'norm',
                        label: 'Normal',
                    },
                    {
                        value: 'eco',
                        label: 'Economy',
                    },
                    {
                        value: 'hal',
                        label: 'Halogen',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 10,
                type: 'multiple',
                textualId: 'appliancesType',
                label: 'What appliances do you have in your home?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'wm',
                        label: 'Washing Machine',
                    },
                    {
                        value: 'fr',
                        label: 'Freezer',
                    },
                    {
                        value: 'dw',
                        label: 'Dishwasher',
                    },
                    {
                        value: 'dr',
                        label: 'Dryer',
                    },
                    {
                        value: 'ov',
                        label: 'Oven',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 11,
                type: 'unique',
                textualId: 'acUse',
                label: 'Do you have an AC unit?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 12,
                type: 'unique',
                textualId: 'woodUse',
                label: 'Do you use wood to heat your home?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 13,
                type: 'unique',
                textualId: 'smartThermostat',
                label: 'Do you have a smart thermostat at home?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 14,
                type: 'unique',
                textualId: 'photovoltaic',
                label: 'Do you have solar panels installed at home?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 15,
                type: 'unique',
                textualId: 'electricCar',
                label: 'Do you have an electric vehicle?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Usages and Appliances',
                    value: 'usageAppl',
                },
            },
            {
                questionId: 16,
                type: 'unique',
                textualId: 'attSavingImportance',
                label: 'How important is saving energy to you?',
                description: null,
                display: 'dropdown',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Behaviour',
                    value: 'behaviour',
                },
            },
            {
                questionId: 17,
                type: 'undefined',
                textualId: 'attIsTryingToSave',
                label: 'Does your household consciously try to reduce energy consumption where possible?',
                description: null,
                display: 'dropdown',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'y',
                        label: 'Yes',
                    },
                    {
                        value: 'n',
                        label: 'No',
                    },
                ],
                category: {
                    label: 'Behaviour',
                    value: 'behaviour',
                },
            },
            {
                questionId: 18,
                type: 'unique',
                textualId: 'attSavingMotivation',
                label: 'What would be your motivation to save energy?',
                description: null,
                display: 'dropdown',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'smon',
                        label: 'Save money',
                    },
                    {
                        value: 'spla',
                        label: 'Save the planet',
                    },
                    {
                        value: 'dwas',
                        label: 'Dislike wastage',
                    },
                    {
                        value: 'doth',
                        label: "Don't like to use more than others",
                    },
                ],
                category: {
                    label: 'Behaviour',
                    value: 'behaviour',
                },
            },
            {
                questionId: 19,
                type: 'unique',
                textualId: 'attSavingIsBehaviourOrAppliances',
                label: 'Do you think more savings can be made from changing behaviours or changing appliances?',
                description: null,
                display: 'radio',
                rules: null,
                allowedAnswers: [
                    {
                        value: 'beh',
                        label: 'Behaviour',
                    },
                    {
                        value: 'app',
                        label: 'Appliances',
                    },
                ],
                category: {
                    label: 'Behaviour',
                    value: 'behaviour',
                },
            },
        ];
        const surveyConfig = {
            hideRules: [
                {
                    textualId: 'GARDEN_HAVE',
                    hide_rules: {
                        values: 'n',
                        children: ['GARDEN_WATER_NB_PER_WEEK_SUMMER'],
                    },
                },
                {
                    textualId: 'SHOWER_NB_PER_WEEK',
                    hide_rules: {
                        values: '0',
                        children: ['SHOWER_DURATION_MIN'],
                    },
                },
                {
                    textualId: 'GARDEN_WATER_NB_PER_WEEK_SUMMER',
                    hide_rules: {
                        values: '0',
                        children: ['GARDEN_WATER_MANNER'],
                    },
                },
            ],
        };

        // @ts-ignore
        const qm = new QuestionManager(surveyConfig);
        // @ts-ignore
        const dynamicSurvey =
            // @ts-ignore
            new DynamicWidgetWrapper(
                qm,
                '/api/proxy/survey-questions',
                // @ts-ignore
                new HttpMock(questions, 5000, 5000)
            );
        dynamicSurvey.render('survey_widget');
    }
}
