<div>
    <div class="alert alert-warning">
        <span>{{ $t('login.password.age.is.outdated') }}</span>
    </div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="username">
                <strong>{{ $t('login.username') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.username ? 'is-invalid' : '']"
                type="text"
                v-model="username"
                id="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.username')"
                v-model="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="password">
                <strong>{{ $t('login.password') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.password ? 'is-invalid' : '']"
                @keyup.enter="setPassword()"
                type="password"
                v-model="password"
                id="password"
                name="password"
                autocomplete="off"
            />
            <auth-plus-password-strength-meter
                :password="password"
                :username="username"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                @keyup.enter="setPassword()"
                type="password"
                :placeholder="$t('login.password')"
                v-model="password"
                name="password"
                autocomplete="off"
            />
            <auth-plus-password-strength-meter
                :password="password"
                :username="username"
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="passwordConfirmation">
                <strong>{{ $t('login.password.confirmation') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.confirmation ? 'is-invalid' : '']"
                @keyup.enter="setPassword()"
                type="password"
                v-model="passwordConfirmation"
                id="passwordConfirmation"
                name="passwordConfirmation"
                autocomplete="off"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                @keyup.enter="setPassword()"
                type="password"
                :placeholder="$t('login.password.confirmation')"
                v-model="passwordConfirmation"
                name="passwordConfirmation"
                autocomplete="off"
            />
        </div>

        <template v-if="isTFAActive">
            <div
                v-if="displayElement('input-with-label')"
                class="form-group m-form__group"
            >
                <label for="totpCode">
                    <strong>{{ $t('widget.modal.tfa.code.long') }}</strong> {{
                    $t('login.field.required') }}
                </label>
                <input
                    :class="['form-control m-input', errors.confirmation ? 'is-invalid' : '']"
                    type="text"
                    v-model="totpCode"
                    id="totpCode"
                    name="totpCode"
                    autocomplete="off"
                />
            </div>
            <div v-else class="form-group m-form__group">
                <input
                    class="form-control m-input"
                    type="text"
                    :placeholder="$t('widget.modal.tfa.code.long')"
                    v-model="totpCode"
                    name="totpCode"
                    autocomplete="off"
                />
            </div>
        </template>

        <messages-block
            :errors="errorInformation"
            class="new-password-widget-messages"
        ></messages-block>

        <!--begin::Action-->
        <div class="m-login__action">
            <vue-button-spinner
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom m-btn--air
                    m-login__btn m-login__btn--primary
                "
                :is-loading="isLoading"
                :disabled="isLoading"
                :status="status"
            >
                <span @click="setPassword">{{ $t('login.reset') }}</span>
            </vue-button-spinner>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>
