import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Payment from '../interfaces/Payment';

import SubmitPayment from '@/interfaces/SubmitPayment';
import SubmitDeposit from '@/interfaces/SubmitDeposit';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class PaymentProvider
    extends PrototypeProvider
    implements Payment
{
    public async invoices(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/payment/invoices/' + id, this.config);
    }

    public async depositCurrent(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/payment/deposit/' + id, this.config);
    }

    public async depositDetails(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/payment/deposit/' + id + '/details',
            this.config
        );
    }

    public async submitDeposit(data: SubmitDeposit): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/deposit', data, this.config);
    }

    public async accountBalance(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/payment/balance/' + id, this.config);
    }

    public async bankFromIban(iban: string): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/payment/iban/' + iban + '/bank',
            this.config
        );
    }

    public async bicFromIban(iban: string): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/payment/iban/' + iban + '/bic',
            this.config
        );
    }

    public async updatePaymentData(
        data: SubmitPayment
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/payment/payment-data', data, this.config);
    }

    public async downloadInvoice(
        id: number,
        invoiceNumber: number
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/payment/invoices/' + id + '/download/' + invoiceNumber,
            this.config
        );
    }

    public async depositList(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/deposit/' + id + '/list', this.config);
    }

    public async depositPlan(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/deposit/' + id + '/plan', this.config);
    }
}
