import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './qenergy-widget.html';

@WithRender
@Component({})
export class QenergyWidget extends Base {
    get bannerUrl() {
        const path = '/img/evus/' + this.currentClient;
        switch (this.currentClient) {
            case 'qcells':
                return path + '/Qcells_Strommix.jpg';
            default:
                return '';
        }
    }
}
