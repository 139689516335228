<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2 v-if="displayElement('header-v2')" />
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside"
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        ></div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>
                    <router-view />
                </div>
            </div>
            <div class="app-footer-right">
                <app-footer v-if="displayElement('app-footer-right')" />
            </div>

            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <app-footer v-if="displayElement('app-footer')" />
    <!-- end::Footer -->
</div>
