<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2 v-if="displayElement('header-v2')"></header-v2>
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside m-login--item"
            v-if="loginAsideStyle"
        >
            <div id="auth_header">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="login-logo">
                <div :style="loginLogo"></div>
            </div>
            <div class="login-image login-image--d">
                <img src="/img/evus/schweizstrom/login.png" alt="img" />
            </div>
            <div class="login-image login-image--m">
                <img
                    src="/img/evus/schweizstrom/startseite-schweizstrom-kundenportal-mobile.png"
                    alt="img"
                />
            </div>
        </div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper m-login--item"
            v-if="displayAuth"
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-title" data-test="login-title">
                        {{ $t('widget.lp.title.line1') }} <br />
                        {{ $t('widget.lp.title.line2') }} <br />
                        {{ $t('widget.lp.title.line3') }}
                    </div>
                    <div class="login-list">
                        <div class="me-5">
                            <div class="login-list__item">
                                {{ $t('widget.lp.advantage.usage') }}
                            </div>
                            <div class="login-list__item">
                                {{ $t('widget.lp.advantage.invoices') }}
                            </div>
                            <div class="login-list__item">
                                {{ $t('widget.lp.advantage.readings') }}
                            </div>
                        </div>
                        <div>
                            <div class="login-list__item">
                                {{ $t('widget.lp.advantage.deposit') }}
                            </div>
                            <div class="login-list__item">
                                {{ $t('widget.lp.advantage.move') }}
                            </div>
                            <div class="login-list__item">
                                {{ $t('widget.lp.advantage.data.change') }}
                            </div>
                        </div>
                    </div>

                    <!-- LOGIN AREA-->
                    <login-page
                        v-if="!isRegister && !resetPassword"
                        :second="second"
                    ></login-page>

                    <!-- RESET PASSWORD AREA -->
                    <reset-password-widget
                        v-if="resetPassword"
                    ></reset-password-widget>

                    <!-- REGISTER AREA-->
                    <register-page v-if="isRegister"></register-page>

                    <!-- RESTORE TWO FACTOR AUTHENTICATION AREA -->
                    <restore-two-factor-authentication
                        v-if="isRestoreTwoFactorAuthentication"
                    ></restore-two-factor-authentication>

                    <!-- AUTHPLUS FORCE CHANGE PASSWORD -->
                    <auth-plus-new-password-widget
                        v-if="isForceToChangePassword"
                    ></auth-plus-new-password-widget>

                    <div class="m-login__note" v-if="showLogoutNote">
                        <div class="m-login__note-body">
                            <span>{{ $t('widget.lp.note.heading') }}</span>
                            <div>{{ $t('widget.lp.note.text')}}</div>
                        </div>
                    </div>
                </div>
                <!--end::Signin-->
            </div>

            <!--end::Body-->
        </div>
    </div>
    <div class="footer">
        <div class="montains montains--auth">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 708.661 62.685"
            >
                <polygon
                    class="montainfill"
                    fill="#f3f3f3"
                    points="708.661 62.685 589.056 27.521 538.224 44.339 509.818 33.636 491.877 38.222 436.559 15.289 406.658 25.991 354.331 0 302.003 25.991 272.102 15.289 216.784 38.222 198.844 33.636 170.439 44.339 119.606 27.521 0 62.685 708.661 62.685"
                ></polygon>
            </svg>
        </div>
        <div class="footer__top footer__top--login">
            <h3 class="footer-login-title">{{ $t('login.footer.title') }}</h3>
            <p>
                <span>{{ $t('login.footer.subtitle') }}</span>
                <br />
                <a href="mailto:info@schweizstrom.com">info@schweizstrom.com</a>
                <br />
            </p>
        </div>
        <div class="footer-content-bottom">
            <div
                class="m-container m-container--fluid m-container--full-height m-page__container"
            >
                <div
                    class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
                >
                    <div class="footer-content-bottom__info info-footer-bottom">
                        <div class="info-footer-bottom">
                            <div class="info-footer-bottom__descr">
                                © EBLD Schweiz Strom GmbH i.L.
                            </div>
                            <ul class="info-footer-bottom__list">
                                <li class="info-footer-bottom__item">
                                    <a
                                        href="https://www.schweizstrom.de/de/ueber-uns/kontakt.html "
                                        target="_blank"
                                        >{{ $t('footer.contact') }}</a
                                    >
                                </li>
                                <li class="info-footer-bottom__item">
                                    <a
                                        href="https://www.schweizstrom.de/de/service/haeufige-fragen-kundenportal.html"
                                        target="_blank"
                                        >{{ $t('footer.faqs') }}</a
                                    >
                                </li>
                                <li class="info-footer-bottom__item">
                                    <a
                                        href="https://www.schweizstrom.de/de/impressum.html "
                                        target="_blank"
                                        >{{ $t('footer.imprint')}}</a
                                    >
                                </li>
                                <li class="info-footer-bottom__item">
                                    <a
                                        href="https://www.schweizstrom.de/de/impressum/datenschutz.html"
                                        target="_blank"
                                        >{{ $t('footer.privacy') }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
</div>
