import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './revoke-widget.html';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import moment from 'moment';
import Contracts from '@/interfaces/Contracts';
import * as Sentry from '@sentry/browser';

const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class RevokeWidget extends Base {
    protected successInformation: Record<any, any> = [];
    protected errorInformation: Record<any, any> = [];

    private confirm = false;

    get revocationDate() {
        return moment(new Date()).format('YYYY-MM-DD');
    }

    private revoke() {
        if (this.contractId === null) return;
        contractProvider
            .contractRevoke(this.contractId, this.revocationDate)
            .then(
                (response) => {
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    } else {
                        if (response.data.errorMessages.length > 0) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                },
                () => {
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                }
            )
            .catch((error) => {
                Sentry.captureException(new Error(error));
                this.errorInformation.push({
                    key: '',
                    message: this.$t('widget.cdc.failure').toLocaleString(),
                });
            });
    }
}
