import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './deposit-plan-widget.html';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Format from '@/interfaces/Format';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        monthYearHum: formatProvider.monthYearHum(),
        euro: formatProvider.euro(),
    },
    components: {
        ContentLoader,
    },
})
export class DepositPlanWidget extends Base {
    protected depositPlanError = false;

    protected created() {
        if (this.$store.state.contracts.contractId) {
            this.$store
                .dispatch(
                    'payment/depositPlan',
                    this.$store.state.contracts.contractId
                )
                .then(() => {
                    this.depositPlanError = this.$store.getters[
                        'payment/getState'
                    ](this.$store.state.contracts.contractId).depositPlanError;
                });
        }
    }

    get deposits(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).depositPlan;
    }

    get depositsLoaded(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).depositPlanLoaded;
    }

    get isDepositPlanError() {
        return this.depositPlanError;
    }

    get isDepositPlanLoading() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).isDepositPlanLoading;
    }
}
