// Import main VUE libraries
//import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import { App } from './components/app';
import router from './router/Router';
import store from './store/Store';
import i18n from './i18n';
import IdleVue from 'idle-vue';
import Tooltip from 'vue-directive-tooltip';
import VueScrollTo from 'vue-scrollto';
import VueMeta from 'vue-meta';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import VueCookies from 'vue-cookies';
import { Plugin as vueFragments } from 'vue-fragment';

// Fonts import
// -----------------------------------------
import 'typeface-poppins';
import '@fortawesome/fontawesome-free/css/all.css';

// Styles import
// -----------------------------------------
if (process.env.VUE_APP_ENVIRONMENT) {
    require(`./styles/env/${process.env.VUE_APP_ENVIRONMENT}/style.scss`);
} else {
    require('./styles/theme/style.scss');
}

// Maintenance style
require('./styles/maintenance/style.scss');

import TagService from './services/TagService';

const tagService = new TagService();

// add tag manager
if (process.env.VUE_APP_ENVIRONMENT === 'waermeenergie') {
    const usercentricsHeader = document.createElement('script');
    usercentricsHeader.src =
        'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    usercentricsHeader.id = 'usercentrics-cmp';
    usercentricsHeader.setAttribute('data-settings-id', '3eRMpKsDr9');
    usercentricsHeader.setAttribute('async', 'async');
    document.head.appendChild(usercentricsHeader);
}

// Google Tag Manager
const addTagManager = (src, tag) => {
    if (tag.includes('GTM-')) {
        const gtagHeader = document.createElement('script');
        gtagHeader.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start": new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","${tag}");`;
        document.head.appendChild(gtagHeader);
    } else {
        const gtagHeader = document.createElement('script');
        gtagHeader.src = src;
        gtagHeader.async = true;
        document.head.appendChild(gtagHeader);

        const gtagHeaderHelper = document.createElement('script');
        gtagHeaderHelper.innerHTML = `<script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${tag}'); </script>`;
        document.head.appendChild(gtagHeader);
    }

    const gtagBody = document.createElement('noscript');
    gtagBody.innerHTML = `<iframe src="${src}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(gtagBody);
};

if (
    process.env.VUE_APP_GTM_TAG &&
    process.env.VUE_APP_GTM_URL &&
    !window.Cypress
) {
    addTagManager(process.env.VUE_APP_GTM_URL, process.env.VUE_APP_GTM_TAG);
}

// add ChatBot Demo to powercloud us
if (process.env.VUE_APP_ENVIRONMENT === 'powercloud-us' && !window.Cypress) {
    tagService.appendHeadTag({
        tag: 'script',
        attributes: [
            {
                name: 'src',
                value: 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1',
            },
        ],
    });
    tagService.appendBodyTag({
        tag: 'div',
        innerHTML:
            '<df-messenger intent="WELCOME" chat-title="work digital - chat" agent-id="40254b3c-c21c-4156-a794-6312fd13f9a0" language-code="en" chat-icon="/img/evus/powercloud-us/favicon.ico"></df-messenger>',
    });
}
// add ChatBot Elli
if (process.env.VUE_APP_ENVIRONMENT?.includes('elli') && !window.Cypress) {
    tagService.appendHeadTag({
        tag: 'script',
        attributes: [
            {
                name: 'type',
                value: 'application/ld+json',
            },
        ],
        innerHTML: `{
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "Volkswagen Naturstrom Kundenportal",
            "url" : "${process.env.VUE_APP_PORTAL_URL}"
        }`,
    });
    tagService.appendHeadTag({
        tag: 'script',
        attributes: [
            {
                name: 'src',
                value: 'https://cdn.stadtwerk.bot/chatbot/latest/elli.js',
            },
        ],
    });
}

// add tracker wechselstrom
if (
    process.env.VUE_APP_ENVIRONMENT === 'wechselstrom' &&
    process.env.VUE_APP_ENVIRONMENT_STAGE == 'prod' &&
    !window.Cypress
) {
    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = `
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="https://www.relaunch.wechselstrom.de/matomo/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
    `;
    document.head.appendChild(scriptTag);
}

// Setup Vue components
// -----------------------------------------
Vue.config.productionTip = false;

// Set VueIdle
// -----------------------------------------
Vue.use(IdleVue, {
    store,
    startAtIdle: false,
    idleTime: 5000, // 15 Minuten * 60 Sekunden = 900 Sekunden
});

// Tooltip
// -----------------------------------------
Vue.use(Tooltip);

Vue.use(VueScrollTo, {
    offset: () => {
        return window.innerWidth && window.innerWidth <= 1024 ? -75 : -18;
    },
});

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});

// Elastic APM
if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'local' && !window.Cypress) {
    Vue.use(ApmVuePlugin, {
        router,
        config: {
            serviceName: 'portal-frontend',
            serverUrl: process.env.VUE_APP_ELASTIC_APM_URL,
            serviceVersion: process.env.VUE_APP_VERSION,
            environment:
                process.env.VUE_APP_ENVIRONMENT_STAGE +
                '-' +
                process.env.VUE_APP_ENVIRONMENT,
            logLevel: 'warn',
            distributedTracingOrigins: [
                'https://portal-dev.workdigital.de',
                'https://portal.workdigital.de',
            ],
            // agent configuration
        },
    });
}

// Setup Sentry Tracking
// -----------------------------------------
if (
    process.env.VUE_APP_SENTRY_DSN !== '' &&
    process.env.VUE_APP_ENVIRONMENT_STAGE !== 'local' &&
    process.env.VUE_APP_ENVIRONMENT !== 'waermeenergie' &&
    !window.Cypress
) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new VueIntegration({ Vue, attachProps: true })],
        release: 'portal-frontend@' + process.env.VUE_APP_VERSION,
        environment:
            process.env.VUE_APP_ENVIRONMENT +
            '.' +
            process.env.VUE_APP_ENVIRONMENT_STAGE,
        autoSessionTracking: false,
        ignoreErrors: [
            'Non-Error promise rejection captured',
            /Navigation cancelled from ".*" to ".*" with a new navigation\./,
            'Operation canceled because of duplicate request.',
        ],
        beforeSend(event, hint) {
            if (
                hint &&
                hint.originalException &&
                hint.originalException instanceof Error
            ) {
                const error = hint.originalException.message;
                if (error) {
                    if (
                        error.indexOf('Request failed with status code 422') >
                            -1 || // laravel validation error
                        error.indexOf('Request failed with status code 401') >
                            -1 || // unauthorized with login
                        error.indexOf('Request aborted') > -1 || // form submit cancelled unexpected
                        error.indexOf(
                            'Operation canceled because of duplicate request'
                        ) > -1 // Canceling of request, as already sent from other widget
                    ) {
                        return null;
                    }
                }
            }
            return event;
        },
    });
}

// Vue Fragments
Vue.use(vueFragments);

// Setup Vue cookies
// -----------------------------------------
Vue.use(VueCookies);

// set default config
Vue.$cookies.config('1d');

tagService.addAllToDOM();
// Set main Vue app
// -----------------------------------------
const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

if (window.Cypress) {
    window.app = app;
}
