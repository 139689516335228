import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './snackbar.html';

@WithRender
@Component({
    props: {
        message: {
            type: String,
            default: '',
        },
    },
})
export class Snackbar extends Base {}
