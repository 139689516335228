<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2 v-if="displayElement('header-v2')"></header-v2>
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside"
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        ></div>

        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
        >
            <!--begin::Body-->
            <div class="m-meter_reading__body">
                <div class="m-meter_reading__wrapper">
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>

                    <!-- Login Display -->
                    <div class="m-login__title">
                        <h1></h1>
                        <h6></h6>
                    </div>

                    <!--begin::Form-->
                    <div
                        v-if="showMeterReadingForm"
                        class="m-meter_reading__form m-form"
                    >
                        <div
                            v-if="displayElement('show-title')"
                            class="row m-meter_reading__title"
                        >
                            <h3>{{ $t('meter.reading.title') }}</h3>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input
                                    class="form-control m-input"
                                    type="text"
                                    id="contractId"
                                    :placeholder="$t('meter.reading.form.contractId')"
                                    v-model="details.contractId"
                                />
                            </div>
                            <div class="col">
                                <input
                                    class="form-control m-input"
                                    type="text"
                                    id="counterNumber"
                                    :placeholder="$t('meter.reading.form.counterNumber')"
                                    v-model="details.counterNumber"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <input
                                    class="form-control m-input"
                                    type="number"
                                    id="reading"
                                    :placeholder="$t('meter.reading.form.reading')"
                                    v-model="details.reading"
                                />
                            </div>
                            <div class="col">
                                <datetime
                                    v-model="details.readAtDate"
                                    type="date"
                                    input-id="readAt"
                                    input-class="form-control m-input"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    :placeholder="$t('meter.reading.form.readAt')"
                                >
                                </datetime>
                            </div>
                        </div>

                        <div
                            class="mt-3"
                            v-if="!displayElement('input-personal-errors') && (errors.length || Object.keys(errors).length)"
                        >
                            <show-multiple-error-messages
                                :errors="errors"
                            ></show-multiple-error-messages>
                        </div>

                        <messages-block
                            class="meter-reading-message-block"
                            :success="successInformation"
                            :errors="errorInformation"
                        ></messages-block>

                        <div class="m-meter_reading__action">
                            <div>
                                <a
                                    @click="$router.push({name: 'register'})"
                                    class="m-link"
                                >
                                    <span
                                        >{{ $t('register.customer.portal'
                                        )}}</span
                                    >
                                </a>
                            </div>

                            <button
                                :disabled="isSendData"
                                @click="sendMeterReading"
                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-meter_reading__btn m-meter_reading__btn--primary"
                            >
                                {{ $t('meter.reading.send') }}
                            </button>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        {{ $t('meter.reading.disabled') }}
                    </div>
                    <!--end::Form-->
                </div>
            </div>
            <div v-if="locales.length > 1">
                <span
                    v-for="(locale, index) in locales"
                    @click="changeLocale(locale)"
                >
                    {{ $t('locale.'+locale) }}
                    <span v-if="index !== locales.length - 1"> - </span>
                </span>
            </div>
            <div class="app-footer-right">
                <app-footer
                    v-if="displayElement('app-footer-right')"
                ></app-footer>
            </div>

            <!--end::Body-->
        </div>
    </div>

    <!-- begin::Footer -->
    <app-footer v-if="displayElement('app-footer')"></app-footer>
    <!-- end::Footer -->
</div>
