import Base from '@/mixins/base';
import { Component } from 'vue-property-decorator';
import WithRender from './elli-general.html';
import { Dashboard as ElliDashboard } from './elli';
import { Dashboard as ElliThg } from './elli-thg';
import { Dashboard as ElliThgDealer } from './elli-thg-dealer';
import { Dashboard as ElliFleet } from './elli-fleet';

@WithRender
@Component({
    components: {
        ElliDashboard,
        ElliThg,
        ElliThgDealer,
        ElliFleet,
    },
})
export class ElliGeneral extends Base {
    mounted() {
        const script = document.querySelector(
            'script[src="https://cdn.stadtwerk.bot/chatbot/latest/elli.js"]'
        );
        if (script !== null) {
            delete window['hsag_chatbot'];
            script.remove();
            const element = document.getElementById('hsagChatbot');
            if (element) {
                element.remove();
            }
            const newScript = document.createElement('script');
            newScript.async = true;
            newScript.type = 'text/javascript';
            newScript.src = 'https://cdn.stadtwerk.bot/chatbot/latest/elli.js';
            document.body.appendChild(newScript);
        }
    }
    get dashboard() {
        if (this.customerData.isFleetCustomer) {
            return ElliFleet;
        }
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );
        if (contract) {
            const isNonCommodity = contract.type === 'non_commodity';
            const tariff = this.$store.getters['tariff/getState'](
                this.contractId
            );
            let isDealer = false;
            if (
                tariff &&
                tariff.nonCommodityProduct &&
                tariff.nonCommodityProduct.productCode
            ) {
                isDealer =
                    tariff.nonCommodityProduct.productCode.includes(
                        'Haendlerprovision'
                    );
            }
            if (isNonCommodity && !isDealer) {
                return ElliThg;
            }

            if (isDealer) {
                return ElliThgDealer;
            }
        }

        return ElliDashboard;
    }

    get customerData() {
        return this.$store.state.customer;
    }
}
