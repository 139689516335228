<div
    class="m-portlet m-portlet--mobile position-relative"
    data-test="tariff-widget"
>
    <div
        class="m-portlet__body"
        :class="[displayElement('h-100-class') ? 'h-100' : '']"
    >
        <div
            class="d-block"
            :class="[displayElement('h-100-class') ? 'h-100' : '']"
            id="currentTariff-content"
        >
            <div class="currentTariff-title">
                <span
                    data-test="tariff-widget-product-title"
                    v-if="!productImage"
                    >{{ productTitle }}</span
                >
                <img
                    data-test="tariff-widget-product-img"
                    v-if="productImage"
                    :src="productImage"
                    style="margin-top: 0.4em"
                />
            </div>
            <tariff-widget-bsw
                v-if="displayElement('tariff-widget-bsw')"
            ></tariff-widget-bsw>
            <div
                :class="[
          'currentTariff-text',
          displayElement('non-margin-top-3-class') ? '' : 'mt-3',
          displayElement('bottom-15-class') ? 'bottom-15' : '',
          displayElement('position-absolute-class') && isNonCommodityContract ? 'position-absolute' : '',
        ]"
            >
                <span id="currentTariff-description"></span>
                <div
                    class="button-wrap"
                    v-if="showTariffLinks && displayElement('show-tariff-buttons')"
                >
                    <button
                        v-if="tariffOverviewAllowed"
                        class="btn btn-link btn-sm btn-link-block"
                        @click="$emit('toggleWidget4', 'showTariffOverview')"
                    >
                        {{ $t('widget.td.tariffOverview') }}
                    </button>
                    <button
                        v-if="contractMoveAllowed"
                        class="btn btn-link btn-sm btn-link-block"
                        @click="$emit('toggleWidget4', 'showMoveWidget')"
                    >
                        {{ $t('widget.td.move') }}
                    </button>
                    <button
                        v-if="tariffChangeAllowed && tariffChangeAvailable && !displayElement('change-tariff-button-bottom')"
                        class="btn btn-link btn-sm btn-link-block"
                        data-test="tariff-change-widget-button-click"
                        @click="$emit('toggleWidget4', 'showTariffChange')"
                    >
                        {{ $t('widget.td.tariffChange') }}
                    </button>
                </div>
                <div>
                    <button
                        data-test="tariff-widget-detail-button-click"
                        v-if="displayElement('show-details-button')"
                        @click="$emit('toggleWidget', 'tariffDetailsWidget')"
                        class="btn btn-link btn-sm btn-link-block btn-details-link"
                        id="button-tariffDetails-link"
                    >
                        {{ $t('widget.td.details') }}
                    </button>
                    <button
                        v-else
                        data-test="tariff-widget-detail-button-click"
                        @click="$emit('toggleWidget', 'tariffDetailsWidget')"
                        class="btn btn-primary btn-block"
                        id="button-tariffDetails"
                    >
                        {{ $t('widget.td.details') }}
                    </button>
                </div>
            </div>
            <div
                class="tariff-bottom-block"
                v-if="displayElement('show-tariff-button-overview') ||  displayElement('show-price-information-current-tariff')"
            >
                <div
                    class="row"
                    v-if="displayElement('show-price-information-current-tariff')"
                >
                    <div class="col-6 tariff-bottom-block__title">
                        {{ $t('widget.td.workingPrice') }}:
                    </div>
                    <div class="col-6 tariff-bottom-block__value">
                        <span
                            >{{ workingPrice | euro }} {{ commodityPrice
                            }}</span
                        >
                    </div>
                </div>
                <div
                    class="row"
                    v-if="displayElement('show-price-information-current-tariff')"
                >
                    <div class="col-6 tariff-bottom-block__title">
                        {{ $t('widget.td.basePrice') }}:
                    </div>
                    <div class="col-6 tariff-bottom-block__value">
                        <span
                            >{{ basePriceMonth | euro }} {{
                            $t('widget.td.basePrice.prefix') }}</span
                        >
                    </div>
                </div>
                <div
                    class="row"
                    v-if="displayElement('show-tariff-button-overview')"
                >
                    <div class="col text-center">
                        <span
                            class="tariff-bottom-block__button"
                            @click="$emit('toggleWidget4', 'showTariffOverview')"
                            >{{ $t('widget.td.tariffOverview') }}</span
                        >
                    </div>
                </div>
            </div>
            <div
                v-if="tariffChangeAllowed && tariffChangeAvailable && displayElement('change-tariff-button-bottom')"
                class="change-tariff__bottom"
            >
                <div class="change-tariff__header">
                    <span
                        >{{ $t('widget.td.until') }} {{
                        $store.state.settings.tariffChangePossibleUntil }} {{
                        $t('widget.td.change') }}:</span
                    >
                </div>
                <div>
                    <button
                        class="button"
                        data-test="tariff-change-widget-button-click"
                        @click="$emit('toggleWidget4', 'showTariffChange')"
                    >
                        <span>{{ $t('widget.td.change.now') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
