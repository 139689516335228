import { CancellationWidgetSachsenEnergie } from '@/components/widgets/cancellation-widget/custom/sachsenenergie';
import { CancellationWidgetElli } from '@/components/widgets/cancellation-widget/custom/elli';
import { CancellationWidgetElliThgDealer } from '@/components/widgets/cancellation-widget/custom/elli-thg-dealer';
import { CancellationWidgetWdenergy } from '@/components/widgets/cancellation-widget/custom/wdenergy';
import { CancellationWidgetWdenergyUs } from '@/components/widgets/cancellation-widget/custom/wdenergy-us';
import { CancellationWidget } from '@/components/widgets/cancellation-widget/cancellation-widget';

let widget;
if (process.env.VUE_APP_ENVIRONMENT === 'sachsenenergie') {
    widget = CancellationWidgetSachsenEnergie;
} else if (process.env.VUE_APP_ENVIRONMENT === 'elli') {
    widget = CancellationWidgetElli;
} else if (process.env.VUE_APP_ENVIRONMENT?.includes('elli-thg')) {
    widget = CancellationWidgetElliThgDealer;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = CancellationWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = CancellationWidgetWdenergyUs;
} else {
    widget = CancellationWidget;
}
export { widget as CancellationWidget };
