<div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="username">
                <strong>{{ $t('login.username') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.username ? 'is-invalid' : '']"
                type="text"
                v-model="username"
                id="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.username')"
                v-model="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="password">
                <strong>{{ $t('login.password') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.password ? 'is-invalid' : '']"
                @keyup.enter="setPassword()"
                type="password"
                v-model="password"
                id="password"
                name="password"
                autocomplete="off"
            />
            <auth-plus-password-strength-meter
                v-if="isAuthPlusActive || displayElement('auth-plus-password-strength-meter')"
                :password="password"
                :username="username"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                @keyup.enter="setPassword()"
                type="password"
                :placeholder="$t('login.password')"
                v-model="password"
                name="password"
                autocomplete="off"
            />
            <auth-plus-password-strength-meter
                v-if="isAuthPlusActive || displayElement('auth-plus-password-strength-meter')"
                :password="password"
                :username="username"
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="passwordConfirmation">
                <strong>{{ $t('login.password.confirmation') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.confirmation ? 'is-invalid' : '']"
                @keyup.enter="setPassword()"
                type="password"
                v-model="passwordConfirmation"
                id="passwordConfirmation"
                name="passwordConfirmation"
                autocomplete="off"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                @keyup.enter="setPassword()"
                type="password"
                :placeholder="$t('login.password.confirmation')"
                v-model="passwordConfirmation"
                name="passwordConfirmation"
                autocomplete="off"
            />
        </div>

        <messages-block :errors="errorInformation"></messages-block>

        <!--begin::Action-->
        <div class="m-login__action">
            <a @click="$router.push({name: 'login'})" class="m-link">
                <span>{{ $t('login.already.have') }}</span>
            </a>
            <button
                @click="setPassword()"
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom m-btn--air
                    m-login__btn m-login__btn--primary
                "
            >
                {{ $t('login.reset') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>
