import AddOn from '@/interfaces/AddOn';
import { injectable } from 'inversify';

@injectable()
export default class AddOnProvider implements AddOn {
    public parseImage(image: string | null): string {
        // <p>https:\/\/www.praemie24.com\/webapp\/img\/9150557_Feindaten.jpg<\/p>
        // <p>https:\/\/sales-journey.workdigital.de\/qcells\/img\/amazon.edd2950c.png<\/p>

        if (typeof image === 'string') {
            return image.slice(3, image.length - 4).replace('/', '/');
        }
        return typeof image;
    }
}
