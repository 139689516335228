import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './welcome-widget-v2.html';

@WithRender
@Component({})
export class WelcomeWidgetV2 extends Base {
    get contract(): Record<any, any> {
        const contractInfo = this.$store.state.contracts.list.filter(
            (contract) => {
                return (
                    contract.contractId ===
                    this.$store.state.contracts.contractId
                );
            }
        )[0];
        if (!contractInfo) {
            return {
                contractId: null,
                title: null,
            };
        }
        return contractInfo;
    }

    get showContractTitle(): boolean {
        return (
            this.contract.type !== 'non_commodity' &&
            this.contract.energy !== 'non_energy'
        );
    }
}
