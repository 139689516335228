<div class="col-12 mobile-modal__inner tariff-change-widget donation-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <i class="fas fa-euro-sign tariff-change-widget-icon"></i>
                    <span class="tariff-change-title static-title">
                        {{ $t('widget.donation.title') }}
                    </span>
                    <div class="mobile-modal__wrap">
                        <div class="row mt-3">
                            <div class="col-sm-4 col-12 tariff-change-left">
                                <div class="currentTariff-title">
                                    <img
                                        data-test="tariff-widget-product-img"
                                        class="img-max-width-100"
                                        src="/img/evus/bsw/logo-berliner-tafel.PNG"
                                        style="
                                            width: 14em;
                                            margin-top: 0.4em;
                                            margin-bottom: 0.4em;
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-8 col-12">
                                <div v-html="$t('widget.donation.text')"></div>
                                <div class="form-check mt-4">
                                    <label
                                        :class="[
                                            'form-check-label',
                                            confirmedError ? 'is-invalid text-danger' : ''
                                        ]"
                                        for="donation-confirm"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="donation-confirm"
                                            v-model="confirmation"
                                        />
                                        {{ $t('widget.donation.confirmation') }}
                                        <i></i>
                                        <span></span>
                                    </label>
                                </div>
                                <div class="text-end mt-2">
                                    <button
                                        class="btn btn-primary mt-4"
                                        @click="submit"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.donation.confirm') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="showSuccessDonation">
                            <div class="alert alert-success mt-2">
                                {{ $t('widget.donation.success') }}
                            </div>
                        </div>
                        <messages-block
                            :errors="errorInformation"
                        ></messages-block>
                        <div class="row bottom-block">
                            <div>
                                <span>{{ $t('widget.donation.asterix') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
