// -----------------------------
//    Customer Data structure
// -----------------------------

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import * as Sentry from '@sentry/browser';

import Auth from '../../interfaces/Auth';
import Customer from '../../interfaces/Customer';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const customerProvider = IocContainer.get<Customer>(SERVICES.CUSTOMER);

// Data Structure
const state = {
    onLoad: true,
    id: '',
    company: '',
    salutation: '',
    salutation_number: '',
    title: '',
    firstname: '',
    surname: '',
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    dateOfBirth: '',
    dateOfBirthDisplay: '',
    email: '',
    emailPrivate: '',
    emailBusiness: '',
    phone: '',
    phoneHomeAreaCode: '',
    phoneHome: '',
    phoneMobileAreaCode: '',
    phoneMobile: '',
    phoneBusinessAreaCode: '',
    phoneBusiness: '',
    phoneAdditional: '',
    authorizedAt: '',
    authorizedSinceDays: '',
    customerCommunicationType: '',
    adsMail: false,
    adsPhone: false,
    adsPost: false,
    communications: [],
    communicationsLoaded: false,
    adsBonusAvailable: false,
    postbox: '',
    isAuthPlusMaximumAgePassword: false,
    communicationsErrorLoaded: false,
    customerInformationLoaded: false,
    isFleetCustomer: false,
    groupAffiliation: null,
    communicationProfiles: [],
};

// Outside methods for communication with data.
const actions = {
    async getDetails({ commit }) {
        if (authProvider.isAuthenticated()) {
            await customerProvider.customerDetails().then(
                (response): void => {
                    if (response.data) {
                        commit('SET_CUSTOMER_DETAILS', response.data);
                        commit('SET_CUSTOMER_INFORMATION_LOADED', true);
                        customerProvider.checkOptOut().then((res) => {
                            if (res.data.success) {
                                commit(
                                    'settings/SET_SETTING',
                                    {
                                        key: 'optOut',
                                        value: res.data.optOut,
                                    },
                                    { root: true }
                                );
                            }
                        });
                    }
                    commit('SET_CUSTOMER_INFORMATION_LOADED', false);
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                    commit('SET_CUSTOMER_INFORMATION_LOADED', false);
                }
            );
        }
    },
    async communications({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await customerProvider.communications(data.limit, data.offset).then(
                (response): void => {
                    if (response.data) {
                        commit('SET_CUSTOMER_COMMUNICATIONS', response.data);
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                    commit('SET_COMMUNICATIONS_NOT_LOADED', true);
                }
            );
        }
    },
    async adsBonusAvailable({ commit }) {
        if (authProvider.isAuthenticated()) {
            await customerProvider.adsBonusAvailable().then(
                (response): void => {
                    if (response.data && response.data.success) {
                        commit(
                            'SET_CUSTOMER_ADS_BONUS_AVAILABLE',
                            response.data.success
                        );
                    }
                },
                (error) => {
                    Sentry.captureException(error);
                }
            );
        }
    },
    async confirmAdsSending({ commit }) {
        commit('SET_ADS_PHONE', true);
        commit('SET_ADS_MAIL', true);
        commit('SET_ADS_POST', true);

        await customerProvider.update({
            company: state.company,
            salutation: state.salutation_number,
            title: state.title,
            firstname: state.firstname,
            surname: state.surname,
            street: state.street,
            houseNumber: state.houseNumber,
            zip: state.zip,
            city: state.city,
            dateOfBirth: state.dateOfBirth,
            email: state.email,
            emailPrivate: state.emailPrivate,
            emailBusiness: state.emailBusiness,
            phone: state.phone,
            phoneHomeAreaCode: state.phoneHomeAreaCode,
            phoneHome: state.phoneHome,
            phoneMobileAreaCode: state.phoneMobileAreaCode,
            phoneMobile: state.phoneMobile,
            phoneBusinessAreaCode: state.phoneBusinessAreaCode,
            phoneBusiness: state.phoneBusiness,
            phoneAdditional: state.phoneAdditional,
            customerCommunicationType: state.customerCommunicationType,
            adsMail: true,
            adsPhone: true,
            adsPost: true,
            adsConfirmation: true,
            synchronizeWithBilling: null,
            contractId: null,
            postbox: state.postbox,
            activeTab: '',
            groupAffiliation: null,
        });
    },
    adsBonusSubmitted({ commit }) {
        commit('SET_CUSTOMER_ADS_BONUS_AVAILABLE', false);
        commit('SET_ADS_PHONE', true);
        commit('SET_ADS_MAIL', true);
        commit('SET_ADS_POST', true);
    },
    getCommunicationProfiles({ commit }) {
        customerProvider.getCommunicationProfiles().then((response) => {
            commit('SET_COMMUNICATION_PROFILES', response.data.data ?? []);
        });
    },
};

// Logic that change data
const mutations = {
    SET_CUSTOMER_DETAILS(localState, data) {
        localState.id = data.id ? data.id : null;
        localState.company = data.company ? data.company : null;
        localState.salutation = data.salutation ? data.salutation : null;
        localState.salutation_number = data.salutation_number
            ? data.salutation_number
            : null;
        localState.title = data.title ? data.title : null;
        localState.firstname = data.firstname ? data.firstname : null;
        localState.surname = data.surname ? data.surname : null;
        localState.street = data.street ? data.street : null;
        localState.houseNumber = data.houseNumber ? data.houseNumber : null;
        localState.zip = data.zip ? data.zip : null;
        localState.city = data.city ? data.city : null;
        localState.dateOfBirth = data.dateOfBirth ? data.dateOfBirth : null;
        localState.dateOfBirthDisplay = data.dateOfBirthDisplay
            ? data.dateOfBirthDisplay
            : null;
        localState.email = data.email ? data.email : null;
        localState.emailPrivate = data.emailPrivate ? data.emailPrivate : null;
        localState.emailBusiness = data.emailBusiness
            ? data.emailBusiness
            : null;
        localState.phone = data.phone ? data.phone : null;
        localState.phoneHomeAreaCode = data.phoneHomeAreaCode
            ? data.phoneHomeAreaCode
            : null;
        localState.phoneHome = data.phoneHome ? data.phoneHome : null;
        localState.phoneMobileAreaCode = data.phoneMobileAreaCode
            ? data.phoneMobileAreaCode
            : null;
        localState.phoneMobile = data.phoneMobile ? data.phoneMobile : null;
        localState.phoneBusinessAreaCode = data.phoneBusinessAreaCode
            ? data.phoneBusinessAreaCode
            : null;
        localState.phoneBusiness = data.phoneBusiness
            ? data.phoneBusiness
            : null;
        localState.authorizedAt = data.authorizedAt ? data.authorizedAt : null;
        localState.authorizedSinceDays = data.authorizedSinceDays
            ? data.authorizedSinceDays
            : null;
        localState.customerCommunicationType = data.customerCommunicationType
            ? data.customerCommunicationType
            : null;
        localState.adsMail = data.adsMail && data.adsMail === '1';
        localState.adsPhone = data.adsPhone && data.adsPhone === '1';
        localState.adsPost = data.adsPost && data.adsPost === '1';
        localState.postbox = data.postbox ? data.postbox : null;
        localState.isAuthPlusMaximumAgePassword =
            data.isAuthPlusMaximumAgePassword
                ? data.isAuthPlusMaximumAgePassword
                : false;
        localState.isFleetCustomer = data.isFleetCustomer
            ? data.isFleetCustomer
            : false;
    },
    SET_CUSTOMER_COMMUNICATIONS(localState, data) {
        data.forEach((element) => {
            if (
                localState.communications.filter(
                    (e) => e.communicationId === element.id
                ).length <= 0
            ) {
                let cssClass =
                    'm-timeline-1__item--' + element.data.timelinePosition;
                if (element.data.timelineFirst) {
                    cssClass += ' m-timeline-1__item--first';
                }
                if (element.data.timelineLast) {
                    cssClass += ' m-timeline-1__item--last';
                }

                localState.communications.push({
                    contractId: element.contractId ? element.contractId : null,
                    communicationId: element.id ? element.id : null,
                    createdAt: element.createdAt ? element.createdAt : null,
                    createdBy: element.createdBy ? element.createdBy : null,
                    title: element.title ? element.title : null,
                    archiveId: element.archiveId ? element.archiveId : null,
                    sent: element.sent ? element.sent : 0,
                    sentAt: element.sentAt ? element.sentAt : null,
                    timelinePosition: element.data.timelinePosition
                        ? element.data.timelinePosition
                        : null,
                    timelineFirst: element.data.timelineFirst
                        ? element.data.timelineFirst
                        : false,
                    timelineLast: element.data.timelineLast
                        ? element.data.timelineLast
                        : false,
                    timelineClass: cssClass,
                });
            }
        });
        localState.communicationsLoaded = true;
    },
    SET_ADS_MAIL(localState, value) {
        localState.adsMail = value;
    },
    SET_ADS_PHONE(localState, value) {
        localState.adsPhone = value;
    },
    SET_ADS_POST(localState, value) {
        localState.adsPost = value;
    },
    SET_CUSTOMER_ADS_BONUS_AVAILABLE(localState, value) {
        localState.adsBonusAvailable = value;
    },
    SET_CUSTOMER_INFORMATION_LOADED(localState, value) {
        if (!localState.customerInformationLoaded) {
            localState.customerInformationLoaded = value;
        }
    },
    SET_COMMUNICATIONS_NOT_LOADED(localState, value) {
        localState.communicationsErrorLoaded = value;
    },
    SET_COMMUNICATION_PROFILES(localState, data) {
        localState.communicationProfiles = data;
    },
};

// Get Data transformation (like computed)
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
