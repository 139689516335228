import { MeterSubmitWidget } from '@/components/widgets/meter-submit-widget/meter-submit-widget';
import { MeterSubmitWidgetEts } from '@/components/widgets/meter-submit-widget/custom/ets';
import { MeterSubmitWidgetSchweizstrom } from '@/components/widgets/meter-submit-widget/custom/schweizstrom';
import { MeterSubmitWidgetWaermeenergie } from '@/components/widgets/meter-submit-widget/custom/waermeenergie';
import { MeterSubmitWidgetElli } from '@/components/widgets/meter-submit-widget/custom/elli';

const widgetTypes = {
    ets: MeterSubmitWidgetEts,
    schweizstrom: MeterSubmitWidgetSchweizstrom,
    waermeenergie: MeterSubmitWidgetWaermeenergie,
    elli: MeterSubmitWidgetElli,
};

const widget =
    widgetTypes[process.env.VUE_APP_ENVIRONMENT as string] ?? MeterSubmitWidget;

export { widget as MeterSubmitWidget };
