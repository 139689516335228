<div
    class="mobile-modal__inner mobile-modal__inner--tariff-details"
    data-test="tariff-details-widget"
>
    <div class="m-portlet m-portlet--mobile border-radius-rb">
        <div class="m-portlet__body">
            <div
                data-test="tariff-details-widget-close-click"
                v-if="!displayElement('close-button-hidden')"
                @click="$emit('closeWidget')"
                id="button-closeTariffDetails"
                class="fullwidthArea-close"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="row mobile-modal__wrap" id="tariffDetails-content">
                <div class="col-12">
                    <i
                        class="fa fa-hands-helping readingHistory-icon"
                        v-if="displayElement('hands-helping-icon')"
                    ></i>
                    <span
                        class="box-title static-title static-title--custom"
                        v-if="displayElement('contract-details-number')"
                    >
                        {{ $t('widget.td.title') }} {{
                        $t('widget.td.title.extension') }} {{ contract.id }}
                    </span>
                    <span
                        class="box-title static-title static-title--custom"
                        v-else
                        >{{ $t('widget.td.title') }} {{ contract.id }}</span
                    >
                </div>
                <div
                    class="col-12"
                    v-if="contractInCancellation && !displayElement('hide-contract-in-cancellation')"
                >
                    <div class="text-warning">
                        {{
                        $t('widget.td.inCancellation.yourCancellationForContract')
                        }} {{ contract.id }} {{
                        $t('widget.td.inCancellation.for') }} {{
                        contract.desiredEndDate | date }} {{
                        $t('widget.td.inCancellation.hasBeen') }} {{
                        contract.cancelledAt | date }} {{
                        $t('widget.td.inCancellation.received') }}.<br />
                        <span
                            v-if="!displayElement('hide-cancellation-reason')"
                        >
                            {{ $t('widget.td.inCancellation.reason') }}: {{
                            contract.cancellationReason }}
                        </span>
                    </div>
                    <br />
                </div>
                <div class="col-12" v-if="contractInRevocation">
                    <div class="text-warning">
                        {{ $t('widget.td.contractInRevocation', { contractId:
                        contract.id, contractRevokeAt:
                        $options.filters.date(contract.revokeAt),
                        contractFillingDateRevocation:
                        $options.filters.date(contract.fillingDateRevocation),
                        }) }}
                    </div>
                </div>
                <div class="wrap-content">
                    <div class="col--flex">
                        <div class="col-content">
                            <b>{{ $t('widget.td.delivery') }}</b><br />
                            <span id="tariffDetails-deliveryAddress">
                                {{ contract.deliveryAddress.firstname }} {{
                                contract.deliveryAddress.surname }}<br />
                                {{ contract.deliveryAddress.street }} {{
                                contract.deliveryAddress.houseNumber }}<br />
                                {{ contract.deliveryAddress.zip }} {{
                                contract.deliveryAddress.city }}</span
                            >
                            <br />
                            <button
                                v-if="contractMoveAllowed && displayElement('show-contract-move')"
                                class="btn btn-link mb-3"
                                @click="$emit('toggleWidget', 'showMoveWidget')"
                            >
                                {{ $t('widget.td.move') }}
                            </button>
                            <br />

                            <template v-if="displayRuntime">
                                <div
                                    v-if="displayElement('product-name-instead-of-runtime')"
                                >
                                    <b>{{ product.title }}</b><br />
                                </div>
                                <div v-else>
                                    <b>{{ $t('widget.td.runtime') }}</b><br />
                                </div>
                                <span id="tariffDetails-dates">
                                    {{ $t('widget.td.begin') }}: {{
                                    contract.startDate | date }}<br />
                                    <div
                                        v-if="displayElement('show-period-info')"
                                    >
                                        <span>
                                            {{ $t('widget.td.min.term') }}: {{
                                            contract.minimumRuntime }} {{
                                            $t('widget.td.runtime.' +
                                            contract.minimumRuntimeUnit) }} </span
                                        ><br />
                                        <span>
                                            {{ $t('widget.td.extRuntime') }}: {{
                                            contract.extensionTime }} {{
                                            $t('widget.td.runtime.' +
                                            contract.extensionTimeUnit) }} </span
                                        ><br />
                                        <span>
                                            {{
                                            $t('widget.td.cancellation.period')
                                            }}: {{ cancellationPeriod }} {{
                                            $t('widget.m.days') }}
                                        </span>
                                    </div>

                                    <span v-if="contractCancelled">
                                        {{ $t('widget.td.end') }}: {{ endDate |
                                        date }}
                                    </span>
                                    <span v-else>
                                        <div
                                            v-if="displayElement('hide-nextCancellationDate')"
                                        ></div>
                                        <div
                                            v-else-if="displayElement('show-nextCancellationDate-extended')"
                                        >
                                            {{ $t('widget.td.period') }}: {{
                                            $t('widget.td.period.to') }} {{
                                            nextCancellationDate | date }}<br />
                                            {{
                                            $t('widget.td.cancellation.period')
                                            }}: {{ $tc('weeks',
                                            cancellationPeriod) }}
                                        </div>
                                        <div v-else>
                                            {{ $t('widget.td.period') }}: {{
                                            nextCancellationDate | date }}
                                        </div>
                                    </span>
                                    <div
                                        v-if="displayElement('show-term-of-contract')"
                                    >
                                        {{ $t('widget.td.term.of.contract') }}:
                                        {{ contract.minimumRuntime }} {{
                                        $t('widget.td.runtime.' +
                                        contract.minimumRuntimeUnit) }}
                                    </div>
                                </span>
                                <button
                                    v-if="contractCancellationAllowed && displayElement('show-cancellation-button')"
                                    class="btn btn-link"
                                    @click="$emit('toggleWidget', 'showCancellationWidget')"
                                    data-test="button-open-cancellation-widget"
                                >
                                    {{ $t('widget.td.cancellation') }}
                                </button>
                            </template>
                        </div>

                        <button
                            data-test="tariff-details-widget-tariff-overview-click"
                            v-if="tariffOverviewAllowed && !displayElement('hide-tariff-overview')"
                            class="btn btn-primary"
                            @click="$emit('toggleWidget', 'showTariffOverview')"
                        >
                            {{ $t('widget.td.tariffOverview') }}
                        </button>
                    </div>
                    <div class="col--flex">
                        <div class="col-content">
                            <div class="tariffDetails-title">
                                <b
                                    v-if="displayElement('contract-details-label')"
                                    >{{ $t('widget.td.contract.details') }}</b
                                >
                                <b v-else>{{ product.title }}</b>
                            </div>
                            <div v-if="displayElement('contract-number-in-td')">
                                <span class="value--right"
                                    >{{ $t('widget.td.contractNumber') }}:</span
                                >
                                {{ contract.id }}
                            </div>
                            <div v-if="displayElement('contract-number-in-td')">
                                <span class="value--right"
                                    >{{ $t('widget.td.tariff') }}:</span
                                >{{ product.title }}
                            </div>
                            <div v-if="displayElement('counter-number-in-td')">
                                <span class="value--right"
                                    >{{ $t('widget.td.counterNumber') }}: </span
                                >{{ contract.counterNumber }}
                                <br />
                                <br />
                            </div>
                            <div class="value-wrap">
                                <span class="value--right"
                                    >{{ $t('widget.td.yearlyUsage') }}:
                                </span>
                                {{ tariff.usage | euroFull }} {{commodity}}
                            </div>
                            <div class="value-wrap">
                                <span class="value--right"
                                    >{{ $t('widget.td.basePrice') }}:
                                </span>
                                {{ tariff.basePrice | euro }} {{ $t('euro')
                                }}/{{ $t('year') }}
                            </div>
                            <div class="value-wrap">
                                <span class="value--right"
                                    >{{ $t('widget.td.workingPrice') }}:</span
                                >
                                <span class="value--text-align-right">
                                    <template
                                        v-for="workingPrice in tariff.workingPrices"
                                    >
                                        <template
                                            v-if="tariff.workingPrices.length > 1"
                                            >{{ workingPrice.registerName }}:
                                        </template>
                                        {{ workingPrice.workingPriceBrutto |
                                        euro }} {{ commodityPrice }}
                                        <br
                                            v-if="tariff.workingPrices.length > 1"
                                        />
                                    </template>
                                </span>
                            </div>
                            <div
                                class="value-wrap"
                                v-if="tariff.productBonus > 0 && tariff.productBonusPercent > 0"
                            >
                                <span class="value--right"
                                    >{{ $t('widget.td.newCustomerBonus')
                                    }}</span
                                >
                                <div v-if="tariff.productBonusPercent > 0">
                                    {{ tariff.productBonusPercent }} {{
                                    $t('euro')}}
                                </div>
                                <div v-else-if="tariff.productBonus > 0">
                                    {{ tariff.productBonus }} {{ $t('euro')}}
                                </div>
                            </div>
                            <div
                                class="value-wrap"
                                v-if="tariff.InstantBonus > 0"
                            >
                                <span class="value--right"
                                    >{{ $t('widget.td.instantBonus') }}</span
                                >
                                {{ tariff.InstantBonus}}
                            </div>

                            <div v-if="displayElement('show-term-of-contract')">
                                {{ $t('widget.td.fixedPrice') }}: {{
                                fixedPriceMonths }}
                            </div>
                            <button
                                v-if="tariffOverviewAllowed && displayElement('show-tariff-overview')"
                                class="btn btn-link"
                                @click="$emit('toggleWidget', 'showTariffOverview')"
                            >
                                {{ $t('widget.td.tariffOverview') }}
                            </button>
                        </div>

                        <button
                            class="btn btn-primary"
                            @click="$emit('toggleWidget', 'showEnergyCompoundWidget')"
                        >
                            {{ $t('widget.mo.strommix.title') }}
                        </button>
                    </div>
                    <div class="col--flex">
                        <!--Address Details-->
                        <div class="col-content">
                            <span
                                v-if="displayElement('address-details')"
                                class="mb-5"
                            >
                                <b>{{ $t('widget.td.address') }}</b>
                                <i
                                    @click="$emit('toggleWidget2', 'changeBillingDataWidget')"
                                    class="fa fa-pen"
                                ></i>
                                <br />
                                <span
                                    v-if="contract.billingAddress.firstname && contract.billingAddress.surname"
                                    >{{`${contract.billingAddress.firstname}
                                    ${contract.billingAddress.surname}`}}<br
                                /></span>
                                <span
                                    v-if="contract.billingAddress.street && contract.billingAddress.houseNumber"
                                    >{{`${contract.billingAddress.street}
                                    ${contract.billingAddress.houseNumber}`}}<br
                                /></span>
                                <span
                                    v-if="contract.billingAddress.zip && contract.billingAddress.city"
                                    >{{`${contract.billingAddress.zip}
                                    ${contract.billingAddress.city}`}}<br
                                /></span>
                                <br />
                            </span>
                            <!--Tariff & Banking Details-->
                            <span>
                                <b>{{ $t('widget.dc.title' )}}</b>

                                <div>
                                    {{ $t('widget.dc.monthly') }}: {{ current }}
                                    {{ $t('euro')}}
                                </div>
                                <div>
                                    {{ $t('widget.dc.nextDueDate') }}: {{ useAt
                                    | date }}
                                </div>
                            </span>
                        </div>

                        <button
                            v-if="contractMoveAllowed && !displayElement('hide-contract-move')"
                            class="btn btn-primary"
                            @click="$emit('toggleWidget', 'showMoveWidget')"
                        >
                            {{ $t('widget.td.move') }}
                        </button>
                        <button
                            v-if="contractCancellationAllowed && !displayElement('hide-cancellation-button')"
                            class="btn btn-primary"
                            @click="$emit('toggleWidget', 'showCancellationWidget')"
                            data-test="button-open-cancellation-widget"
                        >
                            {{ $t('widget.td.cancellation') }}
                        </button>
                        <button
                            v-if="showRevocationWidget"
                            class="btn btn-primary"
                            @click="$emit('toggleWidget', 'revokeWidget')"
                        >
                            {{ $t('widget.td.revocation') }}
                        </button>
                        <button
                            v-if="tariffChangeAllowed && tariffChangeAvailable && !displayElement( 'hide-tariff-change')"
                            class="btn btn-primary"
                            @click="$emit('toggleWidget', 'showTariffChange')"
                        >
                            {{ $t('widget.td.tariffChange') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
