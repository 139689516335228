import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Survey from '@/interfaces/Survey';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class SurveyProvider
    extends PrototypeProvider
    implements Survey
{
    public async sendResults(data): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/survey/results', data, this.config);
    }
}
