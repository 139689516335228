import { CustomerServiceFormWidget } from '../customer-service-form-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './sachsenenergie.html';

@WithRender
@Component({})
export class CustomerServiceFormWidgetSachsenenergie extends CustomerServiceFormWidget {
    public checkAndSubmit(): void {
        const allowedTypes = [
            'application/pdf',
            'image/jpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];

        for (const type of this.details.documentContentTypes) {
            if (!allowedTypes.includes(type)) {
                this.errorInformation = [];
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'widget.cs.invalidFileType'
                    ).toLocaleString(),
                });
                return;
            }
        }

        this.submit();
    }
}
