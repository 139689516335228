<div class="col-12 mobile-modal__inner tariff-change-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div class="row">
                <div class="col">
                    <span class="tariff-change-title static-title">
                        {{ $t('widget.tc.title') }} {{
                        $store.state.settings.tariffChangeSelectedDate }}
                    </span>
                    <div class="tariff-change-title-name">
                        {{ tariff.title }}
                    </div>
                    <div class="mobile-modal__wrap">
                        <div class="row mt-3">
                            <span>{{ $t('widget.tc.top.notice') }}</span>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-5 col-12 tariff-change-left">
                                <div class="row mt-3">
                                    <div class="pink-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.to.workingPrice')
                                                }}:</span
                                            >
                                            {{ tariff.workingPriceBrutto |
                                            euro}}
                                            {{$t('unit.centperelectricity')}}
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.to.basePrice')
                                                }}:</span
                                            >
                                            {{ tariff.basePriceBruttoMonth |
                                            euro}}
                                            {{$t('euro')}}/{{$t('widget.tc.month')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 justify-content-start">
                                    <div class="pink-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.tc.reduction')
                                                }}:</span
                                            >
                                            {{ tariff.deposit | euroFull }} {{
                                            $t('euro') }}/{{
                                            $t('widget.tc.month')}}
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.tc.total.costs')
                                                }}:</span
                                            >
                                            {{ tariff.totalBasePriceBonusBrutto
                                            | euroFull }}
                                            {{$t('euro')}}/{{$t('year')}}
                                        </div>
                                        <div>
                                            <span class="fw-normal">
                                                {{ $t('widget.tc.usage') }}
                                            </span>
                                            {{ tariff.usage }} {{
                                            $t('unit.electricity') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="pink-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.td.runtime')
                                                }}:</span
                                            >
                                            {{
                                            $tc(tariff.contractInitialDurationType,
                                            parseInt(tariff.contractInitialDuration))}}
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{
                                                $t('widget.tc.fixedPrice.until')}}:</span
                                            >
                                            {{ tariff.fixedPriceDate | date
                                            }}<sup>*</sup>
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{$t('widget.tc.cancellation.period')}}:</span
                                            >
                                            {{
                                            $tc(tariff.cancellationPeriodType,
                                            tariff.cancellationPeriod)}}
                                            {{$t('widget.tc.cancellation.period.postfix')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-7 col-12">
                                <div class="form-check mt-4">
                                    <label
                                        :class="[
                                            'form-check-label',
                                            displayElement('branded-checkbox')
                                                ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                : '',
                                            tariff.confirmedError ? 'is-invalid text-danger' : ''
                                        ]"
                                        :for="'cancellation-confirmation-' + tariff.productCode"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :id="'cancellation-confirmation-' + tariff.productCode"
                                            @change="updateCheckbox(tariff)"
                                        />
                                        <span class="form-check__text">
                                            {{ $t('widget.tc.confirmation.1') }}
                                            <a
                                                href="https://www.prokon.net/strom-gas/kundenservice/prokon-strom-agb"
                                                target="_blank"
                                                >{{
                                                $t('widget.tc.confirmation.2')}}</a
                                            >
                                            {{ $t('widget.tc.confirmation.3')}}
                                            <a
                                                href="https://www.prokon.net/files/Widerrufsbelehrung-Prokon-Strom_online.pdf"
                                                target="_blank"
                                                >{{
                                                $t('widget.tc.confirmation.4')
                                                }}</a
                                            >{{ $t('widget.tc.confirmation.5')
                                            }}
                                            <a
                                                href="https://www.prokon.net/datenschutz-strom-gas"
                                                target="_blank"
                                                >{{
                                                $t('widget.tc.confirmation.6')
                                                }}</a
                                            >{{ $t('widget.tc.confirmation.7')
                                            }}
                                        </span>
                                        <i></i>
                                        <span></span>
                                    </label>
                                </div>
                                <div class="text-end mt-2">
                                    <button
                                        v-if="(!showSuccessChangeTariff)"
                                        class="btn btn-primary mt-4"
                                        @click="submit(tariff)"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.tc.confirm') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="showSuccessChangeTariff">
                            <div class="alert alert-success mt-2">
                                {{ $t('widget.tc.success.message') }}
                            </div>
                        </div>
                        <messages-block
                            :errors="errorInformation"
                        ></messages-block>
                        <div class="row bottom-block">
                            <div>
                                <span>{{ $t('widget.tc.first.star') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
