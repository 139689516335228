import { CoreComponent } from '@/components/pages/auth/password/CoreComponent';
import Component from 'vue-class-component';
import WithRender from './password-forgot-page.html'
import { ForgotPasswordWidget } from '@/components/widgets/forgot-password-widget';

@WithRender
@Component({
    components: {
        'password-forgot-widget': ForgotPasswordWidget,
    }
})
export class PasswordForgotPage extends CoreComponent{}
