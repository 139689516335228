<ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow social-links">
    <li class="m-menu__item">
        <a
            class="m-menu__link"
            href="https://www.facebook.com/BerlinerStadtwerke"
            target="_blank"
        >
            <i class="m-menu__link-icon fab fa-facebook-f"></i>
        </a>
    </li>
    <li class="m-menu__item">
        <a
            class="m-menu__link"
            href="https://twitter.com/stadtwerkberlin"
            target="_blank"
        >
            <i class="m-menu__link-icon fab fa-twitter"></i>
        </a>
    </li>
    <li class="m-menu__item">
        <a
            class="m-menu__link"
            href="https://www.instagram.com/berliner_stadtwerke/"
            target="_blank"
        >
            <i class="m-menu__link-icon fab fa-instagram"></i>
        </a>
    </li>
    <li class="m-menu__item">
        <a
            class="m-menu__link"
            href="https://www.youtube.com/channel/UCKcfrCZhmxGIc3FPspLto_g"
            target="_blank"
        >
            <i class="m-menu__link-icon fab fa-youtube"></i>
        </a>
    </li>
    <li class="m-menu__item">
        <a
            class="m-menu__link"
            href="https://www.linkedin.com/company/berliner-stadtwerke/"
            target="_blank"
        >
            <i class="m-menu__link-icon fab fa-linkedin"></i>
        </a>
    </li>
</ul>
