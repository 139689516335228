<script>
import { ContentLoader } from 'vue-content-loader';

export default {
    components: {
        ContentLoader,
    },

    name: 'image-modal',

    props: {
        image: String,
        loaded: Boolean,
    },

    data: () => ({
        modalVisible: false,
    }),
};
</script>

<template>
    <div class="preview-doc" v-if="loaded">
        <div class="preview-button" @click="modalVisible = true" v-if="image">
            <span>{{ $t('widget.ts.viewUploadedDocument') }}</span>
            <i class="fas fa-eye view-document"></i>
        </div>
        <div class="preview-button not-found" v-else>
            <span>{{ $t('widget.ts.noDocumentFound') }}</span>
        </div>

        <div
            class="modal"
            v-if="modalVisible"
            @click.self="modalVisible = false"
        >
            <i
                class="far fa-times close-modal"
                @click="modalVisible = false"
            ></i>

            <img :src="image" :alt="image" />
        </div>
    </div>
    <content-loader
        v-else
        class="load-preview"
        :width="155"
        :height="23"
    ></content-loader>
</template>

<style lang="scss" scoped>
.preview-doc,
.load-preview {
}

.preview-doc {
    .preview-button {
        background: #575962;
        border-radius: 4px;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.not-found {
            cursor: not-allowed;
        }

        span,
        .view-document {
            color: #fff;
            font-size: 14px;
        }

        span {
            margin-bottom: 2px;
        }

        .view-document {
            margin-left: 8px;
        }
    }

    .modal {
        position: fixed;
        inset: 0 0 0 0;
        z-index: 999999;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;

        .close-modal {
            align-self: flex-end;
            margin: 24px;
            transition: all 0.25s ease;

            &:hover {
                cursor: pointer;
                transform: scale(1.25);
            }
        }

        img {
            max-width: 100%;
            margin: auto;
            cursor: default;
        }
    }
}
</style>
