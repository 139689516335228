import { AxiosResponse } from 'axios';
import AuthProvider from '@/providers/AuthProvider';
import WaermeenergieAuth from '@/interfaces/WaermeenergieAuth';

export default class AuthWaermeenergieProvider
    extends AuthProvider
    implements WaermeenergieAuth
{
    constructor() {
        super();
        this.http.interceptors.response.use(
            (response) => response,
            (error) => error.response
        );
    }

    public async validateUsername(authUserName): Promise<AxiosResponse> {
        return await this.http.post('/validate/username', {
            authUserName,
        });
    }

    public async register(data): Promise<AxiosResponse> {
        return await this.http.post('/register', data);
    }

    public async deleteAccount(data): Promise<AxiosResponse> {
        return await this.http.post('/account/delete', data, {
            headers: {
                Accept: 'application/json',
                'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async forgotUsername(
        data: Record<any, any>
    ): Promise<AxiosResponse> {
        return await this.http.post('/forgot/username', data);
    }

    public async forgotPassword(
        data: Record<any, any>
    ): Promise<AxiosResponse> {
        return await this.http.post('/forgot/password', data);
    }

    public async checkToken(data: Record<any, any>): Promise<AxiosResponse> {
        return await this.http.post('/validate/token', data);
    }

    public async resetPassword(data: Record<any, any>): Promise<AxiosResponse> {
        return await this.http.post('/validate/reset', data);
    }
}
