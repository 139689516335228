<div class="col-12 mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="box-title static-title"
                        >{{ $t('widget.fpo.title') }}</span
                    >
                    <div class="mobile-modal__wrap">
                        <table
                            :class="['table table-striped', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                            id="invoiceList-content"
                        >
                            <tbody>
                                <tr>
                                    <th
                                        :data-name="$t('widget.fpo.activeFrom')"
                                    >
                                        <span
                                            >{{ $t('widget.fpo.activeFrom')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.fpo.activeUntil')"
                                    >
                                        <span
                                            >{{ $t('widget.fpo.activeUntil')
                                            }}</span
                                        >
                                    </th>
                                    <th :data-name="$t('widget.fpo.iban')">
                                        <span>{{ $t('widget.fpo.iban') }}</span>
                                    </th>
                                    <th :data-name="$t('widget.fpo.bank')">
                                        <span>{{ $t('widget.fpo.bank') }}</span>
                                    </th>
                                    <th :data-name="$t('widget.fpo.bic')">
                                        <span>{{ $t('widget.fpo.bic') }}</span>
                                    </th>
                                    <th
                                        :data-name="$t('widget.fpo.SepaMandate')"
                                    >
                                        <span
                                            >{{ $t('widget.fpo.SepaMandate')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.fpo.alternativeAccountHolder')"
                                    >
                                        <span
                                            >{{
                                            $t('widget.fpo.alternativeAccountHolder')
                                            }}</span
                                        >
                                    </th>
                                </tr>
                                <tr v-for="payment in futurePaymentList">
                                    <td
                                        :data-name="$t('widget.fpo.activeFrom')"
                                    >
                                        {{ payment.activeFrom | date }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.fpo.activeUntil')"
                                    >
                                        {{ payment.activeUntil | date }}
                                    </td>
                                    <td :data-name="$t('widget.fpo.iban')">
                                        {{ payment.iban }}
                                    </td>
                                    <td :data-name="$t('widget.fpo.bank')">
                                        {{ payment.bank }}
                                    </td>
                                    <td :data-name="$t('widget.fpo.bic')">
                                        {{ payment.bic }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.fpo.SepaMandate')"
                                    >
                                        {{ getSepaMandate(payment) }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.fpo.alternativeAccountHolder')"
                                    >
                                        {{ payment.alternativeAccountHolder }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
