import { EnergyCompoundWidgetSchweizstrom } from '@/components/widgets/energy-compound-widget/custom/schweizstrom';
import { EnergyCompoundWidget } from '@/components/widgets/energy-compound-widget/energy-compound-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = EnergyCompoundWidgetSchweizstrom;
} else {
    widget = EnergyCompoundWidget;
}
export { widget as EnergyCompoundWidget };
