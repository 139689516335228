import { TariffDetailsWidgetEts } from '@/components/widgets/tariff-details-widget/custom/ets';
import { TariffDetailsWidgetSchweizstrom } from '@/components/widgets/tariff-details-widget/custom/schweizstrom';
import { TariffDetailsWidget } from '@/components/widgets/tariff-details-widget/tariff-details-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = TariffDetailsWidgetEts;
} else if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = TariffDetailsWidgetSchweizstrom;
} else {
    widget = TariffDetailsWidget;
}
export { widget as TariffDetailsWidget };
