import Vue from 'vue';
import IocContainer from '../container/IocContainer';
import SERVICES from '../container/Services';

import Auth from '../interfaces/Auth';

import store from '@/store/Store';
import { Route } from 'vue-router';

export default {
    // For all
    guest(to: any, from: any, next: any): void {
        document.title = process.env.VUE_APP_TITLE + ' - ' + to.meta.title;
        store.dispatch('settings/load').then(() => {
            const isMaintenanceMode =
                store.getters['settings/getState']().isMaintenanceMode;
            if (isMaintenanceMode && to.name !== 'maintenance') {
                next('/maintenance');
            } else {
                if (to.name === 'maintenance' && !isMaintenanceMode) {
                    const auth = IocContainer.get<Auth>(SERVICES.AUTH);
                    if (auth.isAuthenticated()) {
                        next('/dashboard');
                    } else {
                        next('/');
                    }
                } else {
                    next();
                }
            }
        });
    },

    // Auth guard
    auth(to: any, from: any, next: any): void {
        document.title = process.env.VUE_APP_TITLE + ' - ' + to.meta.title;
        const auth = IocContainer.get<Auth>(SERVICES.AUTH);

        if (to.query.token) {
            auth.authenticated(to.query.token);
            store.dispatch('auth/set', true);
        }

        store.dispatch('settings/load').then(() => {
            const isMaintenanceMode =
                store.getters['settings/getState']().isMaintenanceMode;
            if (isMaintenanceMode) {
                next('/maintenance');
            } else {
                if (auth.isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            }
        });
    },

    // Admin guard
    admin(to: any, from: any, next: any): void {
        document.title = process.env.VUE_APP_TITLE + ' - ' + to.meta.title;
        const auth = IocContainer.get<Auth>(SERVICES.AUTH);
        auth.checkAdminToken({ admin_token: to.query.auth }).then(
            (response) => {
                if (response.data.success) {
                    Vue.$cookies.set(
                        'SUPER-ADMIN-TOKEN',
                        response.data.token,
                        0
                    );
                    next('/dashboard');
                } else {
                    next('/');
                }
            }
        );
    },
};
