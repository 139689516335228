import { DepositWidget } from '@/components/widgets/deposit-widget/deposit-widget';
import { DepositWidgetEts } from '@/components/widgets/deposit-widget/custom/ets';
import { DepositWidgetSchweizstrom } from '@/components/widgets/deposit-widget/custom/schweizstrom';
import { DepositWidgetFullClickable } from '@/components/widgets/deposit-widget/custom/full-clickable';

const widgetTypes = {
    ets: DepositWidgetEts,
    schweizstrom: DepositWidgetSchweizstrom,
    elli: DepositWidgetFullClickable,
};

const widget =
    widgetTypes[process.env.VUE_APP_ENVIRONMENT as string] ?? DepositWidget;

export { widget as DepositWidget };
