<header id="m_header" class="m-grid__item m-header">
    <div class="m-container m-container--fluid">
        <div class="m-stack m-stack--ver m-stack--desktop w-100">
            <!-- BEGIN: Brand -->
            <div :class="brandAuth" class="m-brand m-brand--skin-dark">
                <div
                    class="
                        m-stack m-stack--ver m-stack--general
                        d-flex
                        flex-column
                    "
                >
                    <div
                        class="
                            m-stack__item m-stack__item--top
                            m-brand__logo
                            ms-2
                        "
                    >
                        <img alt="" :src="headerLogo('white', 'svg')" />
                    </div>
                    <div
                        class="
                            m-stack__item m-stack__item--bottom
                            d-flex
                            flex-row
                            align-items-center
                        "
                    >
                        <button
                            @click="$emit('toggleTab', 1)"
                            class="m-stack-menu__button"
                            :class="[currentTab === 1 ? 'm-stack-menu__button_active' : '']"
                        >
                            {{$t('header.link-1')}}
                        </button>
                        <button
                            @click="$emit('toggleTab', 2)"
                            class="m-stack-menu__button"
                            :class="[currentTab === 2 ? 'm-stack-menu__button_active' : '']"
                        >
                            {{$t('header.link-2')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
