<div class="col-12 tariff-change-widget" id="donation">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <i class="fas fa-file-export tariff-change-widget-icon"></i>
                    <span class="tariff-change-title static-title">
                        {{ $t('widget.tdwv2.title') }}
                    </span>
                    <div class="mobile-modal__wrap">
                        <div class="row mt-3">
                            <div class="col-sm-4 col-12 tariff-change-left">
                                <div class="currentTariff-title">
                                    <img
                                        data-test="tariff-widget-product-img"
                                        class="img-max-width-100"
                                        v-if="productImage"
                                        :src="productImage"
                                        style="
                                            width: 14em;
                                            margin-top: 0.4em;
                                            margin-bottom: 0.4em;
                                        "
                                    />
                                </div>
                                <div class="row mt-3">
                                    <div class="green-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.to.basePrice')
                                                }}:</span
                                            >
                                            {{ 9.00 | euro}}
                                            {{$t('euro')}}/{{$t('widget.tc.month')}}<sup
                                                >*</sup
                                            >
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.to.workingPrice')
                                                }}:</span
                                            >
                                            {{ 42.00 | euro}}
                                            {{$t('unit.centperelectricity')}}<sup
                                                >*</sup
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="green-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{
                                                $t('widget.tc.fixedPrice.until')}}:</span
                                            >
                                            31.12.2024<sup>**</sup>
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.td.min.term')
                                                }}:</span
                                            >
                                            31.12.2024 (18 Monate)
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{$t('widget.tc.cancellation.period')}}:</span
                                            >
                                            {{
                                            $t('widget.dw.cancellation.period.text.v2')
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8 col-12">
                                <div>{{ $t('widget.dw.text1') }}</div>
                                <div class="form-check mt-4">
                                    <label
                                        :class="[
                                            'form-check-label',
                                            displayElement('branded-checkbox')
                                                ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                : '',
                                            confirmedError ? 'is-invalid text-danger' : ''
                                        ]"
                                        :for="'cancellation-confirmation-donation-widget'"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :id="'cancellation-confirmation-donation-widget'"
                                            v-model="confirmation"
                                            :disabled="donationApplied"
                                        />
                                        {{ $t('widget.tc.confirmation.1') }}
                                        <a
                                            href="https://berlinerstadtwerke.de/wp-content/uploads/dokumente/Berliner-Stadtwerke_AGB.pdf"
                                            target="_blank"
                                            >{{
                                            $t('widget.tc.confirmation.2')}}</a
                                        >
                                        {{ $t('widget.tc.confirmation.3')}}
                                        <a
                                            href="https://berlinerstadtwerke.de/app/dokumente/Berliner-Stadtwerke_Widerrufsformular.pdf"
                                            target="_blank"
                                            >{{ $t('widget.tc.confirmation.4')
                                            }}</a
                                        >{{ $t('widget.tc.confirmation.5') }}
                                        <a
                                            href="https://berlinerstadtwerke.de/datenschutz/"
                                            >{{ $t('widget.tc.confirmation.6')
                                            }}</a
                                        >{{ $t('widget.tc.confirmation.7') }}
                                        <i></i>
                                        <span></span>
                                    </label>
                                </div>
                                <div
                                    class="text-end mt-2"
                                    v-if="!currentTariff.contract.donationApplied"
                                >
                                    <button
                                        v-if="(!showSuccessDonation)"
                                        class="btn btn-primary mt-4"
                                        @click="submit()"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.tc.confirm') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="showSuccessDonation || currentTariff.contract.donationApplied"
                        >
                            <div class="alert alert-success mt-2">
                                {{ $t('widget.dw.v2.success.text') }}
                            </div>
                        </div>
                        <messages-block
                            :errors="errorInformation"
                        ></messages-block>
                        <div class="row bottom-block">
                            <div>
                                <span>{{ $t('widget.dw.asterix1') }}</span>
                            </div>
                            <div>
                                <span>{{ $t('widget.dw.asterix2') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
