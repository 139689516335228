<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div
            @click="$emit('closeWidget')"
            v-if="!displayElement('hide-close-button')"
            class="fullwidthArea-close"
        >
            <i class="fa fa-times"></i>
        </div>
        <div id="customer-service-form-content">
            <i
                v-if="displayElement('show-service-form-icon')"
                class="fa fa-comment-alt serviceForm-icon"
            ></i>
            <span class="customerService-title static-title">
                {{ $t('widget.cs.title') }}
            </span>
        </div>
        <div class="mobile-modal__wrap">
            <div>{{ $t('widget.cs.sender') }}</div>
            <div class="row">
                <div class="col-12 col-sm-4 form-col">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            :value="customerDetails.firstname"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-4 form-col">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            :value="customerDetails.surname"
                            disabled
                        />
                    </div>
                </div>
                <div
                    class="col-12 col-sm-4 form-col"
                    v-if="!displayElement('hide-email-field')"
                >
                    <div class="form-group">
                        <input
                            type="email"
                            class="form-control"
                            v-model="details.email"
                            :placeholder="$t('widget.cs.email')"
                            required
                        />
                    </div>
                </div>
                <div
                    class="col-12 col-sm-4 form-col"
                    :hidden="displayElement('hide-contract-id-field')"
                >
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            :value="contractId"
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4">
                    <div class="form-group">
                        <label for="choose-topic">
                            {{ $t('widget.cs.topic.choose') }}
                        </label>
                        <treeselect
                            v-model="details.categoryId"
                            id="choose-topic"
                            :options="categories"
                            :disableBranchNodes="true"
                            :maxHeight="200"
                            :placeholder="$t('widget.cs.topic.choose.placeholder')"
                            :noOptionsText="$t('widget.cs.topic.choose.no.options')"
                            @select="handleSelectCategory"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-8">
                    <div class="form-group">
                        <label for="message">
                            {{ $t('widget.cs.message') }}
                        </label>
                        <textarea
                            id="message"
                            type="text"
                            class="form-control service-form-textarea"
                            rows="6"
                            v-model="details.text"
                        ></textarea>
                    </div>
                </div>
                <div class="col-12 col-sm-4 customer-service-form-button">
                    <div
                        class="form-group"
                        :class="[displayElement('show-privacy-notes') ? 'mb-0': '']"
                    >
                        <label for="files-list">
                            {{ $t('widget.cs.files') }}
                        </label>
                        <input
                            id="files-list"
                            type="file"
                            class="form-control"
                            accept="text/csv,text/plain,application/pdf,application/vnd.oasis.opendocument.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,image/gif,image/jpeg,image/png"
                            multiple
                            ref="fileList"
                            @change="processFile($event)"
                        />
                    </div>
                    <div
                        class="mb-3"
                        v-if="displayElement('show-privacy-notes')"
                    >
                        <span
                            >{{ $t('widget.cs.privacy.notes') }}
                            <a
                                href="https://www.eoptimum-home.de/datenschutz"
                                target="_blank"
                                >{{ $t('widget.cs.privacy.notes.postifx') }}</a
                            >.</span
                        >
                    </div>
                    <div class="customer-service-form-button-wrapper">
                        <button
                            :class="{'float-end': displayElement('submit-align-right')}"
                            class="btn btn-primary d-block"
                            @click="submit"
                            :disabled="submitPending"
                        >
                            {{ $t('widget.cs.submit.message') }}
                        </button>
                    </div>
                </div>
                <div class="col-12">
                    <messages-block
                        :success="successInformation"
                        :errors="errorInformation"
                    ></messages-block>
                </div>
            </div>
        </div>
    </div>
</div>
