<div
    class="col-12 mobile-modal__inner account-transactions"
    data-test="account-transactions-widget"
>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div>
                <i
                    class="fa fa-chart-line readingHistory-icon pt-2"
                    v-if="displayElement('chart-line-icon')"
                ></i>
                <span class="box-title static-title"
                    >{{ $t('widget.at.title') }}</span
                >
                <div class="mobile-modal__wrap">
                    <div class="box-btns">
                        <div
                            :class="[displayElement('btn-group-class') ? 'btn-group m-btn-group' : '']"
                        >
                            <button
                                data-test="account-transactions-widget-requirements-button"
                                :class="[
                  'btn',
                  this.showTransactionsTable.receivables ? 'active' : '',
                  displayElement('btn-secondary-class') ? 'btn-secondary btn-in-group' : 'btn-link'
                ]"
                                @click="toggleTransactionsTable('receivables')"
                            >
                                <span>{{ $t('widget.at.requirements') }}</span>
                            </button>
                            <button
                                data-test="account-transactions-widget-payments-button"
                                :class="[
                  'btn',
                  this.showTransactionsTable.payments ? 'active' : '',
                  displayElement('btn-secondary-class') ? 'btn-secondary btn-in-group' : 'btn-link'
                ]"
                                @click="toggleTransactionsTable('payments')"
                            >
                                <span
                                    >{{ $t('widget.at.payments') }}</span
                                ></button
                            ><br /><br />
                        </div>
                    </div>
                    <div v-if="noticeText" class="notice-text mb-3">
                        <span>{{ noticeText }}</span>
                    </div>
                    <div v-if="this.showTransactionsTable.receivables">
                        <template
                            v-if="displayElement('hidden-table-name')"
                        ></template>
                        <div v-else>
                            <b>{{ $t('widget.at.requirements') }}</b>
                        </div>
                        <table
                            class="table table-striped"
                            id="accountTransactionsTable-receivables"
                            data-test="account-transactions-widget__table"
                        >
                            <tbody>
                                <tr v-if="displayElement('fixed-th')">
                                    <th style="width: 15%">
                                        {{ $t('widget.at.date') }}
                                    </th>
                                    <th
                                        :class="{'d-block': displayElement('show-table-header-xs')}"
                                        style="width: 25%"
                                    >
                                        {{ $t('widget.at.description') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.duedate') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.total') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.balanced') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.open') }}
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th>{{ $t('widget.at.date') }}</th>
                                    <th
                                        :class="{'d-block': displayElement('show-table-header-xs')}"
                                    >
                                        {{ $t('widget.at.description') }}
                                    </th>
                                    <th>{{ $t('widget.at.duedate') }}</th>
                                    <th>{{ $t('widget.at.total') }}</th>
                                    <th>{{ $t('widget.at.balanced') }}</th>
                                    <th>{{ $t('widget.at.open') }}</th>
                                </tr>
                                <tr
                                    v-for="receivable in receivableTransactions"
                                    :class="{ 'transactionOverdue': receivable.overdue, 'active': receivable.active }"
                                    :key="receivable.bookingNumber + Math.random()"
                                >
                                    <td data-name="datum">
                                        {{ receivable.createdAt | date }}
                                    </td>
                                    <td
                                        data-name="beschreibung"
                                        @click="activate(receivable)"
                                    >
                                        {{ receivable.notice }}
                                    </td>
                                    <td data-name="fälligkeit">
                                        {{ receivable.payableAt | date }}
                                    </td>
                                    <td data-name="gesamtbetrag">
                                        {{ receivable.grossAmount | euro }} {{
                                        $t('euro') }}
                                    </td>
                                    <td data-name="ausgeglichen">
                                        {{ receivable.fulfilled | euro }} {{
                                        $t('euro') }}
                                    </td>
                                    <td data-name="offen">
                                        {{ receivable.notFulfilled | euro }} {{
                                        $t('euro') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="this.showTransactionsTable.payments">
                        <template
                            v-if="displayElement('hidden-table-name')"
                        ></template>
                        <div v-else><b>{{ $t('widget.at.payments') }}</b></div>
                        <table
                            class="table table-striped"
                            id="accountTransactionsTable-payments"
                            data-test="account-transactions-widget__table"
                        >
                            <tbody>
                                <tr v-if="displayElement('fixed-th')">
                                    <th style="width: 15%">
                                        {{ $t('widget.at.date') }}
                                    </th>
                                    <th
                                        :class="{'d-block': displayElement('show-table-header-xs')}"
                                        style="width: 40%"
                                    >
                                        {{ $t('widget.at.description') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.totalamount') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.posted') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.remaining') }}
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th>{{ $t('widget.at.date') }}</th>
                                    <th
                                        :class="{'d-block': displayElement('show-table-header-xs')}"
                                    >
                                        {{ $t('widget.at.description') }}
                                    </th>
                                    <th>{{ $t('widget.at.totalamount') }}</th>
                                    <th>{{ $t('widget.at.posted') }}</th>
                                    <th>{{ $t('widget.at.remaining') }}</th>
                                </tr>
                                <tr
                                    v-for="payment in paymentTransactions"
                                    :class="{ 'active': payment.active }"
                                    :key="payment.bookingNumber + Math.random()"
                                >
                                    <td data-name="datum">
                                        {{ payment.createdAt | date }}
                                    </td>
                                    <td
                                        data-name="beschreibung"
                                        @click="activate(payment)"
                                    >
                                        {{ payment.notice }}
                                    </td>
                                    <td data-name="gesamtbetrag">
                                        {{ payment.grossAmount | euro }} {{
                                        $t('euro') }}
                                    </td>
                                    <td data-name="ausgeglichen">
                                        {{ payment.fulfilled | euro }} {{
                                        $t('euro') }}
                                    </td>
                                    <td data-name="offen">
                                        {{ payment.notFulfilled | euro }} {{
                                        $t('euro') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
