import CustomInput from './input';
import CustomLabel from './label';
import FormGroup from './form-group';
import FormWrapper from './form-wrapper';
import CustomDateTime from './custom-datetime-input';
import CustomRadio from './custom-radio';
import CustomVSelect from './custom-v-select';
import CustomCheckbox from './custom-checkbox';
import CustomSelect from './custom-select';

export const FormComponents = {
    CustomInput,
    CustomLabel,
    FormGroup,
    FormWrapper,
    CustomDateTime,
    CustomRadio,
    CustomVSelect,
    CustomCheckbox,
    CustomSelect,
};
