import { injectable } from 'inversify';
import axios, { AxiosResponse } from 'axios';
import PrototypeProvider from '@/providers/PrototypeProvider';
import Verify from '@/interfaces/Verify';

@injectable()
export default class VerifyProvider
    extends PrototypeProvider
    implements Verify
{
    constructor() {
        super();
        this.http = axios.create({
            baseURL: process.env.VUE_APP_API_URL + '/v1/',
            headers: {
                Accept: 'application/json',
                Authorization: this.getApiAuth(),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                'WD-PORTAL-ENVIRONMENT': this.getAppEnv(),
                'WD-OVERWRITE-POWERCLOUD-APIS':
                    process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true',
            },
        });

        this.http.interceptors.response.use(
            (response) => response,
            this.responseErrorHandler
        );
    }

    public async load(token: string): Promise<AxiosResponse> {
        return await this.http.post('/verification/load', {
            token,
        });
    }

    public async done(token: string): Promise<AxiosResponse> {
        return await this.http.post('/verification/request/done', {
            token,
        });
    }
}
