import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './auth-plus-password-strength-meter.html';
import { Watch } from 'vue-property-decorator';

import { MessagesBlock } from '@/components/snippets/messages';
import AuthPlus from '@/interfaces/AuthPlus';

@WithRender
@Component({
    props: {
        username: {
            type: String,
            default: '',
        },
        password: {
            type: String,
            default: '',
        },
    },
    components: {
        'messages-block': MessagesBlock,
    },
})
export class AuthPlusPasswordStrengthMeter extends Base {
    public errorInformation: Record<any, any> = [];
    protected settings: Partial<AuthPlus> = {};
    protected authPlusPassword = '';

    /**
     *
     * @protected
     */
    protected created() {
        this.settings = this.$store.state.settings;
    }

    @Watch('username')
    protected onUsernameChange() {
        if (this.settings.isAuthPlusActive && this.authPlusPassword) {
            this.errorInformation = [];
            this.checkExtendedPasswordRules(this.authPlusPassword);
        }
    }

    @Watch('password')
    protected onPasswordChange(newVal) {
        this.authPlusPassword = newVal;
        this.errorInformation = [];
        if (this.settings.isAuthPlusActive && newVal !== '') {
            this.checkExtendedPasswordRules(newVal);
        }
    }

    /**
     *
     * @param newVal
     * @protected
     */
    protected checkExtendedPasswordRules(newVal) {
        if (
            typeof this.settings.authPlusPasswordLength !== 'undefined' &&
            this.settings.authPlusPasswordLength !== null &&
            this.settings.authPlusPasswordLength > newVal.length
        ) {
            this.errorInformation.push({
                key: 'auth.plus.error.password.length',
                message: this.$t(
                    'auth.plus.error.password.length'
                ).toLocaleString(),
            });
        }

        // const pattern = new RegExp(
        //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
        // );

        if (
            typeof this.settings.authPlusCountSpecialCharacters !==
                'undefined' &&
            this.settings.authPlusCountSpecialCharacters !== null &&
            this.settings.authPlusCountSpecialCharacters > 0 &&
            typeof this.settings.authPlusListSpecialCharacters === 'string' &&
            this.settings.authPlusListSpecialCharacters !== ''
        ) {
            const specialCharacters =
                this.settings.authPlusListSpecialCharacters.replace(
                    /[[\]\\\s]/g,
                    '\\$&'
                );
            const regexp = new RegExp(`[${specialCharacters}]`, 'g');

            if (
                (newVal.match(regexp) || []).length <
                this.settings.authPlusCountSpecialCharacters
            ) {
                this.errorInformation.push({
                    key: 'auth.plus.error.password.count.special.characters',
                    message: this.$t(
                        'auth.plus.error.password.count.special.characters'
                    ).toLocaleString(),
                });
            }
        }

        if (
            typeof this.settings.authPlusCountUppercaseCharacters !==
                'undefined' &&
            this.settings.authPlusCountUppercaseCharacters !== null &&
            this.settings.authPlusCountUppercaseCharacters > 0 &&
            this.settings.authPlusCountUppercaseCharacters >
                newVal.replace(/[^A-Z]/g, '').length
        ) {
            this.errorInformation.push({
                key: 'auth.plus.error.password.count.upper.characters',
                message: this.$t(
                    'auth.plus.error.password.count.upper.characters'
                ).toLocaleString(),
            });
        }

        if (
            typeof this.settings.authPlusCountLowercaseCharacters !==
                'undefined' &&
            this.settings.authPlusCountLowercaseCharacters !== null &&
            this.settings.authPlusCountLowercaseCharacters > 0 &&
            this.settings.authPlusCountLowercaseCharacters >
                newVal.replace(/[^a-z]/g, '').length
        ) {
            this.errorInformation.push({
                key: 'auth.plus.error.password.count.lower.characters',
                message: this.$t(
                    'auth.plus.error.password.count.lower.characters'
                ).toLocaleString(),
            });
        }

        if (
            typeof this.settings.authPlusCountNumbers !== 'undefined' &&
            this.settings.authPlusCountNumbers !== null &&
            this.settings.authPlusCountNumbers > 0 &&
            this.settings.authPlusCountNumbers >
                newVal.replace(/[^\d]/g, '').length
        ) {
            this.errorInformation.push({
                key: 'auth.plus.error.password.count.numbers',
                message: this.$t(
                    'auth.plus.error.password.count.numbers'
                ).toLocaleString(),
            });
        }

        this.checkPassportInBlacklist(newVal);
        this.checkUsernameInPassport(newVal);
    }

    protected checkPassportInBlacklist(newVal) {
        if (
            typeof this.settings.authPlusBlacklistPasswords === 'string' &&
            this.settings.authPlusListSpecialCharacters !== ''
        ) {
            const blacklist =
                this.settings.authPlusBlacklistPasswords.split(',');
            if (typeof blacklist === 'object') {
                let isPassportInBlacklist = false;
                blacklist.forEach((value) => {
                    const regexp = new RegExp(`${value}`);
                    const match = newVal.match(regexp);
                    if (match !== null && match.length > 0) {
                        isPassportInBlacklist = true;
                    }
                });
                if (isPassportInBlacklist) {
                    this.errorInformation.push({
                        key: 'auth.plus.error.password.in.blacklist',
                        message: this.$t(
                            'auth.plus.error.password.in.blacklist'
                        ).toLocaleString(),
                    });
                }
            }
        }
    }

    protected checkUsernameInPassport(newVal) {
        if (
            typeof this.settings.isAuthPlusDenyUsernamePasswords ===
                'boolean' &&
            this.settings.isAuthPlusDenyUsernamePasswords
        ) {
            if (
                typeof this.$props.username === 'string' &&
                this.$props.username !== ''
            ) {
                const regexp = new RegExp(`${this.$props.username}`);
                const match = newVal.match(regexp);
                if (match !== null && match.length > 0) {
                    this.errorInformation.push({
                        key: 'auth.plus.error.password.deny.username',
                        message: this.$t(
                            'auth.plus.error.password.deny.username'
                        ).toLocaleString(),
                    });
                }
            }
        }
    }
}
