import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './ewii.html';
import { Header } from '@/components/template/header';

@WithRender
@Component({
    props: { currentTab: Number },
})
export class EwiiHeader extends Header {
    get isNotAuthPage(): boolean {
        return this.$route.path !== '/' && this.$route.path !== '/register';
    }

    get brandAuth(): Record<any, any> {
        return { 'm-brand--auth': !this.isNotAuthPage };
    }
}
