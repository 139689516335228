import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './tips-widget.html';

@WithRender
@Component({})
export class AdvizzoTipsWidget extends vue {
    public mounted(): void {
        // @ts-ignore
        document.getElementById('tips_widget').innerHTML = Placeholder.tips;

        const tipsData = [
            {
                id: 0,
                masterId: 48,
                type: 'ongoing',
                title: 'Turn down the heat.',
                tweet: 'Heating water uses a lot of energy, try turning down the overall temperature of your water heater to save today.',
                explanation:
                    'Heating water can account for around 15% of the energy consumed in your home. Most manufacturers set the water heater thermostats at 140ºF (60ºC), but you usually only require them to be set at 120ºF (49ºC).\n\nBy turning down the temperature you not only make significant savings to your energy consumption, you also slowdown mineral build up and corrosion in your water heater and pipes, as well as reducing the risk of accidentally scalding yourself.\n\nBe sure to test different temperatures using the tap furthest away from the water heater to find the ideal low temperature for you.\n\nCheck the manual for your water heater to find out how to turn down the setting, or speak to your building manager about doing it for you.',
                category: 'Infrastructure/Whole house',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_down_the_heat-48-305_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_down_the_heat-48-305_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_down_the_heat-48-305_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_down_the_heat-48-305_1024x648.jpeg',
                },
            },
            {
                id: 1,
                masterId: 49,
                type: 'ongoing',
                title: 'Say no to food waste',
                tweet: 'Food waste negatively impacts the planet and your budget',
                explanation:
                    "In France, food waste amounts to 20 kg per person, per year, including 7 kg of non-eaten food stuff still in their packaging. This waste represents unnecessary use of natural resources such as agricultural land and water, and generates avoidable greenhouse gas and waste.\n\nFood waste also impacts a household's budget: it is estimated that the cost of food waste is between €100 and €160 per person per year (source: ADEME), which makes a total of €12 to 20 millions of food waste in France per year.",
                category: 'Kitchen',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-say_no_to_food_waste-49-307_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-say_no_to_food_waste-49-307_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-say_no_to_food_waste-49-307_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-say_no_to_food_waste-49-307_1024x648.jpeg',
                },
            },
            {
                id: 2,
                masterId: 50,
                type: 'ongoing',
                title: 'Cold and refreshing.',
                tweet: 'Try having a cold shower to invigorate yourself in the morning while saving energy',
                explanation:
                    'Cold showers have many benefits including reducing muscle soreness, treat depression symptoms, improving your skin by reducing loss of oils and many more. By having cold showers you also reduce the energy consumption by reducing the amount of water needing to be heated up.\n\nThere are two ways to introduce cold showers to your life. Either start off with a warm shower and slowly reduce the temperature or have a warm shower then end it with a dramatic switch to cold for a few seconds before you get out.',
                category: 'Bathroom',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cold_and_refreshing-50-386_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cold_and_refreshing-50-386_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cold_and_refreshing-50-386_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cold_and_refreshing-50-386_1024x648.jpeg',
                },
            },
            {
                id: 3,
                masterId: 52,
                type: 'ongoing',
                title: 'Energy efficient evaporation.',
                tweet: 'Turn off the heat-dry setting on your dishwasher and let your dishes just air dry',
                explanation:
                    'Using a dishwasher saves both energy and water. You can save even more energy by turning off the heat-dry setting on your dishwasher that produces heat from energy to dry your dishes. Some dishwasher models even have an air-dry setting. If your dishwasher does not, simply open the door by a small crack to allow the heat to retain inside while allowing the moisture to escape.',
                category: 'Kitchen',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-energy_efficient_evaporation-52-313_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-energy_efficient_evaporation-52-313_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-energy_efficient_evaporation-52-313_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-energy_efficient_evaporation-52-313_1024x648.jpeg',
                },
            },
            {
                id: 4,
                masterId: 80,
                type: 'ongoing',
                title: 'Replace old appliances',
                tweet: 'Many old appliances can be energy inefficient, consider replacing them when possible',
                explanation:
                    'Whether it is your washing machine, clothes dryer, cooker, fridge or TV, many appliances models that are over 10 years old are often highly inefficient when compared to their newer alternatives. Modern appliances are typically designed with efficiency in mind; so to invest in new appliances is a quick way to start becoming more energy efficient in your home. Look for the energy efficiency label which is provided by the Emirates Authority for Standardization and Metrology (ESMA) on air conditioners, lamps, washing machines, and refrigerating appliances. This label helps you to recognise the most energy-efficient appliances available in the market. The labels are found on the product package, sales tag, or on the item itself. Buy the appliance with higher number of stars on the label.',
                category: 'Major Electrical Appliances Usage',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_old_appliances-80-372_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_old_appliances-80-372_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_old_appliances-80-372_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_old_appliances-80-372_1024x648.jpeg',
                },
            },
            {
                id: 5,
                masterId: 54,
                type: 'ongoing',
                title: 'Cool Washing.',
                tweet: 'Reducing the temperature your washing machine operates is an easy way to save electricity',
                explanation:
                    "You don't always need to wash your clothes on such high temperature settings. Challenge yourself to see if you can even tell the difference between clothes washed at 40ºC and those washed at 30ºC. Reducing the temperature by this much will save you a third of the energy used per cycle. Washing clothes at low temperatures will also reduce the risk of your clothes shrinking or the color running.",
                category: 'Bathroom',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cool_washing-54-317_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cool_washing-54-317_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cool_washing-54-317_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-cool_washing-54-317_1024x648.jpeg',
                },
            },
            {
                id: 6,
                masterId: 82,
                type: 'ongoing',
                title: 'Hang your laundry out to dry',
                tweet: 'Dryers consume a lot of electricity. Take advantage of the sunshine and hang your clothes to dry',
                explanation:
                    'Hang your laundry out to dry, this will help you reduce your electricity consumption up to 1,000 kWh every year. If outdoors hang drying is restricted by dusty conditions, or limited outdoor space, consider purchasing an indoor drying rack and placing it in a room with good ventilation. If you really must use a dryer, please be sure not to over or under load it',
                category: 'Major Electrical Appliances Usage',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-hang_your_laundry_out_to_dry-82-376_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-hang_your_laundry_out_to_dry-82-376_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-hang_your_laundry_out_to_dry-82-376_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-hang_your_laundry_out_to_dry-82-376_1024x648.jpeg',
                },
            },
            {
                id: 7,
                masterId: 83,
                type: 'ongoing',
                title: 'Avoid peak hours',
                tweet: 'Try to avoid using your appliances between 12:00 pm (midday) to 5:00 pm during summer to protect the environment',
                explanation:
                    'More electricity consumption during peak times in summer season can cause power plants to produce more electricity to meet demands. Producing more electricity at these times means more overall impact on the environment. Help reduce demand by not running your appliances and devices during peak times. Try to do your daily tasks that require high electricity before 12:00 pm (midday) or delay usage until after 5:00 pm.',
                category: 'Major Electrical Appliances Usage',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_peak_hours-83-378_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_peak_hours-83-378_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_peak_hours-83-378_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_peak_hours-83-378_1024x648.jpeg',
                },
            },
            {
                id: 8,
                masterId: 57,
                type: 'ongoing',
                title: 'Avoid frost build up.',
                tweet: 'Frost build-up in your freezer is bad!',
                explanation:
                    'Frost build-up in fridges and freezers make them run less efficiently, because the frost makes the compressors run harder, using more energy. It is always recommended to defrost (or manually-defrost) them when the ice build-up is 0.5 cm thick or more.',
                category: 'Kitchen',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_frost_build_up-57-323_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_frost_build_up-57-323_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_frost_build_up-57-323_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-avoid_frost_build_up-57-323_1024x648.jpeg',
                },
            },
            {
                id: 9,
                masterId: 58,
                type: 'event',
                title: 'Use solar powered water heaters',
                tweet: 'Install a solar water heaters on you roof if you can',
                explanation:
                    'Electric water heating is less efficient and wastes more energy than a solar water heater. Installing a solar water heater could reduce your water heating bill by 90% per month.\n\nThere are many professional companies that will deliver and install your solar water heater. The initial costs of the solar water heater and its payback period are both low making installation a great investment.\n\nSolar water heating systems requires very basic maintenance and have a long lifetime.',
                category: 'Infrastructure/Whole house',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_powered_water_heaters-58-325_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_powered_water_heaters-58-325_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_powered_water_heaters-58-325_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_powered_water_heaters-58-325_1024x648.jpeg',
                },
            },
            {
                id: 10,
                masterId: 86,
                type: 'ongoing',
                title: 'Focus with task lighting',
                tweet: 'Provide focused light only where needed. This saves you electricity by reducing the need for general lighting',
                explanation:
                    'Save electricity by lighting only where it is needed. This process could be used while working or cooking in your home. After some time, it will seem natural to task light the important areas of your home or villa. To help you get there, try leaving reminders to switch off the lights, that grab your attention next to your light switches and doorways. Get other household members involved and set goals as a family to light only areas where and when needed',
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-focus_with_task_lighting-86-384_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-focus_with_task_lighting-86-384_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-focus_with_task_lighting-86-384_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-focus_with_task_lighting-86-384_1024x648.jpeg',
                },
            },
            {
                id: 11,
                masterId: 87,
                type: 'ongoing',
                title: 'Check for cold air leaks',
                tweet: 'Windows and doors may leak cold air therefore wasting energy. Weather-strip these openings to keep the cold air inside',
                explanation:
                    'Windows and doors with gaps and openings leak cold air. This means that your air conditioner will have to work extra hard to maintain the ideal temperature in your home. The simplest way to detect leaks in your home is by placing your hands near areas where you think there are gaps. If this is not enough, another simple trick is to turn off your AC, then put a lit candle near areas with a potential leak. If the flame on the candle dances around, it means you have a leak. Weather-stripping is an easy and cost-effective way to reduce electricity costs and increase cooling in your home. If home improvements do not fall under your control, contact your building maintenance team to apply weather-stripping, especially in more difficult areas',
                category: 'Cooling',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-check_for_cold_air_leaks-87-387_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-check_for_cold_air_leaks-87-387_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-check_for_cold_air_leaks-87-387_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-check_for_cold_air_leaks-87-387_1024x648.jpeg',
                },
            },
            {
                id: 12,
                masterId: 61,
                type: 'ongoing',
                title: 'Feel the benefits of natural light',
                tweet: 'Take full advantage of the benefits of sunlight during the day',
                explanation:
                    'Increasing the amount of natural light that enters your home also reduces the need for artificial lighting.\n\nBlinds and shutters on your windows are useful for controlling sunlight without increasing the temperature inside your home.',
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light-61-331_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light-61-331_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light-61-331_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light-61-331_1024x648.jpeg',
                },
            },
            {
                id: 13,
                masterId: 89,
                type: 'ongoing',
                title: 'Adjust your TV settings',
                tweet: "Changing your TV's display settings can reduce its power use by 5 - 20% with no compromise on quality",
                explanation:
                    'New televisions are configured to look best on the showroom floor at a setting that consumes more electricity than what is required at your home. Changing your TV\'s display settings can reduce its power use by 5 - 20% without compromising picture quality. Use the "display" or "picture" menus on your TV. Adjusting the "contrast" and "brightness" settings will help reduce electricity use the most. Dimming the display can also extend the life of your television',
                category: 'Major Electrical Appliances Usage',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-adjust_your_tv_settings-89-391_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-adjust_your_tv_settings-89-391_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-adjust_your_tv_settings-89-391_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-adjust_your_tv_settings-89-391_1024x648.jpeg',
                },
            },
            {
                id: 14,
                masterId: 63,
                type: 'ongoing',
                title: 'Switch off rather than stand by.',
                tweet: "Devices continue to use power when they're in standby mode",
                explanation:
                    "It might not seem like it but even after turning off a device or putting it on standby mode, it continues to use electricity,for example a TV left on standby mode or even a phone charger left in the plug. This is because they require electricity to maintain standby mode or because of the way they are built leads to this continuous electricity leak. While it might not seem much at any given time, collectively this can account for up to 10% of your household's electricity consumption.\n\nThe solution to this problem is to simply switch off devices at the mains plug when not in use. If you have a cluster of devices plug all those devices into a single power strip/extension plug and turn off all your devices at once at that power strip's main switch. For example, most your entertainment devices such as TV, stereo/speakers, video game console and media players are usually located in one area of your living room. By plugging all these devices into a single power strip, you can easily ensure none of these devices keep draining energy by flipping a single off switch on the power strip.",
                category: 'Infrastructure/Whole house',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_off_rather_than_stand_by-63-335_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_off_rather_than_stand_by-63-335_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_off_rather_than_stand_by-63-335_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_off_rather_than_stand_by-63-335_1024x648.jpeg',
                },
            },
            {
                id: 15,
                masterId: 91,
                type: 'ongoing',
                title: 'Turn off the lights',
                tweet: 'Always remember to turn off the light before leaving a room',
                explanation:
                    "Lights left on in vacant rooms is 100% wasted and serves no purpose at all. Lighting accounts for almost from 10% to 20% of your household's electricity use, so wasted electricity here can make a big difference. To help you get there, try leaving reminders that grab your attention next to your light switches and doorways. Get everyone involved. Remind each other whenever someone forgets to turn off the light and thank them whenever they switch it off",
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_off_the_lights-91-395_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_off_the_lights-91-395_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_off_the_lights-91-395_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-turn_off_the_lights-91-395_1024x648.jpeg',
                },
            },
            {
                id: 16,
                masterId: 92,
                type: 'ongoing',
                title: 'Install Dimmers',
                tweet: 'Rooms with dim lighting can have a calming effect. It can also help reduce electricity consumption',
                explanation:
                    'The brightness of lights can affect our mood, productivity, sleep cycles and eyes. That is why it makes sense to have greater control of the brightness of lights in your home by installing dimmers. Lighting accounts for about 10% to 20% of your home’s electricity use. Using dimmers can also reduce the amount of electricity spent on lighting. Install dimmers in the rooms that you use most, such as the bedrooms, dining and living rooms. By using only the light you need, you can also extend the life of your bulbs. Look for dimmers that are compatible with your lights',
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-install_dimmers-92-398_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-install_dimmers-92-398_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-install_dimmers-92-398_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-install_dimmers-92-398_1024x648.jpeg',
                },
            },
            {
                id: 17,
                masterId: 66,
                type: 'event',
                title: 'Use energy efficient fridges',
                tweet: 'Energy efficient fridges keep food fresh while minimizing energy waste',
                explanation:
                    'If the seal on your fridge or freezer is not tight, your appliance could be leaking the cooled air it produces. You can repair yourself with a new seal following manufacturer instructions or get a handyman to do it for you. This repair saves energy and adds years of life to the fridge.\r\n\r\nSet the fridge temperature to 4°C to and the freezer temperature to -18°C.\r\n\r\nYour fridge is on 24 hours a day to keep your food fresh and as a result, is one of the largest energy consumers in your kitchen.\r\n\r\nTo keep your fridge running efficiently, clean the condenser coils regularly with a coil brush according to the directions in the user’s manual.\r\n\r\nLeave about 10 cm of space between the fridge and the wall for air circulation. Keep your fridge away from the oven, dishwasher, and direct sunlight to keep it from overworking.',
                category: 'Kitchen',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_energy_efficient_fridges-66-341_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_energy_efficient_fridges-66-341_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_energy_efficient_fridges-66-341_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_energy_efficient_fridges-66-341_1024x648.jpeg',
                },
            },
            {
                id: 18,
                masterId: 67,
                type: 'ongoing',
                title: 'Wash on a full load',
                tweet: 'Wait till you have a full load of laundry before running the machine',
                explanation:
                    "If you can't wait for a full load, use the right water level to match the size of the load.\n\nWhen in the market for a new machine, consider a high efficiency model that will use an average of 30% less water and 40-50% less energy. Many modern washing machines detect automatically their load and automatically adjust the amount of water they use.\n\nIf you still have a manual selection option for half-loads, try to avoid using it as it typically uses as much water and energy as a normal full load. Try to find other items to throw in to make up a full load.",
                category: 'Bathroom',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-wash_on_a_full_load-67-410_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-wash_on_a_full_load-67-410_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-wash_on_a_full_load-67-410_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-wash_on_a_full_load-67-410_1024x648.jpeg',
                },
            },
            {
                id: 19,
                masterId: 68,
                type: 'ongoing',
                title: "You don't need power to have flow.",
                tweet: 'Install an efficient aerated shower head for your power shower',
                explanation:
                    'A power shower with an ordinary showerhead running for 10 minutes can use twice as much hot water as one with an efficient shower head. \n \nA low-flow shower head reduces water usage without compromising water pressure so your shower can be comfortable and less costly. In fact, it can pay for itself in energy and water savings in just a few months.\n\nThere are two types of low flow shower heads:\n• Non-aeration models produce a strong water jet by limiting the flow of water and forcing it through very small holes;\n• Aeration shower head mixes oxygen in the water to compensate for reduced water flow',
                category: 'Bathroom',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-you_dont_need_power_to_have_flow-68-366_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-you_dont_need_power_to_have_flow-68-366_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-you_dont_need_power_to_have_flow-68-366_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-you_dont_need_power_to_have_flow-68-366_1024x648.jpeg',
                },
            },
            {
                id: 20,
                masterId: 69,
                type: 'ongoing',
                title: 'Put a full load in your dishwasher.',
                tweet: 'Surprisingly a dishwasher can be more efficient than washing by hand, but only if it’s full',
                explanation:
                    'Only run your dishwasher when it is full to make the best use of water, energy and detergent.\n\nMost modern dishwashers have an eco-setting. Try to use this setting as it can help save even more water and energy. When purchasing a new appliance, look for one offering several different cycles. This will allow you to select more energy and water efficient cycles when heavy duty cleaning is not required.',
                category: 'Kitchen',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-put_a_full_load_in_your_dishwasher-69-347_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-put_a_full_load_in_your_dishwasher-69-347_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-put_a_full_load_in_your_dishwasher-69-347_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-put_a_full_load_in_your_dishwasher-69-347_1024x648.jpeg',
                },
            },
            {
                id: 21,
                masterId: 70,
                type: 'event',
                title: 'Switch to laptops',
                tweet: 'When buying a new computer try to always get a laptop. They consume up to 90% less energy than normal desktops',
                explanation:
                    "Laptops are built for efficiency, using up to 90% less energy than desktops. They draw less electricity when active, are unplugged more often, and can operate in low power mode to conserve battery life.  \n\nUnless you are going to be doing some heavy duty computing, modern laptops are able to help you accomplish all you need from a computer.\n\nThink about energy efficiency when you buy a new computer so your investment can be convenient and energy efficient. \n\nWhen you're not using your laptop, be sure to turn it off and unplug it to save even more energy.",
                category: 'Infrastructure/Whole house',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_to_laptops_-70-364_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_to_laptops_-70-364_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_to_laptops_-70-364_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switch_to_laptops_-70-364_1024x648.jpeg',
                },
            },
            {
                id: 22,
                masterId: 71,
                type: 'ongoing',
                title: 'Gone in 60 seconds.',
                tweet: 'Knocking a minute off your shower time will cut your energy bills',
                explanation:
                    "It's nice to be clean, but that should be easy enough to achieve in under four minutes. Get a shower timer to time how long you spend in the shower and figure out ways to help you reduce the times. You might find you spend more time than necessary thinking about the universe rather than washing.",
                category: 'Bathroom',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-gone_in_60_seconds-71-363_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-gone_in_60_seconds-71-363_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-gone_in_60_seconds-71-363_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-gone_in_60_seconds-71-363_1024x648.jpeg',
                },
            },
            {
                id: 23,
                masterId: 72,
                type: 'ongoing',
                title: 'Consider showers over baths.',
                tweet: 'There’s nothing wrong with a good long soak, but taking a quick shower is much more efficient',
                explanation:
                    'Ideally, you should try replacing baths with a 4-minute shower using an efficient shower head. This would only use around 32 liters of water and by reducing your hot water use, you also reduce your gas or electricity bill (depending on your hot water system).',
                category: 'Bathroom',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-consider_showers_over_baths-72-361_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-consider_showers_over_baths-72-361_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-consider_showers_over_baths-72-361_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-consider_showers_over_baths-72-361_1024x648.jpeg',
                },
            },
            {
                id: 24,
                masterId: 73,
                type: 'event',
                title: "Don't miss any alert!",
                tweet: 'We can alert you in case of cold weather, and help you monitor your consumption',
                explanation:
                    'You can receive daily SMS alerts to let you know when there is a cold weather forecast. This will help you adapting your consumption to avoid heating your home too much, and paying too much!',
                category: 'Infrastructure/Whole house',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-dont_miss_any_alert-73-360_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-dont_miss_any_alert-73-360_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-dont_miss_any_alert-73-360_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-dont_miss_any_alert-73-360_1024x648.jpeg',
                },
            },
            {
                id: 25,
                masterId: 79,
                type: 'ongoing',
                title: 'Smart-drying practice',
                tweet: 'Turn off the heat-dry setting on your dishwasher and let your dishes just air dry or wipe them with a cloth',
                explanation:
                    'You can save electricity consumption of your dishwasher by turning off the heat-dry setting on your dishwasher that produces heat from electricity to dry your dishes. Some dishwasher models even have an air-dry setting. If your dishwasher does not have this setting, simply open the door slightly in order to retain the heat inside while allowing the moisture to escape. Doing this can save up to 15% of the overall electricity used',
                category: 'Dishwasher',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-smartdrying_practice-79-370_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-smartdrying_practice-79-370_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-smartdrying_practice-79-370_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-smartdrying_practice-79-370_1024x648.jpeg',
                },
            },
            {
                id: 26,
                masterId: 81,
                type: 'ongoing',
                title: 'Replace lights with LEDs',
                tweet: 'Switch to light bulbs that last longer and are more energy efficient. Switch to LEDs',
                explanation:
                    'Lighting can account for around 10% to 20% of your household electricity consumption. LEDs are typically 80% more efficient than traditional incandescent light bulbs. Incandescent light bulbs also cause your rooms to heat up more. Only 15% of the electricity consumed goes towards producing light, while the rest gets converted to heat! LEDs bulbs last much longer than traditional light bulbs. Replacing your old light bulbs with energy efficient ones is a clever and easy way to save electricity',
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_lights_with_leds-81-374_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_lights_with_leds-81-374_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_lights_with_leds-81-374_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-replace_lights_with_leds-81-374_1024x648.jpeg',
                },
            },
            {
                id: 27,
                masterId: 84,
                type: 'ongoing',
                title: 'Use solar water heaters',
                tweet: 'Install a solar water heater on your roof to save electricity',
                explanation:
                    'Installing a solar water heater could reduce the water heating cost   by 90% per month. There are many professional companies that will deliver and install solar water heaters. The initial low cost of the solar water heater and its short payback period makes installation a great investment. Solar water heating systems requires very basic maintenance and have a long lifetime. Enjoy immediate electricity savings by using solar water heaters',
                category: 'Major Electrical Appliances Usage',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_water_heaters-84-380_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_water_heaters-84-380_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_water_heaters-84-380_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_solar_water_heaters-84-380_1024x648.jpeg',
                },
            },
            {
                id: 28,
                masterId: 85,
                type: 'ongoing',
                title: 'Control external lighting',
                tweet: 'It is important to keep outdoor lights on at times. A lighting control system save electricity, as lights work only when needed',
                explanation:
                    'When left on all night, outdoor lights consume significant amount of electricity. Lighting control systems help reduce electricity consumption such as photo-cell sensors that prevent lights from working during the day, and timers that switch the lights on and off as needed',
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-control_external_lighting-85-382_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-control_external_lighting-85-382_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-control_external_lighting-85-382_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-control_external_lighting-85-382_1024x648.jpeg',
                },
            },
            {
                id: 29,
                masterId: 88,
                type: 'ongoing',
                title: 'Feel the benefits of natural light during winter season',
                tweet: 'Sunlight has many great benefits. Take advantage of it by using natural sunlight during the day rather than artificial light in winter season',
                explanation:
                    'Increasing the amount of natural light that enters your home reduces the need for artificial lighting. Blinds and shutters on your windows are useful for controlling sunlight when needed without affecting the temperature inside your home',
                category: 'Lighting',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light_during_winter_season-88-389_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light_during_winter_season-88-389_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light_during_winter_season-88-389_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-feel_the_benefits_of_natural_light_during_winter_season-88-389_1024x648.jpeg',
                },
            },
            {
                id: 30,
                masterId: 90,
                type: 'ongoing',
                title: 'Switch-off rather than stand by',
                tweet: "Devices continue to use power when they're in standby mode. Switch-off appliances to avoid that",
                explanation:
                    "It might not seem like it but even after turning off a device or putting it on standby mode, it continues to use electricity, for example a TV left on standby mode or even a phone charger left in the plug. This is because they require electricity to maintain standby mode or because the way they are built leads to this continuous electricity leak. While it might not seem much at any given time, collectively this can account for up to 10% of your household's electricity consumption. The solution to this problem is to simply switch-off devices at the main plug when not in use",
                category: 'Major Electrical Appliances Usage',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switchoff_rather_than_stand_by-90-393_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switchoff_rather_than_stand_by-90-393_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switchoff_rather_than_stand_by-90-393_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-switchoff_rather_than_stand_by-90-393_1024x648.jpeg',
                },
            },
            {
                id: 31,
                masterId: 93,
                type: 'ongoing',
                title: 'Use curtains to reduce heat during summer season',
                tweet: 'Curtains and shades block out heat from sunlight that enters from windows, reducing the need for AC usage',
                explanation:
                    'A large portion of the heat built up in your home during the summer season is from the sunlight and heat that comes through your windows. By installing good window shades and curtains you can easily block out some of this heat, thereby reducing the need to cool down your home using AC and fans. When choosing curtains and shades, remember that white colours are better for reflecting the sunlight back out of the window; so be sure the curtain or shade is white on the side facing the window. Thick curtains are also better than thin ones. Pay special attention to windows that face south, east, and west since they typically receive the most sunlight in Dubai',
                category: 'Cooling',
                savingClass: [],
                addedToActionPlan: false,
                imageSizes: {
                    thumb: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_curtains_to_reduce_heat_during_summer_season-93-400_160x120.jpeg',
                    small: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_curtains_to_reduce_heat_during_summer_season-93-400_320x240.jpeg',
                    medium: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_curtains_to_reduce_heat_during_summer_season-93-400_640x480.jpeg',
                    large: 'https://adv-cdn.net/tl/tip_images/generated/staging/web-use_curtains_to_reduce_heat_during_summer_season-93-400_1024x648.jpeg',
                },
            },
        ];

        // @ts-ignore
        const tipsWidget = new TipsWidget({
            language: {
                title: 'Useful energy saving tips',
            },
        });

        // @ts-ignore
        const dynamicTipsWidget = new DynamicWidgetWrapper(
            tipsWidget,
            '/api/proxy/tips',
            // @ts-ignore
            new HttpMock(tipsData)
        );
        dynamicTipsWidget.render('tips_widget');
    }
}
