import { DonationWidget } from '@/components/widgets/donation-widget/donation-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './donation-widget-v2.html';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import { productImageMap } from '@/map/productImageMap';
import Format from '@/interfaces/Format';
import { Watch } from 'vue-property-decorator';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
    },
})
export class DonationWidgetV2 extends DonationWidget {
    get productImage(): string | null {
        if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
            try {
                return (
                    productImageMap['berlinStrom_Oeko_Treue12.24_ps'] ??
                    productImageMap['berlinStrom_Oeko_Treue12.24_ps_test']
                );
            } catch (error) {
                if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'prod') {
                    console.error(
                        'Product image not found for product code: ' +
                            'berlinStrom_Oeko_Treue12.24_ps'
                    );
                    console.log(error);
                }
                return null;
            }
        }
        return null;
    }

    get donationApplied() {
        return this.currentTariff.contract.donationApplied;
    }

    public mounted() {
        if (this.donationApplied) {
            this.confirmation = true;
        }
    }
}
