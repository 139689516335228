import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './electricity-production-widget.html';

@WithRender
@Component({})
export class AdvizzoElectricityProductionWidget extends vue {
    public mounted(): void {
        const elProdApiData = {
            electricityUnit: 'kW',
            gasUnit: 'm3',
            solarUnit: 'kW',
            netUnit: 'kW',
            data: [
                {
                    date: '2021-04-01',
                    solar: '230',
                    net: '-82.543',
                    electricity: '301.881',
                    gas: '77.9',
                },
                {
                    date: '2021-05-01',
                    solar: '210',
                    net: '-26.367',
                    electricity: 0,
                    gas: 0,
                },
                {
                    date: '2021-06-01',
                    solar: '240',
                    net: '33.831',
                    electricity: 0,
                    gas: 0,
                },
                {
                    date: '2021-07-01',
                    solar: '220',
                    net: '-11.565',
                    electricity: 0,
                    gas: 0,
                },
                {
                    date: '2021-08-01',
                    solar: '225',
                    net: '-16.718',
                    electricity: 0,
                    gas: 0,
                },
                {
                    date: '2021-09-01',
                    solar: '250',
                    net: '29.862',
                    electricity: 0,
                    gas: 0,
                },
                {
                    date: '2021-10-01',
                    solar: '270',
                    net: '41.881',
                    electricity: 0,
                    gas: 0,
                },
                {
                    date: '2021-11-01',
                    solar: '260',
                    net: 0,
                    electricity: 0,
                    gas: 0,
                },
            ],
        };

        const elProductionConfig = {
            language: {
                title: 'Monthly electricity production from 4 kWp solar PV system and consumption',
                yAxisTitle: 'Kilowatt-hours per month',
                xAxisTitle: 'Monthly payments',
                tooltip: {
                    title: 'Date: ',
                },
            },
            chart: {
                defaultSectionIndex: 0,
                sections: [
                    {
                        id: '12months',
                        label: 'Monthly view (12 months)',
                        span: 'monthly',
                        slice: '12',
                    },
                    {
                        id: '4weeks',
                        label: 'Weekly view (4 weeks)',
                        span: 'weekly',
                        slice: '28',
                    },
                    {
                        id: '24hours',
                        label: 'Daily view (24 hours)',
                        span: 'daily',
                        slice: '24',
                    },
                ],
                points: [
                    {
                        label: 'Net',
                        unit: 'kW',
                        color: '#458f0e',
                    },
                    {
                        label: 'Solar',
                        unit: 'kW',
                        color: '#ff9500',
                    },
                    {
                        label: 'Gas',
                        unit: 'm3',
                        color: '#eb0000',
                    },
                    {
                        label: 'Electricity',
                        unit: 'kW',
                        color: '#414bce',
                    },
                ],
            },
        };

        // @ts-ignore
        const elProductionWidget = new ElectricityProductionWidget(
            elProductionConfig
        );
        // @ts-ignore
        const dynamicElProdWidget = new DynamicWidgetWrapper(
            elProductionWidget,
            '/api/proxy/solar-power-generation?rperiod=l12m',
            // @ts-ignore
            new HttpMock(elProdApiData)
        );
        dynamicElProdWidget.render('electricity_production_widget');
    }
}
