import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './opt-in-widget.html';
import * as Sentry from '@sentry/browser';
import IocContainer from '@/container/IocContainer';
import Customer from '@/interfaces/Customer';
import Tracking from '@/interfaces/Tracking';
import SERVICES from '@/container/Services';

import { MessagesBlock } from '@/components/snippets/messages';

const customerProvider = IocContainer.get<Customer>(SERVICES.CUSTOMER);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({
    components: {
        'messages-block': MessagesBlock,
    },
})
export class OptInWidget extends Base {
    public checked = false;
    public showFull = false;

    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    get displayBonusOptIn() {
        return true;
    }

    protected async submit() {
        this.errorInformation = [];
        this.successInformation = [];
        await customerProvider
            .updateAdsBonus(this.$store.state.contracts.contractId)
            .then(
                () => {
                    trackingProvider.qcellsNewsletterSuccessful();
                    this.successInformation.push({
                        key: 'widget.oi.success',
                        message: this.$t('widget.oi.success').toString(),
                    });
                    setTimeout(() => {
                        this.$store.dispatch('customer/adsBonusSubmitted');
                    }, 5000);
                    return;
                },
                (error) => {
                    this.errorInformation.push({
                        key: 'general.error',
                        message: this.$t('general.error').toString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
    }
}
