import Component from 'vue-class-component';
import { CoreComponent } from '@/components/pages/auth/username/CoreComponent';
import { HeaderV2 } from '@/components/template/header-v2';
import { Footer } from '@/components/template/footer';
import WithRender from './username.html';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
    }
})
export class Username extends CoreComponent {}
