import { injectable } from 'inversify';
import Commodity from '@/interfaces/Commodity';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class CommodityTypeProvider
    extends PrototypeProvider
    implements Commodity
{
    public getCommodityType(currentContract: Record<any, any>, widget: string): string {
        if (
            currentContract.energy &&
            ['electricity', 'water', 'gas'].indexOf(currentContract.energy) >= 0
        ) {
            if (widget === 'tariff-details' && currentContract.energy === 'gas') {
                return 'unit.tariff.details.' + currentContract.energy;
            } else {
                return 'unit.' + currentContract.energy;
            }

        }
        return currentContract.energy;
    }

    public getCommodityTypeWorkingPrice(
        currentContract: Record<any, any>
    ): string {
        const energy = currentContract.energy;
        switch (!!energy) {
            case energy === 'electricity':
                return 'unit.centperelectricity';
            case energy === 'water':
                return 'unit.centperwater';
            case energy === 'gas':
                return 'unit.centpergas';
            default:
                return energy;
        }
    }
}
