<form-wrapper form="formPaymentData">
    <div class="mobile-modal__inner">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-payment-data-widget-close-click"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="box-title static-title">
                            {{ $t('widget.cpd.title') }}
                        </span>
                        <div
                            id="paymentDataChange-content"
                            class="mobile-modal__wrap"
                        >
                            <div id="paymentDataChangeForm">
                                <div class="row">
                                    <div class="col-12 row mb-4">
                                        <div class="col-6">
                                            <label class="m-radio">
                                                <input
                                                    type="radio"
                                                    value="sepa"
                                                    v-model="paymentType"
                                                />
                                                {{ $t('widget.cpd.payment.sepa')
                                                }} {{ paymentDetails.directDebit
                                                ?
                                                $t('widget.cpd.payment.sepa.active')
                                                :
                                                $t('widget.cpd.payment.sepa.inactive')
                                                }}
                                                <span></span>
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <label class="m-radio">
                                                <input
                                                    type="radio"
                                                    value="transfer"
                                                    v-model="paymentType"
                                                />
                                                {{
                                                $t('widget.cpd.payment.transfer')
                                                }}
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        :class="['col-sm-6 col-xs-12', paymentType === 'transfer' ? 'ms-auto' : '']"
                                    >
                                        <div
                                            class="form-group form-check"
                                            v-if="paymentType === 'transfer'"
                                        >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="payment-transfer-agree"
                                                v-model="showTransfer"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="payment-transfer-agree"
                                            >
                                                {{
                                                $t('widget.cpd.payment.transfer.explanation')
                                                }}
                                            </label>
                                        </div>
                                        <div
                                            v-if="paymentType === 'sepa' || showTransfer"
                                        >
                                            <form-group
                                                id="change-payment-data-widget__iban"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cpd.iban') }}
                                                </custom-label>
                                                <custom-input
                                                    data-test="change-payment-data-widget-iban-input--iban"
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cpd.iban')"
                                                    v-model="details.iban"
                                                    @focus="details.iban=null"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-payment-data-widget__bic"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cpd.bic') }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="bicBankPlaceholder"
                                                    :value="bic"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-payment-data-widget__bank"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cpd.bank') }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="bicBankPlaceholder"
                                                    :value="bank"
                                                />
                                            </form-group>
                                            <div
                                                class="form-group"
                                                v-if="sepaMandateActive"
                                            >
                                                <label>
                                                    {{
                                                    $t('widget.cpd.activeSepa')
                                                    }}
                                                </label>
                                                <div class="fs-22">
                                                    {{
                                                    paymentDetails.sepaMandate
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                class="mt-4"
                                                v-if="!paymentDetails.directDebit"
                                            >
                                                <div class="mb-2">
                                                    {{
                                                    $t('widget.cpd.payment.sepa.question')
                                                    }}
                                                </div>
                                                <div>
                                                    <label class="m-radio">
                                                        <input
                                                            type="radio"
                                                            :value="true"
                                                            v-model="isAccountHolder"
                                                        />
                                                        {{ $t('yes') }}
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label class="m-radio">
                                                        <input
                                                            type="radio"
                                                            :value="false"
                                                            v-model="isAccountHolder"
                                                        />
                                                        {{
                                                        $t('widget.cpd.payment.sepa.account.holder')
                                                        }}
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <form-group
                                                    id="change-payment-data-widget__alternative-account-holder"
                                                    class="ps-4 pe-2"
                                                    v-if="isAccountHolder === false"
                                                >
                                                    <custom-input
                                                        type="text"
                                                        :class="['form-control ms-2', alternativeAccountHolderError ? 'is-invalid' : '']"
                                                        v-model="alternativeAccountHolder"
                                                    />
                                                </form-group>
                                                <div
                                                    class="
                                                        form-group form-check
                                                    "
                                                    v-if="paymentType === 'sepa'"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        :class="['form-check-input', sepaAgreeError ? 'is-invalid' : '']"
                                                        id="payment-sepa-agree"
                                                        v-model="sepaAgree"
                                                        @change="sepaAgreeError = false"
                                                        data-test="change-payment-data-widget-sepa-click"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="payment-sepa-agree"
                                                    >
                                                        {{
                                                        $t('widget.cpd.payment.sepa.explanation')
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-sm-6 col-xs-12"
                                        v-if="paymentType === 'sepa'"
                                    >
                                        <div
                                            class="alert alert-danger"
                                            v-if="sepaError"
                                        >
                                            {{
                                            $t('widget.cpd.payment.sepa.error')
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-8 col-xs-12">
                                        <messages-block
                                            fromTestElement="change-payment-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="col text-end">
                                            <button
                                                data-test="change-payment-data-widget-save-click"
                                                class="btn btn-primary"
                                                @click="checkData"
                                                :disabled="submitPending || !dataChanged"
                                            >
                                                {{ $t('widget.cpd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
