<div class="m-aside-modal m-aside-modal__wrap">
    <div class="m-aside-modal__body">
        <!--begin::Form-->

        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('protected.password.change') }}
            </span>
            <div class="m-aside-modal__close" @click="closeModal()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="oldPassword">
                <strong>{{ $t('protected.password.current') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.oldPassword ? 'is-invalid' : '']"
                v-model="oldPassword"
                id="oldPassword"
                name="oldPassword"
                autocomplete="off"
                value=""
                type="password"
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                :placeholder="$t('protected.password.current')"
                v-model="oldPassword"
                name="oldPassword"
                autocomplete="off"
                value=""
                type="password"
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="newPassword">
                <strong>{{ $t('protected.password.new') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input m-login__form-input--last', errors.newPassword ? 'is-invalid' : '']"
                @keyup.enter="changePassword()"
                v-on:keyup.enter="changePassword"
                type="password"
                v-model="newPassword"
                id="newPassword"
                name="newPassword"
                autocomplete="off"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input m-login__form-input--last"
                @keyup.enter="changePassword()"
                v-on:keyup.enter="changePassword"
                v-model="newPassword"
                type="password"
                :placeholder="$t('protected.password.new')"
                name="newPassword"
                autocomplete="off"
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="oldPassword">
                <strong>{{ $t('login.password.confirmation') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.newConfirmPassword ? 'is-invalid' : '']"
                v-model="newConfirmPassword"
                id="newConfirmPassword"
                name="newConfirmPassword"
                autocomplete="off"
                value=""
                type="password"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                :placeholder="$t('login.password.confirmation')"
                v-model="newConfirmPassword"
                name="newConfirmPassword"
                autocomplete="off"
                value=""
                type="password"
            />
        </div>

        <template v-if="isTwoFactorAuthenticationActivated">
            <div
                v-if="displayElement('input-with-label')"
                class="form-group m-form__group"
            >
                <label for="totpCode">
                    <strong>{{ $t('widget.modal.tfa.code.long') }}</strong> {{
                    $t('login.field.required') }}
                </label>
                <input
                    :class="['form-control m-input', errors.totpCode ? 'is-invalid' : '']"
                    id="totpCode"
                    name="totpCode"
                    autocomplete="off"
                    value=""
                    type="text"
                    v-model="totpCode"
                />
            </div>
            <div v-else class="form-group m-form__group">
                <input
                    :class="['form-control m-input', errors.totpCode ? 'is-invalid' : '']"
                    id="totpCode"
                    name="totpCode"
                    autocomplete="off"
                    value=""
                    type="text"
                    :placeholder="$t('widget.modal.tfa.code.long')"
                    v-model="totpCode"
                />
            </div>
        </template>

        <messages-block :success="successInformation"></messages-block>

        <div
            class="mt-3 modal-password-update-widget-message-block-box"
            v-if="!displayElement('input-personal-errors')"
        >
            <messages-block :errors="errorInformation"></messages-block>
        </div>

        <!--begin::Action-->
        <div class="m-login__action">
            <button
                @click="changePassword()"
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom
                    m-login__btn m-login__btn--primary
                "
            >
                {{ $t('protected.password.change') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
</div>
