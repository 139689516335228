import { PostBoxWidgetEts } from '@/components/widgets/post-box-widget/custom/ets';
import { PostBoxWidgetSchweizstrom } from '@/components/widgets/post-box-widget/custom/schweizstrom';
import { PostBoxWidget } from '@/components/widgets/post-box-widget/post-box-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = PostBoxWidgetEts;
} else if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = PostBoxWidgetSchweizstrom;
} else {
    widget = PostBoxWidget;
}
export { widget as PostBoxWidget };
