<div
    class="m-portlet m-portlet--mobile account-balance-widget"
    data-test="account-balance-widget"
    @click="$emit('toggleWidget', 'accountTransactionsWidget')"
>
    <div class="m-portlet__body">
        <div class="row accountBalance">
            <div
                class="col-2"
                :class="[ displayElement('center-icon') ? 'nextReading__icon--center': '' ]"
            >
                <i class="fa fa-chart-line accountBalance__icon"></i>
            </div>
            <div class="col-10">
                <div
                    id="accountBalance-content"
                    style="display: block; position: relative"
                >
                    <h5>{{ $t('widget.ab.heading') }}</h5>
                    <template v-if="displayElement('pencil-icon')">
                        <div class="actionMiddleWidget toggle-icons">
                            <svg
                                data-test="account-balance-widget-click-icon"
                                v-if="showAction && accountBalanceLoaded"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="24px/input show">
                                    <path
                                        id="Shape"
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M47.0759 23.2181C41.0347 14.9052 33.308 10.7018 24 10.7018C14.6921 10.7018 6.96543 14.9052 0.924149 23.2181C0.585342 23.6843 0.585342 24.3157 0.924149 24.7819C6.96543 33.0948 14.6921 37.2982 24 37.2982C33.308 37.2982 41.0347 33.0948 47.0759 24.7819C47.4147 24.3157 47.4147 23.6843 47.0759 23.2181ZM4.57895 22.8486C9.86785 16.5004 16.3153 13.3618 24 13.3618L24.7399 13.3716L25.472 13.4011C32.7547 13.7933 38.8833 17.1291 43.9294 23.473L44.338 24L43.9294 24.527L43.4211 25.1514C38.1322 31.4996 31.6848 34.6382 24 34.6382L23.2602 34.6284L22.5281 34.599C15.2454 34.2068 9.11678 30.8709 4.07073 24.527L3.66004 24L4.07073 23.473L4.57895 22.8486ZM24 15.34C28.7828 15.34 32.66 19.2172 32.66 24C32.66 28.7828 28.7828 32.66 24 32.66C19.2173 32.66 15.34 28.7828 15.34 24C15.34 19.2172 19.2173 15.34 24 15.34ZM18 24C18 20.6863 20.6863 18 24 18C27.3138 18 30 20.6863 30 24C30 27.3137 27.3138 30 24 30C20.6863 30 18 27.3137 18 24Z"
                                        fill="#001E50"
                                    />
                                </g>
                            </svg>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="actionMiddleWidget actionMiddleWidget--default"
                            v-if="displayElement('interactive-icon')"
                        >
                            <i
                                data-test="account-balance-widget-click-icon"
                                :class="{'active': isActiveWidget}"
                                v-if="showAction && accountBalanceLoaded"
                            ></i>
                        </div>
                        <div class="actionMiddleWidget" v-else>
                            <i
                                data-test="account-balance-widget-click-icon"
                                class="fa fa-ellipsis-h"
                                v-if="showAction && accountBalanceLoaded"
                            ></i>
                        </div>
                    </template>
                    <div
                        class="accountBalance__title"
                        :class="{ 'text-danger': accountBalance < 0 }"
                    >
                        <div v-if="!accountBalanceLoaded">
                            <content-loader
                                :width="165"
                                :height="35"
                                style="width: 165px; height: 35px"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                            {{ $t('euro') }}
                        </div>
                        <template v-else>
                            {{ accountBalance | euroSpecial }} {{ $t('euro') }}
                        </template>
                    </div>
                    <div
                        class="accountBalance__text"
                        v-if="displayElement('show-bold-words')"
                    >
                        {{ $t('widget.ab.title1') }}
                        <strong>{{ $t('widget.ab.title2') }}</strong>
                    </div>
                    <div class="accountBalance__text" v-else>
                        {{ $t('widget.ab.title') }}
                    </div>
                </div>
            </div>

            <account-transactions-widget
                v-if="isActiveWidget && displayElement('account-transactions-widget')"
                class="d-md-none"
            ></account-transactions-widget>
        </div>
    </div>
</div>
