<div
    class="m-portlet m-portlet--mobile m-portlet--meter-history"
    data-test="meter-history-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col">
                <i class="fa fa-chart-bar readingHistory-icon"></i>
                <span
                    data-test="meter-history-widget-title"
                    class="monthlyDeposit-title static-title static-title--custom"
                    >{{ $t('widget.mh.title') }}</span
                >
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div
                    id="meter-history-chart-box"
                    ref="meter-history-chart-box"
                    :class="[isLoading ? 'meter-loading' : '']"
                >
                    <highcharts
                        ref="meter-history-chart"
                        :options="chartOptions"
                    ></highcharts>
                    <div class="m-spinner"></div>

                    <button
                        class="btn btn-primary btn-sm btn-switch-history-widget m-btn--gradient-from-light"
                        v-if="isUsageHistoryWidgetEnabled && invoiceCount > 0"
                        v-on:click="$emit('toggleHistoryWidget')"
                        data-test="meter-history-widget-button"
                    >
                        {{ $t('widget.uh.usageReadingView') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
