import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './modal-two-factor-authentication-widget.html';
import { Emit } from 'vue-property-decorator';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';
import { Snackbar } from '@/components/snippets/snackbar';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';

@WithRender
@Component({
    components: {
        ContentLoader,
        // External packages
        'messages-block': MessagesBlock,
        snackbar: Snackbar,
    },
})
export class ModalTwoFactorAuthenticationWidget extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    protected showSnackbar = false;
    protected enableLoadingState = false;
    protected isActivated = false;
    protected isContentLoader = false;
    protected tfaContent = '';
    protected image = '';
    protected secret = '';
    protected code = '';
    protected codesHtml = '';
    protected codesForCopy = '';
    protected codes: [] = [];

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    public created() {
        this.isContentLoader = true;
        this.tfaContent = this.$t('widget.modal.tfa.message').toLocaleString();

        this.auth
            .twoFactorAuthenticationIsActivated()
            .then((response) => {
                this.isActivated =
                    response.data.success && response.data.activated;
                this.isContentLoader = false;
            })
            .catch((error) => {
                this.isContentLoader = false;
                Sentry.captureException(new Error(error));
            });
    }

    public printCodes() {
        window.print();
        return true;
    }

    public copyCodes() {
        this.showSnackbar = true;
        const listener = (event: ClipboardEvent) => {
            if (typeof event === 'object' && event && event.clipboardData) {
                event.clipboardData.setData('text/plain', this.codesForCopy);
                event.preventDefault();
                document.removeEventListener('copy', listener);
            }
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        setTimeout(() => {
            this.showSnackbar = false;
        }, 1000);
    }

    @Emit('toggle-two-factor-authentication-modal')
    private closeModal() {
        // closeModal
        localStorage.removeItem('2FA-enabling');
    }

    @Emit('toggle-cancel-two-factor-authentication-modal')
    private cancelTwoFactorAuthentication() {
        // closeModal
        localStorage.removeItem('2FA-enabling');
    }

    private disable() {
        this.errorInformation = [];
        this.successInformation = [];
        this.auth
            .twoFactorAuthenticationDisable({
                code: this.code,
            })
            .then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        this.isActivated = false;
                        this.code = '';
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    } else {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                }
            })
            .catch((error) => {
                if (
                    error.response.data.errors &&
                    error.response.data.errors.code
                ) {
                    this.errorInformation.push({
                        key: '',
                        message: error.response.data.errors.code[0],
                    });
                }
                Sentry.captureException(new Error(error));
            });
    }

    private enable() {
        this.codes = [];
        this.codesHtml = '';
        this.errorInformation = [];
        this.successInformation = [];
        this.enableLoadingState = true;

        this.auth
            .twoFactorAuthenticationEnable()
            .then((response) => {
                if (response && response.data && response.data.success) {
                    this.image = response.data.image;
                    this.secret = response.data.secret;
                } else {
                    if (
                        typeof response.data.isActivated !== 'undefined' &&
                        response.data.isActivated
                    ) {
                        this.isActivated = true;
                    } else {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                }
                this.enableLoadingState = false;
            })
            .catch((error) => {
                Sentry.captureException(new Error(error));
            });
    }

    private sendCode() {
        this.errorInformation = [];
        this.successInformation = [];
        this.codes = [];
        this.codesHtml = '';

        this.auth
            .twoFactorAuthenticationActivate({
                code: this.code,
                secret: this.secret,
            })
            .then((response) => {
                this.errorInformation = [];
                if (response && response.data && response.data.success) {
                    this.image = '';
                    this.codes = response.data.codes;
                    this.codesHtml = '<ul class="list-of-codes">';
                    for (const row of response.data.codes) {
                        this.codesHtml += `<li>${row}</li>`;
                        this.codesForCopy += `${row}\n`;
                    }
                    this.codesForCopy = this.codesForCopy.substring(
                        0,
                        this.codesForCopy.length - 2
                    );
                    this.codesHtml += '</ul>';
                } else {
                    if (typeof response.data.messageLocalized === 'object') {
                        this.errorInformation.push(
                            response.data.messageLocalized
                        );
                    } else if (
                        typeof response.data.messageLocalized === 'string'
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: response.data.messageLocalized,
                        });
                    } else {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                    }
                }
                this.enableLoadingState = false;
            })
            .catch((error) => {
                if (
                    error.response.data.errors &&
                    error.response.data.errors.code
                ) {
                    this.errorInformation.push({
                        key: '',
                        message: error.response.data.errors.code[0],
                    });
                }
                Sentry.captureException(new Error(error));
            });
    }
}
