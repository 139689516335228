import { default as Component } from 'vue-class-component';
import WithRender from './addon-status-widget.html';
import Base from '@/mixins/base';
import { ContentLoader } from 'vue-content-loader';
import * as Sentry from '@sentry/browser';
import * as FileSave from 'file-saver';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Auth from '../../../interfaces/Auth';
import Tracking from '@/interfaces/Tracking';
import Contracts from '../../../interfaces/Contracts';
import { MessagesBlock } from '@/components/snippets/messages';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

@WithRender
@Component({
    components: {
        'content-loader': ContentLoader,
        'messages-block': MessagesBlock,
    },
})
export class AddonStatusWidget extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public errors: any[] = [];

    beforeMount() {
        this.$store.dispatch(
            'contracts/contractAddonStatus',
            this.$store.state.contracts.contractId
        );
    }
    get addonStatus(): any {
        return this.$store.state.contracts.addonStatus;
    }

    public downloadDocument(e) {
        e.preventDefault();
        const title = this.removeUmlaut(
            this.addonStatus.bonus.replace(/\s/g, '')
        );
        if (authProvider.isAuthenticated()) {
            contractProvider
                .downloadCustomerCommunication(
                    this.$store.state.contracts.contractId,
                    this.addonStatus.documentId,
                    title
                )
                .then(
                    async (response) => {
                        if (response.data.type === 'application/pdf') {
                            trackingProvider.downloadCustomerCommunication(
                                title
                            );
                            const blob = new Blob([response.data], {
                                type: 'application/pdf',
                            });
                            FileSave.saveAs(blob, title + '.pdf');
                            return;
                        }
                        const isJsonBlob = (data) =>
                            data instanceof Blob &&
                            data.type === 'application/json';
                        const responseData = isJsonBlob(response?.data)
                            ? await response?.data?.text()
                            : response?.data || {};
                        const responseJson =
                            typeof responseData === 'string'
                                ? JSON.parse(responseData)
                                : responseData;

                        this.errorInformation.push(
                            responseJson.messageLocalized
                        );
                        setTimeout(() => {
                            this.errorInformation = [];
                        }, 3000);
                    },
                    (error) => {
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }

    get iconPath(): string {
        return `/img/evus/${
            process.env.VUE_APP_ENVIRONMENT
        }/status_${this.removeUmlaut(
            this.addonStatus.status.replace(/\s/g, '')
        )}.svg`;
    }
}
