import WithRender from '../dashboard.html';
import { default as Component } from 'vue-class-component';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
import { TariffChangeWidget } from '../../../widgets/tariff-change-widget';
import { CookieBannerWidget } from '../../../widgets/cookie-banner-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';

@WithRender
@Component({
    components: {
        TariffChangeWidget,
        CookieBannerWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})
export class Dashboard extends DashboardParent {
    get tariff(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )
        ) {
            return (
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).changeList[0] ?? null
            );
        }

        return null;
    }

    get showTariffAvailableWidget(): boolean {
        return this.tariff !== null;
    }
}
