<div
    class="m-portlet m-portlet--mobile m-portlet--usage-history"
    data-test="usage-history-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col">
                <i class="fa fa-chart-bar readingHistory-icon"></i>
                <span
                    data-test="usage-history-widget-title"
                    class="monthlyDeposit-title static-title static-title--custom"
                >
                    {{ $t('widget.uh.title') }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div
                    id="usage-history-chart-box"
                    :class="{'meter-loading': !invoicesLoaded}"
                >
                    <Chart
                        ref="usage-history-chart"
                        :series="usageHistorySeries"
                        :referenceValues="referenceValues"
                        :referenceValuesEnabled="referenceValuesEnabled"
                        :name="$t('widget.uh.usageReadingAxis')"
                        :color="chartColor"
                    />

                    <p
                        v-if="referenceValuesEnabled && !displayElement('hide-reference-explanation')"
                        class="reference-values-explanation"
                        v-html="$t('widget.uh.referenceValuesExplanation')"
                    ></p>

                    <div class="m-spinner"></div>

                    <button
                        class="btn btn-primary btn-sm btn-switch-history-widget m-btn--gradient-from-light"
                        @click="$emit('toggleHistoryWidget')"
                        data-test="usage-history-widget-button"
                    >
                        {{ $t('widget.mh.meterReading') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
