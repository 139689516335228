import { LoginPage } from '@/components/pages/auth/login-page';
import WithRender from './schweizstrom.html';
import Component from 'vue-class-component';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({
    props: {
        second: {
            type: Number,
            default: 0,
        },
    },
})
export class LoginPageSchweizstrom extends LoginPage {
    private register() {
        this.$router
            .push({ name: 'register' })
            .catch((failure) => handleNavigationFailure(failure));
    }
}
