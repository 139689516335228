<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <i
                    class="fa fa-calendar readingHistory-icon"
                    v-if="displayElement('calendar-icon')"
                ></i>

                <span class="customerCommunications-title static-title">
                    <i
                        class="fas fa-exclamation registration-issue-exclamation"
                        v-if="registrationIssue"
                    ></i>
                    {{ showContractData ? $t('widget.sb.title') :
                    $t('widget.sb.order.status') }}
                </span>
                <template v-if="!displayElement('closer-title')"
                    ><br /><br
                /></template>
                <div>
                    <b v-if="displayElement('shipping-address')"
                        >{{ showContractData ? $t('widget.sb.deliveryPoint') :
                        $t('widget.sb.shippingAddress') }}:
                    </b>
                    <b v-else>{{$t('widget.sb.deliveryPoint')}}</b>
                    {{ contract.street }} {{ contract.houseNumber }}, {{
                    contract.zip }} {{ contract.city }}<br />
                    <div class="m-timeline-2">
                        <div class="m-timeline-2__marker"></div>
                        <div class="m-timeline-2__items m--padding-top-25">
                            <div
                                class="m-timeline-2__item"
                                v-for="(status, index) in statuses"
                            >
                                <div
                                    :class="['m-timeline-2__item-cricle', 'border-0', registrationIssue && highlightStatus(status) ? 'registration-issue' : '']"
                                    v-if="displayElement('checkbox-icon')"
                                >
                                    <img
                                        src="/img/evus/default/check_mark.png"
                                        alt=""
                                        height="20"
                                    />
                                </div>
                                <div
                                    class="m-timeline-2__item-cricle"
                                    v-else-if="(status.statusNumber !== statuses.length) &&
                                     status.completed &&
                                     displayElement('check-icon') &&
                                     !(registrationIssue && highlightStatus(status))
                                    "
                                >
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="m-timeline-2__item-cricle" v-else>
                                    <i
                                        :class="['fa fa-genderless', registrationIssue && highlightStatus(status) ? 'registration-issue' : '']"
                                    ></i>
                                </div>
                                <div
                                    class="m-timeline-2__item-text m--padding-top-5"
                                >
                                    <span
                                        :class="[registrationIssue && highlightStatus(status) ? 'registration-issue' : '', 'status-title' ]"
                                    >
                                        {{ $t(status.title) }} </span
                                    ><br />
                                    {{ $t(status.description, status.fields) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
