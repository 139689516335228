import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './survey-widget.html';

import IocContainer from '@/container/IocContainer';
import Survey from '@/interfaces/Survey';
import SERVICES from '@/container/Services';

/*
import * as SurveyVue from 'survey-vue';
SurveyVue.StylesManager.applyTheme('bootstrap');
const Survey = SurveyVue.Survey;
*/
const surveyProvider = IocContainer.get<Survey>(SERVICES.SURVEY);

@WithRender
@Component({
    name: 'SurveyWidget',
    props: {
        widget: {
            type: String,
            default: null,
        },
    },
    /*
    components: {
        'survey': Survey,
    },
    */
})
export class SurveyWidget extends Base {
    protected survey: any = null;

    get currentWidget() {
        return this.$props.widget;
    }

    protected created(): void {
        this.$store.dispatch('settings/load').then(() => {
            switch (this.currentWidget) {
                case 'meter-submit-widget':
                    this.showSurveyVue(
                        this.$store.state.settings.surveyMeterSubmitWidget
                        // 'meter-submit-widget'
                    );
                    break;
                case 'deposit-change-widget':
                    this.showSurveyVue(
                        this.$store.state.settings.surveyDepositChangeWidget
                        // 'deposit-change-widget'
                    );
                    break;
            }
        });
    }

    protected async sendDataToServer(survey, widget): Promise<void> {
        await surveyProvider
            .sendResults({
                survey: widget,
                results: survey.data,
            })
            .then(() => {
                // console.log(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private showSurveyVue(json): void {
        if (typeof json !== 'undefined' && json !== '' && json !== null) {
            /*
            this.survey = new SurveyVue.Model(JSON.parse(json))
            this.survey.onComplete.add((result) => {
                this.sendDataToServer(result, widget).then(() => {
                    console.log('complete');
                });
            });
            */
        }
    }
}
