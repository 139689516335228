<div class="col-12 mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <div class="d-flex align-items-center gap-2 mb-3">
                        <i
                            v-if="displayElement('coins-icon')"
                            class="fa fa-coins readingHistory-icon"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.do.title') }}</span
                        >
                    </div>

                    <div class="mobile-modal__wrap">
                        <table
                            :class="['table table-striped', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                            id="invoiceList-content"
                        >
                            <tbody>
                                <tr>
                                    <th :data-name="$t('widget.to.activeFrom')">
                                        <span
                                            >{{ $t('widget.to.activeFrom')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.to.activeUntil')"
                                    >
                                        <span
                                            >{{ $t('widget.to.activeUntil')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.do.depositValue')"
                                    >
                                        <span
                                            >{{ $t('widget.do.depositValue')
                                            }}</span
                                        >
                                    </th>
                                    <th :data-name="$t('widget.do.useAt')">
                                        <span>{{ $t('widget.do.useAt') }}</span>
                                    </th>
                                </tr>
                                <tr
                                    v-for="(deposit, key) in mappedDepositList"
                                    :class="{'active': key === 0 && deposit.nextExecution}"
                                    :key="`deposit-${key}`"
                                >
                                    <td :data-name="$t('widget.to.activeFrom')">
                                        {{ deposit.activeFrom | date }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.to.activeUntil')"
                                    >
                                        {{ deposit.activeUntil | date }}
                                    </td>
                                    <td :data-name="$t('widget.to.basePrice')">
                                        {{ deposit.grossAmount | euroSpecial }}
                                        {{ $t('euro') }}
                                    </td>
                                    <td :data-name="$t('widget.do.useAt')">
                                        <template
                                            v-if="deposit && deposit.nextExecution"
                                            >{{ deposit.nextExecution.useAt |
                                            date }}</template
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
