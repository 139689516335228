import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './future-payment-overview.html';

import Format from '@/interfaces/Format';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        date: formatProvider.date(),
    },
})
export class FuturePaymentOverview extends Base {
    protected elements = {};

    get futurePaymentList(): any {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.futureBankingDetails;
    }

    protected getSepaMandate(payment): string {
        return payment.directDebit && payment.sepaMandate !== ''
            ? payment.sepaMandate
            : '-';
    }
}
