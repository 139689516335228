import { CustomerServiceFormWidget } from '../customer-service-form-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './schweizstrom.html';

@WithRender
@Component({})
export class CustomerServiceFormWidgetSchweizstrom extends CustomerServiceFormWidget {
    protected isShowBlock = true;

    protected mounted(): void {
        const inputFile = document.querySelector(
            '.service-form-file input[type=file]'
        );
        const formConrolFile = document.querySelector('.form-control-file');
        const formConrolFileSpan = document.querySelector(
            '.form-control-file span'
        );
        const inputFilePlaceholder = (inputFile as HTMLInputElement)
            .placeholder;

        formConrolFileSpan!.textContent = inputFilePlaceholder;

        inputFile?.addEventListener('change', (e: Event) => {
            if ((e.target as HTMLInputElement).files![0]) {
                const fileName = (e.target as HTMLInputElement).files![0].name;

                if (formConrolFile && fileName) {
                    formConrolFile.textContent = fileName;
                }
            }
        });
    }
}
