import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class FilesProvider extends PrototypeProvider {
    public async uploadFileViaS3(data: any): Promise<AxiosResponse<any>> {
        this.createConfig();
        return this.http.post('/upload/file', data, this.config);
    }

    public uploadFilesLive(data: any): Promise<AxiosResponse<any>> {
        this.createConfig();
        return this.http.post('/upload/file/live', data, this.config);
    }

    public reUploadDocuments(data: any): Promise<AxiosResponse<any>> {
        this.createConfig();
        return this.http.post('/upload/file/re-upload', data, this.config);
    }

    public async updateGenericData(
        data: FormData
    ): Promise<AxiosResponse<any>> {
        this.createConfig();

        return this.http.post(
            '/session/update/generic-storage',
            data,
            this.config
        );
    }
}
