<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2 v-if="displayElement('header-v2')"></header-v2>
    <div
        class="
            m-grid__item m-grid__item--fluid
            m-grid
            m-grid--desktop
            m-grid--ver-desktop
            m-grid--hor-tablet-and-mobile
            m-login m-login--6
        "
        id="m_login"
    >
        <div
            class="
                m-grid__item m-grid__item--order-tablet-and-mobile-2
                m-grid m-grid--hor
                m-login__aside
            "
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        >
            <div id="auth_header">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div
            class="
                m-grid__item
                m-grid__item--fluid
                m-grid__item--order-tablet-and-mobile-1
                m-login__wrapper
            "
            v-if="displayAuth"
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>

                    <!-- Login Display -->
                    <div class="m-login__title">
                        <h1 v-if="isVerify">{{ $t('verify.title') }}</h1>
                        <h1 data-test="login-title" v-else-if="!isRegister">
                            {{ $t('login.title') }}
                        </h1>
                        <h1 v-else>{{ $t('registration.title') }}</h1>
                        <template v-if="!displayElement('app-login-subtitle')">
                            <h6
                                v-if="!isRegister && !isVerify && !isRestoreTwoFactorAuthentication"
                                v-html="$t('login.subtitle')"
                            ></h6>
                            <h6 v-else-if="isVerify">
                                {{ $t('verify.subtitle') }}
                            </h6>
                        </template>
                    </div>

                    <!-- LOGIN AREA-->
                    <login-page
                        v-if="isLoginPage"
                        :second="second"
                    ></login-page>

                    <!-- RESET PASSWORD AREA -->
                    <reset-password-widget
                        v-if="resetPassword"
                    ></reset-password-widget>

                    <!-- REGISTER AREA-->
                    <register-page v-if="isRegister"></register-page>

                    <!-- VERIFY WIDGET -->
                    <verify-widget v-if="isVerify"></verify-widget>

                    <!-- RESTORE TWO FACTOR AUTHENTICATION AREA -->
                    <restore-two-factor-authentication
                        v-if="isRestoreTwoFactorAuthentication"
                    ></restore-two-factor-authentication>

                    <!-- AUTHPLUS ONE TIME PASSWORD -->
                    <one-time-password
                        v-if="isOneTimePassword && !isVerify"
                    ></one-time-password>

                    <!-- AUTHPLUS FORCE CHANGE PASSWORD -->
                    <auth-plus-new-password-widget
                        v-if="isForceToChangePassword"
                    ></auth-plus-new-password-widget>

                    <div class="m-login__note" v-if="showLogoutNote">
                        <div class="m-login__note-body">
                            <span>{{ $t('widget.lp.note.heading') }}</span>
                            <div>{{ $t('widget.lp.note.text')}}</div>
                        </div>
                    </div>
                </div>
                <!--end::Signin-->
            </div>

            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <!--    <app-footer v-if="displayElement('app-footer')"></app-footer>-->
    <div class="elli-auth-footer">
        <span
            >{{ $t('auth.footer.copyright', { date: new
            Date().getFullYear()})}}</span
        >
        <div v-if="locales.length > 1">
            <span
                v-for="(locale, index) in locales"
                @click="changeLocale(locale)"
                >{{ $t('locale.'+locale) }}<span
                    v-if="index !== locales.length - 1"
                >
                    -
                </span></span
            >
        </div>
        <span>
            <a
                href="https://www.elli.eco/de/impressum"
                class="m-nav__link"
                target="_blank"
            >
                {{ $t('footer.imprint') }}
            </a>
        </span>
    </div>
    <!-- end::Footer -->
    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
    <otp-hint-popup-widget v-if="isOneTimePassword"></otp-hint-popup-widget>
</div>
