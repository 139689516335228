import { default as Component } from 'vue-class-component';
import WithRender from './reset-password-widget.html';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';
import { AuthPlusPasswordStrengthMeter } from '@/components/snippets/auth-plus-password-strength-meter';

import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import Base from '@/mixins/base';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
        'auth-plus-password-strength-meter': AuthPlusPasswordStrengthMeter,
    },
})
export class ResetPasswordWidget extends Base {
    protected errorInformation: Record<any, any> = [];
    protected errors: {
        username: boolean;
        password: boolean;
        confirmation: boolean;
    } = {
        username: false,
        password: false,
        confirmation: false,
    };

    protected elements = {
        bsw: ['input-with-label'],
        'dsp-*': ['auth-plus-password-strength-meter'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private password = '';
    private passwordConfirmation = '';

    get isAuthPlusActive() {
        return this.$store.state.settings.isAuthPlusActive;
    }

    get username() {
        return this.$store.state.auth.username;
    }

    set username(username) {
        this.$store.dispatch('auth/setUserName', username).then(() => {
            // auth
        });
    }

    private setPassword() {
        this.errorInformation = [];
        this.errors = {
            username: false,
            password: false,
            confirmation: false,
        };
        this.auth
            .setPassword(
                this.$route.params.hash,
                this.username,
                this.password,
                this.passwordConfirmation
            )
            .then(
                (response) => {
                    if (response && response.data && response.data.success) {
                        this.$router
                            .push({ name: 'login' })
                            .catch((failure) =>
                                handleNavigationFailure(failure)
                            );
                    } else {
                        if (
                            response.data.error &&
                            response.data.error.length > 0
                        ) {
                            this.errorInformation = response.data.error;
                        } else if (
                            response.data.messageLocalized &&
                            response.data.messageLocalized.length > 0
                        ) {
                            this.errorInformation =
                                response.data.messageLocalized;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                },
                (error) => {
                    const errors =
                        typeof error.response.data !== 'undefined' &&
                        typeof error.response.data.errors !== 'undefined'
                            ? error.response.data.errors
                            : null;

                    this.errorInformation = [];

                    if (errors) {
                        if (errors.username && errors.username.length > 0) {
                            this.setErrorsByResponse(errors.username);
                        }
                        if (errors.password && errors.password.length > 0) {
                            this.setErrorsByResponse(errors.password);
                        }
                        if (
                            errors.passwordConfirmation &&
                            errors.passwordConfirmation.length > 0
                        ) {
                            this.setErrorsByResponse(
                                errors.passwordConfirmation
                            );
                        }
                    } else if (
                        typeof error.response.data !== 'undefined' &&
                        typeof error.response.data.messageLocalized !==
                            'undefined'
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: error.response.data.messageLocalized,
                        });
                    }
                    Sentry.captureException(new Error(error));
                }
            );
    }

    private setErrorsByResponse(errors) {
        for (const i in errors) {
            if (typeof errors[i] === 'string') {
                const errorTranslations = {
                    isPassportInBlacklist:
                        'auth.plus.error.password.in.blacklist',
                    isErrorPasswordLength: 'auth.plus.error.password.length',
                    isErrorCountSpecialCharacters:
                        'auth.plus.error.password.count.special.characters',
                    isErrorCountUppercaseCharacters:
                        'auth.plus.error.password.count.upper.characters',
                    isErrorCountLowercaseCharacters:
                        'auth.plus.error.password.count.lower.characters',
                    isErrorCountNumbers:
                        'auth.plus.error.password.count.numbers',
                    isErrorCountHistoryPasswords:
                        'auth.plus.error.password.in.history',
                    isErrorDenyUsernamePasswords:
                        'auth.plus.error.password.deny.username',
                    isErrorMinimumAgePassword:
                        'auth.plus.error.password.minimum.age',
                };
                let key = '';
                let message = errors[i];

                if (errorTranslations[message]) {
                    key = message;
                    message = this.$t(errorTranslations[message]);
                }

                this.errorInformation.push({
                    key,
                    message,
                });
            } else if (typeof errors[i] === 'object') {
                this.errorInformation.push({
                    key: errors[i].key,
                    message: errors[i].message,
                });
            }
        }
    }
}
