import { TariffChangeWidget as parentTariffChangeWidget } from '../tariff-change-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './prokon.html';
import * as Sentry from '@sentry/browser';
import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import Tariff from '@/interfaces/Tariff';
import { productImageMap } from '@/map/productImageMap';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const tariffProvider = IocContainer.get<Tariff>(SERVICES.TARIFF);

@WithRender
@Component({})
export class TariffChangeWidgetProkon extends parentTariffChangeWidget {
    get tariff() {
        return this.list[0];
    }

    get productCode() {
        return this.tariff.productCode;
    }

    get productImage(): string | null {
        if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
            try {
                return productImageMap[this.productCode];
            } catch (error) {
                if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'prod') {
                    console.error(
                        'Product image not found for product code: ' +
                            this.productCode
                    );
                    console.log(error);
                }
                return null;
            }
        }
        return null;
    }

    protected async submit(tariff): Promise<any> {
        if (!tariff.confirmationCheckbox && this.currentClient !== 'qcells') {
            tariff.confirmedError = true;
            return;
        }

        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            this.details.productCode = tariff.productCode;
            let data = {
                tariffChangeDateType: 'deadline',
                tariffChangeStartDate: null,
            };
            if (
                this.$store.state.settings.tariffChangeSelectedDate &&
                this.$store.state.settings.tariffChangeSelectedDate !== ''
            ) {
                data = {
                    tariffChangeDateType: 'date',
                    tariffChangeStartDate:
                        this.$store.state.settings.tariffChangeSelectedDate,
                };
            }
            await tariffProvider
                .changeTariff(Object.assign(this.details, data))
                .then(
                    (response): void => {
                        this.submitPending = false;
                        if (response.data.success) {
                            this.showSuccessChangeTariff = true;
                            this.activeFrom = response.data.data.activeFrom;
                            this.product = response.data.data.product;
                            this.newDepositValue =
                                response.data.data.newDepositValue;
                            setTimeout(() => {
                                this.$store.dispatch(
                                    'dashboard/showTariffOverview'
                                );
                            }, 30000);
                        } else {
                            if (
                                response.data.errorMessages &&
                                response.data.errorMessages.length > 0
                            ) {
                                this.errorInformation =
                                    response.data.errorMessages;
                            } else if (
                                typeof response.data.messageLocalized ===
                                'object'
                            ) {
                                this.errorInformation.push(
                                    response.data.messageLocalized
                                );
                            } else if (
                                typeof response.data.messageLocalized ===
                                'string'
                            ) {
                                this.errorInformation.push({
                                    key: '',
                                    message: response.data.messageLocalized,
                                });
                            } else {
                                this.errorInformation.push({
                                    key: '',
                                    message:
                                        this.$t(
                                            'general.error'
                                        ).toLocaleString(),
                                });
                            }
                        }
                    },
                    (error) => {
                        this.submitPending = false;
                        this.successInformation = [];
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }
}
