<div class="col-12 mobile-modal__inner move-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <i class="fas fa-truck move-icon"></i>
                    <span class="box-title static-title pt-0">
                        {{ $t('widget.mo.title') }}
                    </span>
                    <div class="mobile-modal__wrap row">
                        <div class="col-12 col-md-4">
                            <div
                                :class="['form-group', departureDateError ? 'text-danger' : '']"
                            >
                                <label for="move-out-date">
                                    {{ $t('widget.mo.move.out.date') }}
                                </label>
                                <input
                                    v-if="displayElement('date-input')"
                                    v-model="details.departureDate"
                                    type="date"
                                    :class="['form-control', departureDateError ? 'is-invalid' : '']"
                                    id="move-out-date"
                                    @change="departureDateError = false"
                                />
                                <datetime
                                    v-else
                                    v-model="details.departureDate"
                                    type="date"
                                    input-id="move-out-date"
                                    :input-class="['form-control', departureDateError ? 'is-invalid' : '']"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    @close="departureDateError = false"
                                ></datetime>
                            </div>
                            <div
                                :class="['form-group', arrivalDateError ? 'text-danger' : '']"
                            >
                                <label for="move-in-date">
                                    {{ $t('widget.mo.move.in.date') }}
                                </label>
                                <input
                                    v-if="displayElement('date-input')"
                                    v-model="details.arrivalDate"
                                    type="date"
                                    :class="['form-control', arrivalDateError ? 'is-invalid' : '']"
                                    id="move-in-date"
                                    @change="arrivalDateError = false"
                                />
                                <datetime
                                    v-else
                                    v-model="details.arrivalDate"
                                    type="date"
                                    input-id="move-in-date"
                                    :input-class="['form-control', arrivalDateError ? 'is-invalid' : '']"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    @close="arrivalDateError = false"
                                ></datetime>
                            </div>
                            <div
                                class="form-group"
                                v-if="!displayElement('hide-new-usage')"
                            >
                                <label
                                    for="new-usage"
                                    :class="[newUsageError ? 'text-danger' : '']"
                                >
                                    {{ $t('widget.mo.new.consumption') }}
                                </label>
                                <input
                                    class="form-control"
                                    type="number"
                                    id="new-usage"
                                    v-model="details.newUsage"
                                    @change="newUsageError = false"
                                    :class="['form-control', newUsageError ? 'is-invalid' : '']"
                                />
                            </div>
                            <div class="form-group">
                                <label
                                    for="new-counterNumber"
                                    :class="[counterNumberError ? 'text-danger' : '']"
                                >
                                    {{ $t('widget.mo.new.counterNumber') }}
                                    <i
                                        :class="['fas fa-question-circle text-primary-color font-size-12', ]"
                                        v-tooltip.top="$t('widget.mo.counterNumber.tooltip')"
                                        v-if="displayElement('show-counterNumber-tooltip')"
                                    ></i>
                                </label>
                                <input
                                    class="form-control"
                                    type="text"
                                    id="new-counterNumber"
                                    v-model="details.counterNumber"
                                    @change="counterNumberError = false"
                                    @input="this.sanitizeCounterNumber"
                                    :class="['form-control', counterNumberError ? 'is-invalid' : '']"
                                />
                                <div
                                    v-if="this.requireCounterNumberValidation"
                                    class="counter-number-hint"
                                >
                                    {{ $t('widget.mo.meter.number.hint') }}:
                                    <ul>
                                        <li>
                                            - {{
                                            $t('widget.mo.meter.number.hint.format1')
                                            }}
                                        </li>
                                        <li>
                                            - {{
                                            $t('widget.mo.meter.number.hint.format2')
                                            }}
                                        </li>
                                        <li>
                                            - {{
                                            $t('widget.mo.meter.number.hint.format3')
                                            }}
                                        </li>
                                        <li>
                                            - {{
                                            $t('widget.mo.meter.number.hint.format4')
                                            }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="row">
                                <div class="col-12 col-sm-5 col-lg-4">
                                    <div class="form-group">
                                        <label
                                            :class="[cityError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.post.code') }}
                                        </label>
                                        <v-select
                                            :options="citiesZipCodes"
                                            @search="citySearch"
                                            @input="cityInput"
                                            label="text"
                                            v-model="selectedCityZip"
                                            :class="['v-select--custom', cityError ? 'is-invalid' : '']"
                                        >
                                            <template #no-options>
                                                <span
                                                    v-if="displayElement('hide-city-input-no-options')"
                                                ></span>
                                                <span v-else
                                                    >{{ $t('widget.mo.no.city')
                                                    }}</span
                                                >
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-7 col-lg-8">
                                    <div class="form-group">
                                        <label
                                            :class="[cityError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.place') }}
                                        </label>
                                        <v-select
                                            :options="cities"
                                            @search="citySearch"
                                            @input="cityInput"
                                            label="text"
                                            v-model="selectedCityName"
                                            :class="['v-select--custom', cityError ? 'is-invalid' : '']"
                                        >
                                            <template #no-options>
                                                <span
                                                    v-if="displayElement('hide-city-input-no-options')"
                                                ></span>
                                                <span v-else
                                                    >{{ $t('widget.mo.no.city')
                                                    }}</span
                                                >
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="selectedCityId">
                                <div class="col-12 col-sm-8">
                                    <div class="form-group">
                                        <label
                                            :class="[streetError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.street') }}
                                        </label>
                                        <v-select
                                            :options="streets"
                                            v-model="details.street"
                                            :class="['v-select--custom', streetError ? 'is-invalid' : '']"
                                            @input="streetError = false"
                                        >
                                            <template #no-options>
                                                <span
                                                    v-if="displayElement('hide-street-input-no-options')"
                                                ></span>
                                                <span v-else
                                                    >{{
                                                    $t('widget.mo.no.street')
                                                    }}</span
                                                >
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label
                                        for="houseNumber"
                                        :class="[houseNumberError ? 'text-danger' : '']"
                                    >
                                        {{ $t('widget.mo.house.number') }}
                                    </label>
                                    <div class="form-group">
                                        <input
                                            id="houseNumber"
                                            type="text"
                                            :class="['form-control', houseNumberError ? 'is-invalid' : '']"
                                            v-model="details.houseNumber"
                                            @change="houseNumberError = false"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4 pt-1 ms-0">
                                <div
                                    class="col-12 col-lg-8 form-check"
                                    v-if="!displayElement('hide-confirmation-checkbox')"
                                >
                                    <label
                                        :class="[
                                            'form-check-label',
                                             displayElement('branded-checkbox')
                                                ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                : '',
                                             confirmationCheckboxError ? 'is-invalid text-danger' : ''
                                        ]"
                                        for="cancellation-confirmation"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="cancellation-confirmation"
                                            v-model="details.confirmation"
                                            @change="confirmationCheckboxError = false"
                                        />
                                        {{ $t('widget.mo.confirmation') }}
                                        <i></i>
                                        <span></span>
                                    </label>
                                </div>

                                <div
                                    class="col-12 text-end"
                                    :class="[displayElement('move-button') ? 'col-lg-12' : 'col-lg-4']"
                                >
                                    <button
                                        class="btn btn-primary"
                                        @click="submit"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.c.confirm') }}
                                    </button>
                                </div>
                            </div>

                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
