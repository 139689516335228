// -----------------------------
//   Contact Data structure
// -----------------------------

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import Auth from '../../interfaces/Auth';
import Contact from '../../interfaces/Contact';

import * as Sentry from '@sentry/browser';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contactProvider = IocContainer.get<Contact>(SERVICES.CONTACT);

// Data Structure
const state = {
    onLoad: false,
    categories: [],
};

// Outside methods for communication with data.
const actions = {
    async categories({ commit }) {
        if (authProvider.isAuthenticated()) {
            await contactProvider.categories().then(
                (response) => {
                    if (response.data && response.data.success) {
                        commit('SET_CATEGORIES', response.data);
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    async categoriesForClient({ commit }, clientKey) {
        await contactProvider.categoriesForClient(clientKey).then(
            (response) => {
                if (response.data && response.data.success) {
                    commit('SET_CATEGORIES', response.data);
                }
            },
            (error) => {
                Sentry.captureException(new Error(error));
            }
        );
    },
};

// Logic that change data
const mutations = {
    SET_CATEGORIES(localState, data) {
        localState.onLoad = true;
        if (data.categories.length > 0) {
            localState.categories = data.categories;
        }
    },
};

// Get Data transformation (like computed)
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
