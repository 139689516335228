import { injectable } from 'inversify';
import axios, { AxiosResponse } from 'axios';
import PrototypeProvider from '@/providers/PrototypeProvider';
import Meter from '@/interfaces/Meter';
import SubmitMeter from '@/interfaces/SubmitMeter';

@injectable()
export default class MeterProvider extends PrototypeProvider implements Meter {
    constructor() {
        super();
        this.http = axios.create({
            baseURL: process.env.VUE_APP_API_URL + '/v1/',
            headers: {
                Accept: 'application/json',
                Authorization: this.getApiAuth(),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                'WD-PORTAL-ENVIRONMENT': this.getAppEnv(),
                'WD-OVERWRITE-POWERCLOUD-APIS':
                    process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true',
            },
        });

        this.http.interceptors.response.use(
            (response) => response,
            this.responseErrorHandler
        );
    }

    public async send(data: SubmitMeter): Promise<AxiosResponse<any>> {
        return await this.http.post('meter/reading', data);
    }
}
