<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-2">
                <i class="fas fa-info-circle contactDetails-icon"></i>
            </div>
            <div class="col-10">
                <div class="actionMiddleWidget">
                    <i
                        class="fa fa-ellipsis-h"
                        @click="$emit('toggleWidget', 'tariffDetailsWidgetEts')"
                    ></i>
                </div>
                <div class="customerCommunications-title static-title">
                    {{ $t('widget.td.title') }}
                </div>
                <div>{{ $t('widget.td.subtitle') }}</div>
            </div>
        </div>
    </div>
</div>
