import WithRender from './dsp-flex.html';
import { default as Component } from 'vue-class-component';
import { CustomerDataCorrectionWidget } from '../customer-data-correction-widget';

import moment from 'moment';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({})
export class CustomerDataCorrectionWidgetDspFlex extends CustomerDataCorrectionWidget {
    public showMetersPopup = false;

    // Today + 3 Weeks
    // id:"desired_date"
    // id:"terminated_in_person"
    // ----
    // Today
    // id:"relocation_at"

    get firstValidDeliveryDate(): any {
        const date = new Date();
        switch (this.customerSpecificationObject.id) {
            case 'desired_date':
            case 'terminated_in_person':
                const deliveryDay21 = moment(
                    date.setDate(date.getDate() + 21)
                ).format('YYYY-MM-DD');
                return moment(new Date(deliveryDay21)).valueOf();
            case 'relocation_at':
                const deliveryDay = moment(date.setDate(date.getDate())).format(
                    'YYYY-MM-DD'
                );
                return moment(new Date(deliveryDay)).valueOf();
        }
        return date.setDate(date.getDate() - 1);
    }

    protected focusEventDatetime() {
        // const currentDate = moment(this.details.date).format('YYYY-MM-DD');
        // if (currentDate === this.contractDetails.startDate || currentDate === this.contractDetails.desiredStartDate) {}
        this.changeDetailsDateBySpecification();
    }

    protected changeDetailsDateBySpecification(): void {
        switch (this.customerSpecificationObject.id) {
            case 'desired_date':
            case 'terminated_in_person':
                this.details.date = moment(new Date())
                    .add(21, 'days')
                    .format('YYYY-MM-DD');
                break;
            case 'relocation_at':
                this.details.date = moment(new Date()).format('YYYY-MM-DD');
                break;
            default:
                this.details.date = moment(new Date()).format('YYYY-MM-DD');
                break;
        }
    }

    get minDate(): string {
        switch (this.customerSpecificationObject.id) {
            case 'desired_date':
            case 'terminated_in_person':
                return moment(new Date()).add(21, 'days').format('YYYY-MM-DD');
            case 'relocation_at':
                return moment(new Date()).format('YYYY-MM-DD');
            default:
                return moment(new Date()).format('YYYY-MM-DD');
        }
    }

    public validateDateWithChangeDate(): boolean {
        this.changeDetailsDateBySpecification();
        return this.validateDate();
    }

    public isDateValid(dt): any {
        const selectedDate = dt.split('T');
        const date = moment(new Date(selectedDate[0].toString())).valueOf();
        return (
            date >= this.firstValidDeliveryDate && date <= this.lastValidDate
        );
    }

    protected closePopupByWrapper() {
        if (this.showMetersPopup) {
            this.showMetersPopup = false;
        }
    }
}
