import { TariffRecommendation } from '@/components/widgets/tariff-recommendation/tariff-recommendation';

const widget = TariffRecommendation;

// if (process.env.VUE_APP_ENVIRONMENT === '') {
// widget = ;
// } else {
//     widget = TariffRecommendation;
// }
export { widget as TariffRecommendation };
