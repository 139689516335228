import { LeftAside } from '@/components/template/left-aside/left-aside';
import ThgServiceElliDefault from '@/models/thg/ThgServiceElliDefault';
import { default as Component } from 'vue-class-component';
import WithRender from './elli.html';

@WithRender
@Component({})
export class LeftAsideElli extends LeftAside {
    public isThgNotDealer(contract) {
        return this.isThg(contract) && !this.isDealer(contract);
    }

    public showDefaultView(contract) {
        if (contract.type === 'non_commodity') {
            const tariff = this.$store.getters['tariff/getState'](
                contract.contractId
            );
            let isDealer = false;
            if (
                tariff &&
                tariff.nonCommodityProduct &&
                tariff.nonCommodityProduct.productCode
            ) {
                isDealer =
                    tariff.nonCommodityProduct.productCode.includes(
                        'Haendlerprovision'
                    );
            }

            return isDealer;
        }

        return true;
    }

    public processTitleElli(contract) {
        if (contract.type === 'non_commodity') {
            if (this.isThgNotDealer(contract)) {
                return this.$t('non.commodity.thg.title').toString();
            }

            if (this.isDealer(contract)) {
                const tariff = this.$store.getters['tariff/getState'](
                    contract.contractId
                );
                const productCode = tariff.product.code;
                if (productCode) {
                    if (productCode.includes('_ELLI_')) {
                        return this.$t('non.commodity.product.elli').toString();
                    }
                    if (productCode.includes('_VW_')) {
                        return this.$t('non.commodity.product.vw').toString();
                    }
                    if (productCode.includes('_VWFS_')) {
                        return this.$t('non.commodity.product.vwfs').toString();
                    }
                    if (productCode.includes('_Skoda_')) {
                        return this.$t(
                            'non.commodity.product.skoda'
                        ).toString();
                    }
                    if (productCode.includes('_Cupra_')) {
                        return this.$t(
                            'non.commodity.product.cupra'
                        ).toString();
                    }
                    if (productCode.includes('_Audi_')) {
                        return this.$t('non.commodity.product.audi').toString();
                    }
                }
            }
        }

        return this.processTitle(contract.title);
    }

    public getLicensePlateForContract(contract): string {
        const plate: string = ThgServiceElliDefault.instance.getLicensePlate(
            contract && contract.extendedData ? contract.extendedData : {}
        );

        const hasNoPlate: boolean =
            !plate ||
            typeof plate == 'undefined' ||
            (typeof plate == 'string' &&
                plate.toLocaleLowerCase() ==
                    ThgServiceElliDefault.FIELD_VALUE_NONE.toLocaleLowerCase());
        return hasNoPlate ? '' : plate;
    }

    public isProductExists(contract): boolean {
        const tariff = this.$store.getters['tariff/getState'](
            contract.contractId
        );

        return !!tariff.product.code;
    }
}
