<div class="col-12 mobile-modal__inner" data-test="recommendation-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div id="recommendation-content" class="mobile-modal__wrap">
                <div class="row">
                    <div class="col-12 recommendation-content-header">
                        <a
                            id="recommendation-widget-hide-form"
                            v-if="!showDescription"
                            @click="hideForm"
                        >
                            <i class="fa fa-times"></i>
                        </a>

                        <i
                            v-if="showDescription"
                            class="icon-recommendation-new"
                        ></i
                        ><i v-else class="icon-recommendation-new"></i>
                        <span class="box-title static-title"
                            >{{ $t(showDescription ?
                            'widget.recommendation.title' :
                            'widget.recommendation.title.redeem') }}</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div
                        v-if="showDescription"
                        class="col-md-12 col-xs-12 col-sm-12"
                    >
                        <template v-if="contractInExpiredState">
                            <div
                                class="recommendation-description"
                                v-html="$t('widget.recommendation.text.cancelled')"
                            ></div>
                        </template>
                        <template v-else>
                            <div
                                class="recommendation-description"
                                v-html="$t('widget.recommendation.text')"
                            ></div>
                            <button
                                class="btn btn-primary btn-recommendation d-block"
                                @click="showForm"
                            >
                                {{ $t('widget.recommendation.redeem.bonus') }}
                            </button>
                        </template>
                    </div>
                    <template v-else>
                        <div class="col-md-4 col-xs-12 col-sm-12">
                            {{ $t('widget.recommendation.your.discount.code') }}
                            <br />
                            <input
                                :class="['form-control']"
                                type="text"
                                v-model="bonusCode"
                                :disabled="submitPending || verificationPending"
                                id="input-recommendation-bonus-code"
                                name="bonusCode"
                                autocomplete="off"
                                :placeholder="$t('widget.recommendation.bonus.code')"
                            />
                            <br />
                            <button
                                class="btn btn-primary btn-verify-bonus-code d-block"
                                @click="verifyBonus"
                                :disabled="submitPending || verificationPending"
                            >
                                {{
                                $t('widget.recommendation.your.discount.code.verify')
                                }}
                            </button>
                        </div>
                        <div class="col-md-8 col-xs-12 col-sm-12">
                            <span
                                :class="isVerificationSuccessful ? 'recommendation-bonus-value-info-valid' : ''"
                                id="recommendation-bonus-value-info"
                            >
                                {{ $t('widget.recommendation.credit') }}
                                <span
                                    id="recommendation-bonus-value"
                                    v-html="bonusValue"
                                ></span>
                                {{ $t('widget.recommendation.credit.currency')
                                }}
                            </span>
                            <div id="checkbox-bonus-terms-box">
                                <div class="form-group">
                                    <div
                                        class="custom-check"
                                        :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="checkbox-bonus-terms"
                                            v-model="acceptTerms"
                                            :disabled="submitPending"
                                        />
                                        <label for="checkbox-bonus-terms">
                                            <span
                                                v-html="$t('widget.recommendation.gtc')"
                                            ></span>
                                        </label>
                                    </div>
                                    <messages-block
                                        :success="successInformation"
                                        :errors="errorInformation"
                                    ></messages-block>
                                </div>
                            </div>
                            <button
                                class="btn btn-primary btn-recommendation-submit d-block"
                                @click="submitBonus"
                                :disabled="submitPending || !isVerificationSuccessful"
                            >
                                {{ $t('widget.recommendation.redeem.now') }}
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</div>
