import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './account-transactions-widget.html';

import moment from 'moment';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Format from '../../../interfaces/Format';
import Tracking from '@/interfaces/Tracking';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({
    filters: {
        date: formatProvider.date(),
        euro: formatProvider.euro(),
    },
})
export class AccountTransactionsWidget extends Base {
    private showTransactionsTable = {
        receivables: true,
        payments: false,
    };

    protected elements = {
        bsw: [
            'btn-group-class',
            'btn-secondary-class',
            'chart-line-icon',
            'hidden-table-name',
        ],
        wdenergy: ['fixed-th'],
        'wdenergy-us': ['fixed-th'],
        sachsenenergie: ['custom-payment-notice', 'custom-receivable-notice'],
        'elli-thg-dealer': ['show-info-icon'],
    };

    private activeTransactions: number[] = [];

    protected mounted(): void {
        trackingProvider.showContractAccount();
    }

    protected destroyed(): void {
        trackingProvider.hideContractAccount();
    }

    get paymentTransactions(): Record<any, any> {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).accountBalance.transactions.payment.map((obj) => {
            let notice = obj.notice;

            if (
                this.displayElement('custom-payment-notice') &&
                obj.type === 'in'
            ) {
                notice = this.$t('widget.ab.payment.in', {
                    month: moment(obj.createdAtDate)
                        .locale('de')
                        .format('MMMM'),
                    year: moment(obj.createdAtDate).year(),
                    commodity: this.contract.title,
                });
            }

            return {
                ...obj,
                active: this.activeTransactions.includes(obj.bookingNumber),
                notice,
            };
        });
    }

    get receivableTransactions(): Record<any, any> {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).accountBalance.transactions.receivable.map((obj) => {
            let notice = obj.notice;

            if (
                this.displayElement('custom-payment-notice') &&
                obj.type === 'deposit'
            ) {
                notice = this.$t('widget.ab.receivable.deposit', {
                    month: moment(obj.payableAt).locale('de').format('MMMM'),
                    year: moment(obj.payableAt).year(),
                    commodity: this.contract.title,
                });
            }

            return {
                ...obj,
                active: this.activeTransactions.includes(obj.bookingNumber),
                notice,
            };
        });
    }

    get contract(): Record<any, any> {
        return this.contractsList.find((contract) => {
            return contract.contractId === this.contractId;
        });
    }

    get noticeText(): string {
        return this.$store.state.settings.accountTransactionNotice;
    }

    protected activate(item): void {
        if (!this.activeTransactions.includes(item.bookingNumber)) {
            this.activeTransactions.push(item.bookingNumber);
        } else {
            this.activeTransactions.splice(
                this.activeTransactions.findIndex(
                    (e) => e === item.bookingNumber
                ),
                1
            );
        }
    }

    private toggleTransactionsTable(key: string): void {
        this.closeTransactionsTable();
        this.showTransactionsTable[key] = true;
    }

    private closeTransactionsTable(): void {
        this.showTransactionsTable.receivables = false;
        this.showTransactionsTable.payments = false;
    }
}
