import Vue from 'vue';
import Base from '@/mixins/base';
import Component, { mixins } from 'vue-class-component';

// Call Render template.
import WithRender from './maintenance.html';

import moment from 'moment';

@WithRender
@Component({})
export class Maintenance extends mixins(Vue, Base) {
    public maintenanceStart = null;
    public maintenanceEnd = null;
    public maintenanceText = null;
    public isMaintenanceMode = false;
    public maintenanceShowLogo = false;

    protected message = '';

    private timerId;

    get showMessageTimer() {
        return this.maintenanceText ? this.maintenanceText : this.message;
    }

    protected created(): void {
        this.maintenanceStart =
            this.$store.getters['settings/getState']().maintenanceStart;
        this.maintenanceEnd =
            this.$store.getters['settings/getState']().maintenanceEnd;
        this.maintenanceText =
            this.$store.getters['settings/getState']().maintenanceText;
        this.isMaintenanceMode =
            this.$store.getters['settings/getState']().isMaintenanceMode;
        this.maintenanceShowLogo =
            this.$store.getters['settings/getState']().maintenanceShowLogo;

        if (
            this.maintenanceText === null ||
            typeof this.maintenanceText === 'undefined' ||
            this.maintenanceText === ''
        ) {
            this.timerId = setInterval(this.updateTimer, 1000);
        }
    }

    protected updateTimer() {
        const duration = moment.duration(
            moment(this.maintenanceEnd).diff(moment(Date.now()))
        );

        if (
            duration.days() > 0 ||
            duration.hours() > 0 ||
            duration.minutes() > 0 ||
            duration.seconds() > 0
        ) {
            let timer = '';

            if (duration.days() > 0) {
                timer +=
                    ' ' +
                    this.$tc(
                        'maintenance.days',
                        duration.days()
                    ).toLocaleString();
            }

            if (duration.hours() > 0) {
                timer +=
                    ' ' +
                    this.$tc(
                        'maintenance.hours',
                        duration.hours()
                    ).toLocaleString();
            }

            if (duration.minutes() > 0) {
                timer +=
                    ' ' +
                    this.$tc(
                        'maintenance.minutes',
                        duration.minutes()
                    ).toLocaleString();
            }

            if (duration.seconds() > 0) {
                timer +=
                    ' ' +
                    this.$tc(
                        'maintenance.seconds',
                        duration.seconds()
                    ).toLocaleString();
            }
            this.message = this.$t('maintenance.message.with.timer', {
                timer,
            }).toLocaleString();
        } else {
            this.message = this.$t('maintenance.message').toLocaleString();
            clearTimeout(this.timerId);
        }
    }
}
