import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import WithRender from './left-aside.html';
import { BSWLeftAside } from './custom/bsw';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import { Emit } from 'vue-property-decorator';
import moment from 'moment';

import Auth from '@/interfaces/Auth';
import CountryFlag from 'vue-country-flag';

@WithRender
@Component({
    components: {
        'bsw-left-aside': BSWLeftAside,
        CountryFlag,
    },
})
export class LeftAside extends Base {
    public displayLocaleSelect = false;
    public timeToLogout = { hour: 0, minute: 15, second: 0 };
    public timerDisplay = moment().set(this.timeToLogout);
    public displayTimeOut = false;
    public interval = 0;
    public idleAt: any = null;
    private version = process.env.VUE_APP_ENVIRONMENT_STAGE;
    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    protected elements = {
        bsw: [
            'aside-menu--welcome',
            'bsw-left-aside',
            'customer-data-alt_v2',
            'no-hastag',
            'contract-list-order-by-id',
        ],
        sachsenenergie: [
            'commodity-icon',
            'contract-instead-customer',
            'customer-data-alt',
            'logout-simple-link',
            'welcome-exclamation',
            'unlock-icon',
            'timeout-countdown',
        ],
        buehl: ['custom-commodity-icons'],
    };

    @Watch('isAppIdle')
    public onIsAppIdleLoad(newVal): void {
        if (newVal) {
            this.displayTimeOut = true;
            this.idleAt = moment();
        } else {
            this.displayTimeOut = false;
            if (this.idleAt) {
                const remainingSeconds = this.idleAt
                    .add(this.timeToLogout)
                    .diff(moment(), 'seconds');
                this.idleAt = null;
                if (remainingSeconds <= 0) {
                    this.logout();
                }
            }
        }
    }

    get locales(): string[] {
        return this.$i18n.availableLocales;
    }

    get currentLocale(): string {
        return this.$i18n.locale;
    }

    get customerData(): Record<any, any> {
        return this.$store.state.customer;
    }

    get contractList(): Record<any, any> {
        if (!this.$store.state.contracts.list) {
            return [];
        }
        return this.$store.state.contracts.list;
    }

    get selectedContractId(): number | false {
        return this.$route.name === 'dashboardId' ||
            this.$route.name === 'sales-journey'
            ? this.$store.state.contracts.contractId
            : false;
    }

    get toggleSidebarClass(): string {
        if (this.$attrs.toggleSidebar) {
            return 'm-aside-left--on';
        } else {
            return '';
        }
    }

    get displayChangePassword(): boolean {
        return this.$store.state.settings.changePasswordEnabled;
    }

    get time(): string {
        return this.timerDisplay.format('HH:mm:ss');
    }

    get showLogoutSuccessfulMessage(): boolean {
        return this.$store.state.settings.showLogoutSuccessfulMessage;
    }

    get isAuthPlusActive() {
        return this.$store.state.settings.isAuthPlusActive;
    }

    get isTwoFactorAvailable() {
        return (
            this.isAuthPlusActive &&
            this.$store.state.settings.isAuthPlusTwoFactorAuthenticationActive
        );
    }

    public toggleLocaleSelect(): void {
        this.displayLocaleSelect = !this.displayLocaleSelect;
    }

    @Watch('displayTimeOut')
    protected onStartTimeOut(newVal): void {
        if (newVal) {
            const startTimerDisplay = this.timerDisplay;
            this.interval = window.setInterval(() => {
                this.timerDisplay = moment(
                    this.timerDisplay.subtract(1, 'seconds')
                );
                if (
                    startTimerDisplay.diff(this.timerDisplay, 'minutes') >= 15
                ) {
                    this.displayTimeOut = false;
                    window.clearInterval(this.interval);
                    this.timerDisplay = startTimerDisplay;
                    this.logout();
                }
            }, 1000);
        } else if (!newVal && this.interval) {
            this.timerDisplay = moment().set(this.timeToLogout);
            window.clearInterval(this.interval);
        }
    }

    private changeLocale(locale: string): void {
        this.$i18n.locale = locale;
        localStorage.setItem('wd-portal-language', this.$i18n.locale);
    }

    private customCommodityIcons(energy: string): string {
        return `/img/evus/${this.currentClient}/${energy}.svg`;
    }

    get settings(): Record<any, any> {
        return this.$store.state.settings;
    }

    public processTitle(title: string): string {
        if (this.currentClient === 'eoptimum') {
            switch (title) {
                case 'Gas':
                    return this.$t('contract.title.gas').toString();
                case 'Strom':
                    return this.$t('contract.title.strom').toString();
            }
        }

        return title;
    }

    private logout(): void {
        if (this.showLogoutSuccessfulMessage) {
            localStorage.setItem('wd-portal-show-goodbye-message', '1');
        }
        this.auth.logout(this.settings.idpLogoutUrl);
    }

    @Emit('toggle-modal')
    private toggleOpenChangePasswordModal(): void {
        return;
    }

    @Emit('toggle-two-factor-authentication-modal')
    private toggleOpenTwoFactorAuthenticationModal() {
        return;
    }
}
