import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './footer.html';

@WithRender
@Component({})
export class Footer extends Base {
    protected elements = {
        bsw: ['contacts-bsw'],
        ets: ['contacts-ets'],
        prokon: ['contacts-prokon'],
        qcells: ['contacts-qcells'],
        wdenergy: [
            'contacts-col-sm-12-class',
            'links-col-sm-12-class',
            'contacts-wdenergy',
        ],
        'wdenergy-us': [
            'contacts-col-sm-12-class',
            'links-col-sm-12-class',
            'contacts-wdenergy',
        ],
        sachsenenergie: ['contacts-sachsenenergie', 'hide-telephone-block'],
        'dsp-*': ['hide-telephone-block'],
        eoptimum: ['contacts-eoptimum'],
        hsg: ['contacts-hsg'],
        wechselstrom: ['contacts-wechselstrom'],
    };

    get logo(): string {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            case 'ets':
                return (
                    'background-image: url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/logo_footer.svg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/logo_footer.jpg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/logo_footer.png);'
                );
            default:
                return (
                    'background-image: url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/logo.svg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/logo.jpg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/logo.png);'
                );
        }
    }

    get year(): number {
        return new Date().getFullYear();
    }
}
