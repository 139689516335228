import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './deposit-change-widget.html';
import moment from 'moment';
import { Datetime } from 'vue-datetime';

import vSelect from 'vue-select';

import { MessagesBlock } from '@/components/snippets/messages';

import { Watch } from 'vue-property-decorator';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import * as Sentry from '@sentry/browser';

import { SurveyWidget } from '@/components/widgets/survey-widget';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';
import Payment from '@/interfaces/Payment';
import Format from '@/interfaces/Format';
import SubmitDeposit from '@/interfaces/SubmitDeposit';
import Tracking from '@/interfaces/Tracking';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const paymentProvider = IocContainer.get<Payment>(SERVICES.PAYMENT);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({
    components: {
        'vue-slider': VueSlider,
        'survey-widget': SurveyWidget,
        datetime: Datetime,
        vSelect,
        'messages-block': MessagesBlock,
    },
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
        euroSpecial: formatProvider.euroSpecial(),
    },
})
export class DepositChangeWidget extends Base {
    protected details: SubmitDeposit = {
        contractId: this.$store.state.contracts.contractId,
        depositValue: null,
        activeFrom: null,
        alternativeDayOfMonth: null,
    };

    protected errorInformation: Record<any, any> = [];
    protected successInformation: Record<any, any> = [];
    protected submitPending = false;
    protected showSurvey = false;

    protected elements = {
        bsw: [
            'alert-in-column',
            'coins-icon',
            'depositDetails-current',
            'default-btn',
            'deposit-overview-bottom',
            'deposit-overview-bottom-secondary',
        ],
        wdenergy: ['deposit-inside-text'],
        'wdenergy-us': ['deposit-inside-text'],
        prokon: ['deposit-overview-bottom'],
    };

    get showActiveFrom(): boolean {
        return this.$store.state.settings.activeFromDeposit;
    }

    get showAlternativeDayOfMonth(): boolean {
        return this.$store.state.settings.activeAlternativeDayOfMonthDeposit;
    }

    get minDate(): string {
        return moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
    }

    get daysList(): Record<any, any> {
        return Array.from(Array(31).keys()).map((item, index) => index + 1);
    }

    get depositDetails(): Record<any, any> {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit;
    }

    get depositOverviewAllowed(): boolean {
        return this.$store.state.settings.depositOverview;
    }

    get datetimePhrases(): Record<any, any> {
        return {
            ok: this.$t('datetime.ok'),
            cancel: this.$t('datetime.cancel'),
        };
    }

    get planButtonClasses(): Record<any, any> {
        return { 'mt-5': this.displayElement('deposit-overview-bottom') };
    }

    public tooltipFormatter(v): string {
        return (
            this.$options.filters?.euroSpecial(v) +
            ' ' +
            this.$t('euro').toLocaleString()
        );
    }

    protected mounted(): void {
        trackingProvider.showAdvancePaymentChange();
        this.details.contractId = this.$store.state.contracts.contractId;
        this.details.depositValue = this.depositDetails.current;
        if (this.currentClient === 'ets') {
            this.$store.dispatch(
                'payment/accountBalance',
                this.$store.state.contracts.contractId
            );
        }
        if (this.depositOverviewAllowed) {
            this.$store.dispatch(
                'payment/depositList',
                this.$store.state.contracts.contractId
            );
        }
    }

    protected destroyed(): void {
        trackingProvider.hideAdvancePaymentChange();
    }

    @Watch('depositDetails.current')
    protected onDepositDetailsChange(): void {
        this.details.contractId = this.$store.state.contracts.contractId;
        this.details.depositValue = this.depositDetails.current;
    }

    protected async submit(): Promise<void> {
        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            await paymentProvider.submitDeposit(this.details).then(
                (response) => {
                    this.submitPending = false;
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        trackingProvider.masterDataChangesAdvanceAdjustment();
                        this.$store.dispatch(
                            'payment/depositCurrent',
                            this.$store.state.contracts.contractId
                        );
                        this.$store.dispatch(
                            'payment/depositDetails',
                            this.$store.state.contracts.contractId
                        );
                        if (this.depositOverviewAllowed) {
                            this.$store.dispatch(
                                'payment/depositList',
                                this.$store.state.contracts.contractId
                            );
                        }
                        this.$store.dispatch(
                            'payment/depositPlan',
                            this.$store.state.contracts.contractId
                        );

                        this.showSurvey = true;
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }
}
