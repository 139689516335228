import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './status-bar-widget.html';
import moment from 'moment';

@WithRender
@Component({})
export class StatusBarWidget extends Base {
    protected elements = {
        bsw: ['calendar-icon', 'check-icon'],
        wdenergy: [
            'checkbox-icon',
            'closer-title',
            'shipping-address',
            'hide-status-number-single',
        ],
        'wdenergy-us': [
            'checkbox-icon',
            'closer-title',
            'shipping-address',
            'hide-status-number-single',
        ],
        lichblick: ['shipping-address'],
        sachsenenergie: ['check-icon', 'hide-status-number-single'],
        'dsp-*': ['check-icon', 'highlight-issue'],
    };

    get statusesOptions(): {
        title: string;
        description: string;
        completed: boolean;
        highlight: number[];
        fields: any;
    }[] {
        return [
            {
                title: 'widget.status.created',
                description: this.showContractData
                    ? 'widget.status.created.desc'
                    : 'widget.status.created.order.desc',
                completed:
                    this.contract.statusCode >= 1000 &&
                    this.contract.statusCode < 8000,
                highlight: [],
                fields: {},
            },
            ...(this.contractDateIsSet
                ? [
                      {
                          title: 'widget.status.cancellation',
                          description: 'widget.status.willBe.cancelled',
                          completed:
                              this.contract.statusCode >= 2000 &&
                              this.contract.statusCode < 2002,
                          highlight: [],
                          fields: {},
                      },
                      {
                          title: 'widget.status.cancellation',
                          description: 'widget.status.hasBeen.cancelled',
                          completed:
                              this.contract.statusCode > 2001 &&
                              this.contract.statusCode < 8000,
                          highlight: [],
                          fields: {},
                      },
                  ]
                : []),
            {
                title: 'widget.status.registration',
                description: 'widget.status.willBe.registered',
                completed:
                    this.contract.statusCode >= 3000 &&
                    this.contract.statusCode < 3005,
                highlight: [],
                fields: {},
            },
            {
                title: 'widget.status.registration',
                description: 'widget.status.hasBeen.registered',
                completed:
                    this.contract.statusCode > 3004 &&
                    this.contract.statusCode < 8000,
                highlight: [3004],
                fields: {},
            },
            {
                title: 'widget.status.waiting',
                description: 'widget.status.waiting.desc',
                completed:
                    this.contract.statusCode >= 4000 &&
                    this.contract.statusCode < 8000,
                highlight: [],
                fields: {
                    startDate: this.startDate,
                },
            },
            {
                title: 'widget.status.delivery',
                description: 'widget.status.delivery.desc',
                completed:
                    this.contract.statusCode >= 5000 &&
                    this.contract.statusCode < 8000,
                highlight: [],
                fields: {},
            },
            {
                title: 'widget.status.terminated',
                description: 'widget.status.terminated.desc',
                completed:
                    this.contract.statusCode >= 6000 &&
                    this.contract.statusCode < 8000,
                highlight: [],
                fields: {},
            },
            {
                title: 'widget.status.expired',
                description: 'widget.status.expired.desc',
                completed:
                    this.contract.statusCode >= 7000 &&
                    this.contract.statusCode < 8000,
                highlight: [],
                fields: {},
            },
            {
                title: 'widget.status.canceled',
                description: 'widget.status.canceled.desc',
                completed: this.contract.statusCode >= 8000,
                highlight: [],
                fields: {},
            },
        ];
    }

    protected created(): void {
        if (this.$store.state.contracts.contractId) {
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
        }
    }

    get contract(): Record<any, any> {
        const list = this.$store.state.contracts.list;

        const contractInfo = list
            ? list.filter((contract) => {
                  return (
                      contract.contractId ===
                      this.$store.state.contracts.contractId
                  );
              })[0]
            : null;

        if (!contractInfo) {
            return {
                contractId: null,
                status: null,
                statusCode: null,
                statusCodeTitle: null,
                startDate: null,
            };
        }

        return contractInfo;
    }

    get startDate(): string {
        if (!this.contract.startDate) {
            return '';
        }
        return Date.parse(this.contract.startDate)
            ? moment(this.contract.startDate).format('DD.MM.YYYY')
            : this.contract.startDate;
    }

    get statuses(): Record<any, any> {
        return this.statusesOptions.filter((status) => {
            return status.completed;
        });
    }

    get displayStatusNumber(): boolean {
        return !(
            this.displayElement('hide-status-number-single') &&
            this.statuses.length === 1
        );
    }

    get contractDateIsSet(): boolean {
        const tariffState = this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        );
        return (
            tariffState &&
            typeof tariffState.contract === 'object' &&
            (tariffState.contract.customerSpecification ===
                'earliest_possible_date' ||
                tariffState.contract.customerSpecification === 'desired_date')
        );
    }

    get showContractData(): boolean {
        return !(
            this.contract &&
            this.contract.type === 'non_commodity' &&
            this.contract.energy === 'non_energy'
        );
    }

    get registrationIssue(): boolean {
        return (
            this.displayElement('highlight-issue') &&
            (this.contract.statusCode === 2004 ||
                this.contract.statusCode === 3004)
        );
    }

    private highlightStatus(status): boolean {
        return status.highlight.includes(this.contract.statusCode);
    }
}
