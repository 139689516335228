import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './welcome-widget.html';

@WithRender
@Component({})
export class WelcomeWidget extends Base {
    protected elements = {
        sachsenenergie: ['one-line-without-contract'],
        qcells: ['show-contact'],
        prokon: [
            'show-contract-phrase',
            'show-contract',
            'hide-hash-contractId',
        ],
        eoptimum: ['show-personalized-message'],
    };

    get contract() {
        if (this.$store.state.contracts.list) {
            const contractInfo = this.$store.state.contracts.list.filter(
                (contract) => {
                    return (
                        contract.contractId ===
                        this.$store.state.contracts.contractId
                    );
                }
            )[0];
            if (!contractInfo) {
                return {
                    contractId: null,
                    title: null,
                };
            }
            return contractInfo;
        }
        return {
            contractId: null,
            title: null,
        };
    }

    get currentTariff() {
        return this.$store.state.tariff;
    }

    get customerData() {
        return this.$store.state.customer;
    }

    get isMorning() {
        const now = new Date();
        return now.getHours() <= 9 && now.getMinutes() <= 59;
    }

    get contractId() {
        return this.$store.state.contracts.contractId;
    }

    get isMidDay() {
        const now = new Date();
        return (
            now.getHours() >= 10 &&
            now.getHours() <= 17 &&
            now.getMinutes() <= 59
        );
    }

    get isEvening() {
        const now = new Date();
        return now.getHours() >= 18;
    }

    get lastLogin() {
        return '09 Feb 2021, 03:23';
    }

    get productTitle() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).product.title;
    }

    get showContractTitle() {
        return (
            this.contract.type !== 'non_commodity' &&
            this.contract.energy !== 'non_energy'
        );
    }

    get nonCommodityProductTitle() {
        const productCode = this.currentNoCommodityProduct.productCode;
        if (productCode) {
            if (productCode.includes('_ELLI_')) {
                return this.$t('non.commodity.product.elli');
            }
            if (productCode.includes('_VW_')) {
                return this.$t('non.commodity.product.vw');
            }
            if (productCode.includes('_VWFS_')) {
                return this.$t('non.commodity.product.vwfs');
            }
            if (productCode.includes('_Skoda_')) {
                return this.$t('non.commodity.product.skoda');
            }
            if (productCode.includes('_Cupra_')) {
                return this.$t('non.commodity.product.cupra');
            }
            if (productCode.includes('_Audi_')) {
                return this.$t('non.commodity.product.audi');
            }
        }
        return this.$t('widget.nc.title');
    }

    public processTitle(title: string): string {
        if (this.currentClient === 'eoptimum') {
            switch (title) {
                case 'Gas':
                    return this.$t('contract.title.gas').toString();
                case 'Strom':
                    return this.$t('contract.title.strom').toString();
            }
        }

        return title;
    }
}
