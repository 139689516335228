<div class="m-portlet m-portlet--mobile m-portlet--invoice">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12 col-invoice">
                <div
                    class="d-flex align-items-center"
                    v-if="currentClient == 'wdenergy'"
                >
                    <i class="fa fa-file-invoice readingHistory-icon"></i>
                    <span
                        class="
                            ms-2
                            monthlyDeposit-title
                            static-title static-title--custom
                        "
                        >{{ $t('widget.i.title') }}</span
                    >
                </div>
                <template v-else>
                    <i class="fa fa-file-invoice readingHistory-icon"></i>
                    <span
                        class="
                            monthlyDeposit-title
                            static-title static-title--custom
                        "
                        >{{ $t('widget.i.title') }}</span
                    >
                </template>
                <div
                    class="actionMiddleWidget"
                    @click="changeShowContent"
                    v-if="displayElement('show-more-button')"
                >
                    <i class="fa fa-ellipsis-h"></i>
                </div>
                <table
                    :class="['table table-striped', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                    id="invoiceList-content"
                    v-if="showContent"
                >
                    <tbody>
                        <tr>
                            <th
                                data-name="Rechnungsart"
                                v-if="displayElement('invoice-type')"
                            >
                                <span>{{ $t('widget.i.invoiceType') }}</span>
                            </th>
                            <th
                                :data-name="$t('widget.i.billNumber')"
                                class="d-block"
                            >
                                <span>{{ $t('widget.i.billNumber') }}</span>
                            </th>
                            <th data-name="Zeitraum">
                                <span>{{ $t('widget.i.billCycle') }}</span>
                            </th>
                            <th
                                data-name="Verbrauchsmenge"
                                v-if="displayElement('invoice-usage')"
                            >
                                <span>{{ $t('widget.i.usage') }}</span>
                            </th>
                            <th data-name="Betrag">
                                <span>{{ $t('widget.i.amount') }}</span>
                            </th>
                            <th data-name="Details">
                                <span>{{ $t('widget.i.details') }}</span>
                            </th>
                        </tr>
                        <template v-if="invoicesMapped.length > 0">
                            <template v-for="invoice in invoicesMapped">
                                <tr :class="{'active': invoice.active}">
                                    <td v-if="displayElement('invoice-type')">
                                        {{ $t('widget.i.' + invoice.invoiceType)
                                        }}
                                    </td>
                                    <td
                                        data-name="mobile-main"
                                        @click="activate(invoice)"
                                    >
                                        {{ invoice.invoiceNumber }}
                                    </td>
                                    <td :data-name="$t('widget.i.billCycle')">
                                        {{ invoice.periodStart | date }} - {{
                                        invoice.periodEnd | date }}
                                    </td>
                                    <td v-if="displayElement('invoice-usage')">
                                        {{ invoice.usage | euro }} {{ $t('kwh')
                                        }}
                                    </td>
                                    <td :data-name="$t('widget.i.amount')">
                                        {{ (invoiceShowUnsettledValue ?
                                        invoice.unsettledValue : invoice.value)
                                        | euro }} {{ $t('euro') }}
                                    </td>
                                    <td class="td-invoice-download">
                                        <template
                                            v-if="invoice.isDownloadAvailable"
                                        >
                                            <a
                                                v-if="displayElement('downloadInvoice-link')"
                                                href="#"
                                                :class="[
                                                    checkHasDownloadError(invoice.invoiceNumber) ? 'downloadError' : ''
                                                ]"
                                                @click.prevent="downloadInvoice(invoice)"
                                            >
                                                <i
                                                    :title="$t('widget.i.download')"
                                                    class="fas fa-file-pdf"
                                                ></i>
                                            </a>
                                            <button
                                                v-else
                                                :disabled="checkHasDownloadError(invoice.invoiceNumber)"
                                                :class="[
                                                    'btn btn-sm btn-invoice-download',
                                                    displayElement('download-btn-link') ? 'btn-link' : 'btn-primary',
                                                    checkHasDownloadError(invoice.invoiceNumber) ? 'downloadError' : ''
                                                ]"
                                                @click="downloadInvoice(invoice)"
                                            >
                                                <span
                                                    >{{ $t('widget.i.download')
                                                    }}</span
                                                >
                                            </button>
                                        </template>
                                    </td>
                                </tr>
                                <tr
                                    v-if="checkHasDownloadError(invoice.invoiceNumber)"
                                ></tr>
                                <tr
                                    v-if="checkHasDownloadError(invoice.invoiceNumber)"
                                    class="invoice-download-error"
                                >
                                    <td colspan="100%">
                                        {{ $t('widget.cbd.postbox.error.file')
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <template v-else
                            ><span
                                v-if="displayElement('show-no-invoices-text')"
                                >{{$t('widget.i.no.invoices')}}</span
                            >
                        </template>
                    </tbody>
                </table>
                <a
                    v-if="displayElement('show-personal-info-link')"
                    :href="$t('widget.infoPersonalDataLink')"
                    class="personal-info-link info-link-m0"
                    target="_blank"
                    >{{ $t('widget.infoPersonalData') }}</a
                >
                <template
                    v-if="displayElement('show-invoice-explanation-link')"
                >
                    <span>{{ $t('widget.invoice.explanation.text') }}</span>
                    <a
                        :href="$t('widget.invoice.explanation.link')"
                        class="personal-info-link info-link-m0"
                        target="_blank"
                        >{{ $t('widget.invoice.explanation.link.text') }}</a
                    >
                </template>

                <content-loader
                    v-if="!invoicesLoaded"
                    height="20"
                    data-test="CONTENT-LOADER"
                ></content-loader>
            </div>
        </div>
    </div>
</div>
