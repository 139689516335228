import { ChangePaymentDataWidget } from '../change-payment-data-widget';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import WithRender from './ets.html';

@WithRender
@Component({})
export class ChangePaymentDataWidgetEts extends ChangePaymentDataWidget {
    private paymentType = 'sepa';
    private isAccountHolder = false;
    private alternativeAccountHolder = '';
    private sepaAgree = false;
    private showTransfer = false;

    private sepaError = false;

    private alternativeAccountHolderError = false;
    private sepaAgreeError = false;

    @Watch('paymentType')
    public onPaymentTypeChange(newVal: string, oldVal: string): void {
        if (newVal === 'transfer' && this.paymentDetails.directDebit) {
            this.$nextTick(function () {
                this.paymentType = oldVal;
                this.sepaError = true;
            });
        }

        this.details.directDebit = newVal === 'sepa';
    }

    @Watch('isAccountHolder')
    public onIsAccountHolderChange(newVal: boolean): void {
        if (newVal === true) {
            this.details.alternativeAccountHolder =
                this.deliveryAddress.firstname +
                ' ' +
                this.deliveryAddress.surname;
        }
        if (newVal === false) {
            this.details.alternativeAccountHolder =
                this.alternativeAccountHolder;
        }
    }

    @Watch('alternativeAccountHolder')
    public onAlternativeAccountHolderChange(newVal: string): void {
        this.details.alternativeAccountHolder = newVal;
        this.alternativeAccountHolderError = false;
    }

    @Watch('showTransfer')
    public onShowTransferChange(newVal: boolean): void {
        if (newVal === false) {
            this.details.directDebit = false;
            this.details.iban = '';
            this.details.alternativeAccountHolder = '';
            this.details.bic = null;
            this.details.bank = null;
        }
    }

    get deliveryAddress(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.deliveryAddress;
    }

    protected created(): void {
        this.paymentType =
            this.paymentDetails.directDebit === true ? 'sepa' : 'transfer';
        this.showTransfer = !this.paymentDetails.directDebit;
        this.isAccountHolder = !(
            this.paymentDetails.alternativeAccountHolder ===
            this.deliveryAddress.firstname + ' ' + this.deliveryAddress.surname
        );
    }

    protected checkData(): void {
        if (this.details.directDebit) {
            if (!this.isAccountHolder && !this.alternativeAccountHolder) {
                this.alternativeAccountHolderError = true;
                return;
            }
            if (this.paymentType === 'sepa' && !this.sepaAgree) {
                this.sepaAgreeError = true;
                return;
            }
        }

        this.submit();
    }
}
