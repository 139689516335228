import Base from '@/mixins/base';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import { ThgBonusEntry } from '@/components/widgets/thg-bonus-entry';
import moment from 'moment';
import { default as Component } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WithRender from './thg-bonus-overview.html';
import iocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import SERVICES from '@/container/Services';

const formatProvider = iocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    components: {
        ThgBonusEntry,
    },
    filters: {
        euro: formatProvider.euro(),
    },
})
export class ThgBonusOverview extends Base {
    @Prop() public bonusYear!: string;
    @Prop() public thgService!: ThgBaseService;

    public isTitleLoading: boolean = false;

    public created() {
        this.isTitleLoading = true;
        this.$store
            .dispatch(
                'contracts/products',
                this.$store.state.contracts.contractId
            )
            .then(() => {
                this.isTitleLoading = false;
            });
    }

    get isPendingForBonusYear(): boolean {
        return (
            this.thgService.getCurrentStateForYear(this.bonusYear) == 'pending'
        );
    }

    get showNewBonusInfo(): boolean {
        const hasNoBonusOrPendingBonusForNewYear: boolean =
            !this.thgService.hasBonusForYear(this.bonusYear); // || this.isPendingForBonusYear;

        let previousYear = this.thgService.getPreviousYear(this.bonusYear);
        let hasBonusForLastYear: boolean =
            this.thgService.hasBonusForYear(previousYear);
        let isBonusConfirmedForLastYear: boolean =
            this.thgService.isBonusForYearConfirmed(previousYear);

        if (
            (!isBonusConfirmedForLastYear || !hasBonusForLastYear) &&
            previousYear === '2023'
        ) {
            previousYear = this.thgService.getPreviousYear(previousYear);
            hasBonusForLastYear = this.thgService.hasBonusForYear(previousYear);
            isBonusConfirmedForLastYear =
                this.thgService.isBonusForYearConfirmed(previousYear);
        }

        return (
            hasNoBonusOrPendingBonusForNewYear &&
            hasBonusForLastYear &&
            isBonusConfirmedForLastYear &&
            this.contractInformationAvailable
        );
    }

    get newBonusInfoYear(): string {
        return moment().format('YYYY');
    }

    get contractInformationAvailable(): boolean {
        return this.currentTariff.contract.deliveryAddress.firstname !== null
            ? true
            : false;
    }

    public showBonusSelector(year: string): void {
        this.$emit('showBonusSelectorForYear', year);
    }
}
