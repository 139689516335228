<footer class="m-grid__item m-footer">
    <div class="bottom-footer">
        <div
            class="m-container m-container--fluid m-container--full-height m-page__container"
        >
            <div
                class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
            >
                <div class="bottom-footer">
                    <div
                        class="m-container m-container--fluid m-container--full-height m-page__container"
                    >
                        <div
                            class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
                        >
                            <div
                                class="row footer-contacts footer-contacts--elli"
                            >
                                <div
                                    v-if="!displayElement('hide-telephone-block')"
                                    class="col-xs-12 text-start"
                                    :class="[displayElement('contacts-col-sm-12-class') || displayElement('contacts-elli') ? 'col-sm-12' : 'col-sm-6',
                                        displayElement('contacts-eoptimum') ? 'col-xl-3 col-md-12' : ''
                                    ]"
                                >
                                    <div
                                        class="row"
                                        v-if="displayElement('contacts-qcells')"
                                    >
                                        <div class="col-sm-6 col-xs-12">
                                            <b>Hanwha Q CELLS GmbH</b><br />
                                            Energy Retail / Q.ENERGY<br />
                                            OT Thalheim<br />
                                            Sonnenallee 17 - 21<br />
                                            06766 Bitterfeld-Wolfen<br />
                                            Deutschland
                                        </div>
                                        <div class="col-sm-6 col-xs-12">
                                            <br />
                                            TEL +49 (0)3494 386 4444<br />
                                            FAX +49 (0)3494 386 4434<br />
                                            MAIL service@energie.q-cells.de<br />
                                        </div>
                                    </div>
                                    <div
                                        class="row"
                                        v-else-if="displayElement('contacts-bsw')"
                                    >
                                        <div class="col-12">
                                            <div>
                                                Rufen Sie uns an kostenfrei
                                                unter
                                            </div>
                                            <h4>0800 537 1000</h4>
                                            Montag bis Freitag 8 – 20 Uhr
                                        </div>
                                    </div>
                                    <div
                                        class="row"
                                        v-else-if="displayElement('contacts-prokon')"
                                    >
                                        <div class="col-12">
                                            <div>
                                                Wir sind gern persönlich für Sie
                                                da:
                                            </div>
                                            <br />
                                            <h4>Telefon: 04821 6855-0</h4>
                                            Mo. & Di.: 8:00 – 12:00 Uhr & 13:00
                                            – 16:00 Uhr <br />
                                            Mi.: 8:00 – 12:00 Uhr (nachmittags
                                            nur schriftlicher Service) <br />
                                            Do.: 13:00 – 18:00 Uhr (vormittags
                                            nur schriftlicher Service) <br />
                                            Fr.: 8:00 – 12:00 Uhr <br />
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="displayElement('contacts-elli')"
                                        class="row"
                                    >
                                        <div class="footer-content--wrapp-elli">
                                            <div class="footer-content--elli">
                                                <div
                                                    class="footer-content__column"
                                                >
                                                    <img
                                                        src="/img/evus/elli/img-footer.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    class="footer-content__column"
                                                >
                                                    <div
                                                        class="footer-content__info"
                                                    >
                                                        <div
                                                            class="footer-content__title"
                                                        >
                                                            Hotline Volkswagen
                                                            Naturstrom
                                                        </div>
                                                        <div
                                                            class="footer-content__text"
                                                        >
                                                            Montags bis freitags
                                                            von 8 Uhr – 17 Uhr
                                                            berät Sie unser
                                                            Volkswagen
                                                            Naturstrom Service
                                                            Team telefonisch.
                                                        </div>
                                                        <a
                                                            href="tel:053613790501"
                                                            class="footer-content__link"
                                                            >05361 3790501</a
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="row"
                                        v-else-if="displayElement('contacts-eoptimum')"
                                    >
                                        <div class="col-12">
                                            <div>
                                                Gerne helfen wir Dir auch
                                                persönlich weiter
                                            </div>
                                            <h4>0800 226 663 050</h4>
                                            Montag bis Donnerstag von 08:30 bis
                                            18:00 Uhr, freitags von 08:30 bis
                                            17:00 Uhr.
                                        </div>
                                    </div>
                                    <div
                                        class="row"
                                        v-else-if="displayElement('contacts-ets')"
                                    >
                                        <div class="col-12">
                                            <div
                                                class="logo"
                                                :style="logo"
                                            ></div>
                                        </div>
                                    </div>
                                    <template
                                        v-else-if="displayElement('contacts-wdenergy')"
                                    >
                                    </template>
                                    <div
                                        v-else-if="displayElement('contacts-hsg')"
                                    >
                                        <div class="col-12">
                                            {{ $t('footer.default.callUs') }}
                                            <br />
                                            040 855 979 96
                                            <br />
                                            {{
                                            $t('footer.default.mondayToFriday')
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="displayElement('contacts-wechselstrom')"
                                    ></div>
                                    <div class="row" v-else>
                                        <div class="col-12">
                                            <h3>
                                                {{ $t('footer.default.callUs')
                                                }}
                                            </h3>
                                            {{ $t('footer.default.free') }}<br />
                                            <h4>0800 123 45678</h4>
                                            {{
                                            $t('footer.default.mondayToFriday')
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <template
                                    v-if="displayElement('contacts-sachsenenergie')"
                                >
                                    <div class="col-xs-12">
                                        <ul
                                            class="m-footer__nav m-nav m-nav--inline m--pull-right"
                                        >
                                            <li
                                                class="m-nav__item"
                                                v-if="agbLink"
                                            >
                                                <a
                                                    :href="agbLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.agb') }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="contactLink"
                                            >
                                                <a
                                                    :href="contactLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.contact')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="m-nav__item">
                                                <a
                                                    :href="privacyLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.privacy')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="tosLink"
                                            >
                                                <a
                                                    :href="tosLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.tos') }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="m-nav__item">
                                                <a
                                                    :href="imprintLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.imprint')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                                <template
                                    v-else-if="displayElement('contacts-eoptimum')"
                                >
                                    <div
                                        class="col-xs-12 col-xl-9 col-md-12"
                                        :class="[displayElement('links-col-sm-12-class') || displayElement('contacts-elli') ? 'col-sm-12' : 'col-sm-6']"
                                    >
                                        <ul
                                            class="m-footer__nav m-nav m-nav--inline m--pull-right"
                                        >
                                            <li
                                                class="m-nav__item"
                                                v-if="contactLink"
                                            >
                                                <a
                                                    :href="contactLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.contact')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="m-nav__item">
                                                <a
                                                    :href="privacyLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.privacy')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="agbLink"
                                            >
                                                <a
                                                    :href="agbLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.agb') }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="m-nav__item">
                                                <a
                                                    :href="imprintLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.imprint')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="legalNoticeLink"
                                            >
                                                <a
                                                    :href="legalNoticeLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{
                                                        $t('footer.legal.notice')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="tosLink"
                                            >
                                                <a
                                                    :href="tosLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.tos') }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="formularsLink"
                                            >
                                                <a
                                                    :href="formularsLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{
                                                        $t('footer.formulars')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="energyPricingLink"
                                            >
                                                <a
                                                    :href="energyPricingLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{
                                                        $t('footer.energyPricing')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                                <template
                                    v-else-if="!displayElement('contacts-sachsenenergie') && !displayElement('contacts-eoptimum')"
                                >
                                    <div
                                        class="col-xs-12"
                                        :class="[displayElement('links-col-sm-12-class') || displayElement('contacts-elli') ? 'col-sm-12' : 'col-sm-6']"
                                    >
                                        <ul
                                            class="m-footer__nav m-nav m-nav--inline m--pull-right"
                                        >
                                            <li
                                                class="m-nav__item"
                                                v-if="contactLink"
                                            >
                                                <a
                                                    :href="contactLink"
                                                    class="m-nav__link"
                                                    :target="currentClient !== 'prokon' ? '_blank' : '_self'"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.contact')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="m-nav__item">
                                                <a
                                                    :href="privacyLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.privacy')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="agbLink"
                                            >
                                                <a
                                                    :href="agbLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.agb') }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="m-nav__item">
                                                <a
                                                    :href="imprintLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.imprint')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="legalNoticeLink"
                                            >
                                                <a
                                                    :href="legalNoticeLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{
                                                        $t('footer.legal.notice')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="tosLink"
                                            >
                                                <a
                                                    :href="tosLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{ $t('footer.tos') }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="formularsLink"
                                            >
                                                <a
                                                    :href="formularsLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{
                                                        $t('footer.formulars')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                            <li
                                                class="m-nav__item"
                                                v-if="energyPricingLink"
                                            >
                                                <a
                                                    :href="energyPricingLink"
                                                    class="m-nav__link"
                                                    target="_blank"
                                                >
                                                    <p class="m-nav__link-text">
                                                        {{
                                                        $t('footer.energyPricing')
                                                        }}
                                                    </p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
