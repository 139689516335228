import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './meter-history-widget.html';
import Highcharts from 'highcharts';
import _ from 'lodash';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Format from '../../../interfaces/Format';
import Commodity from '@/interfaces/Commodity';
import { TranslateResult } from 'vue-i18n';
import { Chart } from 'highcharts-vue';
import * as Sentry from '@sentry/browser';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);

@WithRender
@Component({
    components: {
        highcharts: Chart,
    },
    filters: {
        date: formatProvider.date(),
    },
    props: {
        isUsageHistoryWidgetEnabled: {
            type: Boolean,
            default: false,
        },
    },
})
export class MeterHistoryWidget extends Base {
    protected elements = {
        waermeenergie: ['hide-history-legend'],
    };

    private seriesData: Record<any, any> = [];
    private historyData: Record<any, any> = {};
    public isLoading = true;

    protected mounted(): void {
        if (this.$store.state.contracts.contractId) {
            Promise.all([
                this.$store.dispatch(
                    'reading/history',
                    this.$store.state.contracts.contractId
                ),
            ]).then(this.onHistoryLoaded);
        }
        this.$root.$on('historyLoaded', () => {
            this.clearSeries();
            this.onHistoryLoaded();
        });
    }

    get invoiceCount(): any {
        return !this.invoicesLoaded
            ? 0
            : this.$store.getters['payment/getState'](
                  this.$store.state.contracts.contractId
              ).invoices.length;
    }

    get invoicesLoaded(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).invoicesLoaded;
    }

    private clearSeries(): void {
        if (this.chart) {
            while (this.chart.series.length > 0) {
                this.chart.series[0].remove(true);
            }
        }
    }

    get fullTimeline(): Record<any, any> {
        const timeline: any = [];

        if (this.historyData.history && this.historyData.history.readings) {
            this.historyData.history.readings.forEach((reading) => {
                const currentReadAt = reading.readAt;

                if (
                    timeline.filter((tl) => {
                        return tl.readAt === currentReadAt;
                    }).length === 0
                ) {
                    timeline.push({
                        readAt: currentReadAt,
                        readingValues: this.historyData.history.readings.filter(
                            (readingInner) => {
                                return readingInner.readAt === currentReadAt;
                            }
                        ),
                    });
                }
            });

            return _.orderBy(timeline, 'readAt', 'asc');
        }

        return timeline;
    }

    get seriesColors(): Record<any, any> {
        const colors: string[] = [];

        this.series.forEach(() => {
            if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
                colors.push('#cb0a20');
            } else {
                colors.push('var(--primary)');
            }
        });

        return colors;
    }

    set series(series: Record<any, any>) {
        this.seriesData = series;
    }

    get series(): Record<any, any> {
        return this.seriesData;
    }

    get chart(): any {
        return this.$refs['meter-history-chart']
            ? (this.$refs['meter-history-chart'] as any).chart
            : null;
    }

    private onHistoryLoaded(): void {
        if (this.chart === null) {
            return;
        }
        const data: Record<any, any> = this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        );
        if (
            data.history &&
            data.history.counters &&
            data.history.counters.length > 0
        ) {
            this.historyData = data;
            this.buildSeries();
        }
    }

    private buildSeries(): void {
        const seriesData: Record<any, any> = [];

        if (this.fullTimeline && this.fullTimeline.length > 0) {
            this.historyData.history.counters.forEach((counter) => {
                counter.registers.forEach((register) => {
                    const readingValues: any = [];

                    this.fullTimeline.forEach((timeline: any) => {
                        const value = timeline.readingValues.filter((rv) => {
                            return (
                                rv.counterNumber === counter.counterNumber &&
                                rv.obisCode === register.obis
                            );
                        });

                        if (value.length > 0) {
                            const date = new Date(
                                timeline.readAt.replace(/-/g, '/')
                            );
                            readingValues.push([
                                Date.UTC(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate()
                                ),
                                parseFloat(value[0].value),
                            ]);
                        } else {
                            readingValues.push(null);
                        }
                    });

                    if (process.env.VUE_APP_ENVIRONMENT === 'waermeenergie') {
                        let valueReadings: any = {
                            origin: 'undefined',
                            qualifier: 'undefined',
                        };

                        this.fullTimeline.forEach((timeline: any) => {
                            timeline.readingValues.forEach((rv) => {
                                valueReadings = { ...rv };
                            });
                        });

                        seriesData.push({
                            name:
                                (register.description
                                    ? register.description === 'NT'
                                        ? '<span class="mr-1">☾</span>'
                                        : '<span class="mr-1">☼</span>'
                                    : '') +
                                counter.counterNumber +
                                (register.description
                                    ? ' | ' + register.description
                                    : '') +
                                `<div class="readings-wrapper">${this.$t(
                                    'widget.ms.type'
                                )}: ${this.$t(
                                    'widget.ms.type.' + counter.type
                                )}` +
                                `<br>${this.$t('widget.ms.origin')}: ${this.$t(
                                    'widget.ms.origin.' + valueReadings.origin
                                )} ` +
                                `<br>${this.$t(
                                    'widget.ms.qualifier'
                                )}: ${this.$t(
                                    'widget.ms.qualifier.' +
                                        valueReadings.qualifier
                                )}</div>` +
                                `${this.$t('meter.reading.form.reading')}`,
                            data: readingValues,
                        });
                    } else {
                        seriesData.push({
                            name:
                                counter.counterNumber +
                                (register.description
                                    ? ' | ' + register.description
                                    : ''),
                            data: readingValues,
                        });
                    }
                });
            });
        } else {
            seriesData.push({
                name: '',
                data: [],
            });
        }

        const reference = this;

        setTimeout(() => {
            reference.onSeriesBuilt(seriesData);
        }, 1);
    }

    private onSeriesBuilt(seriesData: Record<any, any>): void {
        this.series = seriesData;

        const newOptions: Record<any, any> = {
            colors: this.seriesColors,
        };

        this.chart.update(newOptions);

        for (const i in seriesData) {
            if (!seriesData[i]) {
                continue;
            }
            this.chart.addSeries(seriesData[i], false);
        }

        this.onSeriesChanged();
    }

    private getAllReadings(): number[] {
        const readings: number[] = [];

        for (const seriesEntry of this.seriesData as []) {
            for (const seriesData of (seriesEntry as any).data) {
                if (seriesData !== null) {
                    readings.push(seriesData[1]);
                }
            }
        }

        return readings;
    }

    private readingSorter(valueA: number, valueB: number): number {
        return valueA - valueB;
    }

    private getReadingMinimum(offsetFactor = 0, offsetOnly = false): number {
        const readings: number[] = this.getAllReadings();
        readings.sort(this.readingSorter);

        const lowestValue: number = readings.length > 0 ? readings[0] : 0;
        const offset: number = lowestValue * offsetFactor;
        const valueWithOffset: number = lowestValue - offset;

        return offsetOnly ? offset : valueWithOffset < 0 ? 0 : valueWithOffset;
    }

    private getReadingMaximum(offsetFactor = 0, offsetOnly = false): number {
        const readings: number[] = this.getAllReadings();
        readings.sort((a, b) => a - b).reverse();
        const highestValue: number = readings.length > 0 ? readings[0] : 0;
        const offset: number = highestValue * offsetFactor;

        return offsetOnly ? offset : highestValue + offset;
    }

    private getAxisExtremesY(offsetFactor = 0): Record<any, any> {
        const readingMinimum: number = this.getReadingMinimum();
        const readingMaximum: number = this.getReadingMaximum();
        const offsetValue: number = this.getReadingMaximum(offsetFactor, true);

        return {
            min:
                readingMinimum - offsetValue <= 0
                    ? 0
                    : readingMinimum - offsetValue,
            max: readingMaximum + offsetValue,
        };
    }

    private getAxisExtremesX(): Record<any, any> {
        let offsetDays = 1;

        const minimum: number = this.getDateMinimumFromSeriesData(0);
        const maximum: number = this.getDateMaximumFromSeriesData(0);
        const timeSpan: number = maximum - minimum;

        if (timeSpan >= 365 * 24 * 60 * 60 * 1000) {
            offsetDays = 30;
        } else if (timeSpan >= 61 * 24 * 60 * 60 * 1000) {
            offsetDays = 14;
        }

        return {
            min: minimum - offsetDays * 86400 * 1000,
            max: maximum + offsetDays * 86400 * 1000,
            crosshair: true,
        };
    }

    private onSeriesChanged(): void {
        if (this.chart === null) {
            return;
        }

        try {
            if (this.chart.xAxis && this.chart.xAxis.length > 0) {
                this.chart.xAxis[0].update(this.getAxisExtremesX());
            }

            if (this.chart.xAxis && this.chart.yAxis.length > 0) {
                this.chart.yAxis[0].update(this.getAxisExtremesY(0.1));
            }

            this.chart.legend.update({
                enabled:
                    this.series.length > 1 &&
                    !this.displayElement('hide-history-legend'),
            });

            this.chart.redraw(false);
        } catch (e) {
            Sentry.captureException(new Error(e as string));
        }
        this.isLoading = false;
    }

    private getAllReadingDates(): number[] {
        const readingDates: number[] = [];
        for (const seriesEntry of this.seriesData as []) {
            for (const seriesData of (seriesEntry as any).data) {
                if (seriesData !== null) {
                    readingDates.push(seriesData[0]);
                }
            }
        }

        return readingDates;
    }

    private getDateMinimumFromSeriesData(offsetDays = 0): number {
        const readingDates: number[] = this.getAllReadingDates();
        readingDates.sort();

        if (readingDates.length > 0) {
            return readingDates[0] - offsetDays * 86400 * 1000;
        }

        return 0;
    }

    private getDateMaximumFromSeriesData(offsetDays = 0): number {
        const readingDates: number[] = this.getAllReadingDates();
        readingDates.reverse();

        if (readingDates.length > 0) {
            return readingDates[0] + offsetDays * 86400 * 1000;
        }

        return 0;
    }

    private tickPositioner(reference: any): number[] {
        const positions: number[] = [];
        let tick: number = Math.floor(reference.dataMin);
        let tickDivisor = 6;
        if (this.seriesData && this.seriesData[0] && this.seriesData[0].data) {
            tickDivisor =
                this.seriesData[0].data.length <= 6
                    ? this.seriesData[0].data.length - 1
                    : 6;
        }

        const increment: number = Math.ceil(
            (reference.dataMax - reference.dataMin) / tickDivisor
        );

        if (reference.dataMax !== null && reference.dataMin !== null) {
            for (
                tick;
                tick - increment < reference.dataMax;
                tick += increment
            ) {
                positions.push(tick);
            }
        }
        return positions;
    }

    private labelFormatterAxisY(reference: any): string {
        return reference.value
            ? parseFloat(reference.value).toLocaleString('de-DE', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
              })
            : '-';
    }

    private tooltipFormatter(reference: any): string {
        let tooltipContent: string =
            '<div class="meter-history-widget-tooltip-header">' +
            Highcharts.dateFormat('%d.%m.%Y', reference.x) +
            '</div>';
        tooltipContent +=
            '<div class="meter-history-widget-tooltip-content">' +
            reference.series.name +
            ': <b>' +
            parseFloat(reference.y).toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            }) +
            '</b></div>';

        return tooltipContent;
    }

    get chartOptions(): Record<any, any> {
        const widget = this;

        const tickPositionerCallback: any = function (this: any) {
            return widget.tickPositioner(this);
        };

        const labelFormatterCallbackAxisY: any = function (this: any) {
            return widget.labelFormatterAxisY(this);
        };

        const tooltipFormatterCallback: any = function (this: any) {
            return widget.tooltipFormatter(this);
        };

        return {
            chart: {
                type: 'spline',
                height: 400,
            },
            exporting: {
                chartOptions: {
                    chart: {
                        events: false,
                    },
                },
            },
            title: {
                text: null,
            },
            series: [],
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%d.%m.%Y}',
                },
                axisTicks: {
                    show: true,
                },
                showEmpty: false,
                tickPositioner: tickPositionerCallback,
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: this.$t('widget.mh.meterReadingAxis'),
                    },
                    min: 0,
                    gridLineWidth: 1,
                    visible: true,
                    endOnTick: false,
                    startOnTick: false,
                    labels: {
                        formatter: labelFormatterCallbackAxisY,
                    },
                },
            ],
            legend: {
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                offsetY: 0,
                offsetX: 0,
            },
            plotOptions: {
                series: {
                    lineWidth: 4,
                },
            },
            tooltip: {
                xDateFormat: '%d.%m.%Y',
                useHTML: true,
                formatter: tooltipFormatterCallback,
            },
        };
    }

    get commodity(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityType(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract,
                'meter-history'
            )
        );
    }

    get commodityPrice(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }
}
