import Base from '@/mixins/base';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import { default as Component } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithRender from './thg-bonus-entry.html';
import iocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import SERVICES from '@/container/Services';
import NonCommodityProductFacade from '@/models/NonCommodityProductFacade';
import moment from 'moment';

const formatProvider = iocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        euro: formatProvider.euro(),
    },
})
export class ThgBonusEntry extends Base {
    @Prop() public product!: NonCommodityProductFacade;
    @Prop() public bonusYear!: string;
    @Prop() public thgService!: ThgBaseService;
    @Prop() isTitleLoading!: boolean;

    public get productPrice(): number | undefined {
        return this.product.basePrice;
    }

    public get productStatus(): string {
        return this.thgService.getStatusForProduct(this.product);
    }

    public get productStatusFormatted(): string {
        return this.thgService.getFormattedStatusForProduct(this.product);
    }

    public get productImage(): string {
        let image: string = '';
        if (
            !this.isTitleLoading &&
            this.$store.state.contracts.products.hasLoaded
        ) {
            image = this.productImageForYear(this.product.year);
            if (image.length == 0) {
                image = this.productImageForYear(
                    this.thgService.getNextYear(this.product.year)
                );
            }
        }

        return image.length > 0 ? image : '';
    }

    public get productName(): string {
        let title: string = '';
        if (
            !this.isTitleLoading &&
            this.$store.state.contracts.products.hasLoaded
        ) {
            title = this.getProductNameForYear(this.product.year);
            if (title.length == 0) {
                title = this.getProductNameForYear(
                    this.thgService.getNextYear(this.product.year)
                );
            }
        }
        return title.length > 0 ? title : '-';
    }

    private getProductNameForYear(year: string): string {
        const product = this.getProductForYear(year);
        if (product) {
            return product.title;
        }

        if (this.thgService.hasBonusInPreviousYear(year)) {
            const product = this.getProductForPreviousYear(year);
            if (product) {
                return product.title;
            }
        }

        return '';
    }

    private productImageForYear(year: string): string {
        const product = this.getProductForYear(year);
        if (product) {
            return product.productImage;
        }

        if (this.thgService.hasBonusInPreviousYear(year)) {
            const product = this.getProductForPreviousYear(year);
            if (product) {
                return product.productImage;
            }
        }

        return '';
    }

    private getProductForPreviousYear(
        year: string
    ): NonCommodityProductFacade | null {
        return this.getProductForYear(parseInt(year) - 1 + '');
    }

    private getProductForYear(year: string): NonCommodityProductFacade | null {
        if (
            this.$store.state.contracts.products &&
            this.$store.state.contracts.products.list &&
            Array.isArray(this.$store.state.contracts.products.list)
        ) {
            for (const contractProduct of this.$store.state.contracts.products
                .list) {
                const product =
                    NonCommodityProductFacade.withData(contractProduct);
                if (moment(contractProduct.activeFrom).format('YYYY') == year) {
                    return product;
                }
            }
        }
        return null;
    }
}
