import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './ets.html';

@WithRender
@Component({})
export class TariffWidgetEts extends Base {
    get isNonCommodityContract() {
        const selectContract = this.contractsList.filter((contract) => {
            return (
                contract.contractId === this.$store.state.contracts.contractId
            );
        })[0];
        return (
            selectContract.type === 'non_commodity' &&
            selectContract.energy === 'non_energy'
        );
    }

    protected created() {
        this.$store.dispatch(
            'tariff/overview',
            this.$store.state.contracts.contractId
        );
        this.$store.dispatch(
            'tariff/details',
            this.$store.state.contracts.contractId
        );
    }
}
