<div data-test="left-aside">
    <button
        class="m-aside-left-close m-aside-left-close--skin-dark"
        id="m_aside_left_close_btn"
        @click="$emit('toggleSidebar')"
    >
        <i class="fa fa-times"></i>
    </button>
    <div
        id="m_aside_left"
        class="m-grid__item m-aside-left m-aside-left--skin-dark m-aside-left--skin-white"
        :class="toggleSidebarClass"
    >
        <!-- BEGIN: Aside Menu -->
        <div
            id="m_ver_menu"
            class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-scroller ps m-aside-menu--submenu-skin-white"
            m-menu-vertical="1"
            m-menu-scrollable="1"
            m-menu-dropdown-timeout="500"
            style="position: relative; height: 100%; overflow: hidden"
        >
            <div
                class="m-aside-menu--welcome"
                v-if="displayElement('aside-menu--welcome')"
            >
                {{ $t('widget.welcome.short') }}<br />
                {{ customerDetails.salutation }} {{ customerDetails.surname
                }}<br />
                {{ $t('widget.welcome.in.customer.portal') }}.
            </div>
            <div class="m-aside-menu--custom-welcome">
                <div class="m-aside-menu--custom-welcome-salutation">
                    {{ $t('widget.welcome.short') }}
                </div>
                <div class="m-aside-menu--custom-welcome-name">
                    {{customerDetails.firstname}} {{ customerDetails.surname }}
                </div>
                <div class="m-aside-menu--custom-welcome-number">
                    {{ $t('left.aside.customer.nr') }}
                    <span>{{customerDetails.id}}</span>
                </div>
            </div>
            <ul
                class="m-menu__nav m-menu__nav--dropdown-submenu-arrow pt-0 scrollable"
            >
                <li
                    data-test="left-aside-contract-buttons"
                    class="m-menu__item"
                    :class="{'m-menu__item--selected': selectedContractId === contract.contractId}"
                    v-for="contract in contractsList"
                    :key="contract.contractId"
                    @click="closeSidebar"
                >
                    <router-link
                        :to="'/dashboard/'+contract.contractId"
                        class="m-menu__link"
                    >
                        <i
                            v-if="contract.energy === 'gas'"
                            :class="'m-menu__link-icon fa fa-file-contract fa-gas' + (selectedContractId === contract.contractId ? '-negative' : '')"
                        ></i>
                        <i
                            v-else-if="contract.energy === 'electricity'"
                            :class="'m-menu__link-icon fa fa-file-contract fa-strom' + (selectedContractId === contract.contractId ? '-negative' : '')"
                        ></i>
                        <span class="m-menu__minimized-data"
                            >{{ contract.contractId }}</span
                        >
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span
                                    class="m-menu__link-text sidebar-contract-number"
                                >
                                    <span class="title"
                                        >{{ contract.title }}</span
                                    ><br />
                                    <span class="sidebar-contract-details">
                                        <div
                                            class="sidebar-contract-details__address"
                                        >
                                            {{ contract.street }} {{
                                            contract.houseNumber }}
                                        </div>
                                        <div
                                            class="sidebar-contract-details__city"
                                        >
                                            <span class="zip"
                                                >{{ contract.zip }}</span
                                            >
                                            {{ contract.city }}
                                        </div>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </router-link>
                </li>
            </ul>
            <div class="bottom-nav custom-bottom-nav">
                <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
                    <li class="m-menu__item" v-if="displayTimeOut">
                        <span
                            v-if="displayElement('timeout-countdown')"
                            class="m-menu__item-text"
                            ><span class="m-menu__item-text__block"
                                >{{ $t('left.aside.timeout.automatic') }}</span
                            >
                            <span class="m-menu__item-text__block"
                                >{{ time }} {{ $t('left.aside.timeout.minute')
                                }}</span
                            ></span
                        >
                    </li>
                    <li v-if="isTwoFactorAvailable" class="m-menu__item">
                        <a
                            @click="toggleOpenTwoFactorAuthenticationModal"
                            class="m-menu__link"
                        >
                            <i class="m-menu__link-icon fa fa-unlock"></i>
                            <span class="m-menu__link-title">
                                <span class="m-menu__link-wrap">
                                    <span class="m-menu__link-text"
                                        >{{
                                        $t('asside.two.factor.authentication')
                                        }}</span
                                    >
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="m-menu__item">
                        <a
                            v-if="displayElement('logout-simple-link')"
                            class="btn btn-link btn-white"
                            @click="logout()"
                            ><span>{{ $t('asside.signout') }}</span></a
                        >
                        <a v-else @click="logout()" class="m-menu__link">
                            <span class="m-menu__link-custom-icon"></span>
                            <span class="m-menu__link-title">
                                <span class="m-menu__link-wrap">
                                    <span class="m-menu__link-text"
                                        >{{ $t('asside.signout') }}</span
                                    >
                                </span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- begin: Aside Footer Area -->
            <div class="sidebar-footer-area" v-if="version !== 'prod'">
                <b>{{ version }}</b>
            </div>
            <!-- END: Aside Footer Area -->
            <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                    class="ps__thumb-x"
                    tabindex="0"
                    style="left: 0px; width: 0px"
                ></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 4px">
                <div
                    class="ps__thumb-y"
                    tabindex="0"
                    style="top: 0px; height: 0px"
                ></div>
            </div>
        </div>
        <!-- END: Aside Menu -->
    </div>
    <div class="menu-backdrop" @click="$emit('toggleSidebar')"></div>
</div>
