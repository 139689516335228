import { default as Component } from 'vue-class-component';
import WithRender from './schweizstrom.html';
import { LeftAside as LeftAsideParent } from '@/components/template/left-aside/left-aside';

@WithRender
@Component({})
export class SchweizstromLeftAside extends LeftAsideParent {
    protected closeSidebar(): void {
        const mobileWidth = 1024;
        if (window.innerWidth <= mobileWidth) this.$emit('toggleSidebar');
    }
}
