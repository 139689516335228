import { CancellationWidget } from '../cancellation-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli.html';
import vSelect from 'vue-select';
import { formatISO, addBusinessDays } from 'date-fns';

@WithRender
@Component({
    components: {
        'v-select': vSelect,
    },
})
export class CancellationWidgetElli extends CancellationWidget {
    get minimalDate() {
        if (this.details.cancelType === 'cancelDate') {
            return formatISO(addBusinessDays(new Date(), 11));
        }

        return null;
    }
}
