import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './sales-journey.html';
import { Watch } from 'vue-property-decorator';

import { Header } from '@/components/template/header';
import { Footer } from '@/components/template/footer';
import { LeftAside } from '@/components/template/left-aside';
import { ScrollTop } from '@/components/template/scroll-top';

import { WelcomeWidget } from '@/components/widgets/welcome-widget';

@WithRender
@Component({
    components: {
        // Template
        'app-header': Header,
        'app-footer': Footer,
        'left-aside': LeftAside,
        'scroll-top': ScrollTop,

        // Widgets
        'welcome-widget': WelcomeWidget,
    },
})
export class SalesJourney extends Base {
    public toggleSidebarState = false;

    protected elements = {
        bsw: [
            'app-footer-inside-container',
            'hide-app-footer-outside-container',
        ],
    };

    get bodyClass(): string {
        if (this.toggleSidebarState) {
            return 'm-brand--minimize m-aside-left--minimize m-aside-left--on';
        } else {
            return '';
        }
    }

    // Style
    get widgetsBackground(): string {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/background.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/background.png); background-size: cover, cover;'
        );
    }

    get salesJourneyLink(): any {
        if (this.$route.params.id) {
            const testJsTag =
                'https://sales-journey-dev.workdigital.de/elli-thg-' +
                this.branding +
                '-test/wd-sales-journey.min.js';
            const prodJsTag =
                'https://thg-order-forms.elli.eco/elli-thg-' +
                this.branding +
                '/wd-sales-journey.min.js';
            return this.isProduction ? prodJsTag : testJsTag;
        }
        return this.$store.state.settings.salesJourneyLink;
    }

    get htmlTag() {
        console.log(this.$route);
        if (this.$route.params.id) {
            return (
                '<wd-sales-journey campaign="' +
                this.campaign +
                '" customer="' +
                this.customerDetails.id +
                '"></wd-sales-journey>'
            );
        }

        return '<wd-sales-journey></wd-sales-journey>';
    }

    get productCode(): string {
        return this.currentNoCommodityProduct.productCode ?? '';
    }

    get branding() {
        if (this.productCode) {
            if (this.productCode.includes('_ELLI_')) {
                return 'elli';
            }
            if (this.productCode.includes('_VW_')) {
                return 'vw';
            }
            if (this.productCode.includes('_VWFS_')) {
                return 'vwfs';
            }
            if (this.productCode.includes('_Skoda_')) {
                return 'skoda';
            }
            if (this.productCode.includes('_Cupra_')) {
                return 'cupra';
            }
            if (this.productCode.includes('_Audi_')) {
                return 'audi';
            }
            if (this.productCode.includes('_Freenet_')) {
                return 'freenet';
            }
        }

        return '';
    }

    get campaign() {
        if (this.productCode) {
            if (this.productCode.includes('_ELLI_')) {
                return 'THGVW';
            }
            if (this.productCode.includes('_VW_')) {
                return 'THGVW';
            }
            if (this.productCode.includes('_VWFS_')) {
                return 'SFWVGHT';
            }
            if (this.productCode.includes('_Skoda_')) {
                return 'ADOKSGHT';
            }
            if (this.productCode.includes('_Cupra_')) {
                return 'ARPUCGHT';
            }
            if (this.productCode.includes('_Audi_')) {
                return 'IDUAGHT';
            }
            if (this.productCode.includes('_Freenet_')) {
                return 'TENEERFGHT';
            }
        }

        return '';
    }

    protected created(): void {
        // this.$store.dispatch('customer/getDetails').then(() => {
        //     // getDetails
        // });
        // this.$store.dispatch('contracts/contracts').then(() => {
        //     // contracts
        // });
    }

    @Watch('salesJourneyLink')
    protected onsalesJourneyLinkChange(newVal): void {
        if (newVal) {
            this.addScript(newVal);
        }
    }

    protected mounted(): void {
        if (this.$route.params.id) {
            this.addScript(this.salesJourneyLink);
        } else {
            if (this.salesJourneyLink) {
                this.addScript(this.salesJourneyLink);
            }
        }
    }

    protected toggleSidebar(): void {
        this.toggleSidebarState = !this.toggleSidebarState;
    }

    protected addScript(link: string): void {
        const script = document.createElement('script');
        script.setAttribute('src', link);
        document.head.appendChild(script);
    }
}
