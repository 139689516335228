import { default as Component } from 'vue-class-component';
// Call Render template.
import WithRender from './schweizstrom.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
import { SchweizstromHeader } from '@/components/template/header/custom/schweizstrom';
import { SchweizstromFooter } from '@/components/template/footer/custom/schweizstrom';

// Templates
import { SchweizstromLeftAside } from '@/components/template/left-aside/custom/schweizstrom';

// Widgets
import { PostBoxWidget } from '@/components/widgets/post-box-widget';
import { CustomerServiceFormWidget } from '@/components/widgets/customer-service-form-widget';
import { StatusBarWidget } from '@/components/widgets/status-bar-widget';
import { TariffDetailsWidget } from '@/components/widgets/tariff-details-widget';
import { DepositWidget } from '@/components/widgets/deposit-widget';
import { DepositChangeWidget } from '@/components/widgets/deposit-change-widget';
import { DepositOverviewWidget } from '@/components/widgets/deposit-overview-widget';
import { AccountBalanceWidget } from '@/components/widgets/account-balance-widget';
import { AccountTransactionsWidget } from '@/components/widgets/account-transactions-widget';
import { MeterWidget } from '@/components/widgets/meter-widget';
import { MeterSubmitWidget } from '@/components/widgets/meter-submit-widget';
import { TariffOverviewWidget } from '@/components/widgets/tariff-overview-widget';
import { EnergyCompoundWidget } from '@/components/widgets/energy-compound-widget';
import { MoveWidget } from '@/components/widgets/move-widget';
import { ChangeWidget } from '@/components/widgets/change-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';

@WithRender
@Component({
    components: {
        SchweizstromLeftAside,
        SchweizstromHeader,
        SchweizstromFooter,

        // Widgets
        PostBoxWidget,
        CustomerServiceFormWidget,
        StatusBarWidget,
        TariffDetailsWidget,
        DepositWidget,
        DepositChangeWidget,
        DepositOverviewWidget,
        AccountBalanceWidget,
        AccountTransactionsWidget,
        MeterWidget,
        MeterSubmitWidget,
        TariffOverviewWidget,
        EnergyCompoundWidget,
        MoveWidget,
        ChangeWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})
export class Dashboard extends DashboardParent {
    public width = 0;

    protected showGroup2 = {
        changeBillingDataWidget: false,
        changeCustomerDataWidget: false,
        changePaymentDataWidget: false,
        isOpenTariffDetailsWidget: false,
    };

    protected mounted(): void {
        this.width = window.innerWidth;

        if (this.width >= 768) {
            this.showGroup2.isOpenTariffDetailsWidget = true;
        }
    }

    get showIsOpenTariffDetailsWidget(): boolean {
        return this.showGroup2.isOpenTariffDetailsWidget ? true : false;
    }

    get productTitle(): string {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).product.title;
        }
        return '';
    }

    protected closeGroup2(): void {
        this.showGroup2.changeBillingDataWidget = false;
        this.showGroup2.changeCustomerDataWidget = false;
        this.showGroup2.changePaymentDataWidget = false;
        this.showGroup2.isOpenTariffDetailsWidget = false;
    }
}
