<form-wrapper form="formCustomerData">
    <div class="mobile-modal__inner" data-test="change-customer-data-widget">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-customer-data-widget-close-click"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close change-customer-details-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <i
                            class="far fa-user readingHistory-icon mt-2"
                            v-if="displayElement('user-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.ccd.title') }}</span
                        >
                        <div
                            id="customerDataChange-content"
                            class="mobile-modal__wrap"
                        >
                            <div id="customerDataChangeForm">
                                <div class="row">
                                    <div class="col form-col">
                                        <div class="row">
                                            <form-group
                                                id="change-customer-data-widget__title"
                                                :class="[displayElement('col-6-field') ? 'col-6' : 'col-12']"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.titel')
                                                    }}</custom-label
                                                >
                                                <custom-select
                                                    class="form-control"
                                                    v-model="details.title"
                                                >
                                                    <option
                                                        value="-"
                                                        :selected="details.title === '-'"
                                                    >
                                                        -
                                                    </option>
                                                    <option
                                                        value="Dr."
                                                        :selected="details.title === 'Dr.'"
                                                    >
                                                        Dr.
                                                    </option>
                                                    <option
                                                        value="Prof."
                                                        :selected="details.title === 'Prof.'"
                                                    >
                                                        Prof.
                                                    </option>
                                                </custom-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__salutation"
                                                :class="[displayElement('col-6-field') ? 'col-6' : 'col-12']"
                                            >
                                                <custom-label
                                                    >{{
                                                    $t('widget.ccd.salutation')
                                                    }}</custom-label
                                                >
                                                <custom-select
                                                    class="form-control"
                                                    v-model="details.salutation"
                                                >
                                                    <option
                                                        value="9"
                                                        :selected="parseInt(details.salutation_number) === 9"
                                                    >
                                                        {{
                                                        $t('widget.ccd.without')
                                                        }}
                                                    </option>
                                                    <option
                                                        value="1"
                                                        :selected="parseInt(details.salutation_number) === 1"
                                                    >
                                                        {{ $t('widget.ccd.mr')
                                                        }}
                                                    </option>
                                                    <option
                                                        value="2"
                                                        :selected="parseInt(details.salutation_number) === 2"
                                                    >
                                                        {{ $t('widget.ccd.mrs')
                                                        }}
                                                    </option>
                                                    <template
                                                        v-if="!displayElement('hide-company-family-dears')"
                                                    >
                                                        <option
                                                            value="8"
                                                            :selected="parseInt(details.salutation_number) === 8"
                                                        >
                                                            {{
                                                            $t('widget.ccd.company')
                                                            }}
                                                        </option>
                                                        <option
                                                            value="3"
                                                            :selected="parseInt(details.salutation_number) === 3"
                                                        >
                                                            {{
                                                            $t('widget.ccd.family')
                                                            }}
                                                        </option>
                                                    </template>
                                                </custom-select>
                                                <span></span>
                                            </form-group>
                                        </div>
                                        <form-group
                                            id="change-customer-data-widget__firstname"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.firstname')
                                                }}</custom-label
                                            >
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.firstname')"
                                                v-model="details.firstname"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__lastname"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.lastname')
                                                }}</custom-label
                                            >
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.lastname')"
                                                v-model="details.surname"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-mobile-with-areacode"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.phone')
                                                }}</custom-label
                                            >
                                            <div class="row">
                                                <div class="col-5">
                                                    <custom-input
                                                        data-test="change-customer-data-widget-input--prephone-number"
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.area')"
                                                        v-model="details.phoneMobileAreaCode"
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <custom-input
                                                        data-test="change-customer-data-widget-input--phone-number"
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.number')"
                                                        v-model="details.phoneMobile"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__email-private"
                                        >
                                            <custom-label>
                                                {{ $t('widget.ccd.email') }}
                                            </custom-label>
                                            <custom-input
                                                type="email"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.emailPrivate')"
                                                v-model="details.emailPrivate"
                                                @click="showEmailRepeat=true"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__date-of-birth"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.date')
                                                }}</custom-label
                                            >
                                            <custom-datetime
                                                v-model="details.dateOfBirth"
                                                type="date"
                                                input-class="form-control"
                                                value-zone="local"
                                                format="dd.LL.yyyy"
                                            ></custom-datetime>
                                        </form-group>
                                    </div>
                                    <div class="col form-col">
                                        <template v-if="showSelectAddress">
                                            <form-group
                                                id="change-customer-data-widget__zip-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.post.code')
                                                    }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'zip')"
                                                    @input="cityInput"
                                                    label="zipText"
                                                    v-model="selectedCityZip"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__city-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.place') }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'name')"
                                                    @input="cityInput"
                                                    label="nameText"
                                                    v-model="selectedCityName"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                class="form-group"
                                                v-if="selectedCityName"
                                                id="change-customer-data-widget__street-select"
                                            >
                                                <custom-label
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.street') }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="streets"
                                                    v-model="selectedStreet"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                    @input="streetInput"
                                                    label="name"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__house-number"
                                                v-if="selectedCityName"
                                            >
                                                <custom-label
                                                    :class="[houseNumberError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.mo.house.number')
                                                    }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    :class="['form-control', houseNumberError ? 'is-invalid' : '']"
                                                    v-model="details.houseNumber"
                                                    @change="houseNumberError = false"
                                                />
                                            </form-group>
                                        </template>
                                        <template v-else>
                                            <form-group
                                                id="change-customer-data-widget__street"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.ccd.street')
                                                    }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.street')"
                                                    v-model="details.street"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__house-number"
                                                class="disabled"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.ccd.house') }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.house')"
                                                    v-model="details.houseNumber"
                                                    disabled
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__zip"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.ccd.zip') }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.zip')"
                                                    v-model="details.zip"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__city"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.ccd.place') }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.place')"
                                                    v-model="details.city"
                                                />
                                            </form-group>
                                        </template>
                                        <form-group
                                            class="form-group"
                                            id="change-customer-data-widget__synchronize-with-billing--excluded"
                                            v-if="showSyncBilling"
                                        >
                                            <custom-checkbox
                                                :labelClass="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                                :options="checkboxOptions()"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                v-model="details.synchronizeWithBilling"
                                                inputClass="form-check-input"
                                            />
                                        </form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-xs-12">
                                        <messages-block
                                            fromTestElement="change-customer-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div class="col-sm-6 col-xs-12">
                                        <a
                                            :href="$t('widget.infoPersonalDataLink')"
                                            class="
                                                personal-info-link
                                                info-link-m0
                                            "
                                            target="_blank"
                                        >
                                            {{ $t('widget.infoPersonalData') }}
                                        </a>
                                        <div class="text-end">
                                            <button
                                                data-test="change-customer-data-widget-save-click"
                                                class="btn btn-primary"
                                                @click="checkAndSubmit"
                                                :disabled="submitPending"
                                            >
                                                {{ $t('widget.ccd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
