import { CancellationWidget } from '../cancellation-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './sachsenenergie.html';
import vSelect from 'vue-select';
import moment from 'moment';

@WithRender
@Component({
    components: {
        'v-select': vSelect,
    },
})
export class CancellationWidgetSachsenEnergie extends CancellationWidget {
    get minDateTime() {
        if (this.details.cancelType === 'cancelDate') {
            return this.nextCancellationDate;
        } else if (this.details.cancelType === 'moveAtDate') {
            return moment().subtract(5, 'week').format('YYYY-MM-DD');
        }
        return null;
    }

    get maxDateTime() {
        if (this.details.cancelType === 'moveAtDate') {
            return moment().add(3, 'month').format('YYYY-MM-DD');
        }
        return null;
    }

    get contract() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract;
    }

    get nextCancellationDate() {
        return this.contract.nextCancellationDate;
    }

    protected onCancelContractCallback(response): void {
        this.submitPending = false;
        if (
            response.data.success &&
            typeof response.data.messageLocalized === 'object'
        ) {
            this.submitPending = true;
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );

            if (
                response.data.messageLocalized.key &&
                response.data.messageLocalized.key ===
                    'powercloud.cancel.contract.by.id.success.true'
            ) {
                if (this.details.cancelType === 'moveAtDate') {
                    response.data.messageLocalized.key += '.for.move';
                }
            }

            this.successInformation.push(response.data.messageLocalized);
        } else if (
            response.data.success &&
            typeof response.data.messageLocalized === 'string'
        ) {
            this.submitPending = true;
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
            this.successInformation.push({
                key: '',
                message: response.data.messageLocalized,
            });
        } else {
            if (
                response.data.errorMessages &&
                response.data.errorMessages.length > 0
            ) {
                this.errorInformation = response.data.errorMessages;
            } else if (typeof response.data.messageLocalized === 'object') {
                this.errorInformation.push(response.data.messageLocalized);
            } else if (typeof response.data.messageLocalized === 'string') {
                this.errorInformation.push({
                    key: '',
                    message: response.data.messageLocalized,
                });
            }
        }
    }
}
