import { LeftAside } from '@/components/template/left-aside/left-aside';
import { LeftAsideElli } from '@/components/template/left-aside/custom/elli';

let widget;

if (
    process.env.VUE_APP_ENVIRONMENT === 'elli' ||
    process.env.VUE_APP_ENVIRONMENT?.includes('elli-thg')
) {
    widget = LeftAsideElli;
} else {
    widget = LeftAside;
}

export { widget as LeftAside };
