import { AccountTransactionsWidgetSchweizstrom } from '@/components/widgets/account-transactions-widget/custom/schweizstrom';
import { AccountTransactionsWidgetElli } from '@/components/widgets/account-transactions-widget/custom/elli';
import { AccountTransactionsWidgetBsw } from '@/components/widgets/account-transactions-widget/custom/bsw';
import { AccountTransactionsWidget } from '@/components/widgets/account-transactions-widget/account-transactions-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = AccountTransactionsWidgetSchweizstrom;
} else if (process.env.VUE_APP_ENVIRONMENT?.includes('elli')) {
    widget = AccountTransactionsWidgetElli;
} else if (process.env.VUE_APP_ENVIRONMENT?.includes('bsw')) {
    widget = AccountTransactionsWidgetBsw;
} else {
    widget = AccountTransactionsWidget;
}
export { widget as AccountTransactionsWidget };
