<div
    class="m-portlet m-portlet--mobile m-portlet--change-widget"
    data-test="change-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <span
                    class="
                        monthlyDeposit-title
                        static-title static-title--custom
                    "
                    >{{ $t('widget.cw.title') }}</span
                ><br />
                <div class="changeData-button-list row">
                    <div class="col-12 col-md-3" v-if="contractMoveAllowed">
                        <button
                            class="btn btn-primary w-100"
                            @click="$emit('toggleWidget4', 'showMoveWidget')"
                        >
                            {{ $t('widget.td.move') }}
                        </button>
                    </div>
                    <div class="col-12 col-md-3">
                        <button
                            data-test="change-widget-customer-data-click"
                            :disabled="changeCustomerDataLoading"
                            @click="$emit('toggleWidget', 'changeCustomerDataWidget')"
                            :class="['btn btn-primary btn-sm w-100', groupWidget.changeCustomerDataWidget ? 'active' : '']"
                            id="button-changeCustomerData"
                        >
                            <span>{{ $t('widget.cw.customer') }}</span>
                        </button>
                    </div>
                    <div class="col-12 col-md-3" v-if="showChangeBillingData">
                        <button
                            :disabled="changeBillingDataLoading"
                            @click="$emit('toggleWidget', 'changeBillingDataWidget')"
                            :class="['btn btn-primary btn-sm w-100', groupWidget.changeBillingDataWidget ? 'active' : '']"
                            id="button-changeBillingData"
                            data-test="change-widget-billing-data-click"
                        >
                            <span>{{ $t('widget.cw.postal') }}</span>
                        </button>
                    </div>
                    <div class="col-12 col-md-3" v-if="showChangePaymentData">
                        <button
                            :disabled="changePaymentDataLoading"
                            @click="$emit('toggleWidget', 'changePaymentDataWidget')"
                            :class="['btn btn-primary btn-sm w-100', groupWidget.changePaymentDataWidget ? 'active' : '']"
                            id="button-changePaymentData"
                            data-test="change-widget-payment-data-click"
                        >
                            <span>{{ $t('widget.cw.payment') }}</span>
                        </button>
                    </div>
                    <div
                        class="col-12 col-md-3"
                        v-if="displayElement('show-tariff-details-link')"
                    >
                        <button
                            :class="['btn btn-primary btn-sm w-100']"
                            @click="$emit('toggleWidget', 'isOpenTariffDetailsWidget')"
                        >
                            <span>{{ $t('widget.td.link') }}</span>
                        </button>
                    </div>
                    <div
                        class="col-12 col-md-3"
                        v-if="contractCancellationAllowed"
                    >
                        <button
                            class="btn btn-primary"
                            :class="['w-100',displayElement('tariff-details-button-link') ? 'btn-cancellation--link' : '', displayElement('tariff-details-button-link-arrow') ? 'btn-cancellation--link-arrow' : '']"
                            @click="$emit('toggleWidget4', 'showCancellationWidget')"
                        >
                            {{ $t('widget.td.cancellation') }}
                            <span
                                v-if="displayElement('tariff-details-button-link-arrow')"
                                >&rarr;</span
                            >
                        </button>
                    </div>
                    <div class="col-12 col-md-3" v-if="showRevocationWidget">
                        <button
                            class="btn btn-primary w-100"
                            @click="$emit('toggleWidget', 'revokeWidget')"
                        >
                            {{ $t('widget.td.revocation') }}
                        </button>
                    </div>
                    <div
                        class="col-12 col-md-3"
                        v-if="tariffChangeAllowed && tariffChangeAvailable"
                    >
                        <button
                            class="btn btn-primary w-100"
                            @click="$emit('toggleWidget', 'showTariffChange')"
                        >
                            {{ $t('widget.td.tariffChange') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
