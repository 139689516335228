// -----------------------------
//      Cookie Data structure
// -----------------------------

// Data Structure
const state = {
    showCookieAlert: true,
};

// Outside methods for communication with data.
const actions = {
    setCookiesAlertStatus({ commit }, status) {
        commit('SET_COOKIE_ALERT_STATUS', status);
    },
    loadCookiesAlertStatus({ commit }) {
        commit('LOAD_COOKIE_ALERT_STATUS');
    },
};

// Logic that change data
const mutations = {
    SET_COOKIE_ALERT_STATUS(localState, status) {
        localState.showCookieAlert = status;
        localStorage.setItem('showCookieAlert', status);
    },
    LOAD_COOKIE_ALERT_STATUS(localState) {
        const showCookieAlert = localStorage.getItem('showCookieAlert');
        if (showCookieAlert !== null) {
            localState.showCookieAlert = showCookieAlert === 'true';
        }
    },
};

// Get Data transformation (like computed)
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
