<div class="m-login__form m-form">
    <div v-if="!showOtpAlternative">
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="username"
                ><strong>{{ $t('login.onetime.password.username') }}</strong> {{
                $t('login.field.required') }}</label
            >
            <input
                :disabled="passwordSent || isLoading || loginLoadingState"
                :class="['form-control m-input', errors && errors.username ? 'is-invalid' : '']"
                type="text"
                v-model="username"
                id="username"
                name="username"
                autocomplete="off"
                value=""
                @keyup.enter="handleEnterKey('username')"
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                :disabled="passwordSent || isLoading || loginLoadingState"
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.onetime.password.username')"
                v-model="username"
                name="username"
                autocomplete="off"
                value=""
                @keyup.enter="handleEnterKey('username')"
            />
        </div>

        <template v-if="passwordSent">
            <div
                class="form-group m-form__group"
                v-if="displayElement('input-with-label')"
            >
                <label for="password"
                    ><strong>{{ $t('login.password') }}</strong> {{
                    $t('login.field.required') }}</label
                >
                <input
                    :class="['form-control m-input m-login__form-input--last', errors && errors.password ? 'is-invalid' : '']"
                    @keyup.enter="handleEnterKey('password')"
                    v-on:input="trimPassword"
                    type="password"
                    v-model="password"
                    id="password"
                    name="password"
                    autocomplete="off"
                />
            </div>
            <div class="form-group m-form__group" v-else>
                <input
                    class="form-control m-login__form-input--last m-input"
                    @keyup.enter="handleEnterKey('password')"
                    v-on:input="trimPassword"
                    v-model="password"
                    :placeholder="$t('login.onetime.password.placeholder')"
                    type="password"
                    name="password"
                    autocomplete="off"
                />
            </div>
            <div
                class="mt-3"
                v-if="(errors.length || Object.keys(errors).length)"
            >
                <show-multiple-error-messages
                    :errors="errors"
                ></show-multiple-error-messages>
            </div>
        </template>
    </div>

    <div v-else>
        <div class="form-group m-form__group">
            <input
                :disabled="customerNumberContractNumberSent || isLoading || loginLoadingState"
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.onetime.password.customer.number')"
                v-model="customerNumber"
                name="customerNumber"
                autocomplete="off"
                value=""
                @keyup.enter="handleEnterKey('customerNumber')"
            />
        </div>
        <div class="form-group m-form__group">
            <input
                :disabled="customerNumberContractNumberSent || isLoading || loginLoadingState"
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.onetime.password.contract.number')"
                v-model="contractNumber"
                name="contractNumber"
                autocomplete="off"
                value=""
                @keyup.enter="handleEnterKey('contractNumber')"
            />
        </div>
        <template v-if="customerNumberContractNumberSent">
            <div class="form-group m-form__group">
                <template v-if="otpAlternativeAuthTrait == 'iban'">
                    <div id="secret-field-iban-masked-container">
                        <div id="secret-field-iban-masked-label">
                            {{ $t('login.onetime.password.iban.digits.label') }}
                        </div>
                        <input
                            v-for="field in secretFieldIbanMaskedFields"
                            class="secret-field-iban-masked"
                            type="text"
                            maxlength="1"
                            data-test="iban-masked"
                            @keyup="onSecretFieldIbanMaskedKeyUp"
                            :readonly="field.readonly || isLoading || loginLoadingState"
                            :disabled="field.readonly || isLoading || loginLoadingState"
                            :value="field.value"
                        />
                    </div>
                </template>
                <template v-else>
                    <input
                        :disabled="isLoading || loginLoadingState"
                        class="form-control m-input"
                        type="text"
                        :placeholder="$t('login.onetime.password.email.address')"
                        v-model="emailAddress"
                        name="emailAddress"
                        autocomplete="off"
                        value=""
                        @keyup.enter="handleEnterKey('emailAddress')"
                    />
                </template>
            </div>
        </template>

        <div class="mt-3" v-if="(errors.length || Object.keys(errors).length)">
            <show-multiple-error-messages
                :errors="errors"
            ></show-multiple-error-messages>
        </div>
    </div>

    <div class="m-login__action">
        <!-- Messages -->
        <div class="mt-3 me-auto">
            <div
                class="errors me-auto"
                v-if="displayElement('input-personal-errors') && errors.length"
            >
                <div class="text-danger" v-for="error in errors">
                    <div v-for="msg in error">
                        <img src="/img/evus/lichtblick/error.svg" alt="error" />
                        {{ msg }}
                    </div>
                </div>
            </div>
        </div>
        <vue-recaptcha
            v-if="captchaEnabled"
            v-show="showCaptcha"
            class="justify-content-end"
            :class="[showCaptcha ? 'd-flex' : '']"
            ref="recaptcha"
            @verify="onVerify"
            :loadRecaptchaScript="true"
            :sitekey="googleSiteKey"
            :size="captchaSize"
        >
        </vue-recaptcha>

        <template v-if="!showOtpAlternative">
            <vue-button-spinner
                v-if="passwordSent"
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
                :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                :is-loading="isLoading || loginLoadingState"
                :disabled="isLoading || loginLoadingState || !isOneTimePasswordLoginReady"
                :status="status"
            >
                <span @click="loginWithOnetimePassword"
                    >{{ $t('login.submit') }}</span
                >
            </vue-button-spinner>
            <vue-button-spinner
                v-else
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
                :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                :is-loading="isLoading || loginLoadingState"
                :disabled="!canSubmitOtpPrepare || isLoading || loginLoadingState"
                :status="status"
            >
                <span @click="login"
                    >{{ isOneTimePasswordEnableAlternative ?
                    $t('login.submit.otp.long') : $t('login.submit') }}</span
                >
            </vue-button-spinner>

            <template
                v-if="isOneTimePasswordEnableAlternative && !passwordSent"
            >
                <div class="login-submit-otp-alternative-or"></div>

                <vue-button-spinner
                    class="btn btn-link m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner flex-margin-left-auto"
                    :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                    :is-loading="isLoading || loginLoadingState"
                    :disabled="isLoading || loginLoadingState"
                    :status="status"
                >
                    <span @click="switchToOtpAlternative"
                        >{{ $t('login.submit.otp.alternative.btn.switch')
                        }}</span
                    >
                </vue-button-spinner>
            </template>
        </template>
        <template v-else>
            <vue-button-spinner
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
                :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                :is-loading="isLoading || loginLoadingState"
                :disabled="!canSubmitOtpAlternative || isLoading || loginLoadingState"
                :status="status"
            >
                <span @click="login"
                    >{{ !customerNumberContractNumberSent ?
                    $t('login.submit.otp.alternative.verify') :
                    $t('login.submit') }}</span
                >
            </vue-button-spinner>

            <div class="login-submit-otp-alternative-or"></div>

            <vue-button-spinner
                class="btn btn-link m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner flex-margin-left-auto"
                :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                :is-loading="isLoading || loginLoadingState"
                :disabled="isLoading || loginLoadingState"
                :status="status"
            >
                <span @click="switchToOtpDefault"
                    >{{ $t('login.submit.otp.alternative.btn.switch.back')
                    }}</span
                >
            </vue-button-spinner>
        </template>
    </div>

    <div
        v-if="(passwordSent) && (!errors || errors.length === 0)"
        class="otp-message-success"
    >
        <div class="otp-message-success__title">
            {{ $t('login.onetime.password.success.title') }}
        </div>
        <div
            class="otp-message-success__item"
            v-html="$t('login.onetime.password.success.1')"
        ></div>
        <div
            class="otp-message-success__item"
            v-html="$t('login.onetime.password.success.2')"
        ></div>
        <div
            class="otp-message-success__item"
            v-html="$t('login.onetime.password.success.3')"
        ></div>
        <div
            class="otp-message-success__item"
            v-html="$t('login.onetime.password.success.4')"
        ></div>
    </div>
</div>
