<div class="m-portlet m-portlet--mobile m-portlet--balance border-radius-rb">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <div id="accountBalance-content" style="display: block">
                    <div
                        data-test="account-balance-widget-click-icon"
                        v-if="showAction && accountBalanceLoaded && !isActiveWidget"
                        @click="$emit('toggleWidget', 'accountTransactionsWidget')"
                        class="fullwidthArea-open"
                    >
                        <i class="fa fa-times"></i>
                    </div>
                    <div
                        data-test="account-balance-widget-click-icon"
                        v-if="isActiveWidget"
                        @click="$emit('toggleWidget', 'accountTransactionsWidget')"
                        class="fullwidthArea-close"
                    >
                        <i class="fa fa-times"></i>
                    </div>
                    <div
                        class="accountBalance__title"
                        :class="{ 'text-danger': accountBalance < 0 }"
                    >
                        <div v-if="!accountBalanceLoaded">
                            <content-loader
                                :width="165"
                                :height="35"
                                style="width: 165px; height: 35px"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                            {{ $t('euro') }}
                        </div>
                        <template v-else>
                            {{ accountBalance | euroSpecial }} {{ $t('euro') }}
                        </template>
                    </div>
                    <div class="accountBalance__text">
                        {{ $t('widget.ab.title') }}
                    </div>
                </div>
            </div>

            <account-transactions-widget
                v-if="isActiveWidget && displayElement('account-transactions-widget')"
                class="d-md-none"
            ></account-transactions-widget>
        </div>
    </div>
</div>
