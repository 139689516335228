import ProductData from '@/interfaces/ProductData';
import moment from 'moment';
import ThgServiceElliDefault from './thg/ThgServiceElliDefault';
import VehicleType from './VehicleType';

export default class NonCommodityProductFacade implements ProductData {
    public addonsInReleasedVersion: number = 0;
    public availability: string = '';
    public bonusSavings: number = 0;
    public business: string = '';
    public cancellationPeriodDays: number = 0;
    public cancellationPeriodDaysType: string = '';
    public deposit: { brutto: number } = { brutto: 0 };
    public energy: string = '';
    public extendedData: Record<any, any> | null = null;
    public fixedPriceMonths: number = 0;
    public fixedPriceMonthsType: string = '';
    public highPrioCampaignIdentifier: string = '';
    public highestVersion: string = '';
    public highestVersionName: string = '';
    public hasAddOns: string = '';
    public initialDuration: number = 0;
    public initialDurationType: string = '';
    public kind: string = '';
    public name: string = '';
    public title: string = '';
    public productCode: string = '';
    public productId: number = 0;
    public releasedAt: string = '';
    public releasedName: string = '';
    public releasedVersion: number = 0;
    public renewalDuration: number = 0;
    public renewalDurationType: string = '';
    public tariffEco: string = '';
    public type: string = '';
    public updatedAt: string = '';
    public basePrice?: number | undefined;
    public basePriceBrutto?: number | undefined;
    public elliNonCommodityProcurementPrices?:
        | [{ classification: string; net: number; brutto: number }]
        | undefined;
    public cmsDescriptionExternal: string = '';
    public cmsDescriptionInternal: string = '';
    public year: string = '';
    public vouchercode: string = '';

    public static withData(data: Record<any, any>): NonCommodityProductFacade {
        return Object.assign(new NonCommodityProductFacade(), data);
    }

    public static isProductAvailableForBusiness(productCode: string): boolean {
        const code = productCode.toUpperCase();
        return code.includes('THG_') && code.includes('_PRAEMIE');
    }

    public getExtendedData(category: string, technicalKey: string): any {
        const extendedDataCategory = this.extendedData?.values?.[category];
        return extendedDataCategory.find(
            (value) => value.technicalKey === technicalKey
        );
    }

    public getBasePriceBrutto(
        filterYears?: string[] | undefined,
        vehicleClass?: string | undefined
    ): number | undefined {
        if (!filterYears) {
            return this.basePriceBrutto;
        }
        const prices = NonCommodityProductFacade.getVehicleTypePrices(
            this,
            vehicleClass,
            filterYears
        );

        return prices
            ? prices.reduce((accumulator, object) => {
                  return accumulator + object.brutto;
              }, 0)
            : 0;
    }

    public getBasePrice(
        filterYears?: string[] | undefined,
        vehicleClass?: string | undefined
    ): number | undefined {
        if (!filterYears) {
            return this.basePrice;
        }
        const prices = NonCommodityProductFacade.getVehicleTypePrices(
            this,
            vehicleClass,
            filterYears
        );
        return prices
            ? prices.reduce((accumulator, object) => {
                  return accumulator + object.net;
              }, 0)
            : 0;
    }

    public getBasePriceBruttoForYear(year: number | string): number {
        const prices = this.getPricesForYear(year);

        if (prices && prices.length == 1) {
            return prices[0].brutto;
        } else if (prices && prices.length > 1) {
            let price = 0;
            for (let i = 0; i < prices.length; ++i) {
                price += prices[i].brutto;
            }
            return price;
        }

        return 0;
    }

    public getBasePriceForYear(year: number | string): number {
        const prices = this.getPricesForYear(year);

        if (prices && prices.length == 1) {
            return prices[0].net;
        } else if (prices && prices.length > 1) {
            let price = 0;
            for (let i = 0; i < prices.length; ++i) {
                price += prices[i].net;
            }
            return price;
        }

        return 0;
    }

    public getPricesForYear(
        year: number | string
    ):
        | ArrElement<ProductData['elliNonCommodityProcurementPrices']>[]
        | undefined {
        if (typeof year != 'string') {
            year = year + '';
        }
        const prices = NonCommodityProductFacade.getVehicleTypePrices(
            this,
            undefined,
            [year]
        );
        return prices ?? [];
    }

    public get productName(): string {
        return this.name;
    }

    public get isMostPicked(): boolean {
        return this.isEcoProduct;
    }

    public get isCreditProduct(): boolean {
        return this?.productCode.toLowerCase().includes('_credit') ?? false;
    }

    public get isCashProduct(): boolean {
        const productCode = this?.productCode.toLowerCase() || '';
        return (
            (productCode.includes('_prämie') ||
                productCode.includes('_praemie')) ??
            false
        );
    }

    public get isEcoProduct(): boolean {
        return this?.productCode.toLowerCase().includes('bonus') ?? false;
    }

    public get isBusinessProduct(): boolean {
        return this?.productCode.toLowerCase().includes('_b2b') ?? false;
    }

    public get productDescription(): string {
        return this.cmsDescriptionExternal;
    }

    public get hasProductDescription(): boolean {
        return this.cmsDescriptionExternal.length > 0;
    }

    public get productDescriptionShort(): string {
        return this.cmsDescriptionInternal.replace('CO2', 'CO<sub>2</sub>');
    }

    public get hasProductDescriptionShort(): boolean {
        return this.cmsDescriptionInternal.length > 0;
    }

    public get isReady(): boolean {
        return false;
    }

    public static getAvailableYears(
        products: ProductData[],
        isCustomerTypeBusiness: boolean
    ): number[] {
        const years: number[] = [];
        const filteredProducts = this.filterBusinessProducts(
            products,
            isCustomerTypeBusiness
        );

        for (const product of filteredProducts) {
            if (product.elliNonCommodityProcurementPrices) {
                for (const procurementPrice of product.elliNonCommodityProcurementPrices) {
                    const yearFrom = parseInt(
                        moment(procurementPrice.dateFrom).format('YYYY')
                    );
                    const yearUntil = parseInt(
                        moment(procurementPrice.dateUntil).format('YYYY')
                    );

                    if (years.indexOf(yearFrom) == -1) {
                        years.push(yearFrom);
                    }
                    if (years.indexOf(yearUntil) == -1) {
                        years.push(yearUntil);
                    }

                    for (let year = yearFrom + 1; year < yearUntil; ++year) {
                        if (years.indexOf(year) == -1) {
                            years.push(year);
                        }
                    }
                }
            }
        }
        return years.sort();
    }

    public static filterBusinessProducts(
        products: ProductData[],
        returnBusinessProducts: boolean
    ) {
        return products.filter((product) =>
            returnBusinessProducts
                ? this.isProductBusiness(product)
                : !this.isProductBusiness(product)
        );
    }

    private static sumUpPricesForVehicleType(
        products: ProductData[],
        vehicleType: VehicleType,
        filterYears: string[]
    ): VehicleType {
        const productPrices = products.map((product) =>
            this.getVehicleTypePrices(
                product,
                vehicleType.identifier,
                filterYears
            )
        );
        const pricesNetto: number[] = [];
        const pricesBrutto: number[] = [];

        if (productPrices.length > 0) {
            let productPriceNetto: number = 0;
            let productPriceBrutto: number = 0;

            for (const productPrice of productPrices) {
                if (productPrice) {
                    for (const price of productPrice) {
                        productPriceNetto += price?.net ?? 0;
                        productPriceBrutto += price?.brutto ?? 0;
                    }
                }
            }

            pricesNetto.push(productPriceNetto);
            pricesBrutto.push(productPriceBrutto);
        }

        vehicleType.netPrice =
            pricesNetto.length > 0 ? Math.max(...pricesNetto) : 0;
        vehicleType.bruttoPrice =
            pricesBrutto.length > 0 ? Math.max(...pricesBrutto) : 0;

        return vehicleType;
    }

    private static getHighestPriceForVehicleType(
        products: ProductData[],
        vehicleType: VehicleType
    ): VehicleType {
        const prices = products.map((product) =>
            this.getVehicleTypePrice(product, vehicleType.identifier)
        );
        vehicleType.netPrice = 0;
        vehicleType.bruttoPrice = 0;
        if (prices.length > 0) {
            vehicleType.netPrice = Math.max(
                ...prices.map((price) => price?.net ?? 0)
            );
            vehicleType.bruttoPrice = Math.max(
                ...prices.map((price) => price?.brutto ?? 0)
            );
        }

        return vehicleType;
    }

    public static getEnabledVehicleTypes(
        products: ProductData[],
        isCustomerTypeBusiness: boolean,
        filterYears?: string[] | undefined
    ): VehicleType[] {
        const vehicleTypes: VehicleType[] = ThgServiceElliDefault.vehicleTypes;
        const filteredProducts: ProductData[] = this.filterBusinessProducts(
            products,
            isCustomerTypeBusiness
        );

        for (const vehicleTypeKey in vehicleTypes) {
            const vehicleType = vehicleTypes[vehicleTypeKey];
            const productsWithIdentifier: ProductData[] =
                this.getProductsForIdentifier(
                    filteredProducts,
                    vehicleType.identifier
                ) ?? [];

            if (filterYears && filterYears.length > 0) {
                vehicleTypes[vehicleTypeKey] = this.sumUpPricesForVehicleType(
                    productsWithIdentifier,
                    vehicleType,
                    filterYears
                );
            } else {
                vehicleTypes[vehicleTypeKey] =
                    this.getHighestPriceForVehicleType(
                        productsWithIdentifier,
                        vehicleType
                    );
            }
        }

        return vehicleTypes.filter((type) => type.netPrice || type.bruttoPrice);
    }

    public static getProductsForIdentifier(
        products: ProductData[] | undefined,
        identifier: string
    ) {
        return products?.filter((product) =>
            product.elliNonCommodityProcurementPrices?.find(
                (price) => price.classification === identifier
            )
        );
    }

    private static getVehicleTypePrice(
        product: ProductData | undefined,
        identifier: string
    ):
        | ArrElement<ProductData['elliNonCommodityProcurementPrices']>
        | undefined {
        return product?.elliNonCommodityProcurementPrices?.find(
            (vehicleTypePrice) => vehicleTypePrice.classification === identifier
        );
    }

    private static getVehicleTypePrices(
        product: ProductData | undefined,
        vehicleClass: string | undefined,
        filterYears: string[]
    ):
        | ArrElement<ProductData['elliNonCommodityProcurementPrices']>[]
        | undefined {
        const data: ArrElement<
            ProductData['elliNonCommodityProcurementPrices']
        >[] = [];
        if (product && product.elliNonCommodityProcurementPrices) {
            for (const price of product.elliNonCommodityProcurementPrices) {
                if (
                    !price ||
                    (vehicleClass && price.classification != vehicleClass)
                ) {
                    continue;
                }

                let isValidDate = false;
                const dateFrom = moment(price.dateFrom, 'YYYY-MM-DD');
                const dateUntil = moment(price.dateUntil, 'YYYY-MM-DD');

                for (const filterYear of filterYears) {
                    const dateYear = moment(
                        filterYear + '-01-01',
                        'YYYY-MM-DD'
                    );
                    if (
                        dateFrom.isSameOrBefore(dateYear) &&
                        dateUntil.isSameOrAfter(dateYear)
                    ) {
                        isValidDate = true;
                        break;
                    }
                }

                if (!isValidDate) {
                    continue;
                }

                data.push(price);
            }
        }

        return data;
    }

    private static isProductBusiness(product: ProductData): boolean {
        return product.productCode?.toUpperCase().includes('_B2B') ?? false;
    }

    public static getEnabledVehicleTypesForProduct(
        product: ProductData
    ): VehicleType[] {
        return NonCommodityProductFacade.getEnabledVehicleTypes(
            [product],
            this.isProductBusiness(product)
        );
    }

    public get productImage(): string {
        return (
            '/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/product/product-' +
            this.productImageType +
            '.jpg'
        );
    }

    private get productImageType(): string {
        if (this.isEcoProduct) {
            return 'naturstrom';
        } else if (this.isCreditProduct) {
            return 'wecharge';
        }
        return 'cash';
    }
}
