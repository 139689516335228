import vSelect from 'vue-select';
import Base from '@/mixins/base';
import { default as Component, mixins } from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import { BaseFormElement } from '../BaseFormElement';
import Vue from 'vue';

@Component({
    components: {},
})
export class CustomVSelect extends mixins(vSelect) {
    @Prop() public value!: string;
    @Prop() public form!: string;
    @Prop({ default: 'label' }) public label: boolean | undefined;
    @Prop() public id!: string;
    @Prop() public input!: string;
    @Prop() public type!: string;
    @Prop() public forceDisable: boolean | undefined;

    @Inject('form') public injectedForm!: string;
    @Inject('id') public injectedId!: string;

    mounted() {
        this.$el.querySelector('input')!.setAttribute('data-test', this.getId);
    }

    get getForm(): string {
        if (this.form) {
            return this.form;
        } else if (this.injectedForm) {
            return this.injectedForm;
        } else {
            throw new Error('No form was provided');
        }
    }

    get getId(): string {
        if (this.id) {
            return this.id;
        } else if (this.injectedId) {
            return this.injectedId;
        } else {
            console.warn('No id provided for input');
            return '';
        }
    }

    beforeCreate() {
        // @ts-ignore
        Vue.delete(this.$options.props, 'disabled');
    }

    get disabled(): boolean {
        if (this.forceDisable) {
            return true;
        }
        if (this.$store.state.settings.formSettings[this.getForm]) {
            if (
                this.$store.state.settings.formSettings[
                    this.getForm
                ].disabled.includes(this.getId)
            ) {
                this.$emit('disabled', true);
                return true;
            }
        }
        this.$emit('disabled', false);
        return false;
    }
}
