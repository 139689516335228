// -----------------------------
//      Auth Data structure
// -----------------------------

import i18n from '@/i18n';

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import Auth from '../../interfaces/Auth';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);

// Data Structure
const state = {
    status: false,
    errorTryCount: 0,
    errorTryLastTime: 0,
    isStepFour: false,
    forceChangePassword: false,
    isTFAActive: false,
    username: '',
};

// Outside methods for communication with data.
const actions = {
    set({ commit }, status): void {
        if (status) {
            commit('SET_AUTH', status);
        }
    },
    loadErrorTry({ commit }): void {
        commit('LOAD_ERROR_TRIES');
    },
    incrementErrorTry({ commit }): void {
        commit('INCREMENT_ERROR_TRY');
    },
    errorTryReset({ commit }): void {
        commit('SET_ERROR_TRIES_RESET');
    },
    setStepFour({ commit }, isStepFour): void {
        commit('SET_STEP_FOUR', isStepFour);
    },
    setUserName({ commit }, username): void {
        commit('SET_USER_NAME', username);
    },
    forceChangePassword({ commit }, username): void {
        commit('FORCE_CHANGE_PASSWORD');
        commit('SET_USER_NAME', username);
    },
    isTFAActive({ commit }, isTFAActive): void {
        commit('IS_TFA_ACTIVE', isTFAActive);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    idp({ commit }, data): Promise<void> {
        return new Promise((resolve, reject) => {
            authProvider
                .idpCheckToken(data)
                .then((result) => {
                    if (typeof result.data !== 'undefined') {
                        resolve(result.data);
                    } else {
                        reject(new Error(i18n.t('no.data').toLocaleString()));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

// Logic that change data
const mutations = {
    SET_AUTH(localState, status) {
        if (status === authProvider.isAuthenticated()) {
            localState.status = status;
        }
    },
    LOAD_ERROR_TRIES(localState) {
        const errorTries = localStorage.getItem('t');
        if (errorTries) {
            const errorTriesArr = errorTries.split('|');
            if (errorTriesArr.length === 2) {
                localState.errorTryCount = parseInt(errorTriesArr[0], 10);
                localState.errorTryLastTime = parseInt(errorTriesArr[1], 10);
            }
        }
    },
    INCREMENT_ERROR_TRY(localState) {
        localState.errorTryCount++;
        localState.errorTryLastTime = new Date().getTime();
        localStorage.setItem(
            't',
            localState.errorTryCount + '|' + localState.errorTryLastTime
        );
    },
    SET_ERROR_TRIES_RESET(localState) {
        localState.errorTryCount = 0;
        localState.errorTryLastTime = 0;
        localStorage.removeItem('t');
    },
    SET_STEP_FOUR(localState, isStepFour) {
        localState.isStepFour = isStepFour;
    },
    SET_USER_NAME(localState, username) {
        localState.username = username;
    },
    FORCE_CHANGE_PASSWORD(localState) {
        localState.forceChangePassword = true;
    },
    IS_TFA_ACTIVE(localState, isTFAActive) {
        localState.isTFAActive = true;
    },
};

// Get Data transformation (like computed)
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
