import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './tariff-available-widget.html';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import AddOn from '@/interfaces/AddOn';

const addOnProvider = IocContainer.get<AddOn>(SERVICES.ADDON);
@WithRender
@Component({})
export class TariffAvailableWidget extends Base {
    protected parseImage(image: string | null): string {
        return addOnProvider.parseImage(image);
    }
}
