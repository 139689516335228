<footer class="m-grid__item m-footer">
    <div class="montains">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.661 62.685">
            <polygon
                class="montainfill"
                fill="#ffffff"
                points="708.661 62.685 589.056 27.521 538.224 44.339 509.818 33.636 491.877 38.222 436.559 15.289 406.658 25.991 354.331 0 302.003 25.991 272.102 15.289 216.784 38.222 198.844 33.636 170.439 44.339 119.606 27.521 0 62.685 708.661 62.685"
            ></polygon>
        </svg>
    </div>
    <div class="bottom-footer">
        <div
            class="m-container m-container--fluid m-container--full-height m-page__container"
        >
            <div
                class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
            >
                <div class="footer-content row">
                    <div class="footer-content__title">
                        {{ $t('footer.title') }}
                    </div>
                    <div class="footer-content__text">
                        {{ $t('footer.text') }}
                    </div>
                    <a
                        href="https://www.schweizstrom.de/de/service/kunden-werben.html "
                        target="_blank"
                        class="btn btn-primary footer-content__link"
                        >{{ $t('footer.button') }}</a
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="footer-content-bottom">
        <div
            class="m-container m-container--fluid m-container--full-height m-page__container"
        >
            <div
                class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
            >
                <div class="footer-content-bottom__info info-footer-bottom">
                    <div class="info-footer-bottom">
                        <div class="info-footer-bottom__descr">
                            © EBLD Schweiz Strom GmbH i.L.
                        </div>
                        <ul class="info-footer-bottom__list">
                            <li class="info-footer-bottom__item">
                                <a
                                    href="https://www.schweizstrom.de/de/ueber-uns/kontakt.html "
                                    target="_blank"
                                    >{{ $t('footer.contact') }}</a
                                >
                            </li>
                            <li class="info-footer-bottom__item">
                                <a
                                    href="https://www.schweizstrom.de/de/service/haeufige-fragen-kundenportal.html"
                                    target="_blank"
                                    >{{ $t('footer.faqs') }}</a
                                >
                            </li>
                            <li class="info-footer-bottom__item">
                                <a
                                    href="https://www.schweizstrom.de/de/impressum.html "
                                    target="_blank"
                                    >{{ $t('footer.imprint')}}</a
                                >
                            </li>
                            <li class="info-footer-bottom__item">
                                <a
                                    href="https://www.schweizstrom.de/de/impressum/datenschutz.html"
                                    target="_blank"
                                    >{{ $t('footer.privacy') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
