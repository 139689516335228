<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div
            class="d-flex align-items-center"
            v-if="currentClient === 'wdenergy'"
        >
            <i class="fa fa-envelope-open readingHistory-icon"></i>
            <span class="ms-2 customerCommunications-title static-title"
                >{{ $t('widget.pb.title') }}</span
            >
        </div>
        <template v-else>
            <i class="fa fa-envelope-open readingHistory-icon"></i>
            <span class="customerCommunications-title static-title"
                >{{ $t('widget.pb.title') }}</span
            >
        </template>
        <br />
        <div class="tab-content" id="customerCommunications-content">
            <div
                class="tab-pane active"
                id="m_widget2_tab1_content"
                aria-expanded="true"
            >
                <!--begin:Timeline 1-->
                <div class="m-timeline-1 m-timeline-1--fixed">
                    <div
                        class="m-timeline-1__items"
                        id="customerCommunications-timeline"
                    >
                        <div class="m-timeline-1__marker"></div>
                        <div
                            v-if="communicationsErrorLoaded"
                            class="information-not-loaded"
                        >
                            {{ $t('widgets.load.error.message') }}
                        </div>
                        <div
                            v-else
                            v-for="communication in communications"
                            class="m-timeline-1__item"
                            :class="communication.timelineClass"
                        >
                            <div class="m-timeline-1__item-circle">
                                <div class="m--bg-primary"></div>
                            </div>
                            <div class="m-timeline-1__item-arrow"></div>
                            <span
                                class="m-timeline-1__item-time"
                                v-if="displayElement('show-date-without-time')"
                                >{{ communication.createdAt | date }}</span
                            >
                            <span class="m-timeline-1__item-time" v-else
                                >{{ communication.createdAt | datetime }}</span
                            >
                            <div class="m-timeline-1__item-content">
                                <div
                                    class="m-timeline-1__item-title"
                                    :class="{ withButton: displayElement('show-download-button')}"
                                >
                                    <span
                                        id="downloadableCommunication"
                                        v-if="communication.archiveId"
                                        :class="checkHasDownloadError(communication.communicationId) ? 'downloadError' : ''"
                                        @click="downloadCustomerCommunication(communication)"
                                        >{{ communication.title }}
                                        <i
                                            v-if="!displayElement('show-download-button')"
                                            class="fa fa-file-pdf customerCommunicationDownloadIcon"
                                        ></i
                                    ></span>
                                    <span v-else
                                        >{{ communication.title }}</span
                                    >
                                    <button
                                        v-if="displayElement('show-download-button')"
                                        class="btn btn-sm btn-invoice-download btn-primary"
                                    >
                                        <span>Download</span>
                                    </button>
                                </div>
                            </div>
                            <div
                                v-if="checkHasDownloadError(communication.communicationId)"
                                class="customerCommunications-error-box"
                            >
                                {{ $t('widget.cbd.postbox.error.file') }}
                            </div>
                        </div>
                        <div>
                            <service-widget
                                :serviceWidgetPosition="serviceWidgetPosition"
                                @serviceWidgetClicked="$emit('serviceWidgetClicked')"
                            ></service-widget>
                        </div>
                        <div
                            v-if="displayElement('show-custom-agb') && currentProduct.cmsAgb"
                            :class="['m-timeline-1__item', requiredDocumentsPosition]"
                        >
                            <div class="m-timeline-1__item-circle">
                                <div class="m--bg-primary"></div>
                            </div>
                            <div class="m-timeline-1__item-arrow"></div>
                            <div class="m-timeline-1__item-content">
                                <div class="m-timeline-1__item-title">
                                    <span
                                        id="downloadableCommunication"
                                        @click="downloadArchiveFile(currentProduct.cmsFileId)"
                                        >{{ $t('AGB') }} {{
                                        getOnlyText(currentProduct.cmsAgb) }}
                                        <i
                                            class="fa fa-file-pdf customerCommunicationDownloadIcon"
                                        ></i
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END Service Widget -->
                </div>
                <div class="row">
                    <div class="col m--align-center">
                        <button
                            @click="loadMore"
                            v-if="!allLoaded"
                            class="btn btn-sm m-btn--custom m-btn--pill btn-primary"
                        >
                            {{ $t('widget.pb.more') }}
                        </button>
                    </div>
                </div>
                <!--End:Timeline 1-->
            </div>
        </div>
    </div>
</div>
