<div class="col-12 mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row mobile-modal__wrap" id="tariffDetails-content">
                <div class="col-12">
                    <span class="box-title static-title">
                        {{ $t('widget.td.revocation') }}
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col">{{ $t('widget.r.description')}}</div>
                <div class="col">
                    <label class="form-check-label">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="meterReadSubmit_forceInvoice"
                            v-model="confirm"
                        />
                        <span class="revoke-widget-checkbox-text"
                            >{{ $t('widget.td.checkbox-label') }}</span
                        >
                    </label>

                    <div class="d-flex justify-content-end">
                        <button
                            :disabled="!confirm"
                            @click="revoke"
                            class="
                                btn btn-primary
                                m-btn m-btn--custom m-btn--air
                                m-login__btn m-login__btn--primary
                                mt-4
                            "
                        >
                            {{ $t("widget.c.confirm") }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-8 col-xs-12">
                    <messages-block
                        :success="successInformation"
                        :errors="errorInformation"
                    ></messages-block>
                </div>
            </div>
        </div>
    </div>
</div>
