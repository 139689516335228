import { default as Component } from 'vue-class-component';
import WithRender from './datetime.html';
import CustomInput from '../input';
import { Datetime } from 'vue-datetime';
import { Prop, Inject } from 'vue-property-decorator';

@WithRender
@Component({
    components: {
        datetime: Datetime,
    },
})
export class CustomDateTime extends CustomInput {
    @Prop() public value!: string;
    @Prop() public inputId: string | undefined;
    @Prop() public inputClass: string | [] | any | undefined;
    @Prop() public inputStyle: string | [] | null | undefined;
    @Prop() public hiddenName: string | undefined;
    @Prop() public valueZone: string | undefined;
    @Prop() public zone: string | undefined;
    @Prop() public format: string | any | undefined;
    @Prop() public phrases: any | undefined;
    @Prop() public hourStep: number | undefined;
    @Prop() public minuteStep: number | undefined;
    @Prop() public minDatetime: string | undefined;
    @Prop() public maxDatetime: string | undefined;
    @Prop() public auto: boolean | undefined;
    @Prop() public weekStart: number | undefined;
    @Prop() public flow: [] | undefined;
    @Prop() public title: string | undefined;
    @Prop() public hideBackdrop: boolean | undefined;
    @Prop() public backdropClick: boolean | undefined;

    mounted() {
        if (this.$el.getAttribute('data-test') === this.getId) {
            this.$el
                .querySelector('input')!
                .setAttribute('data-test', `${this.getId}-input`);
        } else {
            this.$el
                .querySelector('input')!
                .setAttribute('data-test', this.getId);
        }
    }

    get inputValue() {
        return this.value;
    }

    public onInput(date) {
        this.$emit('input', date);
    }
}
