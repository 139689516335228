<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-2">
                <i class="fas fa-file-alt documents-icon"></i>
            </div>
            <div class="col-10">
                <div class="customerCommunications-title static-title">
                    {{ $t('widget.pb.title') }}
                </div>
            </div>
        </div>
        <content-loader
            v-if="!isLoadedCommunications"
            data-test="CONTENT-LOADER"
            height="20"
        ></content-loader>
        <table class="table" v-if="allLoaded && communications.length">
            <tr>
                <th>{{ $t('widget.at.date') }}</th>
                <th>{{ $t('widget.pb.name') }}</th>
            </tr>
            <tr v-for="communication in communications">
                <td>{{ communication.createdAt | datetime }}</td>
                <td
                    :class="checkHasDownloadError(communication.communicationId) ? 'downloadError' : ''"
                >
                    {{ communication.title }}
                    <span
                        v-if="communication.archiveId"
                        @click="downloadCustomerCommunication(communication)"
                        ><i
                            class="
                                fa fa-download
                                customerCommunicationDownloadIcon
                            "
                        ></i
                    ></span>
                    <div
                        v-if="checkHasDownloadError(communication.communicationId)"
                        class="customerCommunications-error-box"
                    >
                        {{ $t('widget.cbd.postbox.error.file') }}
                    </div>
                </td>
            </tr>
        </table>
        <div class="col m--align-center">
            <button
                @click="loadMore"
                v-if="!allLoaded"
                class="btn btn-sm m-btn--custom m-btn--pill btn-primary"
            >
                {{ $t('widget.pb.more') }}
            </button>
        </div>
    </div>
</div>
