import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './thg-bonus-selector.html';
import { Prop } from 'vue-property-decorator';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import ProductData from '@/interfaces/ProductData';
import iocContainer from '@/container/IocContainer';
import Bonus from '@/interfaces/Bonus';
import SERVICES from '@/container/Services';
import NonCommodityProductFacade from '@/models/NonCommodityProductFacade';
import Format from '@/interfaces/Format';
import { FormComponents } from '@/components/snippets/form-components';
import FileService from '@/services/FileService';
import Files from '@/interfaces/Files';
import Payment from '@/interfaces/Payment';

const formatProvider = iocContainer.get<Format>(SERVICES.FORMAT);
const bonusProvider = iocContainer.get<Bonus>(SERVICES.BONUS);
const apiProvider = iocContainer.get<Files>(SERVICES.FILES);
const paymentProvider = iocContainer.get<Payment>(SERVICES.PAYMENT);

@WithRender
@Component({
    components: {
        'custom-v-select': FormComponents.CustomVSelect,
        'custom-select': FormComponents.CustomSelect,
        'custom-input': FormComponents.CustomInput,
        'custom-datetime': FormComponents.CustomDateTime,
        'custom-radio': FormComponents.CustomRadio,
        'form-wrapper': FormComponents.FormWrapper,
        'form-group': FormComponents.FormGroup,
        'custom-label': FormComponents.CustomLabel,
        'custom-checkbox': FormComponents.CustomCheckbox,
    },
    filters: {
        euro: formatProvider.euro(),
    },
})
export class ThgBonusSelector extends Base {
    @Prop() public bonusYear!: string;
    @Prop() public thgService!: ThgBaseService;
    @Prop() public showVehicleRegistrationUploadSuccess?: boolean;

    public isLoading: boolean = false;
    public isConfirming: boolean = false;
    public isFileUploading: boolean = false;
    public isFileSubmitting: boolean = false;
    public isLoadingBic: boolean = false;
    public errorMessage: string = '';

    public showProductSelection: boolean = true;
    public showVehicleDataEditor: boolean = false;
    public showVehicleDocumentUpload: boolean = false;

    protected productData: ProductData[] = [];
    protected productYear: string = '';
    protected selectedProductCode: string = '';
    protected confirmVehicle: boolean = false;
    protected confirmGtcEco: boolean = false;

    protected bankDetailsBearer: string = '';
    protected bankDetailsIban: string = '';
    protected bankDetailsBic: string = '';
    protected vehicleDataUpdateType: string = 'newDocument';
    protected bicTimer: any = null;

    protected displayedProductDetails: NonCommodityProductFacade | null = null;
    protected uploadedFiles: any[] = [];

    mounted(): void {
        if (this.productData.length == 0) {
            this.loadProducts();
        }
    }

    public vehicleDataNextStep(): void {
        if (
            this.vehicleDataUpdateType ==
            this.thgService.vehicleDataUpdateTypeNewDocument
        ) {
            this.showModalVehicleDocumentUpload();
        } else {
            this.showJourneyProcess();
        }
    }

    public showModalVehicleDocumentUpload(): void {
        this.showProductSelection = false;
        this.showVehicleDataEditor = false;
        this.showVehicleDocumentUpload = true;
    }

    public showModalVehicleDataEditor(): void {
        this.showProductSelection = false;
        this.showVehicleDataEditor = true;
        this.showVehicleDocumentUpload = false;
    }

    public showModalProductSelection(): void {
        this.showProductSelection = true;
        this.showVehicleDataEditor = false;
        this.showVehicleDocumentUpload = false;
    }

    public showJourneyProcess(): void {
        this.$router.push('/dashboard/' + this.contractId + '/sales');
        this.closeSelector();
    }

    public get isDocumentReadyForUpload(): boolean {
        return !this.isFileUploading && !this.isFileSubmitting;
    }

    public changeVehicleDataForYear(year: string): void {
        this.$emit('changeVehicleDataForYear', year);
    }

    public get isReadyForSelection(): boolean {
        return this.isVehicleDataAvailable;
    }

    public get isAnyProductSelected(): boolean {
        return this.selectedProductCode.length > 0;
    }

    public get isBankDataFilled(): boolean {
        return (
            this.bankDetailsBearer.length > 0 &&
            this.bankDetailsIban.length > 0 &&
            this.bankDetailsBic.length > 0
        );
    }

    public get isReadyForConfirmation(): boolean {
        if (
            this.thgService.isBankDataRequiredForProductAndYear(
                this.selectedProduct,
                this.bonusYear
            )
        ) {
            if (!this.isBankDataFilled) {
                return false;
            }
        }

        if (this.selectedProduct?.isEcoProduct && !this.confirmGtcEco) {
            return false;
        }

        return (
            this.isVehicleDataAvailable &&
            this.confirmVehicle &&
            this.isAnyProductSelected
        );
    }

    public get isVehicleDataAvailable(): boolean {
        return this.thgService.getLicensePlate().length > 0;
    }

    public get vehicleClass(): string {
        const vehicleClass = this.thgService.getExtendedDataFieldValue(
            this.thgService.extendedDataKeyVehicleClasses
        );
        if (vehicleClass == '') {
            const vehicleClassWd = this.thgService.getExtendedDataFieldValue(
                this.thgService.extendedDataKeyVehicleClassesWorkDigital
            );
            return vehicleClassWd.toLowerCase();
        }
        return vehicleClass.toLowerCase();
    }

    public get nonCommodityProducts(): NonCommodityProductFacade[] {
        const products: NonCommodityProductFacade[] = [];
        for (const product of this.products) {
            products.push(NonCommodityProductFacade.withData(product));
        }
        return products;
    }

    public showProductInfo(product: NonCommodityProductFacade): void {
        this.displayedProductDetails = product;
    }

    public hideProductInfo(): void {
        this.displayedProductDetails = null;
    }

    public loadProducts(): void {
        if (this.isLoading) {
            return;
        }
        this.productData = [];
        this.isLoading = true;
        const that = this;

        const data = {
            year: this.bonusYear,
            contractId: this.thgService.contractId,
            campaign: this.nonCommodityProductCampaign,
        };
        bonusProvider
            .getBonusProducts(data)
            .then((response) => {
                if (response.data.success) {
                    for (const product of response.data.products) {
                        const productInformation =
                            NonCommodityProductFacade.withData(product);
                        if (this.thgService.isCustomerTypeBusiness) {
                            if (productInformation.isBusinessProduct) {
                                this.productData.push(productInformation);
                            }
                        } else {
                            if (!productInformation.isBusinessProduct) {
                                this.productData.push(productInformation);
                            }
                        }
                    }
                }
            })
            .catch(function (e) {
                console.log(e);
            })
            .finally(() => {
                that.isLoading = false;
            });
    }

    get products(): ProductData[] {
        return this.productData;
    }

    public closeSelector() {
        this.$emit('closeSelector');
    }

    public get licensePlate(): string {
        const licensePlate: string = this.thgService.getLicensePlate();
        return licensePlate.length > 0 ? licensePlate : 'XX-XX-XXXX';
    }

    public displayError(errorKey: string): void {
        this.errorMessage = this.$t(errorKey).toString();
    }

    public resetError(): void {
        this.errorMessage = '';
    }

    public confirmProduct(): void {
        if (this.isConfirming) {
            return;
        }

        this.resetError();

        this.isConfirming = true;
        const that = this;

        const data = {
            year: this.bonusYear,
            contractId: this.thgService.contractId,
            licensePlate: this.thgService.getLicensePlate(),
            productCode: this.selectedProduct?.productCode,
            bankData: {},
            newYearPrice:
                this.selectedProduct?.business === 'private'
                    ? this.selectedProduct?.getBasePriceBrutto(
                          [this.bonusYear],
                          this.vehicleClass
                      )
                    : this.selectedProduct?.getBasePrice(
                          [this.bonusYear],
                          this.vehicleClass
                      ),
        };

        if (
            this.thgService.isBankDataRequiredForProductAndYear(
                this.selectedProduct,
                this.bonusYear
            ) &&
            this.isBankDataFilled
        ) {
            data.bankData = {
                bearer: this.bankDetailsBearer,
                iban: this.bankDetailsIban,
                bic: this.bankDetailsBic,
            };
        }

        bonusProvider
            .applyBonus(data)
            .then((response) => {
                if (response.data.success) {
                    that.$emit('bonusSubmittedForYear', that.bonusYear);
                    this.$store.dispatch('contracts/contracts');
                    this.$store.dispatch(
                        'contracts/products',
                        this.$store.state.contracts.contractId
                    );
                } else {
                    that.displayError(response.data.error);
                }
            })
            .catch(function (e) {
                that.displayError('widget.nc.bonus.selection.error');
            })
            .finally(() => {
                that.isConfirming = false;
            });
    }

    public get selectedProduct(): NonCommodityProductFacade | null {
        for (const product of this.nonCommodityProducts) {
            if (product.productCode == this.selectedProductCode) {
                return product;
            }
        }
        return null;
    }

    public get selectedProductName(): string {
        return this.selectedProduct ? this.selectedProduct.productName : '';
    }

    protected updateBic(): void {
        this.isLoadingBic = true;
        this.bankDetailsBic = '';

        this.bankDetailsIban = this.bankDetailsIban.replaceAll(' ', '');
        paymentProvider
            .bicFromIban(this.bankDetailsIban)
            .then(
                (response) => {
                    this.isLoadingBic = false;
                    if (response.data.success) {
                        this.bankDetailsBic = response.data.data.bic;
                    } else {
                        this.bankDetailsBic = '';
                    }
                },
                (error) => {
                    this.isLoadingBic = false;
                    this.bankDetailsBic = '';
                }
            )
            .then((error) => {
                console.log(error);
            });
    }

    protected onFileSelect(event: Record<any, any>) {
        const file: File = event.target.files[0];
        if (
            ['image/svg', 'image/svg+xml'].some((type) =>
                type.includes(file.type)
            )
        ) {
            event.target.value = '';
            return;
        }

        this.isFileUploading = true;
        const side: string = event.target.getAttribute('data-side');
        const fileService = new FileService(side, file);

        const that = this;

        fileService
            .upload()
            .then((res): void => {
                this.uploadedFiles.push(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => (that.isFileUploading = false));

        return true;
    }

    public submitUploadedFile() {
        if (this.isFileUploading || this.uploadedFiles.length !== 1) {
            return;
        }

        this.isFileSubmitting = true;
        const that = this;

        apiProvider
            .uploadFileViaS3({
                files: this.uploadedFiles,
                contractId: this.contractId,
                year: this.bonusYear,
            })
            .then((res) => {
                if (res.data.success) {
                    //that.$store.dispatch('contracts/contracts');
                    that.showVehicleRegistrationUploadSuccess = true;
                    that.showModalProductSelection();
                }
            })
            .finally(() => {
                that.isFileSubmitting = false;
            });
    }
}
