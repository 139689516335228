<div class="row">
    <div class="col-12 mobile-modal__inner" v-if="productCodeConnectTariff">
        <div class="m-portlet">
            <div class="m-portlet__body">
                <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <!--
                        <span class="tarifOverview-title static-title static-title--custom"
                        >{{ $t('widget.eu.title') }}</span
                    >
                    -->
                        <div
                            class="row mobile-modal__wrap"
                            id="ecarUpload-content"
                            v-if="scriptIsInjected"
                        >
                            <greentrax-widget
                                name="wd-greentrax"
                                :contract_id="contractId"
                            ></greentrax-widget>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
