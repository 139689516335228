<div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <!-- email -->
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="email"
                ><strong>{{ $t('registration.email') }}</strong></label
            >
            <input
                :class="['form-control m-input', errors && errors.email ? 'is-invalid' : '']"
                type="email"
                name="email"
                id="email"
                v-model="email"
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('registration.email')"
                name="email"
                v-model="email"
            />
        </div>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>

        <!--begin::Action-->
        <div class="m-login__action">
            <div>
                <a @click="$router.push({name: 'login'})" class="m-link">
                    {{ $t('login.already.have') }}
                </a>
                <a @click="$router.push({name: 'register'})" class="m-link">
                    {{ $t('login.start.registration') }}
                </a>
            </div>

            <button
                @click="setUsernameReset()"
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom m-btn--air
                    m-login__btn m-login__btn--primary
                "
            >
                {{ $t('username.reset') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>
