<div class="m-aside-modal m-aside-modal__wrap">
    <div class="m-aside-modal__body">
        <!--begin::Form-->

        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('protected.account.delete') }}
            </span>
            <div class="m-aside-modal__close" @click="closeModal()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>

        <div class="form-group">
            <label>{{ $t('widget.mda.delete.account.reasons') }}</label>
            <v-select
                :options="reasonOptions"
                @input="(object) => reason = object.value"
            ></v-select>
            <span></span>
        </div>

        <div class="form-group m-form__group">
            <label>{{ $t('protected.account.delete.confirm') }}</label>
            <input
                class="form-control m-input"
                :placeholder="$t('protected.password.current')"
                v-model="currentPassword"
                name="currentPassword"
                autocomplete="off"
                value=""
                type="password"
            />
        </div>

        <div class="form-group m-form__group">
            <messages-block
                :errors="errorInformation"
                :success="successInformation"
            ></messages-block>
        </div>

        <!--begin::Action-->
        <div class="&quot;m-login__action">
            <button
                @click="deleteAccount()"
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom
                    m-login__btn m-login__btn--primary
                "
            >
                {{ $t('protected.account.delete') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
</div>
