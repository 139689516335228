<div
    class="mobile-modal__inner tariff-details"
    data-test="tariff-details-widget"
>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div
                data-test="tariff-details-widget-close-click"
                v-if="!displayElement('close-button-hidden')"
                @click="$emit('closeWidget')"
                id="button-closeTariffDetails"
                class="fullwidthArea-close"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="row mobile-modal__wrap" id="tariffDetails-content">
                <div
                    :class="[
                        'col-12',
                        displayElement('align-title') ? 'd-flex align-items-center' : ''
                    ]"
                >
                    <i
                        class="fa fa-hands-helping readingHistory-icon"
                        v-if="displayElement('hands-helping-icon')"
                    ></i>
                    <span
                        class="box-title static-title"
                        :class="[displayElement('non-padding') ? 'pt-0' : '']"
                        v-if="displayElement('contract-details-number')"
                    >
                        {{ $t('widget.td.title') }} {{
                        $t('widget.td.title.extension') }} {{ contract.id }}
                    </span>
                    <span
                        v-else
                        class="box-title static-title"
                        :class="[displayElement('non-padding') ? 'pt-0' : '']"
                        >{{ $t('widget.td.title') }}</span
                    >
                    <button
                        v-if="tariffOverviewAllowed && !displayElement('hide-tariff-overview')"
                        class="btn btn-link ms-2"
                        data-test="tariff-details-widget-tariff-overview-click"
                        @click="$emit('toggleWidget', 'showTariffOverview')"
                    >
                        {{ $t('widget.td.tariffOverview') }}
                    </button>
                </div>
                <div
                    class="col-12"
                    v-if="contractInCancellation && !displayElement('hide-contract-in-cancellation')"
                >
                    <div class="text-warning">
                        {{
                        $t('widget.td.inCancellation.yourCancellationForContract')
                        }} {{ contract.id }} {{
                        $t('widget.td.inCancellation.for') }} {{
                        contract.cancelledAt | date }} {{
                        $t('widget.td.inCancellation.hasBeen') }} {{
                        contract.filingDateCancellation | date }} {{
                        $t('widget.td.inCancellation.received') }}.<br />
                        <span
                            v-if="!displayElement('hide-cancellation-reason')"
                        >
                            {{ $t('widget.td.inCancellation.reason') }}: {{
                            contract.cancellationReason }}
                        </span>
                    </div>
                    <br />
                </div>
                <div class="col-12" v-if="contractInRevocation">
                    <div class="text-warning">
                        {{ $t('widget.td.contractInRevocation', { contractId:
                        contract.id, contractRevokeAt:
                        $options.filters.date(contract.revokeAt),
                        contractFillingDateRevocation:
                        $options.filters.date(contract.fillingDateRevocation),
                        }) }}
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4">
                    <b>{{ $t('widget.td.delivery') }}</b><br />
                    <span id="tariffDetails-deliveryAddress">
                        {{ contract.deliveryAddress.firstname }} {{
                        contract.deliveryAddress.surname }}<br />
                        {{ contract.deliveryAddress.street }} {{
                        contract.deliveryAddress.houseNumber }}<br />
                        {{ contract.deliveryAddress.zip }} {{
                        contract.deliveryAddress.city }}
                    </span>
                    <br />
                    <button
                        v-if="contractMoveAllowed && displayElement('show-contract-move')"
                        class="btn btn-link mb-3"
                        @click="$emit('toggleWidget', 'showMoveWidget')"
                    >
                        {{ $t('widget.td.move') }}
                    </button>
                    <br />

                    <template v-if="displayRuntime">
                        <div
                            v-if="displayElement('product-name-instead-of-runtime')"
                        >
                            <b>{{ product.title }}</b><br />
                        </div>
                        <div v-else>
                            <b>{{ $t('widget.td.runtime') }}</b><br />
                        </div>
                        <span id="tariffDetails-dates">
                            {{ $t('widget.td.begin') }}: {{ contract.startDate |
                            date }}<br />
                            <div v-if="displayElement('show-period-info')">
                                <span>
                                    {{ $t('widget.td.min.term') }}: {{
                                    contract.minimumRuntime }} {{
                                    $t('widget.td.runtime.' +
                                    contract.minimumRuntimeUnit) }} </span
                                ><br />
                                <span>
                                    {{ $t('widget.td.extRuntime') }}: {{
                                    contract.extensionTime }} {{
                                    $t('widget.td.runtime.' +
                                    contract.extensionTimeUnit) }} </span
                                ><br />
                                <span>
                                    {{ $t('widget.td.cancellation.period') }}:
                                    {{ cancellationPeriod }} {{
                                    $t('widget.td.runtime.' +
                                    contract.cancellationPeriodUnit) }}
                                </span>
                            </div>

                            <div v-if="displayElement('show-period-info-hsg')">
                                <span>
                                    {{ $t('widget.td.term.of.contract') }}: {{
                                    contract.minimumRuntime }} {{
                                    $t('widget.td.runtime.' +
                                    contract.minimumRuntimeUnit) }} </span
                                ><br />
                                <span>
                                    {{ $t('widget.td.extRuntime') }}: {{
                                    contract.extensionTime }} {{
                                    $t('widget.td.runtime.' +
                                    contract.extensionTimeUnit) }} </span
                                ><br />
                            </div>

                            <span v-if="contractCancelled">
                                {{ $t('widget.td.end') }}: {{ endDate | date }}
                            </span>
                            <span v-else>
                                <div
                                    v-if="displayElement('hide-nextCancellationDate')"
                                ></div>
                                <div
                                    v-else-if="displayElement('show-nextCancellationDate-extended')"
                                >
                                    {{ $t('widget.td.period') }}: {{
                                    $t('widget.td.period.to') }} {{
                                    nextCancellationDate | date }}<br />
                                    {{ $t('widget.td.cancellation.period') }}:
                                    {{ $tc('weeks', cancellationPeriod) }}
                                </div>
                                <div v-else>
                                    {{ $t('widget.td.period') }}: {{
                                    nextCancellationDate | date }}
                                </div>
                            </span>
                            <div v-if="displayElement('show-term-of-contract')">
                                {{ $t('widget.td.term.of.contract') }}: {{
                                contract.minimumRuntime }} {{
                                $t('widget.td.runtime.' +
                                contract.minimumRuntimeUnit) }}
                            </div>
                        </span>
                        <button
                            v-if="contractCancellationAllowed && displayElement('show-cancellation-button')"
                            class="btn btn-link"
                            @click="$emit('toggleWidget', 'showCancellationWidget')"
                            data-test="button-open-cancellation-widget"
                        >
                            {{ $t('widget.td.cancellation') }}
                        </button>
                    </template>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 text-start">
                    <div class="tariffDetails-title">
                        <b v-if="displayElement('contract-details-label')"
                            >{{ $t('widget.td.contract.details') }}</b
                        >
                        <b v-else>{{ product.title }}</b>
                    </div>
                    <div v-if="displayElement('contract-number-in-td')">
                        {{ $t('widget.td.contractNumber') }}: {{ contract.id }}
                    </div>
                    <div v-if="displayElement('contract-number-in-td')">
                        {{ $t('widget.td.tariff') }}: {{ product.title }}
                    </div>
                    <div v-if="displayElement('counter-number-in-td')">
                        {{ $t('widget.td.counterNumber') }}: {{
                        contract.counterNumber }}
                    </div>
                    <div
                        v-if="displayElement('market-location-identifier-in-td')"
                    >
                        {{ $t('widget.td.marketLocation') }}: {{
                        contract.marketLocation }}
                        <br />
                        <br />
                    </div>

                    <div v-if="displayElement('start-date-in-td')">
                        {{ $t('widget.td.begin') }}: {{ contract.startDate |
                        date }}<br />
                    </div>
                    <div v-if="displayElement('show-term-of-contract')">
                        {{ $t('widget.td.term.of.contract') }}: {{
                        contract.minimumRuntime }} {{ $t('widget.td.runtime.' +
                        contract.minimumRuntimeUnit) }}
                    </div>
                    <div>
                        {{ $t('widget.td.yearlyUsage') }}: {{ contract.usage |
                        euroFull }} {{ commodity }}
                    </div>
                    <div v-if="displayElement('next-cancellation-date-in-td')">
                        {{ $t('widget.td.period') }}: {{
                        $t('widget.td.period.to') }} {{ nextCancellationDate |
                        date }}<br />
                    </div>
                    <div v-if="!displayElement('hide-base-price')">
                        {{ $t('widget.td.basePrice') }}: {{ tariff.basePrice |
                        euro }} {{ $t('euro') }}/{{ $t('year') }}
                    </div>
                    <div v-if="!displayElement('hide-working-price')">
                        {{ $t('widget.td.workingPrice') }}:
                        <template v-for="workingPrice in tariff.workingPrices">
                            <template v-if="tariff.workingPrices.length > 1"
                                >{{ workingPrice.registerName }}:
                            </template>
                            {{ workingPrice.workingPriceBrutto | euro }} {{
                            commodityPrice }}
                            <br v-if="tariff.workingPrices.length > 1" />
                        </template>
                    </div>
                    <div v-if="displayElement('show-fixedPrice')">
                        {{ $t('widget.td.fixedPrice') }}:
                        <span v-if="tariff.fixedPriceDate"
                            >{{ $t('widget.td.fixedPrice.until') }} {{
                            tariff.fixedPriceDate | date }}</span
                        >
                        <span v-else>-</span>
                    </div>
                    <br />
                    <div v-if="displayElement('no-fullPrice')"></div>
                    <div v-else-if="displayElement('no-tilde-fullPrice')">
                        {{ $t('widget.td.fullPrice') }}{{isDoubleTariff ? '*' :
                        ''}}: {{ fullPrice | euroFull }} {{ $t('euro') }}/{{
                        $t('year') }}
                    </div>
                    <div v-else>
                        {{ $t('widget.td.fullPrice').toString()
                        }}{{isDoubleTariff ? '*' : ''}}: ~{{ fullPrice |
                        euroFull }} {{ $t('euro') }}/{{ $t('year') }}
                    </div>
                    <div v-if="displayElement('show-term-of-contract')">
                        {{ $t('widget.td.fixedPrice') }}: {{ fixedPriceMonths }}
                    </div>
                    <div class="mt-2" v-if="isDoubleTariff">
                        <span class="tariff-details-astrix"
                            >{{ $t('widget.td.fullPrice.astrix') }}</span
                        >
                    </div>
                    <button
                        v-if="tariffOverviewAllowed && displayElement('show-tariff-overview')"
                        data-test="tariff-details-widget-tariff-overview-click"
                        class="btn btn-link"
                        @click="$emit('toggleWidget', 'showTariffOverview')"
                    >
                        {{ $t('widget.td.tariffOverview') }}
                    </button>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4">
                    <!--Address Details-->
                    <span v-if="displayElement('address-details')" class="mb-5">
                        <b>{{ $t('widget.td.address') }}</b>
                        <i
                            @click="$emit('toggleWidget2', 'changeBillingDataWidget')"
                            class="fa fa-pen"
                        ></i>
                        <br />
                        <span
                            v-if="contract.billingAddress.firstname && contract.billingAddress.surname"
                            >{{ `${contract.billingAddress.firstname}
                            ${contract.billingAddress.surname}` }}<br
                        /></span>
                        <span
                            v-if="contract.billingAddress.street && contract.billingAddress.houseNumber"
                            >{{ `${contract.billingAddress.street}
                            ${contract.billingAddress.houseNumber}` }}<br
                        /></span>
                        <span
                            v-if="contract.billingAddress.zip && contract.billingAddress.city"
                            >{{ `${contract.billingAddress.zip}
                            ${contract.billingAddress.city}` }}<br
                        /></span>
                        <br />
                    </span>
                    <!--Tariff & Banking Details-->
                    <div id="tariffDetails-bankingDetails">
                        <b>{{ $t('widget.td.account') }}</b>
                        <i
                            v-if="displayElement('address-details')"
                            @click="$emit('toggleWidget2', 'changePaymentDataWidget')"
                            class="fa fa-pen"
                        ></i>
                        <br />
                        <template v-if="contractBankDetails.iban">
                            <span
                                v-if="contractBankDetails.accountHolder && contractBankDetails.iban"
                                >{{ $t('widget.td.accountHolder') }}: {{
                                contractBankDetails.accountHolder }}<br
                            /></span>
                            <span v-if="contractBankDetails.iban"
                                >{{ $t('widget.td.iban') }}: {{
                                contractBankDetails.iban }}<br
                            /></span>
                            <span v-if="contractBankDetails.bic"
                                >{{ $t('widget.td.bic') }}: {{
                                contractBankDetails.bic }}<br
                            /></span>
                            <span v-if="contractBankDetails.directDebit"
                                >{{ $t('widget.td.sepaMandate') }}: {{
                                contractBankDetails.sepaMandate }}</span
                            >
                        </template>
                    </div>
                    <div v-if="displayElement('show-agb-links')" class="mt-3">
                        <b>{{ $t('widget.td.important.docs') }}</b>
                        <div class="agb-links">
                            <div class="mt-3" v-if="currentProduct.cmsAgb">
                                <a
                                    href="#"
                                    target="_blank"
                                    @click.prevent="downloadArchiveFile(currentProduct.cmsFileId)"
                                >
                                    <i class="fa fa-file-pdf"></i
                                    ><span class="ps-2"
                                        >AGB ({{
                                        getOnlyText(currentProduct.cmsAgb)
                                        }})</span
                                    >
                                </a>
                            </div>
                            <div class="mt-3">
                                <a
                                    :href="$t('widget.td.important.link2')"
                                    target="_blank"
                                >
                                    <i class="fa fa-file-pdf"></i
                                    ><span class="ps-2"
                                        >Information zur Verarbeitung
                                        personenbezogener Daten</span
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <div
                        class="text-center text--left"
                        :class="[
                        displayElement('tariff-details-offset') ? 'col-xs-4 col-sm-4' : 'col-xs-12 col-sm-12',
                        displayElement('mobile-contract-buttons') ? 'd-flex flex-column d-sm-flex flex-sm-column d-md-block' : ''
                    ]"
                    >
                        <div
                            class="text-center text--left"
                            :class="[displayElement('tariff-details-offset') ? 'offset-8 col-xs-4 col-sm-4' : 'col-xs-12 col-sm-12']"
                        >
                            <button
                                v-if="contractMoveAllowed && !displayElement('hide-contract-move')"
                                class="btn btn-primary"
                                :class="[displayElement('mobile-contract-buttons') ? 'mb-2 mt-2' : '']"
                                @click="$emit('toggleWidget', 'showMoveWidget')"
                            >
                                {{ $t('widget.td.move') }}
                            </button>
                            <button
                                v-if="contractCancellationAllowed && !displayElement('hide-cancellation-button')"
                                class="btn btn-primary"
                                :class="[
                                    displayElement('tariff-details-button-link') ? 'btn-cancellation--link' : '',
                                    displayElement('tariff-details-button-link-arrow') ? 'btn-cancellation--link-arrow' : '',
                                    displayElement('mobile-contract-buttons') ? 'mb-2 mt-2' : ''
                                ]"
                                data-test="button-open-cancellation-widget"
                                @click="$emit('toggleWidget', 'showCancellationWidget')"
                            >
                                {{ $t('widget.td.cancellation') }}
                                <span
                                    v-if="displayElement('tariff-details-button-link-arrow')"
                                    >&rarr;</span
                                >
                            </button>
                            <button
                                v-if="showRevocationWidget && !displayElement('hide-revoke-button')"
                                class="btn btn-primary"
                                @click="$emit('toggleWidget', 'revokeWidget')"
                                :class="[displayElement('mobile-contract-buttons') ? 'mb-2 mt-2' : '']"
                            >
                                {{ $t('widget.td.revocation') }}
                            </button>
                            <button
                                v-if="tariffChangeAllowed && tariffChangeAvailable && !displayElement( 'hide-tariff-change')"
                                class="btn btn-primary"
                                @click="$emit('toggleWidget', 'showTariffChange')"
                                :class="[displayElement('mobile-contract-buttons') ? 'mb-2 mt-2' : '']"
                            >
                                {{ $t('widget.td.tariffChange') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 mt-4"
                    v-if="contractCancellationAllowed && !displayElement('hide-contract-in-cancellation') && displayElement('show-cancellation-link')"
                >
                    <a
                        href="#"
                        @click.prevent="processCancellationLink"
                        class="cancellation-link"
                        data-test="button-open-cancellation-widget"
                    >
                        {{ $t('widget.td.cancellation.link') }}
                    </a>
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>
