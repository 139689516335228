import { WelcomeWidgetEts } from '@/components/widgets/welcome-widget/custom/ets';
import { WelcomeWidgetWdenergy } from '@/components/widgets/welcome-widget/custom/wdenergy';
import { WelcomeWidgetWdenergyUs } from '@/components/widgets/welcome-widget/custom/wdenergy-us';
import { WelcomeWidget } from '@/components/widgets/welcome-widget/welcome-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = WelcomeWidgetEts;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = WelcomeWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = WelcomeWidgetWdenergyUs;
} else {
    widget = WelcomeWidget;
}
export { widget as WelcomeWidget };
