<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-2">
                <i class="fa fa-comment-alt nextReading__icon"></i>
            </div>
            <div class="col-10">
                <div id="nextReading-content">
                    <span class="actionMiddleWidget">
                        <i
                            @click="$emit('toggleWidget', 'customerServiceFormWidget')"
                            class="fa fa-ellipsis-h"
                            id="button-submitMeterRead"
                        ></i>
                    </span>
                    <div class="nextReading__title static-title">
                        {{ $t('widget.cs.title') }}
                    </div>
                    <div class="nextReading__text">
                        {{ $t('widget.cs.subtitle') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
