import { default as Component } from 'vue-class-component';
// Call Render template.
import WithRender from './elli-thg-dealer.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
// Widgets
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget';
import { CancellationWidget } from '@/components/widgets/cancellation-widget';
import { EcarUploadWidget } from '@/components/widgets/ecar-upload-widget';
import { TariffChangeWidget } from '@/components/widgets/tariff-change-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { MarketingMaterial } from '@/components/widgets/marketing-material-widget/marketing-material';
import { DealerQuotasWidget } from '@/components/widgets/dealer-quotas-widget';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({
    components: {
        // Widgets
        ChangeCustomerDataWidget,
        ChangeBillingDataWidget,
        CancellationWidget,
        EcarUploadWidget,
        TariffChangeWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
        MarketingMaterial,
        DealerQuotasWidget,
    },
})
export class Dashboard extends DashboardParent {}
