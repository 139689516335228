import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './neighbor-comparison-widget.html';

@WithRender
@Component({})
export class AdvizzoNeighborComparisonWidget extends vue {
    public mounted(): void {
        // @ts-ignore
        document.getElementById('neighbor_comparison_widget').innerHTML =
            // @ts-ignore
            Placeholder.nc;

        const ncConfig = {
            showConsType: ['electricity', 'gas'],
            consType: {
                electricity: {
                    name: 'electricity',
                    label: 'Electricity',
                    language: {
                        smileyTitle: 'How you are doing',
                        legendTitle: 'Who are your similar homes?',
                    },
                    charts: {
                        you: {
                            label: 'You',
                            color: '#003865',
                        },
                        efficient: {
                            label: 'Efficient homes',
                            color: '#D3D56A',
                        },
                        average: {
                            label: 'Average homes',
                            color: '#EBC03F',
                        },
                    },
                    smileys: {
                        color: '#00263E',
                        items: [
                            {
                                id: 'great',
                                text: 'Great',
                                containsImg: true,
                            },
                            {
                                id: 'good',
                                text: 'Good',
                                containsImg: true,
                            },
                            {
                                id: 'average',
                                text: 'Using more than average',
                                containsImg: true,
                            },
                        ],
                    },
                    legend: {
                        items: [
                            {
                                title: 'Efficient homes',
                                description:
                                    'The most efficient 20 per cent of the similar homes in your area that have 1 occupants',
                                color: '#D3D56A',
                            },
                            {
                                title: 'Average homes',
                                description:
                                    'The average usage of similar homes in your area that have 1 occupants',
                                color: '#EBC03F;',
                            },
                        ],
                    },
                },
                gas: {
                    name: 'gas',
                    label: 'Gas',
                    language: {
                        smileyTitle: 'How you are doing',
                        legendTitle: 'acpbase:nc.legendTitle',
                    },
                    charts: {
                        you: {
                            label: 'You',
                            color: '#CB4335',
                        },
                        efficient: {
                            label: 'Efficient homes',
                            color: '#229954',
                        },
                        average: {
                            label: 'Average homes',
                            color: '#21618C',
                        },
                    },
                    smileys: {
                        color: '#CB4335',
                        items: [
                            {
                                id: 'great',
                                text: 'Great',
                                containsImg: true,
                            },
                            {
                                id: 'good',
                                text: 'Good',
                                containsImg: true,
                            },
                            {
                                id: 'average',
                                text: 'Using more than average',
                                containsImg: true,
                            },
                        ],
                    },
                    legend: {
                        items: [
                            {
                                title: 'Efficient homes',
                                description:
                                    'The most efficient 20 per cent of the similar homes in your area that have {{ occupancy }} occupants',
                                color: '#229954',
                            },
                            {
                                title: 'Average homes',
                                description:
                                    'The average usage of similar homes in your area that have {{ occupancy }} occupants',
                                color: '#21618C',
                            },
                        ],
                    },
                },
            },
        };

        const ncApiData = {
            electricity: {
                startNcDay: '2020-05-01',
                endNcDay: '2020-05-31',
                measurementUnit: 'kWh',
                comparisonPct:
                    'Congratulations! You used approximately 8% less electricity than efficient homes',
                custConsumption: 285,
                avgNeighbors: 355,
                effNeighbors: 310,
                effLevel: 4,
                occupancy: 1,
                conversionNote: null,
                alert: null,
            },
        };

        const ncData = {
            electricity: {
                startNcDay: '2020-05-01',
                endNcDay: '2020-05-31',
                measurementUnit: 'kWh',
                comparisonPct:
                    'Congratulations! You used approximately 8% less electricity than efficient homes',
                custConsumption: 285,
                avgNeighbors: 355,
                effNeighbors: 310,
                effLevel: 4,
                occupancy: 1,
                conversionNote: null,
                alert: null,
            },
        };

        // @ts-ignore
        const ncWidget = new NeighbourComparison(ncConfig);
        // @ts-ignore
        const dynamicNcWidget = new DynamicWidgetWrapper(
            ncWidget,
            '/api/proxy/neighbor-comparison',
            // @ts-ignore
            new HttpMock(ncApiData)
        );
        dynamicNcWidget.render('neighbor_comparison_widget');
    }
}
