import iocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import IEmailProvider from '@/interfaces/Mails';
import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './marketing-material.html';
import * as Sentry from '@sentry/browser';
import { MessagesBlock } from '@/components/snippets/messages';
import { Watch } from 'vue-property-decorator';

const mailsProvider = iocContainer.get<IEmailProvider>(SERVICES.MAIL);

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class MarketingMaterial extends Base {
    protected customEmail = this.$t('widget.mm.custom.email').toLocaleString();
    public showFixedEmail = false;
    protected errorInformation: Record<any, any> = [];
    protected successInformation: Record<any, any> = [];
    public to: string = '';
    public allowToContact: boolean = false;
    public customEmailFieldHasBeenUsed = false;
    public showEmailAction = false;
    @Watch('customEmail')
    public onCustomEmailChanged() {
        this.customEmailFieldHasBeenUsed = true;
    }

    get isProduction() {
        return process.env.VUE_APP_ENVIRONMENT_STAGE === 'prod';
    }

    get product(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).product;
    }

    get productCode(): string {
        return this.product.code ?? '';
    }

    get videoPrefix(): string {
        if (this.productCode.includes('VW')) {
            return 'vw';
        }
        if (this.productCode.includes('Audi')) {
            return 'audi';
        }
        return '';
    }

    get documentPrefix(): string {
        if (this.productCode.includes('VW')) {
            return 'vw';
        }
        if (this.productCode.includes('Audi')) {
            return 'audi';
        }
        if (this.productCode.includes('Skoda')) {
            return 'skoda';
        }
        if (this.productCode.includes('Cupra')) {
            return 'cupra';
        }
        return '';
    }
    get contractId() {
        return this.$store.state.contracts.contractId;
    }

    get jsTag() {
        const testJsTag =
            '<script src="https://sales-journey-dev.workdigital.de/elli-thg-' +
            this.nonCommodityProductBranding +
            '-test/wd-sales-journey.min.js"></script>';
        const prodJsTag =
            '<script src="https://thg-order-forms.elli.eco/elli-thg-' +
            this.nonCommodityProductBranding +
            '/wd-sales-journey.min.js"></script>';
        return this.isProduction ? prodJsTag : testJsTag;
    }

    get htmlTag() {
        return (
            '<wd-sales-journey campaign="' +
            this.nonCommodityProductCampaign +
            '" partner="' +
            this.contractId +
            '"></wd-sales-journey>'
        );
    }

    sendEmail() {
        this.errorInformation = [];
        this.successInformation = [];
        mailsProvider
            .sendPartnerCodeSnippetMail({
                contractId: this.$store.state.contracts.contractId,
                email: this.to ?? '',
                firstName: this.customerDetails.firstname,
                lastName: this.customerDetails.surname,
                message: this.customEmailFieldHasBeenUsed
                    ? this.customEmail
                    : null,
                company: this.contractsData.company ?? null,
                branding: this.nonCommodityProductBranding,
                campaign: this.nonCommodityProductCampaign,
            })
            .then((res) => {
                if (res.data.success) {
                    this.successInformation.push({
                        message: this.$t(
                            'widget.mm.email.success'
                        ).toLocaleString(),
                        isSuccess: false,
                    });
                } else {
                    this.errorInformation.push({
                        message: this.$t('general.error').toLocaleString(),
                        isSuccess: false,
                    });
                }
            })
            .catch((err) => {
                Sentry.captureException(new Error(err));
                if (err.response.status === 500) {
                    this.errorInformation.push({
                        message: this.$t('general.error').toLocaleString(),
                        isSuccess: false,
                    });
                }
                Object.keys(err.response.data.errors).forEach((key) => {
                    this.errorInformation.push({
                        message: err.response.data.errors[key][0],
                        isSuccess: false,
                    });
                });
            });
    }

    get isSubmitEnabled() {
        return this.isEmail(this.to) && this.allowToContact;
    }

    isEmail(email) {
        if (!email) return false;
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
}
