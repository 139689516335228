import WithRender from './../auth-page.html';
import { default as Component } from 'vue-class-component';
import { HeaderV2 } from '@/components/template/header-v2';
import { Header } from '@/components/template/header';
import { Footer } from './../../../template/footer';
import { ResetPasswordWidget } from '@/components/widgets/reset-password-widget';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { LoginPageAhrensburg as LoginPage } from '@/components/pages/auth/login-page/custom/ahrensburg';
import { RegisterPage } from '@/components/pages/auth/register';
import { AuthPage as AuthPageParent } from '@/components/pages/auth/auth-page';
import { RestoreTwoFactorAuthentication } from '@/components/pages/auth/2fa/restore';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-header': Header,
        'app-footer': Footer,
        LoginPage,
        RegisterPage,
        ResetPasswordWidget,
        'cookie-banner-widget': CookieBannerWidget,
        'restore-two-factor-authentication': RestoreTwoFactorAuthentication,
    },
})
export class AuthPageAhrensburg extends AuthPageParent {}
