import { CustomerServiceFormWidgetSachsenenergie } from '@/components/widgets/customer-service-form-widget/custom/sachsenenergie';
import { CustomerServiceFormWidgetSchweizstrom } from '@/components/widgets/customer-service-form-widget/custom/schweizstrom';
import { CustomerServiceFormWidget } from '@/components/widgets/customer-service-form-widget/customer-service-form-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'sachsenenergie') {
    widget = CustomerServiceFormWidgetSachsenenergie;
} else if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = CustomerServiceFormWidgetSchweizstrom;
} else {
    widget = CustomerServiceFormWidget;
}
export { widget as CustomerServiceFormWidget };
