// -----------------------------
//     Tariff Data structure
// -----------------------------
import Vue from 'vue';

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import * as Sentry from '@sentry/browser';

import Auth from '../../interfaces/Auth';
import Tariff from '../../interfaces/Tariff';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const tariffProvider = IocContainer.get<Tariff>(SERVICES.TARIFF);

interface IBankingDetails {
    accountHolder: string;
    iban: string;
    bic: string;
    bank: string;
    directDebit: any;
    sepaMandate: any;
    activeFrom: string;
}

// Data Structure
const state: {
    contractId: number;
    onLoad: boolean;
    product: {
        code: any;
        version: any;
        title: any;
        eco: any;
        description: any;
    };
    tariff: {
        title: any;
        usage: any;
        workingPrices: any;
        basePrice: any;
        yearlyCostForecast: any;
        instantBonus: any;
        instantBonusDate: any;
        activeFrom: any;
        activeUntil: any;
        fixedPriceDate: any;
        fixedPriceMonths: any;
        fixedPriceType: any;
        cancellationPeriod: any;
    };
    contract: {
        id: any;
        energy: any;
        startDate: any;
        desiredStartDate: any;
        endDate: any;
        desiredEndDate: any;
        nextCancellationDate: any;
        cancellationReason: any;
        cancellationTechKey: any;
        cancelledAt: any;
        cancelledBy: any;
        filingDateCancellation: any;
        customerSpecification: any;
        status: any;
        statusCode: any;
        usage: any;
        business: any;
        customerNumber: any;
        counterNumber: any;
        marketLocation: any;
        previousProvider: any;
        loaded: boolean;
        revokeAt: any;
        relocationPossible: any;
        fillingDateRevocation: any;
        deliveryAddress: {
            title: any;
            salutation: any;
            salutationDisplay: any;
            company: any;
            firstname: any;
            surname: any;
            street: any;
            houseNumber: any;
            city: any;
            zip: any;
            phone: any;
            email: any;
        };
        billingAddress: {
            loaded: boolean;
            title: any;
            salutation: any;
            salutationDisplay: any;
            company: any;
            firstname: any;
            surname: any;
            street: any;
            houseNumber: any;
            city: any;
            careOfRecipient: any;
            zip: any;
            phone: any;
            email: any;
            postbox: any;
        };
        bankingDetails: {
            loaded: boolean;
            in: IBankingDetails[];
            out: IBankingDetails[];
            inout: IBankingDetails[];
        };
        futureBankingDetails: [];
        communications: [];
        taxNumber: any;
        vatId: any;
        donationAllowed: any;
        donationApplied: any;
    };
    savedCo2Usage: number;
    changeList: [];
    recommended: [];
    recommendationUntil: string;
    oldList: [];
    isTariffDetailsLoading: boolean;
    tariffDetailsError: boolean;
    isTariffOverviewLoading: boolean;
    tariffOverviewError: boolean;
    communicationsErrorLoaded: boolean;
    contractDetailsError: boolean;
    contractDetailsLoading: boolean;
    nonCommodityProduct: any;
}[] = [];

// Outside methods for communication with data.
const actions = {
    async init({ commit }, contractId) {
        commit('INIT_TARIFF_STORE', contractId);
    },
    async overview({ commit }, data): Promise<void> {
        if (authProvider.isAuthenticated()) {
            commit('SET_TARIFF_OVERVIEW_LOADING', {
                data: true,
                contractId: data,
            });
            commit('SET_TARIFF_OVERVIEW_ERROR', {
                data: false,
                contractId: data,
            });
            await tariffProvider
                .overview(data)
                .then(
                    (response): void => {
                        if (response.data) {
                            commit('SET_TARIFF_OVERVIEW_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_TARIFF_OVERVIEW', {
                                data: response.data,
                                contractId: data,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_TARIFF_OVERVIEW_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    commit('SET_TARIFF_OVERVIEW_LOADING', {
                        data: false,
                        contractId: data,
                    });
                });
        }
    },
    async details({ commit, rootState }, data): Promise<void> {
        if (authProvider.isAuthenticated()) {
            commit('SET_CONTRACT_LOADED', { data: false, contractId: data });

            commit('SET_TARIFF_DETAILS_LOADING', {
                data: true,
                contractId: data,
            });
            commit('SET_CONTRACT_DETAILS_LOADING', {
                data: true,
                contractId: data,
            });

            commit('SET_TARIFF_DETAILS_ERROR', {
                data: false,
                contractId: data,
            });
            commit('SET_CONTRACT_DETAILS_ERROR', {
                data: false,
                contractId: data,
            });

            tariffProvider
                .details(data)
                .then(
                    (response): void => {
                        if (response.data) {
                            commit('SET_TARIFF_DETAILS_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_CONTRACT_DETAILS_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_TARIFF_DETAILS', {
                                data: { rootState, data: response.data },
                                contractId: data,
                            });
                            if (
                                rootState.settings.billingTaxNumberCategory ||
                                rootState.settings.billingTaxIdCategory
                            ) {
                                commit('SET_EXTENDED_BILLING_FIELDS', {
                                    data: { rootState, data: response.data },
                                    contractId: data,
                                });
                            }
                        }
                    },
                    (error) => {
                        commit('SET_TARIFF_DETAILS_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        commit('SET_CONTRACT_DETAILS_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        commit('SET_TARIFF_DETAILS_LOADING', {
                            data: false,
                            contractId: data,
                        });
                        commit('SET_CONTRACT_DETAILS_LOADING', {
                            data: false,
                            contractId: data,
                        });
                        commit('SET_CONTRACT_LOADED', {
                            data: true,
                            contractId: data,
                        });
                    }, 1000);
                });
            tariffProvider
                .getPaymentDetailsByContractId(data)
                .then((res) => {
                    commit('SET_PAYMENT_DETAILS', {
                        data: res.data,
                        contractId: data,
                    });
                })
                .catch((error) => Sentry.captureException(new Error(error)));
        }
    },
    async forChange({ commit }, data): Promise<void> {
        if (authProvider.isAuthenticated()) {
            await tariffProvider.tariffsForTariffChange(data).then(
                (response): void => {
                    if (response.data) {
                        commit('SET_TARIFF_FORCHANGE', {
                            data: response.data,
                            contractId: data,
                        });
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    async forRecommend({ commit }, data): Promise<void> {
        if (authProvider.isAuthenticated()) {
            await tariffProvider.tariffsForRecommendation(data).then(
                (response): void => {
                    if (response.data) {
                        commit('SET_TARIFF_RECOMMENDATIONS', {
                            data: response.data,
                            contractId: data,
                        });
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    async list({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await tariffProvider.list(data).then(
                (response) => {
                    if (response.data) {
                        commit('SET_TARIFF_LIST', {
                            data: response.data,
                            contractId: data,
                        });
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    async bswSavedCo2({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await tariffProvider.bswSavedCo2(data).then(
                (response) => {
                    if (response.data) {
                        commit('SET_BSW_SAVED_CO2', {
                            data: response.data,
                            contractId: data,
                        });
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },

    async communications({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await tariffProvider
                .communications(data.id, data.limit, data.offset)
                .then(
                    (response) => {
                        if (response.data) {
                            commit('SET_COMMUNICATIONS', {
                                data: response.data,
                                contractId: data.id,
                            });
                        }
                    },
                    (error) => {
                        Sentry.captureException(new Error(error));
                        commit('SET_COMMUNICATIONS_NOT_LOADED', true);
                    }
                );
        }
    },
    async setPaymentData({ commit }, data) {
        commit('SET_PAYMENT_DATA');
    },
    allowDonation({ commit }, data) {
        commit('SET_DONATION_ALLOWED', {
            data: data.value,
            contractId: data.id,
        });
    },
};

// Logic that change data
const mutations = {
    INIT_TARIFF_STORE(localState, contract) {
        const contractId = parseInt(contract, 10);
        const stateAlreadySet = localState.find(
            (tariffState) => tariffState.contractId === contractId
        );
        if (!stateAlreadySet) {
            localState.push({
                contractId,
                onLoad: true,
                product: {
                    code: null,
                    version: null,
                    title: null,
                    eco: null,
                    description: null,
                },
                tariff: {
                    title: null,
                    usage: null,
                    workingPrices: null,
                    basePrice: null,
                    yearlyCostForecast: null,
                    instantBonus: null,
                    instantBonusDate: null,
                    activeFrom: null,
                    activeUntil: null,
                    fixedPriceDate: null,
                    fixedPriceMonths: null,
                    fixedPriceType: null,
                    cancellationPeriod: null,
                },
                contract: {
                    id: null,
                    energy: null,
                    startDate: null,
                    desiredStartDate: null,
                    endDate: null,
                    desiredEndDate: null,
                    nextCancellationDate: null,
                    cancellationReason: null,
                    cancellationTechKey: null,
                    cancelledAt: null,
                    cancelledBy: null,
                    filingDateCancellation: null,
                    customerSpecification: null,
                    status: null,
                    statusCode: null,
                    usage: null,
                    business: null,
                    customerNumber: null,
                    counterNumber: null,
                    marketLocation: null,
                    previousProvider: null,
                    loaded: false,
                    revokeAt: null,
                    relocationPossible: null,
                    fillingDateRevocation: null,
                    deliveryAddress: {
                        title: null,
                        salutation: null,
                        salutationDisplay: null,
                        company: null,
                        firstname: null,
                        surname: null,
                        street: null,
                        houseNumber: null,
                        city: null,
                        zip: null,
                        phone: null,
                        email: null,
                    },
                    billingAddress: {
                        loaded: false,
                        title: null,
                        salutation: null,
                        salutationDisplay: null,
                        company: null,
                        firstname: null,
                        surname: null,
                        street: null,
                        houseNumber: null,
                        city: null,
                        careOfRecipient: null,
                        zip: null,
                        phone: null,
                        email: null,
                        postbox: null,
                    },
                    bankingDetails: {
                        loaded: false,
                        in: [],
                        out: [],
                        inout: [],
                    },
                    futureBankingDetails: [],
                    communications: [],
                    taxNumber: null,
                    vatId: null,
                    donationAllowed: false,
                    donationApplied: false,
                },
                savedCo2Usage: 0,
                changeList: [],
                recommended: [],
                oldList: [],
                isTariffDetailsLoading: true,
                tariffDetailsError: false,
                isTariffOverviewLoading: true,
                tariffOverviewError: false,
                communicationsErrorLoaded: false,
                contractDetailsError: false,
                contractDetailsLoading: true,
                nonCommodityProduct: {
                    contractCancellationPeriod: null,
                    contractCancellationPeriodType: null,
                    contractExtensionTime: null,
                    contractExtensionTimeType: null,
                    contractInitialDuration: null,
                    contractInitialDurationType: null,
                    contractInvoiceCycle: null,
                    contractInvoiceCycleInterval: null,
                    contractInvoiceCycleIntervalType: null,
                    description: null,
                    eco: false,
                    invoiceBase: null,
                    productCode: null,
                    productVersion: null,
                    title: null,
                },
            });
        }
    },
    SET_CONTRACT_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.loaded = data;
    },
    SET_CONTRACT_DETAILS_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).contractDetailsLoading = data;
    },
    SET_CONTRACT_DETAILS_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).contractDetailsError = data;
    },
    SET_TARIFF_OVERVIEW(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        if (data.product) {
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.code = data.product.productCode || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.version = data.product.version || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.title = data.product.title || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.eco = data.product.eco || false;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.description = data.product.description || null;

            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.cmsAgb = data.product.cmsAgb || null;

            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.cmsFileId = data.product.cmsFileId || null;

            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).contract.cancellationPeriodUnit =
                data.product.contractCancellationPeriodType || null;
        } else {
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.code = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.version = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.title = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.eco = false;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).product.description = null;
        }

        if (data.tariff) {
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.title = data.tariff.title || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.usage = data.tariff.usage || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.workingPrices = data.tariff.workingPrices || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.basePrice = data.tariff.basePrice || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.yearlyCostForecast =
                data.tariff.yearlyCostForecast || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.instantBonus = data.tariff.instantBonus || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.instantBonusDate = data.tariff.instantBonusDate || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.productBonus = data.tariff.productBonus || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.productBonusPercent =
                data.tariff.productBonusPercent || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.activeFrom = data.tariff.activeFrom || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.activeUntil = data.tariff.activeUntil || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.fixedPriceDate = data.tariff.fixedPriceDate || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.fixedPriceMonths = data.tariff.fixedPriceMonths || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.fixedPriceType = data.tariff.fixedPriceType || null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.cancellationPeriod =
                parseInt(data.tariff.cancellationPeriod, 10) || null;
        } else {
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.title = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.usage = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.workingPrices = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.basePrice = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.yearlyCostForecast = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.instantBonus = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.instantBonusDate = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.productBonus = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.productBonusPercent = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.activeFrom = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.activeUntil = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.fixedPriceDate = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.fixedPriceMonths = null;
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).tariff.fixedPriceType = null;
        }

        if (data.non_commodity) {
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ).nonCommodityProduct = data.product;
        }

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).onLoad = false;
    },
    SET_TARIFF_OVERVIEW_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).isTariffOverviewLoading = data;
    },
    SET_TARIFF_OVERVIEW_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).tariffOverviewError = data;
    },
    SET_TARIFF_DETAILS(localState, dataBag) {
        const data = dataBag.data.data;
        const rootState = dataBag.data.rootState;
        const contractId = dataBag.contractId;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.id =
            data.contract && data.contract.id ? data.contract.id : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.energy =
            data.contract && data.contract.energy ? data.contract.energy : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.startDate =
            data.contract && data.contract.startDate
                ? data.contract.startDate
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.desiredStartDate =
            data.contract && data.contract.desiredStartDate
                ? data.contract.desiredStartDate
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.endDate =
            data.contract && data.contract.endDate
                ? data.contract.endDate
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.desiredEndDate =
            data.contract && data.contract.desiredEndDate
                ? data.contract.desiredEndDate
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.nextCancellationDate =
            data.contract && data.contract.nextCancellationDate
                ? data.contract.nextCancellationDate
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.cancellationReason =
            data.contract && data.contract.cancellationReason
                ? data.contract.cancellationReason
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.cancellationTechKey =
            data.contract && data.contract.cancellationTechKey
                ? data.contract.cancellationTechKey
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.cancelledAt =
            data.contract && data.contract.cancelledAt
                ? data.contract.cancelledAt
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.cancelledBy =
            data.contract && data.contract.cancelledBy
                ? data.contract.cancelledBy
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.filingDateCancellation =
            data.contract && data.contract.filingDateCancellation
                ? data.contract.filingDateCancellation
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.customerSpecification =
            data.contract && data.contract.customerSpecification
                ? data.contract.customerSpecification
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.status =
            data.contract && data.contract.status ? data.contract.status : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.statusCode =
            data.contract && data.contract.statusCode
                ? data.contract.statusCode
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.usage =
            data.contract && data.contract.usage ? data.contract.usage : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.business =
            data.contract && data.contract.business
                ? data.contract.business
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.customerNumber =
            data.contract && data.contract.customerNumber
                ? data.contract.customerNumber
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.counterNumber =
            data.contract && data.contract.counterNumber
                ? data.contract.counterNumber
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.marketLocation =
            data.contract && data.contract.marketLocation
                ? data.contract.marketLocation
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.previousProvider =
            data.contract && data.contract.previousProvider
                ? data.contract.previousProvider
                : null;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.minimumRuntime =
            data.contract && data.contract.minimumRuntime
                ? data.contract.minimumRuntime
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.minimumRuntimeUnit =
            data.contract && data.contract.minimumRuntimeUnit
                ? data.contract.minimumRuntimeUnit
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.extensionTime =
            data.contract && data.contract.extensionTime
                ? data.contract.extensionTime
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.extensionTimeUnit =
            data.contract && data.contract.extensionTimeUnit
                ? data.contract.extensionTimeUnit
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.relocationPossible =
            data.contract && data.contract.relocationPossible
                ? data.contract.relocationPossible
                : false;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.relocationContractId =
            data.contract && data.contract.relocationContractId
                ? data.contract.relocationContractId
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.relocationAt =
            data.contract && data.contract.relocationAt
                ? data.contract.relocationAt
                : null;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.title =
            data.contract && data.contract.deliveryAddress.title
                ? data.contract.deliveryAddress.title
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.salutation =
            data.contract && data.contract.deliveryAddress.salutation
                ? data.contract.deliveryAddress.salutation
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.salutationDisplay =
            data.contract && data.contract.deliveryAddress.salutationDisplay
                ? data.contract.deliveryAddress.salutationDisplay
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.company =
            data.contract && data.contract.deliveryAddress.company
                ? data.contract.deliveryAddress.company
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.firstname =
            data.contract && data.contract.deliveryAddress.firstname
                ? data.contract.deliveryAddress.firstname
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.surname =
            data.contract && data.contract.deliveryAddress.surname
                ? data.contract.deliveryAddress.surname
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.street =
            data.contract && data.contract.deliveryAddress.street
                ? data.contract.deliveryAddress.street
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.houseNumber =
            data.contract && data.contract.deliveryAddress.houseNumber
                ? data.contract.deliveryAddress.houseNumber
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.city =
            data.contract && data.contract.deliveryAddress.city
                ? data.contract.deliveryAddress.city
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.zip =
            data.contract && data.contract.deliveryAddress.zip
                ? data.contract.deliveryAddress.zip
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.phone =
            data.contract && data.contract.deliveryAddress.phone
                ? data.contract.deliveryAddress.phone
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.deliveryAddress.email =
            data.contract && data.contract.deliveryAddress.email
                ? data.contract.deliveryAddress.email
                : null;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.title =
            data.contract && data.contract.billingAddress.title
                ? data.contract.billingAddress.title
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.salutation =
            data.contract && data.contract.billingAddress.salutation
                ? data.contract.billingAddress.salutation
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.salutationDisplay =
            data.contract && data.contract.billingAddress.salutationDisplay
                ? data.contract.billingAddress.salutationDisplay
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.company =
            data.contract && data.contract.billingAddress.company
                ? data.contract.billingAddress.company
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.firstname =
            data.contract && data.contract.billingAddress.firstname
                ? data.contract.billingAddress.firstname
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.surname =
            data.contract && data.contract.billingAddress.surname
                ? data.contract.billingAddress.surname
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.street =
            data.contract && data.contract.billingAddress.street
                ? data.contract.billingAddress.street
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.houseNumber =
            data.contract && data.contract.billingAddress.houseNumber
                ? data.contract.billingAddress.houseNumber
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.city =
            data.contract && data.contract.billingAddress.city
                ? data.contract.billingAddress.city
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.zip =
            data.contract && data.contract.billingAddress.zip
                ? data.contract.billingAddress.zip
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.careOfRecipient =
            data.contract && data.contract.billingAddress.careOfRecipient
                ? data.contract.billingAddress.careOfRecipient
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.phone =
            data.contract && data.contract.billingAddress.phone
                ? data.contract.billingAddress.phone
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.email =
            data.contract && data.contract.billingAddress.email
                ? data.contract.billingAddress.email
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.postbox =
            data.contract && data.contract.billingAddress.postbox
                ? data.contract.billingAddress.postbox
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.loaded = true;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.revokeAt =
            data.contract && data.contract.revokeAt
                ? data.contract.revokeAt
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.fillingDateRevocation =
            data.contract && data.contract.fillingDateRevocation
                ? data.contract.fillingDateRevocation
                : null;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.futureBankingDetails =
            data.contract && data.contract.futureBankingDetails
                ? data.contract.futureBankingDetails
                : [];

        if (data.contract.extendedData) {
            if (data.contract.extendedData.values) {
                if (
                    data.contract.extendedData.values[
                        rootState.settings.donationExtendedDataParentKey
                    ]
                ) {
                    data.contract.extendedData.values[
                        rootState.settings.donationExtendedDataParentKey
                    ].forEach((item) => {
                        if (
                            item.technicalKey ===
                            rootState.settings.donationExtendedDataValueKey
                        ) {
                            localState.find(
                                (tariffState) =>
                                    tariffState.contractId === contractId
                            ).contract.donationApplied = item.value === '1';
                        }
                    });
                }
            }
        }
    },
    SET_DONATION_ALLOWED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.donationAllowed = data;
    },
    SET_EXTENDED_BILLING_FIELDS(localState, dataBag) {
        const data = dataBag.data.data;
        const rootState = dataBag.data.rootState;
        const contractId = dataBag.contractId;

        if (data.contract.extendedData && data.contract.extendedData.values) {
            if (
                data.contract.extendedData.values[
                    rootState.settings.billingTaxNumberCategory
                ]
            ) {
                data.contract.extendedData.values[
                    rootState.settings.billingTaxNumberCategory
                ].forEach((item) => {
                    if (
                        item.technicalKey ===
                        rootState.settings.billingTaxNumberField
                    ) {
                        localState.find(
                            (tariffState) =>
                                tariffState.contractId === contractId
                        ).contract.taxNumber = item.value;
                    }
                });
            }
            if (
                data.contract.extendedData.values[
                    rootState.settings.billingTaxIdCategory
                ]
            ) {
                data.contract.extendedData.values[
                    rootState.settings.billingTaxIdCategory
                ].forEach((item) => {
                    if (
                        item.technicalKey ===
                        rootState.settings.billingTaxIdField
                    ) {
                        localState.find(
                            (tariffState) =>
                                tariffState.contractId === contractId
                        ).contract.vatId = item.value;
                    }
                });
            }
        }
    },
    SET_TARIFF_DETAILS_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).isTariffDetailsLoading = data;
    },
    SET_TARIFF_DETAILS_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).tariffDetailsError = data;
    },
    SET_TARIFF_FORCHANGE(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        if (!contractId) return;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).changeList = [];
        if (data && Array.isArray(data)) {
            data.forEach((element) => {
                localState
                    .find(
                        (tariffState) => tariffState.contractId === contractId
                    )
                    .changeList.push({
                        title: element.title ? element.title : null,
                        productCode: element.productCode
                            ? element.productCode
                            : null,
                        productVersion: element.productVersion
                            ? element.productVersion
                            : null,
                        eco: element.eco ? element.eco : null,
                        invoiceBase: element.invoiceBase
                            ? element.invoiceBase
                            : null,
                        contractInvoiceCycle: element.contractInvoiceCycle
                            ? element.contractInvoiceCycle
                            : null,
                        contractInvoiceCycleInterval:
                            element.contractInvoiceCycleInterval
                                ? element.contractInvoiceCycleInterval
                                : null,
                        contractInvoiceCycleIntervalType:
                            element.contractInvoiceCycleIntervalType
                                ? element.contractInvoiceCycleIntervalType
                                : null,
                        contractInitialDuration:
                            element.contractInitialDuration ??
                            element.moduleInformation.initialDuration ??
                            null,
                        contractInitialDurationType:
                            element.contractInitialDurationType ??
                            element.moduleInformation.initialDurationType ??
                            null,
                        contractExtensionTime: element.contractExtensionTime
                            ? element.contractExtensionTime
                            : null,
                        contractExtensionTimeType:
                            element.contractExtensionTimeType
                                ? element.contractExtensionTimeType
                                : null,
                        contractCancellationPeriod:
                            element.contractCancellationPeriod
                                ? element.contractCancellationPeriod
                                : null,
                        contractCancellationPeriodType:
                            element.contractCancellationPeriodType
                                ? element.contractCancellationPeriodType
                                : null,
                        description: element.description
                            ? element.description
                            : null,
                        usage: element.usage ? element.usage : null,
                        agb: element.moduleInformation.agb
                            ? element.moduleInformation.agb
                            : null,
                        deposit: element.moduleInformation.deposit.brutto
                            ? element.moduleInformation.deposit.brutto
                            : null,
                        addons: element.addons ? element.addons : [],

                        basePriceBrutto: element.moduleInformation
                            .basePriceBrutto
                            ? element.moduleInformation.basePriceBrutto
                            : null,
                        basePriceBruttoMonth: element.moduleInformation
                            .basePriceBruttoMonth
                            ? element.moduleInformation.basePriceBruttoMonth
                            : null,
                        workingPrice: element.moduleInformation.workingPrice
                            ? element.moduleInformation.workingPrice
                            : null,
                        workingPriceBrutto: element.moduleInformation
                            .workingPriceBrutto
                            ? element.moduleInformation.workingPriceBrutto
                            : null,
                        overConsumptionWorkingPriceBrutto: element
                            .moduleInformation.overConsumptionWorkingPriceBrutto
                            ? element.moduleInformation
                                  .overConsumptionWorkingPriceBrutto
                            : null,
                        bonusSavings: element.moduleInformation.bonusSavings
                            ? element.moduleInformation.bonusSavings
                            : null,
                        totalBasePriceBonusBrutto: element.moduleInformation
                            .totalBasePriceBonusBrutto
                            ? element.moduleInformation
                                  .totalBasePriceBonusBrutto
                            : null,
                        totalBasePriceBonusBruttoMonth: element
                            .moduleInformation.totalBasePriceBonusBruttoMonth
                            ? element.moduleInformation
                                  .totalBasePriceBonusBruttoMonth
                            : null,
                        usagePriceBrutto: element.moduleInformation
                            .usagePriceBrutto
                            ? element.moduleInformation.usagePriceBrutto
                            : null,
                        totalBrutto: element.moduleInformation.totalBrutto
                            ? element.moduleInformation.totalBrutto
                            : null,
                        totalSubBonusBrutto: element.moduleInformation
                            .totalSubBonusBrutto
                            ? element.moduleInformation.totalSubBonusBrutto
                            : null,
                        initialRuntime: element.moduleInformation
                            .initialDuration
                            ? element.moduleInformation.initialDuration
                            : null,
                        initialRuntimeType: element.moduleInformation
                            .initialDurationType
                            ? element.moduleInformation.initialDurationType
                            : null,
                        extensionTime: element.moduleInformation.renewalDuration
                            ? element.moduleInformation.renewalDuration
                            : null,
                        extensionTimeType: element.moduleInformation
                            .renewalDurationType
                            ? element.moduleInformation.renewalDurationType
                            : null,
                        cancellationPeriod: element.contractCancellationPeriod
                            ? element.contractCancellationPeriod
                            : null,
                        cancellationPeriodType:
                            element.contractCancellationPeriodType
                                ? element.contractCancellationPeriodType
                                : null,
                        instantBonus: element.moduleInformation.instantBonus
                            ? element.moduleInformation.instantBonus
                            : null,
                        productBonus: element.moduleInformation.productBonus
                            ? element.moduleInformation.productBonus
                            : null,
                        productBonusPercent: element.moduleInformation
                            .productBonusPercent
                            ? element.moduleInformation.productBonusPercent
                            : null,
                        fixedPriceDate: element.moduleInformation.fixedPriceDate
                            ? element.moduleInformation.fixedPriceDate
                            : null,
                        fixedPriceMonths: element.moduleInformation
                            .fixedPriceMonths
                            ? element.moduleInformation.fixedPriceMonths
                            : null,
                    });
            });
        }
    },
    SET_TARIFF_RECOMMENDATIONS(localState, dataBag) {
        const data = dataBag.data.list;
        const contractId = dataBag.contractId;
        if (!contractId) return;
        Vue.set(
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ),
            'recommended',
            []
        );
        Vue.set(
            localState.find(
                (tariffState) => tariffState.contractId === contractId
            ),
            'recommendationUntil',
            dataBag.data.availableUntil
        );
        if (data && Array.isArray(data)) {
            data.forEach((element) => {
                localState
                    .find(
                        (tariffState) => tariffState.contractId === contractId
                    )
                    .recommended.push({
                        title: element.title ? element.title : null,
                        productCode: element.productCode
                            ? element.productCode
                            : null,
                        productVersion: element.productVersion
                            ? element.productVersion
                            : null,
                        eco: element.eco ? element.eco : null,
                        invoiceBase: element.invoiceBase
                            ? element.invoiceBase
                            : null,
                        contractInvoiceCycle: element.contractInvoiceCycle
                            ? element.contractInvoiceCycle
                            : null,
                        contractInvoiceCycleInterval:
                            element.contractInvoiceCycleInterval
                                ? element.contractInvoiceCycleInterval
                                : null,
                        contractInvoiceCycleIntervalType:
                            element.contractInvoiceCycleIntervalType
                                ? element.contractInvoiceCycleIntervalType
                                : null,
                        contractInitialDuration:
                            element.contractInitialDuration ??
                            element.moduleInformation.initialDuration ??
                            null,
                        contractInitialDurationType:
                            element.contractInitialDurationType ??
                            element.moduleInformation.initialDurationType ??
                            null,
                        contractExtensionTime: element.contractExtensionTime
                            ? element.contractExtensionTime
                            : null,
                        contractExtensionTimeType:
                            element.contractExtensionTimeType
                                ? element.contractExtensionTimeType
                                : null,
                        contractCancellationPeriod:
                            element.contractCancellationPeriod
                                ? element.contractCancellationPeriod
                                : null,
                        contractCancellationPeriodType:
                            element.contractCancellationPeriodType
                                ? element.contractCancellationPeriodType
                                : null,
                        description: element.description
                            ? element.description
                            : null,
                        usage: element.usage ? element.usage : null,
                        agb: element.moduleInformation.agb
                            ? element.moduleInformation.agb
                            : null,
                        deposit: element.moduleInformation.deposit.brutto
                            ? element.moduleInformation.deposit.brutto
                            : null,
                        addons: element.addons ? element.addons : [],

                        basePriceBrutto: element.moduleInformation
                            .basePriceBrutto
                            ? element.moduleInformation.basePriceBrutto
                            : null,
                        basePriceBruttoMonth: element.moduleInformation
                            .basePriceBruttoMonth
                            ? element.moduleInformation.basePriceBruttoMonth
                            : null,
                        workingPrice: element.moduleInformation.workingPrice
                            ? element.moduleInformation.workingPrice
                            : null,
                        workingPriceBrutto: element.moduleInformation
                            .workingPriceBrutto
                            ? element.moduleInformation.workingPriceBrutto
                            : null,
                        overConsumptionWorkingPriceBrutto: element
                            .moduleInformation.overConsumptionWorkingPriceBrutto
                            ? element.moduleInformation
                                  .overConsumptionWorkingPriceBrutto
                            : null,
                        bonusSavings: element.moduleInformation.bonusSavings
                            ? element.moduleInformation.bonusSavings
                            : null,
                        totalBasePriceBonusBrutto: element.moduleInformation
                            .totalBasePriceBonusBrutto
                            ? element.moduleInformation
                                  .totalBasePriceBonusBrutto
                            : null,
                        totalBasePriceBonusBruttoMonth: element
                            .moduleInformation.totalBasePriceBonusBruttoMonth
                            ? element.moduleInformation
                                  .totalBasePriceBonusBruttoMonth
                            : null,
                        usagePriceBrutto: element.moduleInformation
                            .usagePriceBrutto
                            ? element.moduleInformation.usagePriceBrutto
                            : null,
                        totalBrutto: element.moduleInformation.totalBrutto
                            ? element.moduleInformation.totalBrutto
                            : null,
                        totalSubBonusBrutto: element.moduleInformation
                            .totalSubBonusBrutto
                            ? element.moduleInformation.totalSubBonusBrutto
                            : null,
                        initialRuntime: element.moduleInformation
                            .initialDuration
                            ? element.moduleInformation.initialDuration
                            : null,
                        initialRuntimeType: element.moduleInformation
                            .initialDurationType
                            ? element.moduleInformation.initialDurationType
                            : null,
                        extensionTime: element.moduleInformation.renewalDuration
                            ? element.moduleInformation.renewalDuration
                            : null,
                        extensionTimeType: element.moduleInformation
                            .renewalDurationType
                            ? element.moduleInformation.renewalDurationType
                            : null,
                        cancellationPeriod: element.contractCancellationPeriod
                            ? element.contractCancellationPeriod
                            : null,
                        cancellationPeriodType:
                            element.contractCancellationPeriodType
                                ? element.contractCancellationPeriodType
                                : null,
                        instantBonus: element.moduleInformation.instantBonus
                            ? element.moduleInformation.instantBonus
                            : null,
                        productBonus: element.moduleInformation.productBonus
                            ? element.moduleInformation.productBonus
                            : null,
                        productBonusPercent: element.moduleInformation
                            .productBonusPercent
                            ? element.moduleInformation.productBonusPercent
                            : null,
                        fixedPriceDate: element.moduleInformation.fixedPriceDate
                            ? element.moduleInformation.fixedPriceDate
                            : null,
                        fixedPriceMonths: element.moduleInformation
                            .fixedPriceMonths
                            ? element.moduleInformation.fixedPriceMonths
                            : null,
                    });
            });
        }
    },
    SET_TARIFF_LIST(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        if (!contractId) return;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).oldList = [];
        if (data && data.tariffs && Array.isArray(data.tariffs)) {
            data.tariffs.forEach((element) => {
                localState
                    .find(
                        (tariffState) => tariffState.contractId === contractId
                    )
                    .oldList.push({
                        title: element.title ? element.title : null,
                        activeFrom: element.activeFrom
                            ? element.activeFrom
                            : null,
                        activeUntil: element.activeUntil
                            ? element.activeUntil
                            : null,
                        basePrice: element.basePrice ? element.basePrice : null,
                        billingPeriod: element.billingPeriod
                            ? element.billingPeriod
                            : null,
                        cancellationPeriod: element.cancellationPeriod
                            ? element.cancellationPeriod
                            : null,
                        depositDivisor: element.depositDivisor
                            ? element.depositDivisor
                            : null,
                        fixedPriceDate: element.fixedPriceDate
                            ? element.fixedPriceDate
                            : null,
                        fixedPriceMonths: element.fixedPriceMonths
                            ? element.fixedPriceMonths
                            : null,
                        fixedPriceType: element.fixedPriceType
                            ? element.fixedPriceType
                            : null,
                        instantBonus: element.instantBonus
                            ? element.instantBonus
                            : null,
                        instantBonusDate: element.instantBonusDate
                            ? element.instantBonusDate
                            : null,
                        contractCancellationPeriod:
                            element.product &&
                            element.product.contractCancellationPeriod
                                ? element.product.contractCancellationPeriod
                                : null,
                        contractCancellationPeriodType:
                            element.product &&
                            element.product.contractCancellationPeriodType
                                ? element.product.contractCancellationPeriodType
                                : null,
                        contractExtensionTime:
                            element.product &&
                            element.product.contractExtensionTime
                                ? element.product.contractExtensionTime
                                : null,
                        contractExtensionTimeType:
                            element.product &&
                            element.product.contractExtensionTimeType
                                ? element.product.contractExtensionTimeType
                                : null,
                        contractInitialDuration:
                            element.product &&
                            element.product.contractInitialDuration
                                ? element.product.contractInitialDuration
                                : null,
                        contractInitialDurationType:
                            element.product &&
                            element.product.contractInitialDurationType
                                ? element.product.contractInitialDurationType
                                : null,
                        contractInvoiceCycle:
                            element.product &&
                            element.product.contractInvoiceCycle
                                ? element.product.contractInvoiceCycle
                                : null,
                        contractInvoiceCycleInterval:
                            element.product &&
                            element.product.contractInvoiceCycleInterval
                                ? element.product.contractInvoiceCycleInterval
                                : null,
                        contractInvoiceCycleIntervalType:
                            element.product &&
                            element.product.contractInvoiceCycleIntervalType
                                ? element.product
                                      .contractInvoiceCycleIntervalType
                                : null,
                        description:
                            element.product && element.product.description
                                ? element.product.description
                                : null,
                        eco:
                            element.product && element.product.eco
                                ? element.product.eco
                                : null,
                        invoiceBase:
                            element.product && element.product.invoiceBase
                                ? element.product.invoiceBase
                                : null,
                        productCode:
                            element.product && element.product.productCode
                                ? element.product.productCode
                                : null,
                        productVersion:
                            element.product && element.product.productVersion
                                ? element.product.productVersion
                                : null,
                        productBonus: element.productBonus
                            ? element.productBonus
                            : null,
                        productBonusPercent: element.productBonusPercent
                            ? element.productBonusPercent
                            : null,
                        usage: element.usage ? element.usage : null,
                        workingPrices: element.workingPrices
                            ? element.workingPrices
                            : null,
                        yearlyCostForecast: element.yearlyCostForecast
                            ? element.yearlyCostForecast
                            : null,
                        priceAdjustmentOf: element.priceAdjustmentOf
                            ? element.priceAdjustmentOf
                            : null,
                        minimumRuntime: element.minimumRuntime
                            ? element.minimumRuntime
                            : null,
                        extensionTime: element.extensionTime
                            ? element.extensionTime
                            : null,
                    });
            });
        }
    },
    SET_BILLING_ADDRESS(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.salutation = data.billingSalutation;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.company = data.billingCompany;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.firstname = data.billingFirstName;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.surname = data.billingSurname;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.street = data.billingStreet;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.houseNumber = data.billingHouseNumber;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.city = data.billingCity;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.zip = data.billingZip;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.phone = data.billingPhoneMobile;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.email = data.billingEmail;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.billingAddress.careOfRecipient = data.careOfRecipient;
    },
    SET_PAYMENT_DETAILS(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.bankingDetails.inout = data.inout;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.bankingDetails.in = data.in;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.bankingDetails.out = data.out;

        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).contract.bankingDetails.loaded = true;
    },

    SET_COMMUNICATIONS(localState, data) {
        if (Array.isArray(data.data)) {
            data.data.forEach((element) => {
                if (
                    localState
                        .find(
                            (tariffState) =>
                                tariffState.contractId === data.contractId
                        )
                        .contract.communications.filter(
                            (e) => e.communicationId === element.id
                        ).length <= 0
                ) {
                    let cssClass =
                        'm-timeline-1__item--' + element.data.timelinePosition;
                    if (element.data.timelineFirst) {
                        cssClass += ' m-timeline-1__item--first';
                    }
                    if (element.data.timelineLast) {
                        cssClass += ' m-timeline-1__item--last';
                    }

                    localState
                        .find(
                            (tariffState) =>
                                tariffState.contractId === data.contractId
                        )
                        .contract.communications.push({
                            communicationId: element.id ? element.id : null,
                            createdAt: element.createdAt
                                ? element.createdAt
                                : null,
                            title: element.title ? element.title : null,
                            archiveId: element.archiveId
                                ? element.archiveId
                                : null,
                            sent: element.sent ? element.sent : 0,
                            sentAt: element.sentAt ? element.sentAt : null,
                            timelinePosition: element.data.timelinePosition
                                ? element.data.timelinePosition
                                : null,
                            timelineFirst: element.data.timelineFirst
                                ? element.data.timelineFirst
                                : false,
                            timelineLast: element.data.timelineLast
                                ? element.data.timelineLast
                                : false,
                            timelineClass: cssClass,
                        });
                }
            });
        }
    },
    SET_BSW_SAVED_CO2(localState, dataBag): void {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (tariffState) => tariffState.contractId === contractId
        ).savedCo2Usage = data.savedCo2Usage ? data.savedCo2Usage : 0;
    },
    SET_COMMUNICATIONS_NOT_LOADED(localState, value) {
        localState.communicationsErrorLoaded = value;
    },
    SET_PAYMENT_DATA(
        localState,
        data: {
            contractId: string;
            key: 'inout' | 'in' | 'out';
            paymentData: any;
        }
    ) {
        localState.find(
            (tariffState) => tariffState.contractId === data.contractId
        ).contract.bankingDetails[data.key] = { ...data.paymentData };
    },
};

// Get Data transformation (like computed)
const getters = {
    getState: (localState) => (contractId) => {
        return localState.find(
            (tariffState) => tariffState.contractId === contractId
        );
    },
    getActiveBankDetails: (localState) => (contractId) => {
        const inout = localState.find(
            (tariffState) => tariffState.contractId == contractId
        ).contract.bankingDetails.inout;

        const inBound = localState.find(
            (tariffState) => tariffState.contractId == contractId
        ).contract.bankingDetails.in;

        const outBound = localState.find(
            (tariffState) => tariffState.contractId == contractId
        ).contract.bankingDetails.out;

        return {
            in: inBound
                .filter((details) => {
                    const activeFrom = new Date(details.activeFrom);
                    activeFrom.setHours(0, 0, 0, 0);
                    const activeUntil = new Date(details.activeUntil);
                    activeUntil.setHours(0, 0, 0, 0);
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    if (
                        (activeFrom <= currentDate &&
                            activeUntil >= currentDate) ||
                        (activeFrom <= currentDate &&
                            details.activeUntil == null)
                    ) {
                        return true;
                    }
                    return;
                })
                .map((details) => ({
                    ...details,
                    directDebit:
                        typeof details.directDebit === 'number' ||
                        typeof details.directDebit === 'string'
                            ? parseInt(details.directDebit) === 1
                            : details.directDebit,
                })),
            out: outBound
                .filter((details) => {
                    const activeFrom = new Date(details.activeFrom);
                    activeFrom.setHours(0, 0, 0, 0);
                    const activeUntil = new Date(details.activeUntil);
                    activeUntil.setHours(0, 0, 0, 0);
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    if (
                        (activeFrom <= currentDate &&
                            activeUntil >= currentDate) ||
                        (activeFrom <= currentDate &&
                            details.activeUntil == null)
                    ) {
                        return true;
                    }
                    return;
                })
                .map((details) => ({
                    ...details,
                    directDebit:
                        typeof details.directDebit === 'number' ||
                        typeof details.directDebit === 'string'
                            ? parseInt(details.directDebit) === 1
                            : details.directDebit,
                })),
            inout: inout
                .filter((details) => {
                    const activeFrom = new Date(details.activeFrom);
                    activeFrom.setHours(0, 0, 0, 0);
                    const activeUntil = new Date(details.activeUntil);
                    activeUntil.setHours(0, 0, 0, 0);
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    if (
                        (activeFrom <= currentDate &&
                            activeUntil >= currentDate) ||
                        (activeFrom <= currentDate &&
                            details.activeUntil == null)
                    ) {
                        return true;
                    }
                    return;
                })
                .map((details) => ({
                    ...details,
                    directDebit:
                        typeof details.directDebit === 'number' ||
                        typeof details.directDebit === 'string'
                            ? parseInt(details.directDebit) === 1
                            : details.directDebit,
                })),
        };
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
