<div class="m-content">
    <div class="h4 font-weight-light">
        <span v-if="isMorning">{{ $t('widget.welcome.goodMorning') }}</span>
        <span v-else-if="isMidDay">{{ $t('widget.welcome.hello') }}</span>
        <span v-else-if="isEvening"
            >{{ $t('widget.welcome.goodEvening') }}</span
        >
        {{ customerDetails.salutation }} {{ customerDetails.firstname }} {{
        customerDetails.surname }}
    </div>
</div>
