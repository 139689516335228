import { default as Component } from 'vue-class-component';
import WithRender from './username-reset-page.html';

import { Username } from './username';
import { ResetUsernameWidget } from '@/components/widgets/reset-username-widget';

@WithRender
@Component({
    components: {
        ResetUsernameWidget,
    },
})
export class UsernameResetPage extends Username {}
