import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './form-wrapper.html';
import { Prop, Provide } from 'vue-property-decorator';

@WithRender
@Component({
    components: {},
})
export class FormWrapper extends Base {
    @Prop() public form!: string;
    @Provide('form') private providedForm = this.form;
}
