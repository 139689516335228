import Base from '@/mixins/base';

interface Elements {
    bsw?: string[];
    ets?: string[];
    waermeenergie?: string[];
    wdenergy?: string[];
}

export class CoreComponent extends Base {
    protected elements: Elements = {};

    get locales(): string[] {
        return this.$i18n.availableLocales;
    }

    get loginAsideStyle(): string | false {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            // No Image, but Centered
            case 'qcells':
                return false;
            default:
                // Image, not Centered
                return (
                    'background-image: url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.jpg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.png), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.gif), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.svg); background-size: cover;'
                );
        }
    }

    get loginLogo(): string {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.svg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.png);'
        );
    }
}
