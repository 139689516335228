<!--<template>-->
<!-- REGISTRATION 1 AREA -->
<div v-if="registrationStep1" class="tight">
    <h6 class="registration-step1-message m-login__title">
        {{ $t('login.explanation') }}
    </h6>

    <div
        class="registration-step1-warning m-login__title"
        v-if="displayElement('registration-step1-warning')"
    >
        <b>{{ $t('login.step1.warning.title') }}</b>
        <br />
        {{ $t('login.step1.warning.text') }}
        <br />
        <a :href="$t('login.step1.warning.cta')"
            >{{ $t('login.step1.warning.cta.text') }}</a
        >
    </div>

    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div
            class="form-group m-form__group"
            v-if="!displayElement('hide-register-contractId-input')"
        >
            <template v-if="displayElement('input-with-label')">
                <label for="contractId">
                    <strong>{{ $t('login.contractNumber') }}</strong>
                </label>
                <input
                    class="form-control m-input"
                    type="number"
                    id="contractId"
                    :placeholder="$t('login.contractNumber')"
                    v-model="details.contractId"
                    data-test="registration-page-contract-id-input"
                />
                <div
                    v-if="displayElement('show-between')"
                    class="register-between-input"
                >
                    {{ $t('between.login.and.password') }}
                </div>
            </template>
            <template v-else>
                <input
                    data-test="registration-page-contract-id-input"
                    class="form-control m-input"
                    type="number"
                    :placeholder="$t('login.contractNumber')"
                    v-model="details.contractId"
                />
                <div
                    v-if="displayElement('show-between')"
                    class="register-between-input"
                >
                    {{ $t('between.login.and.password') }}
                </div>
            </template>
        </div>
        <div
            class="form-group m-form__group"
            v-if="!displayElement('hide-register-customerId-input')"
        >
            <label for="customerId" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('login.customerNumber') }}</strong>
            </label>
            <input
                class="form-control m-input"
                type="number"
                id="customerId"
                :placeholder="$t('login.customerNumber')"
                v-model="details.customerId"
                data-test="registration-page-customer-id-input"
            />
        </div>
        <div
            class="form-group m-form__group"
            v-if="displayElement('email-field')"
        >
            <label for="email" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('login.email') }}</strong>
            </label>
            <input
                class="form-control m-input"
                type="text"
                id="email"
                :placeholder="$t('login.email')"
                v-model="details.email"
                data-test="registration-page-customer-email-input"
            />
        </div>

        <messages-block
            v-if="!displayElement('input-personal-errors') && errorInformation.length > 0"
            :errors="errorInformation"
        ></messages-block>

        <!--begin::Action-->
        <div :class="registerButtonClass" class="m-login__action">
            <a
                @click="$router.push({name: 'login'})"
                class="m-link"
                v-if="!displayElement('already-have-account-below-registration')"
            >
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
            <div>
                <vue-recaptcha
                    v-if="captchaEnabled"
                    v-show="showCaptcha"
                    class="justify-content-end"
                    :class="[showCaptcha ? 'd-flex' : '']"
                    ref="recaptcha"
                    @verify="onVerify"
                    :loadRecaptchaScript="true"
                    :sitekey="googleSiteKey"
                    :size="captchaSize"
                >
                </vue-recaptcha>
                <template v-if="showRegisterButtons">
                    <button
                        @click="getSecretField()"
                        :disabled="isLoading"
                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                        data-test="registration-page-button"
                    >
                        {{ $t('login.start.registration') }}
                    </button>
                    <div
                        v-if="displayElement('register-secondary-button')"
                        class="wrapper-registration-secondary-button"
                    >
                        <button
                            @click="getSecretField()"
                            :disabled="isLoading"
                            class="btn btn-custom-secondary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                        >
                            {{ $t('registration.secondary.button') }}
                        </button>
                    </div>
                </template>
            </div>
            <a
                @click="$router.push({name: 'login'})"
                class="m-link"
                v-if="displayElement('already-have-account-below-registration')"
            >
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>

<!-- REGISTRATION 2 AREA -->
<div v-else-if="registrationStep2" class="tight">
    <div class="registration-step2-message">
        {{ $t('login.start.security') }}
    </div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div
            class="form-group m-form__group"
            v-if="showSecretFieldIbanMasked || displayElement('input-with-label')"
        >
            <label class="pe-0" for="secretField"
                ><strong>{{ details.secretFieldLabel }}</strong></label
            >
            <div
                v-if="showSecretFieldIbanMasked"
                id="secret-field-iban-masked-container"
            >
                <br />
                <input
                    v-for="field in secretFieldIbanMaskedFields"
                    class="secret-field-iban-masked"
                    type="text"
                    maxlength="1"
                    data-test="iban-masked"
                    @blur="onSecretFieldIbanMaskedBlur"
                    @keyup="onSecretFieldIbanMaskedKeyUp"
                    :readonly="field.readonly"
                    :disabled="field.readonly"
                    :value="field.value"
                />
            </div>
            <template v-else>
                <input
                    :class="['form-control m-input', errors.secretField ? 'is-invalid' : '']"
                    :type="secretFieldType"
                    id="secretField"
                    name="secret-field"
                    v-model="details.secretField"
                    data-test="registration-page-secret-field-input"
                />
            </template>
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                type="text"
                name="secret-field"
                :placeholder="details.secretFieldLabel"
                data-test="registration-page-secret-field-input"
                v-model="details.secretField"
            />
        </div>

        <messages-block
            v-if="!displayElement('input-personal-errors') && errorInformation.length > 0"
            :errors="errorInformation"
        ></messages-block>

        <!--begin::Action-->
        <div :class="registerButtonClass" class="m-login__action">
            <a
                @click="$router.push({name: 'login'})"
                class="m-link"
                v-if="!displayElement('already-have-account-below-registration')"
            >
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
            <button
                @click="checkSecretField()"
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                :disabled="isLoading"
                data-test="registration-page-button-secret"
            >
                {{ $t('login.check.answer') }}
            </button>
            <a
                @click="$router.push({name: 'login'})"
                class="m-link"
                v-if="displayElement('already-have-account-below-registration')"
            >
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>

<!-- REGISTRATION 3 AREA -->
<div v-else-if="registrationStep3" class="tight">
    <div class="registration-step3-message">{{ $t('login.auth.success') }}</div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <!-- email -->
        <div class="form-group m-form__group" v-if="showAuthPlusEmailWithLabel">
            <label for="newUsername"
                ><strong>{{ $t('registration.email') }}</strong></label
            >
            <input
                :class="['form-control m-input', errors.email ? 'is-invalid' : '']"
                type="email"
                name="email"
                id="newEmail"
                v-model="details.email"
            />
        </div>
        <div
            class="form-group m-form__group"
            v-else-if="showAuthPlusEmailWithoutLabel"
        >
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('registration.email')"
                name="newEmail"
                v-model="details.email"
            />
        </div>
        <!-- username -->
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="newUsername"
                ><strong>{{ $t('login.username') }}</strong></label
            >
            <input
                :class="['form-control m-input', errors.username ? 'is-invalid' : '']"
                type="text"
                name="username"
                id="newUsername"
                v-model.trim="details.newUsername"
                :disabled="details.newUsernamePreset"
                @input="sanitizeUsername"
                data-test="registration-page-username-input"
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.username')"
                name="username"
                v-model.trim="details.newUsername"
                :disabled="details.newUsernamePreset"
                @input="sanitizeUsername"
                data-test="registration-page-username-input"
            />
        </div>
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="newPassword">
                <strong>{{ $t('login.password') }}</strong>
                <small>{{ $t('login.password.info') }}</small>
            </label>
            <div>
                <input
                    :class="['form-control m-input', errors.password ? 'is-invalid' : '']"
                    name="password"
                    type="password"
                    id="newPassword"
                    v-model="details.newPassword"
                    data-test="registration-page-password-input"
                />
                <auth-plus-password-strength-meter
                    v-if="isAuthPlusActive"
                    :password="details.newPassword"
                    :username="details.newUsername"
                />
                <password-strength-meter
                    v-if="displayElement('password-strength-meter') && !isAuthPlusActive"
                    v-model="details.newPassword"
                    :strength-meter-only="true"
                    :secureLength="8"
                />
            </div>
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                type="password"
                :placeholder="$t('login.password')"
                name="password"
                v-model="details.newPassword"
                data-test="registration-page-password-input"
            />
            <auth-plus-password-strength-meter
                v-if="isAuthPlusActive"
                :password="details.newPassword"
                :username="details.newUsername"
            />
            <password-strength-meter
                v-if="displayElement('password-strength-meter') && !isAuthPlusActive"
                v-model="details.newPassword"
                :strength-meter-only="true"
                :secureLength="8"
            />
        </div>
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="newPasswordConfirmation">
                <strong>{{ $t('login.password.confirmation') }}</strong>
                <small>{{ $t('login.password.confirmation.info') }}</small>
            </label>
            <input
                :class="['form-control m-input', errors.password ? 'is-invalid' : '']"
                type="password"
                name="password_confirmation"
                id="newPasswordConfirmation"
                v-model="details.newPasswordConfirmation"
                data-test="registration-page-password-confirmation-input"
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                type="password"
                :placeholder="$t('login.password.confirmation')"
                name="password_confirmation"
                v-model="details.newPasswordConfirmation"
                data-test="registration-page-password-confirmation-input"
            />
        </div>

        <messages-block
            v-if="!displayElement('input-personal-errors') && errorInformation.length > 0"
            :errors="errorInformation"
        ></messages-block>

        <div
            class="form-group form-check registration-terms"
            v-if="displayElement('checkbox-accept-terms') && !details.alreadyAuthorized"
        >
            <label
                :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
            >
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="registration_terms_and_conditions"
                    v-model="details.termsAndConditions"
                    data-test="registration-page-password-terms-and-conditions-checkbox"
                />

                {{ $t('registration.accept.terms.before') }}<a
                    :href="termsAndConditionslink"
                    target="_blank"
                    ><span>{{ $t('registration.accept.terms') }}</span></a
                >{{ $t('registration.accept.terms.after') }}
                <i></i>
                <span></span>
            </label>
        </div>
        <!--begin::Action-->
        <div :class="registerButtonClass" class="m-login__action">
            <a
                v-if="!displayElement('already-have-account-below-registration')"
                @click="$router.push({name: 'login'})"
                class="m-link"
            >
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
            <button
                @click="updateLogin()"
                :disabled="isLoading"
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                data-test="registration-page-submit-button"
            >
                {{ $t('login.save.data') }}
            </button>
            <a
                @click="$router.push({name: 'login'})"
                class="m-link"
                v-if="displayElement('already-have-account-below-registration')"
            >
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
        </div>
        <!--end::Action-->
    </div>
</div>

<div v-else-if="showShouldVerifyMessage" class="alert alert-success">
    <span>{{ $t('login.should.verify.email') }}</span>
</div>

<!-- Validate User Data AREA -->
<div
    v-else-if="askUserForDataValidation"
    class="w-100 registration-change-customer-data"
>
    <div class="registration-step4-message">
        {{ $t('login.auth.askUserForDataValidation') }}
    </div>
    <change-customer-data-widget
        :alreadyOpen="true"
        @customerDataUpdated="customerDataUpdated"
        @customerDataSentNoChanges="customerDataUpdated"
    >
    </change-customer-data-widget>
    <div class="m-login__action" v-if="showLoginAction">
        <a @click="continueWithoutDataCheck()" class="m-link">
            <span>{{ $t('login.continue') }}</span>
        </a>
    </div>
</div>
<div v-else></div>
<!--</template>-->
