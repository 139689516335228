import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Settings from '@/interfaces/Settings';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class SettingsProvider
    extends PrototypeProvider
    implements Settings
{
    public async load(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/settings', this.config);
    }

    public async idp(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/auth/idp/settings', this.config);
    }
}
