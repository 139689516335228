<div
    class="m-portlet m-portlet--mobile m-portlet--meter border-radius-rb"
    data-test="meter-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <div id="nextReading-content" style="display: block">
                    <span v-if="state !== 'closed' && daysUntil !== 0">
                        <div
                            data-test="meter-widget-click-icon"
                            v-if="showAction && detailsLoaded && !isActiveWidget"
                            @click="$emit('toggleWidget', 'meterSubmitWidget')"
                            class="fullwidthArea-open"
                        >
                            <i class="fa fa-times"></i>
                        </div>
                        <div
                            data-test="meter-widget-click-icon"
                            v-if="isActiveWidget"
                            @click="$emit('toggleWidget', 'meterSubmitWidget')"
                            class="fullwidthArea-close"
                        >
                            <i class="fa fa-times"></i>
                        </div>
                    </span>

                    <template v-if="inRegistrationState">
                        <div class="nextReading__title">
                            {{ $t('widget.m.textWaitingUnclearDate') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'waitingPast'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.textWaitingUnclearDate') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'moveInMissing'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textMoveInMissing') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'moveOutMissing' && daysUntil <= 0"
                    >
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textMoveOutMissingNow') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'moveOutMissing' && daysUntil >= 1"
                    >
                        <div class="nextReading__title">
                            {{ daysUntil }} {{ daysUntil === 1 ?
                            $t('widget.m.day') : $t('widget.m.days') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textMoveOutMissing') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'turnusNow'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textTurnusNow') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'closed' && finalInvoiceCreated"
                    >
                        <div class="nextReading__title">
                            {{ $t('widget.m.finalInvoice') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.hasBeenCreated') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'closed'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.deliveryEnd') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.hasBeenReached') }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="nextReading__title">
                            <content-loader
                                v-if="!daysUntil"
                                :width="35"
                                :height="35"
                                style="width: 35px; height: 35px"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                            <template v-else>{{ daysUntil }}</template>
                            {{ daysUntil === 1 ? $t('widget.m.day') :
                            $t('widget.m.days') }}
                        </div>
                        <div class="nextReading__text">
                            <template v-if="state === 'waiting'">
                                {{ $t('widget.m.textWaiting') }}
                            </template>
                            <template v-else>
                                {{ $t('widget.m.text') }}
                            </template>
                        </div>
                    </template>
                </div>
            </div>

            <meter-submit-widget
                v-if="isActiveWidget && displayElement('meter-submit-widget')"
                class="d-md-none"
            ></meter-submit-widget>
        </div>
    </div>
</div>
