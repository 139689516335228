// -----------------------------
//      Protector Data structure
// -----------------------------

// Data Structure
const state = {
    isBack: false,
    passwordHash: null,
};

// Outside methods for communication with data.
const actions = {
    setProtectorStatus({ commit }, status): void {
        commit('SET_PROTECTOR_STATUS', status);
    },
    setBackPossible({ commit }): void {
        commit('SET_BACK_POSSIBLE');
    },
};

// Logic that change data
const mutations = {
    SET_PROTECTOR_STATUS(localState, status): void {
        if (status === process.env.VUE_APP_PASSWORD) {
            localState.passwordHash = hashCode(status);
            localStorage.setItem('password-protector', hashCode(status));
        }
    },
    SET_BACK_POSSIBLE(localState): void {
        localState.isBack = true;
    },
};

/* tslint:disable:no-bitwise */
const hashCode = (s) => {
    return s.split('').reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        // @ts-ignore
        return a & a;
    }, 0);
};
/* tslint:enable:no-bitwise */

// Get Data transformation (like computed)
const getters = {
    isAllowed() {
        return (
            !process.env.VUE_APP_PASSWORD ||
            state.passwordHash === hashCode(process.env.VUE_APP_PASSWORD) ||
            parseInt(localStorage.getItem('password-protector') + '', 10) ===
                hashCode(process.env.VUE_APP_PASSWORD)
        );
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
