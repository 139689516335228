<div class="m-portlet m-portlet--mobile" data-test="thg-bonus-overview">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <span
                    class="static-title static-title--custom thg-status-title"
                >
                    {{ $t('widget.nc.bonus.overview.title') }}
                </span>
            </div>
        </div>

        <div class="row" v-if="showNewBonusInfo">
            <div class="col-12">
                <div class="thg-bonus-overview-new-bonus-info row">
                    <div class="thg-bonus-overview-wrapper">
                        <div class="thg-bonus-overview-icon-bonus-box col-lg-1">
                            <div class="thg-bonus-overview-icon-bonus"></div>
                        </div>
                        <div
                            class="thg-bonus-overview-new-bonus-info-main col-lg-4"
                            v-html="$t('widget.nc.bonus.overview.waiting', {year: newBonusInfoYear})"
                        ></div>
                        <div
                            class="thg-bonus-overview-new-bonus-info-secondary col-lg-4"
                            v-html="$t('widget.nc.bonus.overview.next.steps')"
                        ></div>
                        <div
                            class="thg-bonus-overview-new-bonus-btn-box col-lg-3"
                        >
                            <button
                                @click="showBonusSelector(newBonusInfoYear)"
                                type="button"
                                class="btn btn-primary d-block thg-bonus-overview-new-bonus-btn-choose-bonus"
                            >
                                {{ $t('widget.nc.bonus.overview.pick.bonus') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="thgService.allPreviousBonusProducts.length > 0">
            <div
                v-for="previousProduct of thgService.allPreviousBonusProducts"
                class="col-12"
            >
                <thg-bonus-entry
                    :isTitleLoading="isTitleLoading"
                    :thgService="thgService"
                    :bonusYear="bonusYear"
                    :product="previousProduct"
                ></thg-bonus-entry>
            </div>
        </div>
    </div>
</div>
