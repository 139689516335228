import { LoginPage as LoginPageParent } from '@/components/pages/auth/login-page';
import WithRender from '../login-page.html';
import Component from 'vue-class-component';

@WithRender
@Component({
    props: {
        second: {
            type: Number,
            default: 0,
        },
    },
})
export class LoginPageAhrensburg extends LoginPageParent {
    private hasSpaces(checkString: string) {
        return /\s/g.test(checkString);
    }

    protected onInvalidAuth(response) {
        this.handleInvalidAuth(response);
        if (this.hasSpaces(this.username)) {
            this.errors.push([this.$t('login.user.error.spaces')]);
        }
    }

    protected onLoginError(error) {
        this.handleLoginError(error);
        if (this.hasSpaces(this.username)) {
            this.errors.push([this.$t('login.user.error.spaces')]);
        }
    }
}
