import Base from '@/mixins/base';
import WithRender from './header-v2.html';
import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import { default as Component } from 'vue-class-component';

@WithRender
@Component({})
export class HeaderV2 extends Base {
    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    protected elements = {
        ets: ['header_nav'],
    };

    get isAuthenticated(): boolean {
        return this.auth.isAuthenticated();
    }

    get logo(): string {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.svg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.png);'
        );
    }

    get background(): string {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/login.svg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/login.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/login.png);'
        );
    }

    get settings(): Record<any, any> {
        return this.$store.state.settings;
    }

    private logout(): void {
        this.auth.logout(this.settings.idpLogoutUrl);
    }
}
