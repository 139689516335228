import { TariffDetailsWidget } from '../tariff-details-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './schweizstrom.html';

@WithRender
@Component({})
export class TariffDetailsWidgetSchweizstrom extends TariffDetailsWidget {
    get useAt() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.useAt;
    }

    get current() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.current;
    }
}
