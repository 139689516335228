<div id="customerCommunicationsServiceWidget">
    <div class="m-timeline-1__item" :class="serviceWidgetPosition">
        <div class="m-timeline-1__item-circle">
            <div class="m--bg-primary"></div>
        </div>
        <div class="m-timeline-1__item-arrow"></div>
        <div class="m-timeline-1__item-content">
            <div
                class="m-timeline-1__item-title"
                v-if="!displayElement('no-title')"
            >
                {{ $t('widget.service.title') }}
            </div>
            <div
                class="m-timeline-1__item-body"
                v-if="displayElement('contact-form-action')"
            >
                {{ $t('widget.service.text.pre') }}
                <a @click="$emit('serviceWidgetClicked')"
                    >{{ $t('widget.service.text.link') }}</a
                >
                {{ $t('widget.service.text.post') }}
            </div>
            <div
                class="m-timeline-1__item_mailto"
                v-else-if="displayElement('mailto-email')"
            >
                {{ $t('widget.service.text') }}
                <a :href='"mailto:" + $t("widget.service.email")'
                    >{{ $t('widget.service.email') }}</a
                >.
            </div>
            <div class="m-timeline-1__item-body" v-else>
                {{ $t('widget.service.text') }}
            </div>
        </div>
    </div>
</div>
