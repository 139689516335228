<div class="m-subheader">
    <div class="m-subheader-content">
        <span v-if="isMorning">{{ $t('widget.welcome.goodMorning') }}</span>
        <span v-else-if="isMidDay">{{ $t('widget.welcome.hello') }}</span>
        <span v-else-if="isEvening"
            >{{ $t('widget.welcome.goodEvening') }}</span
        >
        {{ customerData.firstname }},
        <div>
            {{ $t('widget.cs.contractNumber')}}: {{ contract.contractId }} - {{
            showContractTitle ? contract.title : $t('widget.nc.title') }}
        </div>
    </div>
</div>
