import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './hotline-widget.html';

@WithRender
@Component({})
export class HotlineWidget extends Base {
    get footerImgLink(): string {
        return '/img/evus/' + this.currentClient + '/img-footer.png';
    }

    get showCancelation(): boolean {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );

        if (contract) {
            return this.isThg(contract) || this.isDealer(contract);
        }
        return false;
    }

    get prefix(): string {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );

        if (contract) {
            if (this.isThg(contract)) {
                return 'elli.thg';
            }

            if (this.isDealer(contract)) {
                return 'elli.thg.dealer';
            }
        }

        return 'elli';
    }
}
