import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Bonus from '@/interfaces/Bonus';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class BonusProvider extends PrototypeProvider implements Bonus {
    public async verify(data: { code: string }): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/bonus/verify', data, this.config);
    }

    public async redeem(data: {
        code: string;
        contractId: string;
    }): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/bonus/redeem', data, this.config);
    }

    public async getBonusProducts(data: {
        contractId: string | number | null;
    }): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/bonus/products', data, this.config);
    }

    public async applyBonus(data: {
        contractId: string | number | null;
        year: string | number | null;
        licensePlate: string | null;
        productCode: string | null | undefined;
        newYearPrice: number | null | undefined;
        bankData?: {
            bearer?: string | null;
            iban?: string | null;
            bic?: string | null;
        } | null;
    }): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/contract/apply-bonus', data, this.config);
    }
}
