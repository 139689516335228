<datetime
    :id="getId"
    :disabled="disabled()"
    :type="type"
    :input-id="inputId"
    :input-class="inputClass"
    :input-style="inputStyle"
    :hidden-name="hiddenName"
    :value-zone="valueZone"
    :zone="zone"
    :format="format"
    :phrases="phrases"
    :hour-step="hourStep"
    :minute-step="minuteStep"
    :min-datetime="minDatetime"
    :max-datetime="maxDatetime"
    :auto="auto"
    :week-start="weekStart"
    :flow="flow"
    :title="title"
    :hide-backdrop="hideBackdrop"
    :backdrop-click="backdropClick"
    :value="value"
    @input="(inputValue) => $emit('input', inputValue)"
></datetime>
