<div
    class="
        m-page--fluid
        m--skin-
        m-content--skin-light2
        m-header--fixed m-header--fixed-mobile
        m-aside-left--enabled
        m-aside-left--skin-dark
        m-aside-left--fixed
        m-aside-left--offcanvas
        m-footer--push
        m-aside--offcanvas-default
    "
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <ewii-header @toggleTab="toggleTab" :current-tab="currentTab" />
        <div
            class="
                m-grid__item m-grid__item--fluid
                m-grid m-grid--ver-desktop
                body-margin
                justify-content-center
            "
        >
            <agent-view-widget />
            <call-to-action-banner-widget v-if="showCallToActionBannerWidget" />
            <div class="row w-100">
                <div class="col-12">
                    <v-select
                        v-model="selected"
                        v-if="currentTab !== 1"
                        class="contract-select"
                        :reduce="option => option.contractId"
                        :options="contractsList"
                        label="title"
                    >
                        <template
                            slot="selected-option"
                            class="w-100"
                            slot-scope="contract"
                        >
                            <span class="w-100"
                                >#{{ contract.contractId }} - {{ contract.title
                                }} {{ contract.street }} {{ contract.houseNumber
                                }}, {{ contract.zip }} {{ contract.city }}</span
                            >
                        </template>
                        <template slot="option" slot-scope="contract">
                            <span class="w-50"
                                >#{{ contract.contractId }} - {{ contract.title
                                }} {{ contract.street }} {{ contract.houseNumber
                                }}, {{ contract.zip }} {{ contract.city }}</span
                            >
                        </template>
                    </v-select>
                    <div v-else class="dashboard-button w-100">
                        <a
                            href="https://www.ewii.dk/"
                            class="text-start border-0"
                            ><i class="fa fa-home"></i>Zurück zum alten
                            Portal</a
                        >
                    </div>
                </div>
                <div class="d-flex flex-wrap w-100" v-if="currentTab === 0">
                    <div
                        class="col-md-12 col-sm-12 col-xs-12 row-items mt-4"
                        v-if="!hideMeterHistoryWidget"
                    >
                        <meter-history-widget
                            :isUsageHistoryWidgetEnabled="isUsageHistoryWidgetEnabled"
                            @toggleHistoryWidget="toggleHistoryWidget"
                            v-if="isNoDashboardRedirect"
                        ></meter-history-widget>
                    </div>
                    <div
                        class="col-md-12 col-sm-12 col-xs-12 row-items mt-4"
                        v-if="isUsageHistoryWidgetEnabled && showUsageHistoryWidget"
                    >
                        <usage-history-widget
                            @toggleHistoryWidget="toggleHistoryWidget"
                        ></usage-history-widget>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mobile-modal__inner">
                            <div class="m-portlet m-portlet--mobile">
                                <div class="m-portlet__body px-0">
                                    <div class="custom-widget-title">
                                        <span
                                            class="
                                                box-title
                                                static-title
                                                position-absolute
                                                ps-3
                                                pt-2
                                                m-0
                                                w-75
                                            "
                                        >
                                            {{ $t('dashboard.custom.widget-1')
                                            }}
                                        </span>
                                    </div>
                                    <div class="mobile-modal__wrap mx-0 p-3">
                                        <ul>
                                            <li>
                                                <a href="#link1"
                                                    >Link to Document</a
                                                >
                                            </li>
                                            <li>
                                                <a href="#link2"
                                                    >Link to Document</a
                                                >
                                            </li>
                                            <li>
                                                <a href="#link3"
                                                    >Link to Document</a
                                                >
                                            </li>
                                            <li>
                                                <a href="#link4"
                                                    >Link to Document</a
                                                >
                                            </li>
                                            <li>
                                                <a href="#link5"
                                                    >Link to Document</a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mobile-modal__inner">
                            <div class="m-portlet m-portlet--mobile">
                                <div class="m-portlet__body px-0">
                                    <div class="custom-widget-title">
                                        <span
                                            class="
                                                box-title
                                                static-title
                                                position-absolute
                                                ps-3
                                                pt-2
                                                m-0
                                                w-75
                                            "
                                        >
                                            {{ $t('dashboard.custom.widget-2')
                                            }}
                                        </span>
                                    </div>
                                    <div class="mobile-modal__wrap mx-0 p-3">
                                        <div>
                                            <p>
                                                {{
                                                $t('dashboard.custom.widget-2.text')
                                                }}
                                            </p>
                                        </div>
                                        <div>
                                            <v-select
                                                v-model="selectedParcelhouse"
                                                :options="widgetOptions"
                                                :reduce="option => option.value"
                                                :searchable="false"
                                            />
                                        </div>
                                        <img
                                            :src="parcelhouseImage"
                                            class="w-100"
                                            alt="no image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mobile-modal__inner">
                            <div class="m-portlet m-portlet--mobile">
                                <div class="m-portlet__body px-0">
                                    <div class="custom-widget-title">
                                        <span
                                            class="
                                                box-title
                                                static-title
                                                position-absolute
                                                ps-3
                                                pt-2
                                                m-0
                                                w-75
                                            "
                                        >
                                            {{ $t('dashboard.custom.widget-3')
                                            }}
                                        </span>
                                    </div>
                                    <div class="mobile-modal__wrap mx-0 p-3">
                                        <div>
                                            <p>
                                                {{
                                                $t('dashboard.custom.widget-3.text')
                                                }}
                                            </p>
                                        </div>
                                        <div class="d-flex justify-content-end">
                                            <a
                                                href="https://www.google.com/"
                                                class="custom-widget-3-button"
                                                >{{
                                                $t('dashboard.custom.widget-3.button')
                                                }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap w-100" v-if="currentTab === 1">
                    <div
                        class="col-12 col-md-6 col-lg-4 mt-4"
                        v-for="contract in contractsData"
                    >
                        <div
                            class="mobile-modal__inner contract"
                            @click="selectContract(contract.contractId)"
                        >
                            <div class="m-portlet m-portlet--mobile">
                                <div class="m-portlet__body px-0">
                                    <div
                                        class="
                                            contract-custom-widget-title
                                            position-relative
                                        "
                                    >
                                        <p
                                            class="
                                                box-title
                                                static-title
                                                ps-3
                                                pt-2
                                                m-0
                                                w-75
                                            "
                                        >
                                            {{ contract.title }}
                                        </p>
                                        <p class="w-75 ps-3">
                                            {{ contract.street }} {{
                                            contract.houseNumber }}, {{
                                            contract.zip }} {{ contract.city }}
                                        </p>
                                        <img
                                            class="
                                                position-absolute
                                                lightning-img
                                            "
                                            src="/img/evus/ewii/lightning.svg"
                                            alt="#"
                                        />
                                    </div>
                                    <div class="mobile-modal__wrap mx-0 p-3">
                                        <div
                                            class="
                                                mx-1
                                                font-size-20 font-weight-600
                                            "
                                        >
                                            <p class="m-0">Forbrug</p>
                                        </div>
                                        <div
                                            class="
                                                d-flex
                                                flex-wrap
                                                align-items-end
                                            "
                                        >
                                            <div
                                                class="
                                                    block-1
                                                    text-white
                                                    d-flex
                                                    align-items-end
                                                    justify-content-center
                                                    font-size-20
                                                "
                                                style="
                                                    height: 90px;
                                                    background-color: #09dcbb;
                                                "
                                            >
                                                <div>
                                                    <p class="m-0">
                                                        <span
                                                            class="
                                                                font-weight-bold
                                                            "
                                                            >20,2</span
                                                        >
                                                        kWh
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="
                                                    block-2
                                                    text-white
                                                    d-flex
                                                    align-items-end
                                                    justify-content-center
                                                    font-size-20
                                                "
                                                style="
                                                    height: 100px;
                                                    background-color: #07a0aa;
                                                "
                                            >
                                                <p class="m-0">
                                                    <span
                                                        class="font-weight-bold"
                                                        >24,2</span
                                                    >
                                                    kWh
                                                </p>
                                            </div>
                                            <div
                                                class="
                                                    sub-block-1
                                                    text-white
                                                    d-flex
                                                    align-items-end
                                                    justify-content-center
                                                "
                                                style="
                                                    height: 30px;
                                                    background-color: #09dcbb;
                                                "
                                            >
                                                <p
                                                    class="
                                                        m-1
                                                        position-absolute
                                                    "
                                                >
                                                    1.-15. jan 2020
                                                </p>
                                            </div>
                                            <div
                                                class="
                                                    sub-block-2
                                                    text-white
                                                    d-flex
                                                    align-items-end
                                                    justify-content-center
                                                "
                                                style="
                                                    height: 30px;
                                                    background-color: #07a0aa;
                                                "
                                            >
                                                <p
                                                    class="
                                                        m-1
                                                        position-absolute
                                                    "
                                                >
                                                    1.-15. jan 2021
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap w-100" v-if="currentTab === 2">
                    <div
                        class="col-md-12 col-sm-12 col-xs-12 row-items mt-4"
                        v-if="currentTab === 2"
                    >
                        <invoice-widget
                            :contract-id="contractId"
                            ref="invoiceWidget"
                        ></invoice-widget>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
