import { default as Component } from 'vue-class-component';
import WithRender from './request-password-reset-widget.html';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import AuthPlus from '@/interfaces/AuthPlus';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class RequestPasswordResetWidget extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    protected email = '';
    protected settings: Partial<AuthPlus> = {};
    protected errors = false;
    protected elements = {
        bsw: ['input-with-label'],
        'dsp-*': ['auth-plus', 'auth-plus-password-strength-meter'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    get username() {
        return this.$store.state.auth.username;
    }

    set username(username) {
        this.$store.dispatch('auth/setUserName', username);
    }

    get isAuthPlusActive() {
        return this.settings.isAuthPlusActive;
    }

    get showAuthPlusEmailWithLabel() {
        return (
            this.isAuthPlusActive &&
            this.displayElement('auth-plus') &&
            this.displayElement('input-with-label')
        );
    }

    get showAuthPlusEmailWithoutLabel() {
        return this.isAuthPlusActive && this.displayElement('auth-plus');
    }

    public created() {
        this.settings = this.$store.state.settings;
    }

    private setPasswordReset() {
        this.successInformation = [];
        this.errorInformation = [];
        this.errors = false;

        this.auth
            .setPasswordReset(this.username, this.email, window.location.href)
            .then(
                (response) => {
                    if (response && response.data && response.data.success) {
                        this.username = '';

                        if (typeof response.data.message === 'object') {
                            this.successInformation.push(response.data.message);
                        } else if (typeof response.data.message === 'string') {
                            this.successInformation.push({
                                key: '',
                                message: response.data.message,
                            });
                        }
                    } else {
                        this.errors = true;

                        if (typeof response.data.message === 'object') {
                            this.errorInformation.push(response.data.message);
                        } else if (typeof response.data.message === 'string') {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.message,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
    }
}
