import { InvoiceWidget } from '../invoice-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './invoices-general-widget.html';
import { ContentLoader } from 'vue-content-loader';

import IocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import SERVICES from '@/container/Services';
import moment from 'moment';
import Commodity from '@/interfaces/Commodity';
import { TranslateResult } from 'vue-i18n';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);

@WithRender
@Component({
    filters: {
        euro: formatProvider.euro(),
        euroSpecial: formatProvider.euroSpecial(),
    },
    components: {
        ContentLoader,
    },
    props: {
        showPriceInformationAsterisk: {
            type: Boolean,
            default: false,
        },
    },
})
export class InvoicesGeneralWidget extends InvoiceWidget {
    protected paymentAccountBalanceError = false;
    protected readingNextError = false;

    protected mounted() {
        if (
            this.$store.state &&
            this.$store.state.contracts &&
            this.$store.state.contracts.contractId
        ) {
            this.$store
                .dispatch(
                    'payment/accountBalance',
                    this.$store.state.contracts.contractId
                )
                .then(() => {
                    this.paymentAccountBalanceError = this.$store.getters[
                        'payment/getState'
                    ](
                        this.$store.state.contracts.contractId
                    ).accountBalanceError;
                });
            this.$store
                .dispatch(
                    'reading/next',
                    this.$store.state.contracts.contractId
                )
                .then(() => {
                    this.readingNextError = this.$store.getters[
                        'reading/getState'
                    ](this.$store.state.contracts.contractId).readingNextError;
                });
            this.$store.dispatch(
                'tariff/overview',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
        }
    }

    get contract(): Record<any, any> {
        const contractInfo = this.$store.state.contracts.list.filter(
            (contract) => {
                return (
                    contract.contractId ===
                    this.$store.state.contracts.contractId
                );
            }
        )[0];
        if (!contractInfo) {
            return {
                title: null,
            };
        }
        return contractInfo;
    }

    get tariff(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariff;
    }

    get tariffOverviewError() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariffOverviewError;
    }

    get tariffDetailsError() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariffDetailsError;
    }

    get billingAddress() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.billingAddress;
    }

    get accountBalance(): number {
        return (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).accountBalance.balance ?? 0
        );
    }

    get isAccountBalanceLoading() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).isAccountBalanceLoading;
    }

    get finalInvoiceCreated() {
        return (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).invoices.filter((invoice) => {
                return (
                    moment(invoice.periodEnd).format('YYYY-MM-DD') ===
                    this.$store.getters['tariff/getState'](
                        this.$store.state.contracts.contractId
                    ).contract.endDate
                );
            }).length === 1
        );
    }

    get daysUntil(): any {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).next.daysUntil;
    }

    get state(): any {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).next.state;
    }

    get inRegistrationState(): boolean {
        return (
            this.state === 'waiting' &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.startDate === null
        );
    }

    get commodity(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityType(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract,
                'invoices-general'
            )
        );
    }

    get commodityPrice(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }

    get isPostboxEnabled(): boolean {
        return this.$store.state.settings.customerPostboxEnabled;
    }

    get isInvoicesGeneralLoadedWithError() {
        return (
            this.paymentAccountBalanceError ||
            this.readingNextError ||
            this.tariffOverviewError ||
            this.tariffDetailsError
        );
    }

    get isReadingNextLoading() {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).isReadingNextLoading;
    }

    get isTariffDetailsLoading() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).isTariffDetailsLoading;
    }

    get isTariffOverviewLoading() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).isTariffOverviewLoading;
    }

    get isLoading() {
        return (
            this.isAccountBalanceLoading ||
            this.isReadingNextLoading ||
            this.isTariffDetailsLoading ||
            this.isTariffOverviewLoading
        );
    }
}
