<div class="m-portlet m-portlet--mobile m-portlet--invoice">
    <div class="m-portlet__body">
        <div class="row p-0">
            <div class="col-12 p-0">
                <div class="invoice-widget-title">
                    <span
                        class="
                            monthlyDeposit-title
                            static-title static-title--custom
                            position-absolute
                            ps-3
                            pt-2
                        "
                        >{{ $t('widget.i.title') }}</span
                    >
                </div>
                <table
                    :class="['table', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                    id="invoiceList-content"
                    v-if="showContent"
                >
                    <tbody>
                        <tr>
                            <th
                                :data-name="$t('widget.i.billNumber')"
                                class="d-block"
                            >
                                <span>{{ $t('widget.i.billNumber') }}</span>
                            </th>
                            <th data-name="Zeitraum">
                                <span>{{ $t('widget.i.billCycle') }}</span>
                            </th>
                            <th data-name="Betrag">
                                <span>{{ $t('widget.i.amount') }}</span>
                            </th>
                            <th data-name="Details">
                                <span>{{ $t('widget.i.details') }}</span>
                            </th>
                        </tr>
                        <template v-if="invoicesMapped.length > 0">
                            <tr
                                v-for="invoice in invoicesMapped"
                                :class="{'active': invoice.active}"
                            >
                                <td
                                    data-name="mobile-main"
                                    @click="activate(invoice)"
                                >
                                    {{ invoice.invoiceNumber }}
                                </td>
                                <td :data-name="$t('widget.i.billCycle')">
                                    {{ invoice.periodStart | date }} - {{
                                    invoice.periodEnd | date }}
                                </td>
                                <td :data-name="$t('widget.i.amount')">
                                    {{ (invoiceShowUnsettledValue ?
                                    invoice.unsettledValue : invoice.value) |
                                    euro }} {{ $t('euro') }}
                                </td>
                                <td>
                                    <a
                                        v-if="displayElement('downloadInvoice-link')"
                                        href="#"
                                        @click.prevent="downloadInvoice(invoice)"
                                    >
                                        <strong
                                            >{{ $t('widget.i.download')
                                            }}</strong
                                        >
                                    </a>
                                    <button
                                        v-else
                                        :class="[
                    'btn btn-sm btn-invoice-download',
                    displayElement('download-btn-link') ? 'btn-link' : 'btn-primary',
                  ]"
                                        @click="downloadInvoice(invoice)"
                                    >
                                        <span
                                            >{{ $t('widget.i.download') }}</span
                                        >
                                    </button>
                                </td>
                            </tr>
                        </template>
                        <template v-else
                            ><span
                                v-if="displayElement('show-no-invoices-text')"
                                >{{$t('widget.i.no.invoices')}}</span
                            >
                        </template>
                    </tbody>
                </table>
                <a
                    v-if="displayElement('show-personal-info-link')"
                    :href="$t('widget.infoPersonalDataLink')"
                    class="personal-info-link info-link-m0"
                    target="_blank"
                    >{{ $t('widget.infoPersonalData') }}</a
                >
                <content-loader
                    v-if="!invoicesLoaded"
                    height="20"
                    data-test="CONTENT-LOADER"
                ></content-loader>
            </div>
        </div>
    </div>
</div>
