<div class="col-12 mobile-modal__inner" data-test="deposit-change-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row" id="depositChange-content">
                <div class="col-xs-12 col-sm-6">
                    <i
                        v-if="displayElement('coins-icon')"
                        class="fa fa-coins readingHistory-icon"
                    ></i>
                    <span class="box-title static-title">
                        {{ $t('widget.dc.title') }}
                    </span>
                    <button
                        v-if="depositOverviewAllowed && !displayElement('deposit-overview-bottom')"
                        class="btn btn-link ms-2 mt-1 pt-3"
                        @click="$emit('toggleWidget', 'showDepositOverview')"
                    >
                        {{ $t('widget.do.title') }}
                    </button>
                    <br />
                    <div v-if="displayElement('depositDetails-current')">
                        {{ $t('widget.dc.discount') }}:
                        <strong>
                            {{ depositDetails.current | euroSpecial }} {{
                            $t('euro') }}
                        </strong>
                    </div>
                    <span v-if="displayElement('deposit-inside-text')">
                        {{ $t('widget.dc.text1') }}
                        <b>
                            <span
                                v-if="displayElement('decimal-deposit')"
                                id="recommendedDepositEuroValue"
                            >
                                <br v-if="displayElement('deposit-new-line')" />
                                {{ depositDetails.recommended | euro }} {{
                                $t('euro') }}
                            </span>
                            <span v-else id="recommendedDepositEuroFullValue">
                                <br v-if="displayElement('deposit-new-line')" />
                                {{ depositDetails.recommended | euroFull }} {{
                                $t('euro') }}
                            </span>
                        </b>
                        {{ $t('widget.dc.text2') }}
                    </span>
                    <span v-else>
                        {{ $t('widget.dc.text') }}:
                        <b>
                            <span
                                v-if="displayElement('decimal-deposit')"
                                id="recommendedNewDepositEuroValue"
                            >
                                <br v-if="displayElement('deposit-new-line')" />
                                {{ depositDetails.recommended | euro }} {{
                                $t('euro') }}
                            </span>
                            <span v-else id="recommendedNewDepositEuroFullValue"
                                ><br
                                    v-if="displayElement('deposit-new-line')"
                                />
                                {{ depositDetails.recommended | euroFull }} {{
                                $t('euro') }}
                            </span>
                        </b> </span
                    ><br />

                    <div
                        :class="{'mt-4 mb-4': displayElement('deposit-overview-bottom-secondary')}"
                    >
                        <button
                            v-if="depositOverviewAllowed && displayElement('deposit-overview-bottom')"
                            :class="{
                            ...planButtonClasses,
                            'btn-link': !displayElement('deposit-overview-bottom-secondary'),
                            'btn-primary btn-sm m-btn--gradient-from-light ml-0': displayElement('deposit-overview-bottom-secondary'),
                        }"
                            class="btn p-0 mt-3 mt-1 pt-3"
                            @click="$emit('toggleWidget', 'showDepositOverview')"
                        >
                            {{ $t('widget.do.title') }}
                        </button>
                    </div>

                    <div
                        class="pt-3"
                        v-if="(showActiveFrom || showAlternativeDayOfMonth)"
                    >
                        {{ $t('widget.dc.deposit.dates') }}
                        <div class="row deposit-container">
                            <div v-if="showAlternativeDayOfMonth" class="col">
                                <v-select
                                    :options="daysList"
                                    v-model="details.alternativeDayOfMonth"
                                    label="contractId"
                                    class="w-100 mt-1"
                                    :clearable="false"
                                    :searchable="false"
                                    :placeholder="$t('widget.dc.deposit.alternative.placeholder')"
                                />
                            </div>
                            <div v-if="showActiveFrom" class="col">
                                <datetime
                                    :min-datetime="minDate"
                                    v-model="details.activeFrom"
                                    type="date"
                                    input-id="deposit-active-from"
                                    input-class="form-control"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    :placeholder="$t('widget.dc.deposit.active.placeholder')"
                                    :phrases="datetimePhrases"
                                >
                                    <template slot="after">
                                        <i
                                            class="far fa-calendar-alt datetimepicker-icon"
                                            style="
                                                position: absolute;
                                                right: 24px;
                                                top: 18px;
                                            "
                                        ></i>
                                    </template>
                                </datetime>
                            </div>
                        </div>
                    </div>

                    <template v-if="displayElement('show-notice')">
                        <br />
                        <span class="font-weight-bold color-grey"
                            >{{ $t('widget.dc.noticeTitle')}}<br
                        /></span>
                        <span class="color-grey"
                            >{{ $t('widget.dc.noticeText' )}}</span
                        >
                        <br /><br />
                    </template>
                    <template v-else>
                        <br />
                    </template>
                    <div
                        v-if="successInformation && !displayElement('alert-in-column')"
                    >
                        <messages-block
                            :success="successInformation"
                        ></messages-block>
                    </div>
                    <div
                        v-if="errorInformation && !displayElement('alert-in-column')"
                    >
                        <messages-block
                            :errors="errorInformation"
                        ></messages-block>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <label
                        class="range-newDepositValue-label"
                        for="range-newDepositValue"
                    >
                        {{ $t('widget.dc.monthly') }}
                    </label>
                    <br /><br /><br />
                    <input
                        data-test="deposit-change-widget-input"
                        v-if="displayElement('number-input')"
                        type="number"
                        id="range-newDepositValue"
                        v-model="details.depositValue"
                        :min="depositDetails.minValue"
                        :max="depositDetails.maxValue"
                        class="form-control"
                    />
                    <vue-slider
                        data-test="deposit-change-widget-slider"
                        v-else
                        v-model="details.depositValue"
                        :min="depositDetails.minValue"
                        :max="depositDetails.maxValue"
                        :tooltip="'always'"
                        :tooltip-formatter="tooltipFormatter"
                    ></vue-slider>
                    <div class="d-flex justify-content-end">
                        <div v-if="displayElement('alert-in-column')">
                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>
                        </div>
                        <button
                            data-test="deposit-change-widget-submit"
                            :class="[displayElement('default-btn') ? '' : 'btn-sm', 'btn btn-primary']"
                            @click="submit"
                            :disabled="submitPending"
                        >
                            {{ $t('widget.dc.transfer') }}
                        </button>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6"></div>
                <div class="col-xs-12 col-sm-6">
                    <div v-if="showSurvey" class="survey-widget-block">
                        <survey-widget
                            :widget="'deposit-change-widget'"
                        ></survey-widget>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
