<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div id="customer-service-form-content">
            <div class="box-title static-title">
                {{ $t('widget.cs.title') }}
            </div>
        </div>
        <div class="mobile-modal__wrap">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <div class="form-group">
                        <label for="choose-topic"
                            >{{ $t('widget.cs.topic.choose') }}</label
                        >
                        <treeselect
                            v-model="details.categoryId"
                            id="choose-topic"
                            :options="categories"
                            :disableBranchNodes="true"
                            :maxHeight="200"
                            :placeholder="$t('widget.cs.topic.choose.placeholder')"
                            :noOptionsText="$t('widget.cs.topic.choose.no.options')"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-7">
                    <div class="form-group">
                        <label for="message"
                            >{{ $t('widget.cs.message') }}</label
                        >
                        <textarea
                            id="message"
                            type="text"
                            class="form-control service-form-textarea"
                            rows="8"
                            v-model="details.text"
                        ></textarea>
                    </div>
                </div>
                <div class="col-12 col-sm-5">
                    <div class="form-group">
                        <label for="files-list"
                            >{{ $t('widget.cs.files') }}</label
                        >
                        <input
                            id="files-list"
                            type="file"
                            class="form-control"
                            accept="application/pdf,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx"
                            multiple
                            ref="fileList"
                            @change="processFile($event)"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 col-xs-12">
                    <a
                        :href="$t('widget.infoPersonalDataLink')"
                        class="personal-info-link info-link-m0"
                        target="_blank"
                        >{{ $t('widget.infoPersonalData') }}</a
                    >
                </div>
                <div class="col-sm-4 col-xs-12">
                    <button
                        class="btn btn-primary d-block ms-auto"
                        @click="checkAndSubmit"
                        :disabled="submitPending"
                    >
                        {{ $t('widget.cs.submit.message') }}
                    </button>
                </div>
            </div>

            <messages-block
                :success="successInformation"
                :errors="errorInformation"
            ></messages-block>
        </div>
    </div>
</div>
