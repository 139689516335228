import { TariffWidgetEts } from '@/components/widgets/tariff-widget/custom/ets';
import { TariffWidgetBSW } from '@/components/widgets/tariff-widget/custom/bsw';
import { TariffWidget } from '@/components/widgets/tariff-widget/tariff-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = TariffWidgetEts;
} else if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
    widget = TariffWidgetBSW;
} else {
    widget = TariffWidget;
}
export { widget as TariffWidget };
