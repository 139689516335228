<div class="col-12 mobile-modal__inner tariff-overview-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div
                v-if="!displayElement('hide-close-button')"
                @click="$emit('closeWidget')"
                class="fullwidthArea-close"
                data-test="tariff-overview-widget-close-button"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <i
                        v-if="displayElement('show-tariff-overview-icon')"
                        class="fa fa-tags tarifOverview-icon"
                    ></i>
                    <span class="tarifOverview-title static-title"
                        >{{ $t('widget.to.title') }}</span
                    >
                    <div class="mobile-modal__wrap">
                        <template v-if="isReadingDetailsLoading">
                            <div class="col-12">
                                <content-loader
                                    height="20"
                                    primaryColor="var(--secondary)"
                                    data-test="CONTENT-LOADER"
                                    secondaryColor="var(--primary)"
                                ></content-loader>
                            </div>
                        </template>
                        <template v-else>
                            <table
                                data-test="tariff-details-widget-tariff-overview-table"
                                :class="[
                                'table table-striped tariff-overview-table',
                                displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '',
                                displayElement('scrollable-table') ? 'd-block overflow-auto' : ''
                            ]"
                                id="invoiceList-content"
                            >
                                <tbody>
                                    <tr>
                                        <th
                                            :data-name="$t('widget.to.activeFrom')"
                                            v-if="!displayElement('show-active-on')"
                                        >
                                            <span
                                                >{{ $t('widget.to.activeFrom')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            :data-name="$t('widget.to.activeUntil')"
                                            v-if="!displayElement('show-active-on')"
                                        >
                                            <span
                                                >{{ $t('widget.to.activeUntil')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            :data-name="$t('widget.to.activeOn')"
                                            v-if="displayElement('show-active-on')"
                                        >
                                            <span
                                                >{{ $t('widget.to.activeOn')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            :data-name="$t('widget.to.product')"
                                            :class="{'d-block': displayElement('table-column-product-d-block')}"
                                            v-if="!displayElement('hide-tariff-title')"
                                        >
                                            <span
                                                >{{ $t('widget.to.product')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            :data-name="$t('widget.to.basePrice')"
                                        >
                                            <span
                                                >{{ $t('widget.to.basePrice')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            :data-name="$t('widget.to.workingPrice')"
                                        >
                                            <span
                                                >{{ $t('widget.to.workingPrice')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            v-if="!displayElement('hide-bonus')"
                                            :data-name="$t('widget.to.bonus')"
                                        >
                                            <span
                                                >{{ $t('widget.to.bonus')
                                                }}</span
                                            >
                                        </th>
                                        <th
                                            v-if="!displayElement('hide-instant-bonus')"
                                            :data-name="$t('widget.to.instantBonus')"
                                        >
                                            <span
                                                >{{ $t('widget.to.instantBonus')
                                                }}</span
                                            >
                                        </th>
                                    </tr>
                                    <tr
                                        v-for="tariff in listSorted"
                                        :class="{'active': tariff.active, 'future': isTariffInFuture(tariff)}"
                                    >
                                        <td
                                            :data-name="$t('widget.to.activeFrom')"
                                            v-if="!displayElement('show-active-on')"
                                        >
                                            {{ tariff.activeFrom | date }}
                                        </td>
                                        <td
                                            :data-name="$t('widget.to.activeUntil')"
                                            v-if="!displayElement('show-active-on')"
                                        >
                                            {{ tariff.activeUntil | date }}
                                        </td>
                                        <td
                                            :data-name="$t('widget.to.activeOn')"
                                            v-if="displayElement('show-active-on')"
                                        >
                                            {{ tariff.activeFrom | monthYearHum
                                            }}
                                        </td>
                                        <td
                                            v-if="!displayElement('hide-tariff-title')"
                                            data-name="mobile-main"
                                            @click="activate(tariff)"
                                        >
                                            {{ tariff.title }}
                                        </td>
                                        <td
                                            :data-name="$t('widget.to.basePrice')"
                                        >
                                            <span
                                                v-if="tariff.basePrice !== 0 && tariff.basePrice !== null"
                                            >
                                                {{ tariff.basePrice | euro }} {{
                                                $t('euro') }}/{{ $t('year') }}
                                                <template
                                                    v-if="showPriceInformationAsterisk"
                                                    >*<sup>1</sup></template
                                                >
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td
                                            :data-name="$t('widget.to.workingPrice')"
                                        >
                                            <span
                                                v-if="tariff.workingPrice !== 0 && tariff.workingPrice !== null"
                                            >
                                                <template
                                                    v-for="workingPrice in tariff.workingPrices"
                                                >
                                                    <template
                                                        v-if="tariff.workingPrices.length > 1"
                                                        >{{
                                                        workingPrice.registerName
                                                        }}:
                                                    </template>
                                                    {{
                                                    workingPrice.workingPriceBrutto
                                                    | euro }} {{ commodityPrice
                                                    }}
                                                    <br
                                                        v-if="tariff.workingPrices.length > 1"
                                                    />
                                                </template>
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td
                                            v-if="!displayElement('hide-bonus')"
                                            :data-name="$t('widget.to.bonus')"
                                        >
                                            <span
                                                v-if="tariff.productBonus !== 0 && tariff.productBonus !== null"
                                            >
                                                <template
                                                    v-if="displayElement('do-not-format-bonus')"
                                                    >{{ tariff.productBonus
                                                    }}</template
                                                >
                                                <template v-else
                                                    >{{ tariff.productBonus |
                                                    euroFull }} {{ $t('euro')
                                                    }}</template
                                                >
                                            </span>
                                            <span
                                                v-else-if="tariff.productBonusPercent !== 0 && tariff.productBonusPercent !== null"
                                            >
                                                {{ tariff.productBonusPercent |
                                                euroFull }}%
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td
                                            v-if="!displayElement('hide-instant-bonus')"
                                            :data-name="$t('widget.to.instantBonus')"
                                        >
                                            <span
                                                v-if="tariff.instantBonus !== 0 && tariff.instantBonus !== null"
                                            >
                                                <template
                                                    v-if="displayElement('do-not-format-instant-bonus')"
                                                    >{{ tariff.instantBonus
                                                    }}</template
                                                >
                                                <template v-else
                                                    >{{ tariff.instantBonus |
                                                    euroFull }} {{ $t('euro')
                                                    }}</template
                                                >
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
