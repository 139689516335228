import vue from 'vue';
import vuex from 'vuex';

import Auth from './modules/Auth';
import Contracts from './modules/Contracts';
import Contact from './modules/Contact';
import Customer from './modules/Customer';
import Reading from './modules/Reading';
import Payment from './modules/Payment';
import Tariff from './modules/Tariff';
import Settings from './modules/Settings';
import Cookie from './modules/Cookie';
import Protector from './modules/Protector';
import Dashboard from './modules/Dashboard';

vue.use(vuex);

export default new vuex.Store({
    strict: true,
    modules: {
        auth: Auth,
        contracts: Contracts,
        contact: Contact,
        customer: Customer,
        reading: Reading,
        tariff: Tariff,
        payment: Payment,
        settings: Settings,
        cookie: Cookie,
        protector: Protector,
        dashboard: Dashboard,
    },
});
