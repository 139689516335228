import { injectable } from 'inversify';
import Tracking from '@/interfaces/Tracking';

@injectable()
export default class TrackingProvider implements Tracking {
    public registerStep1Successful(): void {
        this.pushEvent('Registrierung', 'Schritt 1');
    }

    public registerStep2Successful(): void {
        this.pushEvent('Registrierung', 'Schritt 2');
    }

    public registerStep3Successful(): void {
        this.pushEvent('Registrierung', 'Schritt 3');
    }

    public registerStep1Failed(): void {
        this.pushEvent('Registrierung', 'Schritt 1 - Fehlgeschlagen');
    }

    public registerStep1Url(): void {
        this.pushEvent('Registrierung', 'Schritt 1 - URL');
    }

    public registerStep3Direct(): void {
        this.pushEvent('Registrierung', 'Schritt 3 - Direct');
    }

    public registerStep2Failed(): void {
        this.pushEvent('Registrierung', 'Schritt 2 - Fehlgeschlagen');
    }

    public registerStep3Failed(): void {
        this.pushEvent('Registrierung', 'Schritt 3 - Fehlgeschlagen');
    }

    public registerSuccessful(): void {
        this.pushEvent('Registrierung', 'Erfolgreich');
    }

    public confirmDataAfterRegister(): void {
        this.pushEvent('Registrierung', 'Kundendaten Validierung');
    }

    public changeBillingAddress(): void {
        this.pushEvent(
            'Element Einblenden',
            'Stammdatenänderung Rechnungsanschrift'
        );
    }

    public showAdvancePaymentChange(): void {
        this.pushEvent('Element Einblenden', 'Abschlagsänderung');
    }

    public hideAdvancePaymentChange(): void {
        this.pushEvent('Element Ausblenden', 'Abschlagsänderung');
    }

    public postboxGetMore(): void {
        this.pushEvent('Postbox', 'Mehr Laden');
    }

    public showContractAccount(): void {
        this.pushEvent('Element Einblenden', 'Vertragskonto');
    }

    public hideContractAccount(): void {
        this.pushEvent('Element Ausblenden', 'Vertragskonto');
    }

    public downloadCustomerCommunication(title): void {
        this.pushEvent('Download', 'Kundenkommunikation', title);
    }

    public masterDataChangesAdvanceAdjustment(): void {
        this.pushEvent('Stammdatenänderungen', 'Abschlagsanpassung');
    }

    public downloadInvoice(invoiceNumber): void {
        this.pushEvent('Download', 'Rechnung', invoiceNumber);
    }

    public showTariffDetails(): void {
        this.pushEvent('Element Einblenden', 'Tarifdetails');
    }

    public hideTariffDetails(): void {
        this.pushEvent('Element Ausblenden', 'Tarifdetails');
    }

    public showMasterDataChangeInvoiceAddress(): void {
        this.pushEvent(
            'Element Einblenden',
            'Stammdatenänderung Rechnungsanschrift'
        );
    }

    public hideMasterDataChangeInvoiceAddress(): void {
        this.pushEvent(
            'Element Ausblenden',
            'Stammdatenänderung Rechnungsanschrift'
        );
    }

    public showMasterDataChangeCustomerData(): void {
        this.pushEvent('Element Einblenden', 'Stammdatenänderung Kundendaten');
    }

    public hideMasterDataChangeCustomerData(): void {
        this.pushEvent('Element Ausblenden', 'Stammdatenänderung Kundendaten');
    }

    public showMeterReading(): void {
        this.pushEvent('Element Einblenden', 'Zählerstandsmeldung');
    }

    public hideMeterReading(): void {
        this.pushEvent('Element Ausblenden', 'Zählerstandsmeldung');
    }

    public masterDataChangesMeterReading(): void {
        this.pushEvent('Stammdatenänderungen', 'Zählerstand');
    }

    public masterDataChangesPaymentData(): void {
        this.pushEvent('Stammdatenänderungen', 'Zahldaten');
    }

    public showMasterDataChangePaymentDate(): void {
        this.pushEvent('Element Einblenden', 'Stammdatenänderung Zahldate');
    }

    public hideMasterDataChangePaymentDate(): void {
        this.pushEvent('Element Ausblenden', 'Stammdatenänderung Zahldate');
    }

    public masterDataChangesCustomerData(): void {
        this.pushEvent('Stammdatenänderungen', 'Kundendaten');
    }

    public qcellsOptInSuccessful(): void {
        const dataLayerTag = 'dataLayer';
        if (typeof window[dataLayerTag] !== 'undefined') {
            window[dataLayerTag].push({
                event: 'opt-in-successful',
                data: { value: true },
            });
        }
    }

    public qcellsNewsletterSuccessful(): void {
        const dataLayerTag = 'dataLayer';
        if (typeof window[dataLayerTag] !== 'undefined') {
            window[dataLayerTag].push({
                event: 'newsletter-successful',
                data: { value: true },
            });
        }
    }

    public qcellsTariffChangeSuccessful(): void {
        const dataLayerTag = 'dataLayer';
        if (typeof window[dataLayerTag] !== 'undefined') {
            window[dataLayerTag].push({
                event: 'tarif-change-successful',
                data: { value: true },
            });
        }
    }

    private pushEvent(group, event, ...args) {
        // @ts-ignore
        if (!window.Cypress) {
            const gtagKey = 'gtag';
            const dataLayerTag = 'dataLayer';
            // window._paq.push(['trackEvent', group, event, ...args]);
            if (typeof window[gtagKey] !== 'undefined') {
                window[gtagKey]('event', group + ': ' + event, { ...args });
            } else if (typeof window[dataLayerTag] !== 'undefined') {
                window[dataLayerTag].push({
                    event: group + ': ' + event,
                    data: { ...args },
                });
            }
        }
    }
}
