import WithRender from './wdenergy-us.html';
import { default as Component } from 'vue-class-component';
import { ElliHeaderV2 as HeaderV2 } from '@/components/template/header-v2/custom/elli';
import { Footer } from './../../../template/footer';
import { ResetPasswordWidget } from '@/components/widgets/reset-password-widget';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { RegisterPage } from '@/components/pages/auth/register';
import { AuthPage as AuthPageParent } from '@/components/pages/auth/auth-page';
import { RestoreTwoFactorAuthentication } from '@/components/pages/auth/2fa/restore';

import { LoginPage } from '@/components/pages/auth/login-page';
import { OneTimePassword } from '@/components/pages/auth/one-time-password';
import { VerifyWidget } from '@/components/widgets/verify-widget';
import { OtpHintPopupWidget } from '@/components/widgets/otp-hint-popup-widget';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
        LoginPage,
        RegisterPage,
        OneTimePassword,
        ResetPasswordWidget,
        'cookie-banner-widget': CookieBannerWidget,
        VerifyWidget,
        'restore-two-factor-authentication': RestoreTwoFactorAuthentication,
        'otp-hint-popup-widget': OtpHintPopupWidget,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
                {
                    name: 'keywords',
                    content:
                        'VW Naturstrom Kundenportal, Volkswagen Naturstrom Kundenportal, Elli Naturstrom Kundenportal',
                },
            ],
        };
    },
})
export class AuthPageWdenergyUs extends AuthPageParent {}
