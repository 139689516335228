<div class="m-form-agree__bg">
    <div class="m-form-agree__container">
        <div class="m-form-agree__close">
            <i @click="closePopup" class="fa fa-times"></i>
        </div>
        <div class="m-form-agree__title">{{ $t('widget.p.title') }}</div>
        <div class="m-form-agree__body">
            <div class="m-form-agree__body-checkbox">
                <label for="dashboard-popup_checkbox">
                    <input
                        type="checkbox"
                        id="dashboard-popup_checkbox"
                        v-model="isAgree"
                    />
                </label>
            </div>
            <div class="m-form-agree__body-text">
                {{ $t('widget.p.text') }}
                <a :href='"mailto:" + $t("widget.p.mail")'>
                    {{ $t('widget.p.mail') }}
                </a>
                {{ $t('widget.p.end') }}
            </div>
        </div>
        <template v-if="$store.state.settings.optInAllowDeclineButton">
            <div class="d-flex justify-content-between">
                <button
                    v-if="$store.state.settings.optInAllowDeclineButton"
                    class="btn btn-primary mt-auto m-form-agree__btn"
                    @click="declineOptIn"
                >
                    {{ $t('widget.p.button.decline')}}
                </button>
                <button
                    class="btn btn-primary mt-auto m-form-agree__btn"
                    :disabled="!isAgree"
                    @click="updateCustomerDetails"
                >
                    <i class="fa fa-location-arrow"></i>
                    {{ $t('widget.p.button')}}
                </button>
            </div>
        </template>
        <button
            v-else
            data-test="ads-popup-button"
            class="btn btn-primary mt-auto m-form-agree__btn"
            :disabled="!isAgree"
            @click="updateCustomerDetails"
        >
            <i class="fa fa-location-arrow"></i>
            {{ $t('widget.p.button')}}
        </button>
    </div>
</div>
