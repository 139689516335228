import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './schweizstrom.html';

import { Watch } from 'vue-property-decorator';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import moment from 'moment';

import Auth from '@/interfaces/Auth';
import { Header } from '@/components/template/header';

@WithRender
@Component({})
export class SchweizstromHeader extends Header {
    public timerDisplay = moment().set({ hour: 0, minute: 15, second: 0 });
    public displayTimeOut = false;
    public interval = 0;
    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    get showItem(): void {
        return localStorage.setItem('show-popup-text', '1');
    }

    protected mounted(): void {
        if (!localStorage.getItem('show-popup-text')) {
            localStorage.setItem('show-popup-text', '1');
        }

        if (localStorage.getItem('show-popup-text') === '1') {
            const iconClose = document.querySelector(
                '.m-stack--popup-text-close'
            );
            const toggler = document.querySelector('.m-brand__toggler');
            const textPopup = document.querySelector('.m-stack--popup-text');

            if (window.innerWidth <= 1024 && textPopup instanceof HTMLElement) {
                textPopup.setAttribute('data-show', '');
            }

            if (toggler instanceof HTMLElement) {
                toggler.addEventListener('click', () => {
                    if (
                        textPopup instanceof HTMLElement &&
                        localStorage.getItem('show-popup-text') !== '0'
                    ) {
                        textPopup.setAttribute('data-show', '');
                    }
                });
            }

            if (iconClose instanceof HTMLElement) {
                iconClose.addEventListener('click', () => {
                    if (textPopup instanceof HTMLElement) {
                        textPopup.removeAttribute('data-show');
                        localStorage.setItem('show-popup-text', '0');
                    }
                });
            }
        }
    }

    get isNotAuthPage(): boolean {
        return this.$route.path !== '/' && this.$route.path !== '/register';
    }

    get brandAuth(): Record<any, any> {
        return { 'm-brand--auth': !this.isNotAuthPage };
    }

    get showLogoutSuccessfulMessage(): boolean {
        return this.$store.state.settings.showLogoutSuccessfulMessage;
    }

    @Watch('displayTimeOut')
    protected onStartTimeOut(newVal: boolean): void {
        if (newVal) {
            const startTimerDisplay = this.timerDisplay;
            this.interval = window.setInterval(() => {
                this.timerDisplay = moment(
                    this.timerDisplay.subtract(1, 'seconds')
                );
                if (
                    startTimerDisplay.diff(this.timerDisplay, 'minutes') === 15
                ) {
                    this.displayTimeOut = false;
                    window.clearInterval(this.interval);
                    this.timerDisplay = startTimerDisplay;
                    this.logout();
                }
            }, 1000);
        } else if (!newVal && this.interval) {
            window.clearInterval(this.interval);
        }
    }

    private logout(): void {
        if (this.showLogoutSuccessfulMessage) {
            localStorage.setItem('wd-portal-show-goodbye-message', '1');
        }

        this.auth.logout(this.$store.state.settings.idpLogoutUrl);
    }
}
