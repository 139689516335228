<div class="m-portlet m-portlet--mobile fleet-manager-bonus">
    <div class="manager-bonus__first-step" v-if="step === 1">
        <div class="manager-bonus-title">
            <div class="icon"></div>
            THG-Prämien verfügbar!
        </div>
        <div>Vermarkten Sie jetzt Ihre THG-Quote(n) für das Jahr 2024.</div>
        <div>
            <button
                :disabled="productData.length === 0"
                class="btn btn-primary d-block"
                @click.prevent="goNext"
            >
                Jetzt Prämie sichern
            </button>
        </div>
    </div>
    <div class="manager-bonus__second-step" v-if="step === 2">
        <div
            class="d-flex align-items-center justify-content-between step-title"
        >
            <span class="box-title static-title">
                {{ $t('widget.thg.fleet.manager.bonus.step2.title') }}
            </span>
            <div class="m-aside-modal__close" @click="closeStep()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>
        <div>
            <span
                >{{ $t('widget.thg.fleet.manager.bonus.step2.subtitle') }}</span
            >
        </div>
        <div class="red_block">
            <div class="red_block_left">
                <div class="red_block-title">THG-Prämien 2024</div>
                <div class="red_block-items">
                    <div
                        v-for="product in businessProduct.elliNonCommodityProcurementPrices"
                        class="red_block-item"
                    >
                        <div>
                            <span
                                >{{
                                $t('widget.thg.fleet.manager.bonus.vehicle.type.'
                                + product.classification +'.class') }}</span
                            >
                            ({{
                            $t('widget.thg.fleet.manager.bonus.vehicle.type.' +
                            product.classification +'.label') }})
                        </div>
                        <div>
                            <span
                                >{{ product.net | euro }} {{ $t('euro.symbol')
                                }}</span
                            >
                            netto
                        </div>
                    </div>
                </div>
            </div>
            <div class="red_block_right">
                <div class="red_block-title">
                    Die angezeigten THG-Prämienbeträge sind für den aktuellen
                    Tag garantiert
                </div>
                <div>
                    <ul>
                        <li>
                            Die THG-Prämienhöhe ändert sich je nach
                            Marktsituation
                        </li>
                        <li>
                            Sobald Sie Ihre Fahrzeugscheine erfolgreich
                            hochgeladen haben, ist Ihnen der angezeigte Preis
                            garantiert
                        </li>
                        <li>
                            Sollten Sie zu einem späteren Zeitpunkt Ihre
                            THG-Quoten vermarkten wollen, können sich die
                            Beträge ggf. geändert haben
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="btn btn-primary d-block" @click="goNext">
                {{ $t('widget.thg.fleet.manager.bonus.next') }}
            </button>
        </div>
    </div>
    <div class="manager-bonus__third-step" v-if="step === 3">
        <div
            class="d-flex align-items-center justify-content-between step-title"
        >
            <span class="box-title static-title">
                {{ $t('widget.thg.fleet.manager.bonus.step3.title') }}
            </span>
            <div class="m-aside-modal__close" @click="closeStep()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>
        <div>
            <span
                >{{ $t('widget.thg.fleet.manager.bonus.step3.subtitle') }}</span
            >
        </div>
        <div class="upload-box">
            <div class="upload-box_left">
                <div class="upload-box_title">
                    <div class="icon-individual"></div>
                    <div class="title-text">
                        {{
                        $t('widget.thg.fleet.manager.bonus.individual.documents.title')
                        }}
                    </div>
                </div>
                <div>
                    <ul>
                        <li
                            v-html="$t('widget.thg.fleet.manager.bonus.individual.documents.requirements.1')"
                        ></li>
                        <li
                            v-html="$t('widget.thg.fleet.manager.bonus.individual.documents.requirements.2')"
                        ></li>
                    </ul>
                </div>
                <div class="or-divider">
                    <span>{{ $t('widget.thg.fleet.manager.bonus.or') }}</span>
                </div>
                <div class="upload-box_title">
                    <div class="icon-bunch"></div>
                    <div class="title-text">
                        {{
                        $t('widget.thg.fleet.manager.bonus.bunch.documents.title')
                        }}
                    </div>
                </div>
                <div>
                    <ul>
                        <li
                            v-html="$t('widget.thg.fleet.manager.bonus.bunch.documents.requirements.1')"
                        ></li>
                        <li
                            v-html="$t('widget.thg.fleet.manager.bonus.bunch.documents.requirements.2')"
                        ></li>
                        <li
                            v-html="$t('widget.thg.fleet.manager.bonus.bunch.documents.requirements.3')"
                        ></li>
                    </ul>
                </div>
                <div>
                    {{ $t('widget.thg.fleet.manager.bonus.documents.notice') }}
                </div>
            </div>
            <div class="upload-box_right">
                <div class="upload-title">
                    {{
                    $t('widget.thg.fleet.manager.bonus.documents.upload.title',
                    {count: documents.length}) }}
                    <div v-if="uploadingToS3" class="loader"></div>
                </div>
                <div class="uploaded-files">
                    <template v-if="documents.length === 0">
                        <span
                            >{{
                            $t('widget.thg.fleet.manager.bonus.documents.no.files')
                            }}</span
                        >
                    </template>
                    <template v-else>
                        <div v-for="(document, index) in documents">
                            <div class="uploaded-file">
                                <div class="uploaded-file__name">
                                    {{ document.name }}
                                </div>
                                <div
                                    class="uploaded-file__remove"
                                    @click="removeFile(index)"
                                ></div>
                            </div>
                        </div>
                    </template>
                </div>
                <div>
                    <div v-if="fileUploadError" class="error-text">
                        {{
                        $t('widget.thg.fleet.manager.bonus.documents.file.error')
                        }}
                    </div>
                    <div
                        class="file-requirements"
                        :class="{error: fileUploadError}"
                    >
                        {{
                        $t('widget.thg.fleet.manager.bonus.documents.files.requirements')
                        }}
                    </div>
                    <div>
                        <label for="file-upload" class="file-upload">
                            <input
                                type="file"
                                id="file-upload"
                                accept="application/pdf,image/jpeg"
                                multiple
                                ref="fileList"
                                class="upload-input"
                                @change="processFile($event)"
                                :disabled="uploadingToS3"
                            />
                            {{$t('widget.thg.fleet.manager.bonus.documents.select.file')}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="confirm-actions">
            <div
                class="confirm-action"
                :class="{error: errorVehiclesOwnership}"
            >
                <label>
                    <input
                        v-model="confirmVehiclesOwnership"
                        type="checkbox"
                        @click="errorVehiclesOwnership = false"
                        class="checkbox-button__input"
                    />
                    <span class="checkbox-button__control"></span>
                    <span
                        v-html="$t('widget.thg.fleet.manager.bonus.documents.checbox.text1', {company: companyName})"
                    ></span>
                </label>
            </div>
            <div class="confirm-action" :class="{error: errorAgb}">
                <label>
                    <input
                        v-model="confirmAgb"
                        type="checkbox"
                        @click="errorAgb = false"
                        class="checkbox-button__input"
                    />
                    <span class="checkbox-button__control"></span>
                    <span
                        v-html="$t('widget.thg.fleet.manager.bonus.documents.checbox.text2')"
                    ></span>
                </label>
            </div>
        </div>
        <div class="actions">
            <vue-button-spinner
                class="btn btn-primary"
                :is-loading="isLoading"
                :disabled="isLoading || documents.length === 0"
            >
                <span data-test="btn-helper-upload" @click="uploadFiles"
                    >{{ $t('widget.thg.fleet.manager.bonus.start.uploading')
                    }}</span
                >
            </vue-button-spinner>
        </div>
    </div>
    <div class="manager-bonus__final-step" v-if="step === 4">
        <div class="header-row">
            <div class="step-title">
                <span class="box-title static-title">
                    {{ $t('widget.thg.fleet.manager.bonus.step.final.title') }}
                </span>
                <div>
                    <span
                        >{{
                        $t('widget.thg.fleet.manager.bonus.step.final.subtitle')
                        }}</span
                    >
                </div>
            </div>
            <div class="do-not-close-notice" v-if="isLoading">
                <div class="do-not-close-notice__icon">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_1919_2030)">
                            <path
                                d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
                                fill="#FF0F0F"
                            />
                            <path
                                d="M12 5C11.7348 5 11.4804 5.10536 11.2929 5.29289C11.1054 5.48043 11 5.73478 11 6V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8947 14.5196 13 14.2652 13 14V6C13 5.73478 12.8947 5.48043 12.7071 5.29289C12.5196 5.10536 12.2652 5 12 5Z"
                                fill="#FF0F0F"
                            />
                            <path
                                d="M13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18Z"
                                fill="#FF0F0F"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1919_2030">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div
                    class="do-not-close-notice__text"
                    v-html="$t('widget.thg.fleet.manager.bonus.final.step.notice')"
                ></div>
            </div>
        </div>
        <div class="status-info" :class="{loading: isLoading}">
            <div class="chevron"></div>
            <div>
                <span v-if="isLoading" class="loader"></span>
                <span v-else class="success-icon">
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_1866_7953)">
                            <path
                                d="M6.85476 18.5795C6.25866 18.5798 5.68697 18.3428 5.26581 17.921L0.461897 13.1188C-0.0550073 12.6018 -0.0550073 11.7636 0.461897 11.2465C0.978967 10.7296 1.81714 10.7296 2.33421 11.2465L6.85476 15.7671L18.8142 3.8076C19.3313 3.2907 20.1695 3.2907 20.6865 3.8076C21.2034 4.32467 21.2034 5.16284 20.6865 5.67991L8.44371 17.921C8.02255 18.3428 7.45086 18.5798 6.85476 18.5795Z"
                                fill="#1EA97C"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1866_7953">
                                <rect
                                    width="21"
                                    height="21"
                                    fill="white"
                                    transform="translate(0.0742188 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                <span
                    v-if="isLoading"
                    v-html="$t('widget.thg.fleet.manager.bonus.status.processing')"
                ></span>
                <span
                    v-else
                    v-html="$t('widget.thg.fleet.manager.bonus.status.success', {count: completedFiles.length})"
                ></span>
            </div>
        </div>
        <div class="main-content">
            <div class="main-content__left">
                <div class="main-content__title">
                    {{ $t('widget.thg.fleet.manager.bonus.table.1.title',
                    {count: completedFiles.length}) }}
                </div>
                <div class="datatable">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        <span
                                            >{{
                                            $t('widget.thg.fleet.manager.bonus.table.header.fin')
                                            }}</span
                                        >
                                        <span>
                                            <svg
                                                width="12"
                                                height="6"
                                                viewBox="0 0 12 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                                    fill="#575962"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span
                                            >{{
                                            $t('widget.thg.fleet.manager.bonus.table.header.vehicle.class')
                                            }}</span
                                        >
                                        <span>
                                            <svg
                                                width="12"
                                                height="6"
                                                viewBox="0 0 12 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                                    fill="#575962"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span
                                            >{{
                                            $t('widget.thg.fleet.manager.bonus.table.header.price')
                                            }}</span
                                        >
                                        <span>
                                            <svg
                                                width="12"
                                                height="6"
                                                viewBox="0 0 12 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                                    fill="#575962"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in completedFiles">
                                <td class="fin">{{ item.vin }}</td>
                                <td>{{ item.class }}</td>
                                <td>
                                    {{ item.price | euro}} {{ $t('euro.symbol')
                                    }}
                                </td>
                            </tr>
                            <tr v-if="completedFiles.length === 0">
                                <td class="fin">...</td>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="isLoading" class="loading-info">
                    <span class="loader"></span>
                    <span
                        >{{ $t('widget.thg.fleet.manager.bonus.processing')
                        }}</span
                    >
                </div>
            </div>
            <div class="main-content__right">
                <div class="main-content__title">
                    {{ $t('widget.thg.fleet.manager.bonus.table.2.title',
                    {count: errorFiles.length}) }}
                </div>
                <div class="datatable">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        <span
                                            >{{
                                            $t('widget.thg.fleet.manager.bonus.table.header.file')
                                            }}</span
                                        >
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span
                                            >{{
                                            $t('widget.thg.fleet.manager.bonus.table.header.pages')
                                            }}</span
                                        >
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in errorFiles">
                                <td>{{ item.name }}</td>
                                <td>{{ item.page }}</td>
                            </tr>
                            <tr v-if="errorFiles.length === 0">
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="isLoading" class="loading-info">
                    <span class="loader"></span>
                    <span
                        >{{ $t('widget.thg.fleet.manager.bonus.processing')
                        }}</span
                    >
                </div>
                <div v-else-if="errorFiles.length > 0" class="loading-info">
                    <span
                        >{{ $t('widget.thg.fleet.manager.bonus.try.later')
                        }}</span
                    >
                </div>
            </div>
        </div>
        <div class="actions" v-if="!isLoading">
            <button class="btn btn-primary d-block" @click="goNext">
                {{ $t('widget.thg.fleet.manager.bonus.final') }}
            </button>
        </div>
    </div>
    <div class="manager-bonus__success" v-if="step === 5">
        <div class="m-aside-modal m-aside-modal__wrap">
            <div class="m-aside-modal__body">
                <div class="success-step-content">
                    <div class="success-step-content__icon">
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M32.5995 17.6925L21.0837 29.2075C21.0063 29.2852 20.9142 29.3469 20.8129 29.389C20.7116 29.4311 20.603 29.4527 20.4933 29.4527C20.3836 29.4527 20.2749 29.4311 20.1736 29.389C20.0723 29.3469 19.9803 29.2852 19.9028 29.2075L15.4495 24.75C15.3721 24.6723 15.2801 24.6106 15.1788 24.5685C15.0774 24.5264 14.9688 24.5048 14.8591 24.5048C14.7494 24.5048 14.6408 24.5264 14.5394 24.5685C14.4381 24.6106 14.3461 24.6723 14.2687 24.75C14.1909 24.8274 14.1293 24.9194 14.0872 25.0208C14.0451 25.1221 14.0234 25.2307 14.0234 25.3404C14.0234 25.4501 14.0451 25.5588 14.0872 25.6601C14.1293 25.7614 14.1909 25.8534 14.2687 25.9308L18.7237 30.385C19.1936 30.8541 19.8305 31.1175 20.4945 31.1175C21.1585 31.1175 21.7954 30.8541 22.2653 30.385L33.7803 18.8725C33.858 18.7951 33.9195 18.7031 33.9615 18.6019C34.0035 18.5007 34.0252 18.3921 34.0252 18.2825C34.0252 18.1729 34.0035 18.0644 33.9615 17.9631C33.9195 17.8619 33.858 17.7699 33.7803 17.6925C33.7029 17.6148 33.6109 17.5531 33.5096 17.511C33.4083 17.4689 33.2996 17.4473 33.1899 17.4473C33.0802 17.4473 32.9716 17.4689 32.8703 17.511C32.7689 17.5531 32.6769 17.6148 32.5995 17.6925Z"
                                fill="#1EA97C"
                            />
                            <rect
                                x="0.75"
                                y="0.75"
                                width="46.5"
                                height="46.5"
                                rx="23.25"
                                stroke="#1EA97C"
                                stroke-width="1.5"
                            />
                        </svg>
                    </div>
                    <div class="success-step-content__title">
                        {{ $t('widget.thg.fleet.manager.bonus.success.title') }}
                    </div>
                    <div class="success-step-content__list">
                        <ul>
                            <li>
                                {{
                                $t('widget.thg.fleet.manager.bonus.success.text.1')
                                }}
                            </li>
                            <li>
                                {{
                                $t('widget.thg.fleet.manager.bonus.success.text.2')
                                }}
                            </li>
                            <li>
                                {{
                                $t('widget.thg.fleet.manager.bonus.success.text.3')
                                }}
                            </li>
                        </ul>
                    </div>
                    <div class="actions">
                        <button
                            class="btn btn-primary d-block"
                            @click="resetWidget"
                        >
                            {{
                            $t('widget.thg.fleet.manager.bonus.success.button')
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
