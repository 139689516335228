import { CancellationWidget } from '../cancellation-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli-thg-dealer.html';
import vSelect from 'vue-select';

@WithRender
@Component({
    components: {
        'v-select': vSelect,
    },
})
export class CancellationWidgetElliThgDealer extends CancellationWidget {}
