import WithRender from './ewii.html';
import { default as Component } from 'vue-class-component';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
import { Watch } from 'vue-property-decorator';

import vSelect from 'vue-select';
import { EwiiHeader } from '@/components/template/header/custom/ewii';
import { InvoiceWidget } from '@/components/widgets/invoice-widget/';
import { MeterHistoryWidget } from '@/components/widgets/meter-history-widget/';

@WithRender
@Component({
    components: {
        EwiiHeader,
        InvoiceWidget,
        'v-select': vSelect,
        MeterHistoryWidget,
    },
})
export class Dashboard extends DashboardParent {
    private selected = null;
    private currentTab = 1;
    private selectedParcelhouse = 60;

    protected created() {
        if (this.$route.params.id) {
            this.setSelectedContractId(parseInt(this.$route.params.id, 10));
        } else {
            this.currentTab = 1;
        }
    }

    @Watch('selected')
    protected updateData(contractId: number): void {
        if (+this.$route.params.id !== contractId) {
            window.history.pushState({}, '', '/dashboard/' + contractId);
        }
        this.$store.dispatch('contracts/setContractId', contractId);
        this.$store.dispatch('customer/getDetails');

        this.$store.dispatch('contracts/contracts');
        this.$store.dispatch('settings/load').then(() => {
            // data that should be loaded based on settings
            if (this.settings.adsBonusActive) {
                this.$store.dispatch('customer/adsBonusAvailable');
            }
        });
    }

    private setSelectedContractId(value) {
        this.selected = value;
    }

    private selectContract(contractId) {
        this.selected = contractId;
        this.currentTab = 0;
    }

    private toggleTab(tabNum) {
        this.currentTab = tabNum;
    }

    get activeTab() {
        return this.currentTab;
    }

    get widgetOptions() {
        return [
            { value: 60, label: 'Parcelhouse 60m2' },
            { value: 90, label: 'Parcelhouse 90m2' },
            { value: 120, label: 'Parcelhouse 120m2' },
            { value: 150, label: 'Parcelhouse 150m2' },
            { value: 180, label: 'Parcelhouse 180m2' },
        ];
    }

    get parcelhouseImage() {
        switch (this.selectedParcelhouse) {
            case 60:
                return '/img/evus/ewii/parcelhouse_60.png';
            case 90:
                return '/img/evus/ewii/parcelhouse_90.png';
            case 120:
                return '/img/evus/ewii/parcelhouse_120.png';
            case 150:
                return '/img/evus/ewii/parcelhouse_150.png';
            case 180:
                return '/img/evus/ewii/parcelhouse_180.png';
        }
    }
}
