<div class="col-12 mobile-modal__inner mobile-modal__inner--tariff-overview">
    <div class="m-portlet m-portlet--mobile border-radius-rb">
        <div class="m-portlet__body">
            <div
                data-test="tariff-overview-widget-close-button"
                @click="$emit('closeWidget')"
                class="fullwidthArea-close"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <i
                        v-if="displayElement('show-tariff-overview-icon')"
                        class="fa fa-tags tarifOverview-icon"
                    ></i>
                    <span
                        class="
                            tarifOverview-title
                            static-title static-title--custom
                        "
                        >{{ $t('widget.to.title') }}</span
                    >
                    <div class="mobile-modal__wrap">
                        <table
                            data-test="tariff-details-widget-tariff-overview-table"
                            :class="['table table-striped', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                            id="invoiceList-content"
                        >
                            <tbody>
                                <tr>
                                    <th :data-name="$t('widget.to.activeFrom')">
                                        <span
                                            >{{ $t('widget.to.activeFrom')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.to.activeUntil')"
                                    >
                                        <span
                                            >{{ $t('widget.to.activeUntil')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.to.product')"
                                        :class="{'d-block': displayElement('table-column-product-d-block')}"
                                    >
                                        <span
                                            >{{ $t('widget.to.product') }}</span
                                        >
                                    </th>
                                    <th :data-name="$t('widget.to.basePrice')">
                                        <span
                                            >{{ $t('widget.to.basePrice')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.to.workingPrice')"
                                    >
                                        <span
                                            >{{ $t('widget.to.workingPrice')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        v-if="!displayElement('hide-bonus')"
                                        :data-name="$t('widget.to.bonus')"
                                    >
                                        <span>{{ $t('widget.to.bonus') }}</span>
                                    </th>
                                    <th
                                        v-if="!displayElement('hide-instant-bonus')"
                                        :data-name="$t('widget.to.instantBonus')"
                                    >
                                        <span
                                            >{{ $t('widget.to.instantBonus')
                                            }}</span
                                        >
                                    </th>
                                </tr>
                                <tr
                                    v-for="tariff in listSorted"
                                    :class="{'active': tariff.active}"
                                >
                                    <td :data-name="$t('widget.to.activeFrom')">
                                        {{ tariff.activeFrom | date }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.to.activeUntil')"
                                    >
                                        {{ tariff.activeUntil | date }}
                                    </td>
                                    <td
                                        data-name="mobile-main"
                                        @click="activate(tariff)"
                                    >
                                        {{ tariff.title }}
                                    </td>
                                    <td :data-name="$t('widget.to.basePrice')">
                                        {{ tariff.basePrice | euro }} {{
                                        $t('euro') }}/{{ $t('year') }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.to.workingPrice')"
                                    >
                                        <template
                                            v-for="workingPrice in tariff.workingPrices"
                                        >
                                            <template
                                                v-if="tariff.workingPrices.length > 1"
                                                >{{ workingPrice.registerName
                                                }}:
                                            </template>
                                            {{ workingPrice.workingPriceBrutto |
                                            euro }} {{ commodityPrice }}
                                            <br
                                                v-if="tariff.workingPrices.length > 1"
                                            />
                                        </template>
                                    </td>
                                    <td
                                        v-if="!displayElement('hide-bonus')"
                                        :data-name="$t('widget.to.bonus')"
                                    >
                                        <span
                                            v-if="parseInt(tariff.productBonus, 10) !== 0"
                                            >{{ tariff.productBonus | euroFull
                                            }} {{ $t('euro') }}</span
                                        >
                                        <span
                                            v-else-if="parseInt(tariff.productBonusPercent, 10) !== 0"
                                            >{{ tariff.productBonusPercent |
                                            euroFull }}%</span
                                        >
                                        <span v-else>-</span>
                                    </td>
                                    <td
                                        v-if="!displayElement('hide-instant-bonus')"
                                        :data-name="$t('widget.to.instantBonus')"
                                    >
                                        <span
                                            v-if="parseInt(tariff.instantBonus, 10) !== 0"
                                            >{{ tariff.instantBonus | euroFull
                                            }} {{ $t('euro') }}</span
                                        >
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
