import Base from '@/mixins/base';
import { Component } from 'vue-property-decorator';
import WithRender from './elli.html';

import { ChangePaymentDataWidgetElliThgDealer } from './elli-thg-dealer';
import { ChangePaymentDataWidgetElliThg } from './elli-thg';
import { ChangePaymentDataWidget } from '../change-payment-data-widget';

@WithRender
@Component({
    components: {
        ChangePaymentDataWidgetElliThgDealer,
        ChangePaymentDataWidgetElliThg,
        ChangePaymentDataWidget,
    },
})
export class ChangePaymentDataWidgetElli extends Base {
    get widget() {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );
        if (contract) {
            if (this.isDealer(contract)) {
                return ChangePaymentDataWidgetElliThgDealer;
            }
            if (this.isThg(contract)) {
                return ChangePaymentDataWidgetElliThg;
            }
        }

        return ChangePaymentDataWidget;
    }
}
