import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Map from '../interfaces/Map';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class MapProvider extends PrototypeProvider implements Map {
    public async cities(
        commodity: string,
        term: string
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/cities/' + commodity + '?term=' + term,
            this.config
        );
    }

    public async streets(
        commodity: string,
        cityId: number
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/streets/' + commodity + '/' + cityId,
            this.config
        );
    }

    public async providers(commodity: string): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/providers/' + commodity, this.config);
    }
}
