<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-2">
                <i class="fa fa-coins monthlyDeposit-icon"></i>
            </div>
            <div class="col-10">
                <div id="monthlyDeposit-content" style="display: block">
                    <div class="monthlyDeposit-title static-title">
                        {{ $t('widget.d.discount') }}
                    </div>

                    <div class="monthlyDeposit-text">
                        <content-loader
                            v-if="!currentDeposit"
                            :width="20"
                            :height="20"
                            style="width: 20px; height: 12px"
                            data-test="CONTENT-LOADER"
                        ></content-loader>
                        <span v-else>{{ currentDeposit | euroFull }}</span>
                        {{ $t('euro') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
