import { default as Component } from 'vue-class-component';

// Call Render template.
import WithRender from './shell.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';

// Widgets
import { AgentViewWidget } from '../../../widgets/agent-view-widget';
import { WelcomeWidgetV2 } from '@/components/widgets/welcome-widget/welcome-widget-v2';
import { NonCommodityTariffDetailsWidget } from '@/components/widgets/non-commodity-tariff-details-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';

@WithRender
@Component({
    components: {
        // Widgets
        WelcomeWidgetV2,
        NonCommodityTariffDetailsWidget,
        'agent-view-widget': AgentViewWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})

// @ts-ignore
export class Dashboard extends DashboardParent {
    private toggleGroup2AndScroll(key: any) {
        this.toggleGroup2(key);
        this.$nextTick(() => {
            if (this.showGroup2[key]) {
                this.$scrollTo('#' + key);
            }
        });
    }

    private toggleGroup4AndScroll(key: any) {
        this.toggleGroup4(key);
        this.$nextTick(() => {
            if (this.showGroup4[key]) {
                this.$scrollTo('#' + key);
            }
        });
    }
}
