import { default as Component } from 'vue-class-component';
// Call Render template.
import WithRender from './elli.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
// Widgets
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget';
import { CancellationWidget } from '@/components/widgets/cancellation-widget';
import { EcarUploadWidget } from '@/components/widgets/ecar-upload-widget';
import { TariffChangeWidget } from '@/components/widgets/tariff-change-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({
    components: {
        // Widgets
        ChangeCustomerDataWidget,
        ChangeBillingDataWidget,
        CancellationWidget,
        EcarUploadWidget,
        TariffChangeWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})
export class Dashboard extends DashboardParent {
    get showTariffWidgetForce() {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).recommended.length === 0 &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList.length > 0 &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length > 0 &&
            this.contractStatus.statusCode <= 5000 &&
            !this.forceCloseRecommendationWidget
        );
    }
}
