import { default as Component } from 'vue-class-component';
import WithRender from './post-box-widget.html';
import { saveAs } from 'file-saver';

import * as Sentry from '@sentry/browser';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Auth from '../../../interfaces/Auth';
import Format from '../../../interfaces/Format';
import Contracts from '../../../interfaces/Contracts';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

import { ServiceWidget } from '../service-widget/service-widget';
import Tracking from '@/interfaces/Tracking';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {
        'service-widget': ServiceWidget,
    },
    filters: {
        datetime: formatProvider.datetime(),
        date: formatProvider.date(),
    },
})
export class PostBoxWidget extends Base {
    protected elements = {
        bsw: ['show-date-without-time', 'show-custom-agb'],
        hsg: ['show-download-button'],
    };

    private communicationsLimit = 5;
    private communicationsOffset = 0;
    private downloadErrors: Record<any, any> = [];

    protected created() {
        if (this.$store.state.contracts.contractId) {
            this.$store.dispatch('tariff/communications', {
                id: this.$store.state.contracts.contractId,
                limit: this.communicationsLimit,
                offset: this.communicationsOffset,
            });
        }
    }

    protected loadMore() {
        this.communicationsOffset =
            this.communicationsOffset + this.communicationsLimit;
        trackingProvider.postboxGetMore();
        this.$store.dispatch('tariff/communications', {
            id: this.$store.state.contracts.contractId,
            limit: this.communicationsLimit,
            offset: this.communicationsOffset,
        });
    }

    get communications() {
        const tariffState = this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        );
        return tariffState && tariffState.contract
            ? tariffState.contract.communications
            : [];
    }

    get communicationsErrorLoaded() {
        if (this.$store.state.contracts.contractId) {
            const state = this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            );
            return state && state.communicationsErrorLoaded
                ? state.communicationsErrorLoaded
                : false;
        }
    }

    get serviceWidgetPosition() {
        if (
            this.communications !== null &&
            typeof this.communications !== 'undefined' &&
            this.communications.length % 2 === 0
        ) {
            return 'm-timeline-1__item--left';
        } else {
            return 'm-timeline-1__item--right';
        }
    }

    get requiredDocumentsPosition() {
        if (
            this.communications !== null &&
            typeof this.communications !== 'undefined' &&
            this.communications.length % 2 === 0
        ) {
            return 'm-timeline-1__item--right';
        } else {
            return 'm-timeline-1__item--left';
        }
    }

    get isLoadedCommunications() {
        return (
            typeof this.communications === 'object' &&
            (this.communications.length === 0 || this.communications.length > 0)
        );
    }

    get allLoaded() {
        if (this.communications.length === 0) {
            return true;
        }

        return (
            typeof this.communications === 'object' &&
            this.communications.filter((element) => {
                return element.timelineLast === true;
            }).length > 0
        );
    }

    private checkHasDownloadError(communicationId: string) {
        return this.downloadErrors[communicationId] == true;
    }

    private addDownloadError(communicationId: string) {
        if (!this.downloadErrors[communicationId]) {
            this.downloadErrors[communicationId] = true;
        }
        this.$forceUpdate();
    }

    private downloadCustomerCommunication(communication) {
        if (this.checkHasDownloadError(communication.communicationId)) {
            return false;
        }
        const title = this.removeUmlaut(communication.title.replace(/\s/g, ''));
        if (authProvider.isAuthenticated()) {
            contractProvider
                .downloadCustomerCommunication(
                    this.$store.state.contracts.contractId,
                    communication.communicationId,
                    title
                )
                .catch((response) => {
                    this.addDownloadError(communication.communicationId);
                    return response;
                })
                .then(
                    async (response) => {
                        const isJsonBlob = (data) =>
                            data instanceof Blob &&
                            data.type === 'application/json';
                        const responseData =
                            isJsonBlob(response?.data) &&
                            typeof response?.data?.text !== 'undefined'
                                ? await response?.data?.text()
                                : response?.data || {};
                        const responseJson =
                            typeof responseData === 'string'
                                ? JSON.parse(responseData)
                                : responseData;
                        if (
                            responseJson &&
                            responseJson.success &&
                            responseJson.pdf
                        ) {
                            trackingProvider.downloadCustomerCommunication(
                                title
                            );
                            this.saveBlobAsFile(
                                responseJson.pdf,
                                responseJson.title + '.pdf',
                                'application/pdf'
                            );
                            return;
                        }
                    },
                    (error) => {
                        this.addDownloadError(communication.communicationId);
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }

    private downloadArchiveFile(id) {
        const title = this.getOnlyText(this.currentProduct.cmsAgb);
        contractProvider
            .downloadArchiveFile(
                this.$store.state.contracts.contractId,
                id,
                title
            )
            .then(
                async (response) => {
                    const isJsonBlob = (data) =>
                        data instanceof Blob &&
                        data.type === 'application/json';
                    const responseData =
                        isJsonBlob(response?.data) &&
                        typeof response?.data?.text !== 'undefined'
                            ? await response?.data?.text()
                            : response?.data || {};
                    const responseJson =
                        typeof responseData === 'string'
                            ? JSON.parse(responseData)
                            : responseData;
                    if (
                        responseJson &&
                        responseJson.success &&
                        responseJson.pdf
                    ) {
                        trackingProvider.downloadCustomerCommunication(title);
                        this.saveBlobAsFile(
                            responseJson.pdf,
                            responseJson.title + '.pdf',
                            'application/pdf'
                        );
                        return;
                    }
                },
                (error) => {
                    this.addDownloadError(id);
                    Sentry.captureException(new Error(error));
                }
            );
    }
}
