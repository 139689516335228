import Base from '@/mixins/base';
import WithRender from './otp-hint-popup-widget.html';
import { default as Component } from 'vue-class-component';

@WithRender
@Component
export class OtpHintPopupWidget extends Base {
    protected isInTimeframe = false;
    protected isHidden = false;

    public created(): void {
        this.isInTimeframe = new Date('2022-01-01') > new Date();
    }

    public hidePopup(): void {
        this.isHidden = true;
    }
}
