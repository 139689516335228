import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './form-group.html';
import { Prop, Provide, Inject } from 'vue-property-decorator';

@WithRender
@Component({
    components: {},
})
export class FormGroup extends Base {
    @Prop() public id!: string;
    @Prop() public form!: string;
    @Provide('id') private providedId = this.id;
    @Inject('form') public injectedForm!: string;

    get getForm(): string {
        if (this.form) {
            return this.form;
        } else if (this.injectedForm) {
            return this.injectedForm;
        } else {
            throw new Error('No form was provided');
        }
    }

    public disabled(): boolean {
        if (this.$store.state.settings.formSettings[this.getForm]) {
            if (
                this.$store.state.settings.formSettings[
                    this.getForm
                ].disabled.includes(this.id)
            ) {
                this.$emit('disabled', true);
                return true;
            }
        }
        this.$emit('disabled', false);
        return false;
    }

    public hidden(): boolean {
        if (this.$store.state.settings.formSettings[this.getForm]) {
            if (
                this.$store.state.settings.formSettings[
                    this.getForm
                ].hidden.includes(this.id)
            ) {
                this.$emit('hidden', false);
                return false;
            }
        }
        this.$emit('hidden', true);
        return true;
    }
}
