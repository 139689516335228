import { TariffChangeWidget as TariffChangeWidgetParent } from '../tariff-change-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './qcells.html';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Customer from '@/interfaces/Customer';
import Tracking from '@/interfaces/Tracking';
const customerProvider = IocContainer.get<Customer>(SERVICES.CUSTOMER);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({
    computed: {
        fullName() {
            return (
                this.$store.state.customer.salutation +
                ' ' +
                this.$store.state.customer.firstname +
                ' ' +
                this.$store.state.customer.surname
            );
        },
        address() {
            return (
                this.$store.state.customer.street +
                ' ' +
                this.$store.state.customer.houseNumber
            );
        },
    },
})
export class TariffChangeWidget extends TariffChangeWidgetParent {
    public showDetails = false;
    public allAdsAccepted = false;
    public termsAccepted = false;
    public showModal = false;
    public customer = this.$store.state.customer;
    public errorInformationModal: Record<any, any> = [];

    get tariff() {
        const tariffTmp = this.list[0] ?? null;
        if (tariffTmp) {
            tariffTmp.confirmationCheckbox = true;
            this.list[0].confirmationCheckbox = true;
        }
        return tariffTmp;
    }

    get tariffTitle() {
        if (this.tariff && this.tariff.title) {
            return this.tariff.title.replace('Q.ENERGY ', '');
        }
        return '';
    }

    get paymentDetails() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.bankingDetails;
    }

    get showOptInWidget() {
        return !this.$store.state.customer.adsMail ?? false;
    }

    get tariffAgb() {
        const agb = this.tariff.agb || '';
        return agb.replaceAll('<p>&nbsp;</p>', '');
    }

    get tariffBranding() {
        if (
            this.$t(
                'widget.tc.tariff.branding.' + this.tariff.productCode
            ).toLocaleString() !==
            'widget.tc.tariff.branding.' + this.tariff.productCode
        ) {
            return this.$t(
                'widget.tc.tariff.branding.' + this.tariff.productCode
            ).toLocaleString();
        }
        return this.$t('widget.tc.tariff.branding').toLocaleString();
    }

    protected openModal() {
        this.showModal = true;
    }

    protected closeModal() {
        this.showModal = false;
    }

    protected preventClose(e) {
        e.stopPropagation();
    }

    protected async submitCustom(tariff) {
        if (this.termsAccepted) {
            this.submit(tariff).then(() => {
                trackingProvider.qcellsTariffChangeSuccessful();
                this.closeModal();
                if (this.allAdsAccepted) {
                    customerProvider.updateAds(this.details.contractId);
                }
            });
        } else {
            this.errorInformationModal.push({
                key: '',
                message: this.$t(
                    'widget.tc.checkbox.required'
                ).toLocaleString(),
            });
        }
    }
}
