<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2 v-if="displayElement('header-v2')"></header-v2>
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside"
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        >
            <div id="auth_header">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
            v-if="displayAuth"
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-logo">
                        <a
                            v-if="displayElement('eoptimum-link-logo')"
                            href="https://www.eoptimum-home.de"
                            ><div
                                :style="loginLogo"
                                style="
                                    height: 100%;
                                    background-repeat: no-repeat;
                                "
                            ></div
                        ></a>
                        <a
                            v-else-if="displayElement('bsw-link-logo')"
                            href="https://berlinerstadtwerke.de/"
                        >
                            <div
                                :style="loginLogo"
                                style="
                                    height: 100%;
                                    background-repeat: no-repeat;
                                "
                            ></div>
                        </a>
                        <div v-else :style="loginLogo"></div>
                    </div>

                    <!-- Login Display -->
                    <div class="m-login__title">
                        <h1 v-if="isVerify">{{ $t('verify.title') }}</h1>
                        <h1 data-test="login-title" v-else-if="!isRegister">
                            {{ $t('login.title') }}
                        </h1>
                        <h1 v-else>{{ $t('registration.title') }}</h1>
                        <h2 v-if="displayElement('app-login-subheader')">
                            {{ $t('login.registration.subheader') }}
                        </h2>
                        <template v-if="!displayElement('app-login-subtitle')">
                            <h6
                                v-if="!isRegister && !isVerify && !isRestoreTwoFactorAuthentication"
                                v-html="getLoginSubTitle()"
                            ></h6>
                            <h6 v-else-if="isVerify">
                                {{ $t('verify.subtitle') }}
                            </h6>
                        </template>
                    </div>

                    <!-- LOGIN AREA-->
                    <login-page
                        v-if="isLoginPage"
                        :second="second"
                    ></login-page>

                    <!-- RESET PASSWORD AREA -->
                    <reset-password-widget
                        v-if="resetPassword"
                    ></reset-password-widget>

                    <!-- REGISTER AREA-->
                    <register-page v-if="isRegister"></register-page>

                    <!-- VERIFY WIDGET -->
                    <verify-widget v-if="isVerify"></verify-widget>

                    <!-- RESTORE TWO FACTOR AUTHENTICATION AREA -->
                    <restore-two-factor-authentication
                        v-if="isRestoreTwoFactorAuthentication"
                    ></restore-two-factor-authentication>

                    <!-- AUTHPLUS ONE TIME PASSWORD -->
                    <one-time-password
                        v-if="isOneTimePassword"
                    ></one-time-password>

                    <!-- AUTHPLUS FORCE CHANGE PASSWORD -->
                    <auth-plus-new-password-widget
                        v-if="isForceToChangePassword"
                    ></auth-plus-new-password-widget>

                    <div
                        class="m-login-footer-links"
                        v-if="displayElement('login-footer-links-bsw')"
                    >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/kundenportal/nutzungsbedingungen/"
                            target="_blank"
                            >{{ $t('login.footer.tos') }}</a
                        >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/kundenportal/datenschutzinformation/"
                            target="_blank"
                            >{{ $t('login.footer.data.protection') }}</a
                        >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/impressum/"
                            target="_blank"
                            >{{ $t('login.footer.impressum') }}</a
                        >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/kontakt/?your-recipient=Kundenportal"
                            target="_blank"
                            >{{ $t('login.footer.contact') }}</a
                        >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/fragen-und-antworten-rund-um-das-kundenportal"
                            target="_blank"
                            >{{ $t('login.footer.faq') }}</a
                        >
                    </div>
                    <div
                        class="m-login-footer-links text-end"
                        v-else-if="displayElement('login-footer-links-sachsenenergie')"
                    >
                        <a
                            class="m-link me-3"
                            :href="$t('footer.agb.url')"
                            target="_blank"
                            >{{ $t('footer.agb.label') }}</a
                        >
                        <a
                            class="m-link"
                            :href="$t('footer.imprint.url')"
                            target="_blank"
                            >{{ $t('footer.imprint.label') }}</a
                        >
                    </div>
                    <div class="m-login__note" v-if="showLogoutNote">
                        <div class="m-login__note-body">
                            <span>{{ $t('widget.lp.note.heading') }}</span>
                            <div>{{ $t('widget.lp.note.text')}}</div>
                        </div>
                    </div>
                </div>
                <!--end::Signin-->
            </div>
            <div v-if="locales.length > 1">
                <span
                    v-for="(locale, index) in locales"
                    @click="changeLocale(locale)"
                    >{{ $t('locale.'+locale) }}<span
                        v-if="index !== locales.length - 1"
                    >
                        -
                    </span></span
                >
            </div>
            <div class="app-footer-right">
                <app-footer
                    v-if="displayElement('app-footer-right')"
                ></app-footer>
                <div v-else-if="displayElement('login-footer-links-eoptimum')">
                    <div
                        class="col-xs-12 col-xl-9 col-md-12"
                        :class="[displayElement('links-col-sm-12-class') || displayElement('contacts-elli') ? 'col-sm-12' : 'col-sm-6']"
                    >
                        <ul
                            class="m-footer__nav m-nav m-nav--inline m--pull-right"
                        >
                            <li class="m-nav__item">
                                <a
                                    href="https://www.eoptimum-home.de/datenschutz"
                                    class="m-nav__link"
                                    target="_blank"
                                >
                                    <p class="m-nav__link-text">
                                        {{ $t('footer.privacy') }}
                                    </p>
                                </a>
                            </li>
                            <li class="m-nav__item">
                                <a
                                    href="https://www.eoptimum-home.de/impressum"
                                    class="m-nav__link"
                                    target="_blank"
                                >
                                    <p class="m-nav__link-text">
                                        {{ $t('footer.imprint') }}
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <app-footer v-if="displayElement('app-footer')"></app-footer>
    <!-- end::Footer -->
    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
    <otp-hint-popup-widget v-if="isOneTimePassword"></otp-hint-popup-widget>
</div>
