import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './deposit-overview-widget.html';

import Format from '@/interfaces/Format';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
    },
})
export class DepositOverviewWidget extends Base {
    protected elements = {
        bsw: ['sort-desc', 'coins-icon'],
    };

    get depositList(): Record<any, any> {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).depositList;
    }

    get mappedDepositList() {
        return this.depositList
            .map((obj) => ({ ...obj, active: false }))
            .sort(this.sortByDate);
    }

    private sortByDate(a, b): number {
        const dateA = new Date(a.activeFrom).valueOf();
        const dateB = new Date(b.activeFrom).valueOf();

        return this.displayElement('sort-desc') ? dateB - dateA : 0;
    }
}
