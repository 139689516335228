<div
    class="m-portlet m-portlet--mobile border-radius-rb"
    data-test="deposit-widget"
>
    <div class="m-portlet__body m-portlet--deposit-widget">
        <div class="row">
            <div class="col-12">
                <div id="monthlyDeposit-content" style="display: block">
                    <div
                        data-test="deposit-widget-click-icon"
                        v-if="showAction && depositDetailsLoaded && !isActiveWidget"
                        @click="$emit('toggleWidget', 'depositChangeWidget')"
                        class="fullwidthArea-open"
                    >
                        <i class="fa fa-times"></i>
                    </div>
                    <div
                        data-test="deposit-widget-click-icon"
                        v-if="isActiveWidget"
                        @click="$emit('toggleWidget', 'depositChangeWidget')"
                        class="fullwidthArea-close"
                    >
                        <i class="fa fa-times"></i>
                    </div>
                    <div
                        class="monthlyDeposit-title"
                        data-test="deposit-widget-value"
                    >
                        <div
                            v-if="!currentDeposit"
                            data-test="deposit-widget-value"
                        >
                            <content-loader
                                :width="50"
                                :height="35"
                                style="width: 50px; height: 35px"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                            {{ $t('euro') }}
                        </div>
                        <template v-else>
                            <template v-if="displayElement('decimal-euro')">
                                {{ currentDeposit | euro }} {{ $t('euro') }}
                            </template>
                            <template v-else>
                                {{ currentDeposit | euroFull }} {{ $t('euro') }}
                            </template>
                        </template>
                    </div>

                    <div class="monthlyDeposit-text">
                        {{ $t('widget.d.discount') }}
                    </div>
                </div>
            </div>

            <deposit-change-widget
                v-if="isActiveWidget && displayElement('deposit-change-widget')"
                class="d-md-none"
            ></deposit-change-widget>
        </div>
    </div>
</div>
