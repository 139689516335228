import { MeterWidget } from '@/components/widgets/meter-widget/meter-widget';
import { MeterWidgetEts } from '@/components/widgets/meter-widget/custom/ets';
import { MeterWidgetSchweizstrom } from '@/components/widgets/meter-widget/custom/schweizstrom';
import { MeterWidgetFullClickable } from '@/components/widgets/meter-widget/custom/full-clickable';

const widgetTypes = {
    ets: MeterWidgetEts,
    schweizstrom: MeterWidgetSchweizstrom,
    elli: MeterWidgetFullClickable,
};

const widget =
    widgetTypes[process.env.VUE_APP_ENVIRONMENT as string] ?? MeterWidget;

export { widget as MeterWidget };
