import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './messages-block.html';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Language from '@/interfaces/Language';

const langProvider = IocContainer.get<Language>(SERVICES.LANGUAGE);

@WithRender
@Component({
    props: {
        success: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Array,
            default: () => [],
        },
        fromTestElement: {
            type: String,
            default: '',
        },
    },
})
export class MessagesBlock extends Base {
    protected elements = {};

    get getterFromTestElement(): string {
        if (
            this.$props.fromTestElement === '' &&
            this.$parent?.$options.name !== undefined
        ) {
            return (
                this.$parent.$options.name
                    .match(/[A-Z][a-z]+/g)
                    ?.filter((element) => {
                        if (!customer.includes(element.toLowerCase())) {
                            return element;
                        }
                    })
                    ?.map((element) => element.toLowerCase())
                    .join('-') + '-messages'
            );
        }
        return this.$props.fromTestElement;
    }

    get isSuccess(): boolean {
        return (
            this.$props.success.length > 0 &&
            typeof this.$props.success[0] === 'object'
        );
    }

    get messages(): string {
        return this.isSuccess ? this.$props.success : this.$props.errors;
    }

    get classObject(): Record<any, any> {
        return {
            'errors text-success mt-2':
                this.isSuccess && this.displayElement('messages'),
            'alert alert-success':
                this.isSuccess && !this.displayElement('messages'),
            'errors text-danger mt-2':
                !this.isSuccess && this.displayElement('messages'),
            'alert alert-danger':
                !this.isSuccess && !this.displayElement('messages'),
        };
    }

    protected trans(message: any): string {
        const transMessage = langProvider.has(message.key)
            ? langProvider.trans(message.key)
            : message.message;
        return transMessage;
    }
}

const customer = [
    'bsw',
    'buehl',
    'demo',
    'dsp-ahrensburg',
    'dsp-marl',
    'dsp-oie',
    'dsp-revulution',
    'elli',
    'eoptimum',
    'ets',
    'ewii',
    'powercloud',
    'powercloud-us',
    'prokon',
    'qcells',
    'sachsenenergie',
    'schkeuditz',
    'schweizstrom',
    'waermeenergie',
    'wdenergy',
];
