import { ChangeCustomerDataWidget } from '../change-customer-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli-thg.html';

@WithRender
@Component({})
export class ChangeCustomerDataWidgetElliThg extends ChangeCustomerDataWidget {
    public showTooltip = false;
    public taxIdFailed = false;

    public onGroupAffiliationInput(group: {
        label: string;
        value: string;
    }): void {
        this.vwGroupAffiliation = group;
        this.details.groupAffiliation = group.value;
    }

    get submitButtonDisabled(): boolean {
        return (
            this.contractStatus.statusCode >= 8000 ||
            !this.details.zip ||
            !this.details.city ||
            !this.details.street ||
            !this.details.houseNumber ||
            this.submitPending
        );
    }
}
