import { PostBoxWidget } from '../post-box-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './ets.html';

import { ContentLoader } from 'vue-content-loader';

@WithRender
@Component({
    components: {
        ContentLoader,
    },
})
export class PostBoxWidgetEts extends PostBoxWidget {}
