import { default as Component } from 'vue-class-component';
import WithRender from './modal-password-update-widget.html';
import { Emit } from 'vue-property-decorator';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class ModalPasswordUpdateWidget extends Base {
    protected successInformation: Record<any, any> = [];
    protected errorInformation: Record<any, any> = [];
    protected errors: {
        oldPassword: boolean;
        newPassword: boolean;
        newConfirmPassword: boolean;
        totpCode: boolean;
    } = {
        oldPassword: false,
        newPassword: false,
        newConfirmPassword: false,
        totpCode: false,
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private oldPassword = '';
    private newPassword = '';
    private newConfirmPassword = '';
    private isTwoFactorAuthenticationActivated = false;
    protected totpCode = '';

    protected elements = {
        bsw: ['input-with-label'],
    };

    @Emit('toggle-modal')
    private closeModal() {
        this.oldPassword = '';
        this.newPassword = '';
        this.newConfirmPassword = '';
    }

    public created() {
        this.auth
            .twoFactorAuthenticationIsActivated()
            .then((response) => {
                this.isTwoFactorAuthenticationActivated =
                    response.data.success && response.data.activated;
            })
            .catch((error) => {
                Sentry.captureException(new Error(error));
            });
    }

    private getFormattedErrorMessage(errorMessage) {
        if (errorMessage === 'isErrorPasswordLength') {
            errorMessage = this.$t('auth.plus.error.password.length');
        } else if (errorMessage === 'isErrorCountSpecialCharacters') {
            errorMessage = this.$t(
                'auth.plus.error.password.count.special.characters'
            );
        } else if (errorMessage === 'isErrorCountUppercaseCharacters') {
            errorMessage = this.$t(
                'auth.plus.error.password.count.upper.characters'
            );
        } else if (errorMessage === 'isErrorCountLowercaseCharacters') {
            errorMessage = this.$t(
                'auth.plus.error.password.count.lower.characters'
            );
        } else if (errorMessage === 'isErrorCountNumbers') {
            errorMessage = this.$t('auth.plus.error.password.count.numbers');
        } else if (errorMessage === 'isPassportInBlacklist') {
            errorMessage = this.$t('auth.plus.error.password.in.blacklist');
        } else if (errorMessage === 'isErrorDenyUsernamePasswords') {
            errorMessage = this.$t('auth.plus.error.password.deny.username');
        } else if (errorMessage === 'isErrorCountHistoryPasswords') {
            errorMessage = this.$t('auth.plus.error.password.in.history');
        }
        return errorMessage;
    }

    private changePassword() {
        this.successInformation = [];
        this.errorInformation = [];
        this.auth
            .changePassword(
                this.customerDetails.id,
                this.oldPassword,
                this.newPassword,
                this.newConfirmPassword,
                this.totpCode
            )
            .then(
                (response) => {
                    if (response && response.data && response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            if (
                                response.data.messageLocalized.key ===
                                'password.change.success'
                            ) {
                                response.data.messageLocalized.message =
                                    this.$t('login.password.change.success');
                            }

                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        this.oldPassword = '';
                        this.newPassword = '';
                        this.newConfirmPassword = '';
                        this.totpCode = '';
                    } else {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            if (
                                response.data.messageLocalized.key ===
                                'password.change.error'
                            ) {
                                response.data.messageLocalized.message =
                                    this.$t('login.password.change.error');
                            }
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else if (
                            response.data.messageLocalized &&
                            response.data.messageLocalized.length > 0
                        ) {
                            this.errorInformation =
                                response.data.messageLocalized;
                        } else if (
                            response.data.errorCode ===
                            'auth.plus.tfa.code.error'
                        ) {
                            this.errors.totpCode = true;

                            this.errorInformation.push({
                                key: response.data.errorCode,
                                message: this.$t('widget.modal.tfa.code.error'),
                            });
                        }
                    }
                },
                (error) => {
                    if (
                        error.response.data.errors &&
                        error.response.data.errors.oldPassword
                    ) {
                        this.errors.oldPassword = true;
                        this.errorInformation.push({
                            key: '',
                            message: this.getFormattedErrorMessage(
                                error.response.data.errors.oldPassword[0]
                            ),
                        });
                    } else if (
                        error.response.data.errors &&
                        error.response.data.errors.newPassword
                    ) {
                        this.errors.newPassword = true;
                        const bucket: string[] = [];
                        for (const i in error.response.data.errors
                            .newPassword) {
                            if (!error.response.data.errors.newPassword[i]) {
                                continue;
                            }
                            if (
                                error.response.data.errors.newPassword.hasOwnProperty(
                                    i
                                )
                            ) {
                                let message =
                                    error.response.data.errors.newPassword[i];
                                const messageTranslation =
                                    this.getFormattedErrorMessage(message);

                                if (
                                    messageTranslation &&
                                    messageTranslation.length > 0
                                ) {
                                    message = messageTranslation;
                                    bucket.push(message);
                                } else {
                                    if (
                                        this.$te(
                                            error.response.data.errors
                                                .newPassword[i]
                                        )
                                    ) {
                                        bucket.push(
                                            this.$t(
                                                error.response.data.errors
                                                    .newPassword[i]
                                            ).toString()
                                        );
                                    } else {
                                        bucket.push(
                                            error.response.data.errors
                                                .newPassword[i]
                                        );
                                    }
                                }
                            }
                        }
                        this.errorInformation.push({
                            key: '',
                            message: this.getFormattedErrorMessage(
                                error.response.data.errors.newPassword[0]
                            ),
                        });
                    } else if (
                        error.response.data.errors &&
                        error.response.data.errors.newConfirmPassword
                    ) {
                        this.errors.newConfirmPassword = true;
                        this.errorInformation.push({
                            key: '',
                            message: this.getFormattedErrorMessage(
                                error.response.data.errors.newConfirmPassword[0]
                            ),
                        });
                    } else if (
                        error.response.data.errors &&
                        error.response.data.errors.totpCode
                    ) {
                        this.errors.totpCode = true;
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('widget.modal.tfa.code.error'),
                        });
                    }
                    Sentry.captureException(new Error(error));
                }
            );
    }
}
