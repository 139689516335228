<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <i class="fa fa-project-diagram advizzo-icons"></i>
                <span class="static-title static-title--custom advizzo-title"
                    >{{ $t('widget.nc.title') }}</span
                >
                <div class="adv_container">
                    <div id="neighbor_comparison_widget" class=""></div>
                </div>
            </div>
        </div>
    </div>
</div>
