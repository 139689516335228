<div v-if="isDisplayed" class="call-to-action-banner">
    <div
        @click="!buttonText && openUrl()"
        class="call-to-action-banner-content"
    >
        <div
            class="call-to-action-banner-row"
            :class="{ 'call-to-action-banner-with-url': url.length > 0 && !this.buttonText }"
        >
            <div
                class="call-to-action-banner-image"
                :class="{ 'with-logo': displayElement('show-logo') && !faIcon }"
            >
                <i v-if="faIcon" class="fa" :class="faIcon"></i>
                <img v-else-if="displayElement('show-logo')" :src="logoUrl" />
            </div>
            <div class="call-to-action-banner-text" v-html="text"></div>

            <button
                v-if="this.buttonText"
                class="btn btn-secondary"
                @click="openUrl"
            >
                {{ this.buttonText }}
            </button>
        </div>
    </div>
</div>
