<script>
export default {
    name: 'loading-spinner',

    props: {
        size: {
            type: Number,
            default: 80,
        },
        color: {
            type: String,
            default: '#000',
        },
        center: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        cssVars() {
            return {
                '--size': this.size + 'px',
                '--color': this.color,
            };
        },
    },
};
</script>

<template>
    <div class="lds-ring" :style="cssVars" :class="{ center }">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<style lang="scss">
.lds-ring {
    display: inline-block;
    position: relative;
    width: var(--size);
    height: var(--size);

    &.center {
        display: block;
        margin: calc(0.15 * var(--size)) auto;
    }
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(0.8 * var(--size));
    height: calc(0.8 * var(--size));
    margin: calc(0.1 * var(--size));
    border: calc(0.1 * var(--size)) solid var(--color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
