import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './verify-widget.html';
import VueButtonSpinner from 'vue-button-spinner';

import Verify from '@/interfaces/Verify';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import { TranslateResult } from 'vue-i18n';
import { MessagesBlock } from '@/components/snippets/messages';

const verifyProvider = IocContainer.get<Verify>(SERVICES.VERIFY);

@WithRender
@Component({
    components: {
        'message-block': MessagesBlock,
        'vue-button-spinner': VueButtonSpinner,
    },
})
export class VerifyWidget extends Base {
    public token;
    public confirmed = false;
    private isLoading = false;
    private doneResponseSuccess = false;
    private requestData: {
        type: string | null;
        ads_mail: string | null;
        ads_phone: string | null;
        ads_post: string | null;
        client_id: number;
        customer_id: string | null;
        email_business: string | null;
        email_private: string | null;
    } = {
        type: null,
        ads_mail: null,
        ads_phone: null,
        ads_post: null,
        client_id: 0,
        customer_id: null,
        email_business: null,
        email_private: null,
    };
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    public created(): void {
        // this.isLoading = true;
        this.token = this.$route.query.token;
        if (typeof this.token === 'string') {
            verifyProvider.load(this.token).then((response) => {
                // this.isLoading = false;
                if (response.data.success) {
                    this.requestData = response.data.data;

                    if (this.requestData.type === 'email') {
                        verifyProvider.done(this.token).then((response2) => {
                            this.doneResponseSuccess = response2.data.success;
                        });
                    }

                    if (
                        !this.shouldVerifyAds ||
                        this.shouldAutomaticallyConfirmOptIn
                    ) {
                        this.submitRequest();
                    }
                }
            });
        }
    }

    get shouldVerifyAds(): string | null {
        return (
            this.requestData?.ads_mail ||
            this.requestData?.ads_post ||
            this.requestData?.ads_phone
        );
    }

    get shouldAutomaticallyConfirmOptIn(): boolean {
        return this.$store.state.settings.doubleOptInAutomaticallyConfirmOptIn;
    }

    public boolToText(value): TranslateResult {
        if (value) {
            return this.$t('yes');
        }
        return this.$t('no');
    }

    public submitRequest(): boolean | void {
        this.isLoading = true;
        if (this.shouldVerifyAds && !this.shouldAutomaticallyConfirmOptIn) {
            if (!this.confirmed) {
                return false;
            }
        }
        verifyProvider
            .done(this.token)
            .then((response) => {
                this.doneResponseSuccess = response.data.success;
                if (!response.data.success) {
                    this.isLoading = false;
                    this.errorInformation.push(response.data.messageLocalized);
                } else {
                    this.successInformation.push(
                        response.data.messageLocalized
                    );
                }
            })
            .catch((error) => {
                this.isLoading = false;
                this.log(error);
            });
    }
}
