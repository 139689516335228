<div class="m-portlet m-portlet--mobile">
  <div class="m-portlet__body m-portlet-vertragsstatus">
      <div class="row">
          <div class="col-12">
              <span class="customerCommunications-title static-title">
                  <i class="fas fa-exclamation registration-issue-exclamation" v-if="registrationIssue"></i>
                  {{ showContractData ? $t('widget.sb.title') : $t('widget.sb.order.status') }}
              </span>
              <template v-if="!displayElement('closer-title')"></template>
              <div>
                  <div class="m-timeline-2">
                      <div class="m-timeline-2__marker"></div>
                      <div class="m-timeline-2__items m--padding-top-20">
                          <div
                              class="m-timeline-2__item"
                              v-for="(status, index) in statuses"
                          >
                              <div
                                  class="m-timeline-2__item-text m--padding-top-5"
                              >
                                  <b class="m-timeline-2__item-title" :class="registrationIssue && highlightStatus(status) ? 'registration-issue' : ''">
                                      {{ $t(status.title) }}
                                  </b>
                                  <div class="m-timeline-2__item-descr">{{ $t(status.description, status.fields) }}</div> 
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="delivery-address">
                    <b v-if="displayElement('shipping-address')">{{ showContractData ? $t('widget.sb.deliveryPoint') : $t('widget.sb.shippingAddress') }}: </b>
                    <b v-else>{{$t('widget.sb.deliveryPoint')}}</b>
                    <div class="delivery-address__info">{{ contract.street }} {{ contract.houseNumber }}, {{contract.zip }} {{ contract.city }}</div>
                    
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>