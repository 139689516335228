import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './aklamio-popup-widget.html';
import TagService from '@/services/TagService';
const tagService = new TagService();

@WithRender
@Component({})
export class AklamioPopupWidget extends Base {
    get uid() {
        switch (this.currentClient) {
            case 'qcells':
                return '6073fdaf16cdf5c5e714262141467bda';
            default:
                return '';
        }
    }

    get bannerUrl() {
        const path = '/img/evus/' + this.currentClient;
        switch (this.currentClient) {
            case 'qcells':
                return (
                    path +
                    '/Qcells_Banner_Kundenportal_Freunde_werben_2022-08.jpg'
                );
            default:
                return '';
        }
    }

    public mounted() {
        tagService.appendHeadTag({
            tag: 'script',
            attributes: [
                {
                    name: 'type',
                    value: 'text/javascript',
                },
                {
                    name: 'src',
                    value: 'https://api.aklamio.com/assets/embed/1.latest/embed.min.js',
                },
            ],
        });

        tagService.addAllToDOM();
    }
}
