<div class="m-aside-modal m-aside-modal__wrap thg-bonus-selector">
    <div v-if="showVehicleDataEditor" class="m-aside-modal__body">
        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('widget.nc.bonus.selection.vehicle.data.new.title.upload')
                }}
            </span>
            <div class="m-aside-modal__close" @click="closeSelector()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>

        <div class="row">
            <div
                class="col-lg-12 non-commodity-product-selector-vehicle-data-update-"
                v-html="$t('widget.nc.bonus.selection.vehicle.data.new.description')"
            ></div>

            <div
                class="col-lg-12 non-commodity-product-selector-vehicle-data-update-option-selector-box"
            >
                <label
                    class="m-radio non-commodity-product-selector-vehicle-data-update"
                >
                    <input
                        type="radio"
                        :value="thgService.vehicleDataUpdateTypeNewDocument"
                        v-model="vehicleDataUpdateType"
                    />
                    <span></span>
                    <div
                        class="non-commodity-product-selector-vehicle-data-update-option-header"
                        v-html="$t('widget.nc.bonus.selection.vehicle.data.new.registration.new.document')"
                    ></div>
                    <div
                        class="non-commodity-product-selector-vehicle-data-update-option-description"
                        v-html="$t('widget.nc.bonus.selection.vehicle.data.new.registration.new.document.description')"
                    ></div>
                </label>

                <label
                    class="m-radio non-commodity-product-selector-vehicle-data-update"
                >
                    <input
                        type="radio"
                        :value="thgService.vehicleDataUpdateTypeNewVehicle"
                        v-model="vehicleDataUpdateType"
                    />
                    <span></span>
                    <div
                        class="non-commodity-product-selector-vehicle-data-update-option-header"
                        v-html="$t('widget.nc.bonus.selection.vehicle.data.new.registration.new.vehicle')"
                    ></div>
                    <div
                        class="non-commodity-product-selector-vehicle-data-update-option-description"
                        v-html="$t('widget.nc.bonus.selection.vehicle.data.new.registration.new.vehicle.description')"
                    ></div>
                </label>
            </div>
        </div>

        <button
            @click="showModalProductSelection"
            type="button"
            class="btn btn-primary d-block thg-bonus-selector-btn-back"
        >
            {{ $t('widget.nc.bonus.selection.vehicle.data.new.back') }}
        </button>

        <button
            @click="vehicleDataNextStep"
            :disabled="!vehicleDataUpdateType || vehicleDataUpdateType.length == 0"
            type="button"
            class="btn btn-primary d-block thg-bonus-selector-btn-next"
        >
            {{ $t('widget.nc.bonus.selection.vehicle.data.new.next') }}
        </button>
    </div>
    <div v-if="showVehicleDocumentUpload" class="m-aside-modal__body">
        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('widget.nc.bonus.selection.vehicle.data.new.title.upload')
                }}
            </span>
            <div class="m-aside-modal__close" @click="closeSelector()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>

        <div class="row">
            <form-wrapper
                form="non-commomdity-product-selector-vehicle-data-update-document-form"
            >
                <div
                    class="col-lg-12 non-commodity-product-selector-vehicle-data-update-document"
                    v-html="$t('widget.nc.bonus.selection.vehicle.data.new.description.upload')"
                ></div>

                <div
                    class="col-lg-12 non-commodity-product-selector-vehicle-data-update-option-selector-box"
                >
                    <form-group>
                        <custom-label>
                            <div class="folder-icon">
                                <i class="far fa-folder-open"></i>
                            </div>
                            {{
                            $t('widget.nc.bonus.selection.vehicle.document.upload.front.label')
                            }}
                        </custom-label>

                        <input
                            class="form-control"
                            accept="application/pdf,image/png, image/jpeg, image/gif, image/jpg"
                            type="file"
                            data-side="front"
                            @change="onFileSelect"
                        />

                        <div class="filesize-notice">
                            <span
                                v-html="$t('widget.nc.bonus.selection.vehicle.document.upload.front.sub')"
                            ></span>
                        </div>
                    </form-group>
                </div>
            </form-wrapper>
        </div>

        <button
            @click="showModalProductSelection"
            type="button"
            class="btn btn-primary d-block thg-bonus-selector-btn-back"
        >
            {{
            $t('widget.nc.bonus.selection.vehicle.document.upload.button.cancel')
            }}
        </button>

        <button
            @click="submitUploadedFile"
            :disabled="!isDocumentReadyForUpload"
            type="button"
            class="btn btn-primary d-block thg-bonus-selector-btn-next"
        >
            {{
            $t('widget.nc.bonus.selection.vehicle.document.upload.button.upload')
            }}
        </button>
    </div>

    <div v-else-if="showProductSelection" class="m-aside-modal__body">
        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('widget.nc.bonus.selection.title') }}
            </span>
            <div class="m-aside-modal__close" @click="closeSelector()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>

        <div class="form-group m-form__group">
            {{ $t('widget.nc.bonus.selection.title.sub', {year: bonusYear}) }}
            <div
                v-if="showVehicleRegistrationUploadSuccess"
                class="non-commodity-product-selector-vehicle-data-update-success"
            >
                {{ $t('widget.nc.bonus.selection.title.sub.upload.success') }}
            </div>
        </div>

        <div v-if="isLoading || isConfirming" class="form-group m-form__group">
            <div
                class="spinner-border spinner-inside mr-3"
                id="non-commodity-loading-products-spinner"
                role="status"
            ></div>
        </div>
        <div
            v-else-if="nonCommodityProducts.length == 0"
            class="form-group m-form__group"
        >
            <b>{{ $t('widget.nc.bonus.selection.none.available') }}</b>
        </div>
        <div
            v-else-if="nonCommodityProducts.length > 0"
            class="form-group m-form__group product-info"
        >
            <div
                v-for="product in nonCommodityProducts"
                class="col-12 product-widget-element non-commodity-product"
                :class="{'non-commodity-product-eco': product.productCode === currentProduct.code}"
                :key="product.productCode"
            >
                <div class="row">
                    <div class="col-lg-1 thg-bonus-checkbox">
                        <label
                            class="m-radio non-commodity-product-selector-radio"
                        >
                            <input
                                :disabled="!isReadyForSelection"
                                type="radio"
                                :value="product.productCode"
                                v-model="selectedProductCode"
                            />
                            <span></span>
                        </label>
                    </div>
                    <div class="col-lg-2 thg-bonus-image">
                        <img
                            class="non-commodity-product-image"
                            :src="product.productImage"
                        />
                    </div>
                    <div class="col-lg-9">
                        <span
                            v-if="product.cmsDescriptionExternal && product.cmsDescriptionExternal.length > 0"
                            class="non-commodity-product-btn-info"
                            @click="showProductInfo(product)"
                        />
                        <b>{{ product.productName }}</b>
                        <br />
                        <span class="non-commodity-product-price">
                            {{ $t('widget.nc.bonus.overview.amount') }}
                            <br />
                            <b
                                >{{ thgService.isCustomerTypeBusiness ?
                                product.getBasePrice([bonusYear], vehicleClass)
                                : product.getBasePriceBrutto([bonusYear],
                                vehicleClass) | euro }} {{ $t('euro.symbol') }}
                                {{ thgService.isCustomerTypeBusiness ?
                                $t('widget.nc.bonus.selection.amount.net') : ''
                                }}</b
                            >
                        </span>
                    </div>
                </div>

                <div
                    v-if="selectedProductCode == product.productCode && thgService.isBankDataRequiredForProductAndYear(product, bonusYear)"
                    class="row non-commodity-product-bank-information"
                >
                    <form-wrapper form="formBankData">
                        <div class="col-lg-12">
                            <hr class="non-commodity-product-separator" />
                            <div
                                class="non-commodity-banking-information-missing"
                                v-html="$t('widget.nc.bonus.bank.details.information.missing')"
                            />
                        </div>

                        <form-group
                            :class="['col-12']"
                            id="non-conmmodity-product-bank-details-bearer"
                        >
                            <custom-label
                                class="non-commodity-product-bank-details-input-label"
                                v-html="$t('widget.nc.bonus.bank.details.owner.label')"
                            />

                            <custom-input
                                type="text"
                                class="form-control non-commodity-product-bank-details-input-field"
                                :placeholder="$t('widget.nc.bonus.bank.details.owner.placeholder')"
                                v-model="bankDetailsBearer"
                            />
                        </form-group>

                        <form-group
                            :class="['col-12']"
                            id="non-conmmodity-product-bank-details-iban"
                        >
                            <custom-label
                                class="non-commodity-product-bank-details-input-label"
                                v-html="$t('widget.nc.bonus.bank.details.iban.label')"
                            />

                            <custom-input
                                type="text"
                                class="form-control non-commodity-product-bank-details-input-field"
                                :placeholder="$t('widget.nc.bonus.bank.details.iban.placeholder')"
                                v-model.trim="bankDetailsIban"
                                @blur="updateBic()"
                            />
                        </form-group>

                        <form-group
                            :class="['col-12']"
                            id="non-conmmodity-product-bank-details-bic"
                        >
                            <custom-label
                                class="non-commodity-product-bank-details-input-label"
                                v-html="$t('widget.nc.bonus.bank.details.bic.label')"
                            />
                            <custom-input
                                readonly
                                type="text"
                                class="form-control non-commodity-product-bank-details-input-field"
                                :placeholder="$t('widget.nc.bonus.bank.details.bic.placeholder')"
                                v-model="bankDetailsBic"
                            />
                        </form-group>
                    </form-wrapper>
                </div>
            </div>

            <div class="col-12 non-commodity-product-selector-button-box">
                <div class="row">
                    <div
                        v-if="selectedProduct && selectedProduct.isEcoProduct"
                        class="col-lg-12"
                    >
                        <div class="form-group form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="non-commodity-confirm-gtc-eco"
                                v-model="confirmGtcEco"
                            />
                            <label
                                class="form-check-label non-commodity-confirm-gtc-eco-label"
                                for="non-commodity-confirm-gtc-eco"
                                v-html="$t('widget.nc.bonus.selection.product.gtc.confirm', {bonusName: selectedProductName})"
                            />
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group form-check">
                            <input
                                :disabled="!isReadyForSelection"
                                type="checkbox"
                                class="form-check-input"
                                id="non-commodity-confirm-vehicle"
                                v-model="confirmVehicle"
                            />
                            <label
                                class="form-check-label non-commodity-confirm-vehicle-label"
                                for="non-commodity-confirm-vehicle"
                            >
                                {{
                                $t('widget.nc.bonus.selection.registration.confirm',
                                { licensePlate: licensePlate, year: bonusYear })
                                }}

                                <div
                                    class="non-commodity-confirm-vehicle-label-small"
                                >
                                    {{
                                    $t('widget.nc.bonus.selection.registration.confirm.sub')
                                    }}
                                </div>

                                <div
                                    v-if="errorMessage.length > 0"
                                    class="non-commodity-bonus-selector-error-message"
                                    v-html="errorMessage"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <button
                            @click="confirmProduct"
                            :disabled="isLoadingBic || !isReadyForConfirmation || isConfirming"
                            type="button"
                            class="btn btn-primary d-block thg-bonus-selector-confirm-submit"
                        >
                            {{
                            $t('widget.nc.bonus.selection.registration.confirm.button.submit')
                            }}
                        </button>

                        <button
                            v-if="!showVehicleRegistrationUploadSuccess"
                            @click="showModalVehicleDataEditor"
                            :disabled="isLoadingBic || isLoading || isConfirming"
                            type="button"
                            class="btn btn-primary d-block thg-bonus-selector-change-vehicle-data"
                        >
                            {{
                            $t('widget.nc.bonus.selection.registration.confirm.button.change')
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="displayedProductDetails"
        class="m-aside-modal m-aside-modal__wrap"
    >
        <div
            class="m-aside-modal__body non-commodity-bonus-selector-product-details"
        >
            <div class="d-flex align-items-center justify-content-between">
                <span class="box-title static-title">
                    {{ displayedProductDetails.productName }}
                </span>
                <div class="m-aside-modal__close" @click="hideProductInfo()">
                    <i class="m-menu__link-icon fa fa-times"></i>
                </div>
            </div>

            <div
                v-if="displayedProductDetails.cmsDescriptionExternal.length > 0"
                class="non-commodity-bonus-selector-product-details-description"
                v-html="displayedProductDetails.cmsDescriptionExternal"
            />
        </div>
    </div>
</div>
