<div
    class="m-portlet m-portlet--mobile m-portlet--change-widget"
    data-test="change-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <i
                    class="fa readingHistory-icon"
                    :class="displayElement('edit-icon') ? 'fa-edit' : 'fa-user-edit'"
                ></i>
                <span
                    class="
                        monthlyDeposit-title
                        static-title static-title--custom
                    "
                    >{{ $t('widget.cw.title') }}</span
                ><br />
                <div class="changeData-button-list">
                    <button
                        data-test="change-widget-customer-data-click"
                        :disabled="changeCustomerDataLoading"
                        @click="$emit('toggleWidget', 'changeCustomerDataWidget')"
                        :class="['btn btn-link btn-sm', groupWidget.changeCustomerDataWidget ? 'active' : '']"
                        id="button-changeCustomerData"
                    >
                        <span>{{ $t('widget.cw.customer') }}</span></button
                    ><br v-if="!displayElement('no-line-breaks')" />
                    <button
                        v-if="showChangeBillingData"
                        :disabled="changeBillingDataLoading"
                        @click="$emit('toggleWidget', 'changeBillingDataWidget')"
                        :class="['btn btn-link btn-sm', groupWidget.changeBillingDataWidget ? 'active' : '']"
                        id="button-changeBillingData"
                        data-test="change-widget-billing-data-click"
                    >
                        <span>{{ $t('widget.cw.postal') }}</span></button
                    ><br v-if="!displayElement('no-line-breaks')" />
                    <button
                        v-if="showChangePaymentData"
                        :disabled="changePaymentDataLoading"
                        @click="$emit('toggleWidget', 'changePaymentDataWidget')"
                        :class="['btn btn-link btn-sm', groupWidget.changePaymentDataWidget ? 'active' : '']"
                        id="button-changePaymentData"
                        data-test="change-widget-payment-data-click"
                    >
                        <span>{{ $t('widget.cw.payment') }}</span></button
                    ><br v-if="!displayElement('no-line-breaks')" />
                    <button
                        v-if="isMobile"
                        :class="['btn btn-link btn-sm']"
                        @click="$emit('toggleWidget', 'isOpenTariffDetailsWidget')"
                    >
                        <span>{{ $t('widget.td.link') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
