<div class="m-portlet m-portlet--mobile m-portlet--invoice">
    <div class="dashboard-banner aklamio-popup-widget">
        <img
            data-aklamio-embed="sharing"
            data-locale="de"
            :data-uid="uid"
            :src="bannerUrl"
        />
    </div>
</div>
