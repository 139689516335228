import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './change-widget.html';

@WithRender
@Component({
    props: {
        groupWidget: {
            type: Object,
            default: {
                changeCustomerDataWidget: false,
                changeBillingDataWidget: false,
                changePaymentDataWidget: false,
                isOpenTariffDetailsWidget: false,
            },
        },
    },
})
export class ChangeWidget extends Base {
    protected elements = {
        'dsp-*': ['billing-data-hide', 'payment-data-hide'],
        bsw: ['edit-icon'],
        schweizstrom: ['show-tariff-details-link', 'no-line-breaks'],
        eoptimum: ['no-line-breaks'],
    };

    get changeCustomerDataLoading(): boolean {
        return !this.$store.state.customer.id;
    }

    get contract(): Record<any, any> {
        if (this.$store.state.contracts.contractId) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract;
        }

        return {};
    }

    get isHorizontal(): boolean {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );
        if (contract) {
            return this.isThg(contract);
        }

        if (this.customerDetails.isFleetCustomer) {
            return true;
        }

        return false;
    }

    get changeBillingDataLoading(): boolean {
        if (this.contract.billingAddress) {
            return !this.contract.billingAddress.loaded;
        }
        return false;
    }

    get changePaymentDataLoading(): boolean {
        if (this.contract.billingAddress) {
            return !this.contract.bankingDetails.loaded;
        }
        return false;
    }

    get contractStatusTriggered(): boolean | undefined {
        return (
            this.contractCancellationPrevious ||
            this.contractRegistrationOperator
        );
    }

    get showChangeBillingData(): boolean {
        return !(
            this.contractStatusTriggered &&
            this.displayElement('billing-data-hide')
        );
    }

    get showChangePaymentData(): boolean {
        return !(
            this.contractStatusTriggered &&
            this.displayElement('payment-data-hide')
        );
    }

    get prefix(): string {
        if (this.currentClient.includes('elli')) {
            const contract = this.currentContract;
            if (contract) {
                if (this.isThg(contract)) {
                    return '.thg';
                }
            }
            if (this.isDealer(contract)) {
                return '.thg.dealer';
            }
        }

        return '';
    }
}
