<div class="m-portlet m-portlet--mobile" data-test="thg-status-widget">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <span
                    class="static-title static-title--custom thg-status-title"
                    v-html="$t('widget.ts.title')"
                ></span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="thg-status-sub-title"
                    >{{ $t('widget.ts.subtitle', {year: bonusYear}) }}</span
                >
            </div>
        </div>
        <div class="row">
            <div class="col mt-3">
                <div class="stepper-wrapper status-container">
                    <div
                        class="stepper-item"
                        :class="[isPending || isSecondStepWarning || isSecondStepCanceled || !isSecondStepComplete ? 'active' : 'completed']"
                    >
                        <div class="step-counter">&#x2713;</div>
                        <div class="step-name">{{ $t('widget.ts.first') }}</div>
                    </div>
                    <div
                        class="stepper-item"
                        :class="[
                             isSecondStep || isRejected || !isSecondStepCanceled ? 'active' : '',
                             isSecondStepComplete ? 'completed' : '',
                             isSecondStepWarning ? 'warning' : '',
                             isSecondStepCanceled ? 'rejected' : '',
                             ]"
                    >
                        <div class="step-counter">
                            <span v-if="isSecondStepCanceled"></span>
                            <span v-else-if="isSecondStepWarning">!</span
                            ><span v-else>&#x2713;</span>
                        </div>
                        <div class="step-name">
                            {{ $t('widget.ts.second') }}
                        </div>
                    </div>
                    <div
                        class="stepper-item"
                        :class="[
                             isThirdStep ? 'active' : '',
                             isThirdStepCompleted ? 'completed': '',
                             isRejected ? 'rejected' : ''
                             ]"
                    >
                        <div class="step-counter">
                            <span v-if="isRejected"></span
                            ><span v-else>&#x2713;</span>
                        </div>
                        <div class="step-name">{{ $t('widget.ts.third') }}</div>
                    </div>
                    <div
                        class="stepper-item"
                        :class="[isLastStep ? 'active' : '']"
                    >
                        <div class="step-counter">&#x2713;</div>
                        <div class="step-name">{{ $t('widget.ts.four') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
