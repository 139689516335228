<!--begin::Restore two factor authentication -->
<div class="recovery-form">
    <!--begin::QRcode-->
    <div v-if="image" class="recovery-qrcode">
        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>

        <show-multiple-error-messages
            :errors="errors"
        ></show-multiple-error-messages>

        <div class="row">
            <div class="col form-col">
                <img :src="image" width="200" alt="qrcode" />
                <br />
                <span>{{ secret }}</span>
            </div>
            <div class="col form-col">
                <div class="form-group">
                    <input
                        id="code"
                        type="text"
                        :class="['form-control']"
                        :placeholder="$t('widget.modal.tfa.code')"
                        v-model="code"
                    />
                </div>
                <div class="form-group">
                    <div class="text-end">
                        <button
                            class="btn btn-primary"
                            @click="sendCode"
                            :disabled="enableLoadingState"
                        >
                            {{ $t('widget.modal.tfa.submit') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::QRcode-->
    <div v-else>
        <div class="recovery-title">
            {{ $t('restore.tfa.access.recovery') }}
        </div>

        <div class="form-group m-form__group">
            <label for="username" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('restore.tfa.recovery.username') }}</strong>
            </label>
            <input
                :class="['form-control m-input', errors && errors.username ? 'is-invalid' : '']"
                type="text"
                id="username"
                :placeholder="$t('restore.tfa.recovery.username')"
                v-model="details.username"
            />
        </div>
        <show-multiple-error-messages
            :errors="errors"
            :error-key="'username'"
        ></show-multiple-error-messages>
        <div class="form-group m-form__group">
            <label for="email" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('restore.tfa.recovery.email') }}</strong>
            </label>
            <input
                :class="['form-control m-input', errors && errors.email ? 'is-invalid' : '']"
                type="text"
                id="email"
                :placeholder="$t('restore.tfa.recovery.email')"
                v-model="details.email"
            />
        </div>
        <show-multiple-error-messages
            :errors="errors"
            :error-key="'email'"
        ></show-multiple-error-messages>
        <div class="form-group m-form__group">
            <label for="code1" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('restore.tfa.recovery.code') }}</strong>
            </label>
            <input
                class="form-control m-input"
                type="text"
                id="code1"
                :placeholder="$t('restore.tfa.recovery.code')"
                v-model="details.code1"
            />
        </div>
        <div class="form-group m-form__group">
            <label for="code2" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('restore.tfa.recovery.code') }}</strong>
            </label>
            <input
                class="form-control m-input"
                type="text"
                id="code2"
                :placeholder="$t('restore.tfa.recovery.code')"
                v-model="details.code2"
            />
        </div>
        <div class="form-group m-form__group">
            <label for="code3" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('restore.tfa.recovery.code') }}</strong>
            </label>
            <input
                class="form-control m-input"
                type="text"
                id="code3"
                :placeholder="$t('restore.tfa.recovery.code')"
                v-model="details.code3"
            />
        </div>
        <div class="form-group m-form__group">
            <label for="code4" v-if="displayElement('input-with-label')"
                ><strong>{{ $t('restore.tfa.recovery.code') }}</strong>
            </label>
            <input
                class="form-control m-input"
                type="text"
                id="code4"
                :placeholder="$t('restore.tfa.recovery.code')"
                v-model="details.code4"
            />
        </div>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>

        <!--begin::Action-->
        <div class="m-login__action">
            <a @click="$router.push({name: 'login'})" class="m-link">
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>
            <button
                @click="checkRecoveryCodes()"
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom m-btn--air
                    m-login__btn m-login__btn--primary
                "
                :disabled="enableLoadingState"
            >
                {{ $t('restore.tfa.button.check.recovery') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
</div>
<!--end::Restore two factor authentication-->
