import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './disaggregation-widget.html';

@WithRender
@Component({})
export class AdvizzoDisaggregationWidget extends vue {
    public mounted(): void {
        // @ts-ignore
        document.getElementById('disaggregation_widget').innerHTML =
            // @ts-ignore
            Placeholder.dissagg;

        const disaggConfig = {
            language: {
                title: 'Average Energy Use',
            },
            chart: {
                colors: [
                    '#FABDD9',
                    '#00A1DF',
                    '#C1D821',
                    '#FFA813',
                    '#FF3E36',
                    '#2A3188',
                    '#0C7767',
                    '#BB94C4',
                    '#CA93C2',
                ],
                barWidth: 80,
                textFontFamily: 'Arial',
                textMaxFontSize: 19,
                textFontColor: '#000000',
            },
            label: {
                valueUnit: '%',
            },
        };

        const dwData = [
            {
                disaggType: 'water',
                label: 'water',
                disaggValues: [
                    {
                        id: 'wath',
                        label: 'Water heater',
                        value: 21,
                    },
                    {
                        id: 'cook',
                        label: 'Cooking',
                        value: 19,
                    },
                    {
                        id: 'light',
                        label: 'Lighting',
                        value: 18,
                    },
                    {
                        id: 'other',
                        label: 'Other',
                        value: 18,
                    },
                    {
                        id: 'aircon',
                        label: 'Air conditioning',
                        value: 12,
                    },
                    {
                        id: 'appli',
                        label: 'Appliances',
                        value: 12,
                    },
                ],
                alert: 'You recently made changes to your survey so your disaggregation data may change.',
            },
        ];

        // @ts-ignore
        const dw = new DisaggregationWidget(disaggConfig);
        // @ts-ignore
        const dynamicDwWidget = new DynamicWidgetWrapper(
            dw,
            '/api/proxy/disaggregation',
            // @ts-ignore
            new HttpMock(dwData)
        );
        dynamicDwWidget.render('disaggregation_widget');
    }
}
