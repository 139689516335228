import { default as Component } from 'vue-class-component';
import WithRender from './password-reset-page.html';

import { Password } from './password';
import { ResetPasswordWidget } from '@/components/widgets/reset-password-widget';

@WithRender
@Component({
    components: {
        ResetPasswordWidget
    },
})
export class PasswordResetPage extends Password {}
