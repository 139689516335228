export default interface SubmitCancellation {
    contractId: number;
    cancelType: CancelType | null;
    cancelReason: string | undefined;
    extraOrdinaryDate: string | null;
    cancelDate: string | null;
    moveAtDate: string | null;
    confirmation: boolean;
}

export enum CancelType {
    deadlineDate = 'deadlineDate',
    extraOrdinaryDate = 'extraOrdinaryDate',
    cancelDate = 'cancelDate',
    moveAtDate = 'moveAtDate',
}
