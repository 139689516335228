import { ChangeBillingDataWidget } from '../change-billing-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli-thg.html';

@WithRender
@Component({})
export class ChangeBillingDataWidgetElliThg extends ChangeBillingDataWidget {
    get submitButtonDisabled(): boolean {
        return (
            this.contractStatus.statusCode >= 8000 ||
            !this.details.billingZip ||
            !this.details.billingCity ||
            !this.details.billingStreet ||
            !this.details.billingHouseNumber ||
            this.submitPending ||
            !this.dataChanged
        );
    }
}
