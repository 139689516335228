import Base from '@/mixins/base';
import { Component } from 'vue-property-decorator';
import WithRender from './elli-general.html';

import { ChangeCustomerDataWidgetElli } from '@/components/widgets/change-customer-data-widget/custom/elli';
import { ChangeCustomerDataWidgetElliThg } from '@/components/widgets/change-customer-data-widget/custom/elli-thg';
import { ChangeCustomerDataWidgetElliThgDealer } from '@/components/widgets/change-customer-data-widget/custom/elli-thg-dealer';

@WithRender
@Component({
    components: {
        ChangeCustomerDataWidgetElli,
        ChangeCustomerDataWidgetElliThg,
        ChangeCustomerDataWidgetElliThgDealer,
    },
})
export class ChangeCustomerDataWidgetElliGeneral extends Base {
    get widget() {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );
        if (contract) {
            if (this.isDealer(contract)) {
                return ChangeCustomerDataWidgetElliThgDealer;
            }
            if (this.isThg(contract)) {
                return ChangeCustomerDataWidgetElliThg;
            }
        }

        return ChangeCustomerDataWidgetElli;
    }
}
