<div
    class="m-portlet m-portlet--mobile m-portlet--meter-history"
    data-test="marketing-material-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col">
                <i class="fa fa-chart-bar readingHistory-icon"></i>
                <span
                    data-test="meter-history-widget-title"
                    class="monthlyDeposit-title static-title static-title--custom"
                    >{{ $t('widget.mm.title') }}</span
                >
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-4">
                    <span>{{ $t('widget.mm.content.1') }}</span>
                </div>
                <div class="mb-1">
                    <span class="font-weight-bold"
                        >{{ $t('widget.mm.content.2' )}}</span
                    >
                </div>
                <div class="code-snippet mb-2">
                    <span v-if="productCode != ''">
                        {{ jsTag }}<br />
                        {{ htmlTag }}
                    </span>
                    <span v-else> {{ $t('widget.mm.code.loading' )}} </span>
                </div>
                <div class="mb-2">
                    <span class="font-weight-bold"
                        >{{ $t('widget.mm.how.it.works') }}</span
                    >
                </div>
                <div>
                    <span class="ps-1">{{ $t('widget.mm.step.1') }}</span>
                </div>
                <div>
                    <span class="ps-1">{{ $t('widget.mm.step.2') }}</span>
                </div>
                <div class="mb-4">
                    <span class="ps-1">{{ $t('widget.mm.step.3') }}</span>
                </div>
                <div>
                    <span class="font-weight-bold"
                        >{{ $t('widget.mm.materials') }}</span
                    >
                </div>
                <div class="mb-4">
                    <i class="fas fa-file-pdf"></i>
                    <a :href="$t('widget.mm.link')" target="_blank"
                        >{{ $t('widget.mm.file') }}</a
                    >
                    <template
                        v-if="productCode &&
                        (
                            productCode.includes('VW')
                            || productCode.includes('Audi')
                            || productCode.includes('Cupra')
                            || productCode.includes('Skoda')
                        )"
                    >
                        <br />
                        <i class="fas fa-file-pdf"></i>
                        <a
                            :href="$t('widget.mm.link.dock.' + documentPrefix )"
                            target="_blank"
                            >{{ $t('widget.mm.file.dock') }}</a
                        >
                    </template>
                    <template
                        v-if="productCode && (productCode.includes('VW') || productCode.includes('Audi'))"
                    >
                        <br />
                        <i class="fas fa-file-pdf"></i>
                        <a
                            :href="$t('widget.mm.link.' + videoPrefix )"
                            target="_blank"
                            >{{ $t('widget.mm.file2') }}</a
                        >
                    </template>
                </div>
                <!-- Please do not remove: -->
                <!-- <div class="form-check form-switch">
                    <input
                        type="checkbox"
                        id="emailSwitch"
                        class="form-check-input"
                        v-model="showEmailAction"
                        data-test="marketing-material-widget-open"
                    />
                    <label
                        class="form-check-label form-check-label--elli"
                        for="emailSwitch"
                        >{{ $t('widget.mm.it.responsible.heading') }}</label
                    >
                </div> -->
                <div v-if="showEmailAction">
                    <div class="mb-2">
                        <span v-html="$t('widget.mm.it.responsible')">}</span>
                    </div>
                    <div class="marketing-material-email">
                        <div class="marketing-material-email__container">
                            <div>
                                <textarea
                                    class="marketing-material-email__textarea"
                                    cols="30"
                                    rows="10"
                                    v-model="customEmail"
                                ></textarea>
                            </div>
                            <hr class="marketing-material-email__hr" />
                            <div
                                class="marketing-material-email__textarea--pseudo"
                            >
                                <div v-if="showFixedEmail">
                                    <p>
                                        {{$t('widget.mm.email.fixed.greetings')}}
                                    </p>
                                    <p v-if="contractsData.company">
                                        {{$t('widget.mm.email.fixed.para1.with.company',
                                        {firstname: customerDetails.firstname,
                                        surname: customerDetails.surname,
                                        company: contractsData.company})}}
                                    </p>
                                    <p v-else>
                                        {{$t('widget.mm.email.fixed.para1',
                                        {firstname: customerDetails.firstname,
                                        surname: customerDetails.surname})}}
                                    </p>
                                    <p>{{$t('widget.mm.email.fixed.para2')}}</p>
                                    <p class="mb-0">{{jsTag}}</p>
                                    <p>{{htmlTag}}</p>
                                    <br />
                                    <p
                                        class="marketing-material-email__list-point"
                                        v-html="$t('widget.mm.email.fixed.list.point1')"
                                    ></p>
                                    <p
                                        class="marketing-material-email__list-point-text"
                                    >
                                        {{$t('widget.mm.email.fixed.list.point1.text')}}
                                    </p>
                                    <p
                                        class="marketing-material-email__list-point"
                                        v-html="$t('widget.mm.email.fixed.list.point2')"
                                    ></p>
                                    <p
                                        class="marketing-material-email__list-point"
                                        v-html="$t('widget.mm.email.fixed.list.point3')"
                                    ></p>
                                    <p
                                        class="marketing-material-email__list-point-text"
                                    >
                                        {{$t('widget.mm.email.fixed.list.point3.text')}}
                                        <br />
                                        {{$t('widget.mm.email.fixed.link')}}
                                    </p>
                                    <br />
                                    <p>{{$t('widget.mm.email.fixed.para3')}}</p>
                                    <p>{{$t('widget.mm.email.fixed.para4')}}</p>
                                    <br />
                                    <p>{{$t('widget.mm.email.fixed.para5')}}</p>
                                    <p>{{$t('widget.mm.email.fixed.para6')}}</p>
                                </div>
                                <div v-else>
                                    {{$t('widget.mm.email.fixed.closed.text')}}
                                </div>
                            </div>
                            <div
                                class="marketing-material-email__button-container"
                            >
                                <button
                                    @click="showFixedEmail = !showFixedEmail"
                                    class="marketing-material-email__toggle-button"
                                    data-test="marketing-material-widget-toggle"
                                >
                                    <span>
                                        {{$t('widget.mm.email.fixed.open.button')}}
                                    </span>
                                    <span
                                        class="marketing-material-email__toggle-button-icon"
                                        :class="{
                                    'marketing-material-email__toggle-button-icon--active': showFixedEmail,
                                }"
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="marketing-material-email__form-row">
                        <div class="marketing-material-email__form-row">
                            <div
                                class="marketing-material-email__form-elements marketing-material-email__form-elements--40"
                            >
                                <span>
                                    <label
                                        for="marketing-material-email__checkbox"
                                        class="form-input-label"
                                    >
                                        {{ $t('widget.mm.email.input.label') }}
                                    </label>
                                    <input
                                        data-test="marketing-material-widget-email"
                                        type="text"
                                        class="marketing-material-email__test"
                                        placeholder="ansprechpartner@IT.de"
                                        v-model="to"
                                    />
                                </span>
                            </div>
                            <div
                                class="marketing-material-email__form-elements marketing-material-email__form-elements--40"
                            >
                                <div
                                    class="custom-check"
                                    for="marketing-material-email__checkbox"
                                >
                                    <input
                                        type="checkbox"
                                        data-test="marketing-material-widget-checkbox"
                                        id="marketing-material-email__checkbox"
                                        class="form-check-input marketing-material-email__checkbox"
                                        v-model="allowToContact"
                                    />
                                    <label
                                        for="marketing-material-email__checkbox"
                                        class=""
                                    >
                                        {{ $t('widget.mm.email.checkbox.label')
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="marketing-material-email__form-elements">
                            <button
                                @click="sendEmail"
                                class="btn btn-primary"
                                :disabled="!isSubmitEnabled"
                                data-test="marketing-material-widget-submit-button"
                            >
                                {{ $t('widget.mm.email.send') }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <messages-block
                            fromTestElement="marketing-material-widget-messages"
                            class="marketing-material-message-block"
                            :success="successInformation"
                            :errors="errorInformation"
                        ></messages-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
