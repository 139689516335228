<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <i class="fa fa-file-invoice readingHistory-icon"></i>
                <span class="monthlyDeposit-title static-title">
                    {{ $t('widget.dp.title') }}
                </span>
            </div>
            <div
                class="col-12 mobile-modal__wrap mx-0 pb-3"
                id="depositChange-content"
            >
                <div class="col-12" v-if="isDepositPlanLoading">
                    <content-loader
                        height="40"
                        primaryColor="var(--secondary)"
                        secondaryColor="var(--primary)"
                        data-test="CONTENT-LOADER"
                    ></content-loader>
                </div>
                <template v-else>
                    <div
                        class="col-12"
                        v-if="depositsLoaded && isDepositPlanError"
                    >
                        {{ $t('widgets.load.error.message') }}
                    </div>
                    <div
                        class="col-12"
                        v-else-if="depositsLoaded && deposits.length > 0"
                    >
                        <table
                            :class="['table table-striped']"
                            id="invoiceList-content"
                        >
                            <tbody>
                                <tr>
                                    <th data-name="Zeitraum">
                                        <span
                                            >{{ $t('widget.i.billCycle')
                                            }}</span
                                        >
                                    </th>
                                    <th data-name="Betrag">
                                        <span>{{ $t('widget.i.amount') }}</span>
                                    </th>
                                </tr>
                                <tr v-for="deposit in deposits">
                                    <td
                                        :data-name="$t('widget.i.billCycle')"
                                        v-if="deposit.value && deposit.date"
                                    >
                                        {{deposit.date | monthYearHum}}
                                    </td>
                                    <td
                                        :data-name="$t('widget.i.amount')"
                                        v-if="deposit.value && deposit.date"
                                    >
                                        {{ deposit.value | euro }} {{ $t('euro')
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12" v-else>
                        <span>{{$t('widget.dp.no.deposits')}}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</div>
