<div class="col-12 mobile-modal__inner tariff-change">
    <div class="tariff-change__tab">{{ tariffBranding }}</div>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div class="row" v-for="tariff in tariffChangeList">
                <div class="col">
                    <div class="mobile-modal__wrap">
                        <div class="row">
                            <div class="col-12 row mt-5">
                                <div
                                    class="
                                        col-md-5 col-12
                                        tariff-change__inner
                                        text-md-left text-center
                                    "
                                >
                                    <div
                                        v-if="tariff.title"
                                        class="tariff-change__inner-title"
                                    >
                                        <strong>Q.ENERGY</strong> {{ tariffTitle
                                        }}
                                    </div>
                                    <template
                                        v-if="tariff.addons.length === 1 && isDefaultAddon(tariff.addons[0])"
                                    >
                                        <div>
                                            <strong class="tariff-change__plus"
                                                >+</strong
                                            >
                                        </div>
                                        <div class="tariff-change__card">
                                            <img
                                                :src="parseImage(tariff.addons[0].cmsProperties)"
                                            />
                                        </div>
                                    </template>
                                </div>
                                <div
                                    class="
                                        col-md-4 col-12
                                        tariff-change__inner
                                        mt-3 mt-md-0
                                    "
                                >
                                    <div>
                                        <div
                                            v-html="tariff.description"
                                            class="tariff-change__list"
                                        ></div>
                                        <div
                                            :class="[
                                                showDetails ? 'tariff-change__button-details--active' : 'tariff-change__button-details',
                                                'd-none d-md-block'
                                            ]"
                                            @click="showDetails = !showDetails"
                                        >
                                            {{ $t('widget.tc.tariff.details') }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="
                                        col-md-3 col-12
                                        tariff-change__inner
                                        text-md-right text-center
                                    "
                                >
                                    <div class="tariff-change__price">
                                        {{ tariff.deposit | euroFull }} €
                                    </div>
                                    <div class="tariff-change__label-price">
                                        {{ $t('widget.dc.monthly') }}
                                    </div>
                                    <button
                                        class="tariff-change__button"
                                        @click="openModal"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.tc.change.now') }}
                                    </button>
                                </div>
                                <div
                                    class="
                                        col-md-4 col-12
                                        tariff-change__inner
                                        d-flex
                                        justify-content-center
                                        mt-md-0 mt-5
                                        d-md-none d-block
                                    "
                                >
                                    <div>
                                        <div
                                            :class="[
                                                showDetails ? 'tariff-change__button-details--active' : 'tariff-change__button-details',
                                            ]"
                                            @click="showDetails = !showDetails"
                                        >
                                            {{ $t('widget.tc.tariff.details') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal -->
                            <div
                                class="tariff-change__modal"
                                v-if="showModal"
                                @click="closeModal"
                            >
                                <div
                                    class="tariff-change__modal-content"
                                    @click="preventClose"
                                >
                                    <div
                                        class="
                                            tariff-change__modal-header
                                            d-flex
                                            justify-content-between
                                        "
                                    >
                                        <h5 class="tariff-change__modal-title">
                                            {{ $t('widget.tc.modal.title') }}
                                        </h5>
                                        <i
                                            @click="closeModal"
                                            class="fa fa-times"
                                        ></i>
                                    </div>
                                    <div class="sub-header">
                                        <strong>Q.ENERGY</strong> {{ tariffTitle
                                        }}
                                    </div>
                                    <div class="tariff-change__modal-body mt-3">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 row">
                                                <div
                                                    class="
                                                        col-12
                                                        mb-2
                                                        font-weight-bold
                                                    "
                                                >
                                                    {{
                                                    $t('widget.tc.personal.data')
                                                    }}
                                                </div>
                                                <div class="col-12">
                                                    {{ fullName }}
                                                </div>
                                                <div class="col-12">
                                                    {{ address }}
                                                </div>
                                                <div class="col-12">
                                                    {{ customer.zip }} {{
                                                    customer.city }}
                                                </div>
                                                <div class="col-12">
                                                    {{ customer.email }}
                                                </div>
                                                <div class="col-12">
                                                    {{ customer.phone }}
                                                </div>
                                            </div>
                                            <div
                                                class="col-12 col-sm-6"
                                                v-if="paymentDetails.iban !== '' && paymentDetails.iban !== null"
                                            >
                                                <div
                                                    class="
                                                        col-12
                                                        mb-2
                                                        font-weight-bold
                                                    "
                                                >
                                                    {{
                                                    $t('widget.tc.payment.data')
                                                    }}
                                                </div>
                                                <div class="col-12 row">
                                                    <div
                                                        class="col-12 col-md-4"
                                                    >
                                                        {{
                                                        $t('widget.cpd.account')
                                                        }}
                                                    </div>
                                                    <div
                                                        class="col-12 col-md-8"
                                                    >
                                                        {{
                                                        paymentDetails.accountHolder
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="col-12 row">
                                                    <div
                                                        class="col-12 col-md-4"
                                                    >
                                                        {{ $t('widget.cpd.iban')
                                                        }}
                                                    </div>
                                                    <div
                                                        class="col-12 col-md-8"
                                                    >
                                                        {{ paymentDetails.iban
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="col-12 row">
                                                    <div
                                                        class="col-12 col-md-4"
                                                    >
                                                        {{ $t('widget.cpd.bank')
                                                        }}
                                                    </div>
                                                    <div
                                                        class="col-12 col-md-8"
                                                    >
                                                        {{ paymentDetails.bank
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div
                                                class="
                                                    col-12
                                                    mb-3
                                                    font-weight-bold
                                                "
                                            >
                                                {{
                                                $t('widget.tc.tariff.details')
                                                }}
                                            </div>
                                            <div class="col-12 col-sm-6 row">
                                                <div
                                                    class="col-12 col-md-4"
                                                    v-if="(tariff.productBonus > 0 && tariff.productBonus !== null) || (tariff.productBonusPercent > 0 && tariff.productBonusPercent !== null)"
                                                >
                                                    {{
                                                    $t('widget.to.productBonus')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-8"
                                                    v-if="(tariff.productBonus > 0 && tariff.productBonus !== null) || (tariff.productBonusPercent > 0 && tariff.productBonusPercent !== null)"
                                                >
                                                    <strong
                                                        v-if="tariff.productBonus > 0"
                                                    >
                                                        {{ tariff.productBonus |
                                                        euro }} €
                                                    </strong>
                                                    <strong
                                                        v-else-if="tariff.productBonusPercent > 0"
                                                    >
                                                        {{
                                                        tariff.productBonusPercent
                                                        | euroFull }} %
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-4 mt-2"
                                                >
                                                    {{ $t('widget.to.basePrice')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-8 mt-2"
                                                >
                                                    <strong>
                                                        {{
                                                        tariff.basePriceBruttoMonth
                                                        | euro }} € / {{
                                                        $t('widget.tc.month') }}
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-4"
                                                ></div>
                                                <div class="col-12 col-md-8">
                                                    <span
                                                        class="small-desc ms-0"
                                                    >
                                                        ({{
                                                        tariff.basePriceBrutto |
                                                        euro }} EUR / {{
                                                        $t('widget.tc.year') }})
                                                    </span>
                                                </div>
                                                <div
                                                    class="col-12 col-md-4 mt-2"
                                                >
                                                    {{
                                                    $t('widget.to.workingPrice')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-8 mt-2"
                                                >
                                                    <strong
                                                        >{{
                                                        tariff.workingPriceBrutto
                                                        | euro }} {{
                                                        $t('widget.tc.cent') }}
                                                        / {{ commodity }}
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-4 mt-2"
                                                >
                                                    {{
                                                    $t('widget.tc.total.costs')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-8 mt-2"
                                                >
                                                    <strong>
                                                        {{
                                                        tariff.totalBasePriceBonusBruttoMonth
                                                        | euro }} € / {{
                                                        $t('widget.tc.month' )}}
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-4"
                                                ></div>
                                                <div class="col-12 col-md-8">
                                                    <span
                                                        class="small-desc ms-0"
                                                    >
                                                        ({{
                                                        tariff.totalBasePriceBonusBrutto
                                                        | euro }} EUR / {{
                                                        $t('widget.tc.year') }})
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 row">
                                                <div
                                                    class="col-12 col-md-5"
                                                    v-if="tariff.instantBonus > 0 && tariff.instantBonus !== null"
                                                >
                                                    {{
                                                    $t('widget.to.instantBonus')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-7"
                                                    v-if="tariff.instantBonus > 0 && tariff.instantBonus !== null"
                                                >
                                                    <strong>
                                                        {{ tariff.instantBonus |
                                                        euro }} €
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-5 mt-2"
                                                >
                                                    {{
                                                    $t('widget.tc.initital.runtime')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-7 mt-2"
                                                >
                                                    <strong>
                                                        {{
                                                        $tc(tariff.initialRuntimeType,
                                                        tariff.initialRuntime)
                                                        }}
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-5 mt-2"
                                                >
                                                    {{
                                                    $t('widget.tc.extension.runtime')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-7 mt-2"
                                                >
                                                    <strong>
                                                        {{
                                                        $tc(tariff.extensionTimeType,
                                                        tariff.extensionTime) }}
                                                    </strong>
                                                </div>
                                                <div
                                                    class="col-12 col-md-5 mt-2"
                                                >
                                                    {{
                                                    $t('widget.tc.cancellation.period')
                                                    }}:
                                                </div>
                                                <div
                                                    class="col-12 col-md-7 mt-2"
                                                >
                                                    <strong>
                                                        {{
                                                        $tc(tariff.cancellationPeriodType,
                                                        tariff.cancellationPeriod)
                                                        }}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div
                                                class="col-12 font-weight-bold"
                                            >
                                                {{
                                                $t('widget.tc.checkbox.title')
                                                }}
                                            </div>
                                            <div class="col-12">
                                                <div class="col-12 row mt-3">
                                                    <div
                                                        class="
                                                            form-col
                                                            m-login__signin-step-4__ccd-checkbox-second
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                form-group
                                                                form-check
                                                            "
                                                        >
                                                            <label
                                                                for="tc-checkbox-first-input"
                                                                class="
                                                                    form-check-label
                                                                "
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id="tc-checkbox-first-input"
                                                                    class="
                                                                        form-check-input
                                                                        mt-0
                                                                    "
                                                                    v-model="termsAccepted"
                                                                />
                                                                {{
                                                                $t('widget.tc.checkbox.first1')
                                                                }}
                                                                <a
                                                                    href="https://energie.q-cells.de/agb/"
                                                                    target="_blank"
                                                                >
                                                                    {{
                                                                    $t('footer.agb')
                                                                    }}
                                                                </a>
                                                                {{
                                                                $t('widget.tc.checkbox.first2')
                                                                }}
                                                                <a
                                                                    href="https://energie.q-cells.de/datenschutz/"
                                                                >
                                                                    {{
                                                                    $t('login.footer.privacy')
                                                                    }}
                                                                </a>
                                                                {{
                                                                $t('widget.tc.checkbox.first3')
                                                                }}
                                                                <i></i>
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div
                                                    class="col-12 row mt-3"
                                                    v-if="showOptInWidget"
                                                >
                                                    <div
                                                        class="
                                                            form-col
                                                            m-login__signin-step-4__ccd-checkbox-second
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                form-group
                                                                form-check
                                                            "
                                                        >
                                                            <label
                                                                for="ccd-checkbox-second-input"
                                                                class="
                                                                    form-check-label
                                                                "
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id="ccd-checkbox-second-input"
                                                                    class="
                                                                        form-check-input
                                                                        mt-0
                                                                    "
                                                                    v-model="allAdsAccepted"
                                                                />
                                                                {{
                                                                $t('widget.ccd.checkbox.second1')
                                                                }}
                                                                <a
                                                                    :href="'mailto:' + $t('widget.ccd.checkbox.second2')"
                                                                >
                                                                    {{
                                                                    $t('widget.ccd.checkbox.second2')
                                                                    }}
                                                                </a>
                                                                {{
                                                                $t('widget.ccd.checkbox.second3')
                                                                }} (optional)
                                                                <i></i>
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <messages-block
                                        :errors="errorInformationModal"
                                    ></messages-block>
                                    <div class="tariff-change__modal-footer">
                                        <button
                                            type="button"
                                            @click="submitCustom(tariff)"
                                            class="
                                                tariff-change__button
                                                text-uppercase
                                                ms-5
                                            "
                                            :disabled="submitPending"
                                        >
                                            <i class="fas fa-check"></i>{{
                                            $t('widget.tc.modal.submit') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="col-12 row mt-3 tariff-change__details"
                            v-if="showDetails"
                        >
                            <div class="col-12 col-sm-6 row">
                                <div
                                    class="col-12 col-md-4 mt-2"
                                    v-if="(tariff.productBonus > 0 && tariff.productBonus !== null) || (tariff.productBonusPercent > 0 && tariff.productBonusPercent !== null)"
                                >
                                    {{ $t('widget.to.productBonus') }}:
                                </div>
                                <div
                                    class="col-12 col-md-8 mt-2"
                                    v-if="(tariff.productBonus > 0 && tariff.productBonus !== null) || (tariff.productBonusPercent > 0 && tariff.productBonusPercent !== null)"
                                >
                                    <strong v-if="tariff.productBonus > 0">
                                        {{ tariff.productBonus | euro }} €
                                    </strong>
                                    <strong
                                        v-else-if="tariff.productBonusPercent > 0"
                                    >
                                        {{ tariff.productBonusPercent | euroFull
                                        }} %
                                    </strong>
                                </div>
                                <div class="col-12 col-md-4">
                                    {{ $t('widget.to.basePrice') }}:
                                </div>
                                <div class="col-12 col-md-8">
                                    <strong>
                                        {{ tariff.basePriceBruttoMonth | euro }}
                                        € / {{ $t('widget.tc.month') }}
                                    </strong>
                                    <span class="small-desc">
                                        ({{ tariff.basePriceBrutto | euro }} EUR
                                        / {{ $t('widget.tc.year') }})
                                    </span>
                                </div>
                                <div class="col-12 col-md-4 mt-2">
                                    {{ $t('widget.to.workingPrice') }}:
                                </div>
                                <div class="col-12 col-md-8 mt-2">
                                    <strong
                                        >{{ tariff.workingPriceBrutto | euro }}
                                        {{ $t('widget.tc.cent') }} / {{
                                        commodity }}
                                    </strong>
                                </div>
                                <div class="col-12 col-md-4 mt-2">
                                    {{ $t('widget.tc.total.costs') }}:
                                </div>
                                <div class="col-12 col-md-8 mt-2">
                                    <strong>
                                        {{ tariff.totalBasePriceBonusBruttoMonth
                                        | euro }} € / {{ $t('widget.tc.month'
                                        )}}
                                    </strong>
                                    <span class="small-desc">
                                        ({{ tariff.totalBasePriceBonusBrutto |
                                        euro }} EUR / {{ $t('widget.tc.year')
                                        }})
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 row">
                                <div
                                    class="col-12 col-md-5"
                                    v-if="tariff.instantBonus > 0 && tariff.instantBonus !== null"
                                >
                                    {{ $t('widget.to.instantBonus') }}:
                                </div>
                                <div
                                    class="col-col-12 col-md-77"
                                    v-if="tariff.instantBonus > 0 && tariff.instantBonus !== null"
                                >
                                    <strong>
                                        {{ tariff.instantBonus | euro }} €
                                    </strong>
                                </div>
                                <div class="col-12 col-md-5 mt-2">
                                    {{ $t('widget.tc.initital.runtime') }}:
                                </div>
                                <div class="col-12 col-md-7 mt-2">
                                    <strong>
                                        {{ $tc(tariff.initialRuntimeType,
                                        tariff.initialRuntime) }}
                                    </strong>
                                </div>
                                <div class="col-12 col-md-5 mt-2">
                                    {{ $t('widget.tc.extension.runtime') }}:
                                </div>
                                <div class="col-12 col-md-7 mt-2">
                                    <strong>
                                        {{ $tc(tariff.extensionTimeType,
                                        tariff.extensionTime) }}
                                    </strong>
                                </div>
                                <div class="col-12 col-md-5 mt-2">
                                    {{ $t('widget.tc.cancellation.period') }}:
                                </div>
                                <div class="col-12 col-md-7 mt-2">
                                    <strong>
                                        {{ $tc(tariff.cancellationPeriodType,
                                        tariff.cancellationPeriod) }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <messages-block
                        :success="successInformation"
                        :errors="errorInformation"
                    ></messages-block>
                </div>
            </div>
        </div>
        <div
            class="tariff-change__footer"
            v-html="tariffAgb"
            v-if="showDetails"
        ></div>
    </div>
</div>
<!--</div>-->
