import { AccountBalanceWidget } from '@/components/widgets/account-balance-widget/account-balance-widget';
import { AccountBalanceWidgetSchweizstrom } from '@/components/widgets/account-balance-widget/custom/schweizstrom';
import { AccountBalanceWidgetFullClickable } from '@/components/widgets/account-balance-widget/custom/full-clickable';

const widgetTypes = {
    schweizstrom: AccountBalanceWidgetSchweizstrom,
    elli: AccountBalanceWidgetFullClickable,
};

const widget =
    widgetTypes[process.env.VUE_APP_ENVIRONMENT as string] ??
    AccountBalanceWidget;

export { widget as AccountBalanceWidget };
