<div class="col-12 mobile-modal__inner border-radius-rb">
    <div class="m-portlet m-portlet--mobile m-portlet--overview">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="box-title static-title static-title--custom"
                        >{{ $t('widget.do.title') }}</span
                    >
                    <div>
                        <span class="btn btn-link btn-link-custom"
                            >{{ $t('widget.dc.title') }}</span
                        >
                    </div>
                    <div class="mobile-modal__wrap">
                        <table
                            :class="['table table-striped', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                            id="invoiceList-content"
                        >
                            <tbody>
                                <tr>
                                    <th :data-name="$t('widget.to.activeFrom')">
                                        <span
                                            >{{ $t('widget.to.activeFrom')
                                            }}</span
                                        >
                                    </th>
                                    <th
                                        :data-name="$t('widget.to.activeUntil')"
                                    >
                                        <span
                                            >{{ $t('widget.to.activeUntil')
                                            }}</span
                                        >
                                    </th>
                                    <th :data-name="$t('widget.to.basePrice')">
                                        <span
                                            >{{ $t('widget.to.basePrice')
                                            }}</span
                                        >
                                    </th>
                                    <th :data-name="$t('widget.do.useAt')">
                                        <span>{{ $t('widget.do.useAt') }}</span>
                                    </th>
                                </tr>
                                <tr
                                    v-for="deposit in list"
                                    :class="{'active': deposit.active}"
                                >
                                    <td :data-name="$t('widget.to.activeFrom')">
                                        {{ deposit.activeFrom | date }}
                                    </td>
                                    <td
                                        :data-name="$t('widget.to.activeUntil')"
                                    >
                                        {{ deposit.activeUntil | date }}
                                    </td>
                                    <td :data-name="$t('widget.to.basePrice')">
                                        {{ deposit.grossAmount }} {{ $t('euro')
                                        }}
                                    </td>
                                    <td :data-name="$t('widget.do.useAt')">
                                        <template
                                            v-if="deposit && deposit.nextExecution"
                                            >{{ deposit.nextExecution.useAt |
                                            date }}</template
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
