import { AccountTransactionsWidget } from '../account-transactions-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './bsw.html';

@WithRender
@Component({})
export class AccountTransactionsWidgetBsw extends AccountTransactionsWidget {
    public getClassReceivable(receivable: Record<any, any>): string[] {
        const list: string[] = [];

        if (receivable.overdue) {
            list.push('transactionOverdue');
        }
        if (receivable.active) {
            list.push('active');
        }
        if (receivable.notFulfilled > 0) {
            list.push('transactionNotFulfilled');
        } else {
            list.push('transactionFulfilled');
        }

        return list;
    }
}
