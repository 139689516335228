import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './tariff-overview-widget.html';
import { ContentLoader } from 'vue-content-loader';

import moment from 'moment';

import { Watch } from 'vue-property-decorator';

import Format from '@/interfaces/Format';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import _ from 'lodash';
import Commodity from '@/interfaces/Commodity';
import { TranslateResult } from 'vue-i18n';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
        monthYearHum: formatProvider.monthYearHum(),
    },
    components: {
        ContentLoader,
    },
    props: {
        showPriceInformationAsterisk: {
            type: Boolean,
            default: false,
        },
    },
})
export class TariffOverviewWidget extends Base {
    private list = [];

    protected elements = {
        bsw: ['hide-bonus', 'hide-instant-bonus', 'show-tariff-overview-icon'],
        waermeenergie: [
            'show-tariff-overview-icon',
            'hide-close-button',
            'do-not-format-bonus',
            'do-not-format-instant-bonus',
            'scrollable-table',
            'loading-indicator',
        ],
        eoptimum: [
            'hide-tariff-title',
            'show-active-on',
            'hide-bonus',
            'hide-instant-bonus',
        ],
    };

    get tariffList(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).oldList;
    }

    get listSorted(): Record<any, any> {
        return _.orderBy(this.list, 'activeFrom', ['desc']);
    }

    get commodity(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityType(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract,
                'tariff-overview'
            )
        );
    }

    get commodityPrice(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }

    @Watch('tariffList')
    protected onTariffListChange(newVal): void {
        this.prepareTariffList(newVal);
    }

    protected created(): void {
        this.prepareTariffList(this.tariffList);
        if (this.tariffOverviewAllowed) {
            this.$store.dispatch(
                'tariff/list',
                this.$store.state.contracts.contractId
            );
        }
        if (this.tariffChangeAllowed) {
            this.$store.dispatch(
                'tariff/forChange',
                this.$store.state.contracts.contractId
            );
        }
    }

    get tariffChangeAllowed(): boolean {
        return this.$store.state.settings.tariffChange;
    }

    get tariffOverviewAllowed(): boolean {
        return this.$store.state.settings.tariffOverview;
    }

    get isReadingDetailsLoading() {
        return (
            this.$store.getters['reading/getState'](
                this.$store.state.contracts.contractId
            ).isReadingDetailsLoading &&
            this.displayElement('loading-indicator')
        );
    }

    protected isTariffInFuture(tariff) {
        const todayDate = moment();
        const futureDate = moment(tariff.activeFrom, 'YYYY-MM-DD');

        return !todayDate.isAfter(futureDate);
    }

    protected prepareTariffList(list): void {
        this.list = list.map((obj) => ({ ...obj, active: false }));
    }

    protected activate(item: Record<any, any>): void {
        item.active = !item.active;
    }
}
