import { BaseFormElement } from '../BaseFormElement';
import { default as Component } from 'vue-class-component';
import WithRender from './input.html';
import { Prop } from 'vue-property-decorator';

@WithRender
@Component({
    components: {},
})
export class CustomInput extends BaseFormElement {
    @Prop({ default: 'text' }) public type!: string;
    mounted() {
        this.$el.setAttribute('data-test', this.getId);
    }
    public disabled(): boolean {
        if (this.$store.state.settings.formSettings[this.getForm]) {
            if (
                this.$store.state.settings.formSettings[
                    this.getForm
                ].disabled.includes(this.getId)
            ) {
                this.$emit('disabled', true);
                return true;
            }
        }
        this.$emit('disabled', false);
        return false;
    }
}
