import { DepositOverviewWidgetSchweizstrom } from '@/components/widgets/deposit-overview-widget/custom/schweizstrom';
import { DepositOverviewWidget } from '@/components/widgets/deposit-overview-widget/deposit-overview-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = DepositOverviewWidgetSchweizstrom;
} else {
    widget = DepositOverviewWidget;
}
export { widget as DepositOverviewWidget };
