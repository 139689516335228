<div class="tariff-recommendation">
    <div class="mobile-modal">
        <div
            class="col-12 mobile-modal__inner mt-4 tariff-recommendation__more-info"
        >
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__body">
                    <div class="row">
                        <div class="col">
                            <span class="box-title static-title">
                                {{ $t('widget.tr.title') }}
                            </span>
                            <div class="mobile-modal__wrap">
                                <template v-for="tariff in list">
                                    <div
                                        class="row mt-4"
                                        :key="tariff.productCode"
                                    >
                                        <div class="col-4">
                                            <div
                                                class="tariff-rec-details__product-name"
                                            >
                                                <span>{{ tariff.title }}</span>
                                            </div>
                                            <div
                                                class="tariff-rec-details__description-title"
                                            >
                                                <span
                                                    >{{
                                                    $t('widget.tr.description')
                                                    }}</span
                                                >
                                            </div>
                                            <div
                                                class="tariff-rec-details__description"
                                                v-html="tariff.description"
                                            ></div>
                                        </div>
                                        <div class="col-8">
                                            <div class="tariff-rec__prices">
                                                <span
                                                    class="tariff-rec__prices--monthly"
                                                >
                                                    {{
                                                    tariff.totalBasePriceBonusBruttoMonth
                                                    | euro }} {{
                                                    $t('euro.short') }}/{{
                                                    $t('widget.tc.month') }}
                                                </span>
                                                <span>
                                                    ({{
                                                    tariff.totalBasePriceBonusBrutto
                                                    | euro }} {{
                                                    $t('euro.short') }}/{{
                                                    $t('widget.tc.year') }})<sup
                                                        >1</sup
                                                    >
                                                </span>
                                            </div>
                                            <div
                                                class="tariff-recommendation__action--right mt-4"
                                            >
                                                <vue-button-spinner
                                                    class="btn btn-primary mt-4"
                                                    :is-loading="isLoading"
                                                    :disabled="isLoading"
                                                    :status="status"
                                                >
                                                    <span
                                                        @click="submit(tariff)"
                                                        >{{
                                                        $t('widget.tc.change.now')
                                                        }}</span
                                                    >
                                                </vue-button-spinner>
                                                <div
                                                    class="text-start position-relative ms-5 mt-4"
                                                >
                                                    <input
                                                        data-test="tandcs"
                                                        :id="'checkbox-'+tariff.productCode"
                                                        class="elli-checkbox-input"
                                                        type="checkbox"
                                                        v-model="tariff.confirmationCheckbox"
                                                    />
                                                    <label
                                                        :for="'checkbox-'+tariff.productCode"
                                                        class="m-checkbox"
                                                        v-html="
                                                            $t('widget.tr.rules', {
                                                              agb: $t('widget.tc.agb.'+ contract.energy).toString(),
                                                              link: $t('widget.tc.withdrawal.'+ contract.energy).toString()
                                                            })
                                                          "
                                                    >
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row tariff-details-block">
                                        <div class="col-12">
                                            <!--  Tariff details  -->
                                            <div
                                                class="elli-tariff-details__toggle"
                                                @click="toggleDetails(tariff)"
                                            >
                                                {{
                                                $t('widget.tr.tariff.details')
                                                }}
                                                <i
                                                    class="fa fa-chevron-down"
                                                ></i>
                                            </div>

                                            <div
                                                class="show-tariff-details-wrapper"
                                            >
                                                <div
                                                    class="row"
                                                    v-if="getDetailsToggle(tariff)"
                                                >
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                {{
                                                                $t('widget.tc.basePrice')
                                                                }}:
                                                            </div>
                                                            <div
                                                                class="col-6 values"
                                                            >
                                                                {{
                                                                tariff.basePriceBruttoMonth
                                                                | euro }} {{
                                                                $t('euro.symbol')
                                                                }}/{{
                                                                $t('widget.tc.month')
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                {{
                                                                $t('widget.to.workingPrice')
                                                                }}:
                                                            </div>
                                                            <div
                                                                class="col-6 values"
                                                            >
                                                                {{
                                                                tariff.workingPriceBrutto
                                                                | euro}} {{
                                                                $t('unit.centperelectricity')
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                {{
                                                                $t('widget.tc.total.costs')
                                                                }}:
                                                            </div>
                                                            <div
                                                                class="col-6 values"
                                                            >
                                                                {{
                                                                tariff.totalBasePriceBonusBruttoMonth
                                                                | euro }} {{
                                                                $t('euro.symbol')
                                                                }}/{{
                                                                $t('widget.tc.month')
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                {{
                                                                $t('widget.td.minRuntime')
                                                                }}:
                                                            </div>
                                                            <div
                                                                class="col-6 values"
                                                            >
                                                                {{
                                                                tariff.contractInitialDuration
                                                                }} {{
                                                                getDurationType(tariff.contractInitialDurationType,
                                                                tariff.contractInitialDuration)
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                {{
                                                                $t('widget.td.extRuntime')
                                                                }}:
                                                            </div>
                                                            <div
                                                                class="col-6 values"
                                                            >
                                                                {{
                                                                tariff.contractExtensionTime
                                                                }} {{
                                                                getDurationType(tariff.contractExtensionTimeType,
                                                                tariff.contractExtensionTime)
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                {{
                                                                $t('widget.td.cancellation.period')
                                                                }}:
                                                            </div>
                                                            <div
                                                                class="col-6 values"
                                                            >
                                                                {{
                                                                tariff.contractCancellationPeriod
                                                                }} {{
                                                                getDurationType(tariff.contractCancellationPeriodType,
                                                                parseInt(tariff.contractCancellationPeriod))}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <messages-block
                                    :success="successInformation"
                                    :errors="errorInformation"
                                ></messages-block>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <div
                                            class="elli-products-disclaimer-wrapper"
                                        >
                                            <div
                                                class="elli-products-disclaimer"
                                            >
                                                <div
                                                    class="elli-products-disclaimer__link"
                                                >
                                                    <a
                                                        :href="$t('widget.tc.agb.'+ contract.energy)"
                                                        target="_blank"
                                                        >{{ $t('footer.agb')
                                                        }}</a
                                                    >
                                                </div>

                                                <div
                                                    v-if="contract.energy === 'electricity'"
                                                    class="elli-products-disclaimer__link"
                                                >
                                                    <a
                                                        :href="$t('widget.tc.leveling.power.link')"
                                                        target="_blank"
                                                        >{{
                                                        $t('widget.tr.electricity.labeling')
                                                        }}</a
                                                    >
                                                </div>

                                                <div
                                                    class="elli-products-disclaimer__price-level"
                                                    v-html="$t('widget.tr.price.level.' + contract.energy)"
                                                ></div>

                                                <div
                                                    class="elli-products-disclaimer__disclaimer-desc--one-star"
                                                    v-html="$t('widget.tr.star', {
                                                                    postal: postalCode,
                                                                    price: $options.filters.euro(list[0].totalBasePriceBonusBruttoMonth),
                                                                    usage: usage,
                                                                })"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
