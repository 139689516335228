import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './app.html';

@WithRender
@Component({})
export class App extends Base {
    public created(): void {
        let locale = this.$i18n.locale;
        if (locale === 'us') {
            locale = 'en';
        }
        const html = document.documentElement;
        html.setAttribute('lang', locale);

        if (['advizzo', 'wdenergy-us'].includes(this.currentClient)) {
            // @ts-ignore
            const advBundle = document.createElement('script');
            advBundle.setAttribute(
                'src',
                'https://adv-cdn.net/widgets/staging/bundle.js'
            );
            document.head.appendChild(advBundle);
        }
    }
}
