import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Reading from '../interfaces/Reading';
import SubmitReading from '../interfaces/SubmitReading';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class ReadingProvider
    extends PrototypeProvider
    implements Reading
{
    public async next(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/reading/' + id + '/next', this.config);
    }

    public async history(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/reading/' + id + '/history', this.config);
    }

    public async details(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/reading/' + id + '/details', this.config);
    }

    public async submitMeterRead(data: SubmitReading): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/reading', data, this.config);
    }

    public async invoiceSimulate(
        contractId: number,
        dateUntil: string
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/payment/invoice/simulate/' + contractId,
            {
                dateUntil,
            },
            this.config
        );
    }
}
