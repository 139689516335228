// -----------------------------
//   Contracts Data structure
// -----------------------------

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import Auth from '../../interfaces/Auth';
import Contracts from '../../interfaces/Contracts';

import * as Sentry from '@sentry/browser';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

// Data Structure
const state: {
    onLoad: boolean;
    list: null | [];
    communications: [];
    contractId: null | number;
    sortContractsById: boolean;
    addonStatus: {
        hasLoaded: boolean;
        active: boolean;
        trackingLink: string;
        imgLink: string;
        status: string;
        documentId: string;
        bonus: string;
    };
    products: any;
} = {
    onLoad: true,
    list: null,
    communications: [],
    contractId: null,
    sortContractsById: false,
    addonStatus: {
        hasLoaded: false,
        active: true,
        trackingLink: '',
        imgLink: '',
        status: '',
        documentId: '',
        bonus: '',
    },
    products: null,
};

// Outside methods for communication with data.
const actions = {
    async setContractId({ commit }, id) {
        commit('SET_CONTRACT_ID', id);
    },

    async setSortContractsById({ commit }, value: boolean) {
        commit('SET_SORT_CONTRACTS_BY_ID', value);
    },

    async contracts({ commit, dispatch }) {
        if (authProvider.isAuthenticated()) {
            await contractsProvider.contracts().then(
                (response) => {
                    if (response.data) {
                        if (response.data.length > 0) {
                            commit('SORT_CONTRACTS_BY_ID', response.data);
                            response.data.forEach((element) => {
                                dispatch('payment/init', element.contractId, {
                                    root: true,
                                });
                                dispatch('reading/init', element.contractId, {
                                    root: true,
                                });
                                if (element.type === 'non_commodity') {
                                    dispatch(
                                        'tariff/init',
                                        element.contractId,
                                        {
                                            root: true,
                                        }
                                    ).then(() => {
                                        dispatch(
                                            'tariff/overview',
                                            parseInt(element.contractId, 10),
                                            {
                                                root: true,
                                            }
                                        );
                                    });
                                } else {
                                    dispatch(
                                        'tariff/init',
                                        element.contractId,
                                        {
                                            root: true,
                                        }
                                    );
                                }
                            });
                            commit('SET_CONTRACTS', response.data);
                        }
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
            if (Array.isArray(state.list)) {
                state.list.forEach(async (element: { contractId: any }) => {
                    if (
                        process.env.VUE_APP_ENVIRONMENT === 'waermeenergie' ||
                        process.env.VUE_APP_ENVIRONMENT === 'wdenergy'
                    ) {
                        contractsProvider
                            .contractDetails(element.contractId)
                            .then(
                                (response) => {
                                    if (response.data) {
                                        const newData = {
                                            contractId: element.contractId,
                                            startDate: response.data.startDate,
                                            desiredStartDate:
                                                response.data.desiredStartDate,
                                            endDate: response.data.endDate,
                                            desiredEndDate:
                                                response.data.desiredEndDate,
                                        };
                                        commit('ADD_CONTRACT_DATES', newData);
                                    }
                                },
                                (error) => {
                                    Sentry.captureException(new Error(error));
                                }
                            );
                    }
                });
            }
        }
    },

    async contractAddonStatus({ commit }, contractId) {
        if (authProvider.isAuthenticated()) {
            await contractsProvider.contractAddonStatus(contractId).then(
                (response) => {
                    if (response.data) {
                        commit('SET_CONTRACT_ADDON_STATUS', {
                            hasLoaded: true,
                            ...response.data,
                        });
                    }
                },
                (error) => {
                    commit('SET_CONTRACT_ADDON_STATUS', {
                        hasLoaded: true,
                        active: false,
                        link: '',
                        status: '',
                        documentId: '',
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },

    async products({ commit }, contractId) {
        if (authProvider.isAuthenticated()) {
            await contractsProvider.getProductsByContractId(contractId).then(
                (response) => {
                    if (response.data) {
                        commit('SET_CONTRACT_PRODUCTS', {
                            hasLoaded: true,
                            list: Object.values(response.data),
                        });
                    }
                },
                (error) => {
                    commit('SET_CONTRACT_PRODUCTS', {
                        hasLoaded: true,
                        active: false,
                        link: '',
                        status: '',
                        documentId: '',
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async contractDetails({ commit }, id) {
        if (authProvider.isAuthenticated()) {
            await contractsProvider.contractDetails(id).then(
                () => {
                    // tbd
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
};

// Logic that change data
const mutations = {
    SET_CONTRACT_PRODUCTS(localState, data) {
        localState.products = data;
    },

    SET_CONTRACT_ADDON_STATUS(localState, data) {
        localState.addonStatus = data;
    },
    SET_CONTRACT_ID(localState, data) {
        localState.contractId = data;
    },

    SET_SORT_CONTRACTS_BY_ID(localState, data) {
        localState.sortContractsById = data;
    },

    SORT_CONTRACTS_BY_ID(localState, data) {
        if (localState.sortContractsById) {
            data.sort(
                (a, b) =>
                    parseInt(b.contractId, 10) - parseInt(a.contractId, 10)
            );
        }
    },

    SET_CONTRACTS(localState, data) {
        localState.onLoad = false;
        localState.list = [];
        data.forEach((element) => {
            localState.list.push({
                contractId: element.contractId
                    ? parseInt(element.contractId, 10)
                    : null,
                title: element.title ? element.title : null,
                street: element.street ? element.street : null,
                houseNumber: element.houseNumber ? element.houseNumber : null,
                zip: element.zip ? element.zip : null,
                city: element.city ? element.city : null,
                type: element.type ? element.type : null,
                energy: element.energy ? element.energy : null,
                status: element.status ? element.status : null,
                statusCode: element.statusCode
                    ? parseInt(element.statusCode, 10)
                    : null,
                statusCodeTitle: element.statusCodeTitle
                    ? element.statusCodeTitle
                    : null,
                startDate: null,
                endDate: null,
                desiredStartDate: null,
                desiredEndDate: null,
                extendedData: element.extendedData
                    ? element.extendedData
                    : null,
            });
        });
    },

    OVERWRITE_CONTRACT_TITLE(localState, data) {
        if (typeof localState.list === 'object') {
            const element = localState.list.filter(
                (e) => e.contractId === data.contractId
            );
            if (element.length === 1) {
                element[0].title = data.title;
            }
        }
    },

    ADD_CONTRACT_DATES(localState, data) {
        if (typeof localState.list === 'object') {
            const element = localState.list.filter(
                (e) => e.contractId === data.contractId
            );
            if (element.length === 1) {
                element[0].startDate = data.startDate;
                element[0].desiredStartDate = data.desiredStartDate;
                element[0].endDate = data.endDate;
                element[0].desiredEndDate = data.desiredEndDate;
            }
        }
    },
};

// Get Data transformation (like computed)
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
