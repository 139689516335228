import vue from 'vue';
import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './customer-service-form-widget.html';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import * as Sentry from '@sentry/browser';

import Auth from '@/interfaces/Auth';
import Contact from '@/interfaces/Contact';
import SubmitContact from '@/interfaces/SubmitContact';

import SERVICES from '@/container/Services';
import { Watch } from 'vue-property-decorator';
import wcmatch from 'wildcard-match';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contactProvider = IocContainer.get<Contact>(SERVICES.CONTACT);

@WithRender
@Component({
    components: {
        Treeselect,
        'messages-block': MessagesBlock,
    },
})
export class CustomerServiceFormWidget extends Base {
    protected submitPending = false;
    protected errorInformation: Record<any, any> = [];
    protected successInformation: Record<any, any> = [];
    protected details: SubmitContact = {
        clientKey: null,
        contractId: null,
        customerId: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        categoryId: null,
        categoryName: null,
        text: null,
        documentContentTypes: [],
        documentFileNames: [],
        documents: [],
    };

    protected elements = {
        ets: ['hide-email-field'],
        wdenergy: ['show-service-form-icon'],
        'wdenergy-us': ['show-service-form-icon'],
        prokon: [
            'hide-close-button',
            'submit-align-right',
            'hide-contract-id-field',
        ],
        elli: ['show-service-form-icon'],
        eoptimum: ['hide-close-button', 'show-privacy-notes'],
    };

    get contractId(): number | null {
        const list = this.$store.state.contracts.list;

        if (list) {
            return list[list.length - 1].contractId;
        } else {
            return null;
        }
    }

    get categories(): Record<any, any> {
        const productCode = this.currentProduct.code;
        if (productCode) {
            const isMatchDealer = wcmatch('THG_*_Haendlerprovision');
            const isMatchB2C = wcmatch('THG_*_*');
            if (isMatchDealer(productCode)) {
                return [
                    {
                        id: 0,
                        label: 'Fragen zum Produkt',
                    },
                    {
                        id: 1,
                        label: 'Auszahlung der Provision',
                    },
                    {
                        id: 2,
                        label: 'Kundenportal',
                    },
                    {
                        id: 3,
                        label: 'Feedback',
                    },
                    {
                        id: 4,
                        label: 'Sonstiges',
                    },
                ];
            } else if (isMatchB2C(productCode)) {
                return [
                    {
                        id: 0,
                        label: 'Fragen zum Produkt',
                    },
                    {
                        id: 1,
                        label: 'Auszahlung der Prämie',
                    },
                    {
                        id: 2,
                        label: 'Kundenportal',
                    },
                    {
                        id: 3,
                        label: 'Feedback',
                    },
                    {
                        id: 4,
                        label: 'Sonstiges',
                    },
                ];
            }
        }

        return this.parseCategories(this.$store.state.contact.categories);
    }

    get customer(): Record<any, any> {
        return this.$store.state.customer;
    }

    @Watch('customer.id')
    public customerLoaded(): void {
        this.details.customerId = this.customerDetails.id;
        this.details.firstname = this.customerDetails.firstname;
        this.details.lastname = this.customerDetails.surname;
        this.details.email =
            this.customerDetails.emailPrivate ||
            this.customerDetails.emailBusiness;
        this.details.phone = this.customerDetails.phone;
    }

    protected handleSelectCategory(value): void {
        this.details.categoryName = value.label;
    }

    protected created(): void {
        this.$store.dispatch('contact/categories');
        this.details = {
            clientKey: null,
            contractId: this.contractId,
            customerId: this.customerDetails.id,
            firstname: this.customerDetails.firstname,
            lastname: this.customerDetails.surname,
            email:
                this.customerDetails.emailPrivate ||
                this.customerDetails.emailBusiness,
            phone: this.customerDetails.phone || null,
            categoryId: null,
            categoryName: null,
            text: null,
            documentContentTypes: [],
            documentFileNames: [],
            documents: [],
        };
    }

    protected submit(skipAuthentication = false): void {
        if (authProvider.isAuthenticated() || skipAuthentication) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];

            if (
                !this.displayElement('hide-email-field') &&
                (!this.details.email || !this.validateEmail(this.details.email))
            ) {
                this.submitPending = false;
                this.errorInformation.push({
                    key: '',
                    message: this.$t('widget.cs.invalidEmail').toLocaleString(),
                });
                return;
            }

            if (this.details.categoryId === null) {
                this.submitPending = false;
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'widget.cs.topic.choose.error'
                    ).toLocaleString(),
                });
                return;
            }

            if (!this.details.text) {
                this.submitPending = false;
                this.errorInformation.push({
                    key: '',
                    message: this.$t('widget.cs.text.error').toLocaleString(),
                });
                return;
            }

            contactProvider.submit(this.details).then(
                (response) => {
                    this.submitPending = false;
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        this.details.categoryId = null;
                        this.details.categoryName = null;
                        this.details.text = null;
                        this.details.documentContentTypes = [];
                        this.details.documentFileNames = [];
                        this.details.documents = [];
                        // @ts-ignore
                        this.$refs.fileList.value = '';

                        this.details.firstname = this.customerDetails
                            ? this.customerDetails.firstname
                            : null;
                        this.details.lastname = this.customerDetails
                            ? this.customerDetails.surname
                            : null;
                        this.details.contractId = this.contractId;
                        this.details.customerId = this.customerDetails
                            ? this.customerDetails.id
                            : null;
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            response.data.error &&
                            response.data.error.length > 0
                        ) {
                            this.errorInformation = response.data.error;
                        } else if (
                            response.data.error &&
                            typeof response.data.error === 'object'
                        ) {
                            this.errorInformation.push(response.data.error);
                        } else if (
                            response.data.error &&
                            typeof response.data.error === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.error,
                            });
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation = [];
                    if (error && error.response && error.response.data) {
                        if (error.response.data.errors) {
                            for (const prop in error.response.data.errors) {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        error.response.data.errors,
                                        prop
                                    )
                                ) {
                                    for (const idx in error.response.data
                                        .errors[prop]) {
                                        if (
                                            Object.prototype.hasOwnProperty.call(
                                                error.response.data.errors[
                                                    prop
                                                ],
                                                idx
                                            )
                                        ) {
                                            if (
                                                typeof error.response.data
                                                    .errors[prop][idx] ===
                                                'object'
                                            ) {
                                                this.errorInformation.push(
                                                    error.response.data.errors[
                                                        prop
                                                    ][idx]
                                                );
                                            } else if (
                                                typeof error.response.data
                                                    .errors[prop][idx] ===
                                                'string'
                                            ) {
                                                this.errorInformation.push({
                                                    key: '',
                                                    message:
                                                        error.response.data
                                                            .errors[prop][idx],
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (
                            error.response.data.message &&
                            typeof error.response.data.message === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: error.response.data.message,
                            });
                        }
                    }
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }

    private processFile(event, i = 0): void {
        if (i === 0) {
            this.details.documentContentTypes = [];
            this.details.documentFileNames = [];
            this.details.documents = [];
        }

        if (event.target.files[i]) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                this.details.documentContentTypes.push(
                    event.target.files[i].type
                );
                this.details.documentFileNames.push(event.target.files[i].name);
                this.details.documents.push(reader.result);
                this.processFile(event, i + 1);
            };
        }
    }

    private parseCategories(
        categories: Record<any, any>,
        parent = null
    ): Record<any, any> {
        const structured: Record<any, any> = [];
        const childrenCategories = categories.filter(
            (e) => e.parent === parent
        );
        for (const index in childrenCategories) {
            if (childrenCategories.hasOwnProperty(index)) {
                let category = {};
                if (childrenCategories[index].externalId === null) {
                    category = {
                        id: childrenCategories[index].id + Math.random(),
                        label: childrenCategories[index].name,
                        children: this.parseCategories(
                            categories,
                            childrenCategories[index].id
                        ),
                    };
                } else {
                    category = {
                        id: childrenCategories[index].externalId,
                        label: childrenCategories[index].name,
                    };
                }
                structured.push(category);
            }
        }

        return structured;
    }

    private validateEmail(email): boolean {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
