import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './agent-view-widget.html';

@WithRender
@Component({})
export class AgentViewWidget extends Base {
    get shouldDisplayWidget(): boolean {
        return !!this.$cookies.get('SUPER-ADMIN-TOKEN');
    }
}
