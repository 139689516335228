import { default as Component } from 'vue-class-component';
import WithRender from './qcells.html';
import { Footer } from './../../../template/footer';
import { ResetPasswordWidget } from '@/components/widgets/reset-password-widget';

import { AuthPage as AuthPageParent } from '@/components/pages/auth/auth-page';

import { LoginPage } from '@/components/pages/auth/login-page';
import { RegisterPage } from '@/components/pages/auth/register';
import { CookieBannerWidgetQcells } from '@/components/widgets/cookie-banner-widget/custom/qcells';
import { RestoreTwoFactorAuthentication } from '@/components/pages/auth/2fa/restore';

@WithRender
@Component({
    components: {
        'app-footer': Footer,
        LoginPage,
        RegisterPage,
        ResetPasswordWidget,
        'cookie-banner-widget-qcells': CookieBannerWidgetQcells,
        'restore-two-factor-authentication': RestoreTwoFactorAuthentication,
    },
})
export class AuthPageQcells extends AuthPageParent {
    get isRegisterStepFour(): boolean {
        return this.isRegister && this.$store.state.auth.isStepFour;
    }
}
