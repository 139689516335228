import { Dashboard as DashboardParent } from './dashboard';
import { Dashboard as DashboardShell } from './custom/shell';
import { Dashboard as DashboardWaermeEnergie } from './custom/waermeenergie';
import { Dashboard as DashboardEts } from './custom/ets';
import { Dashboard as DashboardQcells } from './custom/qcells';
import { Dashboard as DashboardSachsenenergie } from './custom/sachsenenergie';
import { Dashboard as DashboardWdenergy } from './custom/wdenergy';
import { Dashboard as DashboardWdenergyUs } from './custom/wdenergy-us';
import { Dashboard as DashboardProkon } from './custom/prokon';
import { Dashboard as DashboardDspFlex } from './custom/dsp-flex';
import { Dashboard as DashboardSchweizstrom } from './custom/schweizstrom';
import { Dashboard as DashboardEoptimum } from './custom/eoptimum';
import { Dashboard as DashboardEwii } from './custom/ewii';
import { Dashboard as DashboardHsg } from './custom/hsg';
import { Dashboard as DashboardWechselstrom } from './custom/wechselstrom';
import { ElliGeneral as DashboardElliGeneral } from './custom/elli-general';
import { DashboardBsw } from './custom/bsw';
/*
    If DashboardVersion2 is used, you need to enter the VUE_APP_ENVIRONMENT in /widgets/tarif-details-widget.ts line 30 (protected created())
*/
export const Dashboard =
    {
        ets: DashboardEts,
        qcells: DashboardQcells,
        sachsenenergie: DashboardSachsenenergie,
        shell: DashboardShell,
        waermeenergie: DashboardWaermeEnergie,
        wdenergy: DashboardWdenergy,
        'wdenergy-us': DashboardWdenergyUs,
        prokon: DashboardProkon,
        'dsp-marl': DashboardDspFlex,
        'dsp-ahrensburg': DashboardDspFlex,
        'dsp-oie': DashboardDspFlex,
        'dsp-revulution': DashboardDspFlex,
        elli: DashboardElliGeneral,
        'elli-thg-audi': DashboardElliGeneral,
        'elli-thg-cupra': DashboardElliGeneral,
        'elli-thg-dealer': DashboardElliGeneral,
        'elli-thg-skoda': DashboardElliGeneral,
        'elli-thg-vw': DashboardElliGeneral,
        'elli-thg-vwfs': DashboardElliGeneral,
        'elli-thg-freenet': DashboardElliGeneral,
        'elli-thg-elli': DashboardElliGeneral,
        'elli-thg-man': DashboardElliGeneral,
        schweizstrom: DashboardSchweizstrom,
        eoptimum: DashboardEoptimum,
        ewii: DashboardEwii,
        bsw: DashboardBsw,
        hsg: DashboardHsg,
        wechselstrom: DashboardWechselstrom,
    }[process.env.VUE_APP_ENVIRONMENT!] || DashboardParent;
