<form-wrapper form="formPaymentData">
    <div class="mobile-modal__inner" data-test="change-payment-data-widget">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body mx-3">
                <div class="box-title static-title">
                    <i
                        class="fas fa-chalkboard-teacher readingHistory-icon"
                    ></i>
                    {{ $t('widget.cpd.title') }}
                </div>
                <div
                    id="paymentDataChange-content"
                    class="mobile-modal__wrap mx-0"
                >
                    <template v-if="isContractDetailsLoading">
                        <div class="col-12">
                            <content-loader
                                height="40"
                                primaryColor="var(--secondary)"
                                secondaryColor="var(--primary)"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="row"
                            v-if="!isContractDetailsLoading && isContractDetailsError"
                        >
                            <div class="col-12 text-center">
                                {{ $t('widgets.load.error.message') }}
                            </div>
                        </div>
                        <div v-else class="row mb-3">
                            <div class="col-12">
                                <form-group
                                    id="change-payment-data-widget__iban"
                                >
                                    <custom-label
                                        >{{ $t('widget.cpd.iban')
                                        }}</custom-label
                                    >
                                    <custom-input
                                        data-test="change-payment-data-widget-iban-input--iban"
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('widget.cpd.iban')"
                                        v-model="details.iban"
                                        @focus="details.iban=null"
                                    />
                                </form-group>
                            </div>
                            <div class="col-12">
                                <form-group
                                    id="change-payment-data-widget__bank"
                                >
                                    <custom-label for="payment_bank">
                                        {{ $t('widget.cpd.bank') }}
                                    </custom-label>
                                    <custom-input
                                        type="text"
                                        class="form-control"
                                        :placeholder="bicBankPlaceholder"
                                        :value="bic"
                                    />
                                </form-group>
                            </div>
                            <div
                                class="col-12"
                                v-if="details.activeFrom && details.iban === paymentDetails.iban && paymentDetails.iban !== null"
                            >
                                <b>{{ $t('widget.cpd.activeFrom') }}</b>: {{
                                details.activeFrom | date }}
                            </div>
                            <div class="col-12">
                                <form-group
                                    id="change-payment-data-widget__direct-debit"
                                    class="form-check"
                                >
                                    <custom-checkbox
                                        inputClass="form-check-input"
                                        v-model="details.directDebit"
                                        :options="checkboxOptions()"
                                        :labelClass="[
                                        'form-check-label',
                                        displayElement('branded-checkbox')
                                            ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                            : ''
                                    ]"
                                    />
                                </form-group>
                            </div>
                            <div
                                class="col-12"
                                v-if="paymentDetails.directDebit && (paymentDetails.iban === details.iban || sepaDeactivate)"
                            >
                                <form-group
                                    id="change-payment-data-widget__direct-debit"
                                    class="form-check"
                                >
                                    <custom-checkbox
                                        inputClass="form-check-input"
                                        v-model="details.directDebit"
                                        :options="checkboxOptionsDeactivate()"
                                        :labelClass="[
                                    'form-check-label',
                                    displayElement('branded-checkbox')
                                        ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                        : ''
                                ]"
                                    />
                                </form-group>
                            </div>
                            <div class="col-12">
                                <div
                                    class="form-group"
                                    v-if="sepaMandateActive && paymentDetails.sepaMandate"
                                >
                                    <label
                                        >{{ $t('widget.cpd.activeSepa')
                                        }}</label
                                    >
                                    <div class="fs-22">
                                        {{ paymentDetails.sepaMandate }}
                                    </div>
                                </div>
                                <div class="mb-4" v-if="sepaMandateActive">
                                    {{ $t('widget.cpd.payment.sepa.explanation')
                                    }}
                                </div>
                            </div>
                            <div class="col-12" v-if="fullIbanRequired">
                                <div class="alert alert-danger">
                                    {{ $t('widget.cpd.full.iban.required') }}
                                </div>
                            </div>
                            <div class="col-12" v-if="ibanInvalid">
                                <div class="alert alert-danger">
                                    {{ $t('widget.cpd.invalid.iban') }}
                                </div>
                            </div>
                            <div
                                class="col-6"
                                v-if="!displayElement('hide-submit-button')"
                            >
                                <div class="left">
                                    <a
                                        class="btn btn-link mt-auto ms-auto"
                                        @click="hideModalDeleteIban=!hideModalDeleteIban"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.cpd.delete') }}
                                    </a>
                                </div>
                            </div>
                            <div
                                class="col-6"
                                v-if="!displayElement('hide-submit-button')"
                            >
                                <div class="d-flex">
                                    <button
                                        class="btn btn-primary mt-auto ms-auto"
                                        @click="checkData"
                                        :disabled="submitDisabled"
                                    >
                                        {{ $t('widget.cpd.submit') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <messages-block
                    fromTestElement="change-payment-data-widget-messages"
                    :success="successInformation"
                    :errors="errorInformation"
                ></messages-block>
            </div>

            <!-- Begin Modal -->
            <div class="modale-delte-iban" v-if="!hideModalDeleteIban">
                <div
                    class="modal modal-delete-iban-custom"
                    tabindex="-1"
                    role="dialog"
                >
                    <div class="modal-dialog modal-dialog-delete">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    {{$t('widget.cpd.delete-modal-confirm-title')}}
                                </h5>
                                <button
                                    data-test="change-payment-data-widget-close-click"
                                    type="button"
                                    class="close"
                                    @click="hideModalDeleteIban=!hideModalDeleteIban"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>
                                    {{$t('widget.cpd.delete-modal-confirm-text')}}
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button
                                    data-test="change-payment-data-widget-save-click"
                                    type="button"
                                    class="btn btn-primary mt-auto ms-auto"
                                    @click="() => {
                    deletePaymentData()
                    hideModalDeleteIban=!hideModalDeleteIban
                }"
                                >
                                    {{$t('widget.cpd.delete') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal-delete-iban-custom-backdrop"
                        @click="hideModalDeleteIban=!hideModalDeleteIban"
                    ></div>
                </div>
                <!-- End Modal -->
            </div>
        </div>
    </div>
</form-wrapper>
