import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './thg-status-widget.html';
import { Prop } from 'vue-property-decorator';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';

@WithRender
@Component({})
export class ThgStatusWidget extends Base {
    @Prop() public bonusYear!: string;
    @Prop() public thgService!: ThgBaseService;

    get isPending(): boolean {
        return this.thgService.isStatusPendingForYear(this.bonusYear);
    }

    get isSecondStep(): boolean {
        return (
            this.thgService.isStatusVerifiedForYear(this.bonusYear) ||
            this.thgService.isStatusSentForYear(this.bonusYear)
        );
    }

    get isSecondStepWarning(): boolean {
        return this.thgService.isStatusErrorForYear(this.bonusYear);
    }

    get isSecondStepCanceled(): boolean {
        return this.thgService.isStatusNotVerifiedDuplicateForYear(
            this.bonusYear
        );
    }

    get isSecondStepComplete(): boolean {
        return this.isThirdStep || this.isLastStep;
    }

    get isRejected(): boolean {
        return this.thgService.isStatusRejectedForYear(this.bonusYear);
    }

    get isThirdStep(): boolean {
        return false;
    }

    get isThirdStepCompleted(): boolean {
        return this.isLastStep;
    }

    get isLastStep(): boolean {
        return this.thgService.isStatusConfirmedForYear(this.bonusYear);
    }
}
