<div
    v-if="shouldDisplayWidget"
    style="
        width: 100%;
        text-align: center;
        background-color: deeppink;
        color: white;
    "
>
    <span><b>{{ $t('agentview.active') }}</b></span>
</div>
