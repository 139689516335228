<form-wrapper form="formPaymentData">
    <div
        class="m-portlet m-portlet--mobile thg-car-data"
        data-test="thg-car-data-widget"
    >
        <div class="m-portlet__body">
            <div class="row">
                <div class="col-12">
                    <i class="fa fa-car icon-car-data"></i>
                    <span
                        class="monthlyDeposit-title static-title static-title--custom"
                    >
                        {{ $t('widget.tcd.title') }}
                    </span>
                </div>
            </div>

            <loading-spinner
                v-if="!hasLoaded"
                :size="40"
                color="#001e50"
                :center="true"
            />
            <template v-else>
                <div class="row mt-2" v-if="isContractCancelled">
                    <div class="col-12">
                        <div class="car-data-alert alert-error" role="alert">
                            {{ $t('widget.tcd.contract.cancelled') }}
                        </div>
                    </div>
                </div>
                <div
                    class="row mt-2"
                    v-else-if="statusPending || statusUnknown"
                >
                    <div class="col-12">
                        <div class="car-data-alert alert-warning" role="alert">
                            {{ $t('widget.tcd.pending') }}
                        </div>
                    </div>
                </div>

                <div class="row mt-2" v-else-if="statusUnsuccessful">
                    <div class="col-12">
                        <div class="car-data-alert alert-error" role="alert">
                            {{ $t('widget.tcd.uploadUnsuccessful') }}
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <form-group>
                                <custom-label>
                                    {{ $t('widget.tcd.registration.front') }}

                                    <image-modal
                                        :image="vehicleRegistration.front || ''"
                                        :loaded="hasLoaded"
                                    />
                                </custom-label>
                                <input
                                    class="form-control"
                                    accept="application/pdf,image/png, image/jpeg, image/gif, image/jpg"
                                    type="file"
                                    data-side="front"
                                    @change="onFileSelect"
                                />

                                <div class="folder-icon">
                                    <i class="far fa-folder-open"></i>
                                </div>

                                <div class="filesize-notice">
                                    <span>
                                        {{
                                        $t('widget.tcd.registration.filesize')
                                        }}
                                    </span>
                                </div>
                            </form-group>
                        </div>
                        <div class="col-6"></div>
                    </div>
                    <div class="row">
                        <div class="col text-end">
                            <button
                                class="btn btn-primary d-block button-upload-again"
                                @click="submitFiles"
                                :class="{
                                    disabled: filesAreSubmitting || fileIsUploading || uploadedFiles.length !== 1
                                }"
                            >
                                <loading-spinner
                                    :size="20"
                                    v-if="fileIsUploading || filesAreSubmitting"
                                    color="#fff"
                                />
                                <span v-else>
                                    {{ $t('widget.tcd.upload.again') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row mt-4" v-else-if="statusSuccessful">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <form-group>
                                    <custom-label
                                        >{{ $t('widget.tcd.fin')
                                        }}</custom-label
                                    >
                                    <custom-input
                                        type="text"
                                        readonly="true"
                                        :value="vehicleId"
                                    />
                                </form-group>
                            </div>
                            <div class="col-6">
                                <form-group>
                                    <custom-label
                                        >{{ $t('widget.tcd.mark')
                                        }}</custom-label
                                    >
                                    <custom-input
                                        type="text"
                                        v-model="formFields.licensePlate"
                                    />
                                    <div class="edit-license-plate">
                                        <i class="fas fa-pen"></i>
                                    </div>
                                </form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <form-group>
                                    <custom-label>
                                        {{ $t('widget.tcd.class') }}
                                    </custom-label>
                                    <custom-input
                                        type="text"
                                        readonly="true"
                                        :value="vehicleClass"
                                    />
                                </form-group>
                            </div>
                            <div class="col-6">
                                <form-group>
                                    <custom-label>
                                        {{ $t('widget.tcd.first.reg') }}
                                    </custom-label>
                                    <custom-input type="text" readonly="true" />
                                </form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <form-group>
                                    <custom-label>
                                        {{ $t('widget.tcd.registration.front')
                                        }}

                                        <image-modal
                                            :image="vehicleRegistration.front || ''"
                                            :loaded="hasLoaded"
                                        />
                                    </custom-label>
                                    <custom-input
                                        type="text"
                                        readonly="true"
                                        :value="$t(
                                            vehicleRegistration.front ? 
                                            'widget.ts.verifiedSuccessfully' :
                                            'widget.ts.noDocumentFound'
                                        )"
                                    />
                                </form-group>
                            </div>
                            <div class="col-6"></div>
                        </div>
                        <div class="row mt-2" v-if="!statusSuccessful">
                            <div class="col action">
                                <button
                                    :disabled="fieldsAreSubmitting"
                                    class="btn btn-primary d-block button-submit-fields"
                                    :class="{
                                        disabled: fieldsAreSubmitting
                                    }"
                                    @click="submitFields"
                                >
                                    <loading-spinner
                                        :size="20"
                                        v-if="fieldsAreSubmitting"
                                        color="#fff"
                                    />
                                    <span v-else>
                                        {{ $t('widget.tcd.submit') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</form-wrapper>
