import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './super-check-auth-token.html';

import SERVICES from '@/container/Services';
import IocContainer from '@/container/IocContainer';

import Auth from '@/interfaces/Auth';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({})
export class SuperCheckAuthToken extends Base {
    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    public created() {
        if (this.$route.query.token) {
            this.auth
                .superCheckAuthToken({ token: this.$route.query.token })
                .then((response) => {
                    if (response.data.success) {
                        this.$cookies.set(
                            'SUPER-ADMIN-TOKEN',
                            this.$route.query.token,
                            0
                        );
                        this.$router
                            .push({
                                name: 'dashboard',
                                params: { id: response.data.contractId },
                            })
                            .catch((failure) =>
                                handleNavigationFailure(failure)
                            );
                    } else {
                        console.log('ERROR');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
