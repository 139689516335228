<div
    class="col-12 mobile-modal__inner cancellation-widget-elli"
    data-test="widget-cancellation"
>
    <div class="m-portlet m-portlet--mobile">
        <a name="cancellation" id="cancellation"></a>
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="box-title static-title"
                        >{{ $t('widget.c.title') }}</span
                    >
                    <div class="mobile-modal__wrap row">
                        <div class="col-12 col-sm-4">
                            {{ $t('widget.c.description') }}
                        </div>
                        <div class="col-12 col-sm-8">
                            <!-- <div class="form-group">
                              <label for="type-of-cancellation"
                                  >{{ $t('widget.c.type.of.cancellation')
                                  }}</label
                              >
                              <select
                                  id="type-of-cancellation"
                                  class="form-control"
                                  v-model="details.cancelType"
                              >
                                  <option
                                      v-for="type in cancelTypeOptions"
                                      :value="type.value"
                                      :selected="details.cancelType === type.value"
                                  >
                                      {{ type.label }}
                                  </option>
                              </select>
                              <span></span>
                          </div> -->

                            <div class="form-group form-group--custom">
                                <label
                                    >{{
                                    $t('widget.c.type.of.cancellation')}}</label
                                >
                                <v-select
                                    @input="(object) => details.cancelType = object.value"
                                    :options="cancelTypeOptions"
                                    :clearable="false"
                                    data-test="cancellation-type"
                                >
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes">
                                            <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g
                                                    id="24px/chevron-down"
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <path
                                                        d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                        id="chevron-down"
                                                        fill="#001E50"
                                                        fill-rule="nonzero"
                                                    ></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </template>
                                </v-select>
                                <span></span>
                            </div>

                            <!-- <div class="form-group" v-else>
                          <label
                          >{{ $t('widget.cbd.salutation')
                              }}</label
                          >
                          <v-select
                                  :options="salutationOptions"
                                  :value="salutationCurrentlySelected"
                                  @input="(object) => details.billingSalutation = object.value"
                                  :clearable="false"
                          >
                          </v-select>

                      </div> -->

                            <div
                                :class="['form-group', dateError ? 'text-danger' : '']"
                                v-if="details.cancelType !== 'deadlineDate'"
                            >
                                <label for="cancellation-date"
                                    >{{ $t('widget.c.date') }}</label
                                >
                                <input
                                    v-if="displayElement('date-input')"
                                    v-model="date"
                                    type="date"
                                    :class="['form-control w-50', dateError ? 'is-invalid' : '']"
                                    id="cancellation-date"
                                    @change="dateError = false"
                                />
                                <datetime
                                    v-else
                                    v-model="date"
                                    type="date"
                                    input-id="cancellation-date"
                                    :input-class="['form-control w-50', dateError ? 'is-invalid' : '']"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    @close="dateError = false"
                                ></datetime>
                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <div class="form-check">
                                        <div
                                            class="custom-check"
                                            :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '', confirmationCheckboxError ? 'is-invalid text-danger' : '']"
                                            for="cancellation-confirmation"
                                        >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="cancellation-confirmation"
                                                @change="confirmationCheckboxError = false"
                                                v-model="details.confirmation"
                                            />

                                            <label
                                                for="cancellation-confirmation"
                                                >{{ $t('widget.c.confirmation')
                                                }}</label
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4">
                                    <button
                                        class="btn btn-primary"
                                        @click="submit"
                                        :disabled="isSubmitButtonDisabled"
                                    >
                                        {{ $t('widget.c.confirm') }}
                                    </button>
                                </div>
                            </div>

                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
