// Data Structure
const state = {
    tariffOverview: false,
};

const actions = {
    showTariffOverview({ commit }) {
        commit('SET_TARIFF_OVERVIEW', true);
    },
    hideTariffOverview({ commit }) {
        commit('SET_TARIFF_OVERVIEW', false);
    },
};

const mutations = {
    SET_TARIFF_OVERVIEW(localState, data) {
        localState.tariffOverview = data;
    },
};

// Get Data transformation (like computed)
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
