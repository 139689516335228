<div>
    <h2 class="text-center">{{$t('forgot.password.title')}}</h2>
    <messages-block
        :success="successInformation"
        :errors="failInformation"
    ></messages-block>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="text"
                name="username"
                v-model="data.username"
                :placeholder="$t('username') + '*'"
            />
        </div>
        <div class="invalid-feedback d-block" v-if="errorUsername" v-html="errorUsername"></div>
        <div class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="text"
                name="email"
                v-model="data.email"
                :placeholder="$t('email') + '*'"
            />
        </div>
        <div class="invalid-feedback d-block" v-if="errorEmail" v-html="errorEmail"></div>

        <!--begin::Action-->
        <div class="m-login__action">
            <button
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                @click="$router.push({name: 'login'})"
            >
                {{ $t('back') }}
            </button>
            <button
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                @click="submitForgetPasswordForm"
            >
                {{ $t('forgot.password') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>