import { TariffOverviewWidgetSchweizstrom } from '@/components/widgets/tariff-overview-widget/custom/schweizstrom';
import { TariffOverviewWidget } from '@/components/widgets/tariff-overview-widget/tariff-overview-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = TariffOverviewWidgetSchweizstrom;
} else {
    widget = TariffOverviewWidget;
}
export { widget as TariffOverviewWidget };
