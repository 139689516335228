<div class="m-portlet m-portlet--mobile opt-in">
    <div class="m-portlet__body opt-in__short" v-if="!showFull">
        <div class="title">
            <span>{{ $t('widget.oi.newsletter1') }}</span>
            <span class="font-weight-600"
                >{{ $t('widget.oi.newsletter2' )}}<sup>1</sup></span
            >
            <span>{{$t('widget.oi.newsletter3')}}</span>
        </div>

        <div
            @click="showFull = !showFull"
            class="short-button newsletter-button"
        >
            {{ $t('widget.oi.button') }}
        </div>
    </div>
    <div class="m-portlet__body opt-in__full" v-if="showFull">
        <div @click="showFull = !showFull" class="fullwidthArea-close">
            <i class="fa fa-times"></i>
        </div>
        <div class="title">
            <span>{{ $t('widget.oi.newsletter1') }}</span>
            <span class="font-weight-600"
                >{{ $t('widget.oi.newsletter2' )}}<sup>1</sup></span
            >
            <span>{{$t('widget.oi.newsletter3')}}</span>
        </div>
        <div>
            <input
                id="opt-in__full-checkbox"
                type="checkbox"
                v-model="checked"
            />
            <label for="opt-in__full-checkbox">
                {{ $t('widget.oi.checkbox1') }}
                <a :href="'mailto:' + $t('widget.oi.checkbox-link')"
                    >{{ $t('widget.oi.checkbox-link') }}</a
                >
                {{ $t('widget.oi.checkbox2') }}
            </label>
        </div>
        <button class="submit" :disabled="!checked" @click="submit()">
            {{ $t('widget.oi.submit') }}
        </button>
        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>
        <div class="opt-in__footer">
            <b>{{ $t('widget.oi.agb') }}</b><br />
            <sup>1</sup> {{ $t('widget.oi.agb.description') }}
        </div>
    </div>
</div>
