import Base from '@/mixins/base';
import { Component } from 'vue-property-decorator';
import WithRender from './elli.html';

import { AccountTransactionsWidgetElliThgDealer } from './elli-thg-dealer';
import { AccountTransactionsWidget } from '../account-transactions-widget';

@WithRender
@Component({
    components: {
        AccountTransactionsWidgetElliThgDealer,
        AccountTransactionsWidget,
    },
})
export class AccountTransactionsWidgetElli extends Base {
    get widget() {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );

        if (contract && (this.isDealer(contract) || this.isThg(contract))) {
            return AccountTransactionsWidgetElliThgDealer;
        }

        return AccountTransactionsWidget;
    }
}
