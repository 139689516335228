<div class="col-12 mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile m-portlet--details">
        <div class="m-portlet__body">
            <div
                @click="$emit('closeWidget')"
                id="button-closeTariffDetails"
                class="fullwidthArea-close m-portlet--close"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="box-title static-title">
                {{ $t('widget.td.title') }}
                <button
                    v-if="tariffOverviewAllowed"
                    class="btn btn-link"
                    @click="$emit('toggleWidget', 'showTariffOverview')"
                >
                    {{ $t('widget.td.tariffOverview') }}
                </button>
            </div>
            <div
                class="row mobile-modal__wrap text-break"
                id="tariffDetails-content"
            >
                <div class="col-xs-12 col-sm-6 col-md-3 m-portlet--info">
                    <div><u>{{ $t('widget.td.shipping.address') }}</u></div>
                    <span id="tariffDetails-deliveryAddress">
                        {{ contract.deliveryAddress.firstname }} {{
                        contract.deliveryAddress.surname }}<br />
                        {{ contract.deliveryAddress.street }} {{
                        contract.deliveryAddress.houseNumber }}<br />
                        {{ contract.deliveryAddress.zip }} {{
                        contract.deliveryAddress.city }}</span
                    >
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 m-portlet--info">
                    <div v-if="contract.minimumRuntimeUnit">
                        {{ $t('widget.td.minRuntime') }}: {{
                        contract.minimumRuntime }} {{ $t('widget.td.runtime.' +
                        contract.minimumRuntimeUnit) }}
                    </div>
                    <div v-if="contract.extensionTimeUnit">
                        {{ $t('widget.td.extRuntime') }}: {{
                        contract.extensionTime }} {{ $t('widget.td.runtime.' +
                        contract.extensionTimeUnit) }}
                    </div>
                    <div>
                        {{ $t('widget.td.instantBonus') }}: {{
                        tariff.instantBonus | euro }} €
                    </div>
                    <div
                        v-if="tariff.productBonusPercent || tariff.productBonus"
                    >
                        {{ $t('widget.td.newCustomerBonus') }}:
                        <span v-if="tariff.productBonusPercent">
                            {{ tariff.productBonusPercent | euroFull }} %
                        </span>
                        <span v-else> {{ tariff.productBonus | euro }} € </span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 text-center">
                    <button
                        v-if="contractMoveAllowed"
                        class="btn btn-primary"
                        @click="$emit('toggleWidget', 'showMoveWidget')"
                    >
                        {{ $t('widget.td.move') }}
                    </button>
                    <button
                        v-if="contractCancellationAllowed"
                        class="btn btn-primary"
                        @click="$emit('toggleWidget', 'showCancellationWidget')"
                    >
                        {{ $t('widget.td.cancellation') }}
                    </button>
                    <button
                        v-if="tariffChangeAllowed"
                        class="btn btn-primary"
                        @click="$emit('toggleWidget', 'showTariffChange')"
                    >
                        {{ $t('widget.td.tariffChange') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
