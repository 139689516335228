import { TariffAvailableWidget as TariffAvailableWidgetParent } from '../tariff-available-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './qcells.html';

@WithRender
@Component({})
export class TariffAvailableWidget extends TariffAvailableWidgetParent {
    get tariff(): Record<any, any> {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList[0] ?? null
        );
    }

    get instantBonusAvailable(): boolean {
        return this.tariff.instantBonus > 0 && this.tariff.instantBonus != null;
    }

    get tariffDescription(): string {
        if (this.tariff && this.tariff.description) {
            let description = this.tariff.description || '';
            description = description
                .replace('<li>inkl. 25&euro; Amazon Gutschein</li>', '')
                .replace('<li>inkl. 50&euro; Amazon Gutschein</li>', '');
            if (this.instantBonusAvailable) {
                const pattern = /(<li>inkl.*?<\/li>)/;
                description = description.replace(pattern, '');
            }
            description = description.replaceAll('<ul>', '');
            description = description.replaceAll('</ul>', '');
            description = description.replaceAll('<li>', '<p>');
            description = description.replaceAll('</li>', '</p>');
            return description;
        }
        return '';
    }

    get headerTitle(): string {
        if (
            this.$t(
                'widget.ta.title.' + this.tariff.productCode
            ).toLocaleString() !==
            'widget.ta.title.' + this.tariff.productCode
        ) {
            return this.$t(
                'widget.ta.title.' + this.tariff.productCode
            ).toLocaleString();
        }
        return this.$t('widget.ta.title').toLocaleString();
    }

    get headerSubTitle(): string {
        if (
            this.$t(
                'widget.ta.subtitle.' + this.tariff.productCode
            ).toLocaleString() !==
            'widget.ta.subtitle.' + this.tariff.productCode
        ) {
            return this.$t(
                'widget.ta.subtitle.' + this.tariff.productCode
            ).toLocaleString();
        }
        return this.$t('widget.ta.subtitle').toLocaleString();
    }

    get productSubTitle(): string {
        if (
            this.$t(
                'widget.ta.subtitle.product.' + this.tariff.productCode
            ).toLocaleString() !==
            'widget.ta.subtitle.product.' + this.tariff.productCode
        ) {
            return this.$t(
                'widget.ta.subtitle.product.' + this.tariff.productCode
            ).toLocaleString();
        }
        return this.$t('widget.ta.subtitle.product').toLocaleString();
    }

    get addonDescription(): string {
        if (
            this.$t(
                'widget.ta.subtitle.addon.description.' +
                    this.tariff.productCode +
                    '.' +
                    this.tariff.addons[0].productCode
            ).toLocaleString() !==
            'widget.ta.subtitle.addon.description.' +
                this.tariff.productCode +
                '.' +
                this.tariff.addons[0].productCode
        ) {
            return this.$t(
                'widget.ta.subtitle.addon.description.' +
                    this.tariff.productCode +
                    '.' +
                    this.tariff.addons[0].productCode
            ).toLocaleString();
        }
        return this.$t(
            'widget.ta.subtitle.addon.description.' +
                this.tariff.addons[0].productCode
        ).toLocaleString();
    }

    get instantBonusDescription(): string {
        if (this.tariff && this.tariff.description) {
            const description = this.tariff.description;
            const pattern = /<li>(.*?)<\/li>/;
            const array = pattern.exec(description);
            if (array) {
                return array[1];
            }
        }

        return this.$t('widget.ta.subtitle.instantbonus.description', {
            instantBonus: this.tariff.instantBonus,
        }).toLocaleString();
    }

    get defaultAddonSet(): boolean {
        if (this.tariff) {
            const addons = this.tariff.addons.filter((addon) => {
                return (
                    this.$store.state.settings.tariffChangeDefaultAddon.indexOf(
                        addon.productCode
                    ) !== -1
                );
            });
            return addons.length === 1;
        }
        return false;
    }
}
