import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './ecar-upload-widget.html';

@WithRender
@Component({})
export class EcarUploadWidget extends Base {
    get productCode(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).product.code;
        }
        return '';
    }
}
