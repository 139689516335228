<form-wrapper form="formPaymentData">
    <div class="mobile-modal__inner" data-test="change-payment-data-widget">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-payment-data-widget-close-click"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col-6">
                        <i
                            class="fa fa-user-edit readingHistory-icon mt-2"
                            v-if="displayElement('edit-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.cpd.title') }}</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            id="paymentDataChange-content"
                            class="mobile-modal__wrap"
                        >
                            <div
                                v-if="activeFromChangeBankingDetailsInOutActive"
                            >
                                <button
                                    @click="switchView('in')"
                                    class="btn btn-link"
                                    :class="{'active': activeView=='in','font-weight-500': activeView!='in' }"
                                >
                                    {{ $t('widget.cpd.payment.in') }}
                                </button>
                                <button
                                    @click="switchView('out')"
                                    class="btn btn-link"
                                    :class="{'active': activeView=='out' ,'font-weight-500': activeView!='out' }"
                                >
                                    {{ $t('widget.cpd.payment.out') }}
                                </button>
                            </div>

                            <div id="paymentDataChangeForm">
                                <div class="row">
                                    <div class="col-sm-6 col-xs-12">
                                        <form-group
                                            id="change-payment-data-widget__alternative-account-holder"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cpd.account') }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cpd.account')"
                                                v-model="details.alternativeAccountHolder"
                                            />
                                        </form-group>

                                        <form-group
                                            id="change-payment-data-widget__bic"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cpd.bic') }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="bicBankPlaceholder"
                                                :value="loadingBicPending ? '' :  bic"
                                            />
                                        </form-group>
                                        <form-group
                                            v-if="allowActiveFrom"
                                            id="change-payment-data-widget__date"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cpd.activeFrom')}}
                                            </custom-label>
                                            <custom-datetime
                                                v-model="details.activeFrom"
                                                type="date"
                                                inputClass="form-control"
                                                value-zone="local"
                                                format="dd.LL.yyyy"
                                                :min-datetime="minDate"
                                                :phrases="datetimePhrases"
                                            ></custom-datetime>
                                        </form-group>
                                    </div>
                                    <div class="col-sm-6 col-xs-12">
                                        <form-group
                                            id="change-payment-data-widget__iban"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cpd.iban') }}
                                            </custom-label>

                                            <custom-input
                                                data-test="change-payment-data-widget-iban-input--iban"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cpd.iban')"
                                                @focus="details.iban=null"
                                                v-model="details.iban"
                                            />
                                        </form-group>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-8 col-xs-12">
                                        <messages-block
                                            fromTestElement="change-payment-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div
                                        v-if="!displayElement('disableDataChange')"
                                        class="col-sm-4 col-xs-12"
                                    >
                                        <div
                                            class="text-end"
                                            v-if="displayElement('prevent-submit-no-change')"
                                        >
                                            <button
                                                class="btn btn-primary"
                                                :class="cantSubmit || !details.directDebit ? 'cursor-default' : ''"
                                                :disabled="cantSubmit || !details.directDebit"
                                                @click="submit"
                                            >
                                                {{ $t('widget.cpd.submit') }}
                                            </button>
                                        </div>
                                        <div class="text-end" v-else>
                                            <button
                                                data-test="change-payment-data-widget-save-click"
                                                class="btn btn-primary"
                                                :class="cantSubmit ? 'cursor-default' : ''"
                                                :disabled="cantSubmit"
                                                @click="submit"
                                            >
                                                {{ $t('widget.cpd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
