import { default as Component } from 'vue-class-component';
import WithRender from './username-reset-form.html';
import { Username } from './username';

import { RequestUsernameResetWidget } from '@/components/widgets/request-username-reset-widget';

@WithRender
@Component({
    components: {
        RequestUsernameResetWidget,
    },
})
export class UsernameResetForm extends Username {}
