import { StatusBarWidgetSchweizstrom } from '@/components/widgets/status-bar-widget/custom/schweizstrom';
import { StatusBarWidget } from '@/components/widgets/status-bar-widget/status-bar';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = StatusBarWidgetSchweizstrom;
} else {
    widget = StatusBarWidget;
}
export { widget as StatusBarWidget };
