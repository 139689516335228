import Vue from 'vue';
import { injectable } from 'inversify';
import axios, { AxiosInstance } from 'axios';
import IocContainer from '@/container/IocContainer';
import TranslationProvider from '@/interfaces/Translation';
import SERVICES from '@/container/Services';
import { mutations } from '@/store/ProviderInternalStore';

@injectable()
export default class PrototypeProvider {
    public http: AxiosInstance;
    protected config: any;
    protected translationProvider = IocContainer.get<TranslationProvider>(
        SERVICES.TRANSLATION
    );
    protected responseErrorHandler: any;
    protected sessionstorage = require('sessionstorage');

    constructor() {
        this.http = axios.create({
            headers: {
                'WD-OVERWRITE-POWERCLOUD-APIS':
                    process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true',
            },
        });
        this.createConfig();

        this.responseErrorHandler = (error) => {
            if (
                error &&
                error.response &&
                typeof error.response.status !== 'undefined' &&
                (error.response.status === 401 || error.response.status === 403)
            ) {
                localStorage.removeItem('token');
                window.location.href = '/';
            }

            return Promise.reject(error);
        };

        this.http.interceptors.request.use(
            (config) => {
                const hash = this.hashCode(
                    JSON.stringify(config.method) +
                        JSON.stringify(config.url) +
                        JSON.stringify(config.data)
                );
                if (mutations.exists(hash)) {
                    return Promise.reject(
                        'Operation canceled because of duplicate request.'
                    );
                }
                mutations.add(hash);
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        this.http.interceptors.response.use((response) => {
            mutations.remove(
                this.hashCode(
                    JSON.stringify(response.config.method) +
                        JSON.stringify(response.config.url) +
                        response.config.data
                )
            );
            return response;
        }, this.responseErrorHandler);
    }

    protected createConfig(): void {
        const headers = {
            Accept: 'application/json',
            'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
            'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
            Authorization: this.getApiAuth(),
        };

        if (
            typeof Vue.$cookies !== 'undefined' &&
            Vue.$cookies.isKey('SUPER-ADMIN-TOKEN')
        ) {
            headers['WD-PORTAL-SUPER-AUTH'] =
                Vue.$cookies.get('SUPER-ADMIN-TOKEN');
        }

        if (window.Cypress) {
            headers['IS-CYPRESS'] = 'yes';
        }

        this.config = {
            baseURL: process.env.VUE_APP_API_URL + '/v1/',
            headers,
        };
    }

    /* tslint:disable:no-bitwise */
    protected hashCode = (s): boolean => {
        return s.split('').reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0);
    };
    /* tslint:enable:no-bitwise */

    protected getApiAuth(): string {
        if (process.env.VUE_APP_API_AUTH) return process.env.VUE_APP_API_AUTH;
        throw new Error("'VUE_APP_API_AUTH' is not defined");
    }

    protected getAppEnv(): string {
        if (process.env.VUE_APP_ENVIRONMENT)
            return process.env.VUE_APP_ENVIRONMENT;
        throw new Error("'VUE_APP_ENVIRONMENT' is not defined");
    }
}
