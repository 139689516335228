import { TariffRecommendation } from '../../tariff-recommendation/tariff-recommendation';
import { default as Component } from 'vue-class-component';

import WithRender from '../../tariff-recommendation/tariff-recommendation.html';
import moment from 'moment/moment';

@WithRender
@Component({})
export class TariffChangeWidgetElli extends TariffRecommendation {
    get recommendationTariff(): any {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )?.changeList?.[0] ?? {}
        );
    }
    get isTariffChange(): boolean {
        return true;
    }

    get nextCancellationDate(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.nextCancellationDate
        ) {
            return moment(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract.nextCancellationDate
            ).format('DD.MM.YYYY');
        }
    }

    get priceAdjustmentTariff(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList
        ) {
            let tariff = this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList.find((item) => {
                return (
                    item.priceAdjustmentOf !== null &&
                    item.activeUntil === null &&
                    moment(item.activeFrom) >= moment()
                );
            });
            if (!tariff) {
                this.isNotPA = true;
                tariff = this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).oldList.reduce((prev, current) => {
                    return moment(prev.activeFrom) <= moment(current.activeFrom)
                        ? current
                        : prev;
                });
            }
            return tariff ?? {};
        }
        return {};
    }
}
