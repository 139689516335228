import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './ads-popup-widget.html';
import { Emit } from 'vue-property-decorator';
import IocContainer from '@/container/IocContainer';
import Tracking from '@/interfaces/Tracking';
import SERVICES from '@/container/Services';

const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({})
export class AdsPopupWidget extends Base {
    private isAgree = false;

    @Emit('send-ads-data')
    private closePopup() {
        this.isAgree = false;
        return this.isAgree;
    }

    @Emit('send-ads-data')
    private updateCustomerDetails() {
        if (['qcells'].includes(this.currentClient) && this.isAgree) {
            trackingProvider.qcellsOptInSuccessful();
        }
        return this.isAgree;
    }

    @Emit('send-ads-data')
    private declineOptIn() {
        this.isAgree = false;
        return this.isAgree;
    }
}
