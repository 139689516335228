import { Component, Vue } from 'vue-property-decorator';
import wcmatch from 'wildcard-match';
import * as FileSave from 'file-saver';

@Component
export default class Base extends Vue {
    public captchaPassed = false;
    public captchaToken: string | null = null;
    protected elements = {};

    public removeUmlaut(str: string): string {
        return str
            .replace(/\u00dc/g, 'Ue')
            .replace(/\u00fc/g, 'ue')
            .replace(/\u00c4/g, 'Ae')
            .replace(/\u00e4/g, 'ae')
            .replace(/\u00d6/g, 'Oe')
            .replace(/\u00f6/g, 'oe')
            .replace(/\u00df/g, 'ss');
    }
    public addUmlaut(str: string): string {
        return str
            .replace(/Ue/g, 'Ü')
            .replace(/ue/g, 'ü')
            .replace(/Ae/g, 'Ä')
            .replace(/ae/g, 'ä')
            .replace(/Oe/g, 'Ö')
            .replace(/oe/g, 'ö')
            .replace(/ss/g, 'ß');
    }

    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }

    public log(...args: any[]): void {
        const debuggerToken =
            process.env.VUE_APP_ENVIRONMENT_STAGE === 'test'
                ? localStorage.getItem('wd-debugger-token-test')
                : localStorage.getItem('wd-debugger-token');
        if (
            process.env.VUE_APP_ENVIRONMENT_STAGE === 'local' ||
            (debuggerToken === process.env.VUE_APP_DEBUGGER_TOKEN &&
                process.env.VUE_APP_DEBUGGER_TOKEN !== '' &&
                debuggerToken !== '')
        ) {
            console.log(...args);
        }
    }

    public warn(...args: any[]): void {
        const debuggerToken =
            process.env.VUE_APP_ENVIRONMENT_STAGE === 'test'
                ? localStorage.getItem('wd-debugger-token-test')
                : localStorage.getItem('wd-debugger-token');
        if (
            process.env.VUE_APP_ENVIRONMENT_STAGE === 'local' ||
            (debuggerToken === process.env.VUE_APP_DEBUGGER_TOKEN &&
                process.env.VUE_APP_DEBUGGER_TOKEN !== '' &&
                debuggerToken !== '')
        ) {
            console.warn(...args);
        }
    }

    get isProduction() {
        return process.env.VUE_APP_ENVIRONMENT_STAGE === 'prod';
    }

    get locales(): string[] {
        return this.$i18n.availableLocales;
    }

    get currentClient(): string {
        return process.env.VUE_APP_ENVIRONMENT || '';
    }

    get googleSiteKey(): string {
        return this.$store.state.settings.captchaSiteKey || '';
    }

    get showCaptcha(): boolean {
        return !(this.captchaSize !== 'invisible' && this.captchaPassed);
    }

    get captchaSize(): string {
        return this.$store.state.settings.invisibleCaptcha ? 'invisible' : '';
    }

    get optInBannerActive(): boolean {
        if (this.$store.state.settings.optInBannerActive) {
            if (this.$store.state.settings.optInHideIfOptOutAvailable) {
                return !this.$store.state.settings.optOut;
            }

            return true;
        }

        return false;
    }

    get currentNoCommodityProduct() {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )
        ) {
            return (
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).nonCommodityProduct ?? {}
            );
        }

        return {};
    }

    get contractId(): number | null {
        return this.$store.state.contracts.contractId;
    }

    get customerDetails(): Record<any, any> {
        return this.$store.state.customer;
    }

    get contractsList(): Record<any, any> {
        if (!this.$store.state.contracts.list) {
            return [];
        }
        return this.$store.state.contracts.list;
    }

    get isContractsAvailable(): boolean {
        return this.$store.state.contracts.list !== null;
    }

    get currentProduct() {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ) &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).product.code
        ) {
            return (
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).product ?? {}
            );
        }

        return {};
    }

    get loginAsideStyle(): string | false {
        switch (this.currentClient) {
            // No Image, but Centered
            case 'qcells':
                return false;

            default:
                // Image, not Centered
                return (
                    'background-image: url(/img/evus/' +
                    this.currentClient +
                    '/login.jpg), url(/img/evus/' +
                    this.currentClient +
                    '/login.png), url(/img/evus/' +
                    this.currentClient +
                    '/login.gif), url(/img/evus/' +
                    this.currentClient +
                    '/login.svg); background-size: cover;'
                );
        }
    }

    get loginLogo(): string {
        return (
            'background-image: url(/img/evus/' +
            this.currentClient +
            '/logo.svg), url(/img/evus/' +
            this.currentClient +
            '/logo.jpg), url(/img/evus/' +
            this.currentClient +
            '/logo.png);'
        );
    }

    get loginLogoUrl(): string {
        return '/img/evus/' + this.currentClient + '/logo.svg';
    }

    get contactLink(): string {
        if (this.$te('contact.link')) {
            return this.$t('contact.link').toString();
        }
        if (['sachsenenergie'].includes(this.currentClient)) {
            return '';
        }

        return '#contactLink';
    }

    get privacyLink(): string {
        if (this.$te('privacy.link')) {
            return this.$t('privacy.link').toString();
        }
        return '#privacyLink';
    }

    get agbLink(): string {
        if (this.$te('agb.link')) {
            return this.$t('agb.link').toString();
        }
        return '';
    }

    get imprintLink(): string {
        if (this.$te('imprint.link')) {
            return this.$t('imprint.link').toString();
        }
        return '#imprintLink';
    }

    get legalNoticeLink(): string {
        if (this.$te('legal.notice.link')) {
            return this.$t('legal.notice.link').toString();
        }
        if (['ets'].includes(this.currentClient)) {
            return '';
        }

        return '#legalNotice';
    }

    get tosLink(): string {
        if (this.$te('tos.link')) {
            return this.$t('tos.link').toString();
        }
        return '';
    }

    get formularsLink(): string {
        if (this.$te('formulars.link')) {
            return this.$t('formulars.link').toString();
        }
        if (['ets'].includes(this.currentClient)) {
            return '';
        }
        return '';
    }

    get energyPricingLink(): string {
        if (this.$te('energy.pricing.link')) {
            return this.$t('energy.pricing.link').toString();
        }
        return '';
    }

    get contractsData(): Record<any, any> {
        return this.$store.state.contracts.list;
    }

    get contractsDataSet(): boolean {
        return (
            this.contractsData !== null &&
            this.contractsData.length > 0 &&
            this.$store.state.contracts.contractId
        );
    }

    get contractStatus(): Record<any, any> {
        let contractInfo = {
            contractId: null,
            status: null,
            statusCode: 0,
            statusCodeTitle: null,
        };
        if (this.contractsDataSet) {
            const selectedContractInfo =
                this.$store.state.contracts.list.filter((contract) => {
                    return (
                        contract.contractId ===
                        this.$store.state.contracts.contractId
                    );
                })[0];
            if (selectedContractInfo) {
                contractInfo = selectedContractInfo;
            }
        }
        return contractInfo;
    }

    public shouldDisplayWidgetByContractStatus(
        widgetSpecificSettingKey: string
    ): boolean {
        if (!this.$store.state.settings.dashboard[widgetSpecificSettingKey]) {
            return false;
        }
        const setting =
            this.$store.state.settings.dashboard[widgetSpecificSettingKey];
        if (setting && setting.length > 0) {
            const isDeactivated = setting.some(
                (state) => state.toUpperCase() === 'DEACTIVATED'
            );
            if (isDeactivated) {
                return false;
            }
            if (
                this.contractStatus &&
                this.contractStatus.statusCode !== null
            ) {
                for (let i = 0; i < setting.length; ++i) {
                    if (
                        setting[i] &&
                        this.isStatusMatched(
                            setting[i],
                            this.contractStatus.statusCode
                        )
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private isStatusMatched(pattern: string, value: number): boolean {
        let result = pattern.match(/(\d{4})-(\d{4,5})/);
        if (result) {
            return value >= parseInt(result[1]) && value <= parseInt(result[2]);
        }
        result = pattern.match(/\d{4,5}/);
        if (result) {
            return value == parseInt(pattern);
        }
        return false;
    }

    public getFromExtDataByTechKey(keyField, keyCategory): string {
        let value = '';
        const contract = this.contractsList.find(
            (item) => item.contractId === this.contractId
        );
        if (
            contract &&
            contract.extendedData &&
            contract.extendedData.values[keyCategory]
        ) {
            contract.extendedData.values[keyCategory].forEach((item) => {
                if (item.technicalKey === keyField) {
                    value = item.value;
                }
            });
        }

        return value;
    }

    get currentContract() {
        return this.contractsList.find(
            (item) => item.contractId == this.contractId
        );
    }

    get currentTariff() {
        return this.$store.getters['tariff/getState'](this.contractId);
    }

    public isThg(contract) {
        if (contract.type === 'non_commodity') {
            const tariff = this.$store.getters['tariff/getState'](
                contract.contractId
            );
            let isThg = false;
            if (
                tariff &&
                tariff.nonCommodityProduct &&
                tariff.nonCommodityProduct.productCode
            ) {
                isThg =
                    tariff.nonCommodityProduct.productCode.includes('THG') &&
                    !tariff.nonCommodityProduct.productCode.includes(
                        'Haendlerprovision'
                    );
            }

            return isThg;
        }

        return false;
    }

    public isDealer(contract) {
        if (contract && contract.type === 'non_commodity') {
            const tariff = this.$store.getters['tariff/getState'](
                contract.contractId
            );
            let isDealer = false;
            if (
                tariff &&
                tariff.nonCommodityProduct &&
                tariff.nonCommodityProduct.productCode
            ) {
                isDealer =
                    tariff.nonCommodityProduct.productCode.includes(
                        'Haendlerprovision'
                    );
            }

            return isDealer;
        }

        return false;
    }

    public isFieldEnabled(settings, field): boolean {
        return (
            !this.isFieldHidden(settings, field) &&
            !this.isFieldDisabled(settings, field)
        );
    }

    public isFieldHidden(settings, field): boolean {
        return this.checkField(settings, field, 'hidden');
    }

    public isFieldDisabled(settings, field): boolean {
        return this.checkField(settings, field, 'disabled');
    }

    private checkField(settings, field, type): boolean {
        return settings[type].includes(field);
    }

    public displayElement(element: string): boolean {
        if (this.currentClient) {
            if (this.isCountryAdapted()) {
                const country = process.env.VUE_APP_COUNTRY_CODE || 'de';
                const elements = this.elements[country];
                return this.matchElement(elements, element);
            } else {
                return this.matchElement(this.elements, element);
            }
        }
        return false;
        // return (
        //     this.currentClient &&
        //     this.elements[this.currentClient] &&
        //     this.elements[this.currentClient].includes(element)
        // );
    }

    private isCountryAdapted(): boolean {
        const availableCountries = ['de', 'us', 'dk'];
        let flag = false;
        for (const code of availableCountries) {
            if (code) {
                flag = Object.keys(this.elements).includes(code);
            }
            if (flag) {
                return flag;
            }
        }
        return flag;
    }

    public saveBlobAsFile(
        blobAsBase64: string,
        fileName: string,
        fileType: string
    ) {
        const byteCharacters = atob(blobAsBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
            type: fileType,
        });

        FileSave.saveAs(blob, fileName);
    }

    public getOnlyText(text): string {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.textContent || div.innerText || '';
    }

    private matchElement(elements, element): any {
        if (typeof this.currentClient === 'undefined') {
            return false;
        }

        if (!elements[this.currentClient]) {
            const keys = Object.keys(elements);
            const regexp = /(\S*\*\S*)|(\S*\?\S*)/;
            let matched = '';
            keys.forEach((value) => {
                if (regexp.exec(value)) {
                    const isMatch = wcmatch(value);
                    if (isMatch(this.currentClient)) {
                        matched = value;
                    }
                }
            });
            if (matched !== '') {
                return elements[matched].includes(element);
            }
        } else {
            return elements[this.currentClient].includes(element);
        }
    }

    // Contract status:

    get contractCancellationPrevious(): boolean | undefined {
        if (this.contractStatus && this.contractStatus.statusCode !== null) {
            if (
                this.contractStatus.statusCode >= 2000 &&
                this.contractStatus.statusCode < 3000
            ) {
                return true;
            }
        }
        return false;
    }

    get contractRegistrationOperator(): boolean | undefined {
        if (this.contractStatus && this.contractStatus.statusCode !== null) {
            if (
                this.contractStatus.statusCode >= 3000 &&
                this.contractStatus.statusCode < 4000
            ) {
                return true;
            }
        }
        return false;
    }

    get contractInWaitingState(): boolean {
        if (this.contractStatus && this.contractStatus.statusCode !== null) {
            if (this.contractStatus.statusCode === 4000) {
                return true;
            }
        }
        return false;
    }

    protected isContractInDelivery(contract: Record<any, any>): boolean {
        return contract.statusCode >= 5000 && contract.statusCode < 6000;
    }

    get contractInActiveState(): boolean {
        if (this.contractStatus && this.contractStatus.statusCode !== null) {
            if (
                this.contractStatus.statusCode >= 5000 &&
                this.contractStatus.statusCode < 6000
            ) {
                return true;
            }
        }
        return false;
    }

    get contractInCancellation(): boolean {
        return (
            this.contractStatus.statusCode < 6000 &&
            this.currentTariff.contract.cancellationTechKey !== null &&
            this.currentTariff.contract.cancelledAt !== null
        );
    }

    get contractCancelled(): boolean {
        return this.contractStatus.statusCode >= 6000;
    }

    get contractInExpiredState(): boolean {
        if (this.contractStatus && this.contractStatus.statusCode !== null) {
            if (
                this.contractStatus.statusCode >= 7000 &&
                this.contractStatus.statusCode < 8000
            ) {
                return true;
            }
        }
        return false;
    }

    get contractCancellationAllowed(): boolean {
        return (
            this.$store.state.settings.contractCancellation &&
            !this.contractInCancellation &&
            !this.contractCancelled
        );
    }

    get nonCommodityProductCode(): string {
        return this.currentNoCommodityProduct.productCode ?? '';
    }

    get nonCommodityProductCampaign() {
        if (this.nonCommodityProductBranding) {
            switch (this.nonCommodityProductBranding) {
                case 'audi':
                    return 'IDUAGHT';
                case 'cupra':
                    return 'ARPUCGHT';
                case 'elli':
                    return 'ILLEGHT';
                case 'freenet':
                    return 'TENEERFGHT';
                case 'skoda':
                    return 'ADOKSGHT';
                case 'vw':
                    return 'THGVW';
                case 'vwfs':
                    return 'SFWVGHT';
                case 'man':
                    return 'NAMGHT';
            }
        }
        return '';
    }

    get nonCommodityProductBranding(): string {
        if (this.nonCommodityProductCode) {
            const productCode: string =
                this.nonCommodityProductCode.toLowerCase();

            if (productCode.includes('_audi_')) {
                return 'audi';
            }
            if (productCode.includes('_cupra_')) {
                return 'cupra';
            }
            if (productCode.includes('_elli_')) {
                return 'elli';
            }
            if (productCode.includes('_freenet_')) {
                return 'freenet';
            }
            if (productCode.includes('_skoda_')) {
                return 'skoda';
            }
            if (productCode.includes('_vw_')) {
                return 'vw';
            }
            if (productCode.includes('_vwfs_')) {
                return 'vwfs';
            }
            if (productCode.includes('_man_')) {
                return 'man';
            }
        } else {
            const currentClient = this.currentClient.toLowerCase();
            if (currentClient.includes('audi')) {
                return 'audi';
            }
            if (currentClient.includes('cupra')) {
                return 'cupra';
            }
            if (currentClient.includes('elli')) {
                return 'elli';
            }
            if (currentClient.includes('freenet')) {
                return 'freenet';
            }
            if (currentClient.includes('skoda')) {
                return 'skoda';
            }
            if (currentClient.includes('vw')) {
                return 'vw';
            }
            if (currentClient.includes('vwfs')) {
                return 'vwfs';
            }
            if (currentClient.includes('man')) {
                return 'man';
            }
        }

        return '';
    }
}
