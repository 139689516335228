<div class="col-12 mobile-modal__inner mobile-modal__inner--energy-compound">
    <div class="m-portlet m-portlet--mobile border-radius-rb">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span
                        class="tarifOverview-title static-title static-title--custom"
                        >{{ $t('widget.mo.strommix.title') }}</span
                    >
                    <div
                        class="row mobile-modal__wrap"
                        id="energyCompound-content"
                    >
                        <div class="col-12 col-md-6">
                            <div class="energy-compound-image">
                                <img
                                    src="/img/evus/schweizstrom/strommix-schweizstrom.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="energy-compound-image">
                                <img
                                    src="/img/evus/schweizstrom/strommix-deutschland.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
