<div class="m-portlet m-portlet--mobile" data-test="change-widget">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-2">
                <i class="fa fa-user-edit readingHistory-icon"></i>
            </div>
            <div class="col-10">
                <div class="actionMiddleWidget">
                    <i
                        class="fa fa-ellipsis-h"
                        @click="showList = !showList"
                    ></i>
                </div>
                <div class="monthlyDeposit-title static-title">
                    {{ $t('widget.cw.title') }}
                </div>
                <div>{{ $t('widget.cw.subtitle') }}</div>
                <div class="changeData-button-list" v-if="showList">
                    <button
                        data-test="change-widget-customer-data-click"
                        :disabled="changeCustomerDataLoading"
                        @click="$emit('toggleWidget', 'changeCustomerDataWidget')"
                        class="btn btn-link btn-sm"
                        id="button-changeCustomerData"
                    >
                        {{ $t('widget.cw.customer') }}</button
                    ><br />
                    <button
                        :disabled="changeBillingDataLoading"
                        @click="$emit('toggleWidget', 'changeBillingDataWidget')"
                        class="btn btn-link btn-sm"
                        id="button-changeBillingData"
                        data-test="change-widget-billing-data-click"
                    >
                        {{ $t('widget.cw.postal') }}</button
                    ><br />
                    <button
                        :disabled="changePaymentDataLoading"
                        @click="$emit('toggleWidget', 'changePaymentDataWidget')"
                        class="btn btn-link btn-sm"
                        id="button-changePaymentData"
                        data-test="change-widget-payment-data-click"
                    >
                        {{ $t('widget.cw.payment') }}</button
                    ><br />
                </div>
            </div>
        </div>
    </div>
</div>
