<div class="m-portlet m-portlet--mobile m-portlet--post-box">
    <div class="m-portlet__body">
        <span
            class="
                customerCommunications-title
                static-title
                postbox-custom-title
            "
            >{{ $t('widget.pb.title') }}</span
        ><br />
        <div class="tab-content" id="customerCommunications-content">
            <div
                class="tab-pane active"
                id="m_widget2_tab1_content"
                aria-expanded="true"
            >
                <!--begin:Timeline 1-->
                <div class="m-timeline-1 m-timeline-1--fixed">
                    <div
                        class="m-timeline-1__items"
                        id="customerCommunications-timeline"
                    >
                        <ul class="m-timeline-1__list">
                            <li
                                v-for="communication in communications"
                                class="m-timeline-1__item"
                            >
                                <div class="postbox__info">
                                    <div class="postbox__title">
                                        {{ communication.title }}
                                    </div>
                                    <div
                                        class="postbox__text"
                                        id="downloadableCommunication"
                                        v-if="communication.archiveId"
                                        @click="downloadCustomerCommunication(communication)"
                                    >
                                        {{ $t('widget.pb.more') }}
                                    </div>
                                </div>
                                <div class="postbox__date">
                                    {{ communication.createdAt | datetime }}
                                </div>
                                <div
                                    v-if="checkHasDownloadError(communication.communicationId)"
                                    class="customerCommunications-error-box"
                                >
                                    {{ $t('widget.cbd.postbox.error.file') }}
                                </div>
                            </li>
                            <li>
                                <div class="m-timeline-1--first">
                                    <service-widget
                                        :serviceWidgetPosition="serviceWidgetPosition"
                                        @serviceWidgetClicked="$emit('serviceWidgetClicked')"
                                    ></service-widget>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div class="col m--align-center">
                        <button
                            @click="loadMore"
                            v-if="!allLoaded"
                            class="
                                btn btn-sm
                                m-btn--custom m-btn--pill
                                btn-primary
                            "
                        >
                            {{ $t('widget.pb.more') }}
                        </button>
                    </div>
                </div>
                <!--End:Timeline 1-->
            </div>
        </div>
    </div>
</div>
