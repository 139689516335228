import { TariffDetailsWidget } from '../tariff-details-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './ets.html';

@WithRender
@Component({})
export class TariffDetailsWidgetEts extends TariffDetailsWidget {
    get reading() {
        return this.$store.getters['reading/getState'](this.$store.state.contracts.contractId);
    }
}
