<form-wrapper form="formCustomerData">
    <div
        class="mobile-modal__inner change-customer-data-widget"
        data-test="change-customer-data-widget"
    >
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-customer-data-widget-close-click"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close change-customer-details-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col-6">
                        <i
                            class="far fa-user readingHistory-icon mt-2"
                            v-if="displayElement('user-icon')"
                        ></i>
                        <i
                            class="fa fa-user-edit readingHistory-icon mt-2"
                            v-if="displayElement('edit-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.ccd.title') }}</span
                        >
                        <span class="ccd-box-subtitle" v-if="showSubtitle"
                            >{{ $t("widget.ccd.subtitle") }}</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            id="customerDataChange-content"
                            class="mobile-modal__wrap"
                        >
                            <div id="customerDataChangeForm">
                                <div class="row">
                                    <div class="col form-col">
                                        <form-group
                                            :class="[displayElement('col-6-field') ? 'col-6' : 'col-12']"
                                            id="change-customer-data-widget__title"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.titel')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.titel.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.titel.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.titel')"
                                                v-model="details.title"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__firstname"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.firstname')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.firstname.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.firstname.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.firstname')"
                                                v-model="details.firstname"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__date-of-birth"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.date')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.date.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.date.tooltip')"
                                            ></i>
                                            <custom-datetime
                                                v-else
                                                v-model="details.dateOfBirth"
                                                type="date"
                                                input-class="form-control"
                                                value-zone="local"
                                                format="dd.LL.yyyy"
                                            ></custom-datetime>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__email-private"
                                        >
                                            <custom-label>
                                                <template
                                                    v-if="displayElement('customer-email-label') && !details.company"
                                                >
                                                    {{ $t('widget.ccd.email')
                                                    }}{{displayElement('email*')
                                                    ? '*' : ''}}
                                                </template>
                                                <template v-else>
                                                    {{
                                                    $t('widget.ccd.emailPrivate')
                                                    }}{{displayElement('email*')
                                                    ? '*' : ''}}
                                                </template>
                                            </custom-label>
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.emailPrivate.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.emailPrivate.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="email"
                                                :class="['form-control', isRequiredField.emailPrivate && displayElement('customer-required-field') ? 'is-invalid' : '']"
                                                :placeholder="$t('widget.ccd.emailPrivate')"
                                                v-model="details.emailPrivate"
                                            />
                                        </form-group>
                                        <template v-if="showSelectAddress">
                                            <form-group
                                                id="change-customer-data-widget__zip-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{
                                                    $t('widget.mo.post.code')
                                                    }}</custom-label
                                                >
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'zip')"
                                                    @input="cityInput"
                                                    label="zipText"
                                                    v-model="selectedCityZip"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                    :clearable="false"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                    <template
                                                        #open-indicator="{ attributes }"
                                                    >
                                                        <span
                                                            v-bind="attributes"
                                                        >
                                                            <svg
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            >
                                                                <g
                                                                    id="24px/chevron-down"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <path
                                                                        d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                        id="chevron-down"
                                                                        fill="#001E50"
                                                                        fill-rule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__city-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{ $t('widget.mo.place')
                                                    }}</custom-label
                                                >
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'name')"
                                                    @input="cityInput"
                                                    label="nameText"
                                                    v-model="selectedCityName"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                    :clearable="false"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                    <template
                                                        #open-indicator="{ attributes }"
                                                    >
                                                        <span
                                                            v-bind="attributes"
                                                        >
                                                            <svg
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            >
                                                                <g
                                                                    id="24px/chevron-down"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <path
                                                                        d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                        id="chevron-down"
                                                                        fill="#001E50"
                                                                        fill-rule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__street-select"
                                                v-if="selectedCityName"
                                            >
                                                <custom-label
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.street') }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="streets"
                                                    v-model="selectedStreet"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                    @input="streetInput"
                                                    label="name"
                                                    :clearable="false"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                    <template
                                                        #open-indicator="{ attributes }"
                                                    >
                                                        <span
                                                            v-bind="attributes"
                                                        >
                                                            <svg
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            >
                                                                <g
                                                                    id="24px/chevron-down"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <path
                                                                        d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                        id="chevron-down"
                                                                        fill="#001E50"
                                                                        fill-rule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                        </template>
                                        <template v-else>
                                            <form-group
                                                id="change-customer-data-widget__street"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.street')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.street.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.street.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.street')"
                                                    v-model="details.street"
                                                />
                                            </form-group>
                                        </template>

                                        <form-group
                                            v-if="!showSelectAddress"
                                            id="change-customer-data-widget__zip"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.zip')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.zip.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.zip.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.zip')"
                                                v-model="details.zip"
                                            />
                                        </form-group>
                                        <div class="row">
                                            <form-group
                                                id="change-customer-data-widget__company"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.company')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.company.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.company.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.company')"
                                                    v-model="details.company"
                                                />
                                            </form-group>
                                        </div>
                                    </div>

                                    <div class="col form-col">
                                        <form-group
                                            :class="[ displayElement('col-6-field') ? 'col-6' : 'col-12']"
                                            id="change-customer-data-widget__salutation"
                                        >
                                            <custom-label
                                                >{{
                                                $t('widget.ccd.salutation')}}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.salutation.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.salutation.tooltip')"
                                            ></i>
                                            <custom-v-select
                                                :options="salutationOptions"
                                                :value="salutationCurrentlySelected"
                                                @input="(object) => details.salutation = object.value"
                                                :clearable="false"
                                            >
                                                <template
                                                    #open-indicator="{ attributes }"
                                                >
                                                    <span v-bind="attributes">
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        >
                                                            <g
                                                                id="24px/chevron-down"
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <path
                                                                    d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                    id="chevron-down"
                                                                    fill="#001E50"
                                                                    fill-rule="nonzero"
                                                                ></path>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </template>
                                            </custom-v-select>
                                            <span></span>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__lastname"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.lastname')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.lastname.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.lastname.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.lastname')"
                                                v-model="details.surname"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-home-with-areacode"
                                        >
                                            <div class="row">
                                                <div class="col-5">
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.phone.area')
                                                        }}</custom-label
                                                    >
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.area')"
                                                        v-model="details.phoneHomeAreaCode"
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.phone.number')
                                                        }}</custom-label
                                                    >
                                                    <custom-input
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.number')"
                                                        v-model="details.phoneHome"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group>
                                            <div style="height: 79px"></div>
                                        </form-group>
                                        <template v-if="showSelectAddress">
                                            <form-group
                                                id="change-customer-data-widget__zip-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{
                                                    $t('widget.mo.post.code')
                                                    }}</custom-label
                                                >
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'zip')"
                                                    @input="cityInput"
                                                    label="zipText"
                                                    v-model="selectedCityZip"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                    :clearable="false"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                    <template
                                                        #open-indicator="{ attributes }"
                                                    >
                                                        <span
                                                            v-bind="attributes"
                                                        >
                                                            <svg
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            >
                                                                <g
                                                                    id="24px/chevron-down"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <path
                                                                        d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                        id="chevron-down"
                                                                        fill="#001E50"
                                                                        fill-rule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__city-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{ $t('widget.mo.place')
                                                    }}</custom-label
                                                >
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'name')"
                                                    @input="cityInput"
                                                    label="nameText"
                                                    v-model="selectedCityName"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                    :clearable="false"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                    <template
                                                        #open-indicator="{ attributes }"
                                                    >
                                                        <span
                                                            v-bind="attributes"
                                                        >
                                                            <svg
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            >
                                                                <g
                                                                    id="24px/chevron-down"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <path
                                                                        d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                        id="chevron-down"
                                                                        fill="#001E50"
                                                                        fill-rule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__house-number"
                                                v-if="selectedCityName"
                                            >
                                                <custom-label
                                                    :class="[houseNumberError ? 'text-danger' : '']"
                                                    >{{
                                                    $t('widget.mo.house.number')
                                                    }}</custom-label
                                                >
                                                <custom-input
                                                    type="text"
                                                    :class="['form-control', houseNumberError ? 'is-invalid' : '']"
                                                    v-model="details.houseNumber"
                                                    @change="houseNumberError = false"
                                                />
                                            </form-group>
                                        </template>
                                        <template v-else>
                                            <form-group
                                                id="change-customer-data-widget__house-number"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.house')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.house.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.house.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.house')"
                                                    v-model="details.houseNumber"
                                                />
                                            </form-group>
                                        </template>

                                        <template v-if="!showSelectAddress">
                                            <form-group
                                                id="change-customer-data-widget__city"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.place')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.place.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.place.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.place')"
                                                    v-model="details.city"
                                                />
                                            </form-group>
                                        </template>

                                        <form-group
                                            :class="[ displayElement('col-6-field') ? 'col-6' : 'col-12']"
                                            id="change-customer-data-widget__group_affiliation"
                                            v-if="details.company && !currentClient.includes('freenet')"
                                        >
                                            <custom-label
                                                >{{
                                                $t('widget.ccd.group.affiliation')}}</custom-label
                                            >
                                            <custom-v-select
                                                :options="vwGroupAffiliationOptions"
                                                :value="vwGroupAffiliation"
                                                @input="(object) => onGroupAffiliationInput(object)"
                                                :clearable="false"
                                            >
                                                <template
                                                    #open-indicator="{ attributes }"
                                                >
                                                    <span v-bind="attributes">
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        >
                                                            <g
                                                                id="24px/chevron-down"
                                                                stroke="none"
                                                                stroke-width="1"
                                                                fill="none"
                                                                fill-rule="evenodd"
                                                            >
                                                                <path
                                                                    d="M12,17.813 C11.55,17.813 11.127,17.635 10.811,17.313 L1.025,7.316 L1.975,6.386 L11.76,16.382 C11.89,16.513 12.109,16.514 12.24,16.381 L22.024,6.386 L22.975,7.316 L13.19,17.312 C12.873,17.636 12.45,17.813 12,17.813 Z"
                                                                    id="chevron-down"
                                                                    fill="#001E50"
                                                                    fill-rule="nonzero"
                                                                ></path>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </template>
                                            </custom-v-select>
                                            <div
                                                class="customtooltip circle-icon"
                                            >
                                                <i
                                                    @mouseover="showTooltip = true"
                                                    @click="showTooltip = !showTooltip"
                                                ></i>
                                                <div
                                                    v-if="showTooltip"
                                                    class="circle-icon-tooltip"
                                                >
                                                    <div
                                                        class="circle-icon-tooltip-text"
                                                    >
                                                        <div
                                                            class="circle-icon-tooltip-title"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.title')
                                                            }}<span
                                                                @click="showTooltip = false"
                                                            ></span>
                                                        </div>
                                                        <div
                                                            class="circle-icon-tooltip-label"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.label1')
                                                            }}
                                                        </div>
                                                        <div
                                                            class="circle-icon-tooltip-description"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.description1')
                                                            }}
                                                        </div>
                                                        <div
                                                            class="circle-icon-tooltip-label"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.label2')
                                                            }}
                                                        </div>
                                                        <div
                                                            class="circle-icon-tooltip-description"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.description2')
                                                            }}
                                                        </div>
                                                        <div
                                                            class="circle-icon-tooltip-label"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.label3')
                                                            }}
                                                        </div>
                                                        <div
                                                            class="circle-icon-tooltip-description"
                                                        >
                                                            {{
                                                            $t('widget.ccd.group.affiliation.tooltip.description3')
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form-group>

                                        <form-group
                                            id="change-customer-data-widget__phone-business-with-areacode"
                                            v-if="details.company"
                                        >
                                            <custom-label
                                                >{{
                                                $t('widget.ccd.fixedBusiness')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.fixedBusiness.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.fixedBusiness.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-5">
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.area')"
                                                        v-model="details.phoneBusinessAreaCode"
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.number')"
                                                        v-model="details.phoneBusiness"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__email-business"
                                            v-if="details.company"
                                        >
                                            <custom-label
                                                >{{
                                                $t('widget.ccd.emailBusiness')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.emailBusiness.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.emailBusiness.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="email"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.emailBusiness')"
                                                v-model="details.emailBusiness"
                                            />
                                        </form-group>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-if="displayElement('data-valid-checkbox')"
                                >
                                    <div class="col">
                                        <div
                                            class="form-col m-login__signin-step-4__ccd-checkbox-first"
                                        >
                                            <form-group
                                                class="form-check"
                                                id="change-customer-data-widget__dataValid"
                                            >
                                                <custom-checkbox
                                                    inputClass="form-check-input"
                                                    v-model="dataValid"
                                                    @change="dataValidError = false"
                                                    :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                    :labelClass="[
                                                            'form-check-label',
                                                            displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '',
                                                            dataValidError ? 'is-invalid' : ''
                                                            ]"
                                                    :options="[
                                                                    {
                                                                        value: false,
                                                                        label: `${this.$t('widget.ccd.checkbox.first').toLocaleString()}
                                                                        <i></i>
                                                                        <span></span>
                                                                        `,
                                                                        labelAsHtml: true
                                                                    },
                                                                ]"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div
                                            class="form-col m-login__signin-step-4__ccd-checkbox-second"
                                        >
                                            <form-group
                                                class="form-check"
                                                id="change-customer-data-widget__all-ads-accepted"
                                            >
                                                <custom-checkbox
                                                    inputClass="form-check-input"
                                                    :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                    v-model="allAdsAccepted"
                                                    :labelClass="[
                                                            'form-check-label',
                                                            displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                            ]"
                                                    :options="[
                                                                    {
                                                                        value: false,
                                                                        label: `${this.$t('widget.ccd.checkbox.second1').toLocaleString()}
                                                                        <a
                                                                                :href='mailto: ${this.$t('widget.ccd.checkbox.second2').toLocaleString()}'
                                                                        >
                                                                        ${this.$t('widget.ccd.checkbox.second2')}
                                                                        </a>
                                                                        ${this.$t('widget.ccd.checkbox.second3')}
                                                                        <i></i>
                                                                        <span></span>
                                                                        `,
                                                                        labelAsHtml: true
                                                                    },
                                                                ]"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ccd-form-row-2">
                                    <div class="col-sm-8 col-xs-12">
                                        <messages-block
                                            fromTestElement="change-customer-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div
                                        v-if="!displayElement('disableDataChange')"
                                        class="col-sm-4 col-xs-12"
                                    >
                                        <div class="text-end">
                                            <button
                                                data-test="change-customer-data-widget-save-click"
                                                class="btn btn-primary btn--tt0"
                                                @click="submit"
                                                :disabled="submitButtonDisabled"
                                            >
                                                {{ $t('widget.ccd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
