import { default as Component } from 'vue-class-component';
import VueI18n from 'vue-i18n';
import LocaleMessages = VueI18n.LocaleMessages;

// Call Render template.
import WithRender from './dsp-flex.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';

// templates

// Widgets
import { NonCommodityTariffDetailsWidget } from '@/components/widgets/non-commodity-tariff-details-widget';
import { Header } from '@/components/template/header';
import { Footer } from '@/components/template/footer';
import { LeftAside } from '@/components/template/left-aside';
import { ScrollTop } from '@/components/template/scroll-top';
import { AccountTransactionsWidget } from '@/components/widgets/account-transactions-widget';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget';
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { ChangeWidget } from '@/components/widgets/change-widget';
import { CustomerDataCorrectionWidgetDspFlex } from '@/components/widgets/customer-data-correction-widget/custom/dsp-flex';
import { DepositChangeWidget } from '@/components/widgets/deposit-change-widget';
import { DepositOverviewWidget } from '@/components/widgets/deposit-overview-widget';
import { InvoiceWidget } from '@/components/widgets/invoice-widget';
import { RecommendationWidget } from '@/components/widgets/recommendation-widget';
import { OptInWidget } from '@/components/widgets/opt-in-widget';
import { MeterHistoryWidget } from '@/components/widgets/meter-history-widget';
import { MeterSubmitWidget } from '@/components/widgets/meter-submit-widget';
import { PostBoxWidget } from '@/components/widgets/post-box-widget';
import { PostBoxCombinedWidget } from '@/components/widgets/post-box-combined-widget';
import { TariffDetailsWidget } from '@/components/widgets/tariff-details-widget';
import { TariffWidget } from '@/components/widgets/tariff-widget';
import { WelcomeWidget } from '@/components/widgets/welcome-widget';
import { StatusBarWidget } from '@/components/widgets/status-bar-widget';
import { CustomerServiceFormWidget } from '@/components/widgets/customer-service-form-widget';
import { CustomerServiceWidget } from '@/components/widgets/customer-service-widget';
import { SalesWidget } from '@/components/widgets/sales-widget';
import { CancellationWidget } from '@/components/widgets/cancellation-widget';
import { MoveWidget } from '@/components/widgets/move-widget';
import { TariffAvailableWidget } from '@/components/widgets/tariff-available-widget/custom/qcells';
import { TariffChangeWidget } from '@/components/widgets/tariff-change-widget';
import { TariffOverviewWidget } from '@/components/widgets/tariff-overview-widget';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { AdsPopupWidget } from '@/components/widgets/ads-popup-widget';
import { ModalPasswordUpdateWidget } from '@/components/widgets/modal-password-update-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { RevokeWidget } from '@/components/widgets/revoke-widget';
import { DepositWidget } from '@/components/widgets/deposit-widget';
import { AccountBalanceWidget } from '@/components/widgets/account-balance-widget';
import { MeterWidget } from '@/components/widgets/meter-widget';

@WithRender
@Component({
    components: {
        // Template
        'app-header': Header,
        'app-footer': Footer,
        'left-aside': LeftAside,
        'scroll-top': ScrollTop,
        // External packages
        // Widgets
        'account-balance-widget': AccountBalanceWidget,
        'account-transactions-widget': AccountTransactionsWidget,
        'change-billing-data-widget': ChangeBillingDataWidget,
        'change-customer-data-widget': ChangeCustomerDataWidget,
        'change-widget': ChangeWidget,
        'customer-data-correction-widget': CustomerDataCorrectionWidgetDspFlex,
        'deposit-change-widget': DepositChangeWidget,
        'deposit-overview-widget': DepositOverviewWidget,
        'deposit-widget': DepositWidget,
        'invoice-widget': InvoiceWidget,
        'recommendation-widget': RecommendationWidget,
        'opt-in-widget': OptInWidget,
        'meter-history-widget': MeterHistoryWidget,
        'meter-submit-widget': MeterSubmitWidget,
        'meter-widget': MeterWidget,
        'post-box-widget': PostBoxWidget,
        'post-box-combined-widget': PostBoxCombinedWidget,
        'tariff-details-widget': TariffDetailsWidget,
        'tariff-widget': TariffWidget,
        'welcome-widget': WelcomeWidget,
        'status-bar-widget': StatusBarWidget,
        'customer-service-form-widget': CustomerServiceFormWidget,
        'customer-service-widget': CustomerServiceWidget,
        'sales-widget': SalesWidget,
        'cancellation-widget': CancellationWidget,
        'move-widget': MoveWidget,
        'tariff-available-widget': TariffAvailableWidget,
        'tariff-change-widget': TariffChangeWidget,
        'tariff-overview-widget': TariffOverviewWidget,
        'non-commodity-tariff-details-widget': NonCommodityTariffDetailsWidget,
        'cookie-banner-widget': CookieBannerWidget,
        'ads-popup-widget': AdsPopupWidget,
        'modal-password-update-widget': ModalPasswordUpdateWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
        'revoke-widget': RevokeWidget,
    },
})
export class Dashboard extends DashboardParent {
    private customerServiceFormWidgetEnabled = true;

    protected created(): void {
        if (
            this.$store.state.contracts.contractId &&
            !this.contract.billingAddress.loaded &&
            !this.contract.bankingDetails.loaded
        ) {
            this.$store.dispatch(
                'tariff/overview',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
        }
    }

    get tariffChangeAllowed(): boolean {
        return this.$store.state.settings.tariffChange;
    }

    get tariffOverviewAllowed(): boolean {
        return this.$store.state.settings.tariffOverview;
    }

    get showCustomerServiceFormWidget(): boolean {
        return (
            this.$store.state.settings.enableTicketSystem &&
            this.customerServiceFormWidgetEnabled
        );
    }

    get showDepositWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('depositWidget');
    }

    get showMeterWidget(): boolean {
        return (
            this.shouldDisplayWidgetByContractStatus('meterWidget') &&
            !this.isNonCommodityContract
        );
    }

    get showDspFlexTariffWidget(): boolean {
        return this.showTariffWidget && false;
    }

    get showDspFlexTariffDetailsWidget(): boolean | string | LocaleMessages {
        return this.shouldDisplayWidgetByContractStatus('tariffDetailsWidget');
    }

    get showDspFlexInvoiceWidget(): string | boolean | LocaleMessages {
        return this.showInvoiceWidget;
    }

    get showChangeWidget(): boolean {
        return !this.isNonCommodityContract;
    }
}
