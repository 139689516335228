<div class="mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div
                v-if="!displayElement('close-button-hidden')"
                @click="$emit('closeWidget')"
                id="button-closeTariffDetails"
                class="fullwidthArea-close"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="row mobile-modal__wrap" id="tariffDetails-content">
                <div class="col-12">
                    <i
                        class="fa fa-hands-helping readingHistory-icon"
                        v-if="displayElement('hands-helping-icon')"
                    ></i>
                    <span
                        class="box-title static-title"
                        v-if="displayElement('contract-details-number')"
                    >
                        {{ $t('widget.td.title.order') }} {{
                        $t('widget.td.title.extension.order') }} {{ contract.id
                        }}
                    </span>
                    <span class="box-title static-title" v-else
                        >{{ $t('widget.td.title') }}</span
                    >
                    <button
                        v-if="tariffOverviewAllowed"
                        class="btn btn-link ms-2 mt-1 pt-3"
                        @click="$emit('toggleWidget', 'showTariffOverview')"
                    >
                        {{ $t('widget.td.tariffOverview') }}
                    </button>
                </div>
                <div class="col-12" v-if="contractInCancellation">
                    <div class="text-warning">
                        {{
                        $t('widget.td.inCancellation.yourCancellationForContract')
                        }} {{ contract.id }} {{
                        $t('widget.td.inCancellation.for') }} {{
                        contract.cancelledAt | date }} {{
                        $t('widget.td.inCancellation.hasBeen') }} {{
                        contract.filingDateCancellation | date }} {{
                        $t('widget.td.inCancellation.received') }}.<br />
                        {{ $t('widget.td.inCancellation.reason') }}: {{
                        contract.cancellationReason }}
                    </div>
                    <br />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4">
                    <b>{{ $t('widget.td.shipping.address') }}</b><br />
                    <span id="tariffDetails-deliveryAddress">
                        {{ contract.deliveryAddress.firstname }} {{
                        contract.deliveryAddress.surname }}<br />
                        {{ contract.deliveryAddress.street }} {{
                        contract.deliveryAddress.houseNumber }}<br />
                        {{ contract.deliveryAddress.zip }} {{
                        contract.deliveryAddress.city }}</span
                    >
                    <br /><br />
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4">
                    <span
                        id="tariffDetails-bankingDetails"
                        v-if="contract.bankingDetails.iban"
                    >
                        <b>{{ $t('widget.td.account') }}</b><br />
                        <span
                            v-if="contract.bankingDetails.accountHolder && contract.bankingDetails.iban"
                            >{{ $t('widget.td.accountHolder') }}: {{
                            contract.bankingDetails.accountHolder }}<br
                        /></span>
                        <span v-if="contract.bankingDetails.iban"
                            >{{ $t('widget.td.iban') }}: {{
                            contract.bankingDetails.iban }}<br
                        /></span>
                        <span v-if="contract.bankingDetails.bic"
                            >{{ $t('widget.td.bic') }}: {{
                            contract.bankingDetails.bic }}<br
                        /></span>
                        <span v-if="contract.bankingDetails.directDebit"
                            >{{ $t('widget.td.sepaMandate') }}: {{
                            contract.bankingDetails.sepaMandate }}</span
                        >
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
