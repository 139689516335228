<div class="m-header--wrapper" :style="background">
    <!-- BEGIN: Header -->
    <header id="m_header" class="m-grid__item m-header">
        <div class="m-container m-container--fluid m-container--full-height">
            <div
                class="m-stack m-stack--ver m-stack--desktop d-flex header-v2-elli"
            >
                <!-- BEGIN: Brand -->
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general">
                        <div
                            class="m-stack__item m-stack__item--middle m-brand__logo"
                        >
                            <a
                                href="/"
                                class="m-brand__logo-wrapper"
                                v-if="showLogoTop"
                            >
                                <div :style="logo"></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="brand-text">
                    <span>{{ $t('auth.logo.text') }}</span>
                </div>
                <!-- END: Brand -->
            </div>
            <div class="line-logo" v-if="showLogoLine">
                <div class="line-left"></div>
                <div v-if="isElli" class="center-logo">
                    <svg
                        v-if="showLogoLineVolkswagen"
                        class="line-logo-image"
                        version="1.2"
                        baseProfile="tiny"
                        id="master-artboard"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="1024px"
                        height="1024px"
                        viewBox="0 0 1024 1024"
                        overflow="visible"
                        xml:space="preserve"
                    >
                        <g
                            transform="matrix(10.188387870788574, 0, 0, 10.188387870788574, -251.519936680809, -252.79260253906244)"
                        >
                            <g>
                                <path
                                    fill="#FFFFFF"
                                    d="M75,120.4c-24.9,0-45.3-20.5-45.3-45.4c0-5.6,1-10.9,2.9-15.9l26.5,53.3c0.3,0.7,0.8,1.3,1.6,1.3
                                s1.3-0.6,1.6-1.3l12.2-27.3c0.1-0.3,0.3-0.6,0.6-0.6s0.4,0.3,0.6,0.6l12.2,27.3c0.3,0.7,0.8,1.3,1.6,1.3c0.8,0,1.3-0.6,1.6-1.3
                                l26.5-53.3c1.9,5,2.9,10.3,2.9,15.9C120.3,99.9,99.9,120.4,75,120.4z M75,64.7c-0.3,0-0.4-0.3-0.6-0.6l-14.2-32
                                c4.6-1.7,9.6-2.6,14.8-2.6s10.2,0.9,14.8,2.6l-14.2,32C75.4,64.5,75.3,64.7,75,64.7z M60.5,97.6c-0.3,0-0.4-0.3-0.6-0.6l-23-46.4
                                C41,44.3,46.5,39,53.2,35.3l16.6,36.9C70,72.8,70.5,73,71,73h8c0.6,0,1-0.1,1.3-0.8l16.6-36.9c6.6,3.7,12.2,9,16.3,15.3L90,97
                                c-0.1,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.4-0.3-0.6-0.6l-8.7-19.8c-0.3-0.7-0.7-0.8-1.3-0.8h-8c-0.6,0-1,0.1-1.3,0.8L61.1,97
                                C61,97.3,60.8,97.6,60.5,97.6z M75,125c27.7,0,50-22.3,50-50s-22.3-50-50-50S25,47.3,25,75S47.3,125,75,125z"
                                />
                            </g>
                        </g>
                    </svg>
                    <div
                        v-if="showLogoLineCupra"
                        class="line-logo-image"
                        id="line-logo-image-cupra"
                    ></div>
                    <div
                        v-if="showLogoLineAudi"
                        class="line-logo-image"
                        id="line-logo-image-audi"
                    ></div>
                    <div
                        v-if="showLogoLineSkoda"
                        class="line-logo-image"
                        id="line-logo-image-skoda"
                    ></div>
                    <div
                        v-if="showLogoLineVolkswagenFS"
                        class="line-logo-image"
                        id="line-logo-image-vwfs"
                    ></div>
                    <div
                        v-if="showLogoLineFreenet"
                        class="line-logo-image"
                        id="line-logo-image-freenet"
                    ></div>
                    <div
                        v-if="showLogoLineElli"
                        class="line-logo-image"
                        id="line-logo-image-elli"
                    ></div>
                    <div
                        v-if="showLogoLineMan"
                        class="line-logo-image"
                        id="line-logo-image-man"
                    ></div>
                </div>
                <div v-if="isElli" class="line-right"></div>
            </div>
        </div>
    </header>
    <div class="header-title">
        <span>{{ $t('auth.header.title') }}</span>
    </div>
    <!-- END: Header -->

    <div v-if="showBadge" class="elli-badge-wrapper">
        <div class="elli-badge-container">
            <div class="elli-badge" :style="badgeImage"></div>
        </div>
    </div>
    <!-- Not needed Navigation
  <a href="#" class="m-brand__toggler">
    <span></span>
  </a>
  -->
</div>
