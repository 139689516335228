<div>
    <div class="m-portlet m-portlet--mobile tariff-available">
        <div class="tariff-available__header">
            <div
                class="tariff-available__header-title"
                v-html="headerTitle"
            ></div>
            <div
                class="tariff-available__header-subtitle"
                v-html="headerSubTitle"
            ></div>
        </div>
        <div class="m-portlet__body">
            <div class="col-12 fullWidthArea mobile-modal__inner">
                <div class="tariff-available__main">
                    <div class="tariff-available__main-title">
                        {{ tariff.title }}
                    </div>
                    <div
                        class="tariff-available__main-subtitle"
                        v-html="productSubTitle"
                    ></div>
                    <div
                        class="tariff-available__main-logo-block"
                        v-if="defaultAddonSet"
                    >
                        <div class="tariff-available__main-logo-description">
                            {{ addonDescription }}
                        </div>
                        <div class="tariff-available__main-logo-image">
                            <img
                                :src="parseImage(tariff.addons[0].cmsProperties)"
                            />
                        </div>
                    </div>
                    <div
                        class="tariff-available__main-logo-block tariff-available__main-logo-block-instantBonusHighlight"
                        v-if="instantBonusAvailable"
                    >
                        <div
                            v-html="instantBonusDescription"
                            class="tariff-available__main-logo-description"
                        ></div>
                    </div>
                    <div
                        v-html="tariffDescription"
                        class="tariff-available__main-list"
                    ></div>

                    <!--
                    <div class="info">
                        <span v-html="$t('widget.ta.info.text')"></span>
                    </div>
                    <a href="tel:49034943864444">
                        <button>TEL +49 (0) 3494 - 386 4444</button>
                    </a>
                    -->
                    <button
                        @click="$emit('toggleWidget4', 'showTariffChange')"
                        data-test="tariff-change-widget-button-click"
                    >
                        {{ $t('widget.ta.change.now') }}
                    </button>

                    <div class="tariff-available__footer">
                        <div v-html="tariff.agb"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button
        data-test="tariff-widget-detail-button-click"
        @click="$emit('toggleWidget3', 'tariffDetailsWidget')"
        class="btn btn-primary btn-block mt-3 tariff-available_details-button"
        id="button-tariffDetails"
    >
        {{ $t('widget.ta.current.tariff') }}
    </button>
</div>
