import { ChangeCustomerDataWidget } from '../change-customer-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './sachsenenergie.html';

import vSelect from 'vue-select';

@WithRender
@Component({
    components: {
        'v-select': vSelect,
    },
})
export class ChangeCustomerDataWidgetSachsenenergie extends ChangeCustomerDataWidget {
    public emailRepeat = '';
    public showEmailRepeat = false;

    protected checkAndSubmit(): void {
        const oldEmail = this.$store.state.customer.emailPrivate;
        const newEmail = this.details.emailPrivate;
        if (newEmail !== oldEmail && newEmail !== this.emailRepeat) {
            this.errorInformation.push({
                key: '',
                message: this.$t('widget.ccd.emailsDifferent').toLocaleString(),
            });
        } else {
            this.submit().then(null);
        }
    }
}
