import WithRender from './eoptimum.html';
import { default as Component } from "vue-class-component";
import { Dashboard as DashboardParent } from "@/components/pages/dashboard/dashboard";

import {ChangeWidgetEoptimum} from "@/components/widgets/change-widget/custom/eoptimum";

@WithRender
@Component({
    components: {
        'change-widget': ChangeWidgetEoptimum,
    },
})
export class Dashboard extends DashboardParent {}