import { ChangeWidget } from '../change-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './eoptimum.html';
import moment from 'moment';

@WithRender
@Component({})
export class ChangeWidgetEoptimum extends ChangeWidget {
    get contractStartDate(): string {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.startDate;
    }

    get revocationDate(): string {
        return moment(new Date()).format('YYYY-MM-DD');
    }

    get contractMoveAllowed(): boolean {
        return (
            this.$store.state.settings.contractMove &&
            this.contract.relocationPossible
        );
    }

    get showRevocationWidget(): boolean {
        const date = moment(this.contractStartDate, 'YYYY-MM-DD').add(
            14,
            'days'
        );
        return (
            this.revocationDate <= date.toISOString() &&
            this.$store.state.settings.revocationProcessActive
        );
    }

    get tariffChangeAllowed(): boolean {
        return this.$store.state.settings.tariffChange;
    }

    get tariffChangeAvailable(): boolean {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length > 0
        );
    }
}
