<div class="m-aside-modal m-aside-modal__wrap">
    <div class="m-aside-modal__body">
        <!--begin::Form-->

        <div class="d-flex align-items-center justify-content-between">
            <span class="box-title static-title">
                {{ $t('widget.modal.message.title') }}
            </span>
            <div class="m-aside-modal__close" @click="closeModal()">
                <i class="m-menu__link-icon fa fa-times"></i>
            </div>
        </div>

        {{ message }}

        <!--begin::Action-->
        <div class="m-login__action"></div>
        <!--end::Action-->
    </div>
</div>
