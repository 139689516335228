import { injectable } from 'inversify';
import moment from 'moment';

import Format from '../interfaces/Format';

@injectable()
export default class FormatProvider implements Format {
    public formats: any = {
        date: {
            de: 'DD.MM.YYYY',
            us: 'MM/DD/YYYY',
        },
        datetime: {
            de: 'DD.MM.YYYY HH:mm',
            us: 'MM/DD/YYYY h:mm A',
        },
        euro: {
            de: 'de-DE',
            us: 'en-US',
        },
    };
    public date() {
        const format = this.getFormat('date');
        return function date(d: string | null): string {
            if (d === null) {
                return '';
            }
            return moment(d).format(format);
        };
    }

    public monthYear(): any {
        return function monthYear(d) {
            if (d !== null) {
                const dd = moment(d);
                if (dd.isValid()) {
                    return dd.format('MM.YYYY');
                } else {
                    return d;
                }
            } else {
                return '';
            }
        };
    }

    public monthYearHum(): any {
        return function monthYear(d) {
            if (d !== null) {
                const dd = moment(d);
                if (dd.isValid()) {
                    return dd.format('MMM YYYY');
                } else {
                    return d;
                }
            } else {
                return '';
            }
        };
    }

    public datetime(): any {
        const format = this.getFormat('datetime');
        return function datetime(d) {
            if (d !== null) {
                const dd = moment(d);
                if (dd.isValid()) {
                    return dd.format(format);
                } else {
                    return d;
                }
            } else {
                return '';
            }
        };
    }

    public euro() {
        const format = this.getFormat('euro');
        return function euro(n): string {
            if (n !== null) {
                return parseFloat(n).toLocaleString(format, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            } else {
                return '';
            }
        };
    }

    public euroFull() {
        const format = this.getFormat('euro');
        return function euroFull(n): string {
            if (n !== null) {
                return Number(n).toLocaleString(format, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            } else {
                return '';
            }
        };
    }

    public euroSpecial() {
        const format = this.getFormat('euro');
        return function euroSpecial(n): string {
            if (n !== null) {
                if (n % 1 === 0) {
                    return Number(n).toLocaleString(format);
                } else {
                    return parseFloat(n).toLocaleString(format, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                }
            } else {
                return '';
            }
        };
    }

    private getFormat(type) {
        let country = process.env.VUE_APP_COUNTRY_CODE;
        const defaultCountry = 'de';
        if (!country) country = 'de';

        const format = this.formats[type];
        if (format) {
            if (this.formats[type][country]) {
                return this.formats[type][country];
            } else {
                return this.formats[type][defaultCountry];
            }
        }

        return '';
    }
}
