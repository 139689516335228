import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './auth-page.html';
import { HeaderV2 } from '../../template/header-v2';
import { Footer } from '../../template/footer';
import { ResetPasswordWidget } from '@/components/widgets/reset-password-widget';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { VerifyWidget } from '@/components/widgets/verify-widget';
import { RestoreTwoFactorAuthentication } from '@/components/pages/auth/2fa/restore';

import { LoginPage } from '@/components/pages/auth/login-page';
import { RegisterPage } from '@/components/pages/auth/register';
import { OneTimePassword } from '@/components/pages/auth/one-time-password';
import { AuthPlusNewPasswordWidget } from '@/components/widgets/auth-plus-new-password-widget';
import { Watch } from 'vue-property-decorator';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
        LoginPage,
        RegisterPage,
        OneTimePassword,
        ResetPasswordWidget,
        AuthPlusNewPasswordWidget,
        'cookie-banner-widget': CookieBannerWidget,
        VerifyWidget,
        'restore-two-factor-authentication': RestoreTwoFactorAuthentication,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
            ],
        };
    },
})
export class AuthPage extends Base {
    public showLogoutNote = false;

    public isOPTAlternative = false;
    public isOPTAlternativeTrait = '';

    protected forceToChangePassword = false;

    get isMobile(): boolean {
        return /iPhone|iPad|Android/.test(navigator.userAgent);
    }

    get isLoginPage(): boolean {
        return (
            !this.isRegister &&
            !this.resetPassword &&
            !this.isVerify &&
            !this.isRestoreTwoFactorAuthentication &&
            !this.isOneTimePassword &&
            !this.isForceToChangePassword
        );
    }

    get isOneTimePassword(): boolean {
        return this.$store.state.settings.isAuthPlusOneTimePassword;
    }

    get isOneTimePasswordEnableAlternative(): boolean {
        return this.$store.state.settings
            .isAuthPlusOneTimePasswordEnabledAlternative;
    }

    get isForceToChangePassword(): boolean {
        return this.$store.state.auth.forceChangePassword;
    }

    public displayAuth = false;
    protected isRegister = false;
    protected resetPassword = false;
    protected isVerify = false;
    protected isRestoreTwoFactorAuthentication = false;
    protected second = 0;

    protected elements = {
        bsw: ['login-footer-links-bsw', 'app-login-subheader', 'bsw-link-logo'],
        ets: ['app-footer', 'header-v2'],
        elli: ['app-footer', 'header-v2'],
        'elli-*': ['app-footer', 'header-v2'],
        waermeenergie: ['login-footer-waermeenergie'],
        wdenergy: ['app-footer', 'header-v2'],
        'wdenergy-us': ['app-footer', 'header-v2'],
        keppel: ['app-login-subtitle'],
        qcells: ['cookie-banner'],
        eoptimum: ['eoptimum-link-logo', 'login-footer-links-eoptimum'],
        sachsenenergie: ['login-footer-links-sachsenenergie'],
    };

    public created(): void {
        localStorage.removeItem('token');

        if (this.$store.state.settings.idpActive) {
            this.$router
                .push('/idp')
                .catch((failure) => handleNavigationFailure(failure));
        } else {
            this.displayAuth = true;
            if (
                this.$store.state.settings.isAuthPlusOneTimePassword &&
                (this.$route.name === 'register' ||
                    this.$route.name === 'registrierung')
            ) {
                this.$router
                    .push('/')
                    .catch((failure) => handleNavigationFailure(failure));
            }
        }

        this.$store.dispatch('auth/loadErrorTry');

        const showGoodByeMessage = localStorage.getItem(
            'wd-portal-show-goodbye-message'
        );
        if (showGoodByeMessage) {
            this.showLogoutNote = true;
            setTimeout(() => {
                this.showLogoutNote = false;
            }, 10000);
            localStorage.removeItem('wd-portal-show-goodbye-message');
        }
    }

    public handleToggleOPT(event) {
        this.isOPTAlternative = event.is_active;
        if (event.trait) {
            this.isOPTAlternativeTrait = event.trait;
        } else {
            this.isOPTAlternativeTrait = '';
        }
    }

    public getLoginSubTitle(): string {
        let key: string = this.isOneTimePasswordEnableAlternative
            ? 'login.subtitle.otp.alternative'
            : 'login.subtitle';

        if (this.isOPTAlternative) {
            key = 'login.subtitle.alternative';
        }

        if (this.isOPTAlternativeTrait) {
            key += '.' + this.isOPTAlternativeTrait;
        }

        return this.$t(key).toString();
    }

    public mounted() {
        if (['elli'].includes(this.currentClient)) {
            let chatBot = window['hsag_chatbot'];
            if (!chatBot) {
                const self = this;
                setTimeout(() => {
                    chatBot = window['hsag_chatbot'];
                    self.addBubblesElli(chatBot);
                }, 2000);
            } else {
                this.addBubblesElli(chatBot);
            }
        }
        if (['bsw'].includes(this.currentClient)) {
            const meta = document.createElement('meta');
            meta.setAttribute('name', 'google-site-verification');
            meta.setAttribute(
                'content',
                'V5P-WaxEeO9KNUWenwG56g2SqklYMXtVxYLYHJ2c-fg'
            );
            document.head.appendChild(meta);
        }
    }

    @Watch('$route.name', { immediate: true, deep: true })
    private checkRoute(routeName) {
        this.isRegister =
            routeName === 'register' || routeName === 'registrierung';
        this.resetPassword = routeName === 'reset-password';
        this.isRestoreTwoFactorAuthentication =
            routeName === 'restore-two-factor-authentication';

        this.isVerify = routeName === 'verify';

        if (routeName === 'login') {
            const username = this.$route.query.username
                ? this.$route.query.username.toString()
                : '';
            this.$store.dispatch('auth/setUserName', username);

            setInterval(() => (this.second = new Date().getTime()), 1000);
        }
    }

    private changeLocale(locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('wd-portal-language', this.$i18n.locale);
    }

    private addBubblesElli(chatBot) {
        chatBot.api.speechBubbles([
            {
                text: 'Übermitteln Sie hier Ihren Zählerstand auch ohne Login',
                intent: 'MR_tellMeterReading',
            },
        ]);
    }
}
