import { CookieBannerWidgetQcells } from '@/components/widgets/cookie-banner-widget/custom/qcells';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget/cookie-banner-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'qcells') {
    widget = CookieBannerWidgetQcells;
} else {
    widget = CookieBannerWidget;
}
export { widget as CookieBannerWidget };
