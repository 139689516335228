import Vue from 'vue';

export const store = Vue.observable({
    hashArr: [],
} as { hashArr: any[] });

export const mutations = {
    add(value): void {
        store.hashArr.push(value);
    },
    remove(value): void {
        const index = store.hashArr.indexOf(value);
        if (index > -1) {
            store.hashArr.splice(index, 1);
        }
    },
    exists(value): boolean {
        return store.hashArr.indexOf(value) > -1;
    },
};
