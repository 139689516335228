import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './tariff-change-widget.html';

import { Watch } from 'vue-property-decorator';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';

import SubmitTariff from '@/interfaces/SubmitTariff';
import Auth from '@/interfaces/Auth';
import Tariff from '@/interfaces/Tariff';
import Format from '@/interfaces/Format';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Commodity from '@/interfaces/Commodity';
import AddOn from '@/interfaces/AddOn';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const tariffProvider = IocContainer.get<Tariff>(SERVICES.TARIFF);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);
const addOnProvider = IocContainer.get<AddOn>(SERVICES.ADDON);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
    },
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class TariffChangeWidget extends Base {
    public list: any[] = [];
    public submitPending = false;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public showSuccessChangeTariff = false;
    public activeFrom = '';
    public product = '';
    public newDepositValue = 0;

    protected details: SubmitTariff = {
        contractId: this.$store.state.contracts.contractId,
        productCode: null,
    };

    protected elements = {
        qcells: ['show-success-change-tariff-popup'],
        wdenergy: ['show-success-change-tariff-popup'],
        'wdenergy-us': ['show-success-change-tariff-popup'],
    };

    get tariffChangeList(): Record<any, any> {
        if (this.$store.state.settings.tariffRecommendationWidgetCreated) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList;
        } else {
            return [
                ...this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).changeList,
                ...this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).recommended,
            ];
        }
    }

    get commodity(): any {
        return this.$t(
            commodityProvider.getCommodityType(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract,
                'tariff-change'
            )
        );
    }

    get commodityPrice(): any {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }

    @Watch('tariffChangeList')
    protected onTariffChangeListChange(newVal): void {
        this.prepareTariffList(newVal);
    }

    protected created(): void {
        this.details.contractId = this.$store.state.contracts.contractId;
        this.prepareTariffList(this.tariffChangeList);
    }

    protected isDefaultAddon(addon): boolean {
        return (
            this.$store.state.settings.tariffChangeDefaultAddon.indexOf(
                addon.productCode
            ) !== -1
        );
    }

    protected prepareTariffList(list): void {
        this.list = list.map((obj) => ({
            ...obj,
            confirmedError: false,
            confirmationCheckbox: false,
        }));
    }

    protected updateCheckbox(tariff): void {
        tariff.confirmationCheckbox = !tariff.confirmationCheckbox;
        tariff.confirmedError = false;
    }

    protected async submit(tariff): Promise<any> {
        if (!tariff.confirmationCheckbox && this.currentClient !== 'qcells') {
            tariff.confirmedError = true;
            return;
        }

        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            this.details.productCode = tariff.productCode;
            await tariffProvider.changeTariff(this.details).then(
                (response): void => {
                    this.submitPending = false;
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        if (
                            this.displayElement(
                                'show-success-change-tariff-popup'
                            )
                        ) {
                            this.showSuccessChangeTariff = true;
                            this.activeFrom = response.data.data.activeFrom;
                            this.product = response.data.data.product;
                            this.newDepositValue =
                                response.data.data.newDepositValue;
                        }
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }

    protected closeSuccessChangeTariffPopup(): void {
        this.showSuccessChangeTariff = false;
    }

    protected parseImage(image): string {
        return addOnProvider.parseImage(image);
    }
}
