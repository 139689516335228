<div
    class="m-portlet m-portlet--mobile position-relative"
    data-test="tariff-widget"
>
    <div
        class="m-portlet__body"
        :class="[displayElement('h-100-class') ? 'h-100' : '']"
    >
        <div
            class="d-block"
            :class="[displayElement('h-100-class') ? 'h-100' : '']"
            id="currentTariff-content"
        >
            <div class="currentTariff-title">
                <span
                    data-test="tariff-widget-product-title"
                    v-if="!productImage"
                    >{{ productTitle }}</span
                >
                <img
                    data-test="tariff-widget-product-img"
                    v-if="productImage"
                    :src="productImage"
                    style="margin-top: 0.4em"
                />
            </div>
            <div class="ms-1 me-1 mt-4 pt-4 border-top">
                <div>{{ $t('widget.tariff.text') }}</div>
                <div
                    class="pt-4"
                    v-if="savedCo2Usage > 0 && savedCo2Usage !== null"
                >
                    {{ $t('widget.tariff.already.have') }}
                    <strong>{{ savedCo2Usage || euroFull }} kg CO₂</strong>
                    {{ $t('widget.tariff.already.have.2') }}
                </div>
                <div class="pt-4" v-else>
                    {{ $t('widget.tariff.already.have.without') }}
                </div>

                <div class="mt-4 pt-4 border-top"></div>
            </div>
            <div
                :class="[
          'currentTariff-text',
          displayElement('non-margin-top-3-class') ? '' : 'mt-3',
          displayElement('bottom-15-class') ? 'bottom-15' : '',
          displayElement('position-absolute-class') && isNonCommodityContract ? 'position-absolute' : '',
        ]"
            >
                <span id="currentTariff-description"></span>
                <div class="d-flex">
                    <div class="button-links button-wrap">
                        <div>
                            <button
                                data-test="tariff-widget-detail-button-click"
                                @click="$emit('toggleWidget', 'tariffDetailsWidget')"
                                class="btn btn-link btn-sm btn-link-block btn-details-link"
                                id="button-tariffDetails-link"
                            >
                                <i class="arrow-right"></i>
                                {{ $t('widget.td.details') }}
                            </button>
                        </div>
                        <div>
                            <button
                                v-if="showTariffLinks && tariffOverviewAllowed"
                                class="btn btn-link btn-sm btn-link-block"
                                @click="$emit('toggleWidget4', 'showTariffOverview')"
                            >
                                <i class="arrow-right"></i>
                                {{ $t('widget.td.tariffOverview') }}
                            </button>
                        </div>
                        <div>
                            <button
                                data-test="change-widget-customer-data-click"
                                :disabled="changeCustomerDataLoading"
                                @click="$emit('toggleWidget2', 'changeCustomerDataWidget')"
                                :class="['btn btn-link btn-sm btn-link-block', groupWidget.changeCustomerDataWidget ? 'active' : '']"
                                id="button-changeCustomerData"
                            >
                                <i class="arrow-right"></i>
                                <span
                                    >{{ $t('widget.cw.customer'+prefix) }}</span
                                >
                            </button>
                        </div>
                        <div style="height: 36px"></div>
                    </div>
                    <div class="button-links button-wrap">
                        <div>
                            <button
                                data-test="change-widget-billing-data-click"
                                v-if="showChangeBillingData"
                                :disabled="changeBillingDataLoading"
                                @click="$emit('toggleWidget2', 'changeBillingDataWidget')"
                                :class="['btn btn-link btn-sm btn-link-block', groupWidget.changeBillingDataWidget ? 'active' : '']"
                                id="button-changeBillingData"
                            >
                                <i class="arrow-right"></i>
                                <span>{{ $t('widget.cw.postal'+prefix) }}</span>
                            </button>
                        </div>
                        <div>
                            <button
                                data-test="change-widget-payment-data-click"
                                v-if="showChangePaymentData"
                                :disabled="changePaymentDataLoading"
                                @click="$emit('toggleWidget2', 'changePaymentDataWidget')"
                                :class="['btn btn-link btn-sm btn-link-block', groupWidget.changePaymentDataWidget ? 'active' : '']"
                                id="button-changePaymentData"
                            >
                                <i class="arrow-right"></i>
                                <span
                                    >{{ $t('widget.cw.payment'+prefix) }}</span
                                >
                            </button>
                        </div>
                        <div>
                            <button
                                v-if="showTariffLinks && contractMoveAllowed"
                                class="btn btn-link btn-sm btn-link-block"
                                @click="$emit('toggleWidget4', 'showMoveWidget')"
                            >
                                <i class="arrow-right"></i>
                                {{ $t('widget.td.move') }}
                            </button>
                        </div>
                        <div style="height: 36px"></div>
                    </div>
                </div>
            </div>
            <div
                v-if="donationAllowed && isDonationPeriodActive"
                class="change-tariff__bottom"
                :class="donationApplied ? 'donation-applied' : ''"
            >
                <div class="change-tariff__header">
                    <span v-if="!donationApplied"
                        >{{ $t('widget.td.donation.until') }}:</span
                    >
                </div>
                <div>
                    <button
                        v-if="donationAllowed && !donationApplied"
                        class="button"
                        data-test="tariff-change-widget-button-click"
                        @click="$emit('toggleWidget4', 'showDonation')"
                    >
                        <span>{{ $t('widget.td.donate.now') }}</span>
                    </button>
                    <button v-else class="button">
                        <span>{{ $t('widget.td.donate.now') }}</span>
                    </button>
                </div>
            </div>
            <div
                v-if="tariffChangeAllowed && tariffChangeAvailable && displayElement('change-tariff-button-bottom')"
                class="change-tariff__bottom"
            >
                <div class="change-tariff__header">
                    <span
                        >{{ $t('widget.td.until') }} {{ $t('widget.td.change')
                        }}:</span
                    >
                    <!-- {{ $store.state.settings.tariffChangePossibleUntil }} -->
                </div>
                <div>
                    <button
                        class="button"
                        data-test="tariff-change-widget-button-click"
                        @click="$emit('toggleWidget4', 'showTariffChange')"
                    >
                        <span>{{ $t('widget.td.change.now') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
