<div class="m-grid m-grid--hor m-grid--root m-page">
    <app-header v-if="isMobile"></app-header>
    <header-v2 v-if="displayElement('header-v2')"></header-v2>
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-aside-left"
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        >
            <img src="/img/evus/prokon/logo_white.png" id="prokon-logo-menu" />
        </div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
        >
            <!--begin::Body-->
            <div class="m-login__body text-center">
                <!--begin::Signin-->
                <div>
                    <!-- Login Display -->
                    <div class="m-login__title mb-5">
                        <h1 data-test="login-title" v-if="!isRegister">
                            {{ $t('login.title') }}
                        </h1>
                        <h1 v-else>{{ $t('registration.title') }}</h1>
                        <h4
                            v-if="!isRegister && !displayElement('app-login-subtitle')"
                            v-html="$t('login.subtitle')"
                        ></h4>
                    </div>

                    <!-- LOGIN AREA-->
                    <login-page
                        v-if="!isRegister && !resetPassword"
                        :second="second"
                    ></login-page>

                    <!-- RESET PASSWORD AREA -->
                    <reset-password-widget
                        v-if="resetPassword"
                    ></reset-password-widget>

                    <!-- RESTORE TWO FACTOR AUTHENTICATION AREA -->
                    <restore-two-factor-authentication
                        v-if="isRestoreTwoFactorAuthentication"
                    ></restore-two-factor-authentication>

                    <hr
                        v-if="!isRegister"
                        class="mt-5"
                        style="border-bottom: 1px solid"
                    />

                    <div v-if="!isRegister">
                        <h3 class="text-body mt-4 mb-3 font-weight-bold">
                            {{ $t('login.accountQuestion') }}
                        </h3>
                        <a
                            href="https://mein.prokon.net/registrieren"
                            class="btn btn-primary"
                            style="width: 100% !important"
                            data-test="auth-page-button-registration"
                            >{{ $t('login.registerOffer') }}
                        </a>
                        <div
                            class="m-login__notice mt-4"
                            v-html="$t('login.notice')"
                        ></div>
                    </div>

                    <!-- REGISTER AREA-->
                    <register-page v-if="isRegister"></register-page>

                    <div class="m-login__note" v-if="showLogoutNote">
                        <div class="m-login__note-body">
                            <span>{{ $t('widget.lp.note.heading') }}</span>
                            <div>{{ $t('widget.lp.note.text')}}</div>
                        </div>
                    </div>
                </div>
                <!--end::Signin-->
            </div>
            <div v-if="locales.length > 1">
                <span
                    v-for="(locale, index) in locales"
                    @click="changeLocale(locale)"
                    >{{ $t('locale.'+locale) }}<span
                        v-if="index !== locales.length - 1"
                    >
                        -
                    </span></span
                >
            </div>
            <div class="app-footer-right">
                <div class="col-12 text-end">
                    <ul class="m-footer__nav m-nav m-nav--inline">
                        <li class="m-nav__item" v-if="contactLink">
                            <a
                                :href="contactLink"
                                class="m-nav__link"
                                :target="currentClient !== 'prokon' ? '_blank' : '_self'"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.contact') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item">
                            <a
                                :href="privacyLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.privacy') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item" v-if="agbLink">
                            <a
                                :href="agbLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.agb') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item">
                            <a
                                :href="imprintLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.imprint') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item" v-if="legalNoticeLink">
                            <a
                                :href="legalNoticeLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.legal.notice') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item" v-if="tosLink">
                            <a
                                :href="tosLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.tos') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item" v-if="formularsLink">
                            <a
                                :href="formularsLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.formulars') }}
                                </p>
                            </a>
                        </li>
                        <li class="m-nav__item" v-if="energyPricingLink">
                            <a
                                :href="energyPricingLink"
                                class="m-nav__link"
                                target="_blank"
                            >
                                <p class="m-nav__link-text">
                                    {{ $t('footer.energyPricing') }}
                                </p>
                            </a>
                        </li>
                    </ul>
                </div>
                <app-footer
                    v-if="displayElement('app-footer-right')"
                ></app-footer>
            </div>

            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <app-footer v-if="displayElement('app-footer')"></app-footer>
    <!-- end::Footer -->
    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
</div>
