import Base from '@/mixins/base';
import Component from 'vue-class-component';
import WithRender from './forgot-username-widget.html';
import { MessagesBlock } from '@/components/snippets/messages';
import IocContainer from '@/container/IocContainer';
import WaermeenergieAuth from '@/interfaces/WaermeenergieAuth';
import SERVICES from '@/container/Services';

@WithRender
@Component({
    components: {
        'messages-block': MessagesBlock,
    },
})
export class ForgotUsernameWidget extends Base {
    private auth = IocContainer.get<WaermeenergieAuth>(
        SERVICES.AUTH_WAERMEENERGIE
    );
    private email: string | null = null;
    private successInfo: { key?: string; message?: string }[] = [];
    private failInfo: { key?: string; message?: string }[] = [];

    private submitEmail() {
        this.successInfo = [];
        this.failInfo = [];
        if (!this.email) {
            this.failInfo = [{ key: 'forgot.username.email.required' }];
            return;
        }
        this.auth
            .forgotUsername({ email: this.email })
            .then((response) => {
                if (this.responseSuccess(response)) {
                    this.successInfo = [
                        { message: response.data.messageLocalized },
                    ];
                    // PROCEED
                } else {
                    console.error(response);
                }
            })
            .catch(() => {
                // PROCEED
            });
    }

    private responseSuccess(response) {
        if (response.status === 422) {
            this.failInfo = Object.values(response.data.errors).map(
                (errorArray: string[] | any) => ({
                    message: errorArray.join(' '),
                })
            );
            return false;
        }
        if (!response.data.success) {
            this.failInfo = [JSON.parse(response.data.error)];
            return false;
        }
        if (response.status > 299) {
            this.failInfo = [{ message: response.data.message }];
            return false;
        }
        return response.data.success;
    }
}
