import { ChangeBillingDataWidget } from '../change-billing-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli-thg-dealer.html';

@WithRender
@Component({})
export class ChangeBillingDataWidgetElliThgDealer extends ChangeBillingDataWidget {
    public showTooltip1 = false;
    public showTooltip2 = false;
    public taxIdFailed = false;

    get branding() {
        const productCode = this.currentNoCommodityProduct.productCode;
        if (productCode) {
            if (productCode.includes('_ELLI_')) {
                return this.$t('non.commodity.brand.elli');
            }
            if (productCode.includes('_VW_')) {
                return this.$t('non.commodity.brand.vw');
            }
            if (productCode.includes('_VWFS_')) {
                return this.$t('non.commodity.brand.vwfs');
            }
            if (productCode.includes('_Skoda_')) {
                return this.$t('non.commodity.brand.skoda');
            }
            if (productCode.includes('_Cupra_')) {
                return this.$t('non.commodity.brand.cupra');
            }
            if (productCode.includes('_Audi_')) {
                return this.$t('non.commodity.brand.audi');
            }
        }

        return '';
    }
}
