<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-2">
                <i class="fa fa-calendar-alt nextReading__icon"></i>
            </div>
            <div class="col-10">
                <div id="nextReading-content">
                    <span class="actionMiddleWidget">
                        <i
                            @click="$emit('toggleWidget', 'meterSubmitWidget')"
                            class="fa fa-ellipsis-h"
                            id="button-submitMeterRead"
                            v-if="detailsLoaded"
                        ></i>
                    </span>
                    <div class="nextReading__title static-title">
                        {{ $t('widget.m.text') }}
                    </div>
                    <div class="nextReading__text">
                        {{ $t('widget.td.counterNumber') }}:
                        <content-loader
                            v-if="!counterNumber"
                            :width="80"
                            :height="20"
                            style="
                                width: 80px;
                                height: 12px;
                                margin-left: -12px;
                            "
                            data-test="CONTENT-LOADER"
                        ></content-loader>
                        <template v-else> {{ counterNumber }} </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
