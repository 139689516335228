<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div @click="$emit('closeWidget')" class="fullwidthArea-close">
            <i class="fa fa-times"></i>
        </div>
        <div id="customer-service-form-content">
            <div class="box-title static-title">
                {{ $t('widget.cs.title') }}
            </div>
        </div>
        <div class="mobile-modal__wrap">
            <div>{{ $t('widget.cs.sender') }}</div>
            <div class="row">
                <div class="col-12 col-sm-3 form-col">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            v-model="details.firstname"
                            :placeholder="$t('widget.cs.firstName')"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-3 form-col">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            v-model="details.lastname"
                            :placeholder="$t('widget.cs.lastName')"
                        />
                    </div>
                </div>
                <!--
        <div class="col-12 col-sm-3 form-col">
          <div class="form-group">
            <input type="email" class="form-control" v-model="details.email" :placeholder="$t('widget.cs.email')" required>
          </div>
        </div>
        -->
                <div class="col-12 col-sm-3 form-col">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            v-model="details.customerId"
                            :placeholder="$t('widget.cs.customerNumber')"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-3 form-col">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            v-model="details.contractId"
                            :placeholder="$t('widget.cs.contractNumber')"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4">
                    <div class="form-group">
                        <label for="choose-topic">
                            {{ $t('widget.cs.topic.choose') }}
                        </label>
                        <treeselect
                            v-model="details.categoryId"
                            id="choose-topic"
                            :options="categories"
                            :disableBranchNodes="true"
                            :maxHeight="200"
                            :placeholder="$t('widget.cs.topic.choose.placeholder')"
                            :noOptionsText="$t('widget.cs.topic.choose.no.options')"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-8">
                    <div class="form-group">
                        <label for="message">
                            {{ $t('widget.cs.message') }}
                        </label>
                        <textarea
                            id="message"
                            type="text"
                            class="form-control"
                            rows="8"
                            v-model="details.text"
                        ></textarea>
                    </div>
                </div>
                <div class="col-12 col-sm-4">
                    <div class="form-group">
                        <label for="files-list">
                            {{ $t('widget.cs.files') }}
                        </label>
                        <input
                            id="files-list"
                            type="file"
                            class="form-control"
                            accept="text/csv,text/plain,application/pdf,application/vnd.oasis.opendocument.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,image/gif,image/jpeg,image/png"
                            multiple
                            ref="fileList"
                            @change="processFile($event)"
                        />
                        <button
                            class="btn btn-primary d-block ms-auto"
                            @click="submit(true)"
                            :disabled="submitPending"
                        >
                            {{ $t('widget.cs.submit.message') }}
                        </button>
                    </div>
                </div>
            </div>

            <messages-block
                :success="successInformation"
                :errors="errorInformation"
            ></messages-block>
        </div>
    </div>
</div>
