import iocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Files from '@/interfaces/Files';
import Vapor from 'laravel-vapor';

class FileService {
    protected ApiProvider = iocContainer.get<Files>(SERVICES.FILES);

    public key: string;
    public file: File;
    public size: number;
    public uploadProgress: number = 0;

    constructor(key: string, file: File) {
        this.key = key;
        this.file = file;
        this.size = file.size;
    }

    public async upload(): Promise<any> {
        if (this.size > 20971520) {
            throw new Error('FileTooBig');
        }
        return new Promise((resolve, reject) => {
            Vapor.store(this.file, {
                signedStorageUrl:
                    process.env.VUE_APP_API_URL +
                    '/v1/vapor/signed-storage-url',
                headers: {
                    Authorization: process.env.VUE_APP_API_AUTH,
                },
                visibility: 'public-read',
                progress: (progress) => {
                    console.log(progress);

                    this.uploadProgress = Math.round(progress * 100);
                },
            })
                .then((response) => {
                    resolve({
                        uuid: response.uuid,
                        s3key: response.key,
                        bucket: response.bucket,
                        name: this.file.name,
                        type: this.file.type,
                        key: this.key,
                    });
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                    console.log(error.response);
                });
        });
    }

    public async uploadDirectly(): Promise<any> {
        if (this.size > 4500000) {
            throw new Error('FileTooBig');
        }
        const formData = new FormData();
        formData.append('key', this.key);
        formData.append('file', this.file);
        return this.ApiProvider.updateGenericData(formData);
    }

    public async getPreview(): Promise<any> {
        return this.parseFile(this.file);
    }

    public async parseFile(file: File): Promise<any> {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = (e) => {
                if (e.target) resolve(e.target.result);
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }
}

export default FileService;
