import { default as Component } from 'vue-class-component';
// Call Render template.
import WithRender from './elli-fleet.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
// Widgets
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget';
import { CancellationWidget } from '@/components/widgets/cancellation-widget';
import { EcarUploadWidget } from '@/components/widgets/ecar-upload-widget';
import { TariffChangeWidget } from '@/components/widgets/tariff-change-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { MarketingMaterial } from '@/components/widgets/marketing-material-widget/marketing-material';
import { ThgBonusOverview } from '@/components/widgets/thg-bonus-overview';
import { ThgBonusSelector } from '@/components/widgets/thg-bonus-selector';
import { ThgStatusWidget } from '@/components/widgets/thg-status-widget';
import { ThgCarDataWidget } from '@/components/widgets/thg-car-data-widget';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import { PostBoxCombinedWidget } from '@/components/widgets/post-box-combined-widget';
import ThgServiceElliDefault from '@/models/thg/ThgServiceElliDefault';
import { ThgFleetManagerBonusWidget } from '@/components/widgets/thg-fleet-manager-bonus-widget';
import { FleetManagerQuotasWidget } from '@/components/widgets/fleet-manager-quotas-widget';
import moment from 'moment';

@WithRender
@Component({
    components: {
        // Widgets
        ChangeCustomerDataWidget,
        ChangeBillingDataWidget,
        CancellationWidget,
        EcarUploadWidget,
        TariffChangeWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
        MarketingMaterial,
        ThgBonusOverview,
        ThgBonusSelector,
        ThgStatusWidget,
        ThgCarDataWidget,
        PostBoxCombinedWidget,
        ThgFleetManagerBonusWidget,
        FleetManagerQuotasWidget,
    },
})
export class Dashboard extends DashboardParent {
    protected showBonusSelector: boolean = false;
    protected showVehicleDataEditor: boolean = false;
    protected bonusSelectorYear: string = moment().year() + '';

    public toggleSidebarState = true;

    protected get isProductCPO(): boolean {
        if (
            this.currentTariff.nonCommodityProduct &&
            this.currentTariff.nonCommodityProduct.productCode
        ) {
            return this.currentTariff.nonCommodityProduct.productCode.includes(
                'THG_CPO'
            );
        }

        return false;
    }

    protected hasBonusForYear(year: string): boolean {
        return this.thgService.getBonusYears().indexOf(year) >= 0;
    }

    protected get defaultYear(): string {
        return moment().format('YYYY');
    }

    public get thgService(): ThgBaseService {
        return ThgServiceElliDefault.instance.setStore(this.$store);
    }
}
