<div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="email">
                <strong>{{ $t('registration.email') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.email ? 'is-invalid' : '']"
                type="email"
                v-model="email"
                id="email"
                name="email"
                autocomplete="off"
                value=""
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="email"
                :placeholder="$t('registration.email')"
                v-model="email"
                name="email"
                autocomplete="off"
                value=""
            />
        </div>

        <div
            v-if="displayElement('input-with-label')"
            class="form-group m-form__group"
        >
            <label for="username">
                <strong>{{ $t('username.reset.new.username') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors.username ? 'is-invalid' : '']"
                type="text"
                v-model="username"
                id="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>
        <div v-else class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('username.reset.new.username')"
                v-model="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>

        <!--begin::Action-->
        <div class="m-login__action">
            <a @click="$router.push({name: 'login'})" class="m-link">
                <span>{{ $t('login.already.have') }}</span>
            </a>
            <button
                @click="setUsername()"
                class="
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom m-btn--air
                    m-login__btn m-login__btn--primary
                "
            >
                {{ $t('username.reset') }}
            </button>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>
