import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import moment from 'moment';
import VehicleType from '../VehicleType';
import Format from '@/interfaces/Format';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import _ from 'lodash';
import NonCommodityProductFacade from '../NonCommodityProductFacade';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

export default class ThgServiceElliDefault extends ThgBaseService {
    public static FIELD_VALUE_NONE = 'NONE';

    private isExtendedDataInitialized: boolean = false;
    private static singleton: ThgServiceElliDefault;

    public static vehicleTypes: VehicleType[] = [
        {
            identifier: 'l1e',
            title: 'noncommodity.product.vehicle.type.l1e.label',
            class: 'noncommodity.product.vehicle.type.l1e.class',
        },
        {
            identifier: 'm1',
            title: 'noncommodity.product.vehicle.type.m1.label',
            class: 'noncommodity.product.vehicle.type.m1.class',
            isHighlighted: true,
        },
        {
            identifier: 'n1',
            title: 'noncommodity.product.vehicle.type.n1.label',
            class: 'noncommodity.product.vehicle.type.n1.class',
        },
    ];

    protected getExtendedDataFieldsMultiDefaultValues(): Record<any, any> {
        return {
            [this.extendedDataKeyThgPrices]: 0.0,
        };
    }

    public getExtendedDataFieldsMulti(): Record<any, any> {
        return {
            [this.extendedDataParentKey]: {
                [this.extendedDataKeyLicensePlates]: [],
            },
        };
    }

    public getExtendedDataFields(): Record<any, any> {
        const singleFields: Record<any, any> = {
            [this.extendedDataParentKey]: {
                [this.extendedDataKeyVatId]: '',
            },
        };

        return _.merge(singleFields, this.getExtendedDataFieldsMulti());
    }

    public static get instance(): ThgServiceElliDefault {
        if (ThgServiceElliDefault.singleton == null) {
            ThgServiceElliDefault.singleton = new ThgServiceElliDefault();
        }
        return ThgServiceElliDefault.singleton;
    }

    public static get extendedDataFields(): Record<any, any> {
        return this.instance.getExtendedDataFields();
    }
}
