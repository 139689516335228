import { default as Component } from 'vue-class-component';

// Call Render template.
import WithRender from './sachsenenergie.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';

// templates
import { NonCommodityTariffDetailsWidget } from '@/components/widgets/non-commodity-tariff-details-widget';

// Widgets
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { CustomerServiceFormWidget } from '@/components/widgets/customer-service-form-widget';
import { WelcomeWidget } from '@/components/widgets/welcome-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({
    components: {
        // Widgets
        ChangeCustomerDataWidget,
        CustomerServiceFormWidget,
        NonCommodityTariffDetailsWidget,
        WelcomeWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})
export class Dashboard extends DashboardParent {
    @Watch('$store.state.contracts.contractId')
    protected onContractIdChange(newVal): void {
        if (newVal) {
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
        }
    }
}
