<div class="col-12 mobile-modal__inner" data-test="meter-submit-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="closeSelf" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div id="meterReadSubmit-content" class="mobile-modal__wrap">
                <div class="row">
                    <div class="col-11">
                        <i
                            class="fa fa-calendar-alt readingHistory-icon pt-1"
                            v-if="displayElement('calendar-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.ms.title') }}</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col" v-if="isMoveInToday">
                        {{ $t('widget.ms.expectingReadingToday', {counterNumber:
                        counter.counterNumber})}}.
                    </div>
                    <div
                        class="col"
                        v-else-if="nextReadingStateString === readingStateType.MOVE_IN_MISSING"
                    >
                        {{ $t('widget.ms.yourNextMeterReadForCounterMoveIn')}}
                        {{ counter.counterNumber }} {{
                        $t('widget.ms.expectedAtMoveIn') }}
                        <span
                            :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                            >{{ nextReadingAt | date }}</span
                        >
                        {{ $t('widget.ms.yourMeterReadingMoveIn') }}.
                    </div>
                    <div
                        class="col"
                        v-else-if="nextReadingStateString === readingStateType.MOVE_OUT_MISSING"
                    >
                        {{ $t('widget.ms.yourNextMeterReadForCounterMoveOut')}}
                        {{ counter.counterNumber }} {{
                        $t('widget.ms.expectedAtMoveOut') }}
                        <span
                            :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                            >{{ nextReadingAt | date }}</span
                        >
                        {{ $t('widget.ms.yourMeterReadingMoveOut') }}.
                    </div>
                    <div class="col" v-else>
                        {{ $t('widget.ms.yourNextMeterReadForCounter')}} {{
                        counter.counterNumber }} {{ $t('widget.ms.expectedAt')
                        }}
                        <span
                            :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                            >{{ nextReadingAt | date }}</span
                        >.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-xs-12 col-sm-12">
                        <div
                            :class="[multiRegister ? 'mt-3' : '']"
                            v-for="register in getRegistersDisplay()"
                        >
                            <span v-if="multiRegister">
                                {{ $t('widget.ms.counter') }}:
                                <b> {{ getRegisterTitle(register) }} </b>
                                <br /><br />
                            </span>
                            <div
                                class="row submitMeterReadHistory"
                                data-test="meter-submit-widget-read-history"
                            >
                                <div
                                    class="col-6 col-sm-3"
                                    v-for="reading in getReadingsFromRegister(register)"
                                    data-test="meter-submit-widget-readings"
                                >
                                    <div class="row" v-if="reading">
                                        <div class="col-8 NoPaddingCenterCol">
                                            <i
                                                class="submitMeterReadIcon fa fa-calendar-check"
                                            ></i>
                                            <div
                                                class="submitMeterReadDesc"
                                                data-test="meter-submit-widget-read-history-single"
                                            >
                                                <div>
                                                    {{ (reading.qualifier) ?
                                                    $t('widget.ms.qualifier.'+reading.qualifier)
                                                    : '' }} {{ (reading.origin)
                                                    ?
                                                    $t('widget.ms.origin.'+reading.origin)
                                                    : '' }}
                                                </div>
                                                <div>
                                                    {{ reading.readAt | date }}
                                                </div>
                                                <div>
                                                    {{
                                                    formatReadingValue(reading,
                                                    register.register) }}
                                                    {{commodity}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <hr
                                                class="submitMeterReadHr submitMeterReadHrBold"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-4 col-sm-2 NoPaddingCenterCol"
                                    v-if="!displayElement('no-div-next-reading')"
                                >
                                    <i
                                        class="submitMeterReadIcon fa fa-calendar-day"
                                    ></i>
                                    <div class="submitMeterReadDesc">
                                        <div>&nbsp;</div>
                                        <div>{{ nextReadingAt | date }}</div>
                                        <div
                                            v-if="displayElement('no-placeholder-next-reading')"
                                        ></div>
                                        <div v-else>?????? {{commodity}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        <messages-block
                            fromTestElement="meter-submit-widget-msg"
                            :success="successInformation"
                            :errors="errorInformation"
                        ></messages-block>

                        <div v-if="showReasonInput">
                            <span
                                :class="[displayElement('bold-date-class') ?
                            'font-weight-bold' : '']"
                                v-html="hasErrorCode('exists_previous_reading_with_higher_value')
                            ? $t('widget.ms.reason.tooLow') :
                            $t('widget.ms.reason')"
                            />
                            <v-select
                                v-if="allowImplausibleMeterReads && implausibleReasonExplanationList.length > 0"
                                @input="implausibleReasonExplanationSelected"
                                :options="implausibleReasonExplanationList"
                                :class="[reasonExplanationError ? 'is-invalid' : '']"
                                v-model="implausibleReasonExplanation"
                            >
                            </v-select>
                            <input
                                v-if="showImplausibleReasonExplanationOther"
                                v-model="reason"
                                type="text"
                                :class="[reasonExplanationError ? 'is-invalid' : '', 'form-control']"
                            />
                        </div>
                    </div>
                    <div class="col-md-4 col-xs-12 col-sm-12">
                        <div class="fullWithArea-submit">
                            <div class="row">
                                <div
                                    v-for="reading in getDetailsReadingsAtSelectedDate()"
                                    :class="['col-12 fullWidthArea-input', multiRegister ? 'mt-4' : '']"
                                >
                                    <div v-if="multiRegister"><br /><br /></div>
                                    <h5>
                                        {{ $t('widget.ms.submit.reading.title')
                                        }}:
                                    </h5>
                                    <vue-numeric
                                        v-if="displayElement('vue-numeric') && reading.value !== null"
                                        class="form-control readingInput-obis"
                                        :empty-value="0"
                                        :min="0"
                                        :placeholder="$t('widget.ms.title')"
                                        :precision="registerPrecision(reading)"
                                        separator="."
                                        v-model="reading.value"
                                        data-test="meter-submit-widget-input-number"
                                    ></vue-numeric>
                                    <input
                                        data-test="meter-submit-widget-input-number"
                                        v-else
                                        type="number"
                                        min="0"
                                        step="1"
                                        :placeholder="$t('widget.ms.title')"
                                        v-model="reading.value"
                                        class="form-control readingInput-obis"
                                    />
                                </div>
                                <div
                                    class="col-12 fullWidthArea-input"
                                    v-if="!displayElement('date-input')"
                                >
                                    <h5>
                                        {{ $t('meter.reading.form.readAt') }}:
                                    </h5>
                                    <datetime
                                        v-model="details.readAt"
                                        type="date"
                                        input-class="form-control"
                                        value-zone="local"
                                        format="dd.LL.yyyy"
                                        :min-datetime="minDate"
                                        :max-datetime="maxDate"
                                        :phrases="datetimePhrases"
                                        @close="setEditActive(false)"
                                    ></datetime>
                                </div>
                                <div
                                    class="col-12 fullWithArea-submitButtonHint-inbetweenInvoice"
                                    v-if="isActivateInvoiceTriggering"
                                >
                                    <div
                                        class="form-group form-check"
                                        :class="[displayElement('align-checkbox') ? 'd-flex align-items-center' : '']"
                                    >
                                        <label
                                            :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                        >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                :class="[displayElement('align-checkbox') ? 'mt-6px' : '']"
                                                id="meterReadSubmit_forceInvoice"
                                                v-model="details.forceInvoice"
                                                :disabled="forceInvoiceDisabled"
                                            />
                                            {{
                                            $t('widget.ms.createInterimStatement')
                                            }}
                                        </label>
                                        <i></i>
                                        <span></span>
                                        <i
                                            class="fas fa-info-circle"
                                            v-if="forceInvoiceDisabled"
                                            :class="[displayElement('align-checkbox') ? 'mt-2px' : '']"
                                            v-tooltip.bottom="$tc('widget.ms.forceInvoice.notAllowed', daysUntilNextInbetweenInvoice, {days: daysUntilNextInbetweenInvoice})"
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    class="col-12 text-end fullWithArea-submitButton"
                                    v-if="!editActive || displayElement('date-input')"
                                >
                                    <button
                                        data-test="meter-submit-widget-submit-button"
                                        :class="[
                                            'btn btn-primary btn--tt0',
                                            displayElement('default-btn') ? '' : 'btn-sm',
                                            displayElement('float-right-class') ? 'float-end' : '',
                                        ]"
                                        :disabled="this.submitPending"
                                        @click="submit"
                                    >
                                        {{ $t('widget.ms.transfer') }}
                                    </button>
                                </div>
                            </div>
                            <div
                                v-if="showInvoiceSimulation"
                                class="invoice-simulation"
                            >
                                <div class="invoice-simulation-text">
                                    {{ $t('widget.ms.simulate.invoice.text') }}
                                    <a
                                        v-on:click="toggleInvoiceSimulationForm"
                                        href="#"
                                    >
                                        {{
                                        $t('widget.ms.simulate.invoice.text.link')
                                        }}</a
                                    >
                                </div>
                                <div
                                    v-if="showInvoiceSimulationForm"
                                    class="invoice-simulation-form"
                                >
                                    <v-select
                                        :options="simulateInvoiceDateUntilOptions"
                                        v-model="simulateInvoiceDateUntil"
                                    >
                                    </v-select>

                                    <button
                                        type="button"
                                        :disabled="this.invoiceSimulationPending"
                                        :class="[
                                            'btn btn-primary btn--tt0',
                                            displayElement('default-btn') ? '' : 'btn-sm',
                                            displayElement('float-right-class') ? 'float-end' : '',
                                        ]"
                                        @click="simulateInvoice"
                                    >
                                        {{
                                        $t('widget.ms.simulate.invoice.button')
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6"></div>
                    <div class="col-xs-12 col-sm-6">
                        <div v-if="showSurvey" class="survey-widget-block">
                            <survey-widget
                                :widget="'meter-submit-widget'"
                            ></survey-widget>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
