<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2 v-if="displayElement('header-v2')"></header-v2>
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside"
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        ></div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>
                    <!-- Login Display -->
                    <div class="m-login__title">
                        <h1>{{ $t('login.title') }}</h1>
                        <h6 v-html="$t('login.subtitle')"></h6>
                    </div>
                    <!-- LOGIN AREA-->
                    <customer-service-form-widget-unauthenticated></customer-service-form-widget-unauthenticated>
                    <br /><br />
                    <a href="/" class="m-link"> Zurück </a>

                    <div
                        class="m-login-footer-links"
                        v-if="displayElement('login-footer-links-bsw')"
                    >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/kontakt/"
                            target="_blank"
                            >{{ $t('login.footer.contact') }}</a
                        >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/kundenportal/datenschutzinformation/"
                            target="_blank"
                            >{{ $t('login.footer.data.protection') }}</a
                        >
                        <a
                            class="m-link"
                            href="https://berlinerstadtwerke.de/impressum/"
                            target="_blank"
                            >{{ $t('login.footer.impressum') }}</a
                        >
                    </div>
                </div>
                <!--end::Signin-->
            </div>
            <div v-if="locales.length > 1">
                <span
                    v-for="(locale, index) in locales"
                    @click="changeLocale(locale);"
                    >{{ $t('locale.'+locale) }}<span
                        v-if="index !== locales.length - 1"
                    >
                        -
                    </span></span
                >
            </div>
            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <app-footer v-if="displayElement('app-footer')"></app-footer>
    <!-- end::Footer -->
</div>
