import { ChangePaymentDataWidget } from '../change-payment-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './elli-thg.html';

@WithRender
@Component({})
export class ChangePaymentDataWidgetElliThg extends ChangePaymentDataWidget {
    public showTooltip = false;

    get cantSubmit(): boolean {
        return (
            this.contractStatus.statusCode >= 8000 ||
            this.submitPending ||
            !this.dataChanged ||
            !this.details.iban ||
            this.loadingBicPending ||
            this.loadingBankPending
        );
    }
}
