<div class="otp-login-info-overlay-box" v-if="!isHidden && isInTimeframe">
    <div class="fixed-middle">
        <div class="container pt-3">
            <h1>{{ $t('login.otp.popup.title') }}</h1>

            <div v-html="$t('login.otp.popup.content')"></div>

            <button
                class="
                    vue-btn
                    btn btn-primary
                    m-btn m-btn--pill m-btn--custom m-btn--air
                    m-login__btn m-login__btn--primary
                "
                @click="hidePopup"
            >
                {{ $t('login.otp.popup.label.button') }}
            </button>
        </div>
    </div>
</div>
