import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './change-payment-data-widget.html';

import { Watch } from 'vue-property-decorator';
import moment from 'moment';

import * as Sentry from '@sentry/browser';
import { Datetime } from 'vue-datetime';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Auth from '@/interfaces/Auth';
import Payment from '@/interfaces/Payment';
import SubmitPayment, { IPaymentDetails } from '@/interfaces/SubmitPayment';
import Tracking from '@/interfaces/Tracking';
import Format from '@/interfaces/Format';
import { FormComponents } from '@/components/snippets/form-components';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const paymentProvider = IocContainer.get<Payment>(SERVICES.PAYMENT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

enum ActiveWidgetView {
    IN = 'in',
    OUT = 'out',
    INOUT = 'inout',
}

@WithRender
@Component({
    components: {
        datetime: Datetime,
        'messages-block': MessagesBlock,
        'custom-input': FormComponents.CustomInput,
        'custom-label': FormComponents.CustomLabel,
        'form-group': FormComponents.FormGroup,
        'form-wrapper': FormComponents.FormWrapper,
        'custom-datetime': FormComponents.CustomDateTime,
        'custom-radio': FormComponents.CustomRadio,
        'custom-checkbox': FormComponents.CustomCheckbox,
    },
    filters: {
        date: formatProvider.date(),
    },
})
export class ChangePaymentDataWidget extends Base {
    public submitPending = false;
    public loadingBankPending = false;
    public loadingBicPending = false;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public activeView: ActiveWidgetView = ActiveWidgetView.IN;
    public activeFromChangeBankingDetailsInOutActive: boolean = false;
    public timer: any = null;

    protected details: SubmitPayment = {
        contractId: this.$store.state.contracts.contractId,
        directDebit: false,
        iban: null,
        bic: null,
        bank: null,
        alternativeAccountHolder: null,
        activeFrom: null,
        direction: this.activeView,
    };

    public radioOptions(): {
        value: string | boolean | number;
        label: string;
        labelAsHtml: boolean;
    }[] {
        return [
            {
                value: true,
                label: `${this.$t('widget.cpd.sepa').toLocaleString()}${
                    this.displayElement('sepa*') ? '*' : ''
                }`,
                labelAsHtml: this.currentClient === 'hsg',
            },
            {
                value: false,
                label: `${this.$t('widget.cpd.bonus').toLocaleString()}
                    <br>
                    ${this.$t('widget.cpd.bonus.info').toLocaleString()}`,
                labelAsHtml: true,
            },
        ];
    }

    public checkboxOptions(): {
        value: boolean;
        label: string;
        labelAsHtml?: boolean;
    }[] {
        return [
            {
                value: true,
                label: `${this.$t('widget.cpd.sepa').toLocaleString()}${
                    this.displayElement('sepa*') ? '*' : ''
                }`,
                labelAsHtml: this.currentClient === 'hsg',
            },
        ];
    }

    protected elements = {
        de: {
            bsw: ['edit-icon', 'bank-account', 'bank-account-extend'],
            sachsenenergie: [
                'branded-checkbox',
                'branded-radio',
                'no-sepa-mandat-info',
                'show-personal-info-link',
                'show-radio-buttons',
            ],
            wdenergy: ['branded-checkbox', 'branded-radio'],
            'wdenergy-us': ['branded-checkbox', 'branded-radio'],
            elli: ['branded-checkbox', 'branded-radio'],
            'dsp-*': ['no-sepa-mandat-info'],
            waermeenergie: ['use-future-payment-details'],
        },
    };

    protected minIBANLength = 15;

    protected created(): void {
        this.activeFromChangeBankingDetailsInOutActive =
            this.$store.state.settings.activeFromChangeBankingDetailsInOutActive;
        this.activeView = this.activeFromChangeBankingDetailsInOutActive
            ? ActiveWidgetView.IN
            : ActiveWidgetView.INOUT;
        this.loadPaymentDetails();
    }

    private loadPaymentDetails(): void {
        this.details.contractId = this.$store.state.contracts.contractId;
        if (
            this.paymentDetails !== null &&
            typeof this.paymentDetails !== 'undefined'
        ) {
            this.details.directDebit = this.paymentDetails.iban
                ? this.paymentDetails.directDebit
                : null;
            this.details.iban = this.paymentDetails.iban;
            this.details.bic = this.paymentDetails.bic;
            this.details.bank = this.paymentDetails.bank;
            this.details.direction = this.activeView;
            this.details.alternativeAccountHolder =
                this.paymentDetails.accountHolder;
            if (this.allowActiveFrom) {
                if (this.paymentDetails.activeFrom) {
                    this.details.activeFrom =
                        this.paymentDetails.activeFrom > this.minDate
                            ? this.paymentDetails.activeFrom
                            : this.minDate;
                } else {
                    this.details.activeFrom = this.minDate;
                }
            }
        }
    }

    protected mounted(): void {
        trackingProvider.showMasterDataChangePaymentDate();
    }

    protected destroyed(): void {
        trackingProvider.showMasterDataChangePaymentDate();
    }

    @Watch('paymentDetails.iban')
    protected paymentDetailsChanged(): void {
        this.details.contractId = this.$store.state.contracts.contractId;
        this.details.directDebit = this.paymentDetails.iban
            ? !!this.paymentDetails.directDebit
            : null;
        this.details.iban = this.paymentDetails.iban;
        this.details.bic = this.paymentDetails.bic;
        this.details.bank = this.paymentDetails.bank;
        this.details.alternativeAccountHolder =
            this.paymentDetailsAccountHolder;
        if (this.allowActiveFrom) {
            this.details.activeFrom = this.paymentDetails.activeFrom
                ? this.paymentDetails.activeFrom
                : this.minDate;
        }
    }

    public switchView(view: ActiveWidgetView): void {
        this.activeView = view;
        this.loadPaymentDetails();
    }

    get datetimePhrases(): Record<any, any> {
        return {
            ok: this.$t('datetime.ok'),
            cancel: this.$t('datetime.cancel'),
        };
    }

    get contractId(): number {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.id;
    }

    get customerId(): number {
        return this.$store.state.customer.id;
    }

    get paymentDetails() {
        if (this.displayElement('use-future-payment-details')) {
            if (this.futureBankingDetails.length > 0) {
                return this.futureBankingDetails[
                    this.futureBankingDetails.length - 1
                ];
            }
        }
        const paymentDetailsFromStore = this.$store.getters[
            'tariff/getActiveBankDetails'
        ](this.contractId);
        const paymentDetails = {
            contractId: this.$store.state.contracts.contractId,
            directDebit: false,
            iban: null,
            bic: null,
            bank: null,
            alternativeAccountHolder: null,
            activeFrom: null,
            direction: this.activeView,
        };
        if (this.activeFromChangeBankingDetailsInOutActive) {
            if (paymentDetailsFromStore[this.activeView].length > 0) {
                return paymentDetailsFromStore[this.activeView][
                    paymentDetailsFromStore[this.activeView].length - 1
                ];
            }
        } else {
            if (!(paymentDetailsFromStore.inout.length > 0)) {
                return paymentDetailsFromStore.in.length > 0
                    ? paymentDetailsFromStore.in[
                          paymentDetailsFromStore.in.length - 1
                      ]
                    : paymentDetailsFromStore.out.length > 0
                    ? paymentDetailsFromStore.out[
                          paymentDetailsFromStore.out.length - 1
                      ]
                    : paymentDetails;
            } else {
                return paymentDetailsFromStore.inout[
                    paymentDetailsFromStore.inout.length - 1
                ];
            }
        }
        return paymentDetails;
    }

    get futureBankingDetails() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.futureBankingDetails;
    }

    get paymentDetailsAccountHolder() {
        return (
            this.paymentDetails.accountHolder ||
            this.paymentDetails.alternativeAccountHolder
        );
    }

    get allowActiveFrom() {
        return this.$store.state.settings.activeFromChangeBankingDetails;
    }

    get minDate(): string {
        return moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
    }

    get sepaMandateActive(): boolean {
        return (
            this.paymentDetails !== null &&
            this.paymentDetails.sepaMandate !== '' &&
            this.details.iban === this.paymentDetails.iban &&
            this.paymentDetails.directDebit === true
        );
    }

    get bicBankPlaceholder(): any {
        if (!this.loadingBankPending && !this.loadingBicPending) {
            return this.$t('widget.cpd.bicBankPlaceholder');
        } else {
            return this.$t('widget.cpd.bicBankPlaceholder.waiting');
        }
    }

    get dataChanged(): boolean {
        return (
            this.paymentDetails !== null &&
            (this.details.contractId !==
                this.$store.state.contracts.contractId ||
                (this.details.directDebit !==
                    !!this.paymentDetails.directDebit &&
                    this.paymentDetails.iban) ||
                this.details.iban !== this.paymentDetails.iban ||
                this.details.bic !== this.paymentDetails.bic ||
                this.details.bank !== this.paymentDetails.bank ||
                this.details.alternativeAccountHolder !==
                    this.paymentDetails.accountHolder)
        );
    }

    private updateBic(): void {
        this.loadingBicPending = true;
        this.details.bic = '';

        paymentProvider
            .bicFromIban(this.details.iban)
            .then(
                (response) => {
                    this.loadingBicPending = false;
                    if (response.data.success) {
                        this.details.bic = response.data.data.bic;
                    } else {
                        this.details.bic = '';
                        this.errorInformation.push({
                            key: '',
                            message: this.$t(
                                'widget.cpd.iban.error'
                            ).toLocaleString(),
                        });
                    }
                },
                (error) => {
                    this.loadingBicPending = false;
                    Sentry.captureException(new Error(error));
                    this.details.bic = '';
                }
            )
            .then((error) => {
                console.log(error);
            });
    }

    private updateBank(): void {
        this.loadingBankPending = true;
        this.details.bank = '';

        paymentProvider.bankFromIban(this.details.iban).then(
            (response) => {
                this.loadingBankPending = false;

                if (response.data.success)
                    this.details.bank = response.data.data.bankName;
                else this.details.bank = '';
            },
            (error) => {
                this.loadingBankPending = false;
                Sentry.captureException(new Error(error));
                this.details.bank = '';
            }
        );
    }

    private removeWhitespaceInIban(): void {
        this.details.iban = this.details.iban.replace(/\s/g, '');
    }

    @Watch('details.iban')
    protected ibanHasChanged(): void {
        this.removeWhitespaceInIban();

        if (
            this.details.iban === this.paymentDetails.iban ||
            !authProvider.isAuthenticated() ||
            !this.isIbanValid
        ) {
            clearTimeout(this.timer);
            return;
        }

        this.errorInformation = [];

        this.updateBicAndBank(true);
    }

    get displayFuturePaymentDetailsActive(): boolean {
        return (
            this.$store.state.settings.displayFuturePaymentDetailsActive &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.futureBankingDetails.length > 0
        );
    }

    get isIbanValid(): boolean {
        return /^[a-zA-Z0-9_.\- ]{15,34}$/.test(this.details.iban);
    }

    protected updateBicAndBank(withTimer = false) {
        clearTimeout(this.timer);

        if (withTimer) {
            this.timer = setTimeout(() => {
                this.updateBic();
                this.updateBank();
                this.timer = null;
            }, 3000);
        } else if (this.timer !== null) {
            this.updateBic();
            this.updateBank();
        }
    }

    protected async submit(): Promise<void> {
        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];

            if (
                !this.details.directDebit &&
                this.displayElement('prevent-submit-no-change')
            ) {
                return;
            }

            await paymentProvider.updatePaymentData(this.details).then(
                (response) => {
                    this.submitPending = false;
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        trackingProvider.masterDataChangesPaymentData();

                        this.$store.dispatch(
                            'tariff/details',
                            this.$store.state.contracts.contractId
                        );
                        if (this.details.iban === null) {
                            this.$store.dispatch('tariff/setPaymentData', {
                                contractId:
                                    this.$store.state.contracts.contractId,
                                paymentData: this.details,
                                key: this.details.direction,
                            });
                        }
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation = [];

                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }

    get cantSubmit(): boolean {
        return (
            this.submitPending ||
            !this.dataChanged ||
            !this.details.iban ||
            this.loadingBicPending ||
            this.loadingBankPending
        );
    }
}
