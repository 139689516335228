import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './modal-message-widget.html';
import { Emit } from 'vue-property-decorator';

@WithRender
@Component({
    props: {
        message: {
            type: String,
            default: null,
        },
    },
})
export class ModalMessageWidget extends Base {
    @Emit('toggle-message-modal')
    private closeModal() {
        // closeModal
    }
}
