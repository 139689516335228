import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './change-customer-data-widget.html';
import { Watch } from 'vue-property-decorator';

import moment from 'moment';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import * as Sentry from '@sentry/browser';
import vSelect from 'vue-select';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Map from '@/interfaces/Map';
import Auth from '../../../interfaces/Auth';
import Customer from '../../../interfaces/Customer';
import SubmitCustomer from '../../../interfaces/SubmitCustomer';
import City from '@/interfaces/City';
import Tracking from '@/interfaces/Tracking';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

import { FormComponents } from '@/components/snippets/form-components';

const mapProvider = IocContainer.get<Map>(SERVICES.MAP);
const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const customerProvider = IocContainer.get<Customer>(SERVICES.CUSTOMER);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({
    components: {
        // External packages
        datetime: Datetime,
        'v-select': vSelect,
        'custom-v-select': FormComponents.CustomVSelect,
        'custom-select': FormComponents.CustomSelect,
        'custom-input': FormComponents.CustomInput,
        'custom-datetime': FormComponents.CustomDateTime,
        'custom-radio': FormComponents.CustomRadio,
        'form-wrapper': FormComponents.FormWrapper,
        'form-group': FormComponents.FormGroup,
        'custom-label': FormComponents.CustomLabel,
        'custom-checkbox': FormComponents.CustomCheckbox,
        'messages-block': MessagesBlock,
    },
    props: {
        alreadyOpen: {
            type: Boolean,
            default: false,
        },
    },
})
export class ChangeCustomerDataWidget extends Base {
    public submitPending = false;
    public showContent = true;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public showAdsConfirmation = false;
    public customerCommunicationTypeError = false;
    public adsConfirmationError = false;
    public dataValid = false;
    public dataValidError = false;
    public allAdsAccepted = false;
    public isRequiredField: { [key: string]: boolean } = {
        emailPrivate: false,
        customerCommunicationType: false,
    };
    public showPostboxField = false;

    public cities: City[] = [];
    public streets: [] = [];
    public selectedCityId: null | number = null;
    public selectedCityZip = null;
    public selectedCityName = null;
    public selectedStreet = null;
    public streetError = false;
    public cityError = false;
    public houseNumberError = false;
    public vwGroupAffiliation: { label: string; value: string } | null = null;

    protected details: SubmitCustomer = {
        company: '',
        salutation: '',
        title: '',
        firstname: '',
        surname: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        dateOfBirth: '',
        email: '',
        emailPrivate: '',
        emailBusiness: '',
        phone: '',
        phoneHomeAreaCode: '',
        phoneHome: '',
        phoneMobileAreaCode: '',
        phoneMobile: '',
        phoneBusinessAreaCode: '',
        phoneBusiness: '',
        phoneAdditional: '',
        customerCommunicationType: 0,
        adsMail: false,
        adsPhone: false,
        adsPost: false,
        adsConfirmation: false,
        synchronizeWithBilling: false,
        contractId: this.$store.state.contracts.contractId,
        postbox: '',
        activeTab: '',
        groupAffiliation: '',
    };

    protected salutationOptionsDears: {
        value: number;
        label: TranslateResult;
    }[] = [
        { value: 8, label: this.$t('widget.cbd.company') },
        { value: 3, label: this.$t('widget.cbd.family') },
    ];

    public salutationOptions: { value: number; label: TranslateResult }[] = [
        { value: 9, label: this.$t('widget.cbd.without') },
        { value: 1, label: this.$t('widget.cbd.mr') },
        { value: 2, label: this.$t('widget.cbd.mrs') },
        ...(!this.displayElement('hide-company-family-dears')
            ? this.salutationOptionsDears
            : []),
    ];

    get vwGroupAffiliationOptions(): { label: string; value: string }[] {
        return [
            {
                label: this.$t(
                    'widget.ccd.group.affiliation.normal'
                ).toString(),
                value: 'Normal',
            },
            {
                label: this.$t(
                    'widget.ccd.group.affiliation.affiliated.company'
                ).toString(),
                value: 'Verbundenes Unternehmen',
            },
            {
                label: this.$t(
                    'widget.ccd.group.affiliation.vw.group'
                ).toString(),
                value: 'Umsatzsteuerorganschaft',
            },
        ];
    }

    protected communicationType: {
        value: number;
        label: TranslateResult;
    }[] = [
        { value: 0, label: this.$t('widget.ccd.choose') },
        { value: 2, label: this.$t('widget.ccd.email') },
        { value: 1, label: this.$t('widget.ccd.post') },
    ];

    protected communicationTypeOptions(): {
        value: string | boolean | number;
        label: string;
        labelAsHtml: boolean;
    }[] {
        return [
            {
                value: 0,
                label: `${this.$t('widget.ccd.choose').toLocaleString()}`,
                labelAsHtml: false,
            },
            {
                value: 1,
                label: `${this.$t('widget.ccd.post').toLocaleString()}
                <i
                    class="
                        fas
                        fa-question-circle
                        text-primary-color
                        font-size-12
                    "
                    v-tooltip.top="${this.$t(
                        'widget.ms.briefpost'
                    ).toLocaleString()}"
                ></i>
                `,
                labelAsHtml: true,
            },
            {
                value: 2,
                label: `${this.$t('widget.ccd.email').toLocaleString()}
                <i
                    class="
                        fas
                        fa-question-circle
                        text-primary-color
                        font-size-12
                    "
                    v-tooltip.top="${this.$t(
                        'widget.ms.email'
                    ).toLocaleString()}"
                ></i>
                `,
                labelAsHtml: true,
            },
        ];
    }
    protected communicationTypeRadioOptions(): {
        value: string | boolean | number;
        label: string;
        labelAsHtml: boolean;
    }[] {
        return [
            {
                value: 1,
                label: `${this.$t('widget.ccd.post').toLocaleString()}
                <i
                    class="
                        fas
                        fa-question-circle
                        text-primary-color
                        font-size-12
                    "
                    v-tooltip.top="${this.$t(
                        'widget.ms.briefpost'
                    ).toLocaleString()}"
                ></i>
                `,
                labelAsHtml: true,
            },
            {
                value: 2,
                label: `${this.$t('widget.ccd.email').toLocaleString()}
                <i
                    class="
                        fas
                        fa-question-circle
                        text-primary-color
                        font-size-12
                    "
                    v-tooltip.top="${this.$t(
                        'widget.ms.email'
                    ).toLocaleString()}"
                ></i>
                `,
                labelAsHtml: true,
            },
        ];
    }

    protected elements = {
        bsw: [
            'customer-email-label',
            'customer-phone-label',
            'customer_ads_mail',
            'customer_ads_phone',
            'customer_ads_post',
            'edit-icon',
            'customer-required-field',
            'force-customerCommunicationType-selected',
            'hide-communication-type',
        ],
        qcells: [
            'show-subtitle',
            'all-ads-consent',
            'customer_edit_fields-footnote',
        ],
        waermeenergie: [
            'user-icon',
            'col-6-field',
            'limit-zip-to-numbers',
            'customerCommunicationType-radioButton',
            'show-phone-with-code',
            'postbox-checkbox-enabled',
        ],
        wdenergy: ['hide-company-family-dears', 'email*'],
        'wdenergy-us': ['hide-company-family-dears', 'email*'],
        prokon: ['hide-close-button', 'show-more-button', 'hide-content'],
        'dsp-*': [
            'customer_ads_confirmation', // TODO: ONLY TEST
        ],
        hsg: ['show-header-notice'],
    };

    private communicationTypeSelectedId: any = null;

    get customerInformationLoaded() {
        return this.$store.state.customer.customerInformationLoaded;
    }
    get salutationCurrentlySelected(): any {
        const chosen = this.salutationOptions.find(
            (option) => option.value === Number(this.details.salutation)
        );
        return chosen ? chosen.label : '';
    }

    get communicationTypeSelectedItem(): any {
        return this.communicationType.find(
            (option) => option.value === this.communicationTypeSelectedId
        );
    }

    get communicationTypeCurrentlySelected(): any {
        return this.communicationType.find(
            (option) =>
                option.value === Number(this.details.customerCommunicationType)
        );
    }

    get showSyncBilling(): boolean {
        return this.$store.state.settings.synchronizeWithBilling;
    }

    get minimumAgeRequired(): any {
        return this.$store.state.settings.minimumAgeRequired;
    }

    get showSubtitle(): boolean {
        return (
            this.displayElement('show-subtitle') &&
            this.$route.name === 'register'
        );
    }

    get displayAllAdsConsent(): boolean {
        if (this.currentClient === 'qcells') {
            return (
                this.displayElement('all-ads-consent') &&
                this.customerDetails.adsMail === false
            );
        }
        return this.displayElement('all-ads-consent');
    }

    get showSelectAddress(): boolean {
        return this.$store.state.settings.validateCustomerAddressEnetActive;
    }

    get contract(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract;
    }

    get submitButtonDisabled(): boolean {
        return (
            !this.details.zip ||
            !this.details.city ||
            !this.details.street ||
            !this.details.houseNumber ||
            this.submitPending
        );
    }

    get isPostboxEnabled(): boolean {
        return this.$store.state.settings.customerPostboxEnabled;
    }

    get isShowPostboxField(): boolean {
        return (
            this.isPostboxEnabled &&
            (this.displayElement('postbox-checkbox-enabled')
                ? this.showPostboxField
                : true)
        );
    }

    get isPostboxError(): boolean {
        return (
            this.details.postbox !== null &&
            this.details.postbox !== '' &&
            (!/^[\d\s]+$/.test(this.details.postbox) ||
                !(this.details.postbox.length <= 10))
        );
    }
    get displayAddLabel(): boolean {
        const formSettings =
            this.$store.state.settings.formSettings['formCustomerData'];
        return (
            !formSettings.hidden.includes(
                'change-customer-data-widget__ads-mail'
            ) ||
            !formSettings.hidden.includes(
                'change-customer-data-widget__ads-phone'
            ) ||
            !formSettings.hidden.includes(
                'change-customer-data-widget__ads-post'
            )
        );
    }

    public isNumeric(value: string): boolean {
        return /^-{0,1}\d+$/.test(value);
    }

    protected async citySearch(search, searchType = 'zip'): Promise<void> {
        if (search.length >= 3) {
            try {
                const response = await mapProvider.cities(
                    this.contract.energy,
                    search.replace(/^0+/, '')
                );

                this.cities = this.citiesNormalizing(
                    response.data.results,
                    searchType
                ).filter((city) => {
                    if (searchType === 'zip') {
                        return this.isNumeric(search)
                            ? new RegExp('^' + search).test(city.zip)
                            : false;
                    } else if (searchType === 'name') {
                        return new RegExp('^' + search, 'i').test(city.name);
                    } else if (searchType === 'text') {
                        return new RegExp('^' + search, 'i').test(city.text);
                    }
                    return false;
                });
            } catch (error) {
                Sentry.captureException(new Error(error));
            }
        } else {
            this.cities = [];
        }
    }

    protected citiesNormalizing(filteredCities, searchType): Record<any, any> {
        const citiesLength = filteredCities.length;

        let cities = filteredCities.map((city) => {
            const cityNormalized = { ...city };
            if (cityNormalized.zip.length < 5) {
                cityNormalized.text = '0' + city.text;
                cityNormalized.zip = '0' + city.zip;
            }
            return cityNormalized;
        });

        if (searchType === 'zip') {
            let currentZipInFilter = null;
            const filteredByZip = cities.every((city) => {
                if (currentZipInFilter === null) {
                    currentZipInFilter = city.zip;
                    return true;
                }
                return currentZipInFilter === city.zip;
            });

            cities = cities.map((city) => {
                const cityNormalized = { ...city };
                cityNormalized.zipText =
                    filteredByZip && citiesLength > 1
                        ? `${cityNormalized.zip} ${cityNormalized.name}`
                        : cityNormalized.zip;
                return cityNormalized;
            });
        } else {
            const nameDuplicatesCount = cities.reduce((prev, cur) => {
                prev[cur.name] = (prev[cur.name] || 0) + 1;
                return prev;
            }, {});

            cities = cities.map((city) => {
                const cityNormalized = { ...city };
                cityNormalized.nameText =
                    nameDuplicatesCount[cityNormalized.name] &&
                    nameDuplicatesCount[cityNormalized.name] > 1
                        ? `${cityNormalized.name} ${cityNormalized.zip}`
                        : cityNormalized.name;
                return cityNormalized;
            });
        }

        return cities;
    }

    protected cityInput(value): void {
        if (value) {
            this.selectedCityId = value.id;
            this.selectedCityZip = this.details.zip = value.zip;
            this.selectedCityName = this.details.city = value.name;
            this.cityError = false;

            if (this.selectedCityId) {
                this.selectedStreet = this.details.street = null;
                mapProvider
                    .streets(this.contract.energy, this.selectedCityId)
                    .then(
                        (response) => {
                            this.streets = response.data;
                        },
                        (error) => {
                            Sentry.captureException(new Error(error));
                        }
                    );
            }
        } else {
            this.selectedCityId = null;
            this.selectedCityZip = this.details.zip = null;
            this.selectedCityName = this.details.city = null;
            this.selectedStreet = this.details.street = null;
            this.details.houseNumber = null;
            this.streets = [];
        }
    }

    protected changeShowContent(): void {
        this.showContent = !this.showContent;
    }

    protected communicationTypeInput(value): void {
        if (value && value.value) {
            this.customerCommunicationTypeError = false;
            this.details.customerCommunicationType = Number(value.value);
            this.communicationTypeSelectedId = Number(value.value);
        } else if (value && value.srcElement.value) {
            this.customerCommunicationTypeError = false;
            this.details.customerCommunicationType = Number(
                value.srcElement.value
            );
            this.communicationTypeSelectedId = Number(value.srcElement.value);
        }
    }

    protected streetInput(value): void {
        if (value) {
            this.streetError = false;
            this.selectedStreet = this.details.street = value.name
                ? value.name
                : value;
        } else {
            this.selectedStreet = this.details.street = null;
        }
    }

    protected async mounted(): Promise<void> {
        trackingProvider.showMasterDataChangeCustomerData();
        this.setDetails();

        if (this.showSelectAddress) {
            await this.citySearch(
                `${this.customerDetails.zip} ${this.customerDetails.city}`,
                'text'
            );

            const selectedCity = this.cities.find((city) => {
                return (
                    city.zip.replace(/^0+/, '') ===
                        this.customerDetails.zip.replace(/^0+/, '') &&
                    city.name === this.customerDetails.city
                );
            });

            this.selectedCityZip = this.customerDetails.zip;
            this.selectedCityName = this.customerDetails.city;
            this.selectedStreet = this.customerDetails.street;

            if (selectedCity) {
                try {
                    const response = await mapProvider.streets(
                        this.contract.energy,
                        parseInt(selectedCity.id, 10)
                    );
                    this.streets = response.data;
                } catch (error) {
                    Sentry.captureException(new Error(error));
                }
            }
        }

        if (this.displayElement('hide-content')) {
            this.showContent = false;
        }
        if (this.$props.alreadyOpen) {
            this.showContent = true;
        }
    }

    protected destroyed(): void {
        trackingProvider.hideMasterDataChangeCustomerData();
    }

    @Watch('details.adsMail')
    protected onAdsMailChange(): void {
        this.checkAdsChange();
    }

    @Watch('details.adsPhone')
    protected onAdsPhoneChange(): void {
        this.checkAdsChange();
    }

    @Watch('details.adsPost')
    protected onAdsPostChange(): void {
        this.checkAdsChange();
    }

    @Watch('customerDetails.authorizedAt')
    @Watch('customerDetails.zip')
    @Watch('customerDetails.firstname')
    protected onCustomerDetailsChange(): void {
        this.setDetails();
    }

    @Watch('details.zip')
    protected onMatchZip(value): void {
        if (this.displayElement('limit-zip-to-numbers')) {
            this.details.zip = value.replace(/[^\d]/, '');
        }
    }

    @Watch('selectedCityZip')
    protected onZipChange(newVal): void {
        if (newVal !== this.customerDetails.zip) {
            this.details.street = '';
            this.details.houseNumber = '';
        }
    }

    protected setDetails(): void {
        this.details.company = this.customerDetails.company;
        this.details.salutation = this.customerDetails.salutation_number;
        this.details.title = this.customerDetails.title;
        this.details.firstname = this.customerDetails.firstname;
        this.details.surname = this.customerDetails.surname;
        this.details.street = this.customerDetails.street;
        this.details.houseNumber = this.customerDetails.houseNumber;
        this.details.zip = this.customerDetails.zip;
        this.details.city = this.customerDetails.city;
        this.details.dateOfBirth = this.customerDetails.dateOfBirth;
        this.details.email = this.customerDetails.email;
        this.details.emailPrivate = this.customerDetails.emailPrivate;
        this.details.emailBusiness = this.customerDetails.emailBusiness;
        this.details.phone = this.customerDetails.phone;
        this.details.phoneHomeAreaCode = this.customerDetails.phoneHomeAreaCode;
        this.details.phoneHome = this.customerDetails.phoneHome;
        this.details.phoneMobileAreaCode =
            this.customerDetails.phoneMobileAreaCode;
        this.details.phoneMobile = this.customerDetails.phoneMobile;
        this.details.phoneBusinessAreaCode =
            this.customerDetails.phoneBusinessAreaCode;
        this.details.phoneBusiness = this.customerDetails.phoneBusiness;
        this.details.phoneAdditional = this.customerDetails.phoneAdditional;
        this.details.customerCommunicationType =
            this.customerDetails.customerCommunicationType;
        this.details.adsMail = this.customerDetails.adsMail;
        this.details.adsPhone = this.customerDetails.adsPhone;
        this.details.adsPost = this.customerDetails.adsPost;
        this.details.postbox = this.customerDetails.postbox;
        this.allAdsAccepted =
            this.customerDetails.adsMail &&
            this.customerDetails.adsPhone &&
            this.customerDetails.adsPost;
        this.details.groupAffiliation = this.customerDetails.groupAffiliation;
        if (this.currentContract && this.currentContract.extendedData) {
            if (this.currentContract.extendedData.values) {
                if (this.currentContract.extendedData.values.THG_MULTIYEAR) {
                    this.currentContract.extendedData.values.THG_MULTIYEAR.forEach(
                        (element) => {
                            if (
                                element.technicalKey ===
                                'ACCOUNTING_INDICATOR_MULTI'
                            ) {
                                this.vwGroupAffiliation =
                                    this.vwGroupAffiliationOptions.find(
                                        (item) => {
                                            return item.value === element.value;
                                        }
                                    ) ?? null;
                            }
                        }
                    );
                }
            }
        }

        this.communicationTypeSelectedId =
            this.customerDetails.customerCommunicationType;

        this.showPostboxField =
            this.isPostboxEnabled &&
            this.customerDetails.postbox !== '' &&
            this.customerDetails.postbox !== null;
    }

    protected checkAdsChange(): void {
        if (this.displayElement('customer_ads_confirmation')) {
            this.$nextTick(function () {
                this.showAdsConfirmation =
                    (this.details.adsMail && !this.customerDetails.adsMail) ||
                    (this.details.adsPhone && !this.customerDetails.adsPhone) ||
                    (this.details.adsPost && !this.customerDetails.adsPost);
            });
        }
    }
    get showNoChangeErrorMessage(): boolean {
        this.log(this.$route.name);
        return this.$route.name === 'register';
    }

    get displayDataConfirmationCheckBox(): boolean {
        return this.$route.name === 'register';
    }

    get showDataValid(): boolean {
        return this.$route.name === 'register';
    }

    get formSettings() {
        return this.$store.state.settings.formSettings.formCustomerData;
    }

    public checkboxOptions() {
        return [
            {
                value: true,
                label: this.$t('widget.ccd.acceptAsBilling').toLocaleString(),
            },
        ];
    }

    public allAdsCheckboxOptions = () => {
        return [
            {
                value: true,
                labelAsHtml: true,
                label: `
                ${this.$t('widget.ccd.checkbox.second1').toLocaleString()}
                <a
                :href="'mailto:' + $t('widget.ccd.checkbox.second2')"
                >${this.$t('widget.ccd.checkbox.second2').toLocaleString()}</a>
                ${this.$t('widget.ccd.checkbox.second3').toLocaleString()}
                <i></i>
                <span></span>
                `,
            },
        ];
    };

    public dataValidCheckboxOptions() {
        return [
            {
                value: true,
                labelAsHtml: true,
                label: `
                ${this.$t('widget.ccd.checkbox.first').toLocaleString()}
                <i></i>
                <span></span>
                `,
            },
        ];
    }

    protected async submit(): Promise<void> {
        if (authProvider.isAuthenticated()) {
            // validation
            this.errorInformation = [];
            this.successInformation = [];

            if (this.isPostboxError) {
                this.errorInformation.push({
                    key: 'widget.ccd.postbox.error',
                    message: this.$t(
                        'widget.ccd.postbox.error'
                    ).toLocaleString(),
                });
                return;
            }

            if (this.showDataValid && !this.dataValid) {
                this.dataValidError = true;
                return;
            }

            if (
                this.details.customerCommunicationType === '0' &&
                this.displayElement('force-customerCommunicationType-selected')
            ) {
                this.customerCommunicationTypeError = true;
                return;
            }

            if (this.showAdsConfirmation && !this.details.adsConfirmation) {
                this.adsConfirmationError = true;
                return;
            }

            if (
                this.minimumAgeRequired &&
                moment().diff(moment(this.details.dateOfBirth), 'years') <
                    this.minimumAgeRequired
            ) {
                this.errorInformation.push({
                    key: '',
                    message: this.$t('widget.ccd.minimumAgeRequiredError', {
                        years: this.minimumAgeRequired,
                    }).toLocaleString(),
                });
                return;
            }

            if (
                this.currentClient === 'bsw' &&
                (!this.details.emailPrivate ||
                    !this.details.customerCommunicationType)
            ) {
                !this.details.emailPrivate
                    ? (this.isRequiredField.emailPrivate = true)
                    : (this.isRequiredField.emailPrivate = false);
                !this.details.customerCommunicationType
                    ? (this.isRequiredField.customerCommunicationType = true)
                    : (this.isRequiredField.customerCommunicationType = false);
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'widget.cs.requiredFields'
                    ).toLocaleString(),
                });
                if (!this.details.customerCommunicationType) {
                    this.customerCommunicationTypeError = true;
                    this.errorInformation.push({
                        key: '',
                        message: this.$t(
                            'widget.cdc.error.customerCommunicationType'
                        ).toLocaleString(),
                    });
                }

                return;
            }

            if (
                this.currentClient === 'wdenergy' &&
                !this.details.emailPrivate
            ) {
                this.errorInformation.push({
                    key: '',
                    message: this.$t('widget.cs.invalidEmail').toLocaleString(),
                });
                return;
            }

            if (!this.details.street && !this.details.postbox) {
                this.streetError = true;
                return;
            }

            if (!this.details.houseNumber && !this.details.postbox) {
                this.houseNumberError = true;
                return;
            }

            if (
                this.isFieldEnabled(
                    this.formSettings,
                    'change-customer-data-widget__all-ads-accepted'
                )
            ) {
                if (this.allAdsAccepted) {
                    this.details.adsMail = true;
                    this.details.adsPhone = true;
                    this.details.adsPost = true;
                } else {
                    this.details.adsMail = false;
                    this.details.adsPhone = false;
                    this.details.adsPost = false;
                }
            }

            this.submitPending = true;
            this.details.dateOfBirth = this.details.dateOfBirth
                ? this.details.dateOfBirth.substring(0, 10)
                : null;

            await customerProvider.update(this.details).then(
                (response) => {
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.successInformation.push({
                                key: '',
                                message: this.$t(
                                    'widget.cs.customer.data.successful'
                                ),
                            });
                        }

                        trackingProvider.masterDataChangesCustomerData();
                        this.$emit('customerDataUpdated');
                        if (this.vwGroupAffiliation !== null) {
                            this.$store
                                .dispatch('contracts/contracts')
                                .then(() => {
                                    this.$store
                                        .dispatch('customer/getDetails')
                                        .then(() => {
                                            this.setDetails();
                                        });
                                });
                        } else {
                            this.$store
                                .dispatch('customer/getDetails')
                                .then(() => {
                                    this.setDetails();
                                });
                        }
                    } else {
                        if (
                            this.showNoChangeErrorMessage &&
                            response.data.messageLocalized.key ===
                                'powercloud.update.customer.no.changes'
                        ) {
                            this.$emit('customerDataSentNoChanges');
                            return;
                        }
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            response.data.error &&
                            typeof response.data.error === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.error,
                            });
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        this.submitPending = false;
                    }

                    if (this.details.synchronizeWithBilling) {
                        this.$store.dispatch(
                            'tariff/details',
                            this.$store.state.contracts.contractId
                        );
                    }
                },
                (error) => {
                    this.successInformation = [];
                    if (error.response && error.response.data.errors) {
                        if (error.response.data.errors.emailPrivate) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors.emailPrivate[0],
                            });
                        } else if (error.response.data.errors.emailBusiness) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors.emailBusiness[0],
                            });
                        } else if (error.response.data.errors.phoneHome) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors.phoneHome[0],
                            });
                        } else if (
                            error.response.data.errors.phoneHomeAreaCode
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors
                                        .phoneHomeAreaCode[0],
                            });
                        } else if (error.response.data.errors.phoneMobile) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors.phoneMobile[0],
                            });
                        } else if (
                            error.response.data.errors.phoneMobileAreaCode
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors
                                        .phoneMobileAreaCode[0],
                            });
                        } else if (error.response.data.errors.phoneBusiness) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors.phoneBusiness[0],
                            });
                        } else if (
                            error.response.data.errors.phoneBusinessAreaCode
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    error.response.data.errors
                                        .phoneBusinessAreaCode[0],
                            });
                        }
                    } else {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                        Sentry.captureException(new Error(error));
                    }
                    this.submitPending = false;
                }
            );
        }
    }
}
