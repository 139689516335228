import { CustomerServiceFormWidget } from '../customer-service-form-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './unauthenticated.html';

@WithRender
@Component({})
export class CustomerServiceFormWidgetUnauthenticated extends CustomerServiceFormWidget {
    protected created(): void {
        this.details.clientKey = this.currentClient ?? null;
        this.$store.dispatch('contact/categoriesForClient', this.currentClient);
    }
}
