interface IAdditionalAttribute {
    name: string;
    value: string;
}

interface ITag {
    tag: string;
    innerHTML?: string;
    attributes?: IAdditionalAttribute[];
}

class TagService {
    protected additionalHeadTags: ITag[];
    protected additionalBodyTags: ITag[];

    constructor() {
        this.additionalHeadTags = [];
        this.additionalBodyTags = [];
    }

    public appendBodyTag(tag: ITag) {
        this.additionalBodyTags.push(tag);
    }

    public appendHeadTag(tag: ITag) {
        this.additionalHeadTags.push(tag);
    }

    public addAllToDOM() {
        this.addAllToHead();
        this.addAllToBody();
    }

    private addAllToBody() {
        if (this.additionalBodyTags.length > 0) {
            this.additionalBodyTags.forEach((tag) => {
                this.appendAdditionalTag(tag, document.head);
            });
        }
    }

    private addAllToHead() {
        if (this.additionalHeadTags.length > 0) {
            this.additionalHeadTags.forEach((tag) => {
                this.appendAdditionalTag(tag, document.head);
            });
        }
    }

    private appendAdditionalTag(tag: ITag, appendTo: HTMLElement) {
        const newTag = document.createElement(tag.tag);
        if (tag.innerHTML) {
            newTag.innerHTML = tag.innerHTML;
        }
        if (tag.attributes) {
            this.appendAdditionalAttributes(tag.attributes, newTag);
        }
        appendTo.appendChild(newTag);
    }

    private appendAdditionalAttributes(
        additionalAttributes: IAdditionalAttribute[],
        appendTo: HTMLElement
    ) {
        additionalAttributes.forEach((attribute) => {
            appendTo.setAttribute(attribute.name, attribute.value);
        });
    }
}

export default TagService;
