<div class="fixed-bottom" v-if="showCookieAlert">
    <aside id="cookie-banner" class="cookie-banner">
        <div class="cookie-banner__inner container">
            <span class="cookie-banner__headline">
                Diese Website verwendet Technologien (z. B. Cookies)
            </span>

            <form
                class="cookie-banner__settings"
                name="cookie-selection"
                autocomplete="off"
                method="post"
            >
                <fieldset class="cookie-banner__fieldset-default">
                    <p class="cookie-banner__text">
                        Diese Website nutzt Tracking- und Targeting-Technologien
                        (z. B. Cookies). Es werden für den Betrieb der Seite
                        notwendige Cookies gesetzt. Darüber hinaus verwenden wir
                        Technologien, um die Zugriffe auf unsere Website zu
                        analysieren, Inhalte und Anzeigen zu personalisieren und
                        Funktionen für soziale Medien anbieten zu können.
                        Außerdem geben wir Informationen zu Verwendung unserer
                        Website durch Sie an unsere Partner für soziale Medien,
                        Werbung und Analysen weiter. Unsere Partner führen diese
                        Informationen möglicherweise mit weiteren Daten
                        zusammen, die Sie ihnen bereitgestellt haben oder die
                        sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
                        Um bestimmte Dienste verwenden zu dürfen, benötigen wir
                        Ihre Einwilligung. Indem Sie „Alle akzeptieren“ klicken,
                        erklären Sie sich mit der Verwendung der oben genannten
                        Technologien und Cookies einverstanden (jederzeit
                        widerruflich). Unter „Einstellungen“ können Sie Ihre
                        Einstellungen ändern und die Datenverarbeitung ablehnen.
                        Weitere Informationen finden Sie in unserer
                        Datenschutzerklärung. Ihre Auswahl können Sie jederzeit
                        über die Cookie-Einstellungen anpassen.
                    </p>
                    <div class="cookie-banner__inner-footer">
                        <div class="cookie-banner__actions action-set--1">
                            <button
                                class="cookie-banner__button button--secondary"
                                data-action="settings"
                            >
                                Einstellungen
                            </button>
                            <button
                                class="cookie-banner__button button--primary"
                                data-action="accept"
                                data-test="cookie-banner"
                                @click="hideCookieAlert"
                            >
                                Alle akzeptieren
                            </button>
                        </div>
                        <div class="cookie-banner__links">
                            <a href="#">Impressum</a>
                            <a href="#">Datenschutz</a>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="cookie-banner__fieldset-settings" hidden>
                    <div class="cookie-banner__category-wrap">
                        <label class="form-check form-check--disabled">
                            <input
                                type="checkbox"
                                class="form-check__input"
                                name="categories"
                                disabled
                                checked
                                value="necessary"
                            />
                            <span class="form-check__label"> Notwendig </span>
                            <span class="form-check__label-description">
                                Notwendige Cookies ermöglichen es Ihnen, sich
                                auf unserer Website zu bewegen und deren
                                grundsätzliche Funktionalität bereitzustellen.
                                Ohne diese Cookies können wesentliche Funktionen
                                nicht bereitgestellt werden.
                            </span>
                        </label>
                        <label class="form-check">
                            <input
                                type="checkbox"
                                class="form-check__input"
                                name="categories"
                                value="comfort"
                            />
                            <span class="form-check__label"> Funktional </span>
                            <span class="form-check__label-description">
                                Funktionale Cookies dienen dazu, die Nutzung
                                unseres Angebots für Sie angenehmer zu
                                gestalten. So müssen Sie z. B. im Solarrechner
                                keine Daten für Ihre Dachfläche manuell
                                eintragen, sondern können diese bequem über eine
                                Google Maps Karte auswählen und berechnen
                                lassen. Zudem ermöglichen diese Cookies die
                                Nutzung der Chatfunktion.
                            </span>
                        </label>
                        <label class="form-check">
                            <input
                                type="checkbox"
                                class="form-check__input"
                                name="categories"
                                value="marketing"
                            />
                            <span class="form-check__label">
                                Statistik/Marketing
                            </span>
                            <span class="form-check__label-description">
                                Statistik Cookies werden eingesetzt, um die
                                Nutzung unserer Website statistisch zu erfassen
                                und zum Zwecke der Optimierung unseres Angebotes
                                für Sie auszuwerten. Cookies für Marketingzwecke
                                sammeln Informationen über das Surfverhalten der
                                Websitebesucher, um passende Werbung zu
                                schalten.
                            </span>
                        </label>
                    </div>

                    <div class="cookie-banner__inner-footer">
                        <div class="cookie-banner__actions action-set--1">
                            <span
                                class="settings-back"
                                data-action="settings-back"
                                >←&nbsp;&nbsp;zurück</span
                            >
                            <button
                                class="cookie-banner__button button--secondary"
                                data-action="confirm"
                            >
                                Auswahl speichern
                            </button>
                            <button
                                class="cookie-banner__button button--primary"
                                data-action="accept"
                                @click="hideCookieAlert"
                            >
                                Alle akzeptieren
                            </button>
                        </div>
                        <div class="cookie-banner__links">
                            <a href="https://energie.q-cells.de/impressum/"
                                >Impressum</a
                            >
                            <a href="https://energie.q-cells.de/datenschutz/"
                                >Datenschutz</a
                            >
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </aside>
</div>
