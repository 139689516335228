<div class="m-grid m-grid--hor m-grid--root m-page">
    <div
        class="
            m-grid__item m-grid__item--fluid
            m-grid
            m-grid--desktop
            m-grid--ver-desktop
            m-grid--hor-tablet-and-mobile
            m-login m-login--6
        "
        id="m_login"
    >
        <div
            class="
                m-grid__item
                m-grid__item--fluid
                m-grid__item--order-tablet-and-mobile-1
                m-login__wrapper
            "
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div
                    class="m-login__signin"
                    :class="isRegisterStepFour ? 'm-login__signin-step-4' : ''"
                >
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>

                    <!-- Login Display -->
                    <div class="m-login__title">
                        <h1>
                            {{ $t("login.title2") }}<b data-test="login-title"
                                >{{$t("login.title")}}</b
                            >{{ $t("login.title3") }}
                        </h1>
                        <template v-if="!isRegister">
                            <h6>{{ $t("login.subtitle2") }}</h6>
                            <h6 class="mb-5">{{ $t("login.subtitle3") }}</h6>
                        </template>
                    </div>

                    <!-- LOGIN AREA-->
                    <login-page
                        v-if="isLoginPage"
                        :second="second"
                    ></login-page>

                    <!-- RESET PASSWORD AREA -->
                    <reset-password-widget
                        v-if="resetPassword"
                    ></reset-password-widget>

                    <!-- REGISTER AREA-->
                    <register-page v-if="isRegister"></register-page>

                    <!-- VERIFY WIDGET -->
                    <verify-widget v-if="isVerify"></verify-widget>

                    <!-- RESTORE TWO FACTOR AUTHENTICATION AREA -->
                    <restore-two-factor-authentication
                        v-if="isRestoreTwoFactorAuthentication"
                    ></restore-two-factor-authentication>

                    <!-- AUTHPLUS FORCE CHANGE PASSWORD -->
                    <auth-plus-new-password-widget
                        v-if="isForceToChangePassword"
                    ></auth-plus-new-password-widget>
                </div>
                <!--end::Signin-->
            </div>
            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <footer class="footer__wrap login-footer">
        <div v-if="!isRegister" class="footer__inner">
            <div class="footer__inner-content">
                <div class="font-weight-600">{{ $t("footer.text1") }}</div>
                <div>{{ $t("footer.text2") }}</div>
                <button
                    @click="$router.push({name: 'register'})"
                    class="
                        btn btn-primary
                        m-btn m-btn--pill m-btn--custom m-btn--air
                        m-login__btn m-login__btn--primary
                    "
                >
                    {{ $t("footer.button") }}
                </button>
                <div>{{ $t("footer.text3") }}</div>
            </div>
        </div>
        <div class="footer__inner">
            <div class="footer__inner-content">
                <div class="font-weight-600">{{ $t("footer.text4") }}</div>
                <div>{{ $t("footer.text5") }}</div>
                <div class="mt-4">{{ $t("footer.text6") }}</div>
                <div class="footer-phone">
                    <img src="./img/evus/qcells/icon_phone.svg" alt="" />{{
                    $t("footer.phone") }}
                </div>
                <div class="footer-mail">
                    <img src="./img/evus/qcells/icon_mail.svg" alt="" />{{
                    $t("footer.mail") }}
                </div>
            </div>
        </div>
    </footer>
    <!-- end::Footer -->
    <cookie-banner-widget-qcells
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget-qcells>
</div>
