<header id="m_header" class="m-grid__item m-header">
    <!-- BEGIN: Logout -->
    <div class="container">
        <div class="bottom-nav custom-bottom-nav">
            <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
                <li class="m-menu__item" v-if="displayTimeOut">
                    <span
                        v-if="displayElement('timeout-countdown')"
                        class="m-menu__item-text"
                        ><span class="m-menu__item-text__block"
                            >{{ $t('left.aside.timeout.automatic') }}</span
                        >
                        <span class="m-menu__item-text__block"
                            >{{ time }} {{ $t('left.aside.timeout.minute')
                            }}</span
                        ></span
                    >
                </li>
                <li class="m-menu__item">
                    <a
                        v-if="displayElement('logout-simple-link')"
                        class="btn btn-link btn-white"
                        @click="logout()"
                        ><span>{{ $t('asside.signout') }}</span></a
                    >
                    <a v-else @click="logout()" class="m-menu__link">
                        <span class="m-menu__link-custom-icon"></span>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text"
                                    >{{ $t('asside.signout') }}</span
                                >
                            </span>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="m-container m-container--fluid m-container--full-height">
        <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- BEGIN: Brand -->
            <div
                :class="brandAuth"
                class="m-stack__item m-brand m-brand--skin-dark"
            >
                <div class="m-stack m-stack--ver m-stack--general">
                    <div
                        class="
                            m-stack__item m-stack__item--middle
                            m-brand__logo
                        "
                    >
                        <img alt="" :src="headerLogo('red', 'png')" />
                    </div>
                    <div
                        class="
                            m-stack__item m-stack__item--middle
                            m-brand__tools
                        "
                    >
                        <!-- BEGIN: Left Aside Minimize Toggle -->
                        <a
                            @click="$emit('toggleSidebar')"
                            id="m_aside_left_minimize_toggle"
                            class="
                                m-brand__icon
                                m-brand__toggler
                                m-brand__toggler--left
                                m--visible-desktop-inline-block
                            "
                        >
                            <span></span>
                        </a>
                        <!-- END -->
                        <!-- BEGIN: Responsive Aside Left Menu Toggler -->
                        <a
                            v-if="isNotAuthPage"
                            @click="$emit('toggleSidebar')"
                            id="m_aside_left_offcanvas_toggle"
                            class="
                                m-brand__icon
                                m-brand__toggler
                                m-brand__toggler--left
                                m--visible-tablet-and-mobile-inline-block
                            "
                        >
                            <span></span>
                        </a>
                        <!-- END -->
                    </div>
                    <!-- Popup tooltip -->
                    <div v-once class="m-stack--popup-text">
                        <span class="m-stack--popup-text-info"
                            >{{ $t('header.popup.text.info') }}</span
                        >
                        <span class="m-stack--popup-text-close"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
