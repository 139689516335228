<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <div class="adv_container">
                    <div id="electricity_production_widget" class=""></div>
                </div>
            </div>
        </div>
    </div>
</div>
