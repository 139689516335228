import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './modal-delete-account-widget.html';
import { Emit } from 'vue-property-decorator';

import vSelect from 'vue-select';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import WaermeenergieAuth from '@/interfaces/WaermeenergieAuth';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
        'v-select': vSelect,
    },
})
export class ModalDeleteAccountWidget extends Base {
    protected successInformation: Record<any, any> = [];
    protected errorInformation: Record<any, any> = [];

    private auth = IocContainer.get<WaermeenergieAuth>(
        SERVICES.AUTH_WAERMEENERGIE
    );
    private currentPassword = '';
    private reason = '';

    private reasonOptions: { value: string; label: string }[] = [
        {
            value: 'KEIN_KUNDE_MEHR',
            label: this.$t(
                'widget.mda.delete.account.reason.contract.cancellation'
            ).toLocaleString(),
        },
        {
            value: 'KKO_NICHT_MEHR_NUTZEN',
            label: this.$t(
                'widget.mda.delete.account.reason.other.supports'
            ).toLocaleString(),
        },
        {
            value: 'DATEN_NICHT_ONLINE_UEBERMITTELN',
            label: this.$t(
                'widget.mda.delete.account.reason.unwanted.data.exchange'
            ).toLocaleString(),
        },
        {
            value: 'VERMISSE_FUNKTIONEN',
            label: this.$t(
                'widget.mda.delete.account.reason.missing.functions'
            ).toLocaleString(),
        },
        {
            value: 'SONSTIGE',
            label: this.$t(
                'widget.mda.delete.account.reason.other.reasons'
            ).toLocaleString(),
        },
    ];

    @Emit('toggle-modal')
    private closeModal() {
        this.reason = '';
        this.currentPassword = '';
    }

    private deleteAccount() {
        this.errorInformation = [];

        if (!this.reason) {
            this.errorInformation.push({
                key: 'widget.mda.reason.required',
                message:
                    'Sie müssen einen der Gründe für das Löschen des Kontos auswählen',
            });
        }

        if (!this.currentPassword) {
            this.errorInformation.push({
                key: 'widget.mda.current.password.required',
                message:
                    'Sie müssen das aktuelle Passwort eingeben, um den Vorgang der Kontolöschung zu bestätigen',
            });
        }

        this.auth
            .deleteAccount({
                reason: this.reason,
                currentPassword: this.currentPassword,
            })
            .then((result) => {
                // If request validation error returns
                if (result.status === 422) {
                    this.setValidationErrors(result.data.errors);
                }

                if (result.status === 200 && result.data.success === true) {
                    this.successInformation.push({
                        key: 'widget.mda.success',
                        message:
                            'Ihr Account wurde erfolgreich gelöscht. Sie werden in 5 Sekunden automatisch abgemeldet.',
                    });
                    setTimeout(() => {
                        this.auth.logout(
                            this.$store.state.settings.idpLogoutUrl
                        );
                    }, 5000);
                } else {
                    this.errorInformation.push({
                        key: 'widget.mda.something.went.wrong',
                        message:
                            'Es ist etwas schief gelaufen. Bitte versuchen Sie es später erneut.',
                    });
                }
            })
            .catch(() => {
                this.errorInformation.push({
                    key: 'widget.mda.something.went.wrong',
                    message:
                        'Es ist etwas schief gelaufen. Bitte versuchen Sie es später erneut.',
                });
            });
    }

    private setValidationErrors(errors) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const value = errors[key];
                if (value) {
                    for (const keyValue in value) {
                        if (value.hasOwnProperty(keyValue)) {
                            const message = value[keyValue];
                            if (message && typeof message === 'object') {
                                this.errorInformation.push({
                                    key: message.key,
                                    message: message.message,
                                });
                            }
                        }
                    }
                }
            }
        }
    }
}
