import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Contracts from '../interfaces/Contracts';
import PrototypeProvider from '@/providers/PrototypeProvider';
import SubmitCancellation from '@/interfaces/SubmitCancellation';
import SubmitMove from '@/interfaces/SubmitMove';
import SubmitCustomerDataCorrection from '@/interfaces/Customer/SubmitCustomerDataCorrection';

export interface VehicleRegistration {
    front: string;
    back: string;
}

@injectable()
export default class ContractsProvider
    extends PrototypeProvider
    implements Contracts
{
    public async contractRevoke(
        contractId: number,
        revokeDate: string
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/contract/revoke',
            {
                contractId,
                revokeDate,
            },
            this.config
        );
    }

    public async getProductsByContractId(
        contractId: number
    ): Promise<AxiosResponse<any>> {
        this.createConfig();
        return await this.http.get(
            '/contract/' + contractId + '/products',
            this.config
        );
    }

    public async callToActionBanner(
        contractId: number
    ): Promise<AxiosResponse<any>> {
        this.createConfig();
        return await this.http.get(
            '/contract/call-to-action-banner/' + contractId,
            this.config
        );
    }

    private encodeObjectAsUrlParameters(
        source: Record<string, unknown>
    ): string {
        function reducer(obj, parentPrefix = null) {
            return function (prev, key) {
                const val = obj[key];
                key = encodeURIComponent(key);
                const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

                if (val == null || typeof val === 'function') {
                    prev.push(`${prefix}=`);
                    return prev;
                } else if (typeof val === 'boolean') {
                    prev.push(`${prefix}=${val.toString().toLowerCase()}`);
                    return prev;
                } else if (['number', 'string'].includes(typeof val)) {
                    prev.push(`${prefix}=${encodeURIComponent(val)}`);
                    return prev;
                }
                prev.push(
                    Object.keys(val).reduce(reducer(val, prefix), []).join('&')
                );
                return prev;
            };
        }
        return Object.keys(source).reduce(reducer(source), []).join('&');
    }

    public async contracts(): Promise<AxiosResponse> {
        this.createConfig();
        if (
            [
                'elli',
                'elli-thg-audi',
                'elli-thg-cupra',
                'elli-thg-dealer',
                'elli-thg-skoda',
                'elli-thg-vw',
                'elli-thg-vwfs',
                'elli-thg-freenet',
                'elli-thg-elli',
            ].includes(process.env.VUE_APP_ENVIRONMENT ?? '')
        ) {
            const query = this.encodeObjectAsUrlParameters({
                withExtData: true,
                extDataSelection: {
                    THG: true,
                    THG_MULTIYEAR: true,
                    THG_COMMUNICATION: true,
                },
            });

            return await this.http.get('/contracts?' + query, this.config);
        }
        return await this.http.get('/contracts', this.config);
    }

    public async getVehicleRegistration(
        id: number,
        year: string
    ): Promise<VehicleRegistration | null> {
        const response: VehicleRegistration[] = (
            await this.http.get(
                '/customer/vehicleRegistration?contractId=' +
                    id +
                    '&year=' +
                    year,
                this.config
            )
        ).data.map((item) => ({
            front: item.document_file_name,
            back: '',
        }));

        return response.length > 0 ? response[0] : null;
    }

    public async contractDetails(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/contract/' + id, this.config);
    }

    public async contractAddonStatus(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/addon-status/' + id, this.config);
    }

    public async dataCorrectionRequired(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/contract/' + id + '/datacorrection/required',
            this.config
        );
    }

    public async cancelContract(
        data: SubmitCancellation
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/contract/cancel', data, this.config);
    }

    public async moveContract(data: SubmitMove): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/contract/move', data, this.config);
    }

    public async downloadCustomerCommunication(
        id: number,
        archiveId: number,
        title: string
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/communications/' + id + '/download/' + archiveId + '/' + title,
            {
                ...this.config,
                responseType: 'blob',
            }
        );
    }

    public async downloadArchiveFile(
        id: number,
        archiveId: number,
        title: string
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/communications/' +
                id +
                '/download/archive/' +
                archiveId +
                '/' +
                title,
            {
                ...this.config,
                responseType: 'blob',
            }
        );
    }

    public async dealerQuotes(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/contract/' + id + '/dealer-data',
            this.config
        );
    }

    public async dataCorrection(
        data: SubmitCustomerDataCorrection,
        id: number
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/contract/' + id + '/datacorrection',
            data,
            this.config
        );
    }

    public async updateCarData(data: any): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/contract/update-car-data',
            data,
            this.config
        );
    }

    public async isDonationAllowed(data: any): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/contract/check/donation',
            data,
            this.config
        );
    }
    public async applyDonation(data: any): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/contract/donate', data, this.config);
    }

    public async getCommunicationProfile(id: number) {
        this.createConfig();
        return await this.http.get(
            '/contract/' + id + '/communication-profile',
            this.config
        );
    }
}
