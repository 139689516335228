import WithRender from './qcells.html';
import { default as Component } from 'vue-class-component';

import { CookieBannerWidget } from './../cookie-banner-widget';

@WithRender
@Component({})
export class CookieBannerWidgetQcells extends CookieBannerWidget {
    public created(): void {
        // add JQuery

        const jQuery = document.createElement('script');
        jQuery.setAttribute(
            'src',
            'https://code.jquery.com/jquery-3.5.1.slim.min.js'
        );
        document.body.appendChild(jQuery);

        const script = document.querySelector(
            'script[src="https://www.googletagmanager.com/gtm.js?id=GTM-M9S5LXR"]'
        );
        if (!script) {
            // add Tag Manager
            const dataLayer = document.createElement('script');
            dataLayer.setAttribute('src', '/js/evus/qcells/dataLayer.js');
            document.head.appendChild(dataLayer);

            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.setAttribute(
                'src',
                'https://www.googletagmanager.com/ns.html?id=GTM-M9S5LXR'
            );
            iframe.setAttribute('height', '0');
            iframe.setAttribute('width', '0');
            iframe.setAttribute('style', 'display:none;visibility:hidden');
            noscript.appendChild(iframe);
            document.body.appendChild(noscript);
        }

        // add Cookie Consent
        const cookieConsent = document.createElement('script');
        cookieConsent.setAttribute(
            'src',
            '/js/evus/qcells/cookie-consent.min.js'
        );
        document.head.appendChild(cookieConsent);

        const cookieBanner = document.createElement('script');
        cookieBanner.setAttribute('src', '/js/evus/qcells/cookieBanner.js');
        document.body.appendChild(cookieBanner);
    }
}
