import { TariffChangeWidget as parentTariffChangeWidget } from '../tariff-change-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './bsw.html';
import * as Sentry from '@sentry/browser';
import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import Tariff from '@/interfaces/Tariff';
import { productImageMap } from '@/map/productImageMap';
import { add, parse, format, sub } from 'date-fns';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const tariffProvider = IocContainer.get<Tariff>(SERVICES.TARIFF);

@WithRender
@Component({})
export class TariffChangeWidgetBsw extends parentTariffChangeWidget {
    get tariff() {
        return this.list[0];
    }

    get productCode() {
        return this.tariff.productCode;
    }

    get productImage(): string | null {
        if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
            try {
                return productImageMap[this.productCode];
            } catch (error) {
                if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'prod') {
                    console.error(
                        'Product image not found for product code: ' +
                            this.productCode
                    );
                    console.log(error);
                }
                return null;
            }
        }
        return null;
    }

    get minimalDurationValue() {
        const parsed = parse(
            this.$store.state.settings.tariffChangeSelectedDate,
            'dd.MM.yyyy',
            new Date()
        );
        const durationType = {};
        durationType[this.tariff.contractInitialDurationType.toLowerCase()] =
            this.tariff.contractInitialDuration;
        let result = add(parsed, durationType);
        result = sub(result, {
            days: 1,
        });
        return format(result, 'dd.MM.yyyy');
    }

    protected async submit(tariff): Promise<any> {
        if (!tariff.confirmationCheckbox && this.currentClient !== 'qcells') {
            tariff.confirmedError = true;
            return;
        }

        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            this.details.productCode = tariff.productCode;
            await tariffProvider
                .changeTariff(
                    Object.assign(this.details, {
                        tariffChangeDateType: 'date',
                        tariffChangeStartDate:
                            this.$store.state.settings.tariffChangeSelectedDate,
                    })
                )
                .then(
                    (response): void => {
                        this.submitPending = false;
                        if (response.data.success) {
                            this.showSuccessChangeTariff = true;
                            this.activeFrom = response.data.data.activeFrom;
                            this.product = response.data.data.product;
                            this.newDepositValue =
                                response.data.data.newDepositValue;
                            this.$store.dispatch(
                                'dashboard/showTariffOverview'
                            );
                        } else {
                            if (
                                response.data.errorMessages &&
                                response.data.errorMessages.length > 0
                            ) {
                                this.errorInformation =
                                    response.data.errorMessages;
                            } else if (
                                typeof response.data.messageLocalized ===
                                'object'
                            ) {
                                this.errorInformation.push(
                                    response.data.messageLocalized
                                );
                            } else if (
                                typeof response.data.messageLocalized ===
                                'string'
                            ) {
                                this.errorInformation.push({
                                    key: '',
                                    message: response.data.messageLocalized,
                                });
                            } else {
                                this.errorInformation.push({
                                    key: '',
                                    message:
                                        this.$t(
                                            'general.error'
                                        ).toLocaleString(),
                                });
                            }
                        }
                    },
                    (error) => {
                        this.submitPending = false;
                        this.successInformation = [];
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }
}
