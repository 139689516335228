import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './donation-widget.html';
import IocContainer from '@/container/IocContainer';
import Contracts from '@/interfaces/Contracts';
import SERVICES from '@/container/Services';

const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

import { MessagesBlock } from '@/components/snippets/messages';

@WithRender
@Component({
    components: {
        'messages-block': MessagesBlock,
    },
})
export class DonationWidget extends Base {
    public submitPending = false;
    public showSuccessDonation = false;
    public errorInformation: Record<any, any> = [];
    public confirmation = false;
    public confirmedError = false;

    public alreadySubmited = false;
    public submit() {
        this.submitPending = true;
        if (!this.confirmation) {
            this.confirmedError = true;
            this.submitPending = false;
            return;
        }
        this.confirmedError = false;

        if (this.alreadySubmited) {
            this.errorInformation.push({
                key: '',
                message: this.$t(
                    'widget.donation.already.submitted'
                ).toLocaleString(),
            });
            return;
        }

        contractsProvider
            .applyDonation({
                contractId: this.contractId,
            })
            .then((res) => {
                if (res.data.success) {
                    this.showSuccessDonation = true;
                    this.alreadySubmited = true;
                } else {
                    this.errorInformation.push({
                        key: '',
                        message: this.$t(
                            'widget.donation.already.submitted'
                        ).toLocaleString(),
                    });
                }
                this.submitPending = false;
            });
    }
}
