import { ChangeBillingDataWidgetElliGeneral } from '@/components/widgets/change-billing-data-widget/custom/elli-general';
import { ChangeBillingDataWidgetWdenergy } from '@/components/widgets/change-billing-data-widget/custom/wdenergy';
import { ChangeBillingDataWidgetWdenergyUs } from '@/components/widgets/change-billing-data-widget/custom/wdenergy-us';
import { ChangeBillingDataWidgetWaermeenergie } from '@/components/widgets/change-billing-data-widget/custom/waermeenergie';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget/change-billing-data-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT?.includes('elli')) {
    widget = ChangeBillingDataWidgetElliGeneral;
} else if (process.env.VUE_APP_ENVIRONMENT === 'waermeenergie') {
    widget = ChangeBillingDataWidgetWaermeenergie;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = ChangeBillingDataWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = ChangeBillingDataWidgetWdenergyUs;
} else {
    widget = ChangeBillingDataWidget;
}
export { widget as ChangeBillingDataWidget };
