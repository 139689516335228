import { handleNavigationFailure } from '@/router/utils';
import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './protected.html';

@WithRender
@Component({})
export class Protected extends Base {
    protected error = false;
    protected password = '';

    get isBack(): boolean {
        return this.$store.state.protector.isBack;
    }

    public created(): void {
        if (this.$store.getters['protector/isAllowed']) {
            if (this.isBack) {
                this.$router.back();
            } else {
                this.$router
                    .push({ name: 'login' })
                    .catch((failure) => handleNavigationFailure(failure));
            }
        }
    }

    public validate(): void {
        this.$store.dispatch('protector/setProtectorStatus', this.password);
        if (this.$store.getters['protector/isAllowed']) {
            this.error = false;
            if (this.isBack) {
                this.$router.back();
            } else {
                this.$router
                    .push({ name: 'login' })
                    .catch((failure) => handleNavigationFailure(failure));
            }
        } else {
            this.error = true;
        }
    }
}
