<div class="m-grid m-grid--hor m-grid--root m-page">
    <header-v2></header-v2>
    <div
        class="
            m-grid__item m-grid__item--fluid
            m-grid
            m-grid--desktop
            m-grid--ver-desktop
            m-grid--hor-tablet-and-mobile
            m-login m-login--6
        "
        id="m_login"
    >
        <div
            class="
                m-grid__item m-grid__item--order-tablet-and-mobile-2
                m-grid m-grid--hor
                m-login__aside
            "
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        ></div>
        <div
            class="
                m-grid__item
                m-grid__item--fluid
                m-grid__item--order-tablet-and-mobile-1
                m-login__wrapper
            "
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>

                    <div v-if="loading" class="idp-loading">
                        <span>{{ showIdpMessage }}</span>
                    </div>
                    <div v-else>
                        <div
                            v-if="isLoginAndRegisterStepIdp"
                            class="idp-login-and-register-step-block"
                        >
                            <div class="idp-auth-page-block">
                                <a
                                    v-if="idpPasswordUrl"
                                    :href="idpPasswordUrl"
                                    class="m-link"
                                >
                                    <span
                                        >{{ $t('idp.password.forgotten')
                                        }}</span
                                    >
                                </a>
                                <div>
                                    <a
                                        v-if="idpLoginUrl"
                                        :href="idpLoginUrl"
                                        class="
                                            btn btn-primary
                                            m-btn
                                            m-btn--pill
                                            m-btn--custom
                                            m-btn--air
                                            m-login__btn m-login__btn--primary
                                        "
                                    >
                                        {{ $t('idp.login') }}
                                    </a>
                                    <a
                                        v-if="idpRegisterUrl"
                                        :href="idpRegisterUrl"
                                        class="
                                            btn btn-primary
                                            m-btn
                                            m-btn--pill
                                            m-btn--custom
                                            m-btn--air
                                            m-login__btn m-login__btn--primary
                                        "
                                    >
                                        {{ $t('idp.register') }}
                                    </a>
                                </div>
                                <div
                                    v-if="!idpPasswordUrl && !idpLoginUrl && !idpRegisterUrl"
                                >
                                    {{ $t('idp.nothing.active') }}
                                </div>
                            </div>
                        </div>

                        <div v-if="isTokenStepIdp" class="idp-token-step-block">
                            <template v-if="isCustomerNumberStepIdp">
                                <div class="registration-step1-message">
                                    {{ $t('login.explanation') }}
                                </div>
                                <div class="m-login__form m-form">
                                    <div
                                        class="form-group m-form__group"
                                        v-if="displayElement('ask-for-contract-id')"
                                    >
                                        <input
                                            class="form-control m-input"
                                            type="text"
                                            id="contractId"
                                            :placeholder="$t('login.contractNumber')"
                                            v-model="contractNumber"
                                        />
                                    </div>
                                    <div
                                        class="form-group m-form__group"
                                        v-if="displayElement('ask-for-customer-id')"
                                    >
                                        <input
                                            class="form-control m-input"
                                            type="text"
                                            id="customerId"
                                            :placeholder="$t('login.customerNumber')"
                                            v-model="customerNumber"
                                        />
                                    </div>
                                    <div class="errors mt-3 me-auto">
                                        <div
                                            class="text-danger"
                                            v-for="error in errors"
                                        >
                                            <div v-for="msg in error">
                                                {{ msg }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-login__action">
                                        <span></span>
                                        <button
                                            @click="getSecretField()"
                                            class="
                                                btn btn-primary
                                                m-btn
                                                m-btn--pill
                                                m-btn--custom
                                                m-btn--air
                                                m-login__btn
                                                m-login__btn--primary
                                            "
                                        >
                                            {{ $t('login.start.registration') }}
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template v-if="isSecretFieldStepIdp">
                                <div class="registration-step2-message">
                                    {{ $t('login.start.security') }}
                                </div>
                                <div class="m-login__form m-form">
                                    <div class="form-group m-form__group">
                                        <input
                                            class="form-control m-input"
                                            type="text"
                                            name="secret-field"
                                            :placeholder="secretFieldLabel"
                                            v-model="secretField"
                                        />
                                    </div>
                                    <div class="errors mt-3 me-auto">
                                        <div
                                            class="text-danger"
                                            v-for="error in errors"
                                        >
                                            <div v-for="msg in error">
                                                {{ msg }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-login__action">
                                        <span></span>
                                        <button
                                            @click="checkSecretField()"
                                            class="
                                                btn btn-primary
                                                m-btn
                                                m-btn--pill
                                                m-btn--custom
                                                m-btn--air
                                                m-login__btn
                                                m-login__btn--primary
                                            "
                                        >
                                            {{ $t('login.check.answer') }}
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!--end::Signin-->
            </div>
            <div v-if="locales.length > 1">
                <span
                    v-for="(locale, index) in locales"
                    @click="changeLocale(locale)"
                >
                    {{ $t('locale.'+locale) }}
                    <span v-if="index !== locales.length - 1">-</span>
                </span>
            </div>
            <div class="app-footer-right">
                <app-footer
                    v-if="displayElement('app-footer-right')"
                ></app-footer>
            </div>

            <!--end::Body-->
        </div>
    </div>
    <!-- begin::Footer -->
    <app-footer v-if="displayElement('app-footer')"></app-footer>
    <!-- end::Footer -->
    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
</div>
