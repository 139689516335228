<fragment>
    <span :class="wrapperClass" :key="option.value" v-for="option in options">
        <input
            type="checkbox"
            :class="inputClass"
            :id="getId + '-' + option.value"
            :value="option.value"
            v-model="inputValue"
            :disabled="disabled()"
            :checked="option.checked"
            :data-test="getId"
        />
        <label
            v-if="!option.labelAsHtml"
            :for="getId + '-' + option.value"
            :class="labelClass"
            class="p-0"
            >{{ option.label }}
        </label>
        <label
            v-else
            v-html="option.label"
            class="p-0"
            :for="getId + '-' + option.value"
            :class="labelClass"
        >
        </label>
    </span>
</fragment>
