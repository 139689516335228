<div class="m-login__form m-form">
    <div class="form-group m-form__group">
        <input
            data-test="username"
            class="form-control m-input"
            type="text"
            :placeholder="$t('login.username')"
            v-model="username"
            name="username"
            autocomplete="off"
            value=""
        />
    </div>
    <div class="form-group m-form__group">
        <input
            data-test="password"
            class="form-control m-login__form-input--last m-input"
            @keyup.enter="login()"
            v-on:keyup.enter="login"
            v-model="password"
            type="password"
            :placeholder="$t('login.password')"
            name="password"
            autocomplete="off"
        />
    </div>
    <div class="mt-3" v-if="errors.length || Object.keys(errors).length">
        <show-multiple-error-messages
            :errors="errors"
        ></show-multiple-error-messages>
    </div>

    <div class="m-login__action">
        <button
            data-test="btn-login"
            @click="login()"
            class="
                btn btn-primary
                m-btn m-btn--pill m-btn--custom m-btn--air
                m-login__btn m-login__btn--primary
            "
            :class="{'disabled': loginLoadingState}"
            :disabled="loginLoadingState"
        >
            {{ $t('login.submit') }}
        </button>
        <button
            @click="register()"
            class="
                btn btn-register
                m-btn m-btn--pill m-btn--custom m-btn--air
                m-login__btn m-login__btn--primary
            "
            :class="{'disabled': loginLoadingState}"
            :disabled="loginLoadingState"
            data-test="auth-page-button-registration"
        >
            {{ $t('login.auth.register') }}
        </button>
    </div>
    <div class="m-login__links">
        <a @click="$router.push({name: 'register'})" class="m-link">
            <span>{{ $t('login.forgot' )}}</span>
        </a>
        <a
            @click="$router.push({name: 'meter-reading'})"
            class="m-link"
            v-if="showMeterReadingLink"
        >
            <span>{{ $t('meter.reading.link' )}}</span>
        </a>
    </div>
</div>
