import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './show-multiple-error-messages.html';

@WithRender
@Component({
    props: {
        errors: {
            type: Array,
            default: [],
        },
        errorKey: {
            type: String,
            default: '',
        },
    },
})
export class ShowMultipleErrorMessages extends Base {
    protected multipleErrors: string[] = [];

    protected mounted(): void {
        if (
            typeof this.$props.errorKey !== 'undefined' &&
            this.$props.errorKey !== '' &&
            this.$props.errors[this.$props.errorKey].length > 0
        ) {
            this.$props.errors[this.$props.errorKey].map((error) => {
                this.multipleErrors.push(error);
            });
        } else {
            Object.keys(this.$props.errors).map((key, index) => {
                if (this.$props.errors[key].length > 0) {
                    this.$props.errors[key].map((error) => {
                        this.multipleErrors.push(error);
                    });
                }
            });
        }
    }
}
