<div class="tariff-recommendation">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div class="row">
                <div class="col">
                    <span class="box-title static-title">
                        <svg
                            id="Icons"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 80 80"
                        >
                            <title>VW_Icon_Fav_M</title>
                            <path
                                d="M65.54,22.49a13.36,13.36,0,0,0-19.05-.94l-6.49,6-6.49-6h0a13.35,13.35,0,0,0-19,.95C9.68,27.92,9.91,36.36,15,42.11c6.17,7,14.17,15.24,22,22.67a4.3,4.3,0,0,0,6.08,0c7.79-7.43,15.79-15.69,22-22.67C70.09,36.36,70.32,27.92,65.54,22.49Zm-2,18.3c-6,6.83-14.21,15.26-21.84,22.54a2.3,2.3,0,0,1-3.32,0c-7.63-7.28-15.8-15.71-21.84-22.54-4.43-5-4.66-12.3-.54-17A11.1,11.1,0,0,1,24.33,20a11.91,11.91,0,0,1,7.83,3L40,30.26,47.83,23A11.37,11.37,0,0,1,64,23.81C68.16,28.49,67.93,35.78,63.5,40.79Z"
                            />
                        </svg>
                        {{ $t('widget.tr.title') }}
                    </span>
                    <div @click="closeModal" class="fullwidthArea-close">
                        <i class="fa fa-times"></i>
                    </div>
                    <div class="row" v-if="!isTariffChange">
                        <div class="col-12">
                            <div class="tariff-recommendation__main-text">
                                <span>{{ $t('widget.tr.text1') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">{{ $t('widget.td.tariff') }}:</div>
                    </div>
                    <div class="row">
                        <div class="col-12 tariff-rec-details__product-name">
                            <span>{{ recommendationTariff.title || '' }}</span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div
                                class="tariff-recommendation__description-grid-container grid-header"
                            >
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        v-if="!isTariffChange"
                                        v-html="$t('widget.tr.activeFrom', {date:
                                        nextActiveFrom})"
                                    ></span>
                                    <span
                                        v-else
                                        v-html="$t('widget.tr.activeFrom', {date:
                                        nextCancellationDatePlusOne})"
                                    ></span>
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <div>
                                        <span class="grid-item--bold"
                                            >{{ $t('widget.tr.your.next.tariff')
                                            }}</span
                                        >
                                    </div>
                                    <div>
                                        <span v-if="!isNotPA"
                                            >{{
                                            $t('widget.tr.your.next.tariff.sub')
                                            }}</span
                                        >
                                    </div>
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item isBlueBorder"
                                >
                                    <span class="grid-item--bold"
                                        >{{ $t('widget.tr.loyalty.offer')
                                        }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                ></div>
                            </div>
                            <div
                                class="tariff-recommendation__description-grid-container"
                            >
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        >{{ $t('widget.tr.working.price')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{ nextWorkingPriceBrutto | euro }} {{
                                        $t('unit.centperelectricity') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    :class="[isBlue(nextWorkingPriceBrutto,recommendationTariff.workingPriceBrutto || '')? 'isBlue' : '' ]"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        recommendationTariff.workingPriceBrutto
                                        || '' | euro }} {{
                                        $t('unit.centperelectricity') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item tariff-recommendation__description-grid-item--hidden-mobile chekbox-cell"
                                >
                                    <div>
                                        <label>
                                            <input
                                                data-test="tandcs"
                                                id="elli-conditions-checkbox"
                                                class="elli-checkbox-input"
                                                type="checkbox"
                                                v-model="confirmed"
                                            />
                                            <span
                                                v-html="$t('widget.tr.rules', {
                                                              agb: 'https://www.elli.eco/downloads/de/naturstrom/agb.pdf',
                                                              tarif: recommendationTariff.title || ''
                                                            })"
                                            ></span>
                                        </label>
                                    </div>
                                </div>
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        >{{ $t('widget.tr.basic.price')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    data-test="next-working-price-brutto"
                                >
                                    <span class="grid-item--bold"
                                        >{{ priceAdjustmentTariff.basePrice / 12
                                        | euro }} {{ $t('euro.symbol') }}/{{
                                        $t('widget.tr.month') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    data-test="tariff-working-price-brutto"
                                    :class="[isBlue(priceAdjustmentTariff.basePrice / 12, recommendationTariff.basePriceBruttoMonth || '')? 'isBlue' : '']"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        recommendationTariff.basePriceBruttoMonth
                                        || '' | euro}} {{ $t('euro.symbol')
                                        }}/{{ $t('widget.tr.month') }}</span
                                    >
                                </div>
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    v-if="recommendationTariff.bonusSavings && recommendationTariff.bonusSavings > 0"
                                >
                                    <span
                                        >{{ $t('widget.tr.loyalty.bonus')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    v-if="recommendationTariff.bonusSavings && recommendationTariff.bonusSavings > 0"
                                >
                                    <span class="grid-item--bold"
                                        >{{ priceAdjustmentTariff.productBonus
                                        || 0 | euro}} {{ $t('euro.symbol')
                                        }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    :class="[isBlue(priceAdjustmentTariff.productBonus || '', recommendationTariff.bonusSavings || '') ? 'isBlue' : '' ]"
                                    v-if="recommendationTariff.bonusSavings && recommendationTariff.bonusSavings > 0"
                                >
                                    <span class="grid-item--bold"
                                        >{{ recommendationTariff.bonusSavings ||
                                        '' | euro }} {{ $t('euro.symbol')
                                        }}</span
                                    >
                                </div>
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        >{{
                                        $t('widget.tr.minimum.contract.period')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        priceAdjustmentTariff.contractInitialDuration
                                        || priceAdjustmentTariff.minimumRuntime
                                        | euroFull}} {{
                                        $t('widget.tr.month.months') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        recommendationTariff.contractInitialDuration
                                        || '' | euroFull}} {{
                                        $t('widget.tr.month.months') }}</span
                                    >
                                </div>
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        >{{
                                        $t('widget.tr.minimum.contract.renewal')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{ (priceAdjustmentTariff.extensionTime
                                        ||
                                        priceAdjustmentTariff.contractExtensionTime)
                                        | euroFull}} {{
                                        $t('widget.tr.month.months') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{ ((recommendationTariff.extensionTime
                                        ||
                                        recommendationTariff.contractExtensionTime)
                                        || '') | euroFull }} {{
                                        $t('widget.tr.month.months') }}</span
                                    >
                                </div>
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        >{{
                                        $t('widget.tr.minimum.price.guarantee')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{ 0 }} {{ $t('widget.tr.month.months')
                                        }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    :class="[isBlue(priceAdjustmentTariff.fixedPriceMonths || '', recommendationTariff.fixedPriceMonths || '') ? 'isBlue' : '']"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        recommendationTariff.fixedPriceMonths }}
                                        {{ $t('widget.tr.month.months') }}<sup
                                            >2</sup
                                        ></span
                                    >
                                </div>
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span
                                        >{{
                                        $t('widget.tr.minimum.notice.period')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        priceAdjustmentTariff.contractCancellationPeriod}}
                                        {{ $t('widget.tr.month.months') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        recommendationTariff.contractCancellationPeriod
                                        || '' }} {{ $t('widget.tr.month.months')
                                        }}</span
                                    >
                                </div>
                            </div>
                            <div
                                class="tariff-recommendation__description-grid-container grid-footer mt-4"
                            >
                                <!-- row -->
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{
                                        $t('widget.tr.minimum.estimated.discount')
                                        }}:</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <span class="grid-item--bold"
                                        >{{ priceAdjustmentTariffDeposit |
                                        euroFull}} {{ $t('euro.symbol') }}/{{
                                        $t('widget.tr.month') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                    :class="[isBlue(priceAdjustmentTariffDeposit || '', recommendationTariff.deposit || '') ? 'isBlueBorder' : '']"
                                >
                                    <span class="grid-item--bold"
                                        >{{ recommendationTariff.deposit || '' |
                                        euroFull }} {{ $t('euro.symbol') }}/{{
                                        $t('widget.tr.month') }}</span
                                    >
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item tariff-recommendation__description-grid-item--hidden-mobile"
                                >
                                    <div class="d-flex justify-content-between">
                                        <span
                                            >{{ $t('widget.tr.total') }}:</span
                                        >
                                        <span
                                            >{{
                                            recommendationTariff.totalBasePriceBonusBrutto
                                            || '' | euro }} {{ $t('euro.short')
                                            }}/{{ $t('year') }}<sup
                                                >1</sup
                                            ></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div
                                class="tariff-recommendation__description-grid-container tariff-recommendation__description-grid-container--mobile-only tariff-recommendation__description-grid-container--two-cols"
                            >
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <div>
                                        <label>
                                            <input
                                                data-test="tandcs"
                                                id="elli-conditions-checkbox"
                                                class="elli-checkbox-input"
                                                type="checkbox"
                                                v-model="confirmed"
                                            />
                                            <span
                                                v-html="$t('widget.tr.rules', {
                                                              agb: 'https://www.elli.eco/downloads/de/naturstrom/agb.pdf',
                                                              tarif: recommendationTariff.title || ''
                                                            })"
                                            ></span>
                                        </label>
                                    </div>
                                </div>
                                <div
                                    class="tariff-recommendation__description-grid-item"
                                >
                                    <div class="d-flex justify-content-around">
                                        <span
                                            >{{ $t('widget.tr.total') }}:</span
                                        >
                                        <span
                                            >{{
                                            recommendationTariff.totalBasePriceBonusBrutto
                                            || '' | euro }} {{ $t('euro.short')
                                            }}/{{ $t('year') }}<sup
                                                >1</sup
                                            ></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row tariff-recommendation__action--right mt-4">
                        <div class="col-12">
                            <vue-button-spinner
                                class="btn btn-primary mt-4"
                                :is-loading="isLoading"
                                :disabled="isLoading"
                                :status="status"
                            >
                                <span @click="submit"
                                    >{{ $t('widget.tr.change.now') }}</span
                                >
                            </vue-button-spinner>
                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="!isTariffChange">
                        <div class="col-12">
                            <div class="tariff-recommendation__notice-header">
                                <span>{{ $t('widget.tr.notice') }}:</span>
                            </div>
                            <div>
                                <span>{{ $t('widget.tr.notice.text') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="elli-products-disclaimer-wrapper">
                                <div class="elli-products-disclaimer">
                                    <div class="elli-products-disclaimer__link">
                                        <a
                                            href="https://www.elli.eco/de/naturstrom/agb.pdf"
                                            >{{ $t('widget.tr.agb') }}</a
                                        >
                                    </div>
                                    <div class="elli-products-disclaimer__link">
                                        <a
                                            href="https://www.elli.eco/de/naturstrom/preis-und-lieferbedingungen_classic.pdf"
                                            >{{ $t('widget.tr.price.labeling')
                                            }}</a
                                        >
                                    </div>
                                    <div class="elli-products-disclaimer__link">
                                        <a
                                            href="https://www.elli.eco/de/naturstrom/stromkennzeichnung.pdf"
                                            >{{
                                            $t('widget.tr.electricity.labeling')
                                            }}</a
                                        >
                                    </div>
                                    <div
                                        class="elli-products-disclaimer__price-level"
                                        v-html="$t('widget.tr.price.level')"
                                    ></div>
                                    <div class="elli-products-disclaimer__grid">
                                        <div
                                            class="elli-products-disclaimer__grid-number"
                                        >
                                            1)
                                        </div>
                                        <div
                                            class="elli-products-disclaimer__disclaimer-desc--first-star"
                                            v-html="$t('widget.tr.star', {
                                                                    postal: postalCode,
                                                                    price: $options.filters.euro(recommendationTariff.totalBasePriceBonusBruttoMonth || ''),
                                                                    usage: usage,
                                                                })"
                                        ></div>
                                        <div
                                            class="elli-products-disclaimer__grid-number"
                                        >
                                            2)
                                        </div>
                                        <div
                                            class="elli-products-disclaimer__disclaimer-desc--second-star"
                                            v-html="$t('widget.tr.star.2', {
                                                                    priceGuarantee: recommendationTariff.fixedPriceMonths || '',
                                                            })"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
