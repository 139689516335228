import { ChangeWidget } from '../change-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './schweizstrom.html';

@WithRender
@Component({})
export class ChangeWidgetSchweizstrom extends ChangeWidget {
    private showList = false;

    get isMobile() {
        return /iPhone|iPad|Android/.test(navigator.userAgent);
    }
}
