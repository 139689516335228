<div class="col-12 mobile-modal__inner" data-test="meter-submit-widget">
    <div
        class="m-portlet m-portlet--mobile m-portlet--meter-submit border-radius-rb"
    >
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div
                id="meterReadSubmit-content"
                class="mobile-modal__wrap meterReadSubmit-content"
            >
                <div class="row">
                    <div class="col-12 col-md-4">
                        <i
                            class="fa fa-calendar-alt readingHistory-icon pt-1"
                            v-if="displayElement('calendar-icon')"
                        ></i>
                        <span
                            class="box-title static-title static-title--custom"
                            >{{ $t('widget.ms.title') }}
                        </span>

                        <div class="mobile-modal__wrap--meter-info">
                            <div v-if="isMoveInToday">
                                {{ $t('widget.ms.expectingReadingToday',
                                {counterNumber: counter.counterNumber})}}.
                            </div>
                            <div
                                v-else-if="nextReadingStateString === readingStateType.MOVE_IN_MISSING"
                            >
                                {{
                                $t('widget.ms.yourNextMeterReadForCounterMoveIn')}}
                                {{ counter.counterNumber }} {{
                                $t('widget.ms.expectedAtMoveIn') }}
                                <span
                                    :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                                    >{{ nextReadingAt | date }}</span
                                >
                                {{ $t('widget.ms.yourMeterReadingMoveIn') }}.
                            </div>
                            <div
                                v-else-if="nextReadingStateString === readingStateType.MOVE_OUT_MISSING"
                            >
                                {{
                                $t('widget.ms.yourNextMeterReadForCounterMoveOut')}}
                                {{ counter.counterNumber }} {{
                                $t('widget.ms.expectedAtMoveOut') }}
                                <span
                                    :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                                    >{{ nextReadingAt | date }}</span
                                >
                                {{ $t('widget.ms.yourMeterReadingMoveOut') }}.
                            </div>
                            <div v-else>
                                {{ $t('widget.ms.yourNextMeterReadForCounter')}}
                                {{ counter.counterNumber }} {{
                                $t('widget.ms.expectedAt') }}
                                <span
                                    :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                                    >{{ nextReadingAt | date }}</span
                                >
                            </div>
                        </div>

                        <button
                            @click="isVisibleBlock = !isVisibleBlock"
                            class="meterReadSubmit-content__link btn"
                            id="meterReadSubmit-content__link"
                        >
                            {{ $t('widget.ms.submit.reading') }}
                        </button>

                        <messages-block
                            class="schweizstrom-meter-submit-widget-messages-block"
                            fromTestElement="meter-submit-widget-msg"
                            :success="successInformation"
                            :errors="errorInformation"
                        ></messages-block>
                    </div>
                    <div class="col-12 col-md-4 content-center">
                        <div class="fullWithArea-submit">
                            <div class="row">
                                <div
                                    v-for="reading in getDetailsReadingsAtSelectedDate()"
                                    class="col-12 fullWidthArea-input"
                                >
                                    <div v-if="multiRegister"><br /><br /></div>
                                    <label for="readingInput">
                                        {{ $t('widget.ms.title') }}
                                        <vue-numeric
                                            v-if="displayElement('vue-numeric') && reading.value !== null"
                                            class="form-control readingInput-obis"
                                            :empty-value="0"
                                            :min="0"
                                            placeholder="m³"
                                            :precision="precision(reading)"
                                            separator="."
                                            v-model="reading.value"
                                            id="readingInput"
                                            data-test="meter-submit-widget-input-number"
                                        ></vue-numeric>
                                        <input
                                            data-test="meter-submit-widget-input-number"
                                            v-else
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder="m³"
                                            v-model="reading.value"
                                            class="form-control readingInput-obis"
                                        />
                                    </label>
                                </div>
                                <div
                                    class="col-12"
                                    v-if="editActive && displayElement('date-input')"
                                >
                                    <input
                                        data-test="meter-submit-widget-input-date"
                                        id="readAtDate"
                                        v-model="readAtDate"
                                        type="date"
                                        class="form-control"
                                    />
                                </div>
                                <div
                                    class="col-12 fullWithArea-submitButtonHint-inbetweenInvoice"
                                    v-if="isActivateInvoiceTriggering"
                                >
                                    <div class="form-group form-check">
                                        <label
                                            :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                        >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="meterReadSubmit_forceInvoice"
                                                v-model="details.forceInvoice"
                                                :disabled="forceInvoiceDisabled"
                                            />
                                            {{
                                            $t('widget.ms.createInterimStatement')
                                            }}
                                            <i></i>
                                            <span></span>
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="forceInvoiceDisabled"
                                                v-tooltip.bottom="$tc('widget.ms.forceInvoice.notAllowed', daysUntilNextInbetweenInvoice, {days: daysUntilNextInbetweenInvoice})"
                                            ></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label for="readAtTime" class="readAtTime">
                                        {{ $t('widget.at.date') }}

                                        <datetime
                                            data-test="meter-submit-widget-input-date"
                                            class="form-conrol-time"
                                            v-model="details.readAt"
                                            type="date"
                                            input-class="form-control"
                                            value-zone="local"
                                            format="dd.LL.yyyy"
                                            :min-datetime="minDate"
                                            :max-datetime="maxDate"
                                            :phrases="datetimePhrases"
                                            @close="setEditActive(false)"
                                            id="readAtTime"
                                            :placeholder="$t('widget.ms.datetime.placeholder')"
                                        ></datetime>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 content-end">
                        <div
                            class="col-12 text-end fullWithArea-submitButton"
                            v-if="!editActive || displayElement('date-input')"
                        >
                            <button
                                :class="['btn btn-primary', displayElement('default-btn') ? '' : 'btn-sm',displayElement('float-right-class') ? 'float-end' : '', ]"
                                @click="submit"
                                data-test="meter-submit-widget-submit-button"
                            >
                                {{ $t('widget.ms.transfer') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div
                            :class="['tariff-image', isVisibleBlock ? 'tariff-image-visible' : '']"
                        >
                            <span class="tariff-image__arrow"
                                ><span></span
                            ></span>
                            <img
                                src="/img/evus/schweizstrom/single_tariff.svg"
                                alt=""
                            />
                            <span
                                class="tariff-image__arrow tariff-image__arrow--right"
                                ><span></span
                            ></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6"></div>
                    <div class="col-xs-12 col-sm-6">
                        <div v-if="showSurvey" class="survey-widget-block">
                            <survey-widget
                                :widget="'meter-submit-widget'"
                            ></survey-widget>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
