import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './sales-widget.html';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({})
export class SalesWidget extends Base {
    get widgetPlace() {
        return 'PORTAL';
    }

    get vpCodeParam() {
        return false;
    }

    get uvpCodeParam() {
        return false;
    }

    get campaign() {
        return this.$store.state.settings.salesCampaign;
    }

    get business() {
        return this.customerDetails.company ? 'true' : 'false';
    }

    get salesWidgetLink() {
        return this.$store.state.settings.salesWidgetLink;
    }

    @Watch('salesWidgetLink')
    protected onSalesWidgetLinkChange(newVal) {
        if (newVal) {
            this.addScript(newVal);
        }
    }

    protected mounted() {
        if (this.salesWidgetLink) {
            this.addScript(this.salesWidgetLink);
        }
    }

    protected addScript(link) {
        const script = document.createElement('script');
        script.setAttribute('src', link);
        document.head.appendChild(script);
    }
}
