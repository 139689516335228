import { DepositChangeWidget } from '../deposit-change-widget';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './deposit-general-widget.html';

@WithRender
@Component({
    components: {
        ContentLoader,
    },
})
export class DepositGeneralWidget extends DepositChangeWidget {
    protected depositCurrentError = false;
    protected depositDetailsError = false;

    protected created() {
        if (this.$store.state.contracts.contractId) {
            this.$store
                .dispatch(
                    'payment/depositCurrent',
                    this.$store.state.contracts.contractId
                )
                .then(() => {
                    this.depositCurrentError = this.depositDetails.error;
                });
            this.$store
                .dispatch(
                    'payment/depositDetails',
                    this.$store.state.contracts.contractId
                )
                .then(() => {
                    this.depositDetailsError = this.depositDetails.detailsError;
                });
        }
    }

    get deposits(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).depositPlan;
    }

    get depositsLoaded(): boolean {
        return (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).depositPlanLoaded &&
            this.depositDetails.loaded &&
            this.depositDetails.detailsLoaded
        );
    }

    get isDepositsLoading() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.isLoading;
    }

    get isDepositsDetailsLoading() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.isDetailsLoading;
    }

    get isLoading() {
        return this.isDepositsLoading || this.isDepositsDetailsLoading;
    }

    get isDepositsLoadedWithError() {
        return this.depositCurrentError || this.depositDetailsError;
    }
}
