import vue from 'vue';
import { default as Component } from 'vue-class-component';
import WithRender from './consumption-graph.html';

@WithRender
@Component({})
export class AdvizzoConsumptionGraphWidget extends vue {
    public mounted(): void {
        // @ts-ignore
        document.getElementById('consumption_chart_widget').innerHTML =
            // @ts-ignore
            Placeholder.consumption;

        const consumptionApiData = [
            {
                consType: 'electricity',
                measurementUnit: 'kWh',
                consValues: [
                    {
                        date: '2020-12-01',
                        usage: 253.831,
                        outdoorTemp: 17.32,
                        rainfall: 52.2,
                        tips: [],
                        events: [],
                    },
                    {
                        date: '2021-01-01',
                        usage: 213.435,
                        outdoorTemp: 20.1,
                        rainfall: 95.4,
                        tips: [],
                        events: [],
                    },
                    {
                        date: '2021-02-01',
                        usage: 233.282,
                        outdoorTemp: 19.27,
                        rainfall: 12.6,
                        tips: [],
                        events: [],
                    },
                    {
                        date: '2021-03-01',
                        usage: 299.862,
                        outdoorTemp: 17.4,
                        rainfall: 54.2,
                        tips: [],
                        events: [],
                    },
                    {
                        date: '2021-04-01',
                        usage: 301.881,
                        outdoorTemp: null,
                        rainfall: null,
                        tips: [],
                        events: [],
                    },
                ],
                alert: null,
            },
        ];

        const consConfig = {
            showConsType: ['electricity', 'gas'],
            language: {
                graphFooterNote:
                    'Please click on your usage bar to see more information.',
                title: 'My consumption history',
            },
            defaultTab: 'consumption',
            events: {
                areEventsEnabled: false,
                isAcfUser: false,
                canAcfInteract: false,
                onlyInteractWithNewestEvent: false,
                language: {
                    Pending: {
                        system: 'This event is currently pending.',
                    },
                    Resolved: {
                        customer:
                            '<strong>You marked this event as resolved.</strong>',
                        admin: '<strong>This was marked as resolved.</strong>',
                    },
                    Unresolved: {
                        customer:
                            '<strong>You marked this event as unresolved.</strong>',
                        admin: '<strong>This was marked as unresolved.</strong>',
                    },
                    multipleEventsMessage:
                        'Multiple issues occurred on this date. Please click <a href="/events" target="_blank">here</a> to view them',
                    noRealtimeEvents: 'There are currently no live events.',
                    interaction: {
                        success: 'Your choice has been saved',
                        fail: 'Something went wrong when saving your choice',
                    },
                    interactionMatchesError:
                        'The current interaction status already matches the new status.',
                },

                alertBadge: {
                    message: 'We have identified a problem in your usage',
                    url: 'public/img/alert-icon.png',
                    eventBoxColor: '#eb6c05',
                },

                options: [
                    {
                        class: 'far fa-2x fa-thumbs-down',
                        text: 'Unresolved',
                        value: 2,
                    },
                    {
                        class: 'far fa-2x fa-flip-horizontal fa-thumbs-up',
                        text: 'Resolved',
                        value: 1,
                    },
                ],
            },
            tabs: {
                consumption: {
                    datasetName: 'consumption',
                    label: 'Usage',
                    defaultSectionIndex: 0,
                    sections: [
                        {
                            id: '12months',
                            label: 'Monthly (Last 12 months)',
                            charts: [
                                {
                                    title: null,
                                    span: 'monthly',
                                    slice: 12,
                                    points: [
                                        'usage',
                                        'temperature',
                                        'rainfall',
                                    ],
                                },
                            ],
                        },
                        {
                            id: '24months',
                            label: 'Monthly (Last 24 months)',
                            charts: [
                                {
                                    title: null,
                                    span: 'monthly',
                                    slice: 24,
                                    points: [
                                        'usage',
                                        'temperature',
                                        'rainfall',
                                    ],
                                },
                            ],
                        },
                        {
                            id: '8weeks',
                            label: 'Daily (Last 8 weeks)',
                            charts: [
                                {
                                    title: null,
                                    span: 'daily',
                                    slice: 56,
                                    points: ['usage'],
                                },
                            ],
                        },
                        {
                            id: '4weeks',
                            label: 'Daily (Last 4 weeks)',
                            charts: [
                                {
                                    title: null,
                                    span: 'daily',
                                    slice: 28,
                                    points: ['usage'],
                                },
                            ],
                        },
                        {
                            id: '7days',
                            label: 'Hourly (Last 7 days)',
                            charts: [
                                {
                                    title: null,
                                    span: 'hourly',
                                    slice: 168,
                                    points: ['usage'],
                                    extraOptions: {
                                        pointRadius: 0,
                                    },
                                },
                            ],
                        },
                        {
                            id: '24hours',
                            label: 'Hourly (Last 24 hours)',
                            charts: [
                                {
                                    title: null,
                                    span: 'hourly',
                                    slice: 24,
                                    points: ['usage'],
                                },
                            ],
                        },
                    ],
                },
                comparison: {
                    datasetName: 'comparison',
                    label: 'Neighbour Comparison',
                    defaultSectionIndex: 0,
                    sections: [
                        {
                            id: '24months',
                            label: 'Monthly (Last 24 months)',
                            charts: [
                                {
                                    title: null,
                                    span: 'monthly',
                                    slice: 24,
                                    points: [
                                        'usage',
                                        'averageHomes',
                                        'efficientHomes',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            consType: {
                electricity: {
                    dataInfo: {
                        usage: {
                            hidden: false,
                            name: 'electricity',
                            setName: 'usage',
                            label: 'Usage',
                            unit: 'kWh',
                            badge: {
                                img: false,
                                color: '#00AEB4',
                            },
                            baseColor: '#00AEB4',
                        },
                        temperature: {
                            hidden: true,
                            unit: '°C',
                            setName: 'temperature',
                            label: 'Temperature',
                            badge: {
                                img: true,
                                url: 'public/img/temp.png',
                            },
                            baseColor: '#E74C3C',
                        },
                        outdoorTemp: {
                            hidden: false,
                            unit: '°C',
                            setName: 'outdoorTemp',
                            label: 'Temperature',
                            badge: {
                                img: true,
                                url: 'public/img/temp.png',
                                color: '#e74c3c',
                            },
                            baseColor: '#E74C3C',
                        },
                        rainfall: {
                            hidden: true,
                            unit: 'MM',
                            setName: 'rainfall',
                            label: 'Rainfall',
                            badge: {
                                img: true,
                                url: 'public/img/rain.png',
                            },
                            baseColor: '#F39C12',
                        },
                        averageHomes: {
                            hidden: false,
                            setName: 'averageHomes',
                            label: 'Average neighbours',
                            unit: 'kWh',
                            badge: {
                                img: false,
                                color: '#FFA03A',
                            },
                            baseColor: '#FFA03A',
                        },
                        efficientHomes: {
                            hidden: false,
                            setName: 'efficientHomes',
                            label: 'Efficient neighbours',
                            unit: 'kWh',
                            badge: {
                                img: false,
                                color: '#00AEB4',
                            },
                            baseColor: '#00AEB4',
                        },
                    },
                    chart: {
                        labelColor: '#85929E',
                        labelSize: 12,
                        opacity1: 60,
                        opacity2: 100,
                        type: 'bar',
                    },
                },
                gas: {
                    dataInfo: {
                        usage: {
                            hidden: false,
                            setName: 'usage',
                            name: 'Gas',
                            label: 'Gas use',
                            unit: 'm3',
                            badge: {
                                img: false,
                                color: '#FF8C00',
                            },
                            baseColor: '#FF8C00',
                        },
                        outdoorTemp: {
                            hidden: false,
                            unit: '°C',
                            setName: 'outdoorTemp',
                            label: 'Temperature',
                            badge: {
                                img: true,
                                url: 'public/img/temp.png',
                                color: '#e74c3c',
                            },
                            baseColor: '#E74C3C',
                        },
                        temperature: {
                            hidden: true,
                            unit: '°C',
                            setName: 'temperature',
                            label: 'Temperature',
                            badge: {
                                img: true,
                                url: 'public/img/temp.png',
                            },
                            baseColor: '#E74C3C',
                        },
                        rainfall: {
                            hidden: true,
                            unit: 'MM',
                            setName: 'rainfall',
                            label: 'Rainfall',
                            badge: {
                                img: true,
                                url: 'public/img/rain.png',
                            },
                            baseColor: '#F39C12',
                        },
                        averageHomes: {
                            hidden: false,
                            setName: 'averageHomes',
                            label: 'Average neighbours',
                            unit: 'm3',
                            badge: {
                                img: false,
                                color: '#FFA03A',
                            },
                            baseColor: '#FFA03A',
                        },
                        efficientHomes: {
                            hidden: false,
                            setName: 'efficientHomes',
                            label: 'Efficient neighbours',
                            unit: 'm3',
                            badge: {
                                img: false,
                                color: '#00AEB4',
                            },
                            baseColor: '#00AEB4',
                        },
                        energyCosts: {
                            hidden: false,
                            setName: 'energyCosts',
                            label: 'acpbase::consumption.energyCosts.label',
                            unit: '£',
                            badge: {
                                img: false,
                                color: '#00AEB4',
                            },
                            baseColor: '#00AEB4',
                        },
                    },
                    chart: {
                        labelColor: '#85929E',
                        labelSize: 12,
                        opacity1: 60,
                        opacity2: 100,
                        type: 'bar',
                    },
                },
            },
        };

        // @ts-ignore
        const chartManager = new ChartManager(consConfig);

        const dynamicChartManger =
            // @ts-ignore
            new DynamicWidgetWrapper(
                chartManager,
                '/api/proxy/consumption-history',
                // @ts-ignore
                new HttpMock(consumptionApiData)
            );
        dynamicChartManger.render('consumption_chart_widget');
    }
}
