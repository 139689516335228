import Base from '@/mixins/base';
import vue from 'vue';
import WithRender from './cookie-banner-widget.html';
import { default as Component } from 'vue-class-component';

@WithRender
@Component({})
export class CookieBannerWidget extends Base {
    get showCookieAlert(): boolean {
        this.$store.dispatch('cookie/loadCookiesAlertStatus');
        return this.$store.state.cookie.showCookieAlert;
    }

    hideCookieAlert(): void {
        this.$store.dispatch('cookie/setCookiesAlertStatus', false);
    }
}
