<div class="m-subheader">
    <div class="m-subheader-content">
        <template v-if="displayElement('one-line-without-contract')">
            <div>{{ $t('widget.welcome') }}</div>
        </template>
        <template v-else>
            <template v-if="displayElement('show-contact')">
                <div class="m-subheader-content--left">
                    <div>{{ $t('widget.welcome') }}</div>
                    <div>
                        <template v-if="!displayElement('hide-hash-contractId')"
                            >#</template
                        >
                        {{ contract.contractId }} - {{ showContractTitle ?
                        contract.title : nonCommodityProductTitle }}
                    </div>
                </div>
                <div class="m-subheader-content--right">
                    <div>{{ $t('widget.welcome.text') }}</div>
                    <div>{{ $t('widget.welcome.phone') }}</div>
                    <div>{{ $t('widget.welcome.email') }}</div>
                </div>
            </template>
            <template
                v-else-if="displayElement('show-contract') && !contractId"
            >
                <div class="widget-welcome">{{ $t('widget.welcome') }}</div>
                <div>
                    {{ $t('widget.welcome.contractCustomerPhrase') }}:

                    <template v-if="!displayElement('hide-hash-contractId')"
                        >#</template
                    >
                    {{customerDetails.id}} - {{customerDetails.salutation}}
                    {{customerDetails.firstname}} {{customerDetails.surname}}
                </div>
            </template>
            <template v-else-if="displayElement('show-contract') && contractId">
                <div class="widget-welcome">{{ $t('widget.welcome') }}</div>
                <div>
                    {{ displayElement('show-contract-phrase') &&
                    $t('widget.welcome.contractPhrase') }}
                    <template v-if="!displayElement('hide-hash-contractId')"
                        >#</template
                    >
                    {{ contract.contractId }} - {{ productTitle }}
                </div>
            </template>
            <template v-else>
                <div
                    class="widget-welcome"
                    v-if="displayElement('show-personalized-message')"
                >
                    {{ $t('widget.welcome.prefix') }} {{
                    customerDetails.firstname }} {{ $t('widget.welcome.postfix')
                    }}
                </div>
                <div class="widget-welcome" v-else>
                    {{ $t('widget.welcome') }}
                </div>
                <div v-if="contractId && !this.customerDetails.isFleetCustomer">
                    <template v-if="displayElement('show-contract-phrase')"
                        >{{ $t('widget.welcome.contractPhrase') }}</template
                    >
                    <template v-if="!displayElement('hide-hash-contractId')"
                        >#</template
                    >
                    {{ contract.contractId }} - {{ showContractTitle ?
                    processTitle(contract.title) : nonCommodityProductTitle }}
                </div>
                <div v-else># {{customerDetails.id}} - THG-Quote</div>
            </template>
        </template>
    </div>
</div>
