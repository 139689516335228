// -----------------------------
//    Customer Data structure
// -----------------------------
import Vue from 'vue';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import * as Sentry from '@sentry/browser';

import moment from 'moment';

import Settings from '@/interfaces/Settings';

const settingsProvider = IocContainer.get<Settings>(SERVICES.SETTINGS);

// Data Structure
const state = {
    allowImplausibleMeterReads: false,
    contractCancellation: false,
    enabledCancellationTypes: [],
    cancellationReasons: {
        cancelDate: [],
        deadlineDate: [],
        extraOrdinaryDate: [],
        MoveAtDate: [],
    },
    contractMove: false,
    minimumAgeRequired: null,
    salesCampaign: null,
    salesWidgetLink: null,
    salesJourneyLink: null,
    daysBetweenInvoices: null,
    readingForceMovein: null,
    readingForceDateSelect: null,
    activateInvoiceSimulation: null,
    activateInvoiceTriggering: null,
    tariffChange: false,
    tariffChangePossibleUntil: null,
    tariffChangeSelectedDate: null,
    tariffOverview: false,
    enableTicketSystem: false,
    correctCustomerDataGridActiv: false,
    depositOverview: false,
    forceUserToValidateData: false,
    formSettings: {
        formBillingAddress: { disabled: [], hidden: [] },
        formCustomerDetails: { disabled: [], hidden: [] },
        formPaymentData: { disabled: [], hidden: [] },
    },
    requireCounterNumberValidation: false,
    adsBonusActive: false,
    tariffChangeDefaultAddon: null,
    changePasswordEnabled: false,
    reportReadingWithoutLogin: false,
    activeFromChangeBankingDetails: false,
    implausibleReasonExplanationOptions: null,
    allowImplausibleReasonExplanationOther: false,
    surveyDepositChangeWidget: null,
    surveyMeterSubmitWidget: null,
    validateBillingAddressEnetActive: false,
    showLogoutSuccessfulMessage: false,
    activeFromDeposit: false,
    activeAlternativeDayOfMonthDeposit: false,
    validateCustomerAddressEnetActive: false,
    synchronizeWithBilling: false,
    revocationProcessActive: false,
    idpActive: false,
    idpLoginUrl: null,
    idpRegisterUrl: null,
    idpPasswordUrl: null,
    idpLogoutUrl: null,
    invoiceWidgetShowUnsettledValue: null,
    customerPostboxEnabled: false,
    doubleOptInAutomaticallyConfirmOptIn: null,
    isAuthPlusActive: false,
    authPlusBlacklistPasswords: null,
    authPlusCountLowercaseCharacters: null,
    authPlusCountNumbers: null,
    authPlusCountSpecialCharacters: null,
    authPlusCountUppercaseCharacters: null,
    isAuthPlusDenyUsernamePasswords: false,
    authPlusListSpecialCharacters: null,
    authPlusMaximumAgePassword: null,
    authPlusPasswordLength: null,
    authPlusEnableRecaptcha: false,
    authPlusAttemptsForRecaptcha: null,
    isAuthPlusTwoFactorAuthenticationActive: false,
    isAuthPlusOneTimePassword: false,
    isAuthPlusOneTimePasswordEnabledAlternative: false,
    authPlusOneTimePasswordIbanPromptedPositions: [],
    authPlusOneTimePasswordIbanDisplayedPositions: [],
    enableUsageHistoryChart: false,
    enableUsageHistoryReferenceValues: false,
    enableCaptchaForLogin: false,
    enableCaptchaForRegistration: false,
    invisibleCaptcha: false,
    captchaSiteKey: null,
    maintenanceShowLogo: false,
    maintenanceStart: null,
    maintenanceEnd: null,
    maintenanceText: null,
    isMaintenanceMode: false,
    isDemo: false,
    bonusRecommendationProgramEnabled: false,
    callToActionBannerActivated: false,
    callToActionBannerPriceCondition: null,
    callToActionFaIcon: null,
    optInBannerActive: false,
    optInAllowDeclineButton: false,
    optInHideIfOptOutAvailable: false,
    optOut: false,
    billingTaxNumberCategory: null,
    billingTaxNumberField: null,
    billingTaxIdCategory: null,
    billingTaxIdField: null,
    tariffRecommendationWidgetCreated: false,
    dashboard: {
        statusBarWidget: null,
        customerDataCorrectionWidget: null,
        customerServiceWidget: null,
        depositWidget: null,
        depositWidgetAction: null,
        accountBalanceWidget: null,
        accountBalanceWidgetAction: null,
        meterWidget: null,
        meterDurationAfterCancel: null,
        optInWidget: null,
        tariffWidget: null,
        meterHistoryWidget: null,
        invoiceWidget: null,
        changeWidget: null,
        postBoxWidget: null,
    },
    donationCampaignPeriodEnd: null,
    donationCampaignPeriodStart: null,
    donationExtendedDataValueKey: null,
    donationExtendedDataParentKey: null,
    accountTransactionNotice: null,
    registrationIbanNumbersToCheck: null,
    registrationIbanNumbersToSkip: null,
};

// Outside methods for communication with data.
const actions = {
    async load({ commit }): Promise<void> {
        await settingsProvider.load().then(
            (response) => {
                if (response.data) {
                    commit('SET_SETTINGS', response.data);
                }
            },
            (error) => {
                Sentry.captureException(new Error(error));
            }
        );
    },
    async idp({ commit }): Promise<void> {
        await settingsProvider.idp().then(
            (response) => {
                if (response.data) {
                    commit('SET_IDP_SETTINGS', response.data);
                }
            },
            (error) => {
                Sentry.captureException(new Error(error));
            }
        );
    },
};

function getCancellationReasons<TData>(
    data: TData,
    labelsIdx: string,
    keysIdx: string
) {
    if (!data[labelsIdx] || !data[keysIdx]) return [];

    const labels = data[labelsIdx].split(','),
        keys = data[keysIdx].split(',');
    return labels.map(
        (label: string, idx: number): Record<'label' | 'value', string> => {
            return {
                label: label.trim(),
                value: keys[idx].trim(),
            };
        }
    );
}

// Logic that change data
const mutations = {
    SET_IDP_SETTINGS(localState, data) {
        localState.idpActive = data.idpActive ? data.idpActive === '1' : false;
        localState.idpLoginUrl = data.idpLoginUrl ? data.idpLoginUrl : null;
        localState.idpRegisterUrl = data.idpRegisterUrl
            ? data.idpRegisterUrl
            : null;
        localState.idpPasswordUrl = data.idpPasswordUrl
            ? data.idpPasswordUrl
            : null;
        localState.idpLogoutUrl = data.idpLogoutUrl ? data.idpLogoutUrl : null;
    },

    SET_SETTINGS(localState, data) {
        localState.allowImplausibleMeterReads =
            data.allowImplausibleMeterReads === '1';

        localState.contractCancellation = data.contractCancellation
            ? data.contractCancellation === '1'
            : false;

        if (data.enabledCancellationTypes)
            localState.enabledCancellationTypes = data.enabledCancellationTypes;

        localState.cancellationReasons.cancelDate = getCancellationReasons(
            data,
            'contractCancelDateCancelReasonLabels',
            'contractCancelDateCancelReasonKeys'
        );

        localState.cancellationReasons.deadlineDate = getCancellationReasons(
            data,
            'contractDeadlineDateCancelReasonLabels',
            'contractDeadlineDateCancelReasonKeys'
        );

        localState.cancellationReasons.extraOrdinaryDate =
            getCancellationReasons(
                data,
                'contractExtraOrdinaryDateCancelReasonLabels',
                'contractExtraOrdinaryDateCancelReasonKeys'
            );

        localState.cancellationReasons.MoveAtDate = getCancellationReasons(
            data,
            'contractMoveAtDateCancelReasonLabels',
            'contractMoveAtDateCancelReasonKeys'
        );

        localState.contractMove = data.contractMove
            ? data.contractMove === '1'
            : false;
        localState.minimumAgeRequired = data.minimumAgeRequired
            ? data.minimumAgeRequired
            : null;
        localState.salesCampaign = data.salesCampaign
            ? data.salesCampaign
            : null;
        localState.salesWidgetLink = data.salesWidgetLink
            ? data.salesWidgetLink
            : null;
        localState.salesJourneyLink = data.salesJourneyLink
            ? data.salesJourneyLink
            : null;
        localState.daysBetweenInvoices = data.daysBetweenInvoices
            ? data.daysBetweenInvoices
            : null;
        localState.readingForceMovein = data.readingForceMovein
            ? data.readingForceMovein === '1'
            : false;
        localState.readingForceDateSelect = data.readingForceDateSelect
            ? data.readingForceDateSelect === '1'
            : true;
        localState.activateInvoiceTriggering = data.activateInvoiceTriggering
            ? data.activateInvoiceTriggering === '1'
            : false;
        localState.activateInvoiceSimulation = data.activateInvoiceSimulation
            ? data.activateInvoiceSimulation === '1'
            : false;
        localState.tariffChange = data.tariffChange
            ? data.tariffChange === '1'
            : false;
        localState.tariffChangePossibleUntil = data.tariffChangePossibleUntil
            ? moment(data.tariffChangePossibleUntil).format('DD.MM.YYYY')
            : null;
        localState.tariffChangeSelectedDate = data.tariffChangeSelectedDate
            ? moment(data.tariffChangeSelectedDate).format('DD.MM.YYYY')
            : null;
        localState.tariffOverview = data.tariffOverview
            ? data.tariffOverview === '1'
            : false;
        localState.enableTicketSystem = data.enableTicketSystem
            ? data.enableTicketSystem === '1'
            : false;
        localState.correctCustomerDataGridActiv =
            data.correctCustomerDataGridActiv
                ? data.correctCustomerDataGridActiv === '1'
                : false;
        localState.depositOverview = data.depositOverview
            ? data.depositOverview === '1'
            : false;
        localState.forceUserToValidateData = data.forceUserToValidateData
            ? data.forceUserToValidateData === '1'
            : false;
        localState.requireCounterNumberValidation =
            data.requireCounterNumberValidation
                ? data.requireCounterNumberValidation === '1'
                : false;
        localState.adsBonusActive = data.adsBonusActive
            ? data.adsBonusActive === '1'
            : false;
        localState.tariffChangeDefaultAddon = data.tariffChangeDefaultAddon
            ? data.tariffChangeDefaultAddon
            : null;
        localState.changePasswordEnabled = data.changePasswordEnabled
            ? data.changePasswordEnabled === '1'
            : false;
        localState.reportReadingWithoutLogin = data.reportReadingWithoutLogin
            ? data.reportReadingWithoutLogin === '1'
            : false;
        localState.activeFromChangeBankingDetails =
            data.activeFromChangeBankingDetails
                ? data.activeFromChangeBankingDetails === '1'
                : false;
        localState.implausibleReasonExplanationOptions =
            data.implausibleReasonExplanationOptions
                ? data.implausibleReasonExplanationOptions
                : '';
        localState.allowImplausibleReasonExplanationOther =
            data.allowImplausibleReasonExplanationOther
                ? data.allowImplausibleReasonExplanationOther === '1'
                : false;
        localState.surveyDepositChangeWidget = data.surveyDepositChangeWidget
            ? data.surveyDepositChangeWidget
            : null;
        localState.surveyMeterSubmitWidget = data.surveyMeterSubmitWidget
            ? data.surveyMeterSubmitWidget
            : null;
        localState.validateBillingAddressEnetActive =
            data.validateBillingAddressEnetActive
                ? data.validateBillingAddressEnetActive === '1'
                : false;
        localState.validateCustomerAddressEnetActive =
            data.validateCustomerAddressEnetActive
                ? data.validateCustomerAddressEnetActive === '1'
                : false;
        localState.synchronizeWithBilling = data.synchronizeWithBilling
            ? data.synchronizeWithBilling === '1'
            : false;
        localState.showLogoutSuccessfulMessage =
            data.showLogoutSuccessfulMessage
                ? data.showLogoutSuccessfulMessage === '1'
                : false;
        localState.activeFromDeposit = data.activeFromDeposit
            ? data.activeFromDeposit === '1'
            : false;
        localState.activeAlternativeDayOfMonthDeposit =
            data.activeAlternativeDayOfMonthDeposit
                ? data.activeAlternativeDayOfMonthDeposit === '1'
                : false;
        localState.revocationProcessActive = data.revocationProcessActive
            ? data.revocationProcessActive === '1'
            : false;
        localState.idpActive = data.idpActive ? data.idpActive === '1' : false;
        localState.idpLoginUrl = data.idpLoginUrl ? data.idpLoginUrl : null;
        localState.idpRegisterUrl = data.idpRegisterUrl
            ? data.idpRegisterUrl
            : null;
        localState.idpPasswordUrl = data.idpPasswordUrl
            ? data.idpPasswordUrl
            : null;
        localState.idpLogoutUrl = data.idpLogoutUrl ? data.idpLogoutUrl : null;
        localState.customerPostboxEnabled = data.customerPostboxEnabled
            ? data.customerPostboxEnabled
            : false;
        localState.displayFuturePaymentDetailsActive =
            data.displayFuturePaymentDetailsActive
                ? data.displayFuturePaymentDetailsActive === '1'
                : false;
        localState.doubleOptInAutomaticallyConfirmOptIn =
            data.doubleOptInAutomaticallyConfirmOptIn
                ? data.doubleOptInAutomaticallyConfirmOptIn === '1'
                : false;
        localState.isAuthPlusActive = data.authPlusActive
            ? data.authPlusActive === '1'
            : false;
        localState.authPlusBlacklistPasswords = data.authPlusBlacklistPasswords
            ? data.authPlusBlacklistPasswords
            : null;
        localState.authPlusCountLowercaseCharacters =
            data.authPlusCountLowercaseCharacters
                ? parseInt(data.authPlusCountLowercaseCharacters, 10)
                : null;
        localState.authPlusCountNumbers = data.authPlusCountNumbers
            ? parseInt(data.authPlusCountNumbers, 10)
            : null;
        localState.authPlusCountSpecialCharacters =
            data.authPlusCountSpecialCharacters
                ? parseInt(data.authPlusCountSpecialCharacters, 10)
                : null;
        localState.authPlusCountUppercaseCharacters =
            data.authPlusCountUppercaseCharacters
                ? parseInt(data.authPlusCountUppercaseCharacters, 10)
                : null;
        localState.isAuthPlusDenyUsernamePasswords =
            data.authPlusDenyUsernamePasswords
                ? data.authPlusDenyUsernamePasswords === '1'
                : false;
        localState.authPlusListSpecialCharacters =
            data.authPlusListSpecialCharacters
                ? data.authPlusListSpecialCharacters
                : null;
        localState.authPlusMaximumAgePassword = data.authPlusMaximumAgePassword
            ? data.authPlusMaximumAgePassword
            : null;
        localState.authPlusPasswordLength = data.authPlusPasswordLength
            ? parseInt(data.authPlusPasswordLength, 10)
            : null;
        localState.authPlusEnableRecaptcha = data.authPlusEnableRecaptcha
            ? data.authPlusEnableRecaptcha === '1'
            : null;
        localState.authPlusAttemptsForRecaptcha =
            data.authPlusAttemptsForRecaptcha
                ? parseInt(data.authPlusAttemptsForRecaptcha, 10)
                : null;
        localState.isAuthPlusTwoFactorAuthenticationActive =
            data.authPlusTwoFactorAuthenticationActive
                ? data.authPlusTwoFactorAuthenticationActive === '1'
                : false;
        localState.isAuthPlusOneTimePassword = data.authPlusOneTimePassword
            ? data.authPlusOneTimePassword === '1'
            : false;
        localState.isAuthPlusOneTimePasswordEnabledAlternative =
            data.authPlusOneTimePasswordEnableAlternative &&
            data.authPlusOneTimePasswordEnableAlternative == '1';

        localState.authPlusOneTimePasswordIbanPromptedPositions =
            data.authPlusOneTimePasswordAlternativeIbanPromptedPositions
                ? data.authPlusOneTimePasswordAlternativeIbanPromptedPositions.split(
                      ','
                  )
                : [];

        localState.authPlusOneTimePasswordIbanDisplayedPositions =
            data.authPlusOneTimePasswordAlternativeIbanDisplayedPositions
                ? data.authPlusOneTimePasswordAlternativeIbanDisplayedPositions.split(
                      ','
                  )
                : [];

        localState.enableCaptchaForLogin = data.enableCaptchaForLogin
            ? data.enableCaptchaForLogin === '1'
            : false;
        localState.enableCaptchaForRegistration =
            data.enableCaptchaForRegistration
                ? data.enableCaptchaForRegistration === '1'
                : false;
        localState.enableUsageHistoryChart = data.enableUsageHistoryChart
            ? data.enableUsageHistoryChart === '1'
            : false;
        localState.enableUsageHistoryReferenceValues =
            data.enableUsageHistoryReferenceValues
                ? data.enableUsageHistoryReferenceValues === '1'
                : false;
        localState.invisibleCaptcha = data.invisibleCaptcha
            ? data.invisibleCaptcha === '1'
            : false;
        localState.invoiceWidgetShowUnsettledValue =
            data.invoiceWidgetShowUnsettledValue
                ? data.invoiceWidgetShowUnsettledValue === '1'
                : false;
        localState.captchaSiteKey = data.captchaSiteKey
            ? data.captchaSiteKey
            : null;
        localState.maintenanceShowLogo = data.maintenanceShowLogo
            ? data.maintenanceShowLogo === '1'
            : false;
        localState.maintenanceStart = data.maintenanceStart
            ? data.maintenanceStart
            : null;
        localState.maintenanceEnd = data.maintenanceEnd
            ? data.maintenanceEnd
            : null;
        localState.maintenanceText = data.maintenanceText
            ? data.maintenanceText
            : null;
        localState.isDemo = data.isDemo ? data.isDemo === '1' : false;
        // true or false
        localState.isMaintenanceMode =
            data.maintenanceStart && data.maintenanceEnd
                ? moment(moment().format()).isBetween(
                      data.maintenanceStart,
                      data.maintenanceEnd
                  )
                : false;
        localState.nonCommodityFulfillmentEnabled =
            data.nonCommodityFulfillmentEnabled === '1';
        localState.formSettings = {
            formBillingData: {
                disabled: data.formBillingAddressDisabled,
                hidden: data.formBillingAddressHidden,
            },
            formCustomerData: {
                disabled: data.formCustomerDetailsDisabled,
                hidden: data.formCustomerDetailsHidden,
            },
            formPaymentData: {
                disabled: data.formPaymentDataDisabled,
                hidden: data.formPaymentDataHidden,
            },
        };
        localState.callToActionBannerActivated =
            data.callToActionBannerActivated
                ? data.callToActionBannerActivated === '1'
                : false;
        localState.callToActionBannerPriceCondition =
            data.callToActionBannerPriceCondition
                ? data.callToActionBannerPriceCondition
                : null;
        localState.callToActionFaIcon = data.callToActionFaIcon
            ? data.callToActionFaIcon
            : null;
        localState.callToActionFaIcon2 = data.callToActionFaIcon2
            ? data.callToActionFaIcon2
            : null;
        localState.callToActionFaIcon3 = data.callToActionFaIcon3
            ? data.callToActionFaIcon3
            : null;
        localState.callToActionFaIcon4 = data.callToActionFaIcon4
            ? data.callToActionFaIcon4
            : null;
        localState.callToActionFaIcon5 = data.callToActionFaIcon5
            ? data.callToActionFaIcon5
            : null;
        localState.bonusRecommendationProgramEnabled =
            data.bonusRecommendationProgramEnabled
                ? data.bonusRecommendationProgramEnabled === '1'
                : false;
        localState.activeFromChangeBankingDetailsInOutActive =
            data.activeFromChangeBankingDetailsInOutActive == '1';
        localState.billingTaxNumberCategory = data.billingTaxNumberCategory
            ? data.billingTaxNumberCategory
            : null;
        localState.billingTaxNumberField = data.billingTaxNumberField
            ? data.billingTaxNumberField
            : null;
        localState.billingTaxIdCategory = data.billingTaxIdCategory
            ? data.billingTaxIdCategory
            : null;
        localState.billingTaxIdField = data.billingTaxIdField
            ? data.billingTaxIdField
            : null;

        localState.dashboard.statusBarWidget = data.dashboardStatusBarWidget
            ? data.dashboardStatusBarWidget
            : null;
        localState.dashboard.customerDataCorrectionWidget =
            data.dashboardCustomerDataCorrectionWidget
                ? data.dashboardCustomerDataCorrectionWidget
                : null;
        localState.dashboard.customerServiceWidget =
            data.dashboardCustomerServiceWidget
                ? data.dashboardCustomerServiceWidget
                : null;
        localState.dashboard.depositWidget = data.dashboardDepositWidget
            ? data.dashboardDepositWidget
            : null;
        localState.dashboard.depositWidgetAction =
            data.dashboardDepositWidgetAction
                ? data.dashboardDepositWidgetAction
                : null;
        localState.dashboard.accountBalanceWidget =
            data.dashboardAccountBalanceWidget
                ? data.dashboardAccountBalanceWidget
                : null;
        localState.dashboard.accountBalanceWidgetAction =
            data.dashboardAccountBalanceWidgetAction
                ? data.dashboardAccountBalanceWidgetAction
                : null;
        localState.dashboard.meterWidget = data.dashboardMeterWidget
            ? data.dashboardMeterWidget
            : null;
        localState.dashboard.meterWidgetAction = data.dashboardMeterWidgetAction
            ? data.dashboardMeterWidgetAction
            : null;
        localState.dashboard.meterDurationAfterCancel =
            data.dashboardMeterDurationAfterCancel
                ? data.dashboardMeterDurationAfterCancel
                : null;
        localState.dashboard.optInWidget = data.dashboardOptInWidget
            ? data.dashboardOptInWidget
            : null;
        localState.dashboard.tariffWidget = data.dashboardTariffWidget
            ? data.dashboardTariffWidget
            : null;
        localState.dashboard.meterHistoryWidget =
            data.dashboardMeterHistoryWidget
                ? data.dashboardMeterHistoryWidget
                : null;
        localState.dashboard.invoiceWidget = data.dashboardInvoiceWidget
            ? data.dashboardInvoiceWidget
            : null;
        localState.dashboard.changeWidget = data.dashboardChangeWidget
            ? data.dashboardChangeWidget
            : null;
        localState.dashboard.postBoxWidget = data.dashboardPostBoxWidget
            ? data.dashboardPostBoxWidget
            : null;
        localState.dashboard.tariffDetailsWidget =
            data.dashboardTariffDetailsWidget
                ? data.dashboardTariffDetailsWidget
                : null;
        localState.optInBannerActive = data.optInBannerActive
            ? data.optInBannerActive === '1'
            : false;
        localState.optInAllowDeclineButton = data.optInAllowDeclineButton
            ? data.optInAllowDeclineButton === '1'
            : false;
        localState.optInHideIfOptOutAvailable = data.optInHideIfOptOutAvailable
            ? data.optInHideIfOptOutAvailable === '1'
            : false;

        localState.donationExtendedDataParentKey =
            data.donationExtendedDataParentKey
                ? data.donationExtendedDataParentKey
                : null;

        localState.donationExtendedDataValueKey =
            data.donationExtendedDataValueKey
                ? data.donationExtendedDataValueKey
                : null;

        localState.donationCampaignPeriodStart =
            data.donationCampaignPeriodStart
                ? data.donationCampaignPeriodStart
                : null;

        localState.donationCampaignPeriodEnd = data.donationCampaignPeriodEnd
            ? data.donationCampaignPeriodEnd
            : null;

        localState.accountTransactionNotice = data.accountTransactionNotice
            ? data.accountTransactionNotice
            : null;

        localState.registrationIbanNumbersToSkip =
            data.registrationIbanNumbersToSkip
                ? data.registrationIbanNumbersToSkip
                : null;

        localState.registrationIbanNumbersToCheck =
            data.registrationIbanNumbersToCheck
                ? data.registrationIbanNumbersToCheck
                : null;
    },
    SET_SETTING(localState, data) {
        Vue.set(localState, data.key, data.value);
        // localState[data.key] = data.value;
    },
};

// Get Data transformation (like computed)
const getters = {
    getState: (localState) => () => {
        return localState;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
