import Component from 'vue-class-component';
import { Dashboard } from '@/components/pages/dashboard/dashboard';
import { Watch } from 'vue-property-decorator';
import WithRender from './bsw.html';

@WithRender
@Component
export class DashboardBsw extends Dashboard {
    get isMobile(): boolean {
        return /iPhone|iPad|Android/.test(navigator.userAgent);
    }

    get isMobileOnly(): boolean {
        return /iPhone|Android/.test(navigator.userAgent);
    }
    constructor() {
        super();

        this.usageHistoryReferenceValues = [1200, 1800, 2200, 2500, 2800];
    }

    get showTariffChangeForce(): boolean {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ) &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length > 0
        ) {
            return true;
        }
        return false;
    }

    protected setupCustomerDashboard(): void {
        this.$store.dispatch('contracts/setSortContractsById', true);
    }

    @Watch('invoiceCount')
    @Watch('invoicesLoaded')
    protected hideUsageHistoryIfNoInvoices(): void {
        this.hideMeterHistoryWidget = this.showUsageHistoryWidget =
            this.isUsageHistoryWidgetEnabled &&
            this.invoicesLoaded &&
            this.invoiceCount > 0;
    }

    @Watch('settings.donationCampaignPeriodStart')
    @Watch('$store.state.contracts.contractId')
    @Watch('currentTariff')
    @Watch('currentTariff.contractDetailsLoading')
    @Watch('contract')
    protected handleDonationWidgetOpen(): void {
        const isAllowed = this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.donationAllowed;

        const isInDate =
            new Date(this.$store.state.settings.donationCampaignPeriodStart) <=
                new Date() &&
            new Date(this.$store.state.settings.donationCampaignPeriodEnd) >=
                new Date();

        if (isAllowed && isInDate) {
            this.closeGroup4();
            this.toggleGroup4('showDonation');
        }
    }
}
