import { default as Component, mixins } from 'vue-class-component';
import WithRender from './bsw.html';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Format from '@/interfaces/Format';
import { TariffWidget } from '@/components/widgets/tariff-widget/tariff-widget';
import { ChangeWidget } from '@/components/widgets/change-widget/change-widget';
import Contracts from '@/interfaces/Contracts';
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
import * as Sentry from '@sentry/browser';

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
    },
})
export class TariffWidgetBSW extends mixins(ChangeWidget, TariffWidget) {
    protected created() {
        if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
            this.$store.dispatch(
                'tariff/bswSavedCo2',
                this.$store.state.contracts.contractId
            );
        }
    }

    get savedCo2Usage() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).savedCo2Usage;
    }

    get donationAllowed() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.donationAllowed;
    }

    protected mounted() {
        contractsProvider
            .isDonationAllowed({
                contractId: this.contractId,
            })
            .then((res) => {
                if (res.data.success && res.data.allowed) {
                    this.$store.dispatch('tariff/allowDonation', {
                        id: this.contractId,
                        value: true,
                    });
                }
            })
            .catch((err) => {
                if (err != 'Operation canceled because of duplicate request.') {
                    Sentry.captureException(new Error(err));
                }
            });
    }
}
