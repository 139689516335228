<div class="col-12 tariff-change-widget">
    <div class="m-portlet">
        <div class="m-portlet__body">
            <div class="row">
                <div class="col">
                    <i class="fas fa-file-export tariff-change-widget-icon"></i>
                    <span class="tariff-change-title static-title">
                        {{ $t('widget.tc.title') }} {{
                        $store.state.settings.tariffChangeSelectedDate }}
                    </span>
                    <div class="mobile-modal__wrap">
                        <div class="row mt-3">
                            <div
                                class="col-md-6 col-xl-4 col-12 tariff-change-left"
                            >
                                <div class="currentTariff-title">
                                    <span
                                        data-test="tariff-widget-product-title"
                                        v-if="!productImage"
                                        >{{ productTitle }}</span
                                    >
                                    <img
                                        data-test="tariff-widget-product-img"
                                        class="img-max-width-100"
                                        v-if="productImage"
                                        :src="productImage"
                                        style="
                                            width: 14em;
                                            margin-top: 0.4em;
                                            margin-bottom: 0.4em;
                                        "
                                    />
                                </div>
                                <div class="row mt-3 justify-content-start">
                                    <div class="green-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.dc.title')
                                                }}:</span
                                            >
                                            {{ tariff.deposit | euro }} {{
                                            $t('euro') }}/{{
                                            $t('widget.tc.month')}}<sup>*</sup>
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.tc.total.costs')
                                                }}:</span
                                            >
                                            {{ tariff.totalBasePriceBonusBrutto
                                            | euroFull }}
                                            {{$t('euro')}}/{{$t('year')}}<sup
                                                >*</sup
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="green-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.to.basePrice')
                                                }}:</span
                                            >
                                            {{ tariff.basePriceBruttoMonth |
                                            euro}}
                                            {{$t('euro')}}/{{$t('widget.tc.month')}}<sup
                                                >*</sup
                                            >
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{ $t('widget.to.workingPrice')
                                                }}:</span
                                            >
                                            {{ tariff.workingPriceBrutto |
                                            euro}}
                                            {{$t('unit.centperelectricity')}}<sup
                                                >*</sup
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="green-circle pe-0 me-3 w-auto">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <div>
                                            <span class="fw-normal"
                                                >{{
                                                $t('widget.tc.fixedPrice.until')}}:</span
                                            >
                                            {{ tariff.fixedPriceDate | date
                                            }}<sup>**</sup>
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{
                                                $t('widget.tc.minimal.duration')
                                                }}:</span
                                            >
                                            {{
                                            $tc(tariff.contractInitialDurationType,
                                            parseInt(tariff.contractInitialDuration))}}
                                            (bis {{ minimalDurationValue }})
                                        </div>
                                        <div>
                                            <span class="fw-normal"
                                                >{{$t('widget.tc.cancellation.period')}}:</span
                                            >
                                            {{ $tc('widget.tc.'
                                            +tariff.cancellationPeriodType,
                                            tariff.cancellationPeriod) }}
                                            {{$t('widget.tc.cancellation.period.postfix')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-8 col-12">
                                <div>
                                    Ich erteile dem Lieferanten den Auftrag,
                                    meinen gesamten Bedarf an elektrischer
                                    Energie an die genannte Entnahmestelle zu
                                    liefern. Der Lieferant kann dem Kunden über
                                    die Postbox des Kundenportals
                                    rechtserhebliche Erklärungen zur Begründung,
                                    Durchführung, Änderung oder Beendigung
                                    dieses Lieferverhältnisses (z.B.
                                    Mitteilungen über den Vertrags- oder
                                    Lieferbeginn etc.) zusenden. Änderungen
                                    Ihrer vorgenannten Kontaktdaten sind bitte
                                    unverzüglich in Textform oder online über
                                    das Kundenportal mitzuteilen. Ihr
                                    Ökostromlieferant ist die Berliner
                                    Stadtwerke EnergiePartner GmbH, Am
                                    Köllnischen Park 1, 10179 Berlin,
                                    Geschäftsführung: Andreas Schmitz, AG
                                    Charlottenburg HRB 194357 B.
                                </div>
                                <div class="form-check mt-4">
                                    <label
                                        :class="[
                                            'form-check-label',
                                            displayElement('branded-checkbox')
                                                ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                : '',
                                            tariff.confirmedError ? 'is-invalid text-danger' : ''
                                        ]"
                                        :for="'cancellation-confirmation-' + tariff.productCode"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :id="'cancellation-confirmation-' + tariff.productCode"
                                            @change="updateCheckbox(tariff)"
                                        />
                                        {{ $t('widget.tc.confirmation.1') }}
                                        <a
                                            href="https://berlinerstadtwerke.de/wp-content/uploads/dokumente/Berliner-Stadtwerke_AGB.pdf"
                                            target="_blank"
                                            >{{
                                            $t('widget.tc.confirmation.2')}}</a
                                        >
                                        {{ $t('widget.tc.confirmation.3')}}
                                        <a
                                            href="https://berlinerstadtwerke.de/app/dokumente/Berliner-Stadtwerke_Widerrufsformular.pdf"
                                            target="_blank"
                                            >{{ $t('widget.tc.confirmation.4')
                                            }}</a
                                        >{{ $t('widget.tc.confirmation.5') }}
                                        <a
                                            href="https://berlinerstadtwerke.de/app/dokumente/Berliner-Stadtwerke_Information-zur-Verarbeitung-personenbezogener-Daten.pdf"
                                            >{{ $t('widget.tc.confirmation.6')
                                            }}</a
                                        >{{ $t('widget.tc.confirmation.7') }}
                                        <i></i>
                                        <span></span>
                                    </label>
                                </div>
                                <div
                                    class="text-center text-sm-end mt-4 mt-sm-2"
                                >
                                    <button
                                        v-if="(!showSuccessChangeTariff)"
                                        class="btn btn-primary mt-4"
                                        @click="submit(tariff)"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.tc.confirm') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="showSuccessChangeTariff">
                            <div class="alert alert-success mt-2">
                                {{ $t('widget.tc.success.message', {productName:
                                tariff.title, tariffChangeSelectedDate:
                                $store.state.settings.tariffChangeSelectedDate})
                                }}
                            </div>
                        </div>
                        <messages-block
                            :errors="errorInformation"
                        ></messages-block>
                        <div class="row bottom-block">
                            <div>
                                <span
                                    >* Inklusive gesetzlich geschuldeter
                                    Umsatzsteuer</span
                                >
                            </div>
                            <div>
                                <span
                                    >** Ausgenommen von der Energiepreisgarantie
                                    sind Änderungen der EEG-, KWKG- und
                                    abLa-Umlage, der Umlage nach § 19 StromNEV,
                                    der OffshoreNetzumlage, der Strom- und
                                    Umsatzsteuer, der Konzessionsabgaben und der
                                    Netzentgelte sowie Preisänderungen für
                                    künftig wirksam werdende neue Steuern,
                                    Abgaben oder sonstige staatlich veranlasste,
                                    die Beschaffung, Erzeugung, Speicherung,
                                    Netznutzung (Übertragung und Verteilung),
                                    Messstellenbetrieb oder den Verbrauch von
                                    elektrischer Energie betreffende
                                    Mehrbelastungen oder Entlastungen.</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
