<div class="mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div class="row">
                <div class="col">
                    <span
                        v-if="isPreviousProviderCancellation"
                        class="box-title static-title"
                    >
                        {{ $t('widget.cdc.title.previous') }}
                    </span>
                    <span v-else class="box-title static-title">
                        {{ $t('widget.cdc.title.grid') }}
                    </span>
                    <div
                        id="customerDataChange-content"
                        class="mobile-modal__wrap"
                    >
                        <span v-if="isPreviousProviderCancellation">
                            {{ $t('widget.cdc.description.previous') }}
                        </span>
                        <span v-else>
                            {{ $t('widget.cdc.description.grid') }}
                        </span>
                        <br /><br />
                        <div id="customerDataChangeForm">
                            <div class="row">
                                <div class="col-12 form-col">
                                    <span>
                                        <b>{{ $t('widget.cdc.delivery') }}</b>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span
                                        >{{
                                        $t('widget.cdc.delivery.description')
                                        }}</span
                                    >
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-3">
                                    <span>
                                        <label class="m-radio"
                                            ><input
                                                v-model="deliveryRadio"
                                                type="radio"
                                                name="delivery-radio"
                                                value="move"
                                            />
                                            <p class="mb-0">
                                                {{ $t('widget.cdc.move') }}
                                            </p>
                                            <i></i>
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span>
                                        <label class="m-radio"
                                            ><input
                                                v-model="deliveryRadio"
                                                type="radio"
                                                name="delivery-radio"
                                                value="change"
                                            />
                                            <p class="mb-0">
                                                {{
                                                $t('widget.cdc.change.supplier')
                                                }}
                                            </p>
                                            <i></i>
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div class="row" v-if="deliveryRadio">
                                <div class="col-12 form-col">
                                    <span
                                        ><b
                                            >{{ $t('widget.cdc.personalData')
                                            }}</b
                                        ></span
                                    >
                                    <div class="row">
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label for="company">
                                                    {{ $t('widget.cdc.company')
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="company"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.company')"
                                                    v-model="details.company"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="customer_salutation"
                                                    :class="[salutationError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.salutation')
                                                    }}
                                                </label>
                                                <v-select
                                                    class="v-select-cdc"
                                                    :options="salutationOptions"
                                                    @input="salutationInput"
                                                    v-model="selectedSalutation"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="firstname"
                                                    :class="[firstnameError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.firstname')
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="firstname"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.firstname')"
                                                    v-model="details.firstname"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="surname"
                                                    :class="[surnameError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.cdc.surname')
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="surname"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.surname')"
                                                    v-model="details.surname"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label
                                                    :class="[zipError ? 'text-danger' : '']"
                                                    >{{ $t('widget.cdc.zip')
                                                    }}</label
                                                >
                                                <v-select
                                                    class="v-select-cdc"
                                                    :options="cities"
                                                    @search="zipSearch"
                                                    @input="cityInput"
                                                    label="zip"
                                                    v-model="details.zip"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{ $t('widget.cdc.city')
                                                    }}</label
                                                >
                                                <v-select
                                                    class="v-select-cdc"
                                                    :options="cities"
                                                    @search="citySearch"
                                                    @input="cityInput"
                                                    label="text"
                                                    v-model="details.city"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="street"
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.cdc.street')
                                                    }}
                                                </label>
                                                <v-select
                                                    id="street"
                                                    :options="streets"
                                                    v-model="details.street"
                                                    :getOptionLabel="getStreetsOption"
                                                    :getOptionKey="getStreetsOption"
                                                    :disabled="!details.city"
                                                    @input="streetError = false"
                                                    class="v-select-cdc"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="house_number"
                                                    :class="[houseNumberError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.houseNumber')
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="house_number"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.houseNumber')"
                                                    v-model="details.houseNumber"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 form-data">
                                    <br />
                                    <span
                                        ><b
                                            >{{ $t('widget.cdc.deliveryDetails')
                                            }}</b
                                        ></span
                                    >
                                    <div class="row">
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label
                                                    for="counter_number"
                                                    :class="[counterNumberError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.counterNumber')
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="counter_number"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.counterNumber')"
                                                    @input="this.sanitizeCounterNumber"
                                                    v-model="details.counterNumber"
                                                />
                                                <div
                                                    v-if="this.requireCounterNumberValidation"
                                                    class="counter-number-hint"
                                                >
                                                    {{
                                                    $t('widget.mo.meter.number.hint')
                                                    }}:
                                                    <ul>
                                                        <li>
                                                            - {{
                                                            $t('widget.mo.meter.number.hint.format1')
                                                            }}
                                                        </li>
                                                        <li>
                                                            - {{
                                                            $t('widget.mo.meter.number.hint.format2')
                                                            }}
                                                        </li>
                                                        <li>
                                                            - {{
                                                            $t('widget.mo.meter.number.hint.format3')
                                                            }}
                                                        </li>
                                                        <li>
                                                            - {{
                                                            $t('widget.mo.meter.number.hint.format4')
                                                            }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="market_location_identifier"
                                                    :class="[marketLocationError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.marketLocation')
                                                    }}
                                                </label>
                                                <input
                                                    type="number"
                                                    id="market_location_identifier"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.marketLocation')"
                                                    v-model="details.marketLocationIdentifier"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-6">
                                            <div
                                                class="form-group"
                                                v-if="!disableCustomerSpecification"
                                            >
                                                <label
                                                    for="customer_specification"
                                                    :class="[customerSpecificationError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.customerSpecification')
                                                    }}
                                                </label>
                                                <v-select
                                                    class="v-select-cdc"
                                                    id="customer_specification"
                                                    :options="customerSpecifications"
                                                    v-model="customerSpecificationObject"
                                                    @input="validateDate"
                                                ></v-select>
                                            </div>
                                            <div
                                                class="form-group"
                                                v-if="notEarliestPossible"
                                            >
                                                <label
                                                    for="date"
                                                    :class="[dateError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.cdc.date') }}
                                                </label>
                                                <input
                                                    v-if="displayElement('date-input')"
                                                    v-model="details.date"
                                                    type="date"
                                                    class="form-control"
                                                    id="date"
                                                    @blur="validateDate"
                                                />
                                                <datetime
                                                    v-else
                                                    v-model="details.date"
                                                    :min-datetime="minDate"
                                                    :max-datetime="maxDate"
                                                    type="date"
                                                    input-id="date"
                                                    input-class="form-control"
                                                    value-zone="local"
                                                    format="dd.LL.yyyy"
                                                    @close="validateDate"
                                                ></datetime>
                                            </div>
                                            <div
                                                class="form-group"
                                                v-if="previousProviderRequired"
                                            >
                                                <label
                                                    for="previous_provider_code_number"
                                                    :class="[previousProviderError ? 'text-danger' : '']"
                                                >
                                                    {{
                                                    $t('widget.cdc.previousProviderCodeNumber')
                                                    }}
                                                </label>
                                                <v-select
                                                    class="v-select-cdc"
                                                    id="previous_provider_code_number"
                                                    :options="providers"
                                                    v-model="previousProviderObject"
                                                    :getOptionLabel="getProviderOptionLabel"
                                                    :getOptionKey="getProviderOptionKey"
                                                ></v-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="deliveryRadio">
                                <div class="col-sm-8 col-xs-12">
                                    <messages-block
                                        :success="successInformation"
                                        :errors="errorInformation"
                                    ></messages-block>
                                </div>
                                <div class="col-sm-4 col-xs-12">
                                    <div class="text-end">
                                        <button
                                            class="btn btn-primary"
                                            @click="submit"
                                            :disabled="submitPending"
                                        >
                                            {{ $t('widget.cdc.submit') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
