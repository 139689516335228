<div data-test="left-aside">
    <button
        class="m-aside-left-close m-aside-left-close--skin-dark"
        id="m_aside_left_close_btn"
        @click="$emit('toggleSidebar')"
    >
        <i class="fa fa-times"></i>
    </button>
    <div
        id="m_aside_left"
        class="m-grid__item m-aside-left m-aside-left--skin-dark"
        :class="toggleSidebarClass"
    >
        <!-- BEGIN: Aside Menu -->
        <div
            id="m_ver_menu"
            class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-scroller ps"
            m-menu-vertical="1"
            m-menu-scrollable="1"
            m-menu-dropdown-timeout="500"
            style="position: relative; height: 100%; overflow: hidden"
        >
            <div
                class="m-aside-menu--welcome"
                v-if="displayElement('aside-menu--welcome')"
            >
                {{ $t('widget.welcome.short') }}<br />
                <span v-if="customerData.salutation != 'Ohne'">
                    {{ customerData.salutation }} {{ customerData.surname }}<br />
                </span>
                <span v-else>
                    {{customerData.firstname}} {{ customerData.surname }}<br />
                </span>
                {{ $t('widget.welcome.in.customer.portal') }}.
            </div>
            <ul
                class="m-menu__nav m-menu__nav--dropdown-submenu-arrow pb-0 mb-0"
            >
                <li class="m-menu__item m-menu__item-disabled">
                    <div class="m-menu__link">
                        <i
                            class="m-menu__link-icon fa fa-user sidebar-customer-number"
                        ></i>
                        <span class="m-menu__minimized-data"
                            >{{ customerData.id }}</span
                        >
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span
                                    v-if="displayElement('customer-data-alt')"
                                    class="m-menu__link-text sidebar-customer-number"
                                >
                                    <div
                                        v-if="customerData.firstname"
                                        class="title"
                                    >
                                        {{ $t('left.aside.welcome') }} {{
                                        customerData.firstname }} {{
                                        customerData.surname }}<template
                                            v-if="displayElement('welcome-exclamation')"
                                            >!</template
                                        ><template v-else>,</template>
                                    </div>
                                    <div
                                        v-if="displayElement('contract-instead-customer') && selectedContractId"
                                        class="subtitle"
                                    >
                                        {{ $t('left.aside.contract.nr') }} #{{
                                        selectedContractId }}
                                    </div>
                                    <div
                                        v-else-if="customerData.id"
                                        class="subtitle"
                                    >
                                        {{ $t('left.aside.customer.nr') }}. #{{
                                        customerData.id }}
                                    </div>
                                </span>
                                <span
                                    v-else-if="displayElement('customer-data-alt_v2')"
                                    class="m-menu__link-text sidebar-customer-number"
                                >
                                    {{ customerData.id }}
                                    <span class="sidebar-customer-details">
                                        <br v-if="customerData.company" />{{
                                        customerData.company }}
                                        <span
                                            v-if="customerData.salutation != 'Ohne'"
                                        >
                                            <br />
                                            {{customerData.salutation }}
                                        </span>
                                        {{ customerData.title }} <br />{{
                                        customerData.firstname }} <br />{{
                                        customerData.surname }}
                                    </span>
                                </span>
                                <span
                                    v-else
                                    class="m-menu__link-text sidebar-customer-number"
                                >
                                    {{ customerData.id }}
                                    <span class="sidebar-customer-details">
                                        <br v-if="customerData.company" />{{
                                        customerData.company }} <br />{{
                                        customerData.salutation }} {{
                                        customerData.title }} <br />{{
                                        customerData.firstname }} {{
                                        customerData.surname }}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                </li>
            </ul>
            <ul
                v-if="!customerDetails.isFleetCustomer"
                class="m-menu__nav m-menu__nav--dropdown-submenu-arrow pt-0 m-menu__nav--no-after scrollable scrollable--mobile"
            >
                <li
                    data-test="left-aside-contract-buttons"
                    class="m-menu__item"
                    :class="{'m-menu__item--selected': selectedContractId === contract.contractId}"
                    v-for="contract in contractList"
                    :key="contract.contractId"
                >
                    <router-link
                        :to="'/dashboard/'+contract.contractId"
                        class="m-menu__link"
                    >
                        <i
                            v-if="displayElement('commodity-icon')"
                            :class="'commodity-icon m-menu__link-icon fas fa-' + (contract.energy === 'gas' ? 'burn' : 'bolt')"
                        ></i>
                        <img
                            v-else-if="displayElement('custom-commodity-icons')"
                            class="m-menu__link-icon"
                            :src="customCommodityIcons(contract.energy)"
                        />
                        <i
                            v-else
                            class="m-menu__link-icon fa fa-file-contract"
                        ></i>
                        <span class="m-menu__minimized-data"
                            >{{ contract.contractId }}</span
                        >
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span
                                    class="m-menu__link-text sidebar-contract-number"
                                >
                                    <span
                                        v-if="displayElement('no-hastag')"
                                        class="number"
                                        >{{ contract.contractId }}</span
                                    >

                                    <span v-else class="number"
                                        >#{{ contract.contractId }}</span
                                    >

                                    <template v-if="isProductExists(contract)">
                                        <span class="dash"> - </span>
                                        <span class="title"
                                            >{{ processTitleElli(contract)
                                            }}</span
                                        >
                                        <br />
                                        <span
                                            class="sidebar-contract-details"
                                            v-if="isThgNotDealer(contract) && getLicensePlateForContract(contract).length > 0"
                                        >
                                            <div>
                                                {{
                                                $t('left.aside.license.plate')
                                                }}:
                                            </div>
                                            <div>
                                                {{
                                                getLicensePlateForContract(contract)
                                                }}
                                            </div>
                                        </span>
                                        <span
                                            class="sidebar-contract-details"
                                            v-else
                                        >
                                            <div>
                                                {{ contract.street }} {{
                                                contract.houseNumber }}
                                            </div>
                                            <div>
                                                <span class="zip"
                                                    >{{ contract.zip }}</span
                                                >
                                                {{ contract.city }}
                                            </div>
                                        </span>
                                    </template>
                                </span>
                            </span>
                        </span>
                    </router-link>
                </li>
            </ul>
            <div class="bottom-nav">
                <ul
                    class="m-menu__nav m-menu__nav--dropdown-submenu-arrow"
                    v-if="locales.length > 1"
                >
                    <li
                        class="m-menu__item m-menu__item--submenu"
                        :class="{ 'm-menu__item--open': displayLocaleSelect === true}"
                        aria-haspopup="true"
                        m-menu-submenu-toggle="hover"
                    >
                        <a
                            @click="toggleLocaleSelect()"
                            class="m-menu__link m-menu__toggle flag-icon-parent"
                        >
                            <country-flag
                                :country="currentLocale"
                                size="normal"
                                class="m-menu__link-icon flag-icon"
                            />
                            <span class="m-menu__link-text"
                                >{{ $t('locale.'+currentLocale) }}</span
                            >
                        </a>
                        <div class="m-menu__submenu">
                            <span class="m-menu__arrow"></span>
                            <ul class="m-menu__subnav">
                                <li
                                    class="m-menu__item"
                                    v-for="locale in locales"
                                    v-if="locale != currentLocale"
                                >
                                    <a
                                        @click="changeLocale(locale);toggleLocaleSelect();"
                                        class="m-menu__link flag-icon-parent"
                                    >
                                        <country-flag
                                            :country="locale"
                                            size="normal"
                                            class="m-menu__link-icon flag-icon"
                                        />
                                        <span class="m-menu__link-title">
                                            <span class="m-menu__link-wrap">
                                                <span class="m-menu__link-text"
                                                    >{{ $t('locale.'+locale)
                                                    }}</span
                                                >
                                            </span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
                    <li class="m-menu__item" v-if="displayChangePassword">
                        <a
                            v-if="displayElement('unlock-icon')"
                            class="btn btn-link btn-white"
                            @click="toggleOpenChangePasswordModal"
                            ><span
                                >{{ $t('protected.password.change') }}</span
                            ></a
                        >
                        <a
                            v-else
                            @click="toggleOpenChangePasswordModal"
                            class="m-menu__link"
                        >
                            <i class="m-menu__link-icon fa fa-unlock"></i>
                            <span class="m-menu__link-title">
                                <span class="m-menu__link-wrap">
                                    <span class="m-menu__link-text"
                                        >{{ $t('protected.password.change')
                                        }}</span
                                    >
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="m-menu__item" v-if="displayTimeOut">
                        <span
                            v-if="displayElement('timeout-countdown')"
                            class="m-menu__item-text"
                            ><span class="m-menu__item-text__block"
                                >{{ $t('left.aside.timeout.automatic') }}</span
                            >
                            <span class="m-menu__item-text__block"
                                >{{ time }} {{ $t('left.aside.timeout.minute')
                                }}</span
                            ></span
                        >
                    </li>
                    <li v-if="isTwoFactorAvailable" class="m-menu__item">
                        <a
                            @click="toggleOpenTwoFactorAuthenticationModal"
                            class="m-menu__link"
                        >
                            <i class="m-menu__link-icon fa fa-unlock"></i>
                            <span class="m-menu__link-title">
                                <span class="m-menu__link-wrap">
                                    <span class="m-menu__link-text"
                                        >{{
                                        $t('asside.two.factor.authentication')
                                        }}</span
                                    >
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="m-menu__item">
                        <a
                            v-if="displayElement('logout-simple-link')"
                            class="btn btn-link btn-white"
                            @click="logout()"
                            ><span>{{ $t('asside.signout') }}</span></a
                        >
                        <a v-else @click="logout()" class="m-menu__link">
                            <i class="m-menu__link-icon fa fa-sign-out-alt"></i>
                            <span class="m-menu__link-title">
                                <span class="m-menu__link-wrap">
                                    <span class="m-menu__link-text"
                                        >{{ $t('asside.signout') }}</span
                                    >
                                </span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- begin: Aside Footer Area -->
            <div class="sidebar-footer-area" v-if="version !== 'prod'">
                <b>{{ version }}</b>
            </div>
            <!-- END: Aside Footer Area -->
            <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                    class="ps__thumb-x"
                    tabindex="0"
                    style="left: 0px; width: 0px"
                ></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 4px">
                <div
                    class="ps__thumb-y"
                    tabindex="0"
                    style="top: 0px; height: 0px"
                ></div>
            </div>
        </div>
        <!-- END: Aside Menu -->
    </div>
    <div class="menu-backdrop" @click="$emit('toggleSidebar')"></div>
</div>
