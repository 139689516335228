import { default as Component } from 'vue-class-component';
import vSelect from 'vue-select';

// Call Render template.
import WithRender from './ets.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';

// Template
import { HeaderV2 } from '@/components/template/header-v2';

// Widgets
import { ChangeWidget } from '@/components/widgets/change-widget/';
import { ChangePaymentDataWidget } from '@/components/widgets/change-payment-data-widget';
import { CustomerServiceFormWidget } from '@/components/widgets/customer-service-form-widget';
import { CustomerServiceWidget } from '@/components/widgets/customer-service-widget';
import { DepositWidget } from '@/components/widgets/deposit-widget';
import { DepositChangeWidget } from '@/components/widgets/deposit-change-widget';
import { MeterWidget } from '@/components/widgets/meter-widget';
import { MeterSubmitWidget } from '@/components/widgets/meter-submit-widget';
import { PostBoxWidget } from '@/components/widgets/post-box-widget';
import { TariffWidget } from '@/components/widgets/tariff-widget';
import { TariffDetailsWidget } from '@/components/widgets/tariff-details-widget';
import { WelcomeWidget } from '@/components/widgets/welcome-widget';
import { NonCommodityTariffDetailsWidget } from '@/components/widgets/non-commodity-tariff-details-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';

@WithRender
@Component({
    components: {
        // Template
        HeaderV2,
        // External packages
        vSelect,
        // Widgets
        ChangeWidget,
        ChangePaymentDataWidget,
        CustomerServiceFormWidget,
        CustomerServiceWidget,
        DepositWidget,
        DepositChangeWidget,
        MeterWidget,
        MeterSubmitWidget,
        PostBoxWidget,
        TariffWidget,
        TariffDetailsWidget,
        WelcomeWidget,
        NonCommodityTariffDetailsWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})
// @ts-ignore
export class Dashboard extends DashboardParent {
    private showGroup1 = {
        accountTransactionsWidget: false,
        depositChangeWidget: false,
        meterSubmitWidget: false,
        tariffDetailsWidgetEts: false,
        nonCommunityTariffDetailsWidgetEts: false,
    };

    private showGroup2 = {
        changeBillingDataWidget: false,
        changeCustomerDataWidget: false,
        changePaymentDataWidget: false,
        customerServiceFormWidget: false,
    };

    get showTariffDetailsWidgetEts(): boolean {
        return (
            this.showGroup1.tariffDetailsWidgetEts &&
            this.shouldDisplayWidgetByContractStatus('tariffDetailsWidget')
        );
    }

    get showCustomerServiceFormWidget(): boolean {
        return this.showGroup2.customerServiceFormWidget;
    }

    get currentContractId(): number {
        return this.contractsList.filter((contract) => {
            return (
                contract.contractId === this.$store.state.contracts.contractId
            );
        });
    }

    private closeGroup1(): void {
        this.showGroup1.accountTransactionsWidget = false;
        this.showGroup1.depositChangeWidget = false;
        this.showGroup1.meterSubmitWidget = false;
        this.showGroup1.tariffDetailsWidgetEts = false;
        this.showGroup1.nonCommunityTariffDetailsWidgetEts = false;
    }

    private closeGroup2(): void {
        this.showGroup2.changeBillingDataWidget = false;
        this.showGroup2.changeCustomerDataWidget = false;
        this.showGroup2.changePaymentDataWidget = false;
        this.showGroup2.customerServiceFormWidget = false;
    }

    private contractLabel(contract: Record<any, any>): string {
        return `#${contract.contractId} ${contract.title} - ${contract.street} ${contract.houseNumber}, ${contract.zip} ${contract.city}`;
    }

    private contractSelected(contract: Record<any, any>): void {
        window.location.pathname = `/dashboard/${contract.contractId}`;
    }
}
