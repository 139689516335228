import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './banner-widget.html';

@WithRender
@Component({})
export class BannerWidget extends Base {
    get bannerUrl() {
        const path = '/img/evus/' + this.currentClient;
        switch (this.currentClient) {
            case 'qcells':
                return path + '/PV-Banner.jpg';
            case 'bsw':
                return path + '/Wertewidget_800x542_FA5.jpg';
            default:
                return '';
        }
    }

    get link() {
        switch (this.currentClient) {
            case 'qcells':
                return 'https://energie.q-cells.de/privatkunden/solar/vlink';
            case 'bsw':
                return 'https://berlinerstadtwerke.de/app/dokumente/Berliner-Stadtwerke_Unternehmensflyer.pdf';
            default:
                return '';
        }
    }
}
