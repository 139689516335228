<div
    class="col-12 account-transactions"
    data-test="account-transactions-widget"
>
    <div class="m-portlet m-portlet__body">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div>
                <div
                    v-if="displayElement('show-info-icon')"
                    class="info-icon-tooltip"
                >
                    <i
                        @click="showTooltip = true"
                        class="fa fa-info-circle"
                    ></i>
                    <div class="position-relative">
                        <div v-if="showTooltip" class="info-tooltip-container">
                            <div class="info-tooltip-container__header">
                                <div>{{ $t('widget.at.tooltip.header') }}</div>
                                <div>
                                    <i
                                        @click="showTooltip = false"
                                        class="fa fa-times"
                                    ></i>
                                </div>
                            </div>
                            <div>
                                <span>{{ $t('widget.at.tooltip.text') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <i
                    class="fa fa-chart-line readingHistory-icon pt-2"
                    v-if="displayElement('chart-line-icon')"
                ></i>
                <span class="box-title static-title"
                    >{{ $t('widget.at.title') }}</span
                >
                <div class="">
                    <div>
                        <template
                            v-if="displayElement('hidden-table-name')"
                        ></template>
                        <div v-else><b>{{ $t('widget.at.payments') }}</b></div>
                        <table
                            class="table table-striped"
                            id="accountTransactionsTable-payments"
                        >
                            <tbody>
                                <tr v-if="displayElement('fixed-th')">
                                    <th style="width: 15%">
                                        {{ $t('widget.at.date') }}
                                    </th>
                                    <th
                                        :class="{'d-block': displayElement('show-table-header-xs')}"
                                        style="width: 40%"
                                    >
                                        {{ $t('widget.at.description') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.totalamount') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.posted') }}
                                    </th>
                                    <th style="width: 15%">
                                        {{ $t('widget.at.remaining') }}
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th>{{ $t('widget.at.date') }}</th>
                                    <th
                                        :class="{'d-block': displayElement('show-table-header-xs')}"
                                    >
                                        {{ $t('widget.at.description') }}
                                    </th>
                                    <th>{{ $t('widget.at.totalamount') }}</th>
                                    <th>{{ $t('widget.at.posted') }}</th>
                                    <th>{{ $t('widget.at.remaining') }}</th>
                                </tr>
                                <tr
                                    v-for="payment in paymentTransactions"
                                    :class="{ 'active': payment.active }"
                                    :key="payment.bookingNumber + Math.random()"
                                >
                                    <td data-name="datum">
                                        {{ payment.createdAt | date }}
                                    </td>
                                    <td
                                        data-name="beschreibung"
                                        @click="activate(payment)"
                                    >
                                        {{ payment.notice }}
                                    </td>
                                    <td data-name="gesamtbetrag">
                                        {{ payment.grossAmount | euro }} {{
                                        $t('euro') }}
                                    </td>
                                    <td data-name="ausgeglichen">
                                        {{ payment.fulfilled | euro }} {{
                                        $t('euro') }}
                                    </td>
                                    <td data-name="offen">
                                        {{ payment.notFulfilled | euro }} {{
                                        $t('euro') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
