import { default as Component } from 'vue-class-component';
import WithRender from './tariff-widget.html';
import { TariffWidgetBSW } from './custom/bsw';

import { productImageMap } from '@/map/productImageMap';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Format from '../../../interfaces/Format';
import Commodity from '@/interfaces/Commodity';
import { TranslateResult } from 'vue-i18n';
import Base from '@/mixins/base';
import moment from 'moment';
import Contracts from '@/interfaces/Contracts';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);
const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

@WithRender
@Component({
    filters: {
        euro: formatProvider.euro(),
    },
    components: {
        'tariff-widget-bsw': TariffWidgetBSW,
    },
})
export class TariffWidget extends Base {
    protected elements = {
        bsw: [
            'bottom-15-class',
            'h-100-class',
            'position-absolute-class',
            'tariff-widget-bsw',
            'show-tariff-buttons',
            'show-details-button',
            'non-margin-top-3-class',
            'change-tariff-button-bottom',
        ],
        eoptimum: [
            // 'show-tariff-button-overview',
            // 'show-price-information-current-tariff',
        ],
    };

    get showTariffLinks() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.loaded;
    }

    get tariffChangeAllowed() {
        return this.$store.state.settings.tariffChange;
    }

    get tariffChangeAvailable() {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length > 0
        );
    }

    get tariffOverviewAllowed() {
        return this.$store.state.settings.tariffOverview;
    }

    get contractMoveAllowed() {
        return (
            this.$store.state.settings.contractMove &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.relocationPossible
        );
    }

    get productTitle() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).product.title;
    }

    get productCode() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).product.code;
    }

    get workingPrice() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariff.workingPrice;
    }

    get basePriceMonth() {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).tariff.basePrice / 12
        );
    }

    get isNonCommodityContract() {
        const selectContract = this.contractsList.filter((contract) => {
            return (
                contract.contractId === this.$store.state.contracts.contractId
            );
        })[0];
        return (
            selectContract.type === 'non_commodity' &&
            selectContract.energy === 'non_energy'
        );
    }
    x;

    get productImage(): string | null {
        if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
            try {
                return productImageMap[this.productCode];
            } catch (error) {
                if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'prod') {
                    console.error(
                        'Product image not found for product code: ' +
                            this.productCode
                    );
                    console.log(error);
                }
                return null;
            }
        }
        return null;
    }

    get commodityPrice(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }

    get donationApplied(): boolean {
        return this.currentTariff.contract.donationApplied;
    }

    get formattedDonationCampaignEnd(): string {
        return moment(
            this.$store.state.settings.donationCampaignPeriodEnd
        ).format('DD.MM.YYYY');
    }

    get isDonationPeriodActive(): boolean {
        return (
            new Date(this.$store.state.settings.donationCampaignPeriodStart) <=
                new Date() &&
            new Date(this.$store.state.settings.donationCampaignPeriodEnd) >=
                new Date()
        );
    }
}
