<div
    class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
    :class="bodyClass"
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <!-- BEGIN: Header -->
        <app-header @toggleSidebar="toggleSidebar"></app-header>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div
            class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
        >
            <!-- BEGIN: Left Aside -->
            <left-aside
                @toggleSidebar="toggleSidebar"
                :toggleSidebar="toggleSidebarState"
            ></left-aside>
            <!-- END: Left Aside -->
            <!-- begin: Body -->
            <div
                class="m-grid__item m-grid__item--fluid m-wrapper wallpaper-wrapper"
                :style="widgetsBackground"
            >
                <div class="container main-wrap">
                    <div class="m-content">
                        <div v-html="htmlTag"></div>
                        <app-footer
                            v-if="displayElement('app-footer-inside-container')"
                        ></app-footer>
                    </div>
                </div>
            </div>
            <!-- END: Body -->
        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <template
            v-if="displayElement('hide-app-footer-outside-container')"
        ></template>
        <app-footer v-else></app-footer>
        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->
    <!-- begin::Scroll Top -->
    <scroll-top></scroll-top>
    <!-- end::Scroll Top -->
</div>
