<div class="col-12 mobile-modal__inner mobile-modal__inner--move">
    <div class="m-portlet m-portlet--mobile border-radius-rb">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="box-title static-title static-title--custom">
                        {{ $t('widget.td.move') }}
                    </span>
                    <div class="mobile-modal__wrap row">
                        <div class="col-12">
                            <div class="row">
                                <div
                                    class="col-12 col-md-4"
                                    :class="['form-group', departureDateError ? 'text-danger' : '']"
                                >
                                    <label for="move-out-date">
                                        {{ $t('widget.mo.move.out.date') }}
                                    </label>
                                    <input
                                        v-if="displayElement('date-input')"
                                        v-model="details.departureDate"
                                        type="date"
                                        :class="['form-control input--arrow', departureDateError ? 'is-invalid' : '']"
                                        id="move-out-date"
                                        @change="departureDateError = false"
                                    />
                                    <datetime
                                        v-else
                                        v-model="details.departureDate"
                                        type="date"
                                        input-id="move-out-date"
                                        :input-class="['form-control', departureDateError ? 'is-invalid' : '']"
                                        value-zone="local"
                                        format="dd.LL.yyyy"
                                        @close="departureDateError = false"
                                        class="input--arrow"
                                    ></datetime>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <span
                                        class="box-title static-title static-subtitle"
                                        >{{ $t('widget.mo.move.subtitle')
                                        }}</span
                                    >
                                </div>
                                <div class="col-12 col-md-4">
                                    <div
                                        :class="['form-group', arrivalDateError ? 'text-danger' : '']"
                                    >
                                        <label for="move-in-date">
                                            {{ $t('widget.mo.move.in.date') }}
                                        </label>
                                        <input
                                            v-if="displayElement('date-input')"
                                            v-model="details.arrivalDate"
                                            type="date"
                                            :class="['form-control', arrivalDateError ? 'is-invalid' : '']"
                                            id="move-in-date"
                                            @change="arrivalDateError = false"
                                        />
                                        <datetime
                                            v-else
                                            v-model="details.arrivalDate"
                                            type="date"
                                            input-id="move-in-date"
                                            :input-class="['form-control', arrivalDateError ? 'is-invalid' : '']"
                                            value-zone="local"
                                            format="dd.LL.yyyy"
                                            @close="arrivalDateError = false"
                                            class="input--arrow"
                                        ></datetime>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label
                                            :class="[cityError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.post.code') }}
                                        </label>
                                        <v-select
                                            :options="citiesZipCodes"
                                            @search="citySearch"
                                            @input="cityInput"
                                            label="zip"
                                            v-model="selectedCityZip"
                                            :class="[cityError ? 'is-invalid' : '']"
                                        >
                                            <template #no-options>
                                                <span
                                                    v-if="displayElement('hide-city-input-no-options')"
                                                ></span>
                                                <span v-else
                                                    >{{ $t('widget.mo.no.city')
                                                    }}</span
                                                >
                                            </template>
                                        </v-select>
                                    </div>

                                    <div class="row" v-if="selectedCityId">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.street') }}
                                                </label>
                                                <v-select
                                                    :options="streets"
                                                    v-model="details.street"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                    @input="streetError = false"
                                                >
                                                    <template #no-options>
                                                        <span
                                                            v-if="displayElem('hide-street-input-no-options')"
                                                        ></span>
                                                        <span v-else
                                                            >{{
                                                            $t('widget.mo.no.street')
                                                            }}</span
                                                        >
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label
                                                for="houseNumber"
                                                :class="[houseNumberError ? 'text-danger' : '']"
                                            >
                                                {{ $t('widget.mo.house.number')
                                                }}
                                            </label>
                                            <div class="form-group">
                                                <input
                                                    id="houseNumber"
                                                    type="text"
                                                    :class="['form-control', houseNumberError ? 'is-invalid' : '']"
                                                    v-model="details.houseNumber"
                                                    @change="houseNumberError = false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label
                                            :class="[cityError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.place') }}
                                        </label>
                                        <v-select
                                            :options="cities"
                                            @search="citySearch"
                                            @input="cityInput"
                                            label="text"
                                            v-model="selectedCityName"
                                            :class="[cityError ? 'is-invalid' : '']"
                                        >
                                            <template #no-options>
                                                <span
                                                    v-if="displayElement('hide-city-input-no-options')"
                                                ></span>
                                                <span v-else
                                                    >{{ $t('widget.mo.no.city')
                                                    }}</span
                                                >
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label
                                            for="new-usage"
                                            :class="[newUsageError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.new.consumption')
                                            }}
                                        </label>
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="new-usage"
                                            v-model="details.newUsage"
                                            @change="newUsageError = false"
                                            :class="['form-control', newUsageError ? 'is-invalid' : '']"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label
                                            for="new-counterNumber"
                                            :class="[counterNumberError ? 'text-danger' : '']"
                                        >
                                            {{ $t('widget.mo.new.counterNumber')
                                            }}
                                            <span class="label--optional"
                                                >(optional)</span
                                            >
                                        </label>
                                        <input
                                            class="form-control"
                                            type="text"
                                            id="new-counterNumber"
                                            v-model="details.counterNumber"
                                            @change="counterNumberError = false"
                                            :class="['form-control', counterNumberError ? 'is-invalid' : '']"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-check">
                                        <label
                                            :class="[
                                      'form-check-label',
                                       displayElement('branded-checkbox')
                                          ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                          : '',
                                       confirmationCheckboxError ? 'is-invalid text-danger' : ''
                                  ]"
                                            for="cancellation-confirmation"
                                        >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="cancellation-confirmation"
                                                v-model="details.confirmation"
                                                @change="confirmationCheckboxError = false"
                                            />
                                            {{ $t('widget.mo.confirmation') }}
                                            <i></i>
                                            <span></span>
                                            <span
                                                class="confirmation-link"
                                                v-if="contract.energy === 'electricity'"
                                            >
                                                <a
                                                    href="https://www.schweizstrom.de/dam/jcr:7e056313-d559-44f6-bb64-cc97126589a1/EBLD-AGB-Stromliefervertraege-Stand-30-04-2018.pdf"
                                                    target="_blank"
                                                >
                                                    {{
                                                    $t('widget.mo.move.link.name')
                                                    }}
                                                </a>
                                            </span>
                                            <span
                                                v-if="contract.energy === 'gas'"
                                                class="confirmation-link"
                                            >
                                                <a
                                                    href="https://www.schweizstrom.de/dam/jcr:996794db-0c05-4384-a911-7848d1a39fb4/EBLD-AGB-Erdgasliefervertraege-Stand-30-04-2018.pdf"
                                                    target="_blank"
                                                >
                                                    {{
                                                    $t('widget.mo.move.link.name')
                                                    }}
                                                </a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <div class="col-12 col-md-4 col-4--p0">
                                        <button
                                            class="btn btn-primary btn-primary-custom"
                                            @click="submit"
                                            :disabled="submitPending"
                                        >
                                            {{ $t('widget.c.confirm') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <messages-block
                            :success="successInformation"
                            :errors="errorInformation"
                        ></messages-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
