<div
    class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
    :class="bodyClass"
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <!-- BEGIN: Header -->
        <app-header @toggleSidebar="toggleSidebar"></app-header>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div
            class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
        >
            <!-- BEGIN: Left Aside -->
            <left-aside
                @toggleSidebar="toggleSidebar"
                :toggleSidebar="toggleSidebarState"
                @toggle-modal="toggleOpenChangePasswordModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></left-aside>
            <!-- END: Left Aside -->
            <!-- begin: Body -->
            <modal-password-update-widget
                v-if="showChangePasswordModal"
                @toggle-modal="toggleOpenChangePasswordModal"
            />

            <modal-message-widget
                v-if="showMessageModal"
                :message="$t('widget.modal.message.security.message')"
                @toggle-message-modal="toggleOpenMessageModal"
            ></modal-message-widget>

            <modal-two-factor-authentication-widget
                v-if="showTwoFactorAuthenticationModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
                @toggle-cancel-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></modal-two-factor-authentication-widget>

            <div
                class="m-grid__item m-grid__item--fluid m-wrapper wallpaper-wrapper"
                :style="widgetsBackground"
            >
                <div v-if="isDemoMode" class="demo-banner">
                    <span>powered by</span>
                    <img alt="WorkDigital" src="/img/wd_logo.svg" />
                </div>
                <agent-view-widget />
                <call-to-action-banner-widget
                    v-if="showCallToActionBannerWidget"
                />
                <div v-if="contractsDataSet" class="container main-wrap">
                    <welcome-widget-v2></welcome-widget-v2>
                    <div class="m-content">
                        <!-- ROW -->
                        <div class="row">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                                v-if="showStatusBarWidget"
                            >
                                <status-bar-widget></status-bar-widget>
                            </div>
                        </div>
                        <div v-if="contractInActiveState">
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-md-4 col-sm-12 col-xs-12 row-items"
                                    v-if="showDepositWidget"
                                >
                                    <deposit-widget
                                        @toggleWidget="toggleGroup1"
                                        :isActiveWidget="showGroup1.depositChangeWidget"
                                        :showAction="showDepositWidgetAction"
                                    ></deposit-widget>
                                </div>
                                <div
                                    class="col-md-4 col-sm-12 col-xs-12 row-items"
                                    v-if="showAccountBalanceWidget"
                                >
                                    <account-balance-widget
                                        @toggleWidget="toggleGroup1"
                                        :isActiveWidget="showGroup1.accountTransactionsWidget"
                                        :showAction="showAccountBalanceWidgetAction"
                                    ></account-balance-widget>
                                </div>
                                <div
                                    class="col-md-4 col-sm-12 col-xs-12 row-items"
                                    v-if="showMeterWidget"
                                >
                                    <meter-widget
                                        @toggleWidget="toggleGroup1"
                                        :isActiveWidget="showGroup1.meterSubmitWidget"
                                        :showAction="showMeterWidgetAction"
                                    ></meter-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal d-none d-md-block"
                                v-if="showDepositChangeWidget"
                            >
                                <deposit-change-widget
                                    @closeWidget="closeGroup1"
                                ></deposit-change-widget>
                            </div>
                            <div
                                class="row mobile-modal d-none d-md-block"
                                v-if="showAccountTransactionsWidget"
                            >
                                <account-transactions-widget
                                    @closeWidget="closeGroup1"
                                ></account-transactions-widget>
                            </div>
                            <div
                                class="row mobile-modal d-none d-md-block"
                                v-if="showMeterSubmitWidget"
                            >
                                <meter-submit-widget
                                    @closeWidget="closeGroup1"
                                ></meter-submit-widget>
                            </div>
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    v-if="isNonCommodityContract"
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <non-commodity-tariff-details-widget
                                        @toggleWidget="toggleGroup4AndScroll"
                                        @closeWidget="closeGroup3"
                                    ></non-commodity-tariff-details-widget>
                                </div>
                                <div
                                    v-else
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <tariff-details-widget
                                        @toggleWidget="toggleGroup4AndScroll"
                                        @closeWidget="closeGroup3"
                                    ></tariff-details-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showRevocationWidget"
                            >
                                <div class="row-items w-100">
                                    <revoke-widget
                                        @toggleWidget="toggleGroup3"
                                        @closeWidget="closeGroup4"
                                    ></revoke-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showCancellationWidget"
                            >
                                <div class="row-items">
                                    <cancellation-widget
                                        @closeWidget="closeGroup4"
                                    ></cancellation-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showMoveWidget"
                            >
                                <div class="row-items w-100">
                                    <move-widget
                                        @closeWidget="closeGroup4"
                                    ></move-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showTariffChangeWidget"
                            >
                                <div class="row-items w-100">
                                    <tariff-change-widget
                                        @closeWidget="closeGroup4"
                                    ></tariff-change-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showTariffOverview"
                            >
                                <div class="row-items w-100">
                                    <tariff-overview-widget
                                        @closeWidget="closeGroup4"
                                        id="showTariffOverview"
                                    ></tariff-overview-widget>
                                </div>
                            </div>
                            <div
                                class="row mobile-modal"
                                v-if="showDepositOverviewWidget"
                            >
                                <div class="row-items w-100">
                                    <deposit-overview-widget></deposit-overview-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-md-4 col-sm-12 col-xs-12 row-items"
                                    v-if="showChangeWidget"
                                >
                                    <change-widget
                                        :groupWidget="showGroup2"
                                        @toggleWidget="toggleGroup2AndScroll"
                                    ></change-widget>
                                </div>
                                <div
                                    class="col-md-8 col-sm-12 col-xs-12 row-items"
                                >
                                    <meter-history-widget
                                        v-if="isNoDashboardRedirect"
                                    ></meter-history-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div class="row" v-if="showChangeBillingDataWidget">
                                <div class="col row-items">
                                    <change-billing-data-widget
                                        @closeWidget="closeGroup2"
                                        id="changeBillingDataWidget"
                                    ></change-billing-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row"
                                v-if="showChangeCustomerDataWidget"
                            >
                                <div class="col row-items">
                                    <change-customer-data-widget
                                        @closeWidget="closeGroup2"
                                        id="changeCustomerDataWidget"
                                    ></change-customer-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row"
                                v-if="showChangePaymentDataWidget && stateHasLoaded"
                            >
                                <div class="col row-items">
                                    <change-payment-data-widget
                                        @closeWidget="closeGroup2"
                                        id="changePaymentDataWidget"
                                    ></change-payment-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div class="row" v-if="showInvoiceWidget">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <invoice-widget></invoice-widget>
                                </div>
                            </div>
                        </div>
                        <!-- ROW -->
                        <div class="row" v-if="showPostBoxWidget">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                            >
                                <post-box-widget></post-box-widget>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="noContractsAvailable"
                    class="container main-wrap customer-data-change-no-contracts"
                >
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            {{ $t('widget.welcome') }}<br /><br /><br />
                            {{ $t('widget.welcome.noContract') }}
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <br /><br />
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 row-items">
                            <change-customer-data-widget></change-customer-data-widget>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="container main-wrap"
                    style="height: 90vh"
                ></div>
            </div>
            <!-- END: Body -->
        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <app-footer></app-footer>
        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->
    <!-- begin::Scroll Top -->
    <scroll-top></scroll-top>
    <!-- end::Scroll Top -->
</div>
