<div
    class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default elli-thg"
    :class="bodyClass"
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <!-- BEGIN: Header -->
        <app-header @toggleSidebar="toggleSidebar"></app-header>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div
            class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
        >
            <!-- BEGIN: Left Aside -->
            <left-aside
                @toggleSidebar="toggleSidebar"
                :toggleSidebar="toggleSidebarState"
                @toggle-modal="toggleOpenChangePasswordModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></left-aside>
            <!-- END: Left Aside -->
            <!-- begin: Body -->
            <modal-password-update-widget
                v-if="showChangePasswordModal"
                @toggle-modal="toggleOpenChangePasswordModal"
            />

            <modal-message-widget
                v-if="showMessageModal"
                :message="$t('widget.modal.message.security.message')"
                @toggle-message-modal="toggleOpenMessageModal"
            ></modal-message-widget>

            <modal-two-factor-authentication-widget
                v-if="showTwoFactorAuthenticationModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
                @toggle-cancel-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></modal-two-factor-authentication-widget>

            <div
                class="m-grid__item m-grid__item--fluid m-wrapper wallpaper-wrapper"
                id="dashboardMainArea"
                :style="widgetsBackground"
            >
                <agent-view-widget />
                <call-to-action-banner-widget
                    v-if="showCallToActionBannerWidget"
                />
                <div v-if="contractsDataSet" class="container main-wrap">
                    <template
                        v-if="displayElement('welcome-widget-empty')"
                    ></template>
                    <welcome-widget v-else></welcome-widget>
                    <div
                        class="m-content"
                        v-if="currentTariff.nonCommodityProduct.productCode"
                    >
                        <div>
                            <div
                                v-for="year in thgService.getBonusYears().reverse()"
                                v-if="thgService.hasBonus && !isProductCPO"
                                class="row"
                            >
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <thg-status-widget
                                        :bonusYear="year"
                                        :thgService="thgService"
                                    ></thg-status-widget>
                                </div>
                            </div>

                            <div class="row" v-if="!isProductCPO">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <thg-bonus-overview
                                        :thgService="thgService"
                                        :bonusYear="bonusSelectorYear"
                                        @showBonusSelectorForYear="showBonusSelectorForYear"
                                    ></thg-bonus-overview>
                                    <thg-bonus-selector
                                        v-if="showBonusSelector"
                                        :bonusYear="bonusSelectorYear"
                                        :thgService="thgService"
                                        @bonusSubmittedForYear="bonusSubmittedForYear"
                                        @changeVehicleDataForYear="changeVehicleDataForYear"
                                        @closeSelector="closeBonusSelector"
                                    ></thg-bonus-selector>
                                </div>
                            </div>

                            <div
                                v-if="showCarDataWidgetOld && !isProductCPO"
                                class="row"
                            >
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <thg-car-data-widget
                                        :bonusYear="vehicleDataEditorYear"
                                        :thgService="thgService"
                                        @closeEditor="closeVehicleDataEditor"
                                    ></thg-car-data-widget>
                                </div>
                            </div>

                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <change-widget
                                        :groupWidget="showGroup2"
                                        @toggleWidget="toggleGroup2"
                                    ></change-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showChangeBillingDataWidget"
                            >
                                <div class="col row-items mobile-modal__inner">
                                    <change-billing-data-widget
                                        @closeWidget="closeGroup2"
                                    ></change-billing-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showChangeCustomerDataWidget"
                            >
                                <div class="col row-items mobile-modal__inner">
                                    <change-customer-data-widget
                                        @closeWidget="closeGroup2"
                                    ></change-customer-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showChangePaymentDataWidget && stateHasLoaded"
                            >
                                <div class="col row-items mobile-modal__inner">
                                    <change-payment-data-widget
                                        @closeWidget="closeGroup2"
                                    ></change-payment-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                    v-if="contractStatus.statusCode < 8000 && showStatusBarWidget"
                                >
                                    <status-bar-widget></status-bar-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                    v-if="showCustomerDataCorrectionWidget"
                                >
                                    <customer-data-correction-widget
                                        :dataCorrectionRequiredResponse="dataCorrectionRequiredResponse"
                                        @dataCorrectionDone="dataCorrectionDone"
                                    ></customer-data-correction-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <!--                            <div class="row">-->
                            <!--                                <div-->
                            <!--                                    class="-->
                            <!--                                        col-md-4 col-sm-12 col-xs-12-->
                            <!--                                        row-items-->
                            <!--                                    "-->
                            <!--                                    v-if="showDepositWidget"-->
                            <!--                                >-->
                            <!--                                    <deposit-widget-->
                            <!--                                        @toggleWidget="toggleGroup1"-->
                            <!--                                        :isActiveWidget="showGroup1.depositChangeWidget"-->
                            <!--                                        :showAction="showDepositWidgetAction"-->
                            <!--                                    ></deposit-widget>-->
                            <!--                                </div>-->
                            <!--                                <div-->
                            <!--                                    class="-->
                            <!--                                        col-md-4 col-sm-12 col-xs-12-->
                            <!--                                        row-items-->
                            <!--                                    "-->
                            <!--                                    v-if="showAccountBalanceWidget"-->
                            <!--                                >-->
                            <!--                                    <account-balance-widget-->
                            <!--                                        @toggleWidget="toggleGroup1"-->
                            <!--                                        :isActiveWidget="showGroup1.accountTransactionsWidget"-->
                            <!--                                        :showAction="showAccountBalanceWidgetAction"-->
                            <!--                                    ></account-balance-widget>-->
                            <!--                                </div>-->
                            <!--                                <div-->
                            <!--                                    class="-->
                            <!--                                        col-md-4 col-sm-12 col-xs-12-->
                            <!--                                        row-items-->
                            <!--                                    "-->
                            <!--                                    v-if="showMeterWidget"-->
                            <!--                                >-->
                            <!--                                    <meter-widget-->
                            <!--                                        @toggleWidget="toggleGroup1"-->
                            <!--                                        :isActiveWidget="showGroup1.meterSubmitWidget"-->
                            <!--                                        :showAction="showMeterWidgetAction"-->
                            <!--                                    ></meter-widget>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!-- ROW -->
                            <div class="row" v-if="showOptInWidget">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <opt-in-widget></opt-in-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showDepositChangeWidget"
                            >
                                <deposit-change-widget
                                    @closeWidget="closeGroup1"
                                    @toggleWidget="toggleGroup4"
                                ></deposit-change-widget>
                            </div>
                            <div
                                class="row mobile-modal"
                                v-if="showDepositOverviewWidget"
                            >
                                <div class="row-items w-100">
                                    <deposit-overview-widget
                                        @closeWidget="closeGroup4"
                                    ></deposit-overview-widget>
                                </div>
                            </div>
                            <div
                                class="row mobile-modal"
                                v-if="showMeterSubmitWidget"
                            >
                                <meter-submit-widget
                                    @closeWidget="closeGroup1"
                                ></meter-submit-widget>
                            </div>
                            <!-- ROW -->
                            <!-- <ecar-upload-widget></ecar-upload-widget>-->
                            <!-- ROW -->
                            <div class="row" v-if="!isNonCommodityContract">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <tariff-details-widget
                                        @toggleWidget="toggleGroup4"
                                        @closeWidget="closeGroup3"
                                    ></tariff-details-widget>
                                </div>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showTariffChangeWidget"
                            >
                                <div class="row-items w-100">
                                    <tariff-change-widget
                                        @closeWidget="closeGroup4"
                                    ></tariff-change-widget>
                                </div>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showTariffDetailsWidget && isNonCommodityContract"
                            >
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                >
                                    <non-commodity-tariff-details-widget
                                        @toggleWidget="toggleGroup3"
                                        @closeWidget="closeGroup3"
                                    ></non-commodity-tariff-details-widget>
                                </div>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showMoveWidget"
                            >
                                <div class="row-items w-100">
                                    <move-widget
                                        @closeWidget="closeGroup4"
                                    ></move-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showTariffOverview"
                            >
                                <div class="row-items w-100">
                                    <tariff-overview-widget
                                        @closeWidget="closeGroup4"
                                    ></tariff-overview-widget>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                    v-if="showMeterHistoryWidget && !hideMeterHistoryWidget"
                                >
                                    <meter-history-widget
                                        :isUsageHistoryWidgetEnabled="isUsageHistoryWidgetEnabled"
                                        @toggleHistoryWidget="toggleHistoryWidget"
                                        v-if="isNoDashboardRedirect"
                                    ></meter-history-widget>
                                </div>
                                <div
                                    class="col-md-12 col-sm-12 col-xs-12 row-items"
                                    v-if="isUsageHistoryWidgetEnabled && showUsageHistoryWidget"
                                >
                                    <usage-history-widget
                                        @toggleHistoryWidget="toggleHistoryWidget"
                                    ></usage-history-widget>
                                </div>
                            </div>

                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showRevocationWidget"
                            >
                                <div class="row-items w-100">
                                    <revoke-widget
                                        @toggleWidget="toggleGroup3"
                                        @closeWidget="closeGroup4"
                                    ></revoke-widget>
                                </div>
                            </div>
                        </div>
                        <!-- ROW -->
                        <div class="row" v-if="showPostBoxWidget">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                            >
                                <post-box-widget></post-box-widget>
                            </div>
                        </div>
                        <!-- ROW -->
                        <div class="row" v-if="showCustomerServiceFormWidget">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                            >
                                <customer-service-form-widget></customer-service-form-widget>
                            </div>
                        </div>
                        <!-- ROW -->
                        <div class="row" v-if="showSalesWidget">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                            >
                                <sales-widget></sales-widget>
                            </div>
                        </div>

                        <!-- ROW -->
                        <div class="row">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                            >
                                <hotline-widget
                                    @toggleGroup="toggleGroup4"
                                ></hotline-widget>
                            </div>
                        </div>

                        <!-- ROW -->
                        <div
                            class="row mobile-modal"
                            v-if="showGroup4.showCancellationWidget"
                        >
                            <div class="row-items w-100">
                                <cancellation-widget
                                    @closeWidget="closeGroup4"
                                ></cancellation-widget>
                            </div>
                        </div>

                        <ads-popup-widget
                            v-if="showAgreeAdsPopup && displayElement('show-ads-popup')"
                            @send-ads-data="sendAdsData"
                        />

                        <app-footer
                            v-if="displayElement('app-footer-inside-container')"
                        ></app-footer>
                    </div>
                </div>
                <div
                    v-else-if="noContractsAvailable"
                    class="container main-wrap customer-data-change-no-contracts"
                >
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            {{ $t('widget.welcome') }}<br /><br /><br />
                            {{ $t('widget.welcome.noContract') }}
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <br /><br />
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 row-items">
                            <change-customer-data-widget></change-customer-data-widget>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="container main-wrap"
                    style="height: 90vh"
                ></div>
            </div>
            <!-- END: Body -->
        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <template
            v-if="displayElement('hide-app-footer-outside-container')"
        ></template>
        <app-footer v-else></app-footer>
        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->
    <!-- begin::Scroll Top -->
    <scroll-top></scroll-top>
    <!-- end::Scroll Top -->
    <cookie-banner-widget
        v-if="displayElement('cookie-banner')"
    ></cookie-banner-widget>
</div>
