import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './recommendation-widget.html';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Auth from '@/interfaces/Auth';
import Bonus from '@/interfaces/Bonus';
import _ from 'lodash';

import * as Sentry from '@sentry/browser';
import { MessagesBlock } from '@/components/snippets/messages';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const bonusProvider = IocContainer.get<Bonus>(SERVICES.BONUS);

@WithRender
@Component({
    components: {
        'messages-block': MessagesBlock,
    },
})
export class RecommendationWidget extends Base {
    protected showDescription = true;
    protected bonusCode: string = '';
    public bonusValue: string = '';
    public acceptTerms = false;

    protected submitPending = false;
    protected verificationPending = false;
    protected isVerificationSuccessful = false;
    protected errorInformation: Record<any, any> = [];
    protected successInformation: Record<any, any> = [];

    private timeout: NodeJS.Timeout | null = null;

    public showForm(): void {
        this.showDescription = false;
    }

    public hideForm(): void {
        this.showDescription = true;
    }

    public toggleForm(): void {
        if (this.showDescription) {
            this.hideForm();
        } else {
            this.showForm();
        }
    }

    public getRecommendationImageUrl(): string {
        let recommendationImage: string =
            '/img/default/recommendation-image.png';
        if (process.env.VUE_APP_ENVIRONMENT === 'elli') {
            recommendationImage = '/img/evus/elli/recommendation-image.png';
        }
        return recommendationImage;
    }

    public getDisclaimerUrl(): string {
        const disclaimerUrl = this.$t(
            'widget.recommendation.disclaimer.link.url'
        ).toString();
        return disclaimerUrl;
    }

    public submitBonus(): void {
        if (authProvider.isAuthenticated()) {
            this.errorInformation = [];
            this.successInformation = [];

            if (!this.acceptTerms) {
                this.errorInformation.push({
                    key: 'missing.terms.check',
                    message: this.$t(
                        'widget.recommendation.missing.terms.check'
                    ).toLocaleString(),
                });
                return;
            }

            if (!this.bonusCode || this.bonusCode.length == 0) {
                this.errorInformation.push({
                    key: 'missing.code',
                    message: this.$t(
                        'widget.recommendation.missing.code'
                    ).toLocaleString(),
                });
                return;
            }

            this.submitPending = true;

            const data = {
                code: this.bonusCode,
                contractId: this.$store.state.contracts.contractId,
            };

            bonusProvider.redeem(data).then(
                (response) => {
                    this.submitPending = false;
                    if (response.data.success) {
                        this.onRedemptionSuccess(response);
                    } else {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }

    protected showRedemptionSuccessMessage(response): void {
        this.successInformation.push({
            key: 'verify.success',
            message: this.$t(
                'widget.recommendation.redeem.success'
            ).toLocaleString(),
        });
    }

    protected onRedemptionSuccess(response): void {
        if (response.data.redeemed || response.data.queued) {
            this.bonusValue = response.data.value;
            this.showRedemptionSuccessMessage(response);
        } else {
            this.errorInformation.push({
                key: response.data.messageLocalized.key,
                message: response.data.messageLocalized.message,
            });
        }
    }

    public verifyBonus(): void {
        this.updateBonusValue();
    }

    public updateBonusValue(): void {
        this.verificationPending = true;
        this.isVerificationSuccessful = false;
        this.errorInformation = [];

        if (authProvider.isAuthenticated()) {
            bonusProvider.verify({ code: this.bonusCode }).then(
                (response) => {
                    if (response.data.success) {
                        if (response.data.valid) {
                            this.bonusValue = response.data.value;
                            this.isVerificationSuccessful = true;
                        } else {
                            this.errorInformation.push({
                                key: 'verify.error',
                                message: this.$t(
                                    'widget.recommendation.verify.error'
                                ).toLocaleString(),
                            });
                        }
                    }
                    this.verificationPending = false;
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                    this.verificationPending = false;
                }
            );
        }
    }

    public verifyBonusTimeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(() => {
            this.updateBonusValue();
        }, 800);
    }
}
