<div class="maintenance-wrapper">
    <div class="bg-purple">
        <div class="stars">
            <div class="central-body">
                <img
                    v-if="maintenanceShowLogo"
                    :src="loginLogoUrl"
                    class="maintenance-client-logo"
                    alt="logo"
                />
                <h1>WARTUNGSMODUS</h1>
                <h2 v-html="showMessageTimer"></h2>
            </div>
            <div class="objects">
                <img
                    class="object_rocket"
                    src="/img/default/maintenance/rocket.svg"
                    width="40px"
                    alt=""
                />
                <div class="earth-moon">
                    <img
                        class="object_earth"
                        src="/img/default/maintenance/earth.svg"
                        width="100px"
                        alt=""
                    />
                    <img
                        class="object_moon"
                        src="/img/default/maintenance/moon.svg"
                        width="80px"
                        alt=""
                    />
                </div>
                <div class="box_astronaut">
                    <img
                        class="object_astronaut"
                        src="/img/default/maintenance/astronaut.svg"
                        width="140px"
                        alt=""
                    />
                </div>
            </div>
            <div class="glowing_stars">
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
            </div>
        </div>
    </div>
</div>
