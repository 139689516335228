import { default as Component } from 'vue-class-component';
import WithRender from './header.html';
import { logoMap } from '@/map/logoMap';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {},
})
export class Header extends Base {
    protected elements = {
        'elli-thg-cupra': ['hide-logo'],
        'elli-thg-skoda': ['hide-logo'],
        'elli-thg-vw': ['hide-logo'],
        'elli-thg-vwfs': ['hide-logo'],
        'elli-thg-freenet': ['hide-logo'],
        'elli-thg-elli': ['hide-logo'],
        'elli-thg-man': ['hide-logo'],
    };

    public headerLogo(color = '', extension = 'svg', specialName = ''): string {
        if (logoMap[this.currentClient]) {
            return logoMap[this.currentClient];
        } else if (specialName !== '') {
            return `/img/evus/${this.currentClient}/${specialName}`;
        } else {
            return `/img/evus/${this.currentClient}/logo_${color}.${extension}`;
        }
    }

    get isNotAuthPage(): boolean {
        return this.$route.path !== '/' && this.$route.path !== '/register';
    }

    get brandAuth(): Record<any, any> {
        return { 'm-brand--auth': !this.isNotAuthPage };
    }
}
