<div
    class="m-portlet m-portlet--mobile m-portlet--invoice fleet-manager-quotas"
>
    <div class="m-portlet__body">
        <div class="fleet-manager-quotas__header">
            <div class="title">
                <span class="dealer-quotas-title"
                    >{{ $t('widget.fleet.manager.quotas.title') }}</span
                >
                <span>{{ $t('widget.fleet.manager.quotas.subtitle') }}</span>
            </div>
            <div v-if="false" class="header-actions">
                <button @click.prevent="openGenericReUpload()">
                    {{ $t('widget.fleet.manager.quotas.process.contracts') }}
                </button>
            </div>
        </div>
        <div class="datatable">
            <table id="datatable">
                <thead>
                    <tr>
                        <th>
                            <div @click="toggleSort('contract')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.contract')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'contract'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('vin')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.vin')
                                    }}</span
                                >
                                <span v-if="requestParams.sortedKey === 'vin'">
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('class')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.class')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'class'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('registration')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.registration')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'registration'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('year')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.quota.year')
                                    }}</span
                                >
                                <span v-if="requestParams.sortedKey === 'year'">
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('status')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.status')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'status'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div @click="toggleSort('price')">
                                <span
                                    >{{
                                    $t('widget.fleet.manager.quotas.table.header.price')
                                    }}</span
                                >
                                <span
                                    v-if="requestParams.sortedKey === 'price'"
                                >
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 0L11.1962 5.25H0.803848L6 0Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg
                                        width="12"
                                        height="6"
                                        viewBox="0 0 12 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 6L0.803848 0.75L11.1962 0.75L6 6Z"
                                            fill="#575962"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="dataSets.length > 0">
                        <tr
                            v-for="(data,dataIndex) in sortedData"
                            :key="dataIndex"
                        >
                            <td>
                                <span>{{ data.contractId }}</span>
                            </td>
                            <td>
                                <span>{{ data.vin }}</span>
                            </td>
                            <td><span>{{ data.class }}</span></td>
                            <td>
                                <span
                                    >{{ data.registration === 'NONE' ?
                                    $t('widget.fleet.manager.quotas.unknown') :
                                    formatData(data.registration) }}</span
                                >
                            </td>
                            <td><span>{{ data.year }}</span></td>

                            <td>
                                <span
                                    :class="['status', getStatusClass(data.status)]"
                                ></span
                                ><span
                                    ><template v-if="isRedStatus(data.status)"
                                        ><a
                                            href="https://www.elli.eco/downloads/de/thg/Anhang_Legende_THG_Statuserklaerungsuebersicht.pdf"
                                            target="_blank"
                                            >{{
                                            $t('widget.fleet.manager.quotas.status.'+data.status)
                                            }}</a
                                        ></template
                                    ><template v-else
                                        >{{
                                        $t('widget.fleet.manager.quotas.status.'+data.status)
                                        }}</template
                                    ><i
                                        class="info-tooltip"
                                        v-tooltip.top="$t('widget.fleet.manager.quotas.tooltip.'+data.status)"
                                    ></i
                                ></span>
                            </td>
                            <td>
                                <span
                                    >{{ data.commission | euro}}
                                    {{$t('euro.symbol')}}</span
                                >
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr class="no-data">
                            <td colspan="7">
                                <div>{{ $t('widget.dq.no.data') }}</div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <!--            <div class="pagination" v-if="totalCount > requestParams.take">-->
            <div class="pagination">
                <div class="back" @click="firstPage">
                    <svg
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 4.5L8.5 0.602886V8.39711L1 4.5Z"
                            :fill="backColor"
                        />
                        <line x1="0.5" x2="0.5" y2="9" :stroke="backColor" />
                    </svg>
                </div>
                <div class="begin" @click="prevPage">
                    <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 4.5L13.5 0.602886V8.39711L6 4.5Z"
                            :fill="backColor"
                        />
                        <path
                            d="M0 4.5L7.5 0.602886L7.5 8.39711L0 4.5Z"
                            :fill="backColor"
                        />
                    </svg>
                </div>
                <div class="content">
                    <span>{{ $t('widget.dq.pagination.page') }}</span>
                    <span>{{ currentPage }}</span>
                    <span>{{ $t('widget.dq.pagination.of') }}</span>
                    <span>{{ totalPage }}</span>
                </div>
                <div class="forward" @click="nextPage">
                    <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 4.5L2.5 0.602886L2.5 8.39711L10 4.5Z"
                            :fill="forwardColor"
                        />
                        <path
                            d="M16 4.5L8.5 0.602886V8.39711L16 4.5Z"
                            :fill="forwardColor"
                        />
                    </svg>
                </div>
                <div class="end" @click="lastPage">
                    <svg
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 4.5L2.5 0.602886L2.5 8.39711L10 4.5Z"
                            :fill="forwardColor"
                        />
                        <line
                            y1="-0.5"
                            x2="9"
                            y2="-0.5"
                            transform="matrix(0 1 1 0 11 0)"
                            :stroke="forwardColor"
                        />
                    </svg>
                </div>
                <!--                <a href="javascript:" v-bind:class="{'isDisabled':currentPage==1}" v-on:click="newPage(currentPage-1)">&laquo;</a>-->
                <!--                <a href="javascript:" v-on:click="newPage(page)" v-bind:class="{'active':currentPage==page}"-->
                <!--                   v-for="(page,pageIndex) in pages"-->
                <!--                   v-bind:key="pageIndex" v-bind:disables="currentPage ===page || page === '...'">{{page}}</a>-->
                <!--                <a v-on:click="newPage(currentPage+1)" :class="{'isDisabled':currentPage==totalPage}"-->
                <!--                   href="javascript:">&raquo;</a>-->
            </div>
        </div>

        <div class="issued-contracts">
            <div class="issued-contracts-title">
                {{ $t('widget.fleet.manager.quotas.stopped.title', {count:
                    stoppedContracts.length}) }}
            </div>
            <div v-if="stoppedContracts.length > 0">
                {{ $t('widget.fleet.manager.quotas.stopped.subtitle') }}
            </div>
            <div v-else class="no-issues">
                <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.5995 3.69251L7.08368 15.2075C7.00625 15.2852 6.91424 15.3469 6.81292 15.389C6.71161 15.4311 6.60297 15.4527 6.49326 15.4527C6.38355 15.4527 6.27492 15.4311 6.1736 15.389C6.07228 15.3469 5.98027 15.2852 5.90285 15.2075L1.44951 10.75C1.37209 10.6723 1.28008 10.6106 1.17876 10.5685C1.07744 10.5264 0.968807 10.5048 0.859095 10.5048C0.749384 10.5048 0.640752 10.5264 0.539433 10.5685C0.438115 10.6106 0.346103 10.6723 0.268678 10.75C0.190948 10.8274 0.129271 10.9194 0.0871861 11.0208C0.0451014 11.1221 0.0234375 11.2307 0.0234375 11.3404C0.0234375 11.4501 0.0451014 11.5588 0.0871861 11.6601C0.129271 11.7614 0.190948 11.8534 0.268678 11.9308L4.72368 16.385C5.19364 16.8541 5.83051 17.1175 6.49451 17.1175C7.15851 17.1175 7.79539 16.8541 8.26535 16.385L19.7803 4.87251C19.858 4.7951 19.9195 4.70314 19.9615 4.6019C20.0035 4.50065 20.0252 4.39212 20.0252 4.28251C20.0252 4.17289 20.0035 4.06436 19.9615 3.96312C19.9195 3.86188 19.858 3.76992 19.7803 3.69251C19.7029 3.61478 19.6109 3.5531 19.5096 3.51101C19.4083 3.46893 19.2996 3.44727 19.1899 3.44727C19.0802 3.44727 18.9716 3.46893 18.8703 3.51101C18.7689 3.5531 18.6769 3.61478 18.5995 3.69251Z"
                        fill="#2AA000"
                    />
                </svg>
                {{ $t('widget.fleet.manager.quotas.no-issues') }}
            </div>
            <div class="failed-contracts">
                <div
                    class="failed-contracts__item"
                    v-for="stoppedContract in stoppedContracts"
                >
                    <div class="chevron"></div>
                    <div class="failed-contracts__item-info">
                        <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_1881_7055)">
                                <path
                                    d="M8.5 0.5C6.91775 0.5 5.37104 0.969192 4.05544 1.84824C2.73985 2.72729 1.71447 3.97672 1.10897 5.43853C0.503466 6.90034 0.34504 8.50887 0.653721 10.0607C0.962403 11.6126 1.72433 13.038 2.84315 14.1569C3.96197 15.2757 5.38743 16.0376 6.93928 16.3463C8.49113 16.655 10.0997 16.4965 11.5615 15.891C13.0233 15.2855 14.2727 14.2602 15.1518 12.9446C16.0308 11.629 16.5 10.0823 16.5 8.5C16.4977 6.37897 15.6541 4.34547 14.1543 2.84568C12.6545 1.34589 10.621 0.502294 8.5 0.5V0.5ZM8.5 15.1667C7.18146 15.1667 5.89253 14.7757 4.7962 14.0431C3.69987 13.3106 2.84539 12.2694 2.34081 11.0512C1.83622 9.83305 1.7042 8.49261 1.96144 7.1994C2.21867 5.90619 2.85361 4.71831 3.78596 3.78596C4.71831 2.85361 5.9062 2.21867 7.1994 1.96143C8.49261 1.7042 9.83305 1.83622 11.0512 2.3408C12.2694 2.84539 13.3106 3.69987 14.0431 4.7962C14.7757 5.89253 15.1667 7.18146 15.1667 8.5C15.1647 10.2675 14.4617 11.9621 13.2119 13.2119C11.9621 14.4617 10.2675 15.1647 8.5 15.1667Z"
                                    fill="#E40045"
                                />
                                <path
                                    d="M8.50065 3.83301C8.32384 3.83301 8.15427 3.90325 8.02925 4.02827C7.90422 4.15329 7.83398 4.32286 7.83398 4.49967V9.83301C7.83398 10.0098 7.90422 10.1794 8.02925 10.3044C8.15427 10.4294 8.32384 10.4997 8.50065 10.4997C8.67747 10.4997 8.84704 10.4294 8.97206 10.3044C9.09709 10.1794 9.16733 10.0098 9.16733 9.83301V4.49967C9.16733 4.32286 9.09709 4.15329 8.97206 4.02827C8.84704 3.90325 8.67747 3.83301 8.50065 3.83301Z"
                                    fill="#E40045"
                                />
                                <path
                                    d="M9.16733 12.4997C9.16733 12.1315 8.86885 11.833 8.50065 11.833C8.13246 11.833 7.83398 12.1315 7.83398 12.4997C7.83398 12.8679 8.13246 13.1663 8.50065 13.1663C8.86885 13.1663 9.16733 12.8679 9.16733 12.4997Z"
                                    fill="#E40045"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1881_7055">
                                    <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                        transform="translate(0.5 0.5)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <span class="contract-number"
                            >{{
                            $t('widget.fleet.manager.quotas.table.header.contract')
                            }} {{ stoppedContract.contractId }}</span
                        >
                        <span
                            >{{
                            $t('widget.fleet.manager.quotas.contract.status.' +
                            stoppedContract.status) }}</span
                        >
                    </div>
                    <div class="action">
                        <button @click="openReUpload(stoppedContract)">
                            {{ $t('widget.fleet.manager.quotas.upload.again') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isReUploadOpen" class="upload-again">
            <div class="m-aside-modal m-aside-modal__wrap">
                <div class="m-aside-modal__body">
                    <div class="upload-again-content" v-if="!isSuccessStep">
                        <template v-if="generalReUpload">
                            <div class="upload-again-form-item">
                                <label
                                    >{{
                                    $t('widget.fleet.manager.quotas.upload.again.select.contract')
                                    }}</label
                                >
                                <div class="select-wrapper">
                                    <select v-model="genericReUploadContract">
                                        <option :value="null" disabled selected>
                                            {{
                                            $t('widget.fleet.manager.quotas.upload.again.select.contract.option')
                                            }}
                                        </option>
                                        <option
                                            v-for="contractItem in dataSets"
                                            :key="contractItem.contractId"
                                            :value="contractItem"
                                        >
                                            {{
                                            $t('widget.fleet.manager.quotas.upload.again.contract.option.label',
                                            {contract: contractItem.contractId})
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="upload-again-form-item">
                                <label
                                    >{{
                                    $t('widget.fleet.manager.quotas.upload.again.select.action')
                                    }}</label
                                >
                                <div class="select-wrapper">
                                    <select v-model="genericReUploadAction">
                                        <option :value="null" disabled selected>
                                            {{
                                            $t('widget.fleet.manager.quotas.upload.again.select.action.option')
                                            }}
                                        </option>
                                        <option value="upload">
                                            {{
                                            $t('widget.fleet.manager.quotas.upload.again.select.action.upload')
                                            }}
                                        </option>
                                        <option value="cancel">
                                            {{
                                            $t('widget.fleet.manager.quotas.upload.again.select.action.cancel')
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="upload-again-content__title">
                                {{
                                $t('widget.fleet.manager.quotas.upload.again.title',
                                {contract: toReUpload.contractId}) }}
                            </div>
                            <div class="upload-again-content__subtitle">
                                {{
                                $t('widget.fleet.manager.quotas.upload.again.subtitle')
                                }}
                            </div>
                            <div class="upload-again-content__notice">
                                <div class="upload-again-content__notice-icon">
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_1881_7055)">
                                            <path
                                                d="M8.5 0.5C6.91775 0.5 5.37104 0.969192 4.05544 1.84824C2.73985 2.72729 1.71447 3.97672 1.10897 5.43853C0.503466 6.90034 0.34504 8.50887 0.653721 10.0607C0.962403 11.6126 1.72433 13.038 2.84315 14.1569C3.96197 15.2757 5.38743 16.0376 6.93928 16.3463C8.49113 16.655 10.0997 16.4965 11.5615 15.891C13.0233 15.2855 14.2727 14.2602 15.1518 12.9446C16.0308 11.629 16.5 10.0823 16.5 8.5C16.4977 6.37897 15.6541 4.34547 14.1543 2.84568C12.6545 1.34589 10.621 0.502294 8.5 0.5V0.5ZM8.5 15.1667C7.18146 15.1667 5.89253 14.7757 4.7962 14.0431C3.69987 13.3106 2.84539 12.2694 2.34081 11.0512C1.83622 9.83305 1.7042 8.49261 1.96144 7.1994C2.21867 5.90619 2.85361 4.71831 3.78596 3.78596C4.71831 2.85361 5.9062 2.21867 7.1994 1.96143C8.49261 1.7042 9.83305 1.83622 11.0512 2.3408C12.2694 2.84539 13.3106 3.69987 14.0431 4.7962C14.7757 5.89253 15.1667 7.18146 15.1667 8.5C15.1647 10.2675 14.4617 11.9621 13.2119 13.2119C11.9621 14.4617 10.2675 15.1647 8.5 15.1667Z"
                                                fill="#E40045"
                                            />
                                            <path
                                                d="M8.50065 3.83301C8.32384 3.83301 8.15427 3.90325 8.02925 4.02827C7.90422 4.15329 7.83398 4.32286 7.83398 4.49967V9.83301C7.83398 10.0098 7.90422 10.1794 8.02925 10.3044C8.15427 10.4294 8.32384 10.4997 8.50065 10.4997C8.67747 10.4997 8.84704 10.4294 8.97206 10.3044C9.09709 10.1794 9.16733 10.0098 9.16733 9.83301V4.49967C9.16733 4.32286 9.09709 4.15329 8.97206 4.02827C8.84704 3.90325 8.67747 3.83301 8.50065 3.83301Z"
                                                fill="#E40045"
                                            />
                                            <path
                                                d="M9.16733 12.4997C9.16733 12.1315 8.86885 11.833 8.50065 11.833C8.13246 11.833 7.83398 12.1315 7.83398 12.4997C7.83398 12.8679 8.13246 13.1663 8.50065 13.1663C8.86885 13.1663 9.16733 12.8679 9.16733 12.4997Z"
                                                fill="#E40045"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1881_7055">
                                                <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                    transform="translate(0.5 0.5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div class="upload-again-content__notice-text">
                                    <span
                                        >{{
                                        $t('widget.fleet.manager.quotas.upload.again.notice')
                                        }}</span
                                    >
                                </div>
                            </div>
                        </template>
                        <div
                            v-if="!generalReUpload || genericReUploadAction === 'upload'"
                            class="upload-again-content__upload-area"
                            :class="{ error: fileUploadError}"
                        >
                            <template v-if="document.name">
                                <div class="uploaded-file">
                                    <div class="uploaded-file__name">
                                        {{ document.name }}
                                    </div>
                                    <div
                                        class="uploaded-file__remove"
                                        @click="removeFile"
                                    ></div>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <label
                                        for="file-upload"
                                        class="file-upload"
                                    >
                                        <input
                                            type="file"
                                            id="file-upload"
                                            accept="application/pdf,image/jpeg"
                                            ref="fileList"
                                            class="upload-input"
                                            @change="processFile($event)"
                                        />
                                        {{$t('widget.fleet.manager.quotas.document.select.file')}}
                                    </label>
                                </div>
                                <div
                                    v-if="fileUploadError"
                                    class="file-upload-error"
                                >
                                    {{
                                    $t('widget.fleet.manager.quotas.document.file.error')
                                    }}
                                </div>
                                <div class="file-upload-requirements">
                                    {{
                                    $t('widget.fleet.manager.quotas.document.file.requirements')
                                    }}
                                </div>
                            </template>
                        </div>
                        <div
                            class="reupload-notice"
                            v-if="genericReUploadAction === 'upload'"
                        >
                            {{
                            $t('widget.fleet.manager.quotas.document.file.reupload.notice')
                            }}
                        </div>
                        <template v-if="genericReUploadAction === 'cancel'">
                            <div class="cancellation-notice">
                                {{
                                $t('widget.fleet.manager.quotas.cancellation.notice')
                                }}
                            </div>
                            <div class="cancel-contract-label">
                                {{
                                $t('widget.fleet.manager.quotas.cancellation.contract',
                                {contract: genericReUploadContract.contractId})
                                }}
                            </div>
                        </template>

                        <div class="actions">
                            <button
                                @click="closeReUpload"
                                class="btn btn-secondary d-block back"
                            >
                                {{ $t('widget.fleet.manager.quotas.back') }}
                            </button>
                            <button
                                v-if="!uploadingToS3"
                                class="btn btn-primary d-block upload"
                                :disabled="!document.name && genericReUploadAction != 'cancel'"
                                @click="submitProcess"
                            >
                                <span class="loader" v-if="isLoading"></span>
                                <template
                                    v-if="genericReUploadAction === 'cancel'"
                                >
                                    {{
                                    $t('widget.fleet.manager.quotas.cancel.button')
                                    }}
                                </template>
                                <template v-else>
                                    {{
                                    $t('widget.fleet.manager.quotas.upload.button')
                                    }}
                                </template>
                            </button>
                            <button
                                v-else
                                class="btn btn-primary d-block upload uploading"
                            >
                                <span class="loader"></span>
                                {{
                                $t('widget.fleet.manager.quotas.uploading.button')
                                }}
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="success-step-content">
                            <div class="success-step-content__icon">
                                <svg
                                    v-if="cancellationFailed"
                                    width="49"
                                    height="48"
                                    viewBox="0 0 49 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M29.4998 18.9998C29.3436 18.8436 29.1317 18.7559 28.9107 18.7559C28.6897 18.7559 28.4778 18.8436 28.3215 18.9998L24.4998 22.8215L20.6782 18.9998C20.5219 18.8436 20.31 18.7559 20.089 18.7559C19.868 18.7559 19.6561 18.8436 19.4998 18.9998C19.3436 19.1561 19.2559 19.368 19.2559 19.589C19.2559 19.81 19.3436 20.0219 19.4998 20.1782L23.3215 23.9998L19.4998 27.8215C19.3436 27.9778 19.2559 28.1897 19.2559 28.4107C19.2559 28.6317 19.3436 28.8436 19.4998 28.9998C19.6561 29.1561 19.868 29.2438 20.089 29.2438C20.31 29.2438 20.5219 29.1561 20.6782 28.9998L24.4998 25.1782L28.3215 28.9998C28.4778 29.1561 28.6897 29.2438 28.9107 29.2438C29.1317 29.2438 29.3436 29.1561 29.4998 28.9998C29.6561 28.8436 29.7438 28.6317 29.7438 28.4107C29.7438 28.1897 29.6561 27.9778 29.4998 27.8215L25.6782 23.9998L29.4998 20.1782C29.6561 20.0219 29.7438 19.81 29.7438 19.589C29.7438 19.368 29.6561 19.1561 29.4998 18.9998Z"
                                        fill="#FF5757"
                                    />
                                    <rect
                                        x="1.25"
                                        y="0.75"
                                        width="46.5"
                                        height="46.5"
                                        rx="23.25"
                                        stroke="#FF0F0F"
                                        stroke-width="1.5"
                                    />
                                </svg>
                                <svg
                                    v-else
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M32.5995 17.6925L21.0837 29.2075C21.0063 29.2852 20.9142 29.3469 20.8129 29.389C20.7116 29.4311 20.603 29.4527 20.4933 29.4527C20.3836 29.4527 20.2749 29.4311 20.1736 29.389C20.0723 29.3469 19.9803 29.2852 19.9028 29.2075L15.4495 24.75C15.3721 24.6723 15.2801 24.6106 15.1788 24.5685C15.0774 24.5264 14.9688 24.5048 14.8591 24.5048C14.7494 24.5048 14.6408 24.5264 14.5394 24.5685C14.4381 24.6106 14.3461 24.6723 14.2687 24.75C14.1909 24.8274 14.1293 24.9194 14.0872 25.0208C14.0451 25.1221 14.0234 25.2307 14.0234 25.3404C14.0234 25.4501 14.0451 25.5588 14.0872 25.6601C14.1293 25.7614 14.1909 25.8534 14.2687 25.9308L18.7237 30.385C19.1936 30.8541 19.8305 31.1175 20.4945 31.1175C21.1585 31.1175 21.7954 30.8541 22.2653 30.385L33.7803 18.8725C33.858 18.7951 33.9195 18.7031 33.9615 18.6019C34.0035 18.5007 34.0252 18.3921 34.0252 18.2825C34.0252 18.1729 34.0035 18.0644 33.9615 17.9631C33.9195 17.8619 33.858 17.7699 33.7803 17.6925C33.7029 17.6148 33.6109 17.5531 33.5096 17.511C33.4083 17.4689 33.2996 17.4473 33.1899 17.4473C33.0802 17.4473 32.9716 17.4689 32.8703 17.511C32.7689 17.5531 32.6769 17.6148 32.5995 17.6925Z"
                                        fill="#1EA97C"
                                    />
                                    <rect
                                        x="0.75"
                                        y="0.75"
                                        width="46.5"
                                        height="46.5"
                                        rx="23.25"
                                        stroke="#1EA97C"
                                        stroke-width="1.5"
                                    />
                                </svg>
                            </div>
                            <template v-if="genericReUploadAction === 'cancel'">
                                <template v-if="cancellationFailed">
                                    <div
                                        class="success-step-content__title failed"
                                    >
                                        {{
                                        $t('widget.fleet.manager.quotas.success.cancel.title.failed')
                                        }}
                                    </div>
                                    <div class="mb-3">
                                        {{
                                        $t('widget.fleet.manager.quotas.success.cancel.text.failed')
                                        }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="success-step-content__title">
                                        {{
                                        $t('widget.fleet.manager.quotas.success.cancel.title',
                                        {contract: toReUpload.contractId}) }}
                                    </div>
                                    <div class="mb-3">
                                        {{
                                        $t('widget.fleet.manager.quotas.success.cancel.text',
                                        {contract: toReUpload.contractId}) }}
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="success-step-content__title">
                                    {{
                                    $t('widget.fleet.manager.quotas.success.title',
                                    {contract: toReUpload.contractId}) }}
                                </div>
                                <div class="success-step-content__list">
                                    <ul>
                                        <li>
                                            {{
                                            $t('widget.fleet.manager.quotas.success.text.1')
                                            }}
                                        </li>
                                        <li>
                                            {{
                                            $t('widget.fleet.manager.quotas.success.text.2')
                                            }}
                                        </li>
                                        <li>
                                            {{
                                            $t('widget.fleet.manager.quotas.success.text.3')
                                            }}
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <div class="actions">
                                <button
                                    class="btn btn-primary d-block"
                                    @click="closeReUpload"
                                >
                                    {{
                                    $t('widget.fleet.manager.quotas.success.button')
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="totals">
            <div class="totals-header">
                <span
                    >{{ $t('widget.fleet.manager.quotas.totals.title') }}:</span
                >
            </div>
            <div class="totals-content">
                <div class="totals-content-item">
                    <span
                        ><span class="status open-status"></span>{{
                        $t('widget.fleet.manager.quotas.totals.open') }}:</span
                    >
                    <span>{{ totalOpen | euro }} {{$t('euro.symbol')}}</span>
                </div>
                <div class="separator"></div>
                <div class="totals-content-item">
                    <span
                        ><span class="status successful-status"></span>{{
                        $t('widget.fleet.manager.quotas.totals.successful')
                        }}:</span
                    >
                    <span
                        >{{ totalSuccessful | euro }}
                        {{$t('euro.symbol')}}</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
