import { AxiosResponse } from 'axios';
import SubmitReading from './SubmitReading';

export default interface Reading {
    next(id: number): Promise<AxiosResponse<any>>;
    history(id: number): Promise<AxiosResponse<any>>;
    details(id: number): Promise<AxiosResponse<any>>;
    submitMeterRead(data: SubmitReading): Promise<AxiosResponse<any>>;
    invoiceSimulate(
        contractId: number,
        dateUntil: string
    ): Promise<AxiosResponse<any>>;
}

export enum ReadingState {
    MOVE_IN_MISSING = 'moveInMissing',
    MOVE_OUT_MISSING = 'moveOutMissing',
    WAITING = 'waiting',
    WAITING_PAST = 'waitingPast',
    TURNUS = 'turnus',
    TURNUS_NOW = 'turnusNow',
}
