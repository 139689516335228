import { DepositChangeWidgetEts } from '@/components/widgets/deposit-change-widget/custom/ets';
import { DepositChangeWidgetSchweizstrom } from '@/components/widgets/deposit-change-widget/custom/schweizstrom';
import { DepositChangeWidget } from '@/components/widgets/deposit-change-widget/deposit-change-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = DepositChangeWidgetSchweizstrom;
} else {
    widget = DepositChangeWidget;
}
export { widget as DepositChangeWidget };
