import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';

@Component({
    components: {},
})
export class BaseFormElement extends Base {
    @Prop() public value!: string;
    @Prop() public form!: string;
    @Prop() public label: boolean | undefined;
    @Prop() public id!: string;
    @Prop() public input!: string;
    @Prop() public type!: string;

    @Inject('form') public injectedForm!: string;
    @Inject('id') public injectedId!: string;

    public setValue(e: any): void {
        this.$emit(e.type, e.target.value);
    }

    get getForm(): string {
        if (this.form) {
            return this.form;
        } else if (this.injectedForm) {
            return this.injectedForm;
        } else {
            throw new Error('No form was provided');
        }
    }

    get getId(): string {
        if (this.id) {
            return this.id;
        } else if (this.injectedId) {
            return this.injectedId;
        } else {
            this.warn('No id provided for input');
            return '';
        }
    }
}
