import { ChangeWidgetEoptimum } from '@/components/widgets/change-widget/custom/eoptimum';
import { ChangeWidgetEts } from '@/components/widgets/change-widget/custom/ets';
import { ChangeWidgetSchweizstrom } from '@/components/widgets/change-widget/custom/schweizstrom';
import { ChangeWidgetWdenergyUs } from '@/components/widgets/change-widget/custom/wdenergy-us';
import { ChangeWidget } from '@/components/widgets/change-widget/change-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'eoptimum') {
    widget = ChangeWidgetEoptimum;
} else if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = ChangeWidgetEts;
} else if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = ChangeWidgetSchweizstrom;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = ChangeWidgetWdenergyUs;
} else {
    widget = ChangeWidget;
}
export { widget as ChangeWidget };
