<div>
    <div class="container text-center mt-4">
        <h2>{{ $t('protected.title') }}</h2>

        <div class="row">
            <div class="col-md-6 mx-auto">
                <form v-on:submit.prevent="validate">
                    <div class="form-group text-start">
                        <input
                            class="form-control password-field"
                            :placeholder="$t('login.password')"
                            type="password"
                            name="password"
                            v-model.trim="password"
                        />
                        <span class="error help-block"></span>
                    </div>
                    <div class="text-danger" v-if="error">
                        <p>{{ $t('protected.password.error') }}</p>
                    </div>
                    <button class="btn btn-primary" type="submit">
                        {{ $t('protected.submit') }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
