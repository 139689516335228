import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './customer-data-correction-widget.html';

import { Datetime } from 'vue-datetime';
import vSelect from 'vue-select';
import 'vue-datetime/dist/vue-datetime.css';
import * as Sentry from '@sentry/browser';

import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import SubmitCustomerDataCorrection from '@/interfaces/Customer/SubmitCustomerDataCorrection';
import Contracts from '@/interfaces/Contracts';
import Map from '@/interfaces/Map';
import { Watch } from 'vue-property-decorator';

const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
const mapProvider = IocContainer.get<Map>(SERVICES.MAP);

@WithRender
@Component({
    components: {
        // External packages
        datetime: Datetime,
        'v-select': vSelect,
        'messages-block': MessagesBlock,
    },
    props: {
        dataCorrectionRequiredResponse: {
            type: Object,
            required: true,
            default: () => ({
                id: 0,
                handler: '',
                handlerState: '',
            }),
        },
    },
})
export class CustomerDataCorrectionWidget extends Base {
    public submitPending = false;
    public successInformation: Record<any, any> = [];
    public errorInformation: Record<any, any> = [];
    public streetError = false;
    public salutationError = false;
    public firstnameError = false;
    public surnameError = false;
    public zipError = false;
    public cityError = false;
    public houseNumberError = false;
    public counterNumberError = false;
    public customerSpecificationError = false;
    public dateError = false;
    public previousProviderError = false;
    public customerNumberError = false;
    public marketLocationError = false;
    public selectedCityZip = null;
    public selectedCityName = null;
    public selectedCityId: null | number = null;
    public streets: [] = [];
    public deliveryRadio: null | string = null;
    public selectedSalutation:
        | { key: number; label: TranslateResult }
        | undefined = { key: 9, label: this.$t('widget.cdc.without') };
    protected salutationOptions: { key: number; label: TranslateResult }[] = [
        { key: 9, label: this.$t('widget.cdc.without') },
        { key: 1, label: this.$t('widget.cdc.mr') },
        { key: 2, label: this.$t('widget.cdc.mrs') },
        { key: 8, label: this.$t('widget.cdc.company') },
        { key: 3, label: this.$t('widget.cdc.family') },
    ];

    protected customerSpecificationObject: {
        id: any;
        label: any;
    } = {
        id: 'earliest_possible_date',
        label: this.$t('widget.cdc.asap').toLocaleString(),
    };
    protected previousProviderObject: {
        id: number | null;
        text: string | null;
        codeNumber: number | null;
    } = {
        id: null,
        text: null,
        codeNumber: null,
    };
    protected providers: {
        id: number;
        text: string;
        codeNumber: number;
    }[] = [];

    protected allCities: {
        id: string;
        name: string;
        text: string;
        zip: string;
    }[] = [];

    protected cities: {
        id: string;
        name: string;
        text: string;
        zip: string;
    }[] = [];

    protected details: SubmitCustomerDataCorrection = {
        actionId: 0,
        handler: '',
        handlerState: '',
        counterNumber: null,
        customerSpecification: null,
        previousProviderCodeNumber: null,
        date: null,
        customerNumber: null,
        salutation: null,
        firstname: null,
        surname: null,
        company: null,
        street: null,
        houseNumber: null,
        zip: null,
        city: null,
        marketLocationIdentifier: null,
    };
    protected elements = {
        'dsp-*': ['hide-form-after-success'],
    };

    protected exclude: string[] = [];

    get requireCounterNumberValidation(): boolean {
        return this.$store.state.settings.requireCounterNumberValidation;
    }

    public sanitizeCounterNumber(): void {
        if (!this.requireCounterNumberValidation) {
            return;
        }
        if (this.details.counterNumber) {
            this.details.counterNumber = this.details.counterNumber.replace(
                /[^A-Z0-9-/]/gi,
                ''
            );
        }
    }

    public mounted(): void {
        this.details.actionId = parseInt(
            this.$props.dataCorrectionRequiredResponse.id,
            10
        );
        this.details.handler =
            this.$props.dataCorrectionRequiredResponse.handler;
        this.details.handlerState =
            this.$props.dataCorrectionRequiredResponse.handlerState;

        if (this.contractDetails.deliveryAddress.salutation) {
            this.selectedSalutation = this.salutationOptions.find((item) => {
                return (
                    item.key ===
                    parseInt(
                        this.contractDetails.deliveryAddress.salutation,
                        10
                    )
                );
            });
            this.details.salutation = this.contractDetails.deliveryAddress
                .salutation
                ? parseInt(this.contractDetails.deliveryAddress.salutation, 10)
                : null;
        }
        this.details.firstname = this.contractDetails.deliveryAddress.firstname;
        this.details.surname = this.contractDetails.deliveryAddress.surname;
        this.details.company = this.contractDetails.deliveryAddress.company;
        this.details.street = this.contractDetails.deliveryAddress.street;
        this.details.houseNumber =
            this.contractDetails.deliveryAddress.houseNumber;
        this.details.zip = this.contractDetails.deliveryAddress.zip;
        this.details.city = this.contractDetails.deliveryAddress.city;

        if (
            [
                'contract_net_start_receive',
                'contract_net_delivery_start_denial',
                'contract_net_delivery_start_approval',
            ].includes(this.details.handler)
        ) {
            this.details.customerNumber = null;
        } else {
            this.details.customerNumber =
                this.contractDetails.customerNumber ?? null;
        }

        this.details.marketLocationIdentifier = this.contractDetails
            .marketLocation
            ? '' + this.contractDetails.marketLocation
            : null;
        this.details.counterNumber = this.contractDetails.counterNumber;

        this.details.customerSpecification =
            this.contractDetails.customerSpecification;
        this.details.date = new Date().toISOString();

        this.customerSpecificationObject =
            this.customerSpecificationFull.filter((customerSpecification) => {
                return (
                    customerSpecification.id ===
                    this.details.customerSpecification
                );
            })[0];

        mapProvider.providers(this.contractDetails.energy).then(
            (response) => {
                this.providers = response.data.results;
                const currentProvider = this.providers.filter((provider) => {
                    return (
                        provider.text === this.contractDetails.previousProvider
                    );
                })[0];
                if (currentProvider) {
                    this.details.previousProviderCodeNumber =
                        currentProvider.codeNumber;
                    this.previousProviderObject = currentProvider;
                }
            },
            (error) => {
                Sentry.captureException(new Error(error));
            }
        );

        mapProvider
            .cities(this.contractDetails.energy, '')
            .then(
                (response) => {
                    this.allCities = response.data.results.map((city) => {
                        const cityNormalized = { ...city };
                        if (cityNormalized.zip.length < 5) {
                            cityNormalized.text = '0' + city.text;
                            cityNormalized.zip = '0' + city.zip;
                        }
                        return cityNormalized;
                    });
                    this.cities = this.allCities;
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            )
            .then(() => {
                if (this.details.zip) {
                    const currentCity = this.allCities.find(
                        (city) => city.zip === this.details.zip
                    );

                    if (currentCity?.id) {
                        mapProvider
                            .streets(
                                this.contractDetails.energy,
                                parseInt(currentCity.id, 10)
                            )
                            .then(
                                (response) => {
                                    this.streets = response.data;
                                },
                                (error) => {
                                    Sentry.captureException(new Error(error));
                                }
                            );
                    }
                }
            });
    }

    get contractDetails(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract;
    }

    get customerSpecificationFull(): Record<any, any> {
        return [
            {
                id: 'relocation_at',
                label: this.$t('widget.cdc.relocation').toLocaleString(),
            },
            {
                id: 'earliest_possible_date',
                label: this.$t('widget.cdc.asap').toLocaleString(),
            },
            {
                id: 'desired_date',
                label: this.$t('widget.cdc.desired.date').toLocaleString(),
            },
            {
                id: 'terminated_in_person',
                label: this.$t(
                    'widget.cdc.terminated.in.person'
                ).toLocaleString(),
            },
        ];
    }

    get customerSpecifications(): Record<any, any> {
        if (this.deliveryRadio === 'move') {
            return [
                {
                    id: 'relocation_at',
                    label: this.$t('widget.cdc.relocation').toLocaleString(),
                },
            ];
        }
        return [
            {
                id: 'earliest_possible_date',
                label: this.$t('widget.cdc.asap').toLocaleString(),
            },
            {
                id: 'desired_date',
                label: this.$t('widget.cdc.desired.date').toLocaleString(),
            },
            {
                id: 'terminated_in_person',
                label: this.$t(
                    'widget.cdc.terminated.in.person'
                ).toLocaleString(),
            },
        ];
        // {id: 'basic_supply_registration', label: 'Grundversorgung'},
    }

    get disableCustomerSpecification(): boolean {
        return this.deliveryRadio === 'move';
    }

    get notEarliestPossible(): boolean {
        return (
            this.customerSpecificationObject &&
            this.customerSpecificationObject.id !== 'earliest_possible_date'
        );
    }

    get previousProviderRequired(): boolean {
        return (
            this.customerSpecificationObject &&
            this.customerSpecificationObject.id !== 'relocation_at'
        );
    }

    get isPreviousProviderCancellation(): boolean {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.statusCode < 3000
        );
    }

    get isGridOperatorRegistration(): boolean {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.statusCode < 4000 &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.statusCode >= 3000
        );
    }

    public getProviderOptionKey(option): number {
        return option.id;
    }

    public getProviderOptionLabel(option): string {
        if (option.text && option.text.length > 80) {
            return option.text.substring(0, 80) + '...';
        }
        return option.text;
    }

    public getStreetsOption(option) {
        return option.name || option;
    }

    public salutationInput(value): void {
        this.details.salutation = value.key;
    }

    @Watch('deliveryRadio')
    public onDeliveryRadioChange(): void {
        if (this.deliveryRadio === 'move') {
            this.customerSpecificationObject = {
                id: 'relocation_at',
                label: this.$t('widget.cdc.relocation').toLocaleString(),
            };
        } else {
            this.customerSpecificationObject = {
                id: 'earliest_possible_date',
                label: this.$t('widget.cdc.asap').toLocaleString(),
            };
        }
    }

    @Watch('previousProviderObject')
    public onPreviousProviderChange(): void {
        this.details.previousProviderCodeNumber =
            this.previousProviderObject.codeNumber;
    }

    @Watch('customerSpecificationObject')
    public onCustomerSpecificationObjectChange(): void {
        this.details.customerSpecification =
            this.customerSpecificationObject.id;
    }

    public validateDate(): boolean {
        this.errorInformation = [];
        if (
            this.customerSpecificationObject &&
            this.customerSpecificationObject.id === 'earliest_possible_date'
        ) {
            this.details.date = null;
            return true;
        }
        if (
            this.customerSpecificationObject &&
            this.customerSpecificationObject.id !== 'earliest_possible_date' &&
            !this.isDateValid(this.details.date)
        ) {
            switch (this.customerSpecificationObject.id) {
                case 'desired_date':
                    this.errorInformation.push({
                        key: '',
                        message: this.$t(
                            'widget.cdc.desired.date.error'
                        ).toLocaleString(),
                    });
                    break;
                case 'terminated_in_person':
                    this.errorInformation.push({
                        key: '',
                        message: this.$t(
                            'widget.cdc.terminated.in.person.error'
                        ).toLocaleString(),
                    });
                    break;
                case 'relocation_at':
                    this.errorInformation.push({
                        key: '',
                        message: this.$t(
                            'widget.cdc.relocation.error'
                        ).toLocaleString(),
                    });
                    break;
            }
            return false;
        }
        return true;
    }

    get firstValidDeliveryDate(): any {
        const date = new Date();
        switch (this.customerSpecificationObject.id) {
            case 'desired_date':
                return date.setDate(date.getDate() + 21);
            case 'terminated_in_person':
                return date.setDate(date.getDate() + 21);
            case 'relocation_at':
                return date.setDate(date.getDate() - 6 * 7);
        }
        return date.setDate(date.getDate() - 1);
    }

    get minDate(): string {
        return new Date(this.firstValidDeliveryDate).toISOString();
    }

    get lastValidDate(): any {
        const date = new Date();
        switch (this.customerSpecificationObject.id) {
            case 'desired_date':
                return date.setDate(date.getDate() + 365 / 2);
            case 'terminated_in_person':
                return date.setDate(date.getDate() + 365 / 2);
            case 'relocation_at':
                return date.setDate(date.getDate() + 31);
        }
        return date.setDate(date.getDate() + 1);
    }

    get maxDate(): string {
        return new Date(this.lastValidDate).toISOString();
    }

    public isDateValid(dt): any {
        const date = new Date(dt);
        return date > this.firstValidDeliveryDate && date < this.lastValidDate;
    }

    protected zipSearch(search): void {
        this.cities = this.allCities.filter((city) =>
            city.zip.includes(search)
        );
    }

    protected citySearch(search): void {
        this.cities = this.allCities.filter((city) =>
            city.name.includes(search)
        );
    }

    protected cityInput(value): void {
        if (value) {
            this.selectedCityId = value.id;
            this.selectedCityZip = this.details.zip = value.zip;
            this.selectedCityName = this.details.city = value.name;

            if (this.selectedCityId) {
                mapProvider
                    .streets(this.contractDetails.energy, this.selectedCityId)
                    .then(
                        (response) => {
                            this.streets = response.data;
                        },
                        (error) => {
                            Sentry.captureException(new Error(error));
                        }
                    );
            }
        } else {
            this.selectedCityId = null;
            this.selectedCityZip = this.details.zip = null;
            this.selectedCityName = this.details.city = null;
            this.details.houseNumber = null;
            this.streets = [];
        }

        this.details.street = null;
    }

    protected validateRequired(): boolean {
        let flag = true;
        if (this.details.customerSpecification === 'earliest_possible_date') {
            this.exclude.push('date');
        }
        if (!this.previousProviderRequired) {
            this.exclude.push('previousProviderCodeNumber');
        }
        Object.keys(this.details).forEach((key) => {
            if (!this.exclude.includes(key) && !this.details[key]) {
                flag = false;
                this[key + 'Error'] = true;
            }
        });

        return flag;
    }

    protected resetErrors(): void {
        this.streetError = false;
        this.salutationError = false;
        this.firstnameError = false;
        this.surnameError = false;
        this.zipError = false;
        this.cityError = false;
        this.houseNumberError = false;
        this.counterNumberError = false;
        this.customerSpecificationError = false;
        this.dateError = false;
        this.previousProviderError = false;
        this.customerNumberError = false;
        this.marketLocationError = false;
    }

    protected submit(): boolean | void {
        this.resetErrors();
        this.exclude.push('company');
        this.exclude.push('marketLocationIdentifier');
        this.exclude.push('customerNumber');

        if (!this.details.street) {
            this.streetError = true;
            return;
        }

        if (this.currentClient.includes('elli')) {
            if (
                !this.details.marketLocationIdentifier &&
                !this.details.counterNumber
            ) {
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'widget.cdc.marketLocation.error'
                    ).toLocaleString(),
                });
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'widget.cdc.counterNumber.error'
                    ).toLocaleString(),
                });
                this.counterNumberError = true;
                this.marketLocationError = true;
                return;
            } else {
                this.exclude.push('counterNumber');
            }
        }

        if (
            this.details.marketLocationIdentifier &&
            this.details.marketLocationIdentifier?.length !== 11
        ) {
            this.errorInformation.push({
                key: '',
                message: this.$t(
                    'widget.cdc.marketLocation.error'
                ).toLocaleString(),
            });
            this.marketLocationError = true;
            return;
        }

        if (!this.validateDate()) {
            this.dateError = true;
            return false;
        }

        if (!this.validateRequired()) {
            this.errorInformation.push({
                key: '',
                message: this.$t('widget.cdc.required.error').toLocaleString(),
            });
            return false;
        }

        this.submitPending = true;
        this.errorInformation = [];
        this.successInformation = [];

        if (!this.previousProviderRequired) {
            this.details.previousProviderCodeNumber = null;
            this.details.customerNumber = null;
        }
        if (this.notEarliestPossible && this.details.date) {
            this.details.date = this.details.date.substring(0, 10);
        }

        this.details.street = this.getStreetsOption(this.details.street);

        if (this.deliveryRadio === 'move') {
            this.details.handler = 'contract_net_delivery_start_denial';
        } else {
            if (this.details.customerSpecification === 'terminated_in_person') {
                this.details.handler = 'contract_net_delivery_start_denial';
            } else {
                this.details.handler = 'correct_customer_data';
            }
        }

        contractProvider
            .dataCorrection(
                this.details,
                this.$store.state.contracts.contractId
            )
            .then(
                (response) => {
                    if (response.data && response.data.success) {
                        this.successInformation.push({
                            key: '',
                            message:
                                this.$t('widget.cdc.success').toLocaleString(),
                        });
                        this.$emit(
                            'dataCorrectionDone',
                            this.displayElement('hide-form-after-success')
                        );
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            for (const error of response.data.errorMessages) {
                                if (
                                    error.key &&
                                    error.key === 'rules.counter.number.regex'
                                ) {
                                    error.message = this.$t(
                                        'widget.mo.meter.number.error.regex'
                                    );
                                }
                            }
                            this.errorInformation = response.data.errorMessages;
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t(
                                        'widget.cdc.failure'
                                    ).toLocaleString(),
                            });
                        }
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('widget.cdc.failure').toLocaleString(),
                    });
                }
            )
            .catch((error) => {
                Sentry.captureException(new Error(error));
                this.errorInformation.push({
                    key: '',
                    message: this.$t('widget.cdc.failure').toLocaleString(),
                });
            })
            .finally(() => {
                this.submitPending = false;
            });
    }
}
