<div class="col-12 mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="box-title static-title">
                        {{ $t('widget.tc.title') }}
                    </span>
                    <div class="mobile-modal__wrap mt-2">
                        <div class="row" v-for="tariff in list">
                            <div class="col-4">
                                <div class="tariff-items tariff-items--left">
                                    <div class="tariff-list__img">
                                        <img
                                            src="/img/evus/wdenergy/naturstrom-connect.jpeg"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        class="tariff-list__title"
                                        v-if="tariff.title"
                                    >
                                        {{ tariff.title }}
                                    </div>
                                    <!--                                <div -->
                                    <!--                                    class="tariff-list__product-code"-->
                                    <!--                                    v-if="tariff.productCode"-->
                                    <!--                                >{{ tariff.productCode }}</div>-->
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="tariff-items tariff-items--right">
                                    <div
                                        class="col-12 list-of-points"
                                        v-html="$t('widget.tc.list.of.points')"
                                    ></div>

                                    <div class="form-check mt-5 col-12">
                                        <label
                                            :class="[
                                            'form-check-label',
                                            displayElement('branded-checkbox')
                                                ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                : '',
                                            tariff.confirmedError ? 'is-invalid text-danger' : ''
                                        ]"
                                            :for="'cancellation-confirmation-' + tariff.productCode"
                                        >
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                :id="'cancellation-confirmation-' + tariff.productCode"
                                                @change="updateCheckbox(tariff)"
                                            />
                                            <span
                                                class="form-check-label__square"
                                            ></span>
                                            <span
                                                v-html="$t('widget.tc.confirmation')"
                                            ></span>
                                            <i></i>
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="tariff-items-button mt-4">
                                        <button
                                            class="btn btn-primary"
                                            @click="submit(tariff)"
                                            :disabled="submitPending"
                                        >
                                            {{ $t('widget.tc.confirm') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <messages-block
                            :success="successInformation"
                            :errors="errorInformation"
                        ></messages-block>
                    </div>
                </div>
            </div>

            <div
                class="tariff-popup__bg"
                v-if="showSuccessChangeTariff && displayElement('show-success-change-tariff-popup')"
            >
                <div class="tariff-popup__container">
                    <div class="tariff-popup__close">
                        <i
                            @click="closeSuccessChangeTariffPopup"
                            class="fa fa-times"
                        ></i>
                    </div>
                    <div class="tariff-popup__text">
                        {{ $t('widget.tc.tariff.success') }} {{ product }} {{
                        $t('widget.tc.tariff.activeFrom') }} {{ activeFrom |
                        date }} {{ $t('widget.tc.tariff.deposit') }} {{
                        newDepositValue | euroFull }} {{ $t('euro')}}.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
