<div
    v-if="addonStatus.active && addonStatus.hasLoaded"
    class="m-portlet m-portlet--mobile"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <h3>{{$t('widget.addon.status.title')}}</h3>
            </div>
            <div class="col-sm-4 col-12">
                <h5>
                    <span class="addon-status-widget__title--normal">
                        {{$t('widget.addon.status.chosen.bonus')}}
                    </span>
                    {{addonStatus.bonus}}
                </h5>
            </div>
            <div class="col-sm-8 col-12">
                <h5>
                    <span class="addon-status-widget__title--normal">
                        {{$t('widget.addon.status.status')}}
                    </span>
                    {{addonStatus.status}}
                </h5>
            </div>
            <div
                class="
                    col-sm-4 col-12
                    mt-3
                    d-flex
                    align-items-center
                    justify-content-center
                "
            >
                <img
                    v-if="addonStatus.imgLink"
                    class="img-fluid addon-status-widget__img"
                    :src="addonStatus.imgLink"
                    :alt="`${addonStatus.bonus} - Bild`"
                />
            </div>

            <div class="col-sm-4 mt-3 col-12 d-flex align-items-center">
                <p class="" v-if="addonStatus.status=='Versendet'">
                    {{$t('widget.addon.status.paragraph.dispatched',{bonus:
                    addonStatus.bonus})}}
                    <a
                        v-if="addonStatus.trackingLink"
                        href="addonStatus.trackingLink"
                    >
                        {{$t('widget.addon.status.paragraph.dispatched.link')}}
                    </a>
                </p>
                <p v-if="addonStatus.status=='Warten auf Versand'">
                    {{$t('widget.addon.status.paragraph.waiting.for.dispatch',
                    {bonus: addonStatus.bonus})}}
                </p>
                <p v-if="addonStatus.status=='Verfügbar'">
                    {{$t('widget.addon.status.paragraph.available', {bonus:
                    addonStatus.bonus})}}
                    <a
                        v-if="addonStatus.documentId"
                        href=""
                        @click="downloadDocument"
                    >
                        {{$t('widget.addon.status.paragraph.available.link')}}
                    </a>
                </p>
            </div>
            <div
                class="
                    col-sm-4 col-12
                    d-flex
                    mt-3
                    align-items-center
                    justify-content-center
                "
            >
                <img
                    class="img-fluid addon-status-widget__icon"
                    :src="iconPath"
                    :alt="`status icon - ${addonStatus.status}`"
                />
            </div>
            <div class="mt-2">
                <messages-block
                    :success="successInformation"
                    :errors="errorInformation"
                ></messages-block>
            </div>
        </div>
    </div>
</div>
