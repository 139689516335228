<div class="col-12 mobile-modal__inner" data-test="widget-cancellation">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="box-title static-title"
                        >{{ $t('widget.c.title') }}</span
                    >
                    <div class="mobile-modal__wrap row">
                        <div class="col-12 col-sm-4">
                            {{ $t('widget.c.description') }}
                        </div>
                        <div class="col-12 col-sm-8">
                            <div class="form-group">
                                <label for="type-of-cancellation">
                                    {{ $t('widget.c.type.of.cancellation') }}
                                </label>
                                <v-select
                                    :options="cancelTypeOptions"
                                    @input="cancellationOptionSelected"
                                    id="type-of-cancellation"
                                    data-test="cancellation-type"
                                >
                                </v-select>
                                <span></span>
                            </div>

                            <div
                                class="form-group"
                                v-if="
                                    details.cancelType &&
                                    cancellationReasonOptions && 
                                    cancellationReasonOptions.length > 0
                                "
                            >
                                <label for="cancellation-reason">
                                    {{ $t('widget.c.cancellation.reason') }}
                                </label>
                                <v-select
                                    :class="{ 'is-invalid': cancelReasonError }"
                                    :options="cancellationReasonOptions"
                                    id="cancellation-reason"
                                    v-model="cancelReason"
                                    data-test="cancellation-reason"
                                >
                                </v-select>
                            </div>

                            <div
                                :class="['form-group', dateError ? 'text-danger' : '']"
                                v-if="details.cancelType !== 'deadlineDate'"
                            >
                                <label for="cancellation-date"
                                    >{{ $t('widget.c.date') }}</label
                                >
                                <input
                                    v-if="displayElement('date-input')"
                                    v-model="date"
                                    type="date"
                                    :class="['form-control w-50', dateError ? 'is-invalid' : '']"
                                    id="cancellation-date"
                                    @change="dateError = false"
                                />
                                <datetime
                                    v-else-if="displayElement('cancellation-datetime')"
                                    v-model="date"
                                    type="date"
                                    input-id="cancellation-date"
                                    :input-class="['form-control w-50', dateError ? 'is-invalid' : '']"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    @close="dateError = false"
                                    :phrases="datetimePhrases"
                                ></datetime>
                                <datetime
                                    v-else
                                    v-model="date"
                                    type="date"
                                    input-id="cancellation-date"
                                    :input-class="['form-control w-50', dateError ? 'is-invalid' : '']"
                                    value-zone="local"
                                    format="dd.LL.yyyy"
                                    @close="dateError = false"
                                ></datetime>
                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <div class="form-check form-check--mr">
                                        <label
                                            :class="[
                                                'form-check-label',
                                                displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '',
                                                confirmationCheckboxError ? 'is-invalid text-danger' : '',
                                            ]"
                                            for="cancellation-confirmation"
                                        >
                                            <span
                                                class="cancellation-confirmation-checkbox"
                                            >
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input me-5"
                                                    id="cancellation-confirmation"
                                                    v-model="details.confirmation"
                                                    @change="confirmationCheckboxError = false"
                                                />
                                            </span>
                                            <span
                                                class="cancellation-confirmation-text"
                                            >
                                                {{ $t('widget.c.confirmation')
                                                }}
                                            </span>
                                            <i></i>
                                            <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 text-end">
                                    <button
                                        class="btn btn-primary"
                                        @click="submit"
                                        :disabled="isSubmitButtonDisabled"
                                    >
                                        {{ $t('widget.c.confirm') }}
                                    </button>
                                </div>
                            </div>

                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
