import { MeterSubmitWidget } from '../meter-submit-widget';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './waermeenergie.html';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Format from '@/interfaces/Format';
import { ReadingState } from '@/interfaces/Reading';
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    components: {
        ContentLoader,
    },
})
export class MeterSubmitWidgetWaermeenergie extends MeterSubmitWidget {
    protected isReadingDetailsLoaded = false;
    protected isReadingDetailsError = false;

    protected created() {
        if (this.$store.state.contracts.contractId) {
            this.$store
                .dispatch(
                    'reading/details',
                    this.$store.state.contracts.contractId
                )
                .then(() => {
                    this.isReadingDetailsLoaded =
                        this.readingState.readingDetailsLoaded;
                    this.isReadingDetailsError =
                        this.readingState.readingDetailsError;
                });
        }
    }

    get contract(): Record<any, any> {
        return this.contractsList.filter((contract) => {
            return (contract.id = this.contractId);
        })[0];
    }

    get state(): any {
        return this.nextReadingStateString;
    }

    get turnusRangeStartDate(): any {
        return this.readingState.next.reminderDateCustomerReading;
    }

    get turnusRangeStartDateFormatted(): any {
        return formatProvider.date()(this.turnusRangeStartDate);
    }

    get turnusRangeEndDate(): any {
        return this.readingState.next.latestBillingDate;
    }

    get turnusRangeEndDateFormatted(): any {
        return formatProvider.date()(this.turnusRangeEndDate);
    }

    get turnusNowOrInRange(): boolean {
        if (
            this.nextReadingStateString === ReadingState.TURNUS_NOW ||
            this.nextReadingStateString === ReadingState.TURNUS
        ) {
            if (
                new Date(this.turnusRangeStartDate) <= new Date() &&
                new Date(this.turnusRangeEndDate) >= new Date()
            ) {
                return true;
            }
        }
        return false;
    }

    get inRegistrationState(): boolean {
        return (
            this.nextReadingStateString === ReadingState.WAITING &&
            this.readingState.contract.startDate === null
        );
    }

    get depositDetails(): Record<any, any> {
        return this.$store.state.payment;
    }

    get showReasonInput(): boolean {
        return (
            this.$store.state.settings.allowImplausibleMeterReads &&
            (this.errorCode === '151' || this.errorCode === '152') &&
            this.previousReadingValue === this.details.readings[0].value
        );
    }

    get implausibleReasonExplanationListWaermeenergie(): Record<any, any> {
        if (this.allowImplausibleMeterReads) {
            if (this.errorCode === '152') {
                return [
                    {
                        value: 'ONLA',
                        label: this.$t(
                            'widget.ms.error.152.reason.onla'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLE',
                        label: this.$t(
                            'widget.ms.error.152.reason.onle'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLG',
                        label: this.$t(
                            'widget.ms.error.152.reason.onlg'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLH',
                        label: this.$t(
                            'widget.ms.error.152.reason.onlh'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLI',
                        label: this.$t(
                            'widget.ms.error.152.reason.onli'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLK',
                        label: this.$t(
                            'widget.ms.error.152.reason.onlk'
                        ).toLocaleString(),
                    },
                ];
            } else if (this.errorCode === '151') {
                return [
                    {
                        value: 'ONLB',
                        label: this.$t(
                            'widget.ms.error.151.reason.onlb'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLC',
                        label: this.$t(
                            'widget.ms.error.151.reason.onlc'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLD',
                        label: this.$t(
                            'widget.ms.error.151.reason.onld'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLF',
                        label: this.$t(
                            'widget.ms.error.151.reason.onlf'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLH',
                        label: this.$t(
                            'widget.ms.error.151.reason.onlh'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLI',
                        label: this.$t(
                            'widget.ms.error.151.reason.onli'
                        ).toLocaleString(),
                    },
                    {
                        value: 'ONLJ',
                        label: this.$t(
                            'widget.ms.error.151.reason.onlj'
                        ).toLocaleString(),
                    },
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    get isReadingDetailsLoading() {
        return this.readingState.isReadingDetailsLoading;
    }

    protected readingInputDone(reading) {
        const errorAlreadyExists =
            this.errorInformation.filter((error) => {
                const key = 'key';
                return (
                    error[key] === 'widget.ms.reading.value.breach.max.custom'
                );
            }).length > 0;
        const maxValue = this.registerMaxValue(reading);

        if (reading.value > maxValue && !errorAlreadyExists) {
            this.errorInformation.push({
                key: 'widget.ms.reading.value.breach.max.custom',
                message: this.$t('widget.ms.reading.value.breach.max', {
                    maxValue,
                }),
            });
            this.submitPending = true;
        } else if (reading.value <= maxValue) {
            this.errorInformation = [];
            this.errorCode = '';
            this.errorConfirmation = false;
            this.submitPending = false;
        }
    }

    private implausibleReasonExplanationSelectedWaermeenergie(val) {
        this.implausibleReasonExplanation = val.value || '';
    }
}
