import VueRouter from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export const handleNavigationFailure = (failure) => {
    if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'prod') {
        console.log('handleNavigationFailure', failure);
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
            console.log(failure);
            // Do something if needed ?
        }
    }
};
