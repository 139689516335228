<div class="col-12 mobile-modal__inner" data-test="meter-submit-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div @click="$emit('closeWidget')" class="fullwidthArea-close">
                <i class="fa fa-times"></i>
            </div>
            <div id="meterReadSubmit-content">
                <div class="row">
                    <div class="col-11">
                        <span class="box-title static-title">
                            {{ $t('widget.ms.title') }}
                        </span>
                    </div>
                </div>
                <div class="row mobile-modal__wrap" v-if="!inWaitingState">
                    <div class="col-md-8 col-xs-12 col-sm-12 row">
                        <div class="col-12 col-md-8">
                            <table class="table" v-if="readingsSorted.length">
                                <tr>
                                    <th>{{ $t('widget.at.date') }}</th>
                                    <th>{{ $t('widget.ms.title') }}</th>
                                </tr>
                                <tr v-for="reading in readingsSorted">
                                    <td>{{ reading.readAt | date }}</td>
                                    <td>{{ reading.value | fullNumber }}</td>
                                </tr>
                            </table>
                            <br />

                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>

                            <div v-if="showReasonInput">
                                <span class="fs-12">
                                    {{ $t('widget.ms.reason') }}
                                </span>
                                <input
                                    v-model="reason"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-xs-12 col-sm-12">
                        <div class="fullWithArea-submit">
                            <div v-if="multiRegister"><br /><br /></div>
                            <div>
                                <div>{{ $t('widget.ms.title') }}</div>
                                <div
                                    v-for="reading in getDetailsReadingsAtSelectedDate()"
                                >
                                    <input
                                        type="number"
                                        min="0"
                                        step="1"
                                        :placeholder="$t('widget.ms.title')"
                                        v-model="reading.value"
                                        class="form-control readingInput-obis"
                                    />
                                    <div v-if="multiRegister"><br /><br /></div>
                                </div>
                                <div class="row" v-if="!editActive">
                                    <div
                                        class="col-12 fullWidthArea-submitButtonHint"
                                    >
                                        <div @click="setEditActive(true)">
                                            {{ $t('widget.ms.reading') }} {{
                                            details.readAt | date }}
                                            <i
                                                v-if="!forceMoveInReading"
                                                class="fa fa-edit"
                                            ></i>
                                        </div>
                                    </div>
                                    <div
                                        class="col-12 text-end fullWithArea-submitButton"
                                    >
                                        <button
                                            class="btn btn-sm btn-primary"
                                            @click="submit"
                                        >
                                            {{ $t('widget.ms.transfer') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col">
                                        <datetime
                                            v-model="details.readAt"
                                            type="date"
                                            input-class="form-control"
                                            value-zone="local"
                                            format="dd.LL.yyyy"
                                            :min-datetime="minDate"
                                            :max-datetime="maxDate"
                                            @close="setEditActive(false)"
                                        ></datetime>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>{{ $t('widget.ms.waitingForDelivery') }}</div>
            </div>
        </div>
    </div>
</div>
