<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <i class="fa fa-chart-pie advizzo-icons"></i>
                <span class="static-title static-title--custom advizzo-title"
                    >{{ $t('widget.ou.title') }}</span
                >
                <div class="adv_container">
                    <div id="disaggregation_widget" class=""></div>
                </div>
            </div>
        </div>
    </div>
</div>
