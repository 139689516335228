import { default as Component } from 'vue-class-component';
// Call Render template.
import WithRender from './elli-thg.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';
// Widgets
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget';
import { CancellationWidget } from '@/components/widgets/cancellation-widget';
import { EcarUploadWidget } from '@/components/widgets/ecar-upload-widget';
import { TariffChangeWidget } from '@/components/widgets/tariff-change-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { MarketingMaterial } from '@/components/widgets/marketing-material-widget/marketing-material';
import { ThgBonusOverview } from '@/components/widgets/thg-bonus-overview';
import { ThgBonusSelector } from '@/components/widgets/thg-bonus-selector';
import { ThgStatusWidget } from '@/components/widgets/thg-status-widget';
import { ThgCarDataWidget } from '@/components/widgets/thg-car-data-widget';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import ThgServiceElliDefault from '@/models/thg/ThgServiceElliDefault';
import moment from 'moment';

@WithRender
@Component({
    components: {
        // Widgets
        ChangeCustomerDataWidget,
        ChangeBillingDataWidget,
        CancellationWidget,
        EcarUploadWidget,
        TariffChangeWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
        MarketingMaterial,
        ThgBonusOverview,
        ThgBonusSelector,
        ThgStatusWidget,
        ThgCarDataWidget,
    },
})
export class Dashboard extends DashboardParent {
    protected showBonusSelector: boolean = false;
    protected showVehicleDataEditor: boolean = false;
    protected bonusSelectorYear: string = moment().year() + '';

    mounted() {
        this.initBonusYears();
    }

    protected get isProductCPO(): boolean {
        if (
            this.currentTariff.nonCommodityProduct &&
            this.currentTariff.nonCommodityProduct.productCode
        ) {
            return this.currentTariff.nonCommodityProduct.productCode.includes(
                'THG_CPO'
            );
        }

        return false;
    }

    protected get vehicleDataEditorYear(): string {
        const years: string[] = this.thgService.getBonusYears();
        return years.length > 0 ? years[0] : '';
    }

    protected showCarDataWidgetOld(): boolean {
        const licensePlateForYear: string = this.thgService.getLicensePlate();
        return licensePlateForYear.length > 0;
    }

    protected initBonusYears(): void {
        const bonusYears: string[] = this.thgService.getBonusYears();
        const latestYearWithStatus: string =
            bonusYears.length > 0
                ? bonusYears[bonusYears.length - 1]
                : this.defaultYear;
    }

    protected hasBonusForYear(year: string): boolean {
        return this.thgService.getBonusYears().indexOf(year) >= 0;
    }

    protected get defaultYear(): string {
        return moment().format('YYYY');
    }

    public showBonusSelectorForYear(year: string): void {
        this.bonusSelectorYear = year;
        this.openBonusSelector();
    }

    public changeVehicleDataForYear(year: string): void {
        this.openVehicleDataEditor();
    }

    public openVehicleDataEditor(): void {
        this.showVehicleDataEditor = true;
    }

    public closeVehicleDataEditor(): void {
        this.showVehicleDataEditor = false;
    }

    public openBonusSelector(): void {
        this.showBonusSelector = true;
    }

    public closeBonusSelector(): void {
        this.showBonusSelector = false;
    }

    public get thgService(): ThgBaseService {
        return ThgServiceElliDefault.instance.setStore(this.$store);
    }

    public bonusSubmittedForYear(year: string): void {
        this.closeBonusSelector();
    }
}
