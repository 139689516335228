import { MoveWidgetSchweizstrom } from '@/components/widgets/move-widget/custom/schweizstrom';
import { MoveWidget } from '@/components/widgets/move-widget/move-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'schweizstrom') {
    widget = MoveWidgetSchweizstrom;
} else {
    widget = MoveWidget;
}
export { widget as MoveWidget };
