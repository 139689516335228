<div class="m-portlet m-portlet--mobile" data-test="deposit-widget">
    <div class="m-portlet__body">
        <div class="row">
            <div
                class="col-2"
                :class="[ displayElement('center-icon') ? 'nextReading__icon--center': '' ]"
            >
                <i class="fa fa-coins monthlyDeposit-icon"></i>
            </div>
            <div class="col-10">
                <div
                    id="monthlyDeposit-content"
                    style="display: block; position: relative"
                >
                    <template v-if="displayElement('pencil-icon')">
                        <span class="actionMiddleWidget toggle-icons">
                            <svg
                                v-if="showAction && depositDetailsLoaded"
                                data-test="deposit-widget-click-icon"
                                @click="$emit('toggleWidget', 'depositChangeWidget')"
                                id="button-changeDeposit"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="24px/edit">
                                    <path
                                        id="Shape"
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M35.7042 3.2012L45.6862 16.9372L19.7362 36.6452C18.5652 37.5321 17.1371 38.013 15.6682 38.0152H2.3282L7.3862 25.5532C7.84809 24.4161 8.61151 23.4265 9.5942 22.6912L35.7042 3.2012ZM12.9102 23.5352L35.1382 6.9432L42.0022 16.3952L20.0642 33.0572L12.9102 23.5352ZM9.8502 26.5552C10.0741 26.0345 10.4045 25.5665 10.8202 25.1812L17.9242 34.6352C17.2602 35.0935 16.4749 35.3441 15.6682 35.3552H6.2782L9.8502 26.5552ZM45.849 44.7988V42.1388H2.151V44.7988H45.849Z"
                                        fill="#001E50"
                                    />
                                </g>
                            </svg>
                        </span>
                    </template>
                    <template v-else>
                        <span
                            class="actionMiddleWidget actionMiddleWidget--default"
                            v-if="displayElement('interactive-icon')"
                        >
                            <i
                                data-test="deposit-widget-click-icon"
                                :class="{'active': isActiveWidget}"
                                @click="$emit('toggleWidget', 'depositChangeWidget')"
                                v-if="showAction && depositDetailsLoaded"
                            ></i>
                        </span>
                        <span class="actionMiddleWidget" v-else>
                            <i
                                data-test="deposit-widget-click-icon"
                                @click="$emit('toggleWidget', 'depositChangeWidget')"
                                class="fa fa-ellipsis-h"
                                id="button-changeDeposit"
                                v-if="showAction && depositDetailsLoaded"
                            ></i>
                        </span>
                    </template>
                    <div
                        class="monthlyDeposit-title"
                        data-test="deposit-widget-value"
                    >
                        <div v-if="!currentDeposit">
                            <content-loader
                                :width="50"
                                :height="35"
                                style="width: 50px; height: 35px"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                            {{ $t('euro') }}
                        </div>
                        <template v-else>
                            <template v-if="displayElement('decimal-euro')">
                                {{ currentDeposit | euro }} {{ $t('euro') }}
                            </template>
                            <template v-else>
                                {{ currentDeposit | euroSpecial }} {{ $t('euro')
                                }}
                            </template>
                        </template>
                    </div>

                    <div
                        class="monthlyDeposit-text"
                        v-if="displayElement('show-bold-words')"
                    >
                        {{ $t('widget.d.discount1') }}
                        <strong>{{ $t('widget.d.discount2') }}</strong>
                    </div>
                    <div class="monthlyDeposit-text" v-else>
                        {{ $t('widget.d.discount') }}
                    </div>
                </div>
            </div>

            <deposit-change-widget
                v-if="isActiveWidget && displayElement('deposit-change-widget')"
                class="d-md-none"
            ></deposit-change-widget>
        </div>
    </div>
</div>
