import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './fleet-manager-quotas-widget.html';
import iocContainer from '@/container/IocContainer';
import IocContainer from '@/container/IocContainer';
import Contracts from '@/interfaces/Contracts';
import SERVICES from '@/container/Services';
import Format from '@/interfaces/Format';
import moment from 'moment';
import Customer from '@/interfaces/Customer';
import { PDFDocument } from 'pdf-lib';
import Vapor from 'laravel-vapor';
import { Watch } from 'vue-property-decorator';
import Files from '@/interfaces/Files';
import { CancelType } from '@/interfaces/SubmitCancellation';

const filesProvider = IocContainer.get<Files>(SERVICES.FILES);
const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

const contractProvider = iocContainer.get<Contracts>(SERVICES.CONTRACTS);
const customerProvider = iocContainer.get<Customer>(SERVICES.CUSTOMER);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        euro: formatProvider.euro(),
    },
})
export class FleetManagerQuotasWidget extends Base {
    public dataSets: {
        licensePlate: string;
        contractId: string;
        registration: string;
        vin: string;
        class: string;
        status: string;
        year: string;
        commission: string;
    }[] = [];

    public dataSetDate: string = '';
    public requestParams = {
        take: 7,
        skip: 0,
        search: '',
        sortedType: 'asc',
        sortedKey: '',
    };
    public currentPage = 1;

    public columns = [
        {
            title: 'Registration',
            key: 'registration',
            type: 'clickable',
            source: 'users',
            uniqueField: 'id',
            sortable: true,
        },
    ];

    public isSuccessStep = false;
    public isReUploadOpen = false;
    public generalReUpload = false;
    public cancellationFailed = false;
    public isLoading = false;

    public genericReUploadContract: {
        contractId: number;
        year: string;
        commission: string;
        status: string;
        class: string;
        registration: string;
        vin: string;
        licensePlate: string;
    } | null = null;
    public genericReUploadAction = null;

    public fileUploadError = false;
    public uploadingToS3 = false;
    public toReUpload: any = {};

    public document: any = {};

    @Watch('genericReUploadContract')
    public onGenericReUploadContractChanged() {
        if (this.genericReUploadContract) {
            this.toReUpload = this.genericReUploadContract;
        }
    }

    get backColor() {
        if (this.currentPage === 1) {
            return '#888A8F';
        }
        return '#575962';
    }

    get forwardColor() {
        if (this.currentPage === this.totalPage) {
            return '#888A8F';
        }
        return '#575962';
    }

    get stoppedContracts() {
        return this.dataSets.filter((item) => {
            return this.isStoppedStatus(item.status);
        });
    }

    get totalOpen() {
        let total = 0;
        this.dataSets.forEach((item) => {
            if (
                [
                    'pending',
                    'verified',
                    'not_verified_wrong_document_type',
                    'not_verified_wrong_fuel_code',
                    'not_verified_unreadable',
                    'not_verified_other_reason',
                ].includes(item.status)
            ) {
                total += parseFloat(item.commission);
            }
        });
        return total;
    }

    get totalSuccessful() {
        let total = 0;
        this.dataSets.forEach((item) => {
            if (['confirmed_by_uba'].includes(item.status)) {
                total += parseFloat(item.commission);
            }
        });
        return total;
    }

    get totalPage() {
        const total = Math.ceil(this.dataSets.length / this.requestParams.take);
        return total <= 1 ? 1 : total;
    }

    get sortedData() {
        const key = this.requestParams.sortedKey;
        const order = this.requestParams.sortedType;
        const offset = (this.currentPage - 1) * this.requestParams.take;
        if (key) {
            return this.dataSets
                .sort((a, b) => {
                    if (key === 'registration') {
                        const dateA = new Date(a[key]).getTime();
                        const dateB = new Date(b[key]).getTime();
                        if (order === 'DESC') {
                            return dateA - dateB;
                        }
                        return dateB - dateA;
                    }
                    if (typeof a[key] === 'string') {
                        if (a[key] < b[key]) {
                            return order === 'DESC' ? -1 : 1;
                        }
                        if (a[key] > b[key]) {
                            return order === 'DESC' ? 1 : -1;
                        }
                        return 0;
                    }
                    if (order === 'DESC') {
                        return a[key] - b[key];
                    }
                    return b[key] - a[key];
                })
                .slice(offset, offset + this.requestParams.take);
        }

        return this.dataSets.slice(offset, offset + this.requestParams.take);
    }

    public mounted() {
        this.readData();
    }

    public toggleSort(key) {
        if (this.requestParams.sortedKey === key) {
            if (this.requestParams.sortedType === 'DESC') {
                this.requestParams.sortedType = 'ASC';
            } else {
                this.requestParams.sortedType = 'DESC';
            }
        } else {
            this.sortedKeyValue(key, 'DESC');
        }
    }

    public sortedKeyValue(key, type) {
        this.requestParams.sortedKey = key;
        this.requestParams.sortedType = type;
        this.readData();
    }

    public readData() {
        customerProvider.getFleetManagerQuotas().then((res) => {
            this.dataSets = res.data.data;
        });
    }
    public newPage(page) {
        if (page != 0 && page <= this.totalPage) {
            this.requestParams.skip = (page - 1) * this.requestParams.take;
            this.currentPage = page;
            this.readData();
        }
    }
    public firstPage() {
        this.currentPage = 1;
    }

    public lastPage() {
        this.currentPage = this.totalPage;
    }

    public prevPage() {
        if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
        }
    }

    public nextPage() {
        if (this.currentPage < this.totalPage) {
            this.currentPage = this.currentPage + 1;
        }
    }

    public isRedStatus(status) {
        return !['pending', 'verified', 'confirmed_by_uba'].includes(status);
    }

    public isStoppedStatus(status) {
        return ['not_verified_wrong_document_type', 'not_verified_unreadable', 'not_verified_other_reason'].includes(status);
    }

    public getStatusClass(status) {
        if (['pending', 'verified'].includes(status)) {
            return 'open-status';
        }
        if (['confirmed_by_uba'].includes(status)) {
            return 'successful-status';
        }
        if (
            [
                'not_verified_wrong_document_type',
                'not_verified_wrong_fuel_code',
                'not_verified_unreadable',
                'not_verified_other_reason',
                'not_verified_duplicate',
                'rejected_by_uba',
                'cancelled',
            ].includes(status)
        ) {
            return 'cancelled-status';
        }
    }
    public formatData(date) {
        return moment(date).format('DD.MM.YYYY');
    }

    public openReUpload(contract) {
        this.resetData();
        this.isReUploadOpen = true;
        this.toReUpload = contract;
    }

    public openGenericReUpload() {
        this.resetData();
        this.isReUploadOpen = true;
        this.generalReUpload = true;
    }

    public closeReUpload() {
        this.resetData();
    }

    protected resetData() {
        this.isSuccessStep = false;
        this.isReUploadOpen = false;
        this.generalReUpload = false;
        this.cancellationFailed = false;
        this.isLoading = false;
        this.genericReUploadContract = null;
        this.genericReUploadAction = null;
        this.fileUploadError = false;
        this.uploadingToS3 = false;
        this.toReUpload = {};
        this.document = {};
    }

    private processFile(event): void {
        this.uploadingToS3 = true;
        this.fileUploadError = false;
        if (event.target.files[0]) {
            const reader = new FileReader();
            if (event.target.files[0].type === 'application/pdf') {
                reader.readAsArrayBuffer(event.target.files[0]);
                reader.onload = async () => {
                    if (reader.result) {
                        const pdfSrcDoc = await PDFDocument.load(reader.result);
                        const pagesCount = pdfSrcDoc.getPageCount();
                        if (pagesCount > 1) {
                            this.fileUploadError = true;
                            this.uploadingToS3 = false;
                            return;
                        }

                        const result = await this.uploadToS3(
                            event.target.files[0],
                            event.target.files[0].type
                        );
                        this.document = {
                            name: event.target.files[0].name,
                            type: event.target.files[0].type,
                            content: result,
                        };
                        this.uploadingToS3 = false;
                    }
                };
            } else {
                reader.readAsBinaryString(event.target.files[0]);
                reader.onload = async () => {
                    if (event.target.files[0].size > 20000000) {
                        this.fileUploadError = true;
                    } else {
                        const result = await this.uploadToS3(
                            event.target.files[0],
                            event.target.files[0].type
                        );
                        this.document = {
                            name: event.target.files[0].name,
                            type: event.target.files[0].type,
                            content: result,
                        };
                        this.uploadingToS3 = false;
                    }
                };
            }
        }
    }

    private uploadToS3(file, type) {
        return new Promise((resolve, reject) => {
            Vapor.store(file, {
                signedStorageUrl:
                    process.env.VUE_APP_API_URL +
                    '/v1/vapor/signed-storage-url',
                headers: {
                    Authorization: process.env.VUE_APP_API_AUTH,
                },
                visibility: 'public-read',
            })
                .then((response) => {
                    resolve({
                        uuid: response.uuid,
                        s3key: response.key,
                        bucket: response.bucket,
                        name: file.name,
                        type: type,
                    });
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                    console.log(error.response);
                });
        });
    }

    public removeFile() {
        this.document = {};
    }

    public submitProcess() {
        this.isLoading = true;
        if (this.generalReUpload && this.genericReUploadContract) {
            if (this.genericReUploadAction === 'upload') {
                const data = {
                    contractId: this.genericReUploadContract.contractId,
                    year: this.genericReUploadContract.year,
                    file: this.document,
                };
                this.reUploadDocuments(data);
            }

            if (this.genericReUploadAction === 'cancel') {
                contractsProvider
                    .cancelContract({
                        contractId:
                            this.genericReUploadContract.contractId ?? 0,
                        cancelType: CancelType.deadlineDate,
                        cancelReason: '',
                        extraOrdinaryDate: null,
                        cancelDate: null,
                        moveAtDate: null,
                        confirmation: true,
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.isLoading = false;
                            this.isSuccessStep = true;
                        }
                    });
            }
        } else {
            const data = {
                contractId: this.toReUpload.contractId,
                year: this.toReUpload.year,
                file: this.document,
            };
            this.reUploadDocuments(data);
        }
    }

    protected reUploadDocuments(data) {
        filesProvider.reUploadDocuments(data).then((res) => {
            if (res.data.success) {
                const index = this.dataSets.findIndex((item) => {
                    return (
                        item.contractId == res.data.contractId &&
                        item.year == res.data.year
                    );
                });
                console.log(index);
                if (index >= 0) {
                    this.dataSets[index]['status'] = 'pending';
                }
                this.isLoading = false;
                this.isSuccessStep = true;
            }
        });
    }
}
