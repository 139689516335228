import { InvoiceWidget } from '../invoice-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './ewii.html';

import { Watch } from 'vue-property-decorator';

@WithRender
@Component({})
export class EwiiInvoicesWidget extends InvoiceWidget {
    @Watch('$store.state.contracts.contractId')
    protected watchByContractId(): void {
        this.$store.dispatch(
            'payment/invoices',
            this.$store.state.contracts.contractId
        );
    }
}
