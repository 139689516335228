import { injectable } from 'inversify';
import TranslationProviderInterface from '../interfaces/Translation';
@injectable()
class TranslationProvider implements TranslationProviderInterface {
    /**
     * @returns {String}
     */
    public getCurrentLocale(): string {
        let locale = localStorage.getItem('wd-portal-language');
        if (locale === null || typeof locale === 'undefined' || locale === '') {
            locale = process.env.VUE_APP_I18N_LOCALE
                ? process.env.VUE_APP_I18N_LOCALE
                : 'de';
        }
        return String(locale);
    }
}
export default TranslationProvider;
