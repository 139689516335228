<div class="mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div class="row">
                <div class="col">
                    <span
                        v-if="isPreviousProviderCancellation"
                        class="box-title static-title"
                    >
                        {{ $t('widget.cdc.title.previous') }}
                    </span>
                    <span v-else class="box-title static-title">
                        {{ $t('widget.cdc.title.grid') }}
                    </span>
                    <div
                        id="customerDataChange-content"
                        class="mobile-modal__wrap"
                    >
                        <span v-if="isPreviousProviderCancellation">
                            {{ $t('widget.cdc.description.previous') }}
                        </span>
                        <span v-else>
                            {{ $t('widget.cdc.description.grid') }}
                        </span>
                        <br /><br />
                        <div id="customerDataChangeForm">
                            <div class="row">
                                <div class="col-12 form-col">
                                    <span
                                        ><b
                                            >{{ $t('widget.cdc.personalData')
                                            }}</b
                                        ></span
                                    >
                                    <div class="row">
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label for="company">
                                                    {{ $t('widget.cdc.company')
                                                    }}*
                                                </label>
                                                <input
                                                    type="text"
                                                    id="company"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.company')"
                                                    v-model="details.company"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="customer_salutation"
                                                >
                                                    {{
                                                    $t('widget.cdc.salutation')
                                                    }}
                                                </label>
                                                <select
                                                    id="customer_salutation"
                                                    class="form-control"
                                                    v-model="details.salutation"
                                                >
                                                    <option
                                                        :value="9"
                                                        :selected="details.salutation === 9"
                                                    >
                                                        {{
                                                        $t('widget.cdc.without')
                                                        }}
                                                    </option>
                                                    <option
                                                        :value="1"
                                                        :selected="details.salutation === 1"
                                                    >
                                                        {{ $t('widget.cdc.mr')
                                                        }}
                                                    </option>
                                                    <option
                                                        :value="2"
                                                        :selected="details.salutation === 2"
                                                    >
                                                        {{ $t('widget.cdc.mrs')
                                                        }}
                                                    </option>
                                                    <option
                                                        :value="8"
                                                        :selected="details.salutation === 8"
                                                    >
                                                        {{
                                                        $t('widget.cdc.company')
                                                        }}
                                                    </option>
                                                    <option
                                                        :value="3"
                                                        :selected="details.salutation === 3"
                                                    >
                                                        {{
                                                        $t('widget.cdc.family')
                                                        }}
                                                    </option>
                                                </select>
                                                <span></span>
                                            </div>
                                            <div class="form-group">
                                                <label for="firstname">
                                                    {{
                                                    $t('widget.cdc.firstname')
                                                    }}*
                                                </label>
                                                <input
                                                    type="text"
                                                    id="firstname"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.firstname')"
                                                    v-model="details.firstname"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="surname">
                                                    {{ $t('widget.cdc.surname')
                                                    }}*
                                                </label>
                                                <input
                                                    type="text"
                                                    id="surname"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.surname')"
                                                    v-model="details.surname"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label
                                                    >{{ $t('widget.cdc.zip')
                                                    }}*</label
                                                >
                                                <v-select
                                                    :options="cities"
                                                    @search="zipSearch"
                                                    @input="cityInput"
                                                    label="zip"
                                                    disabled="disabled"
                                                    v-model="details.zip"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    >{{ $t('widget.cdc.city')
                                                    }}*</label
                                                >
                                                <v-select
                                                    :options="cities"
                                                    @search="citySearch"
                                                    @input="cityInput"
                                                    label="text"
                                                    disabled="disabled"
                                                    v-model="details.city"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label
                                                    for="street"
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.cdc.street')
                                                    }}*
                                                </label>
                                                <v-select
                                                    id="street"
                                                    :options="streets"
                                                    v-model="details.street"
                                                    :getOptionLabel="getStreetsOption"
                                                    :getOptionKey="getStreetsOption"
                                                    disabled="disabled"
                                                    @input="streetError = false"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label for="house_number">
                                                    {{
                                                    $t('widget.cdc.houseNumber')
                                                    }}*
                                                </label>
                                                <input
                                                    type="text"
                                                    id="house_number"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.houseNumber')"
                                                    disabled="disabled"
                                                    v-model="details.houseNumber"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 form-data">
                                    <br />
                                    <span
                                        ><b
                                            >{{ $t('widget.cdc.deliveryDetails')
                                            }}</b
                                        ></span
                                    >
                                    <div class="row">
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label for="counter_number">
                                                    {{
                                                    $t('widget.cdc.counterNumber')
                                                    }}*
                                                </label>
                                                <input
                                                    type="text"
                                                    id="counter_number"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.counterNumber')"
                                                    v-model="details.counterNumber"
                                                />
                                                <div class="info-text">
                                                    {{$t('widget.cdc.meter.number.helper.part1')}}
                                                    <span
                                                        class="meters-popup-link"
                                                        @click="showMetersPopup = true"
                                                        >{{$t('widget.cdc.meter.number.helper.link')}}</span
                                                    >
                                                    {{$t('widget.cdc.meter.number.helper.part2')}}
                                                </div>
                                            </div>
                                            <div class="form-group"></div>
                                            <div class="form-group">
                                                <label
                                                    for="market_location_identifier"
                                                >
                                                    {{
                                                    $t('widget.cdc.marketLocation')
                                                    }}
                                                </label>
                                                <input
                                                    type="number"
                                                    id="market_location_identifier"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.marketLocation')"
                                                    v-model="details.marketLocation"
                                                />
                                                <div class="info-text">
                                                    {{$t('widget.cdc.identifier.helper')}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-6">
                                            <div class="form-group">
                                                <label
                                                    for="customer_specification"
                                                >
                                                    {{
                                                    $t('widget.cdc.customerSpecification')
                                                    }}*
                                                </label>
                                                <v-select
                                                    id="customer_specification"
                                                    :options="customerSpecifications"
                                                    v-model="customerSpecificationObject"
                                                    @input="validateDateWithChangeDate"
                                                ></v-select>
                                            </div>
                                            <div
                                                class="form-group"
                                                v-if="notEarliestPossible"
                                            >
                                                <label for="date">
                                                    {{ $t('widget.cdc.date') }}*
                                                </label>
                                                <input
                                                    v-if="displayElement('date-input')"
                                                    v-model="details.date"
                                                    type="date"
                                                    class="form-control"
                                                    id="date"
                                                    @blur="validateDate"
                                                />
                                                <datetime
                                                    v-else
                                                    v-model="details.date"
                                                    type="date"
                                                    input-id="date"
                                                    input-class="form-control"
                                                    value-zone="local"
                                                    format="dd.LL.yyyy"
                                                    :min-datetime="minDate"
                                                    @close="validateDate"
                                                    @focus="focusEventDatetime"
                                                ></datetime>
                                            </div>
                                            <div
                                                class="form-group"
                                                v-if="previousProviderRequired"
                                            >
                                                <label
                                                    for="previous_provider_code_number"
                                                >
                                                    {{
                                                    $t('widget.cdc.previousProviderCodeNumber')
                                                    }}
                                                </label>
                                                <v-select
                                                    id="previous_provider_code_number"
                                                    :options="providers"
                                                    v-model="previousProviderObject"
                                                    :getOptionLabel="getProviderOptionLabel"
                                                    :getOptionKey="getProviderOptionKey"
                                                ></v-select>
                                            </div>
                                            <div
                                                class="form-group"
                                                v-if="previousProviderRequired"
                                            >
                                                <label for="customer_number">
                                                    {{
                                                    $t('widget.cdc.customerNumber')
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="customer_number"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cdc.customerNumber')"
                                                    v-model="details.customerNumber"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-8 col-xs-12">
                                    <messages-block
                                        :success="successInformation"
                                        :errors="errorInformation"
                                    ></messages-block>
                                </div>
                                <div class="col-sm-4 col-xs-12">
                                    <div class="text-end">
                                        <button
                                            class="btn btn-primary"
                                            @click="submit"
                                            :disabled="submitPending"
                                        >
                                            {{ $t('widget.cdc.submit') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="meter-popup-wrapper"
                v-if="showMetersPopup"
                @click="closePopupByWrapper"
            >
                <div class="meter-popup">
                    <div class="close-button" @click="showMetersPopup = false">
                        x
                    </div>
                    <img
                        src="/img/evus/dsp-marl/meters_picture.jpg"
                        alt="meters_picture"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
