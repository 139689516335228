import { MeterHistoryWidget } from '../meter-history-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './ewii.html';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({})
export class MeterHistoryWidgetEwii extends MeterHistoryWidget {
    @Watch('$store.state.contracts.contractId')
    protected watchByContractId(): void {
        this.$store.dispatch(
            'reading/history',
            this.$store.state.contracts.contractId
        );
    }
}
