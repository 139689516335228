import { NonCommodityTariffDetailsWidgetEts } from '@/components/widgets/non-commodity-tariff-details-widget/custom/ets';
import { NonCommodityTariffDetailsWidget } from '@/components/widgets/non-commodity-tariff-details-widget/non-commodity-tariff-details-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = NonCommodityTariffDetailsWidgetEts;
} else {
    widget = NonCommodityTariffDetailsWidget;
}
export { widget as NonCommodityTariffDetailsWidget };
