<div
    class="m-portlet m-portlet--mobile meter-widget"
    data-test="meter-widget"
    @click="$emit('toggleWidget', 'meterSubmitWidget')"
>
    <div class="m-portlet__body">
        <div class="row nextReading">
            <div
                class="col-2"
                :class="[ displayElement('center-icon') ? 'nextReading__icon--center': '' ]"
            >
                <i class="fa fa-calendar-alt nextReading__icon"></i>
            </div>
            <div class="col-10">
                <div
                    id="nextReading-content"
                    style="display: block; position: relative"
                >
                    <h5>{{ $t('widget.mh.meterReading') }}</h5>
                    <template v-if="state && state !== 'closed'">
                        <template v-if="displayElement('pencil-icon')">
                            <div class="actionMiddleWidget toggle-icons">
                                <svg
                                    v-if="showAction && detailsLoaded"
                                    data-test="meter-widget-click-icon"
                                    id="button-submitMeterRead"
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="24px/edit">
                                        <path
                                            id="Shape"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M35.7042 3.2012L45.6862 16.9372L19.7362 36.6452C18.5652 37.5321 17.1371 38.013 15.6682 38.0152H2.3282L7.3862 25.5532C7.84809 24.4161 8.61151 23.4265 9.5942 22.6912L35.7042 3.2012ZM12.9102 23.5352L35.1382 6.9432L42.0022 16.3952L20.0642 33.0572L12.9102 23.5352ZM9.8502 26.5552C10.0741 26.0345 10.4045 25.5665 10.8202 25.1812L17.9242 34.6352C17.2602 35.0935 16.4749 35.3441 15.6682 35.3552H6.2782L9.8502 26.5552ZM45.849 44.7988V42.1388H2.151V44.7988H45.849Z"
                                            fill="#001E50"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                class="actionMiddleWidget actionMiddleWidget--default"
                                v-if="displayElement('interactive-icon')"
                            >
                                <i
                                    data-test="meter-widget-click-icon"
                                    :class="{'active': isActiveWidget}"
                                    v-if="showAction && detailsLoaded"
                                ></i>
                            </div>
                            <div class="actionMiddleWidget" v-else>
                                <i
                                    data-test="meter-widget-click-icon"
                                    class="fa fa-ellipsis-h"
                                    id="button-submitMeterRead"
                                    v-if="showAction && detailsLoaded"
                                ></i>
                            </div>
                        </template>
                    </template>
                    <template v-if="inRegistrationState">
                        <div class="nextReading__title">
                            {{ $t('widget.m.textWaitingUnclearDate') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'waitingPast'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.textWaitingUnclearDate') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'moveInMissing'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textMoveInMissing') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'moveOutMissing' && daysUntil <= 0"
                    >
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textMoveOutMissingNow') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'moveOutMissing' && daysUntil >= 1"
                    >
                        <div class="nextReading__title">
                            {{ daysUntil }} {{ daysUntil === 1 ?
                            $t('widget.m.day') : $t('widget.m.days') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textMoveOutMissing') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'turnusNow' && displayElement('show-bold-words')"
                    >
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textTurnusNow1') }}
                            <strong>{{ $t('widget.m.textTurnusNow2') }}</strong>
                        </div>
                    </template>
                    <template v-else-if="state === 'turnusNow'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.now') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.textTurnusNow') }}
                        </div>
                    </template>
                    <template
                        v-else-if="state === 'closed' && finalInvoiceCreated"
                    >
                        <div class="nextReading__title">
                            {{ $t('widget.m.finalInvoice') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.hasBeenCreated') }}
                        </div>
                    </template>
                    <template v-else-if="state === 'closed'">
                        <div class="nextReading__title">
                            {{ $t('widget.m.deliveryEnd') }}
                        </div>
                        <div class="nextReading__text">
                            {{ $t('widget.m.hasBeenReached') }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="nextReading__title">
                            <content-loader
                                v-if="daysUntil === null"
                                :width="35"
                                :height="35"
                                style="width: 35px; height: 35px"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                            <template
                                v-else-if="daysUntil == 0 && state === 'waiting'"
                                >{{ $t('widget.m.tomorrow') }}</template
                            >
                            <template v-else>
                                {{ daysUntil }} {{ daysUntil === 1 ?
                                $t('widget.m.day') : $t('widget.m.days') }}
                            </template>
                        </div>
                        <div class="nextReading__text">
                            <template
                                v-if="state === 'waiting' && daysUntil == 0"
                            >
                                {{ $t('widget.m.textWaitingTomorrow') }}
                            </template>
                            <template v-else-if="state === 'waiting'">
                                {{ $t('widget.m.textWaiting') }}
                            </template>
                            <template v-else>
                                {{ $t('widget.m.text') }}
                            </template>
                        </div>
                    </template>
                </div>
            </div>

            <meter-submit-widget
                v-if="isActiveWidget && displayElement('meter-submit-widget')"
                class="d-md-none"
            ></meter-submit-widget>
        </div>
    </div>
</div>
