<div class="mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body mx-3">
            <div class="box-title static-title">
                <i class="fas fa-funnel-dollar readingHistory-icon"></i>
                {{ $t('widget.dc.title') }}
            </div>
            <div
                class="row mobile-modal__wrap mx-0 pb-3"
                id="depositChange-content"
            >
                <template v-if="isLoading">
                    <content-loader
                        height="40"
                        primaryColor="var(--secondary)"
                        secondaryColor="var(--primary)"
                        data-test="CONTENT-LOADER"
                    ></content-loader>
                </template>
                <template v-else>
                    <template
                        v-if="depositsLoaded && isDepositsLoadedWithError"
                    >
                        <div class="col-12">
                            {{ $t('widgets.load.error.message') }}
                        </div>
                    </template>
                    <template
                        v-else-if="depositsLoaded && (depositDetails.minValue === 0 || depositDetails.minValue === null) && (depositDetails.maxValue === 0 || depositDetails.maxValue === null)"
                    >
                        <div class="col-12">
                            {{ $t('widget.dp.service.not.available') }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-12 text-center">
                            <h3>
                                {{ depositDetails.recommended | euroFull }} {{
                                $t('euro') }}
                            </h3>
                            {{ $t('widget.dc.text') }}
                        </div>
                        <div class="col-12 ps-5 pr-0">
                            <div class="d-flex">
                                <div class="w-100 pr-5 pt-5">
                                    <input
                                        v-if="displayElement('number-input')"
                                        type="number"
                                        id="range-newDepositValue"
                                        v-model="details.depositValue"
                                        :min="depositDetails.minValue"
                                        :max="depositDetails.maxValue"
                                        class="form-control"
                                    />
                                    <vue-slider
                                        v-else-if="details.depositValue && details.depositValue >= depositDetails.minValue && details.depositValue <= depositDetails.maxValue"
                                        v-model="details.depositValue"
                                        :min="depositDetails.minValue"
                                        :max="depositDetails.maxValue"
                                        :tooltip="'always'"
                                        :tooltip-formatter="tooltipFormatter"
                                    ></vue-slider>
                                    <label
                                        for="range-newDepositValue"
                                        class="text-center w-100 mt-2"
                                    >
                                        {{ $t('widget.dc.monthly') }}
                                    </label>
                                </div>
                                <div class="d-flex">
                                    <button
                                        class="btn btn-primary mt-auto"
                                        @click="submit"
                                        :disabled="submitPending"
                                    >
                                        {{ $t('widget.dc.transfer') }}
                                    </button>
                                </div>
                            </div>

                            <messages-block
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>
                        </div>
                        <div class="col-xs-12 col-sm-6"></div>
                        <div class="col-xs-12 col-sm-6">
                            <div v-if="showSurvey" class="survey-widget-block">
                                <survey-widget
                                    :widget="'deposit-change-widget'"
                                ></survey-widget>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</div>
