import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Contact from '@/interfaces/Contact';
import SubmitContact from '@/interfaces/SubmitContact';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class ContactProvider
    extends PrototypeProvider
    implements Contact
{
    public async categories(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/ticket/categories', this.config);
    }

    public async categoriesForClient(
        clientKey: string
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/ticket/categories/' + clientKey,
            this.config
        );
    }

    public async submit(data: SubmitContact): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/ticket', data, this.config);
    }
}
