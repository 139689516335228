import { EwiiInvoicesWidget } from '@/components/widgets/invoice-widget/custom/ewii';
import { InvoiceWidget } from '@/components/widgets/invoice-widget/invoice-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'ewii') {
    widget = EwiiInvoicesWidget;
} else {
    widget = InvoiceWidget;
}
export { widget as InvoiceWidget };
