<header id="m_header" class="m-grid__item m-header">
    <div class="m-container m-container--fluid m-container--full-height">
        <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- BEGIN: Brand -->
            <div
                :class="brandAuth"
                class="m-stack__item m-brand m-brand--skin-dark"
            >
                <div class="m-stack m-stack--ver m-stack--general">
                    <div
                        class="
                            m-stack__item m-stack__item--middle
                            m-brand__logo
                        "
                    >
                        <img
                            v-if="!displayElement('hide-logo')"
                            alt=""
                            :src="headerLogo()"
                            class="m-stack__logo--align-left"
                        />
                    </div>
                    <div
                        class="
                            m-stack__item m-stack__item--middle
                            m-brand__tools
                        "
                    >
                        <!-- BEGIN: Left Aside Minimize Toggle -->
                        <a
                            @click="$emit('toggleSidebar')"
                            id="m_aside_left_minimize_toggle"
                            class="
                                m-brand__icon
                                m-brand__toggler
                                m-brand__toggler--left
                                m--visible-desktop-inline-block
                            "
                        >
                            <span></span>
                        </a>
                        <!-- END -->
                        <!-- BEGIN: Responsive Aside Left Menu Toggler -->
                        <a
                            v-if="isNotAuthPage"
                            @click="$emit('toggleSidebar')"
                            id="m_aside_left_offcanvas_toggle"
                            class="
                                m-brand__icon
                                m-brand__toggler
                                m-brand__toggler--left
                                m--visible-tablet-and-mobile-inline-block
                            "
                        >
                            <span></span>
                        </a>
                        <!-- END -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
