<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <div
            @click="isShowBlock = !isShowBlock"
            v-if="!displayElement('hide-close-button')"
            class="fullwidthArea-close fullwidthArea-close--reverse"
        >
            <i
                class="fa fa-times"
                :class="[isShowBlock ? '' : 'fa-times-close']"
            ></i>
        </div>
        <div id="customer-service-form-content">
            <i
                v-if="displayElement('show-service-form-icon')"
                class="fa fa-comment-alt serviceForm-icon"
            ></i>
            <span
                class="
                    customerService-title
                    static-title static-title-kundenservice
                "
            >
                {{ $t('widget.cs.title') }}
            </span>
        </div>
        <div
            class="mobile-modal__wrap mobile-modal__wrap--customer-service"
            :class="[isShowBlock ? '' : 'content-hiden']"
        >
            <div class="mobile-modal__column col-7">
                <div class="mobile-modal__absender">
                    <div class="customer-service-form-title col-6">
                        {{ $t('widget.cs.sender') }}
                    </div>
                    <div class="mobile-modal__absender-items">
                        <div class="col-6 form-col">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    :value="details.firstname"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-6 form-col">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    :value="details.surname"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-6 form-col">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    :value="details.phone"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-6 form-col">
                            <div class="form-group">
                                <input
                                    type="email"
                                    class="form-control"
                                    v-model="details.email"
                                    :placeholder="$t('widget.cs.email')"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 textarea-container">
                    <div class="form-group">
                        <label
                            for="message"
                            class="customer-service-form-title"
                        >
                            {{ $t('widget.cs.message') }}
                        </label>
                        <textarea
                            id="message"
                            type="text"
                            class="form-control service-form-textarea"
                            rows="8"
                            v-model="details.text"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="mobile-modal__column col-5">
                <div class="col-12">
                    <div class="form-group">
                        <label
                            for="choose-topic"
                            class="customer-service-form-title"
                        >
                            {{ $t('widget.cs.topic.choose') }}
                        </label>
                        <treeselect
                            class="kundenservice-select"
                            v-model="details.categoryId"
                            id="choose-topic"
                            :options="categories"
                            :disableBranchNodes="true"
                            :maxHeight="300"
                            :placeholder="$t('widget.cs.topic.choose.placeholder')"
                            :noOptionsText="$t('widget.cs.topic.choose.no.options')"
                        />
                    </div>
                </div>
                <div class="col-12 col--mrt">
                    <div class="form-group">
                        <label
                            for="files-list"
                            class="
                                service-form-file
                                customer-service-form-title
                            "
                        >
                            {{ $t('widget.cs.files') }}
                            <span class="files-list-optional">(optional)</span>

                            <input
                                id="files-list"
                                type="file"
                                class="form-control"
                                accept="text/csv,text/plain,application/pdf,application/vnd.oasis.opendocument.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,image/gif,image/jpeg,image/png"
                                multiple
                                ref="fileList"
                                @change="processFile($event)"
                                placeholder="Auswählen"
                            />

                            <div class="form-control form-control-file">
                                <span></span>
                            </div>
                        </label>
                    </div>
                    <div class="text-end button-wrapper">
                        <button
                            :class="{'float-end': displayElement('submit-align-right')}"
                            class="btn btn-primary d-block"
                            @click="submit"
                            :disabled="submitPending"
                        >
                            {{ $t('widget.cs.submit.message') }}
                        </button>
                    </div>
                </div>
            </div>

            <messages-block
                :success="successInformation"
                :errors="errorInformation"
            ></messages-block>
        </div>
    </div>
</div>
