<div class="mobile-modal__inner" data-test="meter-history-widget">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body px-0">
            <div class="meter-history-widget-title">
                <span
                    data-test="meter-history-widget-title"
                    class="
                        box-title
                        static-title
                        position-absolute
                        ps-3
                        pt-2
                        m-0
                    "
                >
                    {{ $t('widget.mh.title') }}
                </span>
            </div>
            <div class="mobile-modal__wrap mx-0 pt-0">
                <apexchart :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div>
</div>
