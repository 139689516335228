import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Tariff from '../interfaces/Tariff';
import PrototypeProvider from '@/providers/PrototypeProvider';

import SubmitTariff from '../interfaces/SubmitTariff';
import SubmitBilling from '../interfaces/SubmitBilling';

@injectable()
export default class TariffProvider
    extends PrototypeProvider
    implements Tariff
{
    public async overview(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/tariff/' + id, this.config);
    }

    public async getPaymentDetailsByContractId(contractId: number) {
        this.createConfig();
        return await this.http.get(
            '/contract/' + contractId + '/payment-details',
            this.config
        );
    }

    public async details(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/tariff/' + id + '/details', this.config);
    }

    public async list(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/tariff/' + id + '/list', this.config);
    }

    public async tariffsForTariffChange(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/tariff/' + id + '/change-list',
            this.config
        );
    }

    public async tariffsForRecommendation(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/tariff/' + id + '/recommendation-list',
            this.config
        );
    }

    public async communications(
        id: number,
        limit: number,
        offset: number
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/communications/' + id + '/' + limit + '/' + offset,
            this.config
        );
    }

    public async bswSavedCo2(id: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/bsw/saved-co2/' + id, this.config);
    }

    public async changeBillingData(
        data: SubmitBilling
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/payment/billing-address',
            data,
            this.config
        );
    }

    public async changeTariff(data: SubmitTariff): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/tariff', data, this.config);
    }
}
