import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './cancellation-widget.html';

import * as Sentry from '@sentry/browser';

import { Watch } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';
import vSelect from 'vue-select';

import { MessagesBlock } from '@/components/snippets/messages';

import Auth from '@/interfaces/Auth';
import Contracts from '@/interfaces/Contracts';
import SubmitCancellation, {
    CancelType,
} from '@/interfaces/SubmitCancellation';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

@WithRender
@Component({
    components: {
        // External packages
        datetime: Datetime,
        'messages-block': MessagesBlock,
        'v-select': vSelect,
    },
})
export class CancellationWidget extends Base {
    public date = null;
    public submitPending = false;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public confirmationCheckboxError = false;
    public cancelReasonError = false;
    public dateError = false;

    protected get cancelTypeOptions(): Record<any, any> {
        let options = [
            {
                label: this.$t('widget.c.cancellation.type.asap'),
                value: 'deadlineDate',
            },
            {
                label: this.$t('widget.c.cancellation.type.extraordinary'),
                value: 'extraOrdinaryDate',
            },
            {
                label: this.$t('widget.c.cancellation.type.specified'),
                value: 'cancelDate',
            },
            {
                label: this.$t('widget.c.cancellation.type.relocation'),
                value: 'moveAtDate',
            },
        ];

        if (this.displayElement('hide-relocation-cancellation')) {
            options.splice(options.length - 1, 1);
        }

        if (this.displayElement('hide-extra-ordinary-cancellation')) {
            options.splice(1, 1);
        }

        const enabledCancellationTypes =
            this.$store.state.settings.enabledCancellationTypes;

        if (enabledCancellationTypes.length > 0)
            options = options.filter((option) => {
                return enabledCancellationTypes.includes(option.value);
            });

        return options;
    }

    protected details: SubmitCancellation = {
        contractId: this.$store.state.contracts.contractId,
        cancelType: null,
        cancelReason: undefined,
        extraOrdinaryDate: null,
        cancelDate: null,
        moveAtDate: null,
        confirmation: false,
    };

    get contractStatus(): Record<any, any> {
        let contractInfo = {
            contractId: null,
            status: null,
            statusCode: 0,
            statusCodeTitle: null,
        };
        if (
            this.$store.state.contracts.list &&
            this.$store.state.contracts.contractId
        ) {
            const selectedContractInfo =
                this.$store.state.contracts.list.filter((contract) => {
                    return (
                        contract.contractId ===
                        this.$store.state.contracts.contractId
                    );
                })[0];
            if (selectedContractInfo) {
                contractInfo = selectedContractInfo;
            }
        }
        return contractInfo;
    }

    protected elements = {
        sachsenenergie: [
            'hide-extra-ordinary-cancellation',
            'cancellation-datetime',
        ],
        elli: ['hide-relocation-cancellation'],
    };

    get datetimePhrases(): Record<any, any> {
        return {
            ok: this.$t('datetime.ok'),
            cancel: this.$t('datetime.cancel'),
        };
    }

    get cancellationReasonOptions() {
        if (!this.details.cancelType) return [];

        return this.$store.state.settings.cancellationReasons[
            this.details.cancelType
        ];
    }

    public cancellationOptionSelected(value): void {
        this.details.cancelType = value.value;
        this.details.cancelReason = undefined;
    }

    public cancellationReasonOptionSelected(
        cancelReason: Record<'label' | 'value', string>
    ): void {
        this.details.cancelReason = cancelReason.value;
    }

    set cancelReason(cancelReason: { value: string; label: string }) {
        this.cancelReasonError = false;
        this.details.cancelReason = cancelReason.value;
    }

    get cancelReason(): { value: string; label: string } {
        return this.cancellationReasonOptions?.find(
            (option: { value: string; label: string }): boolean =>
                option.value === this.details.cancelReason
        );
    }

    @Watch('details.cancelType')
    @Watch('date')
    protected onDateChange(): void {
        this.details.extraOrdinaryDate = null;
        this.details.cancelDate = null;
        this.details.moveAtDate = null;

        switch (this.details.cancelType) {
            case 'extraOrdinaryDate':
                this.details.extraOrdinaryDate = this.date;
                break;
            case 'cancelDate':
                this.details.cancelDate = this.date;
                break;
            case 'moveAtDate':
                this.details.moveAtDate = this.date;
                break;
        }
    }

    protected mounted(): void {
        this.details.contractId = this.$store.state.contracts.contractId;
    }

    protected async submit(): Promise<void> {
        if (!this.details.cancelType) return;
        if (!this.details.confirmation) {
            this.confirmationCheckboxError = true;
            return;
        }

        if (
            this.cancellationReasonOptions?.length &&
            !this.details.cancelReason
        ) {
            this.cancelReasonError = true;
            return;
        }

        if (
            ['extraOrdinaryDate', 'cancelDate', 'moveAtDate'].indexOf(
                this.details.cancelType
            ) !== -1 &&
            !this.date
        ) {
            this.dateError = true;
            return;
        }

        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            await contractsProvider.cancelContract(this.details).then(
                (response) => {
                    this.onCancelContractCallback(response);
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    const errorMessageObj = error.response.data.errorMessages,
                        errorMessage =
                            this.$t(errorMessageObj.key) !== errorMessageObj.key
                                ? this.$t(errorMessageObj.key)
                                : errorMessageObj.message ||
                                  this.$t('general.error').toLocaleString();

                    this.errorInformation.push({
                        key: '',
                        message: errorMessage,
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }

    protected onCancelContractCallback(response): void {
        this.submitPending = false;
        if (
            response.data.success &&
            typeof response.data.messageLocalized === 'object'
        ) {
            this.submitPending = true;
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
            this.successInformation.push(response.data.messageLocalized);
        } else if (
            response.data.success &&
            typeof response.data.messageLocalized === 'string'
        ) {
            this.submitPending = true;
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
            this.successInformation.push({
                key: '',
                message: response.data.messageLocalized,
            });
        } else {
            if (
                response.data.errorMessages &&
                response.data.errorMessages.length > 0
            ) {
                this.errorInformation = response.data.errorMessages;
            } else if (typeof response.data.messageLocalized === 'object') {
                this.errorInformation.push(response.data.messageLocalized);
            } else if (typeof response.data.messageLocalized === 'string') {
                this.errorInformation.push({
                    key: '',
                    message: response.data.messageLocalized,
                });
            }
        }
    }

    get isSubmitButtonDisabled(): boolean {
        if (
            this.details.cancelType === null ||
            !Object.values(CancelType).includes(this.details.cancelType) ||
            this.submitPending
        ) {
            return true;
        }

        if (this.details.cancelType === CancelType.extraOrdinaryDate)
            return !this.details.extraOrdinaryDate;
        if (this.details.cancelType === CancelType.cancelDate)
            return !this.details.cancelDate;
        if (this.details.cancelType === CancelType.moveAtDate)
            return !this.details.moveAtDate;

        return false;
    }
}
