import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './tariff-recommendation.html';
import IocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import SERVICES from '@/container/Services';
import * as Sentry from '@sentry/browser';
import Auth from '@/interfaces/Auth';
import Tariff from '@/interfaces/Tariff';
import { MessagesBlock } from '@/components/snippets/messages';
import { Watch } from 'vue-property-decorator';

import VueButtonSpinner from 'vue-button-spinner';
import moment from 'moment';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const tariffProvider = IocContainer.get<Tariff>(SERVICES.TARIFF);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
    },
    components: {
        // External packages
        'messages-block': MessagesBlock,
        'vue-button-spinner': VueButtonSpinner,
    },
})
export class TariffRecommendation extends Base {
    public showTariff = false;
    public displayDetails = false;

    public confirmed = false;

    protected isLoading = false;
    protected status = '';

    public successInformation: Record<any, any> = [];
    public errorInformation: Record<any, any> = [];

    public isNotPA = false;

    @Watch('confirmed')
    protected handleConfirmedChanged(): void {
        this.errorInformation = [];
    }

    get recommendationTariff(): any {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )?.recommended?.[0] ?? {}
        );
    }

    get allTariffs(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList;
        }
        return [];
    }
    get isTariffChange(): boolean {
        return false;
    }

    get priceAdjustmentTariff(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList
        ) {
            const tariff = this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList.find((item) => {
                return (
                    item.priceAdjustmentOf !== null &&
                    item.activeUntil === null &&
                    moment(item.activeFrom) >= moment()
                );
            });
            return tariff ?? {};
        }
        return {};
    }

    get priceAdjustmentTariffDeposit(): number | string {
        if (this.priceAdjustmentTariff) {
            return (
                ((parseFloat(this.usage) * this.nextWorkingPriceBrutto) / 100 +
                    this.priceAdjustmentTariff.basePrice) /
                12
            );
        }
        return '';
    }

    get nextWorkingPriceBrutto(): number {
        const prices = this.priceAdjustmentTariff.workingPrices;
        if (prices) {
            return prices[0].workingPriceBrutto;
        }
        return 0;
    }

    get contract(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract;
        }

        return {
            zip: '',
            usage: 0,
        };
    }

    get postalCode(): string {
        return this.contract.deliveryAddress.zip;
    }

    get usage(): string {
        return this.contract.usage;
    }

    get nextActiveFrom(): string {
        return moment(this.priceAdjustmentTariff.activeFrom).format(
            'DD.MM.YYYY'
        );
    }

    get nextCancellationDatePlusOne(): string {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.nextCancellationDate
        ) {
            return moment(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract.nextCancellationDate
            )
                .add(1, 'days')
                .format('DD.MM.YYYY');
        }
        return '';
    }

    public created() {
        this.$store.commit('settings/SET_SETTING', {
            key: 'tariffRecommendationWidgetCreated',
            value: true,
        });
    }

    public closeModal(): void {
        this.$emit('closeModal');
    }

    protected isBlue(left, right): boolean {
        return parseFloat(left) > parseFloat(right);
    }

    protected getDurationType(type: string, amount: number): string {
        if (type) {
            type = type.toUpperCase();
        }
        if (type === 'MONTHS' && amount === 1) {
            return this.$t('widget.tr.month').toString();
        } else if (type === 'MONTHS' && (amount > 1 || amount === 0)) {
            return this.$t('widget.tr.months').toString();
        } else if (type === 'WEEKS' && amount === 1) {
            return this.$t('widget.tr.week').toString();
        } else if (type === 'WEEKS' && (amount > 1 || amount === 0)) {
            return this.$t('widget.tr.weeks').toString();
        } else if (type === 'DAYS' && amount === 1) {
            return this.$t('widget.tr.day').toString();
        } else if (type === 'DAYS' && (amount > 1 || amount === 0)) {
            return this.$t('widget.tr.days').toString();
        } else {
            return type;
        }
    }

    protected async submit(): Promise<any> {
        if (!this.confirmed) {
            this.errorInformation.push({
                key: 'widget.tr.error.require',
                message: this.$t('widget.tr.error.require').toString(),
            });

            return;
        }
        if (authProvider.isAuthenticated()) {
            this.isLoading = true;
            let data = {
                contractId: this.$store.state.contracts.contractId,
                productCode: this.recommendationTariff.productCode,
                tariffChangeDateType: 'deadline',
                tariffChangeStartDate: null,
            };
            if (!this.isTariffChange) {
                data = Object.assign(data, { is_recommended: true });
            }
            await tariffProvider.changeTariff(data).then(
                (response): void => {
                    this.isLoading = false;
                    if (response.data.success) {
                        this.successInformation.push({
                            key: 'widget.tr.success',
                            message: this.$t('widget.tr.success').toString(),
                        });
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.successInformation = [];
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }
}
