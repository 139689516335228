import ProductData from '@/interfaces/ProductData';
import NonCommodityProductFacade from '@/models/NonCommodityProductFacade';
import _ from 'lodash';

export abstract class ThgBaseService {
    public extendedDataParentKey: string = 'THG_MULTIYEAR';

    // Single fields
    public extendedDataKeyPartnerNumber: string = 'PARTNER_NUMBER';
    public extendedDataKeyVehicleRegistration: string = 'VEHICLE_REGISTRATION';
    public extendedDataKeyLastStatusUpdates: string = 'LAST_STATUS_UPDATES';
    public extendedDataKeyDateTermsConfirmation: string =
        'DATE_TERMS_CONFIRMATION';
    public extendedDataKeyDateOwnerConsent: string = 'DATE_OWNER_CONSENT';
    public extendedDataKeyVehicleIds: string = 'VEHICLE_IDS';
    public extendedDataKeyVehicleOwner: string = 'VEHICLE_OWNER';
    public extendedDataKeyReferenceYear: string = 'Reference_Year';
    public extendedDataKeyProvisionContract: string = 'PROVISIONCONTRACT';
    public extendedDataKeyVatId: string = 'VAT_ID';
    public extendedDataKeyTaxNumber: string = 'TAX_NUMBER';
    public extendedDataKeySkipRevocationPeriod: string =
        'SKIP_REVOCATION_PROCESS';

    // Multi fields
    public extendedDataKeyDateKfzDocValidation: string =
        'DATE_KFZ_DOC_VALIDATION';
    public extendedDataKeyDatesVehicleCreatedGreenTrax: string =
        'DATES_VEHICLE_CREATED_GT';
    public extendedDataKeyCurrentStates: string = 'currentStates';
    public extendedDataKeyVoucherCodes: string = 'VOUCHER_CODES';
    public extendedDataKeyStatusInfo: string = 'STATUS_INFOS';
    public extendedDataKeyVehicleClasses: string = 'VEHICLE_CLASSES';
    public extendedDataKeyVehicleClassesWorkDigital: string =
        'VEHICLE_CLASSES_WD';
    public extendedDataKeyLicensePlates: string = 'LICENSE_PLATES';
    public extendedDataKeyPricingProductVersions: string =
        'PRICING_PRODUCT_VERSIONS';
    public extendedDataKeyThgPrices: string = 'THG_PRICES';

    // States
    public stateSentToUba = 'sent_to_uba';
    public stateConfirmedByUba = 'confirmed_by_uba';
    public stateRejectedByUba = 'rejected_by_uba';
    public stateNone = 'none';
    public statePending = 'pending';
    public stateUnknown = 'unknown';
    public stateVerified = 'verified';
    public stateNotVerifiedWrongDocumentType =
        'not_verified_wrong_document_type';
    public stateNotVerifiedWrongFuelCode = 'not_verified_wrong_fuel_code';
    public stateNotVerifiedUnreadable = 'not_verified_unreadable';
    public stateNotVerifiedOtherReason = 'not_verified_other_reason';
    public stateNotVerifiedDuplicate = 'not_verified_duplicate';

    //
    public vehicleDataUpdateTypeNewDocument = 'newDocument';
    public vehicleDataUpdateTypeNewVehicle = 'newVehicle';

    protected abstract getExtendedDataFields(): Record<any, any>;
    protected storeInstance;

    private get contract(): any {
        return this.contractsList.find(
            (item) => item.contractId === this.contractId
        );
    }

    private get contractData(): any {
        return this.store.getters['tariff/getState'](this.contractId).contract;
    }

    get contractsList(): Record<any, any> {
        if (!this.store.state.contracts.list) {
            return [];
        }
        return this.store.state.contracts.list;
    }

    get contractId(): number | null {
        return this.store.state.contracts.contractId;
    }

    public getPreviousYear(year: string): string {
        const yearNumber = parseInt(year);
        return yearNumber - 1 + '';
    }

    public getNextYear(year: string): string {
        const yearNumber = parseInt(year);
        return yearNumber + 1 + '';
    }

    /**
     * Retrieves a list of license plates for all years
     */
    public getLicensePlate(
        externalExtendedData?: Record<any, any> | null
    ): string {
        return this.getExtendedDataMultiFieldValue(
            this.extendedDataKeyLicensePlates,
            externalExtendedData
        );
    }

    public getStatusForProduct(product: NonCommodityProductFacade): string {
        let languageKey: string = 'unknown';
        const status = this.getCurrentStateForYear(product.year);
        if (status && status.length > 0) {
            languageKey = status;
        }
        return languageKey.trim().toLowerCase();
    }

    public getFormattedStatusForProduct(
        product: NonCommodityProductFacade
    ): string {
        const languageKey: string = this.getStatusForProduct(product);
        return 'widget.nc.bonus.status.' + languageKey.trim().toLowerCase();
    }

    public getCurrentStateForYear(year: string): string {
        let state: string = this.getExtendedDataMultiFieldValueForYear(
            this.extendedDataKeyCurrentStates,
            year
        );
        state = state.replace(/[\u200B-\u200D\uFEFF]/g, '');
        return state;
    }

    public isStatusVerifiedForYear(year: string): boolean {
        return (
            this.getCurrentStateForYear(year).toLowerCase() ==
            this.stateVerified
        );
    }

    public isStatusPendingForYear(year: string): boolean {
        return (
            this.getCurrentStateForYear(year).toLowerCase() == this.statePending
        );
    }

    public isStatusUnknownForYear(year: string): boolean {
        return [this.stateUnknown, this.stateNone, ''].includes(
            this.getCurrentStateForYear(year).toLowerCase()
        );
    }

    public isStatusRejectedForYear(year: string): boolean {
        return (
            this.getCurrentStateForYear(year).toLowerCase() ==
            this.stateRejectedByUba
        );
    }

    public isStatusConfirmedForYear(year: string): boolean {
        return [this.stateConfirmedByUba].includes(
            this.getCurrentStateForYear(year).toLowerCase()
        );
    }

    public isStatusSentForYear(year: string): boolean {
        return this.getCurrentStateForYear(year) == this.stateSentToUba;
    }

    public isStatusErrorForYear(year: string): boolean {
        return [
            this.stateNotVerifiedWrongDocumentType,
            this.stateNotVerifiedWrongFuelCode,
            this.stateNotVerifiedUnreadable,
            this.stateNotVerifiedOtherReason,
        ].includes(this.getCurrentStateForYear(year).toLowerCase());
    }

    public isStatusNotVerifiedDuplicateForYear(year: string): boolean {
        return [this.stateNotVerifiedDuplicate].includes(
            this.getCurrentStateForYear(year).toLowerCase()
        );
    }

    public isBankDataRequiredForProductAndYear(
        product: NonCommodityProductFacade | null,
        year: string
    ) {
        return product?.isCashProduct && !this.userHasBankData();
    }

    public userHasBankData(): boolean {
        const paymentDetailsFromStore = this.store.getters[
            'tariff/getActiveBankDetails'
        ](this.contractId);
        if (
            !this.store.state.settings.activeFromChangeBankingDetailsInOutActive
        ) {
            return paymentDetailsFromStore.inout[0]?.iban != '';
        } else {
            return paymentDetailsFromStore.out[0]?.iban != '';
        }
    }

    /**
     * Checks whether the current contract has any bonus at all
     */
    public get hasBonus(): boolean {
        return this.getBonusYears().length > 0;
    }

    public hasBonusForYear(year: string): boolean {
        return this.getBonusYears().indexOf(year) >= 0;
    }

    public isBonusForYearConfirmed(year: string): boolean {
        const stateForYear = this.getCurrentStateForYear(year).toLowerCase();
        return (
            [
                this.stateConfirmedByUba,
                this.stateRejectedByUba,
                this.stateVerified,
                this.stateSentToUba,
            ].indexOf(stateForYear) > -1
        );
    }

    public get allPreviousBonusProducts(): NonCommodityProductFacade[] {
        const list: NonCommodityProductFacade[] = [];
        const years: string[] = this.getBonusYears().reverse();
        for (const year of years) {
            list.push(this.getBonusProductForYear(year));
        }
        return list;
    }

    public getBonusProductForYear(year: string): NonCommodityProductFacade {
        const basePrice = this.getExtendedDataMultiFieldValueForYear(
            this.extendedDataKeyThgPrices,
            year
        );
        const data = {
            year: year,
            voucherCode: this.getExtendedDataMultiFieldValueForYear(
                this.extendedDataKeyVoucherCodes,
                year
            ),
            basePrice: isNaN(basePrice) ? 0 : basePrice,
            status: this.getExtendedDataMultiFieldValueForYear(
                this.extendedDataKeyStatusInfo,
                year
            ),
            elliNonCommodityProcurementPrices: [
                {
                    classification: this.getVehicleClassForYear(year),
                    brutto: this.getExtendedDataMultiFieldValueForYear(
                        this.extendedDataKeyThgPrices,
                        year
                    ),
                },
            ],
        };

        return NonCommodityProductFacade.withData(data);
    }

    public hasBonusInPreviousYear(year: string): boolean {
        const yearInt: number = parseInt(year);
        const previousYear: string = yearInt - 1 + '';
        const existingYears: string[] = this.getBonusYears();
        return existingYears.includes(previousYear);
    }

    protected getVehicleClassForYear(year: string): string {
        let value: string = this.getExtendedDataMultiFieldValueForYear(
            this.extendedDataKeyVehicleClasses,
            year
        );
        if (!value || value.length == 0) {
            value = this.getExtendedDataMultiFieldValueForYear(
                this.extendedDataKeyVehicleClassesWorkDigital,
                year
            );
        }
        return value.toLowerCase();
    }

    /**
     * Retrieves a sorted list of all years with a bonus for the current contract, regardless of the status.
     */
    public getBonusYears(): string[] {
        const years: string[] = [];
        const states = this.getExtendedDataMultiFieldValue(
            this.extendedDataKeyCurrentStates
        );

        for (const entry of states) {
            if (years.indexOf(entry.year) == -1) {
                years.push(entry.year);
            }
        }

        return years.sort((a, b) => {
            return Number(b) - Number(a);
        });
    }

    get store(): any {
        if (!this.storeInstance || typeof this.storeInstance == 'undefined') {
            throw new Error('Store not initialized!');
        }
        return this.storeInstance;
    }

    public getExtendedDataMultiFieldValue(
        fieldKey: string,
        externalExtendedData?: Record<any, any> | null
    ): any {
        const extendedData: Record<any, any> =
            externalExtendedData && externalExtendedData != null
                ? externalExtendedData
                : this.extendedData;
        if (
            extendedData &&
            extendedData.values &&
            extendedData.values[this.extendedDataParentKey]
        ) {
            for (const extendedField of extendedData.values[
                this.extendedDataParentKey
            ]) {
                if (extendedField.technicalKey == fieldKey) {
                    try {
                        return JSON.parse(extendedField.value);
                    } catch (e) {
                        return extendedField.value;
                    }
                }
            }
        }

        return [];
    }

    public getExtendedDataMultiFieldValueForYear(
        fieldKey: string,
        year: string
    ): any {
        let values: any = this.getExtendedDataFieldValue(fieldKey);
        if (typeof values == 'string' && values.length > 0) {
            try {
                values = JSON.parse(values);
            } catch (e) {
                values = [];
            }
        }

        for (const entry of values) {
            if (entry.year == year) {
                return entry.value ?? null;
            }
        }

        return '';
    }

    public setExtendedDataMultiFieldValue(
        year: string,
        fieldKey: string,
        value: any
    ): void {
        const multiYearValue: Record<any, any> = this.makeMultiYearValue(
            year,
            fieldKey,
            value
        );
        this.setExtendedDataFieldValue(fieldKey, multiYearValue);
    }

    protected makeMultiYearValue(
        year: string,
        fieldKey: string,
        value: any
    ): Record<any, any> {
        const multiYearValues = this.getExtendedDataMultiFieldValue(fieldKey);
        let existing = false;

        if (this.hasExtendedDataValue(fieldKey)) {
            for (const existingData of multiYearValues) {
                if (multiYearValues.year == year) {
                    existingData.value = value;
                    existing = true;
                    break;
                }
            }
        }

        if (!existing) {
            multiYearValues.push({
                year: year,
                valaue: value,
            });
        }

        return multiYearValues;
    }

    public hasExtendedDataValue(fieldKey: string): boolean {
        if (this.extendedData.values[this.extendedDataParentKey]) {
            for (const existingData of this.extendedData.values[
                this.extendedDataParentKey
            ][fieldKey]) {
                if (existingData.technicalKey == fieldKey) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Override this if needed, e.g. for multi-year fields
     */
    protected modifyExtendedDataDefaultValues(
        fields: Record<any, any>
    ): Record<any, any> {
        return fields;
    }

    public setStore(
        store: any,
        forceOverwrite: boolean = false
    ): ThgBaseService {
        try {
            if (
                !this.store ||
                typeof this.store == 'undefined' ||
                forceOverwrite
            ) {
                this.storeInstance = store;
            }
        } catch (e) {
            this.storeInstance = store;
        }
        return this;
    }

    public setExtendedDataDefaultValues(): void {
        let fields: Record<any, any> = this.getExtendedDataFields();
        fields = this.modifyExtendedDataDefaultValues(fields);

        this.contract.extendedData = _.merge(
            fields,
            this.contract.extendedData
        );
    }

    public get extendedData(): Record<any, any> {
        if (this.contract && this.contract.extendedData == null) {
            this.setExtendedDataDefaultValues();
        }
        return this.contract ? this.contract.extendedData : {};
    }

    public getExtendedDataFieldValue(fieldKey: string): any {
        if (this.extendedData.values[this.extendedDataParentKey]) {
            for (const extendedData of this.extendedData.values[
                this.extendedDataParentKey
            ]) {
                if (extendedData.technicalKey == fieldKey) {
                    return extendedData.value;
                }
            }
        }

        return '';
    }

    public setExtendedDataFieldValue(fieldKey: string, value: any): void {
        if (this.extendedData.values[this.extendedDataParentKey]) {
            for (const extendedData of this.extendedData.values[
                this.extendedDataParentKey
            ]) {
                if (extendedData.technicalKey == fieldKey) {
                    extendedData.value = value;
                    return;
                }
            }
        }
    }

    public isEco(product: ProductData): boolean {
        return product.productCode.toLowerCase().includes('bonus');
    }

    public get products(): ProductData[] {
        const products = this.store.state.availableProducts;
        return products.length > 0 ? products : [];
    }

    public get isCustomerTypePrivate(): boolean {
        return this.contractData.business === false;
    }

    public get isCustomerTypeBusiness(): boolean {
        return this.contractData.business === true;
    }
}
