import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './thg-fleet-manager-bonus-widget.html';
import iocContainer from '@/container/IocContainer';
import Bonus from '@/interfaces/Bonus';
import SERVICES from '@/container/Services';
import NonCommodityProductFacade from '@/models/NonCommodityProductFacade';
import ProductData from '@/interfaces/ProductData';
import IocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import Files from '@/interfaces/Files';
import SubmitContact from '@/interfaces/SubmitContact';
import Vapor from 'laravel-vapor';

const bonusProvider = iocContainer.get<Bonus>(SERVICES.BONUS);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const filesProvider = IocContainer.get<Files>(SERVICES.FILES);

import VueButtonSpinner from 'vue-button-spinner';

import { PDFDocument } from 'pdf-lib';

@WithRender
@Component({
    filters: {
        euro: formatProvider.euro(),
    },
    components: {
        VueButtonSpinner,
    },
})
export class ThgFleetManagerBonusWidget extends Base {
    public step = 1;

    public fileUploadError = false;

    public isSuccess = false;

    public isLoading = false;

    public uploadingToS3 = false;

    public completedFiles: any = [];
    public errorFiles: any = [];

    public confirmVehiclesOwnership = false;
    public errorVehiclesOwnership = false;
    public confirmAgb = false;
    public errorAgb = false;

    protected details: SubmitContact = {
        clientKey: null,
        contractId: null,
        customerId: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        categoryId: null,
        categoryName: null,
        text: null,
        documentContentTypes: [],
        documentFileNames: [],
        documents: [],
    };

    protected documents: any = [];

    protected productData: ProductData[] = [];

    protected handler = (e) => {
        e.preventDefault();
        e.returnValue = '';
    };
    public goNext() {
        this.step++;
    }

    public closeStep() {
        this.step = 1;
    }

    public resetWidget() {
        this.step = 1;
        this.documents = [];
        this.completedFiles = [];
        this.errorFiles = [];
        this.isLoading = false;
        this.isSuccess = false;
        this.confirmVehiclesOwnership = false;
        this.confirmAgb = false;
    }

    public mounted() {
        this.loadProducts();
    }

    get companyName() {
        return (
            this.customerDetails.company ?? this.customerDetails.billingCompany
        );
    }

    get pricesByClass() {
        const out = {};
        if (
            this.productData.length > 0 &&
            this.businessProduct.elliNonCommodityProcurementPrices
        ) {
            for (const product of this.businessProduct
                .elliNonCommodityProcurementPrices) {
                Object.assign(out, {
                    [product.classification]: product.net,
                });
            }
        }
        return out;
    }

    get businessProduct() {
        return (
            this.productData.find((product) => {
                return product.productCode.includes('_B2B');
            }) ?? { elliNonCommodityProcurementPrices: [], productCode: '' }
        );
    }

    private processFile(event, i = 0): void {
        this.uploadingToS3 = true;
        if (i === 0) {
            this.fileUploadError = false;
        }
        if (event.target.files[i]) {
            const reader = new FileReader();
            if (event.target.files[i].type === 'application/pdf') {
                reader.readAsArrayBuffer(event.target.files[i]);
                reader.onload = async () => {
                    if (reader.result) {
                        const pdfSrcDoc = await PDFDocument.load(reader.result);
                        const pagesCount = pdfSrcDoc.getPageCount();
                        const pages: any = [];
                        for (let j = 0; j < pagesCount; j++) {
                            const pdfNewDoc = await PDFDocument.create();
                            const page = await pdfNewDoc.copyPages(pdfSrcDoc, [
                                j,
                            ]);
                            pdfNewDoc.addPage(page[0]);
                            const onePage = await pdfNewDoc.save();
                            const name = event.target.files[i].name.split('.');
                            const result = await this.uploadToS3(
                                new File(
                                    [onePage],
                                    name[0] + '_page_' + (j + 1) + '.' + name[1]
                                ),
                                event.target.files[i].type
                            );
                            pages.push({
                                name:
                                    name[0] +
                                    '_page_' +
                                    (j + 1) +
                                    '.' +
                                    name[1],
                                type: event.target.files[i].type,
                                content: result,
                            });
                        }

                        this.documents.push({
                            name: event.target.files[i].name,
                            type: event.target.files[i].type,
                            pages: pages,
                        });
                    }

                    this.processFile(event, i + 1);
                };
            } else {
                reader.readAsBinaryString(event.target.files[i]);
                reader.onload = async () => {
                    if (event.target.files[i].size > 20000000) {
                        this.fileUploadError = true;
                    } else {
                        const result = await this.uploadToS3(
                            event.target.files[i],
                            event.target.files[i].type
                        );
                        this.documents.push({
                            name: event.target.files[i].name,
                            type: event.target.files[i].type,
                            content: result,
                        });
                    }
                    this.processFile(event, i + 1);
                };
            }
        } else {
            this.uploadingToS3 = false;
        }
    }

    private removeFile(index: number): void {
        this.fileUploadError = false;
        this.documents.splice(index, 1);
    }

    public loadProducts() {
        this.productData = [];
        const data = {
            contractId: null,
            customerId: parseInt(this.customerDetails.id),
            campaign: 'NAMGHT',
        };
        bonusProvider.getBonusProducts(data).then((response) => {
            if (response.data.success) {
                for (const product of response.data.products) {
                    const productInformation =
                        NonCommodityProductFacade.withData(product);
                    if (productInformation.isBusinessProduct) {
                        this.productData.push(productInformation);
                    }
                }
            }
        });
    }

    public async uploadFiles() {
        if (!this.confirmVehiclesOwnership) {
            this.errorVehiclesOwnership = true;
        }
        if (!this.confirmAgb) {
            this.errorAgb = true;
        }
        if (this.errorVehiclesOwnership || this.errorAgb) {
            return;
        }
        this.isLoading = true;
        window.addEventListener('beforeunload', this.handler);
        this.goNext();
        const preparedFiles: any = [];
        this.documents.forEach((document) => {
            if (document.pages) {
                document.pages.forEach((page) => {
                    preparedFiles.push({
                        file: page,
                        campaign: 'NAMGHT', //this.nonCommodityProductCampaign,
                        productCode: this.businessProduct.productCode,
                    });
                });
            } else {
                preparedFiles.push({
                    file: document,
                    campaign: 'NAMGHT', //this.nonCommodityProductCampaign,
                    productCode: this.businessProduct.productCode,
                });
            }
        });

        while (preparedFiles.length > 0) {
            const items = preparedFiles.splice(0, 5);
            const promises: any = [];
            items.forEach((item) => {
                promises.push(
                    filesProvider.uploadFilesLive(item).then((response) => {
                        this.parseResponse(response);
                    })
                );
            });
            await Promise.allSettled(promises);
        }

        this.isLoading = false;
        window.removeEventListener('beforeunload', this.handler);
    }

    private parseResponse(response: any) {
        const pricesByClass = this.pricesByClass;
        if (response.data.responseInfo.status !== 'verified') {
            let filename = response.data.filename;
            let page = 1;
            if (filename.includes('_page_')) {
                page = parseInt(filename.split('_page_')[1].split('.')[0]);
                filename =
                    filename.split('_page_')[0] + '.' + filename.split('.')[1];
            }
            this.errorFiles.push({
                name: filename,
                page: page,
                error: response.data.responseInfo.status_reason,
            });
        } else {
            this.completedFiles.push({
                license: response.data.responseInfo.vehicle_registration,
                vin: response.data.responseInfo.vin,
                class: response.data.responseInfo.vehicle_class,
                price: pricesByClass[
                    response.data.responseInfo.vehicle_class.toLowerCase()
                ],
            });
        }
    }

    private uploadToS3(file, type) {
        return new Promise((resolve, reject) => {
            Vapor.store(file, {
                signedStorageUrl:
                    process.env.VUE_APP_API_URL +
                    '/v1/vapor/signed-storage-url',
                headers: {
                    Authorization: process.env.VUE_APP_API_AUTH,
                },
                visibility: 'public-read',
            })
                .then((response) => {
                    resolve({
                        uuid: response.uuid,
                        s3key: response.key,
                        bucket: response.bucket,
                        name: file.name,
                        type: type,
                    });
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                    console.log(error.response);
                });
        });
    }
}
