export * from './donation-widget-v2';

import { DonationWidget } from '@/components/widgets/donation-widget/donation-widget';
import { DonationWidgetV2 } from '@/components/widgets/donation-widget/donation-widget-v2';

const widgetTypes = {
    bsw: DonationWidgetV2,
};

const widget =
    widgetTypes[process.env.VUE_APP_ENVIRONMENT as string] ?? DonationWidget;

export { widget as DonationWidget };
