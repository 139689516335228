import Base from '@/mixins/base';
import { Component } from 'vue-property-decorator';
import WithRender from './elli-general.html';

import { ChangeBillingDataWidgetElli } from '@/components/widgets/change-billing-data-widget/custom/elli';
import { ChangeBillingDataWidgetElliThg } from '@/components/widgets/change-billing-data-widget/custom/elli-thg';
import { ChangeBillingDataWidgetElliThgDealer } from '@/components/widgets/change-billing-data-widget/custom/elli-thg-dealer';

@WithRender
@Component({
    components: {
        ChangeBillingDataWidgetElli,
        ChangeBillingDataWidgetElliThg,
        ChangeBillingDataWidgetElliThgDealer,
    },
})
export class ChangeBillingDataWidgetElliGeneral extends Base {
    get widget() {
        const contract = this.contractsList.find(
            (item) => item.contractId == this.contractId
        );
        if (contract) {
            if (this.isDealer(contract)) {
                return ChangeBillingDataWidgetElliThgDealer;
            }
            if (this.isThg(contract)) {
                return ChangeBillingDataWidgetElliThg;
            }
        }

        if (this.customerDetails.isFleetCustomer) {
            return ChangeBillingDataWidgetElliThg;
        }

        return ChangeBillingDataWidgetElli;
    }
}
