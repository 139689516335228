import { injectable } from 'inversify';
import PrototypeProvider from '@/providers/PrototypeProvider';
import IEmailProvider from '@/interfaces/Mails';

@injectable()
export default class EmailProvider
    extends PrototypeProvider
    implements IEmailProvider
{
    public sendPartnerCodeSnippetMail(data) {
        this.createConfig();
        data.type = 'PartnerPortalCodeSnippet';
        return this.http.post('/send/email', data, this.config);
    }
}
