<div class="m-portlet m-portlet--mobile m-portlet--post-box">
    <div class="m-portlet__body">
        <div class="row">
            <div class="footer-content--elli">
                <div class="footer-content__column">
                    <img :src="footerImgLink" alt="" />
                </div>
                <div class="footer-content__column">
                    <div class="footer-content__info">
                        <div class="footer-content__title">
                            {{ $t(prefix+'.hotline.header') }}
                        </div>
                        <div class="footer-content__text">
                            {{ $t(prefix+'.hotline.info') }}
                        </div>
                        <a
                            :href="$t(prefix+'.hotline.phone.link')"
                            class="footer-content__link"
                            >{{ $t(prefix+'.hotline.phone') }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col-xs-12 d-flex justify-content-center"
                :class="['col-sm-12']"
            >
                <ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
                    <li class="m-nav__item" v-if="contactLink">
                        <a
                            :href="contactLink"
                            class="m-nav__link"
                            :target="currentClient !== 'prokon' ? '_blank' : '_self'"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('footer.contact') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item">
                        <a
                            :href="privacyLink"
                            class="m-nav__link"
                            target="_blank"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('footer.privacy') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item" v-if="agbLink">
                        <a :href="agbLink" class="m-nav__link" target="_blank">
                            <p class="m-nav__link-text">
                                {{ $t('footer.agb') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item">
                        <a
                            :href="imprintLink"
                            class="m-nav__link"
                            target="_blank"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('footer.imprint') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item" v-if="legalNoticeLink">
                        <a
                            :href="legalNoticeLink"
                            class="m-nav__link"
                            target="_blank"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('footer.legal.notice') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item" v-if="tosLink">
                        <a :href="tosLink" class="m-nav__link" target="_blank">
                            <p class="m-nav__link-text">
                                {{ $t('footer.tos') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item" v-if="formularsLink">
                        <a
                            :href="formularsLink"
                            class="m-nav__link"
                            target="_blank"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('footer.formulars') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item" v-if="energyPricingLink">
                        <a
                            :href="energyPricingLink"
                            class="m-nav__link"
                            target="_blank"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('footer.energyPricing') }}
                            </p>
                        </a>
                    </li>
                    <li class="m-nav__item" v-if="showCancelation">
                        <a
                            class="m-nav__link nav-link-cancellation"
                            @click.prevent="$emit('toggleGroup', 'showCancellationWidget')"
                        >
                            <p class="m-nav__link-text">
                                {{ $t('widget.td.cancellation.link') }}
                                <!-- toggleGroup4('showCancellationWidget') -->
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
