<div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body">
        <i
            class="far fa-envelope readingHistory-icon"
            v-if="displayElement('envelop-default-icon')"
        ></i>
        <i class="fa fa-envelope-open readingHistory-icon" v-else></i>
        <span class="customerCommunications-title static-title"
            >{{ $t('widget.pb.title') }}</span
        ><br />
        <div v-if="communicationsErrorLoaded" class="information-not-loaded">
            {{ $t('widgets.load.error.message') }}
        </div>
        <div v-else class="tab-content" id="customerCommunications-content">
            <content-loader
                v-if="communicationsLoading && displayElement('loading-animation')"
                height="20"
                primaryColor="var(--secondary)"
                secondaryColor="var(--primary)"
                data-test="CONTENT-LOADER"
            ></content-loader>
            <div
                v-else
                class="tab-pane active"
                id="m_widget2_tab1_content"
                aria-expanded="true"
            >
                <!--begin:Timeline 1-->
                <div class="m-timeline-1 m-timeline-1--fixed">
                    <div
                        class="m-timeline-1__items"
                        id="customerCommunications-timeline"
                    >
                        <div class="m-timeline-1__marker"></div>
                        <div
                            v-for="communication in communications"
                            class="m-timeline-1__item"
                            :class="communication.timelineClass"
                        >
                            <div class="m-timeline-1__item-circle">
                                <div class="m--bg-primary"></div>
                            </div>
                            <div class="m-timeline-1__item-arrow"></div>
                            <span
                                class="m-timeline-1__item-time"
                                v-if="displayElement('date-only')"
                            >
                                {{ communication.createdAt | date }}
                            </span>
                            <span class="m-timeline-1__item-time" v-else>
                                {{ communication.createdAt | datetime }}
                            </span>
                            <div class="m-timeline-1__item-content">
                                <div class="m-timeline-1__item-title">
                                    <span
                                        id="downloadableCommunication"
                                        :class="[
                                            displayElement('break-word') ? 'break-word' : ''
                                        ]"
                                        v-if="communication.archiveId"
                                        @click="downloadCustomerCommunication(communication)"
                                        >{{ communicationTitle(communication) }}
                                        <i
                                            class="
                                                fa fa-file-pdf
                                                customerCommunicationDownloadIcon
                                            "
                                        ></i
                                    ></span>
                                    <span v-else
                                        >{{ communicationTitle(communication)
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <service-widget
                            :serviceWidgetPosition="serviceWidgetPosition"
                        ></service-widget>
                        <div
                            class="m-timeline-1__marker"
                            v-if="displayElement('bottom-timeline-marker')"
                        ></div>
                    </div>
                    <!-- END Service Widget -->
                </div>
                <div class="row">
                    <div class="col m--align-center">
                        <button
                            @click="loadMore"
                            v-if="!allLoaded"
                            class="
                                btn btn-sm
                                m-btn--custom m-btn--pill
                                btn-primary
                            "
                        >
                            {{ $t('widget.pb.more') }}
                        </button>
                    </div>
                </div>
                <!--End:Timeline 1-->
            </div>
        </div>
    </div>
</div>
