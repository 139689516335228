import { MeterSubmitWidget } from '../meter-submit-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './schweizstrom.html';

@WithRender
@Component({})
export class MeterSubmitWidgetSchweizstrom extends MeterSubmitWidget {
    public isVisibleBlock = false;

    public created() {
        this.details.readAt = null;
    }
}
