import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './dealer-quotas-widget.html';
import iocContainer from '@/container/IocContainer';
import Contracts from '@/interfaces/Contracts';
import SERVICES from '@/container/Services';
import IocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import moment from 'moment';

const contractProvider = iocContainer.get<Contracts>(SERVICES.CONTRACTS);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        euro: formatProvider.euro(),
    },
})
export class DealerQuotasWidget extends Base {
    public dataSets: {
        registration: string;
        vin: string;
        class: string;
        status: string;
        year: string;
        commission: string;
    }[] = [];

    public dataSetDate: string = '';
    public requestParams = {
        take: 10,
        skip: 0,
        search: '',
        sortedType: 'asc',
        sortedKey: '',
    };
    public currentPage = 1;

    public columns = [
        {
            title: 'Registration',
            key: 'registration',
            type: 'clickable',
            source: 'users',
            uniqueField: 'id',
            sortable: true,
        },
    ];

    get backColor() {
        if (this.currentPage === 1) {
            return '#888A8F';
        }
        return '#575962';
    }

    get forwardColor() {
        if (this.currentPage === this.totalPage) {
            return '#888A8F';
        }
        return '#575962';
    }

    get totalOpen() {
        let total = 0;
        this.dataSets.forEach((item) => {
            if (
                [
                    'pending',
                    'verified',
                    'not_verified_wrong_document_type',
                    'not_verified_wrong_fuel_code',
                    'not_verified_unreadable',
                    'not_verified_other_reason',
                ].includes(item.status)
            ) {
                total += 50;
            }
        });
        return total;
    }

    get totalSuccessful() {
        let total = 0;
        this.dataSets.forEach((item) => {
            if (['confirmed_by_uba'].includes(item.status)) {
                total += 50;
            }
        });
        return total;
    }

    get totalPage() {
        const total = Math.ceil(this.dataSets.length / this.requestParams.take);
        return total <= 1 ? 1 : total;
    }

    get sortedData() {
        const key = this.requestParams.sortedKey;
        const order = this.requestParams.sortedType;
        const offset = (this.currentPage - 1) * this.requestParams.take;
        if (key) {
            return this.dataSets
                .sort((a, b) => {
                    if (key === 'registration') {
                        const dateA = new Date(a[key]).getTime();
                        const dateB = new Date(b[key]).getTime();
                        if (order === 'DESC') {
                            return dateA - dateB;
                        }
                        return dateB - dateA;
                    }
                    if (typeof a[key] === 'string') {
                        if (a[key] < b[key]) {
                            return order === 'DESC' ? -1 : 1;
                        }
                        if (a[key] > b[key]) {
                            return order === 'DESC' ? 1 : -1;
                        }
                        return 0;
                    }
                    if (order === 'DESC') {
                        return a[key] - b[key];
                    }
                    return b[key] - a[key];
                })
                .slice(offset, offset + this.requestParams.take);
        }

        return this.dataSets.slice(offset, offset + this.requestParams.take);
    }

    public mounted() {
        this.readData();
    }

    public toggleSort(key) {
        if (this.requestParams.sortedKey === key) {
            if (this.requestParams.sortedType === 'DESC') {
                this.requestParams.sortedType = 'ASC';
            } else {
                this.requestParams.sortedType = 'DESC';
            }
        } else {
            this.sortedKeyValue(key, 'DESC');
        }
    }

    public sortedKeyValue(key, type) {
        this.requestParams.sortedKey = key;
        this.requestParams.sortedType = type;
        this.readData();
    }

    public readData() {
        if (this.contractId) {
            contractProvider.dealerQuotes(this.contractId).then((res) => {
                this.dataSets = Object.values(res.data.data).map(
                    // @ts-ignore
                    (value) => value[0]
                );
                this.dataSetDate = res.data.date;
            });
        }
    }
    public newPage(page) {
        if (page != 0 && page <= this.totalPage) {
            this.requestParams.skip = (page - 1) * this.requestParams.take;
            this.currentPage = page;
            this.readData();
        }
    }
    public firstPage() {
        this.currentPage = 1;
    }

    public lastPage() {
        this.currentPage = this.totalPage;
    }

    public prevPage() {
        if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
        }
    }

    public nextPage() {
        if (this.currentPage < this.totalPage) {
            this.currentPage = this.currentPage + 1;
        }
    }

    public getStatusClass(status) {
        if (
            [
                'pending',
                'verified',
                'not_verified_wrong_document_type',
                'not_verified_wrong_fuel_code',
                'not_verified_unreadable',
                'not_verified_other_reason',
            ].includes(status)
        ) {
            return 'open-status';
        }
        if (['confirmed_by_uba'].includes(status)) {
            return 'successful-status';
        }
        if (
            ['not_verified_duplicate', 'rejected_by_uba', 'cancelled'].includes(
                status
            )
        ) {
            return 'cancelled-status';
        }
    }
    public formatData(date) {
        return moment(date).format('DD.MM.YYYY');
    }
}
