<template>
    <highcharts :options="chartOptions" />
</template>

<script>
import { Chart } from 'highcharts-vue';
import { dateFormat } from 'highcharts';

export default {
    name: 'Chart',

    components: {
        highcharts: Chart,
    },

    props: {
        name: {
            type: String,
            required: true,
        },
        series: {
            type: Array,
            required: true,
        },
        referenceValues: {
            type: Array,
            default: () => [],
        },
        referenceValuesEnabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'var(--primary)',
        },
    },

    computed: {
        seriesData() {
            return {
                name: this.$t('widget.uh.usageReadingAxis'),
                data: this.series,
                showInLegend: false,
                maxPointWidth: 50,
            };
        },

        xAxis() {
            const threeMonthsPaddingLeft =
                this.series.length > 1 ? 1000 * 60 * 60 * 24 * 30 * 3 : 0;

            return {
                type: 'datetime',
                labels: {
                    format: '{value:%d.%m.%Y}',
                    formatter: this.labelFormatterXAxis,
                },
                axisTicks: {
                    show: true,
                },
                minorTicks: false,
                showEmpty: false,
                tickPositioner: this.tickPositioner,
                crosshair: true,
                maxPadding: 0.2,
                min:
                    Math.min(...this.series.map((point) => point.x)) -
                    threeMonthsPaddingLeft,
            };
        },

        yAxis() {
            return {
                title: {
                    text: this.$t('widget.uh.usageReadingAxis'),
                },
                gridLineWidth: this.referenceValuesEnabled ? 0 : 1,
                visible: true,
                endOnTick: false,
                startOnTick: false,
                min: 0,
                labels: {
                    formatter: this.labelFormatterYAxis,
                },
                plotBands: this.referenceValuesPlotBands,
            };
        },

        referenceValuesPlotBands() {
            if (!this.referenceValuesEnabled || !this.referenceValues)
                return [];

            const spacingBetweenBands = 25,
                marginRight = -16;

            return this.referenceValues.map((num, index) => ({
                color: this.color,
                from:
                    index === 0
                        ? 0
                        : this.referenceValues[index - 1] + spacingBetweenBands,
                to: num,
                label: {
                    text: num.toLocaleString('de-DE'),
                    align: 'right',
                    x: marginRight,
                },
            }));
        },

        chartOptions() {
            return {
                chart: {
                    type: 'column',
                    height: 400,
                },

                colors: [this.color],

                title: {
                    text: null,
                },

                series: [this.seriesData],
                xAxis: [this.xAxis],
                yAxis: [this.yAxis],

                legend: {
                    align: 'center',
                    verticalAlign: 'top',
                    floating: true,
                    offsetY: 0,
                    offsetX: 0,
                },

                plotOptions: {
                    series: {
                        lineWidth: 4,
                    },
                },

                tooltip: {
                    xDateFormat: '%d.%m.%Y',
                    useHTML: true,
                    formatter: this.tooltipFormatterFuncGenerator(),
                },
            };
        },
    },

    methods: {
        getPeriodStartForPos(pos) {
            const item = this.seriesData.data.find((point) => point.x === pos);
            return item.periodStart;
        },

        labelFormatterXAxis(reference) {
            const periodStart = this.getPeriodStartForPos(reference.value);
            return (
                (periodStart
                    ? dateFormat('%d.%m.%y', periodStart) + ' - '
                    : '') + dateFormat('%d.%m.%y', reference.value)
            );
        },

        formatNum(num) {
            return num.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
        },

        labelFormatterYAxis(reference) {
            return this.formatNum(reference.value) || '-';
        },

        tooltipFormatterFuncGenerator() {
            const vm = this;

            return function () {
                const periodStart = vm.getPeriodStartForPos(this.x),
                    formattedPeriodStart =
                        periodStart > 0
                            ? dateFormat('%d.%m.%y', periodStart) + ' - '
                            : '',
                    formattedNum = vm.formatNum(this.y);

                return `
                    <div>
                        <div class="usage-history-widget-tooltip-header">
                        ${formattedPeriodStart}
                        ${dateFormat('%d.%m.%y', this.x)}
                        </div>

                        <div class="usage-history-widget-tooltip-content">
                        <b>${vm.seriesData.name}: ${formattedNum}</b>
                        </div>
                    </div>
                `;
            };
        },

        tickPositioner() {
            return this.seriesData.data.map((point) => point.x);
        },
    },
};
</script>
