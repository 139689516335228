<div
    class="m-portlet m-portlet--mobile m-portlet--change-widget"
    data-test="change-widget"
>
    <div class="m-portlet__body">
        <div class="row">
            <div class="col-12">
                <div
                    class="d-flex align-items-center"
                    v-if="currentClient == 'wdenergy'"
                >
                    <i
                        class="fa readingHistory-icon"
                        :class="displayElement('edit-icon') ? 'fa-edit' : 'fa-user-edit'"
                    ></i>
                    <span
                        class="
                            ms-2
                            monthlyDeposit-title
                            static-title static-title--custom
                        "
                        >{{ $t('widget.cw.title') }}</span
                    >
                </div>
                <template v-else>
                    <i
                        class="fa readingHistory-icon"
                        :class="displayElement('edit-icon') ? 'fa-edit' : 'fa-user-edit'"
                    ></i>
                    <span
                        class="
                            monthlyDeposit-title
                            static-title static-title--custom
                        "
                        >{{ $t('widget.cw.title') }}</span
                    >
                </template>
                <br />
                <div
                    class="changeData-button-list"
                    :class="[isHorizontal ? 'thg' : '']"
                >
                    <button
                        data-test="change-widget-customer-data-click"
                        :disabled="changeCustomerDataLoading"
                        @click="$emit('toggleWidget', 'changeCustomerDataWidget')"
                        :class="['btn btn-link btn-sm', groupWidget.changeCustomerDataWidget ? 'active' : '']"
                        id="button-changeCustomerData"
                    >
                        <span
                            >{{ $t('widget.cw.customer'+prefix) }}</span
                        ></button
                    ><br v-if="!displayElement('no-line-breaks')" />
                    <button
                        data-test="change-widget-billing-data-click"
                        v-if="showChangeBillingData"
                        :disabled="changeBillingDataLoading"
                        @click="$emit('toggleWidget', 'changeBillingDataWidget')"
                        :class="['btn btn-link btn-sm', groupWidget.changeBillingDataWidget ? 'active' : '']"
                        id="button-changeBillingData"
                    >
                        <span>{{ $t('widget.cw.postal'+prefix) }}</span></button
                    ><br v-if="!displayElement('no-line-breaks')" />
                    <button
                        data-test="change-widget-payment-data-click"
                        v-if="showChangePaymentData"
                        :disabled="changePaymentDataLoading"
                        @click="$emit('toggleWidget', 'changePaymentDataWidget')"
                        :class="['btn btn-link btn-sm', groupWidget.changePaymentDataWidget ? 'active' : '']"
                        id="button-changePaymentData"
                    >
                        <span
                            >{{ $t('widget.cw.payment'+prefix) }}</span
                        ></button
                    ><br v-if="!displayElement('no-line-breaks')" />
                    <button
                        v-if="displayElement('show-tariff-details-link')"
                        :class="['btn btn-link btn-sm']"
                        @click="$emit('toggleWidget', 'isOpenTariffDetailsWidget')"
                    >
                        <span>{{ $t('widget.td.link') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
