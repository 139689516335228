import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './contact.html';

import { HeaderV2 } from '@/components/template/header-v2';
import { Footer } from '@/components/template/footer';
import { CustomerServiceFormWidgetUnauthenticated } from '@/components/widgets/customer-service-form-widget/custom/unauthenticated';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
        CustomerServiceFormWidgetUnauthenticated,
    },
})
export class Contact extends Base {
    protected elements = {
        bsw: ['login-footer-links-bsw'],
        ets: ['app-footer', 'header-v2'],
    };

    public created(): void {
        if (this.currentClient !== 'ets') {
            window.location.pathname = '/';
        }
    }

    private changeLocale(locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('wd-portal-language', this.$i18n.locale);
    }
}
