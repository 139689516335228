import { EcarUploadWidgetElli } from '@/components/widgets/ecar-upload-widget/custom/elli';
import { EcarUploadWidgetWdenergy } from '@/components/widgets/ecar-upload-widget/custom/wdenergy';
import { EcarUploadWidgetWdenergyUs } from '@/components/widgets/ecar-upload-widget/custom/wdenergy-us';
import { EcarUploadWidget } from '@/components/widgets/ecar-upload-widget/ecar-upload-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT?.includes('elli')) {
    widget = EcarUploadWidgetElli;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = EcarUploadWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = EcarUploadWidgetWdenergyUs;
} else {
    widget = EcarUploadWidget;
}
export { widget as EcarUploadWidget };
