<div class="fixed-bottom" v-if="showCookieAlert">
    <div
        class="
            container
            bg-white
            pt-3
            border-top border-start border-end
            rounded-top
        "
    >
        <a
            href="#"
            class="float-end"
            aria-label="Close"
            @click.prevent="hideCookieAlert"
            >&#10005;</a
        >
        <div class="text-center pt-2 pb-4 ps-4 pe-4">
            <b>Do you like cookies?</b> &#x1F36A; We use cookies to ensure you
            get the best experience on our website.
            <a href="https://cookiesandyou.com/" target="_blank">Learn more</a>
        </div>
    </div>
</div>
