import { ChangeBillingDataWidget } from '../change-billing-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './waermeenergie.html';

@WithRender
@Component({})
export class ChangeBillingDataWidgetWaermeenergie extends ChangeBillingDataWidget {
    get contractsLoaded() {
        return (
            this.$store.state.contracts.list &&
            this.$store.state.contracts.list.length > 0
        );
    }

    get isSME() {
        if (this.contractsLoaded) {
            const state = this.$store.getters['tariff/getState'](
                this.$store.state.contracts.list[0].contractId
            );
            return state && state.contract.business === true;
        }
        return false;
    }

    get dataChanged(): boolean {
        return (
            this.details.contractId !==
                this.$store.state.contracts.contractId ||
            this.details.billingSalutation !== this.billingAddress.salutation ||
            this.details.billingCompany !== this.billingAddress.company ||
            this.details.billingFirstName !== this.billingAddress.firstname ||
            this.details.billingSurname !== this.billingAddress.surname ||
            this.details.billingStreet !== this.billingAddress.street ||
            this.details.billingHouseNumber !==
                this.billingAddress.houseNumber ||
            this.details.billingCity !== this.billingAddress.city ||
            this.details.billingZip !== this.billingAddress.zip ||
            this.details.billingPhoneMobile !== this.billingAddress.phone ||
            this.details.billingEmail !== this.billingAddress.email ||
            this.details.billingCareOfRecipient !==
                this.billingAddress.careOfRecipient ||
            this.details.postbox !== this.billingAddress.postbox
        );
    }

    get submitButtonDisabledWaermeenergie(): boolean {
        if (
            !this.details.billingSurname ||
            !this.details.billingZip ||
            !this.details.billingCity ||
            this.submitPending ||
            !this.dataChanged
        ) {
            return true;
        }
        if (!this.isSME && !this.details.billingFirstName) {
            return true;
        }

        if (
            (this.isShowPostboxField && !this.details.postbox) ||
            (!this.isShowPostboxField &&
                !this.details.billingStreet &&
                !this.details.billingHouseNumber)
        ) {
            return true;
        }

        return false;
    }

    protected submitWaermeenergie() {
        if (this.isShowPostboxField) {
            this.details.billingStreet = '';
            this.details.billingHouseNumber = '';
        } else {
            this.details.postbox = '';
        }
        this.submit();
    }
}
